import React, { useEffect, useContext, useState } from 'react';

import searchIcon from '../../static/images/mobile/search.svg';
import cryptoIcon from '../../static/images/mobile/crypto.svg';
import forexIcon from '../../static/images/mobile/forex.svg';

import debitIcon from '../../static/images/mobile/debit.svg';
import creditIcon from '../../static/images/mobile/credit.svg';

import axios from 'axios';
import { useAppContextDetails } from '../../context/AppContext';

import { BankContext } from '../../context/Context';
import VaultAction from '../MobileLayoutComponents/VaultAction';
import Skeleton from 'react-loading-skeleton';
import SwitcherSearch from './SwitcherSearch';
import ActionFlow from '../MobileLayoutComponents/ActionFlow';

const VaultCrypto = () => {
  const {
    appCode,
    selectedView,
    setSelectedView,
    selectedCurrency,
    setSelectedCurrency,
    actionFlow,
    setActionFlow,
  } = useAppContextDetails();
  const { email, token, setSidebarCollapse } = useContext(BankContext);
  const [openSearch, setOpenSearch] = useState(false);
  const [allCryptoData, setAllCryptoData] = useState([]);
  const [allTxns, setAllTxns] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios
      .post(`https://comms.globalxchange.io/coin/vault/service/coins/get`, {
        app_code: appCode,
        email,
        displayCurrency: 'USD',
        type: 'crypto',
        orderby_dsc: true,
        with_balance: true,
        post_app_prices: true,
      })
      .then(({ data }) => {
        setSelectedCurrency(data.coins_data[0]);
        setAllCryptoData(data.coins_data);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (selectedView === 'Transactions' && token && email) {
      getTxns();
    }
  }, [selectedView, selectedCurrency]);

  const getTxns = () => {
    axios
      .post(`https://comms.globalxchange.io/coin/vault/service/txns/get`, {
        app_code: appCode,
        coin: selectedCurrency?.coinSymbol,
        displayCurrency: 'INR',
        email: email,
      })
      .then(({ data }) => {
        setAllTxns(data.txns);
      });
  };

  const conditionalView = () => {
    if (selectedView === 'Overview') {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            // justifyContent: 'center',
            paddingTop: '5vh',
          }}
        >
          <div style={{ fontSize: '40px', fontWeight: 700 }}>
            {selectedCurrency?.coinValue}
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              paddingTop: '10px',
            }}
          >
            <img
              src={selectedCurrency?.coinImage}
              alt=""
              width={20}
              height={20}
            />
            <div style={{ fontSize: '18px', paddingLeft: '10px' }}>
              {selectedCurrency?.coinName}
            </div>
          </div>
        </div>
      );
    } else if (selectedView === 'Transactions') {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            // alignItems: 'center',
            // justifyContent: 'center',
            paddingTop: '5vh',
          }}
        >
          {allTxns?.map((item, index) => {
            return (
              <div
                key={index}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  border: '1px solid #e7e7e7',
                  borderRadius: '10px',
                  padding: '20px',
                  marginBottom: '10px',
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {item?.withdraw ? (
                    <img src={debitIcon} width={30} height={30} />
                  ) : (
                    <img src={creditIcon} width={30} height={30} />
                  )}
                  <div style={{ paddingLeft: '10px' }}>
                    <div>{`${selectedCurrency?.coinSymbol} ${
                      item.deposit ? 'Credit' : 'Debit'
                    }`}</div>
                    <div style={{ fontSize: '9px' }}>{item.date}</div>
                  </div>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                  }}
                >
                  <div
                    style={{
                      fontWeight: 'bold',
                      color: item?.deposit ? 'green' : 'red',
                    }}
                  >
                    {item?.deposit ? '+' : '-'}
                    {item?.amount?.toFixed(2)}
                  </div>
                  <div style={{ fontSize: '9px' }}>
                    Balance: {item?.updated_balance?.toFixed(2)}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      );
    } else if (selectedView === 'Actions') {
      return <VaultAction vaultType={'Crypto'} />;
    }
  };

  return (
    <>
      {!actionFlow ? (
        <div>
          {!openSearch ? (
            <>
              <div className="switcherWrapper">
                <div className="iconWrapper">
                  <div className="iconDiv" onClick={(e) => setOpenSearch(true)}>
                    <img className="divImg" src={searchIcon} alt="" />
                  </div>
                  <div
                    style={{
                      fontSize: '10px',
                      fontWeight: 'bold',
                      paddingTop: '5px',
                    }}
                  >
                    Search
                  </div>
                </div>
                <div
                  style={{
                    overflowX: 'scroll',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {!loading ? (
                    allCryptoData.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="iconWrapper"
                          onClick={(e) => setSelectedCurrency(item)}
                        >
                          <div
                            className="iconDiv"
                            // onClick={(e) => setSelectedCurrency(item)}
                          >
                            <img
                              className="divImg"
                              src={item?.coinImage}
                              alt=""
                            />
                          </div>
                          {item.coinName === selectedCurrency.coinName ? (
                            <div
                              style={{
                                width: '90px',
                                fontSize: '10px',
                                fontWeight:
                                  item.coinName === selectedCurrency.coinName
                                    ? 'bold'
                                    : 500,
                                paddingTop: '5px',
                                textAlign: 'center',
                              }}
                            >
                              {item.coinName}
                            </div>
                          ) : null}
                        </div>
                      );
                    })
                  ) : (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {Array(10)
                        .fill(' ')
                        .map((item, index) => {
                          return (
                            <div style={{ textAlign: 'center' }} key={index}>
                              <Skeleton
                                width={50}
                                height={50}
                                style={{
                                  margin: '0px 10px',
                                  marginBottom: '5px',
                                }}
                              />
                              <Skeleton width={50} height={5} />
                            </div>
                          );
                        })}
                    </div>
                  )}
                </div>
              </div>
              <div
                style={{
                  overflowY: 'scroll',
                  height: '80vh',
                  padding: '20px 22px',
                }}
              >
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div
                    style={{
                      fontWeight: selectedView === 'Overview' ? 700 : 500,
                    }}
                    onClick={(e) => setSelectedView('Overview')}
                  >
                    Overview
                  </div>
                  <div
                    style={{
                      fontWeight: selectedView === 'Transactions' ? 700 : 500,
                    }}
                    onClick={(e) => setSelectedView('Transactions')}
                  >
                    Transactions
                  </div>
                  <div
                    style={{
                      fontWeight: selectedView === 'Actions' ? 700 : 500,
                    }}
                    onClick={(e) => setSelectedView('Actions')}
                  >
                    Actions
                  </div>
                </div>
                {conditionalView()}
              </div>
            </>
          ) : (
            <SwitcherSearch
              path={'VaultCrypto'}
              listData={allCryptoData}
              setSelectedData={setSelectedCurrency}
              setOpenSearch={setOpenSearch}
            />
          )}
        </div>
      ) : (
        <ActionFlow />
      )}
    </>
  );
};

export default VaultCrypto;
