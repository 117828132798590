import React from 'react';
import './aiindex.scss';
import Layout from '../../Layout/Layout';
import { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppContextDetails } from '../../context/AppContext';
import { BankContext } from '../../context/Context';
import { RxHamburgerMenu } from 'react-icons/rx';

import fulllogo from '../../static/images/aiindex/airFullLogo.svg';
import search from '../../static/images/malls/search.svg';
import searchIcon from '../../static/images/search.svg';
import ddown from '../../static/images/malls/ddown.svg';
import sample from '../../static/images/aiindex/sample.svg';
import { planBContext } from '../../context/PlanBContext';
import Subscription from '../Shop/Subscription';
import ShopComponent from '../Shop';
import MallProduct from '../../components/MallProduct/MallProduct';

const MallsIndex = () => {
  const {
    aitab,
    setAiTab,
    allOpportunities,
    filteredallOpportunities,
    settFilteredallOpportunities,
    setfiltersearch,
  } = useAppContextDetails();
  const { insideplanBCreate, setinsideplanBCreate } = useContext(planBContext);
  return (
    <Layout active="air" className="aiindex" hideFooter>
      <div style={{ width: '100%', height: '100%' }}>
        <div
          className="aiindex-navbar"
          style={{ padding: '1rem 2.5rem 1rem 3rem' }}
        >
          <div className="aiindex-hamburger">{<RxHamburgerMenu />}</div>
          <div
            className="aiindex-icon"
            style={{ width: '300px', height: '40px' }}
          >
            <img src={fulllogo} alt="fulllogo" />
          </div>
          <div className="search-aiindex">
            <div className="im">
              <input
                type="text"
                placeholder="Im Looking For..."
                // onChange={(event) => setfiltersearch(event?.target?.value)}
              />
              <div className="arroww" style={{ cursor: 'pointer' }}>
                <img src={searchIcon} alt="" />
              </div>
            </div>
          </div>
          <div
            className="aiindex-listBtn"
            onClick={() => {
              window.open('https://verse.viral.group/', '_blank');
            }}
            style={{
              background: 'linear-gradient(270deg, #81cbd7 0%, #3d4394 100%)',
              height: '100%',
              fontWeight: '600',
              border: 'none',
            }}
          >
            List Your Product
          </div>
        </div>
        <div style={{ position: 'relative', width: '100%', height: '92%' }}>
          <MallProduct />
        </div>
      </div>
    </Layout>
  );
};

export default MallsIndex;
