import React from 'react';
import Layout from '../../Layout/Layout';
import { useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAppContextDetails } from '../../context/AppContext';
import { BankContext } from '../../context/Context';
import { useMarketContext } from '../../context/MarketContext';
import { MARKET_ASSET_CLASSES } from '../../config/constants';
import { VaultDetailedSection } from '../VaultsPage1/SeperateComponents';
import Skeleton from 'react-loading-skeleton';
import MarketTableShares from '../MarketsPage1/components/MarketTableShares';
import axios from 'axios';
// import Transaction from
// import Transaction from '../ShareToken/ValutPage';
import './shares.scss';
import fulllogo from '../../static/images/shares/fulllogo.svg';
import searchIcon from '../../static/images/malls/search.svg';
import { useEffect } from 'react';
import Transaction from './Divident';
import { GetSortOrder } from '../../utils/FunctionTools';
import classNames from '../market/marketsPage.module.scss';

const SharesPage = () => {
  const {
    sharesTab,
    setSharesTab,
    coinSelected,
    setCoinSelected,
    marketSearch,
    setMarketSearch,
    Divident,
    setDivident,
    displayData,
    setDisplayData,
    dividendBalance,
    selectedVaultData,
    setSelectedVaultdata,
    dividendLoading,
    setDividendLoading,
    filteredSelectedVaultData,
    setFilteredSelectedVaultdata,
    mapData,
    setMapData,
    filteredMapData,
    setFilteredMapData,
    setshareTokenTabName,
    selectedAppCode,
    setCardSelected,
  } = useAppContextDetails();
  const history = useHistory();
  const { email } = useContext(BankContext);
  const { streamOpen, setStreamOpen } = useMarketContext();

  const { assetClassName, coin } = useParams();
  const [assetClass, setAssetClass] = useState('');
  const [SLoading, setSLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [tabShare, settabShare] = useState('default');
  const [valutSeleted, setvalutSeleted] = useState('');
  const [shareTokenTranscation, setshareTokenTranscation] = useState([]);

  const filterData = (selectedVault) => {
    if (dividendBalance?.length > 0) {
      let result = dividendBalance?.filter((each) => {
        return each?.vaultName === selectedVault;
      });
      // console.log(result, 'result');
      if (result?.length > 0) {
        setSelectedVaultdata(result[0]?.coinVaults);
        setFilteredSelectedVaultdata(result[0]?.coinVaults);
      }
    }
  };

  // console.log('ssssssssss', valutSeleted?.selectedVault);
  function dividend(e) {
    setSLoading(true);
    axios
      .get(
        `https://comms.globalxchange.io/coin/app/dividend/user/vault/txns/get?email=${email}&app_code=${selectedAppCode}&source_vault=${valutSeleted?.selectedVault}&coin=${e}`
      )
      .then((response) => {
        console.log(response?.data, 'response sharetoken');
        setshareTokenTranscation(response?.data?.txns);
        setSLoading(false);
        // setsharetokenBalance(response?.data?.vaultsData);
        // setApiDataLoadingShareValue(false);
      })
      .catch((error) => {
        // setApiDataLoadingShareValue(false);
      });
  }

  function filterItem(text) {
    let filterItem = selectedVaultData?.filter((eachitem) => {
      return eachitem?.coinName?.toLowerCase()?.includes(text?.toLowerCase());
    });
    setFilteredSelectedVaultdata(filterItem);
  }

  function filterItem1(text) {
    let filterItem = mapData?.filter((eachitem) => {
      return eachitem?.name?.toLowerCase()?.includes(text?.toLowerCase());
    });
    setFilteredMapData(filterItem);
  }

  const callVaultTransaction = (e) => {
    setDisplayData('holdingTransaction');
    setDivident(e);
    dividend(e?.coinSymbol);
    //
  };

  const handle = () => {
    switch (tabShare) {
      case 'value':
        break;

      default:
        break;
    }
  };

  const handlesoruce = (e) => {
    setvalutSeleted(e);
    // console.log('ssssssssss', e);
  };

  return (
    <Layout active="shares" className="shares" hideFooter>
      <div style={{ width: '100%', height: '100%' }}>
        <div className="shares-navbar">
          <div className="shares-icon">
            <img
              style={{
                cursor: 'pointer',
                height: '100%',
              }}
              onClick={() => {
                setSharesTab('Holdings');
                setCardSelected(false);
                setDisplayData('default');
                setshareTokenTabName('default');
              }}
              src={fulllogo}
              alt=""
            />
          </div>
          <div className="search-shares">
            <div className="im">
              <input
                type="text"
                placeholder="Im Looking For..."
                onChange={(event) => {
                  if (sharesTab === 'Offerings') {
                    setMarketSearch(event.target.value);
                  } else if (
                    sharesTab === 'Holdings' &&
                    displayData === 'default'
                  ) {
                    setSearchTerm(event.target && event.target.value);
                  } else if (
                    sharesTab === 'Holdings' &&
                    displayData === 'vaultData1'
                  ) {
                    filterItem(event.target && event.target.value);
                  } else if (
                    sharesTab === 'Holdings' &&
                    displayData === 'vaultData'
                  ) {
                    filterItem1(event.target && event.target.value);
                  }
                }}
              />
              <div className="arroww" style={{ cursor: 'pointer' }}>
                <img src={searchIcon} alt="" />
              </div>
            </div>
          </div>
          <div className="shares-listBtn">My Holdings</div>
        </div>

        <div className="w-space">
          <div className="optionss" style={{ width: '49rem' }}>
            {MARKET_ASSET_CLASSES.sort(GetSortOrder('marketOrder')).map(
              (assetClassCard, i) => (
                <div
                  key={i}
                  className={`each-option ${
                    assetClassCard?.label === assetClass?.label
                      ? 'each-option-selected'
                      : ''
                  }`}
                  onClick={() => {
                    if (assetClassCard.marketEnable) {
                      setDisplayData('default');
                      setshareTokenTabName('default');
                      setAssetClass(assetClassCard);
                      if (assetClassCard.name === 'shares') {
                        history.push('/shares');
                      } else {
                        history.push(`/shares/${assetClassCard.name}`);
                      }
                    }
                  }}
                  style={{
                    padding: '5px 1.5rem',
                    border: '0.5px solid #ededed',
                  }}
                >
                  <div>
                    <img
                      src={assetClassCard.miniLogo}
                      alt={assetClassCard.label}
                      style={{ height: '1.1rem', objectFit: 'contain' }}
                    />
                    <p>{assetClassCard?.label}</p>
                  </div>
                </div>
              )
            )}
          </div>
        </div>

        <div className="sharesContent">
          {sharesTab === 'Holdings' && displayData === 'default' ? (
            <VaultDetailedSection
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          ) : sharesTab === 'Holdings' && displayData === 'vaultData' ? (
            <>
              <div className="headerDiv">
                <div>Revenue Stream</div>
                <div>Vault Balances</div>
              </div>
              <div className="overAllData">
                {!dividendLoading && dividendBalance?.length > 0 ? (
                  <>
                    {filteredMapData?.map((e, i) => {
                      return (
                        <div className="eachdata" key={i}>
                          <div className="DataDiv">
                            <div>{e?.name}</div>
                            <div>
                              ${e?.value?.totalEarningsBalanceVault?.toFixed(2)}
                            </div>
                          </div>
                          <div className="BtnsDiv">
                            <div style={{ opacity: '0.5' }}>Actions</div>
                            <div
                              onClick={() => {
                                filterData(e?.selectedVault);
                                setDisplayData('vaultData1');
                                handlesoruce(e);
                              }}
                            >
                              Vaults
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : dividendLoading ? (
                  Array(10)
                    .fill(' ')
                    .map((item, index) => {
                      return (
                        <div className="eachdata" key={index}>
                          <div className="DataDiv">
                            <div>
                              <Skeleton width={70} height={20} />
                            </div>
                            <div>
                              <Skeleton width={70} height={20} />
                            </div>
                          </div>
                          <div className="BtnsDiv">
                            <Skeleton width={50} height={20} />

                            <Skeleton width={50} height={20} />
                          </div>
                        </div>
                      );
                    })
                ) : (
                  ''
                )}
              </div>
            </>
          ) : sharesTab === 'Holdings' && displayData === 'vaultData1' ? (
            <>
              <div className="headerDiv">
                <div style={{ width: '24%' }}>Asset</div>
                <div style={{ width: '24%' }}>Balance</div>
                <div style={{ width: '24%' }}>Value</div>
              </div>
              <div className="overAllData">
                {filteredSelectedVaultData?.map((e, i) => {
                  return (
                    <div className="eachdata" key={i}>
                      <div className="DataDiv1">
                        <div
                          style={{
                            display: 'flex',
                            gap: '5px',
                            alignItems: 'center',
                          }}
                        >
                          <img
                            src={e?.coinImage}
                            alt=""
                            style={{ width: '30px', height: '30px' }}
                          />
                          <div style={{ width: 'auto' }}>{e?.coinName}</div>
                        </div>
                        <div>{e?.userCoinValue?.toFixed(5)}</div>
                        <div>${e?.userCoinValueUSD?.toFixed(2)}</div>
                      </div>
                      <div
                        className="BtnsDiv"
                        style={{
                          justifyContent: 'space-between',
                          width: '28%',
                        }}
                      >
                        <div style={{ opacity: '0.5' }}>Actions</div>
                        <div
                          onClick={() => callVaultTransaction(e)}
                          Transactions
                        >
                          Transactions
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          ) : sharesTab === 'Holdings' &&
            displayData === 'holdingTransaction' ? (
            <>
              <div
                style={{
                  padding: '2rem 3rem',
                }}
              >
                <Transaction
                  setshareTokenTranscation={setshareTokenTranscation}
                  shareTokenTranscation={shareTokenTranscation}
                  SLoading={SLoading}
                />
              </div>
            </>
          ) : (
            <div className="tableArea">
              <MarketTableShares
                streamOpen={streamOpen}
                setStreamOpen={setStreamOpen}
                setCoinSelected={setCoinSelected}
                assetClass={assetClass}
                search={search}
                settabShare={settabShare}
              />
              {/* <div className='transactionSharepage'>
                <Transaction />
              </div> */}
              {/*  */}
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default SharesPage;
