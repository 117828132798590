import React, { useState, useEffect, useContext } from 'react';
import { useAppContextDetails } from '../../../context/AppContext';
import QRCode from 'qrcode.react';
import axios from 'axios';

import pasteIcon from '../../../static/images/paste.svg';
import { BankContext } from '../../../context/Context';

const ViewAddressStepThree = ({ coinData, hashSuccessData }) => {
  const { selectedCurrency, appCode, setBuySellStep, setActionFlow } =
    useAppContextDetails();
  const { email, token, profileId } = useContext(BankContext);
  const [loading, setLoading] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [hashValue, setHashValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: window.innerHeight - 100,
      }}
    >
      <div>
        <div className="breadcrumb">
          <span onClick={(e) => setActionFlow('Submit Hash')}>Submit Hash</span>
          &nbsp;&nbsp;{`>`}&nbsp;&nbsp;
          <span style={{ fontWeight: 700 }}>Success</span>
        </div>
        <div style={{ padding: '0px 22px' }}>
          <div style={{ paddingTop: '40px' }}>
            <div>
              Congratulations. You Have Successully Submitted The Hash For Your
              Lasted {hashSuccessData?.symbol} Deposit
            </div>

            <div>
              <div className="cardlabel">
                Updated {hashSuccessData?.symbol} Balance
              </div>
              <div className="cardStyle">
                <div className="cardText">{hashSuccessData?.coinValue}</div>
                <img src={hashSuccessData?.coinImage} className="cardImage" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ padding: '0px 22px' }}>
        <div className="singleButton">
          <div className="fullButton" onClick={(e) => setActionFlow(null)}>
            <div style={{ color: 'white', fontWeight: 'bold' }}>Go Back</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAddressStepThree;
