import React, { useEffect } from 'react';
import './aiindex.scss';
import Layout from '../../Layout/Layout';
import { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppContextDetails } from '../../context/AppContext';
import { BankContext } from '../../context/Context';

import fulllogo from '../../static/images/aiindex/airFullLogo.svg';
// import fulllogo from '../../static/images/malls/search.svg';
import searchIcon from '../../static/images/search.svg';
import ddown from '../../static/images/malls/ddown.svg';
import sample from '../../static/images/aiindex/sample.svg';
import CreatePlanBContainer from '../../components/BuyBlocks';
import { planBContext } from '../../context/PlanBContext';
import Subscription from '../Shop/Subscription';
import ShopComponent from '../Shop';
import Skeleton from 'react-loading-skeleton';
import { RxHamburgerMenu } from 'react-icons/rx';
import close from '../../static/images/clipIcons/close.svg';

const AiIndex = () => {
  const {
    appData,
    aitab,
    setAiTab,
    allOpportunities,
    filteredallOpportunities,
    setFilteredallOpportunities,
    setfiltersearch,
    selectedType,
    setSelectedType,
    selectedCustody,
    setSelectedCustody,
    selectedAssetClass,
    setSelectedAssetClass,
    selectedBilling,
    setSelectedBilling,
    selectedComp,
    setSelectedComp,
    selectedLeverage,
    setSelectedLeverage,
    selectedPaymentMethods,
    setSelectedPaymentMethods,
    shopCompanies,
    setShopCompanies,
    shopBilling,
    setShopBilling,
  } = useAppContextDetails();
  const {
    aiIndexOfferings,
    setAiIndexOfferings,
    insideplanBCreate,
    setinsideplanBCreate,
    aiIndexDefaultSelected,
    setaiIndexDefaultSelected,
    typeFilter,
    custodyFilter,
    assetClassFilter,
    billingFilter,
    compFilter,
    leverageFilter,
    paymentMethodsFilter,
    shopCompaniesFilter,
    shopBillingFilter,
  } = useContext(planBContext);

  const [selectedFilter, setSelectedFilter] = useState('default');
  const [btnOptionType, setBtnOptionType] = useState('All');
  const [btnOptionAsset, setBtnOptionAsset] = useState('All');
  const [btnOptionCustody, setBtnOptionCustody] = useState('All');
  const [btnOptionBilling, setBtnOptionBilling] = useState('All');
  const [btnOptionInvestment, setBtnOptionInvestment] = useState('$0.00');
  const [btnOptionDrawdown, setBtnOptionDrawdown] = useState('50.00%');
  const [btnOptionManagementFee, setBtnOptionManagementFee] =
    useState('25.00%');
  const [btnOptionCompPlan, setBtnOptionCompPlan] = useState('All');
  const [btnOptionLeverage, setBtnOptionLeverage] = useState('All');
  const [btnOptionPayment, setBtnOptionPayment] = useState('All');

  const [typefilterSelected, setTypefilterSelected] = useState(false);
  const [assetfilterSelected, setAssetfilterSelected] = useState(false);
  const [custodyfilterSelected, setCustodyfilterSelected] = useState(false);
  const [billingfilterSelected, setBillingfilterSelected] = useState(false);

  return (
    <Layout active="air" className="aiindex" hideFooter>
      <div style={{ width: '100%', height: '100%' }}>
        <div
          className="aiindex-navbar"
          style={{ padding: '1rem 2.5rem 1rem 3rem' }}
        >
          <div
            className="aiindex-hamburger"
            onClick={() => {
              setAiIndexOfferings(!aiIndexOfferings);
              setSelectedFilter('default');
            }}
          >
            {aiIndexOfferings ? <div>x</div> : <RxHamburgerMenu />}
          </div>
          <div
            className="aiindex-icon"
            style={{ width: '300px', height: '40px' }}
          >
            <img src={fulllogo} alt="fulllogo" />
          </div>
          <div className="search-aiindex">
            {/* <div className="all">
              <p>All Categories</p>
              <div className="arroww" style={{ cursor: 'pointer' }}>
                <img src={ddown} alt="" />
              </div>
            </div> */}
            <div className="im">
              <input
                type="text"
                placeholder="Im Looking For..."
                onChange={(event) => setfiltersearch(event?.target?.value)}
              />
              <div className="arroww" style={{ cursor: 'pointer' }}>
                <img src={searchIcon} alt="" />
              </div>
            </div>
          </div>
          <div
            className="aiindex-listBtn"
            onClick={() => {
              window.open('https://verse.viral.group/', '_blank');
            }}
            style={{
              background: 'linear-gradient(270deg, #81cbd7 0%, #3d4394 100%)',
              height: '100%',
              fontWeight: '600',
              border: 'none',
            }}
          >
            List Your Product
          </div>
        </div>

        <div
          className="w-space"
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <div className="optionss" style={{ width: '20%' }}>
            <div
              className={`each-option ${
                aitab === 'Offerings' ? 'each-option-selected' : ''
              }`}
              // style={{
              //   borderBottom: aitab === 'Offerings' ? '2px solid #9FD6DF' : '',
              //   fontWeight: aitab === 'Offerings' ? '600' : '',
              // }}
              onClick={() => setAiTab('Offerings')}
            >
              <div>
                <p>Ai Index</p>
              </div>
            </div>
            {/* <div
              className={`each-option ${
                aitab === 'Opportunities' ? 'each-option-selected' : ''
              }`}
              style={{
                // borderBottom:
                //   aitab === 'Opportunities' ? '2px solid #9FD6DF' : '',
                // fontWeight: aitab === 'Opportunities' ? '600' : '',
                pointerEvents: 'none',
              }}
              onClick={() => setAiTab('Opportunities')}
            >
              <div>
                <p>Opportunities</p>
              </div>
            </div> */}
            {/* <div
              className={`each-option ${
                aitab === 'Companies' ? 'each-option-selected' : ''
              }`}
              style={{
                // borderBottom: aitab === 'Companies' ? '2px solid #9FD6DF' : '',
                // fontWeight: aitab === 'Companies' ? '600' : '',
                pointerEvents: 'none',
              }}
              onClick={() => setAiTab('Companies')}
            >
              <div>
                <p>Companies</p>
              </div>
            </div> */}
            <div
              className={`each-option ${
                aitab === 'shop' ? 'each-option-selected' : ''
              }`}
              // style={{
              //   borderBottom: aitab === 'shop' ? '2px solid #9FD6DF' : '',
              //   fontWeight: aitab === 'shop' ? '600' : '',
              // }}
              onClick={() => setAiTab('shop')}
            >
              <div>
                <p>Ai Marketplace</p>
              </div>
            </div>
          </div>
          <div
            className="business-option"
            style={{
              borderBottom: aitab === 'purchases' ? '2px solid #9FD6DF' : '',
              fontWeight: aitab === 'purchases' ? '600' : '',
            }}
            onClick={() => setAiTab('purchases')}
          >
            <div>
              <p>Receipts</p>
            </div>
          </div>
        </div>

        {aitab == 'Offerings' ? (
          <div
            className="aiindex-content"
            style={{
              flexDirection: aiIndexOfferings ? 'row-reverse' : '',
            }}
          >
            <div className="left-ai-container" style={{ padding: '0 2.5rem' }}>
              <div
                className="left-ai-container-heading"
                style={{
                  width: '100%',
                  height: '5rem',
                  display: typefilterSelected
                    ? 'flex'
                    : assetfilterSelected
                    ? 'flex'
                    : custodyfilterSelected
                    ? 'flex'
                    : billingfilterSelected
                    ? 'flex'
                    : 'none',
                  gap: '2rem',
                  padding: '2rem 0 0 0',
                  overflowX: 'scroll',
                }}
              >
                {typefilterSelected && (
                  <div className="eachDivv">
                    <div>{selectedType}</div>
                    <div
                      className="closeDiv"
                      onClick={() => {
                        setTypefilterSelected(false);
                        setSelectedType('');
                        setBtnOptionType('All');
                      }}
                    >
                      <img src={close} alt="" />
                    </div>
                  </div>
                )}
                {assetfilterSelected && (
                  <div className="eachDivv">
                    <div>{selectedAssetClass}</div>
                    <div
                      className="closeDiv"
                      onClick={() => {
                        setAssetfilterSelected(false);
                        setSelectedAssetClass('');
                        setBtnOptionAsset('All');
                      }}
                    >
                      <img src={close} alt="" />
                    </div>
                  </div>
                )}
                {custodyfilterSelected && (
                  <div className="eachDivv">
                    <div>{selectedCustody}</div>
                    <div
                      className="closeDiv"
                      onClick={() => {
                        setCustodyfilterSelected(false);
                        setSelectedCustody('');
                        setBtnOptionCustody('All');
                      }}
                    >
                      <img src={close} alt="" />
                    </div>
                  </div>
                )}
                {billingfilterSelected && (
                  <div className="eachDivv">
                    <div>{selectedBilling}</div>
                    <div
                      className="closeDiv"
                      onClick={() => {
                        setBillingfilterSelected(false);
                        setSelectedBilling('');
                        setBtnOptionBilling('All');
                      }}
                    >
                      <img src={close} alt="" />
                    </div>
                  </div>
                )}
              </div>
              <div
                style={{
                  width: '100%',
                  height: typefilterSelected
                    ? 'calc(100% - 5rem)'
                    : assetfilterSelected
                    ? 'calc(100% - 5rem)'
                    : custodyfilterSelected
                    ? 'calc(100% - 5rem)'
                    : billingfilterSelected
                    ? 'calc(100% - 5rem)'
                    : '100%',
                  overflowY: 'scroll',
                  paddingBottom: '2rem',
                }}
              >
                {filteredallOpportunities
                  ? filteredallOpportunities?.map((eachItem) => {
                      if (eachItem.item_name !== 'sgsg') {
                        return <AIOpportunityCard {...eachItem} />;
                      }
                    })
                  : Array(5)
                      .fill(' ')
                      .map((item, index) => {
                        return (
                          <AIOpportunityCardLoading key={'loading' + index} />
                        );
                      })}
              </div>
            </div>
            <div
              className="right-ai-container"
              style={{
                display: aiIndexOfferings ? '' : 'none',
                borderRight: aiIndexOfferings ? '0.5px solid #e7e7e7' : 'none',
              }}
            >
              {selectedFilter === 'default' && (
                <div className="right-ai-items" style={{ padding: '0' }}>
                  <div className="filterItemR">
                    <div>
                      <div>Category</div>
                      <div
                        className="btn"
                        onClick={() => {
                          setSelectedFilter('Type');
                        }}
                      >
                        {btnOptionType}
                      </div>
                    </div>
                  </div>

                  <div className="filterItemR">
                    <div>
                      <div>Asset Class</div>
                      <div
                        className="btn"
                        onClick={() => {
                          setSelectedFilter('Asset Class');
                        }}
                      >
                        {btnOptionAsset}
                      </div>
                    </div>
                  </div>

                  <div className="filterItemR">
                    <div>
                      <div>Custody</div>
                      <div
                        className="btn"
                        onClick={() => {
                          setSelectedFilter('Custody');
                        }}
                      >
                        {btnOptionCustody}
                      </div>
                    </div>
                  </div>

                  <div className="filterItemR">
                    <div>
                      <div>Billing Type</div>
                      <div
                        className="btn"
                        onClick={() => {
                          setSelectedFilter('Billing Type');
                        }}
                      >
                        {btnOptionBilling}
                      </div>
                    </div>
                  </div>

                  <div className="filterItemR">
                    <div>
                      <div>Minimum</div>
                      <div
                        className="btn"
                        // onClick={() => {
                        //   setSelectedFilter('Minimum Investment');
                        // }}
                        style={{ background: 'none', border: '0.5px solid #ededed' }}
                      >
                        {btnOptionInvestment}
                      </div>
                    </div>
                  </div>

                  {/* <div className="filterItemR">
                    <div>
                      <div>Maximum Drawdown</div>
                      <div
                        className="btn"
                        // onClick={() => {
                        //   setSelectedFilter('Maximum Drawdown');
                        // }}
                        style={{ background: 'none', border: 'none' }}
                      >
                        {btnOptionDrawdown}
                      </div>
                    </div>
                  </div>

                  <div className="filterItemR">
                    <div>
                      <div>Max Management Fee</div>
                      <div
                        className="btn"
                        // onClick={() => {
                        //   setSelectedFilter('Max Management Fee');
                        // }}
                        style={{ background: 'none', border: 'none' }}
                      >
                        {btnOptionManagementFee}
                      </div>
                    </div>
                  </div>

                  <div className="filterItemR">
                    <div>
                      <div>Comp Plan</div>
                      <div
                        className="btn"
                        onClick={() => {
                          setSelectedFilter('Comp Plan');
                        }}
                      >
                        {btnOptionCompPlan}
                      </div>
                    </div>
                  </div>

                  <div className="filterItemR">
                    <div>
                      <div>Leverage</div>
                      <div
                        className="btn"
                        onClick={() => {
                          setSelectedFilter('Leverage');
                        }}
                      >
                        {btnOptionLeverage}
                      </div>
                    </div>
                  </div>

                  <div className="filterItemR">
                    <div>
                      <div>Payment Methods</div>
                      <div
                        className="btn"
                        onClick={() => {
                          setSelectedFilter('Payment Methods');
                        }}
                      >
                        {btnOptionPayment}
                      </div>
                    </div>
                  </div> */}
                </div>
              )}

              {selectedFilter === 'Type' && (
                <FilterItem2
                  title="Category"
                  value="All"
                  selected="true"
                  typeFilter={typeFilter}
                  funcValue={selectedType}
                  func={setSelectedType}
                  setBtn={setBtnOptionType}
                  setgoBack={setSelectedFilter}
                  setfilterFunc={setTypefilterSelected}
                  setClosefilter={setAiIndexOfferings}
                />
              )}

              {selectedFilter === 'Asset Class' && (
                <FilterItem2
                  title="Asset Class"
                  value="All"
                  selected="true"
                  typeFilter={assetClassFilter}
                  funcValue={selectedAssetClass}
                  func={setSelectedAssetClass}
                  setBtn={setBtnOptionAsset}
                  setgoBack={setSelectedFilter}
                  setfilterFunc={setAssetfilterSelected}
                  setClosefilter={setAiIndexOfferings}
                />
              )}

              {selectedFilter === 'Custody' && (
                <FilterItem2
                  title="Custody"
                  value="All"
                  selected="true"
                  typeFilter={custodyFilter}
                  funcValue={selectedCustody}
                  func={setSelectedCustody}
                  setBtn={setBtnOptionCustody}
                  setgoBack={setSelectedFilter}
                  setfilterFunc={setCustodyfilterSelected}
                  setClosefilter={setAiIndexOfferings}
                />
              )}

              {selectedFilter === 'Billing Type' && (
                <FilterItem2
                  title="Billing Type"
                  value="All"
                  selected="true"
                  typeFilter={billingFilter}
                  funcValue={selectedBilling}
                  func={setSelectedBilling}
                  setBtn={setBtnOptionBilling}
                  setgoBack={setSelectedFilter}
                  setfilterFunc={setBillingfilterSelected}
                  setClosefilter={setAiIndexOfferings}
                />
              )}

              {/* {selectedFilter === 'Comp Plan' && (
                <FilterItem2
                  title="Comp Plan"
                  value="All"
                  selected="true"
                  typeFilter={compFilter}
                  funcValue={selectedComp}
                  func={setSelectedComp}
                  setBtn={setBtnOptionCompPlan}
                  setgoBack={setSelectedFilter}
                />
              )}

              {selectedFilter === 'Leverage' && (
                <FilterItem2
                  title="Leverage"
                  value="All"
                  selected="true"
                  typeFilter={leverageFilter}
                  funcValue={selectedLeverage}
                  func={setSelectedLeverage}
                  setBtn={setBtnOptionLeverage}
                  setgoBack={setSelectedFilter}
                />
              )}

              {selectedFilter === 'Payment Methods' && (
                <FilterItem2
                  title="Payment Methods"
                  value="All"
                  selected="true"
                  typeFilter={paymentMethodsFilter}
                  funcValue={selectedPaymentMethods}
                  func={setSelectedPaymentMethods}
                  setBtn={setBtnOptionPayment}
                  setgoBack={setSelectedFilter}
                />
              )} */}
            </div>
          </div>
        ) : aitab == 'shop' ? (
          <div
            style={{
              flexDirection: 'row-reverse',
            }}
            className="aiindex-content"
          >
            <div
              className="left-ai-container"
              style={{
                position: 'relative',
                width: '100%',
                // height: '88%',
                padding: '0 2.5rem',
              }}
            >
              <ShopComponent />
            </div>
            <div
              className="right-ai-container"
              style={{
                display: aiIndexOfferings ? '' : 'none',
                // borderLeft: aiIndexOfferings ? 'none' : '0.5px solid #e7e7e7',
                // borderRight: aiIndexOfferings ? '0.5px solid #e7e7e7' : 'none',
              }}
            >
              <div
                className="right-ai-items"
                // style={{ display: aiIndexOfferings ? '' : 'none' }}
              >
                <FilterItem1
                  title="Companies"
                  value="All"
                  selected="true"
                  typeFilter={shopCompaniesFilter}
                  funcValue={shopCompanies}
                  func={setShopCompanies}
                />
                <FilterItem1
                  title="Billing Methods"
                  value="All"
                  selected="true"
                  typeFilter={shopBillingFilter}
                  funcValue={shopBilling}
                  func={setShopBilling}
                />
              </div>
            </div>
          </div>
        ) : (
          <div style={{ position: 'relative', width: '100%', height: '88%' }}>
            <div className="malls">
              <div className="malls-content">
                <div
                  style={{ width: '100%', height: '100%', overflowY: 'scroll' }}
                >
                  <Subscription />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default AiIndex;

const AIOpportunityCard = ({
  item_name,
  company_name,
  product_icon,
  product_description,
  cost_per_month,
  link,
  last_month_ROI,
  last_12_months_ROI,
  months_running,
  avg_drawdown,
  subscribers,
  company_website,
  _id,
}) => {
  const [aiCardOpen, setAiCardOpen] = useState(false);
  const { aitab, aiIndexOfferingSelected, setAiIndexOfferingSelected } =
    useAppContextDetails();
  return (
    <div
      className="ai-card"
      onClick={() => {
        setAiIndexOfferingSelected(_id);
        setAiCardOpen(!aiCardOpen);
      }}
      style={{
        boxShadow:
          aiIndexOfferingSelected == _id
            ? '0px 4px 4px rgba(0, 0, 0, 0.25)'
            : '',
        height: aiCardOpen ? '' : '15rem',
        padding: aiCardOpen ? '' : '1.5rem',
      }}
    >
      <div
        className="top-data"
        style={{
          height: aiCardOpen ? '' : '100%',
          maxHeight: aiCardOpen ? '' : '100%',
        }}
      >
        <div className="imgg-div">
          <img
            src={product_icon?.includes('http') ? product_icon : sample}
            alt=""
          />
        </div>
        <div className="top-rightt-divv">
          <div className="top-txt">
            <div>
              <div className="txtt1">{item_name}</div>
              <div>
                By{' '}
                <span
                  style={{
                    fontWeight: '600',
                    textDecorationLine: 'underline',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    if (!company_website?.includes('https://' || 'www.')) {
                      window.open('https://' + company_website, '_blank');
                    } else {
                      window.open(company_website, '_blank');
                    }
                  }}
                >
                  {company_name}
                </span>
              </div>
            </div>
            <div className="top-right-txt">
              {/* <div>
                <div className="txtt1">
                  {cost_per_month
                    ? '$' + Number(cost_per_month).toFixed(2)
                    : '$0'}
                </div>
                <div style={{ fontSize: '0.8rem' }}>USD Per Month</div>
              </div> */}
              <div
                className="gs-btn"
                style={{
                  color: '#343C5B',
                  border: '1px solid #EBEBEB',
                  background: 'white',
                  width: '45%',
                }}
              >
                Learn More
              </div>
              <div
                className="gs-btn"
                onClick={() => {
                  if (aitab !== 'businesses') {
                    if (!link?.includes('https://' || 'www.')) {
                      window.open('https://' + link, '_blank');
                    } else {
                      window.open(link, '_blank');
                    }
                  }
                }}
                style={{
                  background:
                    'linear-gradient(270deg, #81cbd7 0%, #3d4394 100%)',
                }}
              >
                Get Started
              </div>
              {/* <div className="center-line"></div> */}
            </div>
          </div>

          <p className="bottom-txt">{product_description}</p>
        </div>
      </div>
      <div
        className="bottom-data"
        style={{ display: aiCardOpen ? '' : 'none' }}
      >
        <div>
          <div>Last Month</div>
          <div className="txtt2">
            {last_month_ROI ? Number(last_month_ROI).toFixed(2) + '%' : '0%'}
          </div>
        </div>
        <div>
          <div>Last 12 Months</div>
          <div className="txtt2">
            {last_12_months_ROI
              ? Number(last_12_months_ROI).toFixed(2) + '%'
              : '0%'}
          </div>
        </div>
        <div>
          <div>Months Running</div>
          <div className="txtt3">{months_running} Months</div>
        </div>
        <div>
          <div>Avg Drawdown</div>
          <div className="txtt3">
            {avg_drawdown ? Number(avg_drawdown).toFixed(2) + '%' : ''}
          </div>
        </div>
        <div>
          <div>Subscribers</div>
          <div className="txtt3">{subscribers}</div>
        </div>
      </div>
    </div>
  );
};

const AIOpportunityCardLoading = () => {
  return (
    <div className="ai-card">
      <div className="top-data">
        <div className="imgg-div">
          <Skeleton circle />
        </div>
        <div className="top-rightt-divv">
          <div className="top-txt">
            <div>
              <div className="txtt1">
                <Skeleton width={120} />
              </div>
            </div>
            <div>
              <div className="txtt1">
                <Skeleton />
              </div>
              <div>
                <span
                  style={{
                    fontWeight: '600',
                    textDecorationLine: 'underline',
                    cursor: 'pointer',
                  }}
                >
                  <Skeleton />
                </span>
              </div>
            </div>
            <div className="top-right-txt">
              <div>
                <div className="txtt1">
                  <Skeleton />
                </div>
                <div style={{ fontSize: '0.8rem' }}>
                  <Skeleton />
                </div>
              </div>
              {/* <div className="gs-btn">
                <Skeleton />
              </div> */}
              <div className="center-line"></div>
            </div>
          </div>

          <p className="bottom-txt">
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </p>
        </div>
      </div>
      <div className="bottom-data">
        <div>
          <div>
            <Skeleton width={80} />
          </div>
          <div className="txtt2">
            <Skeleton width={80} />
          </div>
        </div>
        <div>
          <div>
            <Skeleton width={80} />
          </div>
          <div className="txtt2">
            <Skeleton width={80} />
          </div>
        </div>
        <div>
          <div>
            <Skeleton width={80} />
          </div>
          <div className="txtt3">
            <Skeleton width={80} />
          </div>
        </div>
        <div>
          <div>
            <Skeleton width={80} />
          </div>
          <div className="txtt3">
            <Skeleton width={80} />
          </div>
        </div>
        <div>
          <div>
            <Skeleton width={80} />
          </div>
          <div className="txtt3">
            <Skeleton width={80} />
          </div>
        </div>
      </div>
    </div>
  );
};

const FilterItem = ({
  title,
  value,
  selected,
  typeFilter,
  funcValue,
  func,
}) => {
  const [openOptions, setOpenOptions] = useState(false);

  useEffect(() => {
    setOpenOptions(false);
  }, [funcValue]);
  return (
    <div className="filterItem">
      <div
        onClick={() => {
          if (typeFilter) {
            setOpenOptions(!openOptions);
          }
        }}
      >
        <div>{title}</div>
        <div
          className="btn"
          style={{
            background: selected ? '' : 'white',
            fontWeight: selected ? '' : '500',
            color: '#343c5b ',
          }}
        >
          {funcValue ? funcValue : value}
        </div>
      </div>
      <div
        className="filterItemOptions"
        style={{ display: typeFilter && openOptions ? '' : 'none' }}
      >
        {typeFilter?.length > 0
          ? typeFilter?.map((eachOption) => {
              return <div onClick={() => func(eachOption)}>{eachOption}</div>;
            })
          : ''}
        {funcValue && <div onClick={() => func('')}>All</div>}
      </div>
    </div>
  );
};

const FilterItem1 = ({
  title,
  value,
  selected,
  typeFilter,
  funcValue,
  func,
}) => {
  const [openOptions, setOpenOptions] = useState(false);
  const [btnOption, setBtnOption] = useState('');

  useEffect(() => {
    setOpenOptions(false);
  }, [funcValue]);

  return (
    <div className="filterItem">
      <div
        onClick={() => {
          if (typeFilter) {
            setOpenOptions(!openOptions);
          }
        }}
      >
        <div>{title}</div>
        <div
          className="btn"
          style={{
            background: selected ? '' : 'white',
            fontWeight: selected ? '' : '500',
            color: '#343c5b ',
            overflowX: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {btnOption ? btnOption : funcValue ? funcValue : value}
        </div>
      </div>
      <div
        className="filterItemOptions"
        style={{ display: typeFilter && openOptions ? '' : 'none' }}
      >
        {typeFilter?.length > 0
          ? typeFilter?.map((eachOption) => {
              return title === 'Companies' ? (
                <div
                  onClick={() => {
                    setBtnOption(eachOption?.name);
                    func(eachOption?.email);
                  }}
                >
                  {eachOption?.profile_img && (
                    <img
                      src={eachOption?.profile_img}
                      alt=""
                      style={{
                        width: '10px',
                        height: '10px',
                        marginRight: '8px',
                        borderRadius: '50%',
                      }}
                    />
                  )}
                  <span>{eachOption?.name}</span>
                </div>
              ) : (
                <div
                  onClick={() => {
                    func(eachOption?.billingMethod);
                  }}
                >
                  {eachOption?.billingMethod ? eachOption?.billingMethod : ''}
                </div>
              );
            })
          : ''}
        {funcValue && (
          <div
            onClick={() => {
              setBtnOption('');
              func('');
            }}
          >
            All
          </div>
        )}
      </div>
    </div>
  );
};

const FilterItem2 = ({
  title,
  value,
  selected,
  typeFilter,
  funcValue,
  func,
  setBtn,
  setgoBack,
  setfilterFunc,
  setClosefilter
}) => {
  return (
    <div className="right-ai-items" style={{ padding: '0' }}>
      <div className="filterOption">
        <div style={{ height: '7rem' }}>
          <div>Currently Selected</div>
          <div className="eachDiv" style={{ marginTop: '1rem' }}>
            {funcValue ? funcValue : value}
          </div>
        </div>

        <div className="scrollableDiv" style={{ height: 'calc(100% - 11rem)' }}>
          <div>Other Options</div>
          {typeFilter?.length > 0
            ? typeFilter?.map((e, i) => {
                return (
                  <div
                    className="eachDiv"
                    key={i}
                    onClick={() => {
                      func(e);
                      setBtn(e);
                      setfilterFunc(true);
                      setClosefilter(false);
                      setgoBack('default');
                    }}
                  >
                    <div>{e}</div>
                  </div>
                );
              })
            : ''}
        </div>
        <div className="BtnsDiv" style={{ height: '4rem' }}>
          <div
            style={{
              background:
                'linear-gradient(270deg, rgb(129, 203, 215) 0%, rgb(61, 67, 148) 100%)',
              borderRadius: '35px',
            }}
            onClick={() => {
              setgoBack('default');
            }}
          >
            Go Back
          </div>
        </div>
      </div>
    </div>
  );
};
