import React, { useState, useEffect, useContext } from 'react';
import { BankContext } from '../../../context/Context';
import btc from '../../../static/images/coin-color/bitcoin.svg';
import usdt from '../../../static/images/coin-color/tether.svg';
import indices from '../../../static/images/world.svg';

function BondsControll({ setAssetTab }) {
  const [tabSelected, setTabSelected] = useState('Crypto');
  const { conractsObj, coinListObject } = useContext(BankContext);

  useEffect(() => {
    try {
      setAssetTab(tabSelected);
    } catch (error) {}
  }, [tabSelected, setAssetTab]);
  return (
    <>
      <div className="earn-intrest-controll">
        <div
          className={`tab-item ${tabSelected === 'Crypto'}`}
          onClick={() => setTabSelected('Crypto')}
        >
          <img className="coin-img" src={btc} alt="" />
          <div className="text-content">
            <h5>
              Cryptocurrencies
              <span>
                {
                  Object.entries(conractsObj).filter(
                    ([key, value], i) =>
                      coinListObject &&
                      coinListObject[key] &&
                      coinListObject[key]?.asset_type === 'Crypto'
                  ).length
                }
              </span>
            </h5>
            <h6>Assets Built On A Blockchain</h6>
          </div>
        </div>
        <div
          className={`tab-item ${tabSelected === 'stableCoin'}`}
          onClick={() => setTabSelected('stableCoin')}
        >
          <img className="coin-img" src={usdt} alt="" />
          <div className="text-content">
            <h5>
              StableCoins
              <span>
                {
                  Object.entries(conractsObj).filter(
                    ([key, value], i) =>
                      coinListObject &&
                      coinListObject[key] &&
                      coinListObject[key]?.stable_coin
                  ).length
                }
              </span>
            </h5>
            <h6>Digital Assets Pegged To An Analog Asset</h6>
          </div>
        </div>
        <div
          className={`tab-item ${tabSelected === 'Fiat'}`}
          onClick={() => setTabSelected('Fiat')}
        >
          <img className="coin-img" src={indices} alt="" />
          <div className="text-content">
            <h5>
              Fiat Currencies
              <span>
                {
                  Object.entries(conractsObj).filter(
                    ([key, value], i) =>
                      coinListObject &&
                      coinListObject[key] &&
                      coinListObject[key]?.asset_type === 'Fiat'
                  ).length
                }
              </span>
            </h5>
            <h6>Currencies Issued Central Banks</h6>
          </div>
        </div>
      </div>
    </>
  );
}

export default BondsControll;
