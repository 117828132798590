import React, { useContext, useRef } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';
import { useAppContextDetails } from '../../context/AppContext';
import { BankContext } from '../../context/Context';
import { NetWorthContext } from '../../context/NetWorthContext';
import { FormatCurrency, FormatNumber } from '../../utils/FunctionTools';

function BondsItem({ card, title }) {
  const history = useHistory();
  const ref = useRef();
  const outerRef = useRef();
  const { loadingAppBalance, assetCoin, liquidity } =
    useContext(NetWorthContext);
  const {
    coinNameObject,
    defaultPrecission,
    setChatOn,
    setBondDetail,
    coinListObject,
  } = useContext(BankContext);
  const { appCurrencySymbol, appCurrencyName } = useAppContextDetails();

  return card.tbd ? (
    <div className="bondsItem">
      <div className="coinPrice">
        <div className="img">
          {card.img && <img src={card.img} alt="" />}
          {loadingAppBalance ? <Skeleton height="42" width={300} /> : card.name}
        </div>
        <div className="title">
          {appCurrencySymbol}&nbsp;
          {FormatCurrency(card.value, appCurrencyName, defaultPrecission)}
        </div>
      </div>
      <div className="labels">
        <span>
          {!isNaN(card.assets) ? FormatNumber(card.assets, 0) : card.assets}{' '}
          {card.assetText}
        </span>
        <span>
          <span className="disabledValue">0.00% In 24Hrs</span>
        </span>
      </div>
      <div className="rates">
        <div className="ratesItem text-left">
          <div className="value">
            <span>
              {card.percent
                ? FormatNumber(card.percent, card.percent < 10 ? 2 : 1) + ' %'
                : 'TBD'}
            </span>
          </div>
          <div className="label">% Of Net-Worth</div>
        </div>
        <div
          className={`ratesItem text-center ${
            card.valueTwo ? '' : 'disabledValue'
          }`}
        >
          <div className="value">
            <span>
              {card.valueTwo
                ? appCurrencySymbol +
                  FormatCurrency(
                    card.valueTwo,
                    appCurrencyName,
                    defaultPrecission
                  )
                : 'TBD'}
            </span>
          </div>
          <div className="label">{card.labelTwo || 'Total Investment'}</div>
        </div>
        <div
          className={`ratesItem text-right ${
            card.valueThree ? '' : 'disabledValue'
          }`}
        >
          <div className="value">
            <span>
              {card.valueThree
                ? appCurrencySymbol +
                  FormatCurrency(
                    card.valueThree,
                    appCurrencyName,
                    defaultPrecission
                  )
                : 'TBD'}
            </span>
          </div>
          <div className="label">{card.labelThree || 'Total ROI'}</div>
        </div>
      </div>
    </div>
  ) : (
    <div
      className="bondsItem"
      ref={outerRef}
      onClick={() => {
        if (liquidity === 'Bonds') {
          setChatOn(true);
          setBondDetail({
            id: card.id,
            coin:
              coinNameObject &&
              coinNameObject[assetCoin] &&
              coinNameObject[assetCoin].coinSymbol,
          });
        }
      }}
    >
      <div className="coinPrice" id="id2">
        <div className="img">
          {card.img && <img src={card.img} alt="" />}
          {loadingAppBalance ? <Skeleton height="42" width={300} /> : card.name}
        </div>
        {assetCoin ? (
          card.coinSymbol ? (
            <div className="title">
              <span>
                {FormatCurrency(card.value, card.coinSymbol, defaultPrecission)}{' '}
                {card.coinSymbol}
              </span>
            </div>
          ) : (
            <div className="title">
              {assetCoin && liquidity ? (
                <span>
                  {FormatCurrency(
                    card.value,
                    coinNameObject &&
                      coinNameObject[assetCoin] &&
                      coinNameObject[assetCoin].coinSymbol,
                    defaultPrecission
                  )}
                </span>
              ) : (
                <span>
                  {FormatCurrency(
                    card.value /
                      ((coinNameObject &&
                        coinNameObject[assetCoin] &&
                        coinNameObject[assetCoin].price &&
                        coinNameObject[assetCoin].price[appCurrencyName]) ||
                        1),
                    coinNameObject &&
                      coinNameObject[assetCoin] &&
                      coinNameObject[assetCoin].coinSymbol,
                    defaultPrecission
                  )}
                </span>
              )}
              &nbsp;
              {coinNameObject &&
                coinNameObject[assetCoin] &&
                coinNameObject[assetCoin].coinSymbol}
            </div>
          )
        ) : (
          <div className="title">
            {loadingAppBalance ? (
              <Skeleton height={44} width={300} />
            ) : (
              <>
                {appCurrencySymbol}
                <span>
                  {FormatCurrency(
                    card.value,
                    appCurrencyName,
                    defaultPrecission
                  )}
                </span>{' '}
              </>
            )}
          </div>
        )}
      </div>
      <div className="labels">
        {loadingAppBalance ? (
          <Skeleton height={20} width={220} />
        ) : (
          <span ref={ref}>
            {!isNaN(card.assets) ? FormatNumber(card.assets, 0) : card.assets}{' '}
            {card.assetText}
            {liquidity === 'Bonds' ? (
              <>
                {' '}
                |{' '}
                <span
                  className="moreActions"
                  onClick={() => {
                    window.open(
                      `https://defi.markets/hash/${card?.id}`,
                      '_blank'
                    );
                    // console.log(card, 'card');
                  }}
                >
                  Staking Contracts Hash
                </span>
              </>
            ) : (
              ''
            )}
          </span>
        )}
        {loadingAppBalance ? (
          <Skeleton height={20} width={220} />
        ) : (
          <span>
            {assetCoin ? (
              card.coinSymbol ? (
                <b>
                  {appCurrencySymbol}
                  <span>
                    {FormatCurrency(
                      card.value *
                        (coinListObject &&
                          coinListObject[card.coinSymbol] &&
                          coinListObject[card.coinSymbol].price &&
                          coinListObject[card.coinSymbol].price[
                            appCurrencyName
                          ]),
                      appCurrencyName,
                      defaultPrecission
                    )}
                  </span>{' '}
                  |{' '}
                </b>
              ) : liquidity ? (
                <b>
                  {appCurrencySymbol}
                  <span>
                    {FormatCurrency(
                      card.value *
                        (coinNameObject &&
                          coinNameObject[assetCoin] &&
                          coinNameObject[assetCoin].price &&
                          coinNameObject[assetCoin].price[appCurrencyName]),
                      appCurrencyName,
                      defaultPrecission
                    )}
                  </span>{' '}
                  |{' '}
                </b>
              ) : (
                <b>
                  {appCurrencySymbol}
                  <span>
                    {FormatCurrency(
                      card.value,
                      appCurrencyName,
                      defaultPrecission
                    )}
                  </span>{' '}
                  |{' '}
                </b>
              )
            ) : (
              ''
            )}
            {card.native ? (
              <span>
                {FormatCurrency(
                  card.native,
                  coinNameObject[card?.name]?.coinSymbol
                )}{' '}
                {coinNameObject[card?.name]?.coinSymbol}
              </span>
            ) : (
              <span
                className={`${
                  (coinNameObject &&
                    card.name &&
                    coinNameObject[card.name] &&
                    coinNameObject[card.name]._24hrchange) ||
                  (assetCoin &&
                    coinNameObject[assetCoin] &&
                    coinNameObject[assetCoin]._24hrchange)
                    ? ''
                    : 'disabledValue'
                }`}
              >
                {Math.sign(
                  (coinNameObject &&
                    card.name &&
                    coinNameObject[card.name] &&
                    coinNameObject[card.name]._24hrchange) ||
                    (assetCoin &&
                      coinNameObject[assetCoin] &&
                      coinNameObject[assetCoin]._24hrchange)
                ) == 0
                  ? ''
                  : Math.sign(
                      (coinNameObject &&
                        card.name &&
                        coinNameObject[card.name] &&
                        coinNameObject[card.name]._24hrchange) ||
                        (assetCoin &&
                          coinNameObject[assetCoin] &&
                          coinNameObject[assetCoin]._24hrchange)
                    ) == -1
                  ? '-'
                  : '+'}
                {FormatNumber(
                  ((coinNameObject &&
                    card.name &&
                    coinNameObject[card.name] &&
                    coinNameObject[card.name]._24hrchange) ||
                    (assetCoin &&
                      coinNameObject[assetCoin] &&
                      coinNameObject[assetCoin]._24hrchange)) *
                    Math.sign(
                      (coinNameObject &&
                        card.name &&
                        coinNameObject[card.name] &&
                        coinNameObject[card.name]._24hrchange) ||
                        (assetCoin &&
                          coinNameObject[assetCoin] &&
                          coinNameObject[assetCoin]._24hrchange)
                    ) || 0,
                  2
                )}
                % In 24Hrs
              </span>
            )}
          </span>
        )}
      </div>
      <div className="rates">
        <div className="ratesItem text-left">
          <div className="value">
            {loadingAppBalance ? (
              <Skeleton height={30} width={120} />
            ) : (
              <span>
                {FormatNumber(card.percent, card.percent < 10 ? 2 : 1)} %
              </span>
            )}
          </div>
          <div className="label">
            {loadingAppBalance ? (
              <Skeleton height={20} width={140} />
            ) : (
              `% ${title}`
            )}
          </div>
        </div>
        <div className="ratesItem text-center disabledValue">
          <div className="value">
            {loadingAppBalance ? (
              <Skeleton height={30} width={120} />
            ) : (
              <span>{FormatNumber(0, 2)}%</span>
            )}
          </div>
          <div className="label">
            {loadingAppBalance ? (
              <Skeleton height={20} width={140} />
            ) : (
              'Capital Appreciation'
            )}
          </div>
        </div>
        <div className="ratesItem text-right disabledValue">
          <div className="value">
            {loadingAppBalance ? (
              <Skeleton height={30} width={120} />
            ) : (
              <span>{FormatNumber(0, 2)}</span>
            )}
          </div>
          <div className="label">
            {' '}
            {loadingAppBalance ? (
              <Skeleton height={20} width={140} />
            ) : (
              'Fixed Income'
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BondsItem;
