import {
  faLongArrowAltDown,
  faLongArrowAltUp,
  faSearch,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import Skeleton from 'react-loading-skeleton';
import { FormatCurrency } from '../../utils/FunctionTools';
import MarketDetail from '../MarketDetail/MarketDetail';
import ConrolHead from './ConrolHead';

import ieo from '../../static/images/ieo.svg';
import ifo from '../../static/images/ifo.svg';
import fullScreenIcon from '../../static/images/fullScreen.svg';
import fullScreenIconExit from '../../static/images/fullScreenExit.svg';
import { BankContext } from '../../context/Context';
import { ChatContext } from '../../context/ChatContext';
import LoginModal from '../LoginModalNew';

function IceMarkets() {
  const { setChatOn, setChatPathData } = useContext(ChatContext);
  const { defaultPrecission, email } = useContext(BankContext);
  const [selectedInvestment, setSelectedInvestment] = useState({});
  const [pathList, setPathList] = useState([]);
  const [fullPathList, setFullPathList] = useState([]);
  const [search, setSearch] = useState('');
  const [pathId, setPathId] = useState('');
  const [type, setType] = useState('');
  const [fullScreen, setFullScreen] = useState(false);

  useEffect(() => {
    Axios.get(`https://comms.globalxchange.io/coin/investment/path/get?`).then(
      ({ data }) => {
        setFullPathList(data.paths);
      }
    );
  }, []);

  useEffect(() => {
    setPathList([]);
    Axios.get(
      `https://comms.globalxchange.io/coin/investment/path/get?${
        selectedInvestment?.code
          ? 'investmentType=' + selectedInvestment?.code
          : ''
      }${type ? '&pathType=' + type : ''}`
    ).then(({ data }) => {
      setPathList(data.paths);
    });
  }, [selectedInvestment, type]);

  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [path, setPath] = useState('');

  const onLogin = () => {
    setChatOn(true);
    setChatPathData(path);
    setLoginModalOpen(false);
  };

  return (
    <>
      <ConrolHead
        setSelectedInvestment={(val) => {
          setPathId('');
          setSelectedInvestment(val);
        }}
        selectedInvestment={selectedInvestment}
        fullPathList={fullPathList}
        type={type}
      />
      <div
        className={`assetPlatformTable true ${fullScreen ? ' fullScreen' : ''}`}
      >
        <div className="assetTableControlls">
          <div
            className={`bt-asset ${type === ''}`}
            onClick={() => {
              setType('');
              setPathId('');
            }}
          >
            By Asset
          </div>
          <div
            className={`bt-asset ${type === 'contract'}`}
            onClick={() => {
              setType('contract');
              setPathId('');
            }}
          >
            <img src={ifo} alt="" />
          </div>
          <div
            className={`bt-asset ${type === 'offering'}`}
            onClick={() => {
              setType('offering');
              setPathId('');
            }}
          >
            <img src={ieo} alt="" />
          </div>
          <label className="searchWrapper">
            <input
              type="text"
              placeholder={`Search ${
                selectedInvestment?.name || 'All Markets'
              }`}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <FontAwesomeIcon icon={faSearch} />
          </label>
          <img
            onClick={() => setFullScreen(!fullScreen)}
            className="fullIcon"
            src={fullScreen ? fullScreenIconExit : fullScreenIcon}
            alt=""
          />
        </div>
        {pathId ? (
          <MarketDetail
            selectedInvestment={selectedInvestment}
            pathId={pathId}
            setPathId={setPathId}
          />
        ) : (
          <Scrollbars className="pathListScroll">
            <div className="headSticky">
              <div className="asset">Asset</div>
              <div className="banker">Banker</div>
              <div className="issuancePrice">Issuance Price</div>
              <div className="livePrice">Live Price</div>
            </div>
            {pathList.length
              ? pathList
                  .filter((path) =>
                    path?.token_profile_data?.coinName
                      ?.toLowerCase()
                      .includes(search.toLowerCase())
                  )
                  .map((path) => (
                    <div className="pathItem">
                      <div
                        className="asset"
                        onClick={() => {
                          window
                            .open(
                              `http://tokentreasury.com/${path.path_id}`,
                              '_newtab'
                            )
                            .focus();
                        }}
                      >
                        <img
                          className="coinIcon"
                          src={path?.token_profile_data?.coinImage}
                          alt=""
                        />
                        <span>{path?.token_profile_data?.coinName}</span>
                      </div>
                      <div
                        className="banker"
                        onClick={() => {
                          window
                            .open(
                              `http://tokentreasury.com/${path.path_id}`,
                              '_newtab'
                            )
                            .focus();
                        }}
                      >
                        {/* <img src={path?.token_profile_data?.coinImage} alt="" /> */}
                        <span>{path?.banker}</span>
                      </div>
                      <div
                        className="issuancePrice"
                        onClick={() => {
                          window
                            .open(
                              `http://tokentreasury.com/${path.path_id}`,
                              '_newtab'
                            )
                            .focus();
                        }}
                      >
                        {FormatCurrency(
                          path?.initial_price,
                          path?.asset,
                          defaultPrecission
                        )}{' '}
                        {path?.asset}
                      </div>
                      <div
                        className="livePrice"
                        onClick={() => {
                          window
                            .open(
                              `http://tokentreasury.com/${path.path_id}`,
                              '_newtab'
                            )
                            .focus();
                        }}
                      >
                        {FormatCurrency(
                          path?.token_price,
                          path?.asset,
                          defaultPrecission
                        )}{' '}
                        {path?.asset}
                      </div>
                      <div className="buttons">
                        <div className="btnSell">
                          SELL
                          <FontAwesomeIcon icon={faLongArrowAltDown} />
                        </div>
                        <div
                          className="btnBuy"
                          onClick={() => {
                            if (email) {
                              setChatOn(true);
                              setChatPathData(path);
                            } else {
                              setLoginModalOpen(true);
                              setPath(path);
                            }
                          }}
                        >
                          BUY
                          <FontAwesomeIcon icon={faLongArrowAltUp} />
                        </div>
                      </div>
                    </div>
                  ))
              : Array(7)
                  .fill('')
                  .map((path) => (
                    <div className="pathItem">
                      <div className="asset">
                        <Skeleton className="coinIcon" circle />
                        <Skeleton />
                      </div>
                      <div className="banker">
                        <Skeleton />
                      </div>
                      <div className="issuancePrice">
                        <Skeleton />
                      </div>
                      <div className="livePrice">
                        <Skeleton />
                      </div>
                      <div className="buttons">
                        <Skeleton width={100} />
                        <Skeleton width={100} />
                      </div>
                    </div>
                  ))}
          </Scrollbars>
        )}
        {loginModalOpen && (
          <LoginModal
            onClose={() => {
              setLoginModalOpen(false);
            }}
            onSuccess={onLogin}
          />
        )}
      </div>
    </>
  );
}

export default IceMarkets;
