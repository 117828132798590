import React, { useState, useContext, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';

import searchIcon from '../../static/images/search.svg';
import { InvestmentVaultContext } from '../../context/InvestmentVaultContext';
import { FormatCurrency } from '../../utils/FunctionTools';
import { BankContext } from '../../context/Context';

function VaultPageHead() {
  const {
    coinBalanceList,
    coinSelected,
    setCoinSelected,
    loading,
    showNativeValue,
    setShowNativeValue,
  } = useContext(InvestmentVaultContext);
  const { defaultPrecission } = useContext(BankContext);
  const [searchEnable, setSearchEnable] = useState(false);
  const [searchStr, setSearchStr] = useState('');
  const [searchResult, setSearchResult] = useState([]);

  useEffect(() => {
    if (!searchStr) {
      setSearchResult([]);
    } else {
      setSearchResult(
        coinBalanceList.filter(
          (coin) =>
            coin.coinName.toLowerCase().includes(searchStr.toLowerCase()) ||
            coin.coinSymbol.toLowerCase().includes(searchStr.toLowerCase())
        )
      );
    }
  }, [coinBalanceList, searchStr]);
  return (
    <div className="head">
      <div className="vault-n-balance">
        <div className="vault">
          {loading ? (
            <Skeleton width={150} />
          ) : (
            <>{coinSelected && coinSelected.coinName} Vault</>
          )}
        </div>
        <div className="balance">
          {loading ? (
            <Skeleton width={250} />
          ) : showNativeValue ? (
            coinSelected &&
            FormatCurrency(
              coinSelected.coinValue,
              coinSelected.coinSymbol,
              defaultPrecission
            )
          ) : (
            `$${
              coinSelected &&
              FormatCurrency(
                coinSelected.coinValueUSD,
                'USD',
                defaultPrecission
              )
            }`
          )}{' '}
          <small onClick={() => setShowNativeValue(!showNativeValue)}>
            {loading
              ? ''
              : showNativeValue
              ? `$${
                  coinSelected &&
                  FormatCurrency(
                    coinSelected.coinValueUSD,
                    'USD',
                    defaultPrecission
                  )
                }`
              : coinSelected &&
                FormatCurrency(
                  coinSelected.coinValue,
                  coinSelected.coinSymbol,
                  defaultPrecission
                )}
          </small>
        </div>
      </div>
      <div className="coin-select">
        {loading ? (
          <Skeleton width={250} height={60} />
        ) : searchEnable ? (
          ''
        ) : (
          <div className={`coin-wrap order-1 true`}>
            <img
              style={{ filter: 'none' }}
              src={coinSelected && coinSelected.coinImage}
              alt=""
            />
          </div>
        )}
        {loading ? (
          <div className="ml-2">
            <Skeleton width={60} height={60} />
          </div>
        ) : (
          <div className={`search-wrapper order-0  ${searchEnable}`}>
            <div className="serch-n-result">
              {searchEnable ? (
                <>
                  <div className="search">
                    <input
                      type="text"
                      value={searchStr}
                      onChange={(e) => setSearchStr(e.target.value)}
                      placeholder="Search Assets.io Vaults"
                    />
                    <span
                      className="serch-close"
                      onClick={() => {
                        setSearchEnable(!searchEnable);
                        setSearchStr('');
                      }}
                    >
                      ×
                    </span>
                  </div>
                  {searchResult.map((coin) => (
                    <div
                      className="search-res"
                      key={coin.coinName}
                      onClick={() => {
                        setCoinSelected(coin);
                        setSearchEnable(!searchEnable);
                      }}
                    >
                      <img
                        src={coin.coinImage}
                        alt=""
                        className="search-coin"
                      />
                      <div className="coin">{coin.coinName}</div>
                      <div className="value">
                        $
                        {FormatCurrency(
                          coin.price.USD,
                          'USD',
                          defaultPrecission
                        )}
                        <small className={`change ${coin._24hrchange < 0}`}>
                          ({FormatCurrency(coin._24hrchange)}%)
                        </small>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <img
                  className="search-icon"
                  src={searchIcon}
                  alt=""
                  onClick={() => {
                    setSearchEnable(!searchEnable);
                  }}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default VaultPageHead;
