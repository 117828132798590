import React, { useState, useEffect, useContext } from 'react';
import { useAppContextDetails } from '../../../context/AppContext';
import QRCode from 'qrcode.react';
import axios from 'axios';
import {
  formatterHelper,
  roundHelper,
  usdValueFormatter,
} from '../../../utils/FunctionTools';

import pasteIcon from '../../../static/images/paste.svg';
import { BankContext } from '../../../context/Context';

const WithdrawCryptoStepTwo = ({
  coinData,
  coinAddress,
  hashSuccessData,
  setHashSuccessData,
  addressInput,
  setAddressInput,
  withdrawalDetails,
  setWithdrawalDetails,
}) => {
  const { selectedCurrency, appCode, setBuySellStep, setActionFlow } =
    useAppContextDetails();
  const { email, token, profileId } = useContext(BankContext);
  const [isCopied, setIsCopied] = useState(false);
  const [hashValue, setHashValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [isCryptoFocused, setIsCryptoFocused] = useState(false);
  const [fiatInput, setFiatInput] = useState('');
  const [cryptoInput, setCryptoInput] = useState('');
  const [activeCoinBalance, setActiveCoinBalance] = useState('');
  const [isPriceLockEnabled, setIsPriceLockEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pathId, setPathId] = useState(null);

  const onPasteClick = async () => {
    const copiedText = await navigator.clipboard.readText();
    setHashValue(copiedText.trim());
  };

  const onCryptoInputChange = (input) => {
    console.log({ input, selectedCurrency: selectedCurrency?.price?.USD });

    if (input) {
      const updatedFiatValue =
        parseFloat(input || 0) * (selectedCurrency?.price?.USD || 0);

      console.log({ updatedFiatValue });
      setCryptoInput(input);
      setFiatInput(
        roundHelper(
          isNaN(parseFloat(updatedFiatValue)) ? '' : updatedFiatValue,
          'USD'
        )
      );
    } else {
      setCryptoInput('');
      setFiatInput('');
    }
  };

  const onProceedConfirm = () => {
    setLoading(true);

    axios
      .get(
        `https://comms.globalxchange.io/coin/vault/service/payment/paths/get?select_type=withdraw&to_currency=${selectedCurrency.coinSymbol}&from_currency=${selectedCurrency.coinSymbol}&paymentMethod=vault`
      )
      .then(({ data }) => {
        setPathId(data.paths[0].path_id);
        axios
          .post(
            `https://comms.globalxchange.io/coin/vault/service/trade/execute`,
            {
              token: token,
              email: email,
              app_code: appCode,
              profile_id: profileId,
              path_id: data.paths[0].path_id,
              from_amount: cryptoInput,
              stats: true,
              // identifier: `Withdraw ${activeWallet.coinSymbol} To 3Cdqy7Vx3DiP5jADkS23fmTFNtq98YmsGQ}`,
              // userWithdrawData: '3Cdqy7Vx3DiP5jADkS23fmTFNtq98YmsGQ',
              identifier: `Withdraw ${selectedCurrency.coinSymbol} To ${addressInput}`,
              userWithdrawData: addressInput,
              priceLock: isPriceLockEnabled,
              priceLock_currency: 'false',
            }
          )
          .then(({ data }) => {
            console.log(data, 'kjqdlwefclwebfc');
            setWithdrawalDetails(data);
            // onClose();
            setActionFlow('WithdrawCryptoStepThree');
            setLoading(false);
          });
      });
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: window.innerHeight - 150,
      }}
    >
      <div>
        <div className="breadcrumb">
          <span onClick={(e) => setActionFlow(null)}>Enter Address</span>
          &nbsp;&nbsp;{`>`}&nbsp;&nbsp;
          <span style={{ fontWeight: 700 }}>Enter Amount</span>
        </div>
        <div style={{ padding: '0px 22px' }}>
          <div
            style={{
              fontWeight: 'bold',
              fontSize: '22px',
              paddingBottom: '31px',
            }}
          >
            Step 2: Enter Amount
          </div>
          <div>
            Enter The {selectedCurrency.coinSymbol} Amount That You Want To Send
          </div>
        </div>
      </div>
      <div style={{ padding: '0px 22px' }}>
        <div style={{ padding: '40px 0px' }}>
          <div className="balanceText">
            {formatterHelper(
              selectedCurrency?.coinValue || 0,
              selectedCurrency?.coinSymbol
            )}
            <div className="balanceTextCoin">
              &nbsp;
              {selectedCurrency.coinSymbol}
            </div>
          </div>
          <div className="usdBalance">
            {usdValueFormatter.format(selectedCurrency?.coinValueUSD)}
          </div>
        </div>

        <div className="inputForm">
          <div className="inputContainer">
            <input
              className={isCryptoFocused ? 'input focusedText' : 'input'}
              placeholder={formatterHelper('0.00', selectedCurrency.coinSymbol)}
              onChange={(e) => onCryptoInputChange(e.target.value)}
              value={cryptoInput}
              onFocus={() => setIsCryptoFocused(true)}
              // placeholderTextColor="#878788"
            />
            <div
              className={
                isCryptoFocused ? 'cryptoName focusedText' : 'cryptoName'
              }
            >
              {selectedCurrency.coinSymbol}
            </div>
          </div>
          <div className="divider" />
          <div className="inputContainer">
            <div className="input">{fiatInput ? fiatInput : 0.0}</div>
            <div
              className={
                isCryptoFocused ? 'cryptoName focusedText' : 'cryptoName'
              }
            >
              USD
            </div>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
          onClick={onPasteClick}
        >
          <div style={{ paddingLeft: '10px' }}>Turn On PriceLock Insurance</div>
          <div className="togglecomp">
            <div className="toggle-div">
              <input
                className="tgl1"
                type="checkbox"
                id="switch"
                onClick={(e) => setIsPriceLockEnabled(e.target.checked)}
                checked={isPriceLockEnabled}
              />
              <label className="tgl2" htmlFor="switch" />
            </div>
          </div>
        </div>
      </div>
      <div style={{ padding: '0px 22px' }}>
        <div
          onClick={(e) => onProceedConfirm()}
          // onClick={(e) => setActionFlow('WithdrawCryptoStepThree')}
          className="submitButton"
          style={{ background: '#464B4E', width: '100%' }}
        >
          <div style={{ color: 'white', fontWeight: 'bold' }}>
            {!loading ? 'Proceed To Confirmation' : 'Loading ...'}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithdrawCryptoStepTwo;
