import React from 'react';
import OtpInput from 'react-otp-input';

function GetStartedSetConfirmOtp({
  logo,
  mailId,
  pin,
  setPin,
  resendMailNow,
  verifyEmail,
}) {
  return (
    <div className="stepWrapper stepForm">
      <img src={logo} alt="assetLogo" className="assetLogo" />
      <div className="stepDesc">Enter The Code That Was Sent To {mailId}</div>
      <OtpInput
        containerStyle="otp-input-wrapper"
        value={pin}
        onChange={(pinVal) => setPin(pinVal)}
        numInputs={6}
        separator={<span> </span>}
        inputStyle="otp-input"
      />
      <div className="btns">
        <div className="btnBig" onClick={() => resendMailNow()}>
          Resend Code
        </div>
        <div
          className="btnNext"
          onClick={() => {
            verifyEmail();
          }}
        >
          Next
        </div>
      </div>
    </div>
  );
}

export default GetStartedSetConfirmOtp;
