import React, { useContext, useState } from 'react';
import classNames from './vaultSelectModal.module.scss';
import { FormatCurrency, FormatNumber } from '../../utils/FunctionTools';
import Skeleton from 'react-loading-skeleton';
import { VaultPageContext } from '../../context/VaultPageContext';
import sharesFullLogo from '../../static/images/logos/sharesFullLogo.svg';
import { useShareTokensVaultsList } from '../../queryHooks';

function VaultSelectSharesModal({
  onClose = () => {},
  onSuccess = () => {},
  email,
  vaultSelected,
  setVaultSelected,
}) {
  const [vaultSearch, setVaultSearch] = useState('');

  const { data: vaultsList = [], isLoading: vaultListLoading } =
    useShareTokensVaultsList(email);
  return (
    <div className={classNames.vaultSelectModal}>
      <div
        className={classNames.overlayClose}
        onClick={() => {
          try {
            onClose();
          } catch (error) {}
        }}
      />
      <div className={classNames.vaultSelectCard}>
        <div className={classNames.header}>
          <img src={sharesFullLogo} alt="" className={classNames.headIcon} />
        </div>
        <input
          type="text"
          name="search"
          className={classNames.search}
          style={{
            paddingLeft: '40px',
          }}
          placeholder="Search ShareToken Vaults..."
          onChange={(e) => setVaultSearch(e.target.value)}
          value={vaultSearch}
        />
        <div className={classNames.gridView}>
          {vaultListLoading
            ? Array(10)
                .fill('')
                .map((_, i) => (
                  <div key={i} className={classNames.tableItem}>
                    <Skeleton className={classNames.img} circle />
                    <Skeleton className={classNames.name} width={100} />
                    <Skeleton className={classNames.balance} width={80} />
                  </div>
                ))
            : vaultsList?.vaultsData
                ?.filter(
                  (vault) =>
                    vault?.tokens[0]?.token_profile_data?.coinSymbol
                      .toLowerCase()
                      .includes(vaultSearch.toLowerCase()) ||
                    vault?.tokens[0]?.token_profile_data?.coinName
                      .toLowerCase()
                      .includes(vaultSearch.toLowerCase())
                )
                .map((vault) => (
                  <div
                    key={vault.coinSymbol}
                    className={`${classNames.tableItem} ${
                      classNames[vault === vaultSelected]
                    }`}
                    onClick={() => {
                      setVaultSelected(vault);
                      onSuccess();
                    }}
                  >
                    <img
                      className={classNames.img}
                      src={vault?.tokens[0]?.token_profile_data?.coinImage}
                      alt=""
                    />
                    <div className={classNames.name}>
                      {vault?.tokens[0]?.token_profile_data?.coinSymbol}
                    </div>
                    <div className={classNames.balance}>
                      {FormatNumber(vault?.tokens[0]?.value, 0)} Shares
                    </div>
                  </div>
                ))}
          <div className={`${classNames.tableItem} ${classNames.hide}`} />
          <div className={`${classNames.tableItem} ${classNames.hide}`} />
        </div>
      </div>
    </div>
  );
}

export default VaultSelectSharesModal;
