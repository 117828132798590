import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import classNames from './marketsPage.module.scss';
import tradeStream from '../../static/images/sidebarIcons/tradeStream.svg';
import searchIcon from '../../static/images/search.svg';
import { useShareTokensDetail } from '../../queryHooks';
import { SIDEBAR_FOOTERS, SWAP_ASSET_CLASSES } from '../../config/constants';
import { BankContext } from '../../context/Context';
import { GetSortOrder } from '../../utils/FunctionTools';
import MarketAssetDetail from '../../components/MarketAssetDetail';
import TerminalsSidebar from '../../components/TerminalsSidebar';
import LoginModal from '../../components/LoginModalNew';
import Layout from '../../Layout/Layout';
import MarketTableCrypto from './components/MarketTableCrypto';
import MarketTableForex from './components/MarketTableForex';
import { BondContractDetails } from './components/BondContractDetails';
import { useMarketContext } from '../../context/MarketContext';

import fulllogo from '../../static/images/sidebar-icons/tokenswapIcon.svg';
import ddown from '../../static/images/malls/ddown.svg';
import '../AcademyPage/academy.scss';

import banner1 from '../../assets/images/swapmarkets/swapbanner1.png';
import banner2 from '../../assets/images/swapmarkets/swapbanner2.png';
import banner3 from '../../assets/images/swapmarkets/swapbanner3.png';
import banner4 from '../../assets/images/swapmarkets/swapbanner4.png';
import { useAppContextDetails } from '../../context/AppContext';

function SwapPage() {
  const history = useHistory();
  const { assetClassName, coin } = useParams();
  const [assetClass, setAssetClass] = useState(SWAP_ASSET_CLASSES[0]);
  const [index, setIndex] = useState(2);
  const { email, sidebarCollapse } = useContext(BankContext);
  const { streamOpen, setStreamOpen } = useMarketContext();
  const [coinSelected, setCoinSelected] = useState();
  const [search, setSearch] = useState('');
  const [selectedSidebarMenu, setSelectedSidebarMenu] = useState('Crypto');
  const ref = useRef();

  const { swaptab, setSwaptab } = useAppContextDetails();

  const { data: shareToken } = useShareTokensDetail(coin);

  useEffect(() => {
    const classSelected = SWAP_ASSET_CLASSES.filter(
      (asCls) => asCls.name === assetClassName
    );
    if (classSelected[0]) setAssetClass(classSelected[0]);
  }, [assetClassName]);

  //   useEffect(() => {
  //     if (assetClassName === 'shares' && shareToken?.token_profile_data) {
  //       setCoinSelected(shareToken?.token_profile_data);
  //     }
  //   }, [coin, shareToken, assetClassName]);

  useEffect(() => {
    ref.current.addEventListener('scroll', scrollHandle);
    return () => {
      ref.current.removeEventListener('scroll', scrollHandle);
    };
  }, []);
  const [active, setActive] = useState(false);
  const scrollHandle = (event) => {
    setActive(
      Boolean(
        event?.path[0]?.scrollTop >=
          event?.path[0].childNodes[1].childNodes[0].childNodes[1]?.offsetTop
      )
    );
  };

  function selectTable() {
    switch (assetClass?.name) {
      case 'crypto':
        return (
          <MarketTableCrypto
            streamOpen={streamOpen}
            setCoinSelected={setCoinSelected}
            assetClass={assetClass}
            active={active}
            search={search}
          />
        );
      case 'fiat':
        return (
          <MarketTableForex
            streamOpen={streamOpen}
            setCoinSelected={setCoinSelected}
            assetClass={assetClass}
            search={search}
          />
        );
      //   case 'shares':
      //     return (
      //       <MarketTableShares
      //         streamOpen={streamOpen}
      //         setCoinSelected={setCoinSelected}
      //         assetClass={assetClass}
      //         search={search}
      //       />
      //     );
      //   case 'bonds':
      //     return (
      //       <MarketTableBonds
      //         streamOpen={streamOpen}
      //         setCoinSelected={setCoinSelected}
      //         assetClass={assetClass}
      //         active={active}
      //         search={search}
      //       />
      //     );
      //   case 'moneyMarkets':
      //     return (
      //       <MarketTableMoneyMarkets
      //         streamOpen={streamOpen}
      //         setCoinSelected={setCoinSelected}
      //         assetClass={assetClass}
      //         active={active}
      //         search={search}
      //       />
      //     );
      //   case 'stakingVaults':
      // return <CreateBond />;

      default:
        return '';
    }
  }

  const [loginModalOpen, setLoginModalOpen] = useState(false);

  if (!email) {
    history.push('/');
  }

  return (
    <Layout active="swap" className={classNames.layoutManagement} hideFooter>
      <div className={classNames.marketsPage}>
        <div className={classNames.pageWrap} ref={ref}>
          <div
            className="academy"
            style={{ position: 'relative', height: '12%' }}
          >
            <div className="academy-navbar" style={{ height: '66.66%' }}>
              {/* <div>
            <img src={img1} alt="" />
          </div> */}
              <div className="academy-icon" style={{ width: '15%' }}>
                <img
                  src={fulllogo}
                  alt=""
                  style={{ width: '100%', height: '100%' }}
                />
              </div>
              <div className="search-academy">
                {/* <div className="all">
                  <p>Assets</p>
                  <div className="arroww" style={{ cursor: 'pointer' }}>
                    <img src={ddown} alt="" />
                  </div>
                </div> */}
                <div className="im">
                  <input
                    type="text"
                    placeholder="Im Looking For..."
                    onChange={(event) => {
                      setSearch(event?.target?.value);
                    }}
                  />
                  <div className="arroww" style={{ cursor: 'pointer' }}>
                    <img src={searchIcon} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-space" style={{ height: '33.34%' }}>
              <div
                className="optionss"
                style={{
                  width: '40%',
                  justifyContent: 'flex-start',
                  gap: '4rem',
                }}
              >
                {SWAP_ASSET_CLASSES.sort(GetSortOrder('marketOrder')).map(
                  (assetClassCard, i) => (
                    <div
                      key={i}
                      className={`each-option ${
                        swaptab === assetClassCard.name
                          ? 'each-option-selected'
                          : ''
                      }`}
                      // style={{
                      //   borderBottom:
                      //     swaptab === assetClassCard.name
                      //       ? '2.5px solid #9FD6DF'
                      //       : '',
                      //   //   academytab === 'customers' ? '2px solid #08152D' : '',
                      //   fontWeight:
                      //     swaptab === assetClassCard.name ? '600' : '',
                      // }}
                      onClick={() => {
                        if (assetClassCard.marketEnable) {
                          console.log(assetClassCard.name, 'assetclass');
                          setAssetClass(assetClassCard);
                          history.push(`/swap/${assetClassCard.name}`);
                          setSwaptab(assetClassCard.name);
                        }
                      }}
                    >
                      <div>
                        <p>
                          {assetClassCard.name == 'fiat'
                            ? 'Forex'
                            : assetClassCard.name.charAt(0).toUpperCase() +
                              assetClassCard.name.slice(1)}
                        </p>
                      </div>
                    </div>
                  )
                )}

                {/* <div
                  className="each-option"
                  style={{
                    // borderBottom:
                    //   academytab === 'affiliates' ? '2px solid #08152D' : '',
                    fontWeight: swaptab === 'Forex' ? '600' : '',
                  }}
                  onClick={() => setSwaptab('Forex')}
                >
                  <div>
                    <p>Forex</p>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          {/* <div className={classNames.assetClassWrap}>
            <div className={classNames.profileSearch}>
              <label className={classNames.assetFinder}>
                <img src={searchIcon} alt="Search" />
                <input
                  type="text"
                  placeholder="Asset Finder"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </label>
            </div>
            <div className={classNames.assetClass}>
              {SWAP_ASSET_CLASSES.sort(GetSortOrder('marketOrder')).map(
                (assetClassCard, i) => (
                  <div
                    key={i}
                    className={`${classNames.assetClassCard} ${
                      classNames[assetClassCard === assetClass]
                    } ${classNames}`}
                    onClick={() => {
                      if (assetClassCard.marketEnable) {
                        console.log(assetClassCard.name, 'assetclass');
                        setAssetClass(assetClassCard);
                        history.push(`/swap/${assetClassCard.name}`);
                      }
                    }}
                  >
                    <img
                      src={assetClassCard.icon}
                      alt=""
                      className={classNames.icon}
                    />
                  </div>
                )
              )}
            </div>
            <div
              className={`${classNames.streamOpen} ${
                classNames[Boolean(streamOpen)]
              }`}
              onClick={() => setStreamOpen((prev) => !prev)}
            >
              {streamOpen ? (
                <svg
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.6317 9L17.6617 1.96993C18.1127 1.51912 18.1128 0.789171 17.6618 0.338256C17.2109 -0.112694 16.481 -0.112799 16.0301 0.338221L9 7.36829L1.96993 0.338256C1.51913 -0.112658 0.78914 -0.112799 0.338226 0.338186C-0.112724 0.789101 -0.112759 1.51895 0.338226 1.9699L7.36822 9L0.33819 16.03C-0.111705 16.4799 -0.111705 17.2119 0.33819 17.6617C0.78907 18.1127 1.51898 18.1128 1.9699 17.6617L8.99993 10.6316L16.03 17.6617C16.4808 18.1126 17.2108 18.1127 17.6616 17.6617C18.1126 17.2108 18.1127 16.4809 17.6617 16.0299L10.6317 9Z"
                    fill="#1A2663"
                  />
                  <path
                    d="M10.6317 9L17.6617 1.96993C18.1127 1.51912 18.1128 0.789171 17.6618 0.338256C17.2109 -0.112693 16.481 -0.112799 16.0301 0.338221L9 7.36825V10.6317L16.03 17.6618C16.4808 18.1127 17.2108 18.1128 17.6617 17.6618C18.1127 17.2109 18.1128 16.481 17.6617 16.03L10.6317 9Z"
                    fill="#1A2663"
                  />
                </svg>
              ) : (
                <img src={tradeStream} alt="" />
              )}
            </div>
          </div> */}
          <div className={classNames.pageContent}>
            {coinSelected?.coinSymbol || coin ? (
              <MarketAssetDetail
                coin={coinSelected}
                coinSymbol={coin}
                onClose={() => {
                  setCoinSelected();
                  history.push(`/swap/${assetClass.name}`);
                }}
                assetClass={assetClass}
                streamOpen={streamOpen}
                setStreamOpen={setStreamOpen}
              />
            ) : (
              <div className={classNames.tableArea}>
                <div className={classNames.topBlank}>
                  <div
                    className={classNames.assetClassCard}
                    style={{ display: 'none' }}
                  >
                    <img
                      src={assetClass.icon}
                      alt={assetClass.name}
                      className={classNames.assetLogo}
                    />
                    <div className={classNames.assetActions}>
                      <div
                        className={classNames.action}
                        onClick={() => !email && setLoginModalOpen(true)}
                      >
                        Buy {assetClass.label}
                      </div>
                      <div className={classNames.action}>
                        Speak To An Advisor
                      </div>
                    </div>
                  </div>
                  <div className={classNames.cardList}>
                    {/* {Array(5)
                      .fill('')
                      .map(({}, i) => (
                        <div key={i} className={classNames.card} />
                      ))} */}

                    <div className={classNames.card}>
                      <img src={banner1} alt="banner1" />
                    </div>
                    <div className={classNames.card}>
                      <img src={banner2} alt="banner1" />
                    </div>
                    <div className={classNames.card}>
                      <img src={banner3} alt="banner1" />
                    </div>
                    <div className={classNames.card}>
                      <img src={banner4} alt="banner1" />
                    </div>
                  </div>
                </div>
                {selectTable()}
              </div>
            )}
          </div>
        </div>
        <div
          className={`${classNames.sidebarArea} ${
            classNames[Boolean(streamOpen)]
          }`}
          style={{ maxHeight: '100%' }}
        >
          <div
            className={classNames.sidebarContent}
            style={{ maxHeight: '100%' }}
          >
            {/* {assetClassName === 'shares' && coin ? (
              <TerminalsSidebar
                shareToken={shareToken}
                streamOpen={streamOpen}
                setStreamOpen={setStreamOpen}
              />
            ) : assetClassName === 'bonds' &&
              selectedSidebarMenu === 'Bonds' ? (
              <BondContractDetails
                index={index}
                setIndex={setIndex}
                setSelectedSidebarMenu={setSelectedSidebarMenu}
              />
            ) : ( */}
            <img
              className={classNames.fullLogo}
              src={SIDEBAR_FOOTERS[index].fullLogo}
              alt=""
            />
            {/* )} */}
          </div>

          {/* {(assetClassName === 'shares' && coin) ||
          (assetClassName === 'bonds' && selectedSidebarMenu === 'Bonds') ? (
            ''
          ) : ( */}
          <div className={classNames.sidebarFooter}>
            {SIDEBAR_FOOTERS.map((menu, i) => (
              <div
                className={`${classNames.footerMenu} ${
                  classNames[i === index]
                } ${menu.disable && classNames.disable}`}
                onClick={() => {
                  !menu.disable && setIndex(i);
                  setSelectedSidebarMenu(menu.label);
                }}
              >
                <img src={menu.icon} alt="" />
                <span>{menu.label}</span>
              </div>
            ))}
          </div>
          {/* )} */}
        </div>
        {loginModalOpen && (
          <LoginModal
            onClose={() => setLoginModalOpen(false)}
            onSuccess={() => setLoginModalOpen(false)}
          />
        )}
      </div>
    </Layout>
  );
}

export default SwapPage;
