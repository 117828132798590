import React from 'react';
import { useCoinContextData } from '../../../context/CoinContext';
import { WithdrawalForexPathId } from './api';

export const WithdrawForexBreadcrumb = ({ data }) => {
  const {
    setWithdrawForexStep,
    setCoinAction,
    setWithdrawForexCurrency,
    setWithdrawForexCountry,
    setWithdrawForexPaymentMethod,
    setWithdrawForexOtcDesk,
    withdrawForexCurrency,
    selectedCoin,
    withdrawForexCountry,
    withdrawForexPaymentMethod,
    withdrawForexOtcDesk,
    setForexPathId,
    setWithdrawForexEnteredAmount,
  } = useCoinContextData();

  const pathIdforex = () => {
    let obj = {
      select_type: 'withdraw',
      to_currency: withdrawForexCurrency,
      from_currency: selectedCoin.coinSymbol,
      country: withdrawForexCountry,
      paymentMethod: withdrawForexPaymentMethod,
      banker: withdrawForexOtcDesk,
    };
    WithdrawalForexPathId(obj).then((response) => {
      let result = response?.data?.paths?.[0]?.path_id;
      setForexPathId(result);
    });
  };

  function switchBreadCrumb(event) {
    let selectedText = event.target.textContent;
    if (selectedText.includes('Withdraw') || selectedText.includes('Actions')) {
      setCoinAction(['Menu']);
      setWithdrawForexStep('1');
      setWithdrawForexCurrency('');
      setWithdrawForexCountry('');
      setWithdrawForexPaymentMethod('');
      setWithdrawForexOtcDesk('');
    } else if (selectedText.includes('Receiving Currency')) {
      setWithdrawForexStep('1');
      setWithdrawForexCurrency('');
      setWithdrawForexCountry('');
      setWithdrawForexPaymentMethod('');
      setWithdrawForexOtcDesk('');
    } else if (selectedText.includes('Bank Account')) {
      setWithdrawForexStep('2');
      setWithdrawForexCountry('');
      setWithdrawForexPaymentMethod('');
      setWithdrawForexOtcDesk('');
    } else if (selectedText.includes('Select OTCDesk')) {
      setWithdrawForexStep('3');
      setWithdrawForexPaymentMethod('');
      setWithdrawForexOtcDesk('');
    } else if (selectedText.includes('Enter Amount')) {
      setWithdrawForexStep('4');
      setWithdrawForexOtcDesk('');
      setWithdrawForexEnteredAmount('');
    } else if (selectedText.includes('Withdrawal Confirmation')) {
      setWithdrawForexStep('5');
      setForexPathId('');
      setWithdrawForexOtcDesk('');
      pathIdforex();
    }
  }

  return (
    <div className="d-flex">
      {data.map((d, i) => {
        return (
          <p
            key={i}
            style={{
              marginTop: '5px',
              fontWeight: data.length - 1 == i ? '700' : '',
              fontSize: '11px',
              cursor: 'pointer',
            }}
            id={d.clickable}
            onClick={(e) => switchBreadCrumb(e)}
          >
            <span
              style={{
                textDecoration: data.length - 1 == i ? 'underline' : 'none',
                textUnderlineOffset: data.length - 1 == i ? '3px' : '0px',
              }}
              id={d.clickable}
            >
              {d.value}
            </span>
          </p>
        );
      })}
    </div>
  );
};
