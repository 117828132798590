import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useCoinContextData } from '../../../context/CoinContext';
import './addAccountStyles.scss';

const BankType = () => {
  const {
    addAccountStepName,
    setAddAccountStepName,
    selectedBankType,
    setSelectedBankType,
  } = useCoinContextData();

  const [allBankType, setAllBankType] = useState([]);
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState('');

  useEffect(() => {
    setLoading(true);
    axios
      .get(`https://accountingtool.apimachine.com/getlist-of-institutes`)
      .then(({ data }) => {
        if (data.status) {
          setAllBankType(data.data);
          setLoading(false);
        }
      });
  }, []);

  return (
    <>
      <div className="addAccountContainer">
        <div>
          <span className="prevStep" onClick={(e) => setAddAccountStepName('')}>
            Payment Type
          </span>{' '}
          {`>`}{' '}
          <span style={{ textDecoration: 'underline', fontWeight: 'bold' }}>
            Bank
          </span>
        </div>
        <div className="stepTitle">What Bank Is It?</div>
        <div className="selectionWrapper">
          <input
            placeholder="Search Banks..."
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          <div style={{ overflowY: 'scroll', height: '70vh' }}>
            {!loading
              ? allBankType
                  .filter((o) =>
                    o.institute_name.toLowerCase().includes(query.toLowerCase())
                  )
                  .map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="listItem"
                        onClick={(e) => {
                          setSelectedBankType(item);
                          setAddAccountStepName('accountHolder');
                        }}
                      >
                        <img src={item.profile_image} alt="" />
                        <div>{item.institute_name}</div>
                      </div>
                    );
                  })
              : Array(10)
                  .fill(' ')
                  .map((item, index) => {
                    return (
                      <div className="listItem" key={index}>
                        <Skeleton width={20} height={20} />
                        <Skeleton
                          width={100}
                          height={20}
                          style={{ marginLeft: 10 }}
                        />
                      </div>
                    );
                  })}
          </div>
        </div>
      </div>
    </>
  );
};

export default BankType;
