import React from 'react';
import CoinsData from '../MobileLayoutComponents/CoinsData/CoinsData';

import searchIcon from '../../static/images/mobile/search.svg';
import cryptoIcon from '../../static/images/mobile/crypto.svg';
import forexIcon from '../../static/images/mobile/forex.svg';
import { useContext } from 'react';
import { AppContext } from '../../context/AppContext';
import { useState } from 'react';
import SwitcherSearch from './SwitcherSearch';
import BuySellFlow from '../MobileLayoutComponents/BuySellFlow';
import { useEffect } from 'react';

const MarketCryptoForex = () => {
  const {
    selectedSubMenu,
    setSelectedSubMenu,
    buySellStep,
    setBuySellStep,
    selectedOperation,
    setSelectedAsset,
  } = useContext(AppContext);
  const [openSearch, setOpenSearch] = useState(false);

  useEffect(() => {
    setSelectedAsset(null);
  }, []);

  useEffect(() => {
    if (selectedOperation !== null) {
      if (selectedOperation?.operation === 'Buy') {
        setBuySellStep('BuyStepOne');
      } else {
        setBuySellStep('SellStepOne');
      }
    }
  }, [selectedOperation]);

  useEffect(() => {
    setBuySellStep(null);
  }, [selectedSubMenu]);

  // useEffect(() => {
  //   console.log(selectedOperation, 'wkjbckjwebfkjw');
  // }, [selectedOperation]);

  return (
    <div>
      {!buySellStep ? (
        <div>
          {!openSearch ? (
            <>
              <div className="switcherWrapper">
                <div className="iconDiv">
                  <img
                    className="divImg"
                    src={searchIcon}
                    alt=""
                    onClick={(e) => setOpenSearch(true)}
                  />
                </div>
                <div className="iconWrapper">
                  <div
                    className="iconDiv"
                    onClick={(e) => setSelectedSubMenu('Crypto')}
                  >
                    <img className="divImg" src={cryptoIcon} alt="" />
                  </div>
                  <div
                    style={{
                      fontSize: '10px',
                      fontWeight: 'bold',
                      paddingTop: '5px',
                    }}
                  >
                    {selectedSubMenu === 'Crypto' ? 'Crypto' : null}
                  </div>
                </div>
                <div className="iconWrapper">
                  <div
                    className="iconDiv"
                    onClick={(e) => setSelectedSubMenu('Forex')}
                  >
                    <img className="divImg" src={forexIcon} alt="" />
                  </div>
                  <div
                    style={{
                      fontSize: '10px',
                      fontWeight: 'bold',
                      paddingTop: '5px',
                    }}
                  >
                    {selectedSubMenu === 'Forex' ? 'Forex' : null}
                  </div>
                </div>
              </div>
              <div style={{ overflowY: 'scroll', height: '80vh' }}>
                <CoinsData />
              </div>
            </>
          ) : (
            <SwitcherSearch
              path={'MarketCrypto'}
              listData={[
                { icon: cryptoIcon, name: 'Crypto' },
                { icon: forexIcon, name: 'Forex' },
              ]}
              setSelectedData={setSelectedSubMenu}
              setOpenSearch={setOpenSearch}
            />
          )}
        </div>
      ) : (
        <BuySellFlow />
      )}
    </div>
  );
};

export default MarketCryptoForex;
