import React, { useEffect } from 'react';
import { useCoinContextData } from '../../../context/CoinContext';
import PaymentType from './PaymentType';
import BankType from './BankType';
import CountryType from './CountryType';
import AccountHolder from './AccountHolder';
import AccountDetails from './AccountDetails';

export const AddBankAccount = () => {
  const { addAccountStepName } = useCoinContextData();

  useEffect(() => {
    conditionalSections();
  }, [addAccountStepName]);

  const conditionalSections = () => {
    switch (addAccountStepName) {
      case 'selectBank':
        return <BankType />;

      case 'selectCountry':
        return <CountryType />;

      case 'accountHolder':
        return <AccountHolder />;

      case 'accountDetails':
        return <AccountDetails />;

      default:
        return <PaymentType />;
    }
  };

  return conditionalSections();
};
