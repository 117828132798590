import React, { useState, useEffect, useContext } from 'react';
import { useAppContextDetails } from '../../../context/AppContext';
import QRCode from 'qrcode.react';
import axios from 'axios';

import pasteIcon from '../../../static/images/paste.svg';
import { BankContext } from '../../../context/Context';
import OtpInput from 'react-otp-input';
import Skeleton from 'react-loading-skeleton';

const WithdrawForexStepTwo = ({
  addressInput,
  setAddressInput,
  fromCurrency,
  setCountry,
  setSelectedAccount,
}) => {
  const { selectedCurrency, appCode, setBuySellStep, setActionFlow } =
    useAppContextDetails();
  const { email, token, profileId } = useContext(BankContext);
  const [allCountry, setAllCountry] = useState([]);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [selectedCoin, setSelectedCoin] = useState(null);
  const [loading, setLoading] = useState(false);
  const [accountList, setAccountList] = useState([]);
  const [query, setQuery] = useState('');

  useEffect(() => {
    setLoading(true);
    axios
      .get(`https://comms.globalxchange.io/coin/user/bank/account/get`, {
        params: {
          email: email,
          // email: 'shorupan@inr.group',
        },
      })
      .then(({ data }) => {
        if (data.status) {
          console.log(data, 'kjqebdjhwvcjhwvcjhw');
          setAccountList(data?.bankAccounts_info);
          setLoading(false);
        }
      });
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: window.innerHeight - 100,
      }}
    >
      <div>
        <div className="breadcrumb">
          <span onClick={(e) => setActionFlow(null)}>Receiving Currency</span>
          &nbsp;&nbsp;{`>`}&nbsp;&nbsp;
          <span style={{ fontWeight: 700 }}>Bank Account</span>
        </div>
        <div style={{ padding: '0px 22px' }}>
          <div style={{ paddingTop: '40px' }}>
            {/* <div
                style={{
                  fontWeight: 'bold',
                  fontSize: '22px',
                  paddingBottom: '31px',
                }}
              >
                Which Currency Are You Sending ?
              </div> */}
            <div>Which Account Do You Want Us To Send The Funds To?</div>
          </div>
        </div>
      </div>
      <div
        className="midSectionStyle"
        style={{ height: '60vh', marginTop: '20px' }}
      >
        <input
          className="addressInput"
          onFocus={(e) => setIsSearchOpen(true)}
          placeholder="Search Accounts..."
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        <div
          style={{
            overflowY: 'scroll',
            height: '50vh',
            paddingBottom: '20px',
            borderTop: '1px solid #e7e7e7',
          }}
        >
          {!loading ? (
            accountList?.length > 0 ? (
              accountList
                .filter((o) =>
                  o.instituteData?.institute_name
                    .toLowerCase()
                    .includes(query.toLowerCase())
                )
                .map((item, index) => {
                  return (
                    <div
                      onClick={(e) => {
                        setSelectedAccount(item);
                        setActionFlow('WithdrawForexStepThree');
                      }}
                      // onPress={(e) => {
                      //   navigate('WithdrawForexStepFour', {
                      //     fromCurrency: fromCurrency,
                      //     selectedAccount: item,
                      //   });
                      // }}
                      className={index === 0 ? 'accCardStyle1' : 'accCardStyle'}
                    >
                      <div className="instituteWrapper">
                        <img
                          style={{ width: 26, height: 26 }}
                          src={item?.instituteData?.profile_image}
                        />
                        <div className="instituteName">
                          {item?.instituteData?.institute_name.length > 20 ? (
                            <div>
                              {item?.instituteData?.institute_name?.substring(
                                0,
                                20
                              )}{' '}
                              ...
                            </div>
                          ) : (
                            item?.instituteData?.institute_name
                          )}
                        </div>
                      </div>
                      <div>
                        <div className="accountNameLabel">Account Name</div>
                        <div className="accountNameStyle">
                          {item.account_name}
                        </div>
                      </div>
                      <div className="paymentMethodStyle">
                        <img
                          style={{ width: 23, height: 23 }}
                          src={item?.paymentMethod_data?.icon}
                        />
                        <div className="paymentMetodLable">
                          {item?.paymentMethod_data?.name}
                        </div>
                      </div>
                    </div>
                  );
                })
            ) : (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingTop: 40,
                }}
              >
                <div
                  style={{
                    fontWeight: 500,
                  }}
                >
                  No Data
                </div>
              </div>
            )
          ) : (
            Array(30)
              .fill(' ')
              .map((item) => {
                return (
                  <div className="accCardStyle">
                    <div className="instituteWrapper">
                      <Skeleton
                        itemHeight={25}
                        itemWidth={25}
                        style={{ borderRadius: 20, marginRight: 10 }}
                      />

                      <Skeleton itemHeight={20} itemWidth={250} />
                    </div>
                    <div>
                      <Skeleton itemHeight={5} itemWidth={80} />
                      <Skeleton
                        itemHeight={15}
                        itemWidth={200}
                        style={{ marginTop: 10 }}
                      />
                    </div>
                    <div className="paymentMethodStyle">
                      <Skeleton
                        itemHeight={25}
                        itemWidth={25}
                        style={{ borderRadius: 50, marginRight: 10 }}
                      />
                      <Skeleton itemHeight={20} itemWidth={100} />
                    </div>
                  </div>
                );
              })
          )}
        </div>
      </div>
      <div style={{ padding: '0px 22px' }}>
        <div className="doubleButton">
          <div
            className="submitButton"
            style={{ background: '#464B4E', width: '100%' }}
            onClick={(e) => setActionFlow('WithdrawForexStepOne')}
          >
            <div style={{ color: 'white', fontWeight: 'bold' }}>Back</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithdrawForexStepTwo;
