import React, { useEffect, useContext } from 'react';
import { BankContext } from '../../context/Context';
import { allLicenses } from './apidata';
import { useAppContextDetails } from '../../context/AppContext';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';
import { useState } from 'react';

const Subscription = () => {
  const { email } = useContext(BankContext);
  const { subscriptionData, setSubscriptionData } = useAppContextDetails();
  const [isloading, setisloading] = useState(true);

  useEffect(() => {
    let mailId = email;
    allLicenses(mailId).then((response) => {
      let result = response?.data?.productLicenses;
      setSubscriptionData(result);
      setisloading(false);
    });
  }, []);

  function formatText(val) {
    let string = val[0].toUpperCase() + val.substring(1);
    return string;
  }

  return (
    <>
      {isloading
        ? Array(10)
            .fill(' ')
            .map((item, index) => {
              return (
                <div className="subs-content" key={index}>
                  <div className="contt">
                    <div className="div01">
                      <Skeleton width={100} height={25} />
                      <div className="pro-dett">
                        <Skeleton width={50} height={50} borderRadius={50} />
                        <Skeleton width={150} height={25} />
                      </div>
                    </div>
                    <div className="div01">
                      <Skeleton width={100} height={25} />
                      <div className="pro-dett">
                        <Skeleton width={150} height={25} />
                      </div>
                    </div>
                    <div className="div02">
                      <Skeleton width={100} height={25} />
                      <div className="pro-dett">
                        <Skeleton width={150} height={25} />
                      </div>
                    </div>
                    <div className="div02">
                      <Skeleton width={100} height={25} />
                      <div className="pro-dett">
                        <Skeleton width={150} height={25} />
                      </div>
                    </div>
                  </div>
                  <div className="contt">
                    <div className="div01">
                      <Skeleton width={100} height={25} />
                      <div className="pro-dett">
                        <Skeleton width={150} height={25} />
                      </div>
                    </div>
                    <div className="div01">
                      <Skeleton width={100} height={25} />
                      <div className="pro-dett">
                        <Skeleton width={150} height={25} />
                      </div>
                    </div>
                    <div className="div02">
                      <Skeleton width={100} height={25} />
                      <div className="pro-dett">
                        <Skeleton width={150} height={25} />
                      </div>
                    </div>
                    <div className="div02">
                      <Skeleton width={100} height={25} />
                      <div className="pro-dett">
                        <Skeleton width={150} height={25} />
                      </div>
                    </div>
                  </div>
                  <div className="contt">
                    <div className="div01">
                      <Skeleton width={100} height={25} />
                      <div className="pro-dett">
                        <Skeleton width={150} height={25} />
                      </div>
                    </div>
                    <div className="div01">
                      <Skeleton width={100} height={25} />
                      <div className="pro-dett">
                        <Skeleton width={150} height={25} />
                      </div>
                    </div>
                    <div className="div02">
                      <Skeleton width={100} height={25} />
                      <div className="pro-dett">
                        <Skeleton width={150} height={25} />
                      </div>
                    </div>
                    <div className="div02">
                      <Skeleton width={100} height={25} />
                      <div className="pro-dett">
                        <Skeleton width={150} height={25} />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
        : subscriptionData?.map((e, i) => {
            return (
              <div className="subs-content" key={i}>
                <div className="contt">
                  <div className="div01">
                    <p style={{ textDecoration: 'underline' }}>Product</p>
                    <div className="pro-dett">
                      <img src={e?.licenses?.[0]?.product_icon} alt="" />
                      <p
                        style={{
                          color: '#18191D',
                          fontSize: '1.25rem',
                          fontWeight: '700',
                        }}
                      >
                        {e?.licenses?.[0]?.product_name}
                      </p>
                    </div>
                  </div>
                  <div className="div01">
                    <p style={{ textDecoration: 'underline' }}>License Code:</p>
                    <div className="pro-dett">
                      <p
                        style={{
                          color: '#18191D',
                          fontSize: '1.25rem',
                          fontWeight: '700',
                        }}
                      >
                        {e?.licenses?.[0]?.license_code}
                      </p>
                    </div>
                  </div>
                  <div className="div02">
                    <p style={{ textDecoration: 'underline' }}>
                      Purchase Date:
                    </p>
                    <div className="pro-dett">
                      <p
                        style={{
                          color: '#18191D',
                          fontSize: '1.25rem',
                          fontWeight: '700',
                        }}
                      >
                        {moment(e?.licenses?.[0]?.date).format('MMM Do YYYY')}
                      </p>
                    </div>
                  </div>
                  <div className="div02">
                    <p style={{ textDecoration: 'underline' }}>Purchase Coin</p>
                    <div className="pro-dett">
                      <p
                        style={{
                          color: '#18191D',
                          fontSize: '1.25rem',
                          fontWeight: '700',
                        }}
                      >
                        {e?.licenses?.[0]?.user_pay_coin}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="contt">
                  <div className="div01">
                    <p style={{ textDecoration: 'underline' }}>Product Code</p>
                    <div className="pro-dett">
                      <p
                        style={{
                          color: '#18191D',
                          fontSize: '1.25rem',
                          fontWeight: '700',
                        }}
                      >
                        {e?.licenses?.[0]?.product_code}
                      </p>
                    </div>
                  </div>
                  <div className="div01">
                    <p style={{ textDecoration: 'underline' }}>License ID:</p>
                    <div className="pro-dett">
                      <p
                        style={{
                          color: '#18191D',
                          fontSize: '1.25rem',
                          fontWeight: '700',
                        }}
                      >
                        {e?.licenses?.[0]?.license_id}
                      </p>
                    </div>
                  </div>
                  <div className="div02">
                    <p style={{ textDecoration: 'underline' }}>Renewal Date:</p>
                    <div className="pro-dett">
                      <p
                        style={{
                          color: '#18191D',
                          fontSize: '1.25rem',
                          fontWeight: '700',
                        }}
                      >
                        {moment(e?.licenses?.[0]?.last_renewed_date).format(
                          'MMM Do YYYY'
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="div02">
                    <p style={{ textDecoration: 'underline' }}>Amount Paid</p>
                    <div className="pro-dett">
                      <p
                        style={{
                          color: '#18191D',
                          fontSize: '1.25rem',
                          fontWeight: '700',
                        }}
                      >
                        {e?.licenses?.[0]?.amount_paid?.toFixed(2)}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="contt">
                  <div className="div01">
                    <p style={{ textDecoration: 'underline' }}>Product ID</p>
                    <div className="pro-dett">
                      <p
                        style={{
                          color: '#18191D',
                          fontSize: '1.25rem',
                          fontWeight: '700',
                        }}
                      >
                        {e?.licenses?.[0]?.product_id}
                      </p>
                    </div>
                  </div>
                  <div className="div01">
                    <p style={{ textDecoration: 'underline' }}>
                      License Status:
                    </p>
                    <div className="pro-dett">
                      <p
                        style={{
                          color: '#18191D',
                          fontSize: '1.25rem',
                          fontWeight: '700',
                        }}
                      >
                        {formatText(e?.licenses?.[0]?.license_status)}
                      </p>
                    </div>
                  </div>
                  <div className="div02">
                    <p style={{ textDecoration: 'underline' }}>
                      Next Payment Date:
                    </p>
                    <div className="pro-dett">
                      <p
                        style={{
                          color: '#18191D',
                          fontSize: '1.25rem',
                          fontWeight: '700',
                        }}
                      >
                        {moment(e?.licenses?.[0]?.next_renew_date).format(
                          'MMM Do YYYY'
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="div02">
                    <p style={{ textDecoration: 'underline' }}>Paid From</p>
                    <div className="pro-dett">
                      <p
                        style={{
                          color: '#18191D',
                          fontSize: '1.25rem',
                          fontWeight: '700',
                        }}
                      >
                        {e?.licenses?.[0]?.app_code}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
    </>
  );
};

export default Subscription;
