import React, { useState, useContext } from 'react';
import { BankContext } from '../../../context/Context';

function SetupTraderProfileView({ setProfileSetUp, setDrawerOpen }) {
  const [uName, setUName] = useState('');
  const [dir, setDir] = useState('');
  const [asset, setAsset] = useState('');
  const [bio, setBio] = useState('');
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [upldPrgrs, setUpldPrgrs] = useState(0);

  const [image, setImage] = useState({ preview: '', raw: '' });
  const handleChange = (e) => {
    setImage({
      preview: URL.createObjectURL(e.target.files[0]),
      raw: e.target.files[0],
    });
  };

  return (
    <div className="setup-trade-profile-view h-100 d-flex flex-column">
      <div className="p-4 title">
        <img
          src={'https://app.tokenoptions.com/static/media/funds.a01db99b.svg'}
          alt=""
        />
      </div>
      {loading ? (
        '' // <Loader percentage={(progress + upldPrgrs) / 2} />
      ) : (
        <div className="setup-trade-form flex-grow-1 d-flex flex-column justify-content-around">
          <div className="group">
            <h6 className="label-title">Create Trader ID</h6>
            <div className="d-flex">
              <div className="border-wrapper cloud">
                <label htmlFor="upload-button" className="m-0">
                  <img
                    src={
                      image.preview
                        ? image.preview
                        : 'https://app.tokenoptions.com/static/media/cloud.68f464fd.svg'
                    }
                    alt=""
                    className={'cloud' + (image.preview ? ' p-0' : '')}
                  />
                </label>
                <input
                  type="file"
                  id="upload-button"
                  style={{ display: 'none' }}
                  onChange={handleChange}
                  accept="image/*"
                />
              </div>
              <div className="border-wrapper uname">
                <input
                  className="uname"
                  type="text"
                  placeholder="Trader Username"
                  value={uName}
                  onChange={(e) => setUName(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="group">
            <h6 className="label-title">What assets do you trade?</h6>
            <div className="d-flex">
              <div
                className={
                  'border-wrapper select d-flex' +
                  (asset === 'crypto' ? ' active' : '')
                }
                onClick={() => setAsset('crypto')}
              >
                <h6>CRYPTO </h6>
                <img
                  src="https://app.tokenoptions.com/static/media/crypto.1530c963.svg"
                  alt=""
                />
              </div>
              <div
                className={
                  'border-wrapper select d-flex mx-2' +
                  (asset === 'forex' ? ' active' : '')
                }
                onClick={() => setAsset('forex')}
              >
                <h6>FOREX </h6>
                <img
                  src="https://app.tokenoptions.com/static/media/forex.44726f44.svg"
                  alt=""
                />
              </div>
              <div
                className={
                  'border-wrapper select d-flex' +
                  (asset === 'both' ? ' active' : '')
                }
                onClick={() => setAsset('both')}
              >
                <h6>BOTH </h6>
                <img
                  src="https://app.tokenoptions.com/static/media/both.1b7f7f1b.svg"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="group">
            <h6 className="label-title">What Direction Do you trade?</h6>
            <div className="d-flex">
              <div
                className={
                  'border-wrapper select d-flex' +
                  (dir === 'short' ? ' active' : '')
                }
                onClick={() => setDir('short')}
              >
                <h6>SHORT </h6>
                <img
                  src="https://app.tokenoptions.com/static/media/down.471f8f81.svg"
                  alt=""
                />
              </div>
              <div
                className={
                  'border-wrapper select d-flex mx-2' +
                  (dir === 'long' ? ' active' : '')
                }
                onClick={() => setDir('long')}
              >
                <h6>LONG </h6>
                <img
                  src="https://app.tokenoptions.com/static/media/up.f0ebddd0.svg "
                  alt=""
                />
              </div>
              <div
                className={
                  'border-wrapper select d-flex' +
                  (dir === 'both' ? ' active' : '')
                }
                onClick={() => setDir('both')}
              >
                <h6>BOTH </h6>
                <img
                  src="https://app.tokenoptions.com/static/media/both.1b7f7f1b.svg"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="group">
            <h6 className="label-title">What Describes You</h6>
            <div className="border-wrapper bio d-flex">
              <textarea
                rows="6"
                placeholder="Enter Your Bio Here"
                value={bio}
                onChange={(e) => setBio(e.target.value)}
              ></textarea>
            </div>
          </div>
        </div>
      )}
      <button
        disabled={bio === '' || asset === '' || dir === '' || uName === ''}
        className="footer-btn btn w-100 p-0 d-flex"
      >
        <div className="btn" onClick={() => setDrawerOpen(false)}>
          <img
            src="https://app.tokenoptions.com/static/media/close.c6a1d024.svg"
            alt=""
          />
        </div>
        <div className="btn" onClick={() => setProfileSetUp(false)}>
          <img
            src="https://app.tokenoptions.com/static/media/back.82b450d2.svg"
            alt=""
          />
        </div>
        <div className="p-3 mx-auto">
          {loading ? (
            <>
              Loading{'  '}
              {(progress + upldPrgrs) / 2}
              <i className="fas fa-spinner fa-pulse" />
            </>
          ) : (
            'Next Step'
          )}
        </div>
      </button>
    </div>
  );
}

export default SetupTraderProfileView;
