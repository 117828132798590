import axios from 'axios';
export const allFunds = () => {
  try {
    const response = axios.get(
      `https://comms.globalxchange.io/coin/investment/sub/types/get?parent_investment_type=funds&getStats=true&displayCurrency=INR`
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const SeletedFunds = (e) => {
  try {
    const response = axios.get(
      `https://comms.globalxchange.io/coin/investment/path/get?investmentSubType=${e}`
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const buyProductPrice = (object) => {
  try {
    const response = axios.post(
      `https://comms.globalxchange.io/coin/vault/service/coins/get`,
      object
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const CovertPrice = (object) => {
  try {
    const response = axios.post(
      `https://comms.globalxchange.io/coin/investment/path/execute`,
      object
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const AssetsBalance = (obj) => {
  try {
    const response = axios.post(
      `https://comms.globalxchange.io/coin/vault/service/coins/get`,
      obj
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const vaultBalance = (obj) => {
  if (obj.email && obj.token) {
    try {
      const response = axios.get(
        `https://comms.globalxchange.io/coin/investment/path/user/vault/balances/get?email=${obj.email}&token=${obj.token}&app_code=dgpayments`
      );
      return response;
    } catch (error) {
      return error.response;
    }
  }
};

export const HoldingFunds = (obj) => {
  if (obj.email) {
    try {
      const response = axios.get(
        `https://comms.globalxchange.io/coin/investment/path/user/vault/balances/get?email=${obj.email}&app_code=dgpayments`
      );
      return response;
    } catch (error) {
      return error.response;
    }
  }
};

export const InvestmentData = (obj) => {
  try {
    const response = axios.get(
      `https://comms.globalxchange.io/coin/investment/path/fund/user/stats/get?email=${obj.email}&token=${obj.token}`
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const StatueData = (obj) => {
  try {
    const response = axios.get(
      `https://comms.globalxchange.io/coin/vault/user/license/get?product_code=dgfx&email=${obj.email}`
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const SubmitStatus = (obj) => {
  try {
    const response = axios.post(
      `https://comms.globalxchange.io/gxb/product/license/note/create`,
      obj
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const MultiBank = (obj) => {
  // let obj={
  //   email:"lifewithmed22@gmail.com"
  // }

  try {
    const response = axios.post(
      `https://comms.globalxchange.io/gxb/product/aiprowallet/multibank`,
      obj
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const StatueDataMuiltBak = (obj) => {
  try {
    const response = axios.get(
      `https://comms.globalxchange.io/gxb/product/license/note/get/all?email=${obj}`
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

