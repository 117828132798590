import React, { useState, useEffect } from 'react';
import btc from '../../../static/images/coin-color/bitcoin.svg';
import usdt from '../../../static/images/coin-color/tether.svg';
import indices from '../../../static/images/world.svg';

function EarnIntrestControll({
  setAssetTab,
  countCrypto,
  countStable,
  countFiat,
}) {
  const [tabSelected, setTabSelected] = useState('Crypto');
  useEffect(() => {
    try {
      setAssetTab(tabSelected);
    } catch (error) {}
  }, [tabSelected, setAssetTab]);
  return (
    <>
      {/* <h2 className="mb-4">{title}</h2> */}
      <div className="earn-intrest-controll">
        <div
          className={`tab-item ${tabSelected === 'Crypto'}`}
          onClick={() => setTabSelected('Crypto')}
        >
          <img className="coin-img" src={btc} alt="" />
          <div className="text-content">
            <h5>
              Cryptocurrencies<span>{countCrypto}</span>
            </h5>
            <h6>Assets Built On A Blockchain</h6>
          </div>
        </div>
        <div
          className={`tab-item ${tabSelected === 'stableCoin'}`}
          onClick={() => setTabSelected('stableCoin')}
        >
          <img className="coin-img" src={usdt} alt="" />
          <div className="text-content">
            <h5>
              StableCoins<span>{countStable}</span>
            </h5>
            <h6>Digital Assets Pegged To An Analog Asset</h6>
          </div>
        </div>
        <div
          className={`tab-item ${tabSelected === 'Fiat'}`}
          onClick={() => setTabSelected('Fiat')}
        >
          <img className="coin-img" src={indices} alt="" />
          <div className="text-content">
            <h5>
              Fiat Currencies<span>{countFiat}</span>
            </h5>
            <h6>Currencies Issued Central Banks</h6>
          </div>
        </div>
      </div>
    </>
  );
}

export default EarnIntrestControll;
