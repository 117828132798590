import React, { useState, useContext, useEffect } from 'react';

import Layout from '../Layout/Index';
import EarnIntrest from '../components/MarketPage/EarnIntrest/EarnIntrest';
import useWindowDimensions from '../utils/WindowSize';
import BondsContent from '../components/MarketPage/BondsContent/BondsContent';
import IndexContextProvider from '../context/IndexContext';
import iceLogo from '../static/images/logo.svg';
import fundsLogo from '../static/images/funds.png';
import backDouble from '../static/images/backDouble.svg';
import IcePayouts from '../components/MarketPage/IcePayouts/IcePayouts';
import IceTrust from '../components/MarketPage/IceTrust/IceTrust';
import { BankContext } from '../context/Context';
import LeaderBoardDrawer from '../components/LeaderBoardDrawer/LeaderBoardDrawer';
import { useHistory, useParams } from 'react-router-dom';
import IceMarkets from '../components/IceMarkets/IceMarkets';
import EnterPinUnlock from '../components/EnterPinUnlock/EnterPinUnlock';
import Currencies from '../components/MarketPage/Currencies/Currencies';
import { useAppContextDetails } from '../context/AppContext';

function IcedIndex({ activeTab }) {
  const history = useHistory();
  const { coinParam, marketTab } = useParams();
  const { email } = useContext(BankContext);
  const [menuSelected, setMenuSelected] = useState('rates-intrest');
  const [enterPin, setEnterPin] = useState(false);
  const { width } = useWindowDimensions();
  const { appName } = useAppContextDetails();

  useEffect(() => {
    setMenuSelected('rates-intrest');
  }, [coinParam]);
  function getPageContent(tab) {
    switch (tab) {
      case 'bonds-tab':
        return (
          <>
            <BondsContent />
          </>
        );
      case 'payouts':
        return (
          <>
            <IcePayouts />
          </>
        );
      case 'markets':
        return (
          <>
            <IceMarkets />
          </>
        );
      case 'bridge':
        return (
          <>
            <IceTrust />
          </>
        );
      case 'currencies':
        return (
          <>
            <Currencies />
          </>
        );

      default:
        return (
          <>
            <EarnIntrest />
          </>
        );
    }
  }
  useEffect(() => {
    if (activeTab === 'bonds') setMenuSelected('bonds-tab');
    if (activeTab === 'bridge') setMenuSelected('bridge');
    if (activeTab === 'globalPayments') setMenuSelected('payouts');
  }, [activeTab]);

  useEffect(() => {
    switch (marketTab) {
      case 'currencies':
        setMenuSelected('currencies');
        break;
      case 'trust':
        setMenuSelected('bridge');
        break;
      case 'rates':
        setMenuSelected('rates-intrest');
        break;
      case 'bonds':
        setMenuSelected('bonds-tab');
        break;
      case 'tokens':
        setMenuSelected('markets');
        break;
      default:
        history.push('/');
        break;
    }
  }, [marketTab]);

  const details = [
    'Indicies Will Allow You To Create Your Own Index Funds In A Matter Of Seconds. Tap Into The Biggest Trends In The World Without The Risk Of Choosing The Right Asset. ',
    `${appName} Has Created The Ice Protocol Which Enables Your To Digitalize Any Asset. Once Your Have Turned Your Assets Into Iced Assets, They Can Now Be Used As An Instrument On The Platform `,
    'The IceMachine Is The Interface To The IcedProtocol. It Allows Your To Interact WIth The Smart Contract & Lay The Abstraction Parameters For You Newly Created Iced Asset',
  ];
  const [detailIndex, setDetailIndex] = useState(null);

  return (
    <IndexContextProvider>
      <Layout
        active={activeTab || 'index'}
        className="icedIndex"
        menuSelected={menuSelected}
        setMenuSelected={setMenuSelected}
        hideFooter={!email}
      >
        <div className="icedContainer">
          <div className={`mainContent false`}>
            {width > 768 ? (
              <div className="tab">
                <div
                  className={`tab-itm ${menuSelected === 'currencies'}`}
                  onClick={() => {
                    history.push('/markets/currencies');
                    // setMenuSelected('rates-intrest');
                  }}
                >
                  Currencies
                </div>
                <div
                  className={`tab-itm ${menuSelected === 'rates-intrest'}`}
                  onClick={() => {
                    history.push('/markets/rates');
                    // setMenuSelected('rates-intrest');
                  }}
                >
                  Rates
                </div>
                <div
                  className={`tab-itm ${menuSelected === 'bonds-tab'}`}
                  onClick={() => {
                    history.push('/markets/bonds');
                    // setMenuSelected('bonds-tab');
                  }}
                >
                  Bonds
                </div>
                <div
                  className={`tab-itm ${menuSelected === 'markets'}`}
                  onClick={() => {
                    history.push('/markets/tokens');
                  }}
                >
                  Tokens
                </div>
                <div
                  className={`tab-itm ${menuSelected === 'bridge'}`}
                  onClick={() => {
                    history.push('/markets/trust');
                    // setMenuSelected('bridge');
                  }}
                >
                  Trusts
                </div>
                <div
                  onClick={() => {
                    if (menuSelected === 'markets') {
                      setEnterPin(true);
                    }
                  }}
                  className={`tab-itm ice ${menuSelected === 'collateral'}`}
                >
                  <img
                    style={{
                      filter: 'brightness(0)',
                    }}
                    src={menuSelected === 'markets' ? fundsLogo : iceLogo}
                    alt=""
                  />
                </div>
              </div>
            ) : (
              ''
            )}
            <div className={`content-section ${menuSelected}`}>
              {getPageContent(menuSelected)}
              {details[detailIndex] ? (
                <div
                  className={`overlayComingSoon det-${detailIndex + 3} false`}
                  onMouseEnter={() => setDetailIndex(detailIndex)}
                >
                  <div
                    className="detail"
                    onMouseEnter={() => setDetailIndex(detailIndex)}
                    onMouseLeave={() => setDetailIndex(null)}
                  >
                    <div className="detailText">{details[detailIndex]}</div>
                    <div className="btComingSoon">Coming Soon!</div>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
          {menuSelected === 'markets' ? <LeaderBoardDrawer /> : ''}
          {enterPin && (
            <EnterPinUnlock
              onSucces={() => {
                setEnterPin(false);
              }}
              onClose={() => setEnterPin(false)}
            />
          )}
        </div>
      </Layout>
    </IndexContextProvider>
  );
}

export default IcedIndex;
