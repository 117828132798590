import React from 'react';
import Skeleton from 'react-loading-skeleton';
import './globalSidebar.scss';

const GlobalSidebar = ({
  globalMenu,
  selectedApp,
  setSelectedApp,
  isLoading,
}) => {
  return (
    <div
      className="sidebar-loggedout"
      style={{
        minHeight: window.innerHeight,
      }}
    >
      <div
        style={{
          minHeight: window.innerHeight,
          overflowY: 'scroll',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        {globalMenu.map((item, index) => (
          <div
            key={`${item?._id}_${index}`}
            className="globalItem"
            style={{
              opacity: selectedApp?.appName === item?.appName ? 1 : 0.3,
            }}
            onClick={() => setSelectedApp(item)}
          >
            {isLoading ? (
              <Skeleton style={{ width: 50, height: 50 }} />
            ) : (
              <img src={item?.appLogo} alt="Logo" />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default GlobalSidebar;
