import portfolioIcon from '../../static/images/sidebar-icons/portfolio.svg';

import Fund from '../../static/images/sidebar-icons/fund.svg';
import sociallyIcon from '../../static/images/sidebar-icons/socially.svg';
import signalsIcon from '../../static/images/sidebar-icons/signals.svg';

import vaultsIcon from '../../static/images/sidebar-icons/vaults.svg';
import defiIcon from '../../static/images/sidebar-icons/defiIcon.svg';
import stakingIcon from '../../static/images/sidebar-icons/stakingIcon.svg';
import affiliateIcon from '../../static/images/sidebar-icons/affiliateIcon.svg';
import taxesIcon from '../../static/images/sidebarIcons/taxes.svg';
import supportIcon from '../../static/images/sidebar-icons/supportIcon.svg';
import supportNewIcon from '../../static/images/sidebar-icons/supportNew.svg';
import sharesToken from '../../static/images/login/sharestoken.svg';
import products from '../../static/images/sidebar-icons/malls.svg';
import trainingIcon from '../../static/images/sidebar-icons/trainingIcon.svg';
import swapIcon from '../../static/images/sidebar-icons/swapIcon.svg';
import aiindexIcon from '../../static/images/sidebar-icons/aiindexIcon.svg';
import airIcon from '../../static/images/sidebar-icons/airIcon.svg';
import tellersIcon from '../../static/images/sidebar-icons/tellersIcon.svg';
import assetIcon from '../../static/images/sidebar-icons/assetIcon.svg';
import marketsNewIcon from '../../static/images/sidebar-icons/marketsNewIcon.svg';
import shareIcon from '../../static/images/sidebar-icons/shareIcon.svg';

import displayIcon from '../../static/images/settings/displayCurrencyIcon.svg';
import themeIcon from '../../static/images/settings/themeIcon.svg';
import securityIcon from '../../static/images/settings/securityIcon.svg';
import settingsIcon from '../../static/images/settings/settingsIcon.svg';
import kycIcon from '../../static/images/settings/kycIcon.svg';
import bankingIcon from '../../static/images/settings/banking.svg';
import logoutIcon from '../../static/images/settings/logoutIcon.svg';

import qrAddress from '../../static/images/actionSidebar/qrAddress.svg';
import connectIcon from '../../static/images/vaultCpanelIcons/connect.svg';
import withdraw from '../../static/images/vaultCpanelIcons/withdraw.svg';
import arrowUp from '../../static/images/clipIcons/arrowUp.svg';
import arrowDown from '../../static/images/clipIcons/arrowDown.svg';
import plus from '../../static/images/actionSidebar/plus.svg';
import retiredLogo from '../../static/images/retiredLogo.svg';

//air assests
import apwIcon from '../../static/images/airLogos/apw.svg';
import viralverseIcon from '../../static/images/airLogos/viralverse.svg';
import retiredIcon from '../../static/images/airLogos/retired.svg';
import tradeStreamIcon from '../../static/images/airLogos/tradeStream.svg';
import accountantsIcon from '../../static/images/airLogos/accountants.svg';
// import signalsIcon from '../../static/images/airLogos/signals.svg';
import web3todayIcon from '../../static/images/airLogos/web3today.svg';
import investmentbotsIcon from '../../static/images/airLogos/investmentbots.svg';

import hoilday from '../../static/images/airLogos/hoilday.svg';
import mall from '../../static/images/airLogos/mall.svg';
import assets from '../../static/images/airLogos/assets.svg';
import x3money from '../../static/images/airLogos/x3money.svg';

import single from '../../static/images/airLogos/single.svg';

import rio from '../../static/images/airLogos/rio.svg';
import influencer from '../../static/images/airLogos/influencer.svg';
import air from '../../static/images/airLogos/air.svg';

import { useCoinContextData } from '../../context/CoinContext';

export const SIDEBAR_CONTENTS = [
  {
    id: 1,
    name: 'Portfolio.ai',
    icon: portfolioIcon,
  },
  {
    id: 2,
    name: 'Vaults',
    icon: vaultsIcon,
  },
  {
    id: 3,
    name: 'Staking',
    icon: stakingIcon,
  },
  {
    id: 4,
    name: 'Affiliate',
    icon: affiliateIcon,
  },
  //put inside entra menu
  {
    id: 5,
    name: 'Taxes',
    icon: taxesIcon,
  },
  {
    id: 6,
    name: 'Support',
    icon: supportIcon,
  },
  {
    id: 7,
    name: 'ShareToken',
    icon: sharesToken,
  },
  //put inside entra menu
  {
    id: 8,
    name: 'Products',
    icon: products,
  },
  {
    id: 9,
    name: 'Training',
    icon: trainingIcon,
  },
  {
    id: 10,
    name: 'Swap',
    icon: swapIcon,
  },
  {
    id: 11,
    name: 'Air',
    icon: airIcon,
  },
  {
    id: 12,
    name: 'Funds',
    icon: Fund,
  },
  {
    id: 12,
    name: 'Signals',
    icon: signalsIcon,
  },

  // {
  //   id: 13,
  //   name: 'Retired',
  //   icon: retiredLogo,
  // },
  // {
  //   id: 12,
  //   name: 'Tellers',
  //   icon: tellersIcon,
  // },
  {
    id: 13,
    name: 'Assets',
    icon: assetIcon,
  },
  {
    id: 14,
    name: 'Markets',
    icon: marketsNewIcon,
  },
  {
    id: 15,
    name: 'Shares',
    icon: shareIcon,
  },
];

export const SETTINGS_MENU = (
  onLogoutClick,
  onBankingClick,
  // onTaxesClick,
  onSupportClick,
  onShareTokenClick,
  // onKycClick,
  onTellersClick,
  onSettingsClick,
  onHoldClick,
  onTrainingClick,
  onSignalsClick,
  onFundsClick
) => [
  // {
  //   id: 1,
  //   icon: displayIcon,
  //   text: 'Display Currency',
  //   subText: 'Currently Indian Rupees',
  // },
  // {
  //   id: 2,
  //   icon: themeIcon,
  //   text: 'Theme',
  //   subText: 'Currently Light Mode',
  // },
  {
    id: 3,
    icon: settingsIcon,
    text: 'Settings',
    subText: 'Enhance Your Security',
    onClick: onSettingsClick,
  },
  // {
  //   id: 3,
  //   icon: securityIcon,
  //   text: 'Security',
  //   subText: 'Enhance Your Security',
  // },
  // {
  //   id: 4,
  //   icon: kycIcon,
  //   text: 'KYC',
  //   subText: 'Currently Not Verified',
  //   onClick: onKycClick,
  // },
  {
    id: 5,
    icon: bankingIcon,
    text: 'Banking',
    subText: 'Attach Your Bank Accounts',
    onClick: onBankingClick,
  },
  // {
  //   id: 6,
  //   text: 'Taxes',
  //   icon: taxesIcon,
  //   onClick: onTaxesClick,
  // },
  {
    id: 7,
    text: 'Support',
    icon: supportNewIcon,
    subText: 'Need Help? Click Here',
    onClick: onSupportClick,
  },
  {
    id: 8,
    text: 'ShareToken',
    icon: sharesToken,
    subText: 'Want To Own AiProWallet? Click Here',
    onClick: onShareTokenClick,
  },
  {
    id: 9,
    icon: tellersIcon,
    text: 'Tellers',
    subText: 'A Revolution In Remittance',
    onClick: onTellersClick,
  },
  {
    id: 10,
    icon: supportIcon,
    text: 'OnHold',
    subText: 'Help Your customers',
    onClick: onHoldClick,
  },
  {
    id: 9,
    icon: trainingIcon,
    text: 'Training',
    subText: 'Learn How To Use AI',
    onClick: onTrainingClick,
  },
  {
    id: 12,
    icon: signalsIcon,
    text: 'Signals',
    subText: 'Interact With Signals in AiProWallet',
    onClick: onSignalsClick,
  },
  {
    id: 13,
    icon: Fund,
    text: 'Funds',
    subText: 'Invest Into Tokenized Funds',
    onClick: onFundsClick,
  },
  {
    id: 11,
    icon: logoutIcon,
    text: 'Logout',
    subText: 'Hope To See You Again Soon',
    onClick: onLogoutClick,
  },
];

export const COIN_ACTIONS = (coinName, setCoinAction, isMarketsPage) => {
  const { coinType, networkData } = useCoinContextData();
  const showBuyAndSellOnly = [
    {
      id: 5,
      icon: arrowUp,
      text: 'Buy',
      subText: `Buy ${coinName} With Another Currency`,
      action: () => setCoinAction(['Buy']),
    },
    {
      id: 6,
      icon: arrowDown,
      text: 'Sell',
      subText: `Buy ${coinName} For Another Currency`,
      action: () => setCoinAction(['Sell']),
    },
  ];

  const enableActionsByCondition = isMarketsPage
    ? showBuyAndSellOnly
    : coinType === 'crypto'
    ? [
        // {
        //   id: 1,
        //   icon: qrAddress,
        //   text: 'Add',
        //   subText: `Add ${coinName} From External Wallet`,
        //   action: () => setCoinAction(['Add']),
        // },
        // {
        //   id: 2,
        //   icon: vaultsIcon,
        //   text: 'Transfer',
        //   subText: `Transfer ${coinName} Between Your Vaults`,
        //   action: () => setCoinAction(['Transfer']),
        // },
        // {
        //   id: 3,
        //   icon: connectIcon,
        //   text: 'Connect',
        //   subText: `Send ${coinName} To Your Friends`,
        // },
        // {
        //   id: 4,
        //   icon: withdraw,
        //   text: 'Withdraw',
        //   subText: `Send ${coinName} To An External Wallet`,
        //   action: () => setCoinAction(['Withdraw']),
        // },
        // ...showBuyAndSellOnly,
        {
          id: 5,
          text: 'Deposit',
          subText: `Receive ${coinName} From An External Wallet`,
          action: 
            networkData?.length < 1 ? '' : () => setCoinAction(['AddCrypto']),
        },
        {
          id: 6,
          text: 'Withdraw',
          subText: `Send ${coinName} To An External Wallet`,
          action: () => setCoinAction(['Withdraw']),
        },
      ]
    : [
        {
          id: 1,
          icon: plus,
          text: 'Add',
          subText: `Send ${coinName} From Your Bank Account`,
          action: () => setCoinAction(['Add']),
        },
        {
          id: 2,
          icon: vaultsIcon,
          text: 'Transfer',
          subText: `Transfer ${coinName} Between Your Vaults`,
          action: () => setCoinAction(['Transfer']),
        },
        {
          id: 3,
          icon: connectIcon,
          text: 'Connect',
          subText: `Send ${coinName} To Your Friends`,
        },
        {
          id: 4,
          icon: withdraw,
          text: 'Withdraw',
          subText: `Send ${coinName} To Your Bank Account`,
          action: () => setCoinAction(['Withdraw']),
        },
      ];
  return enableActionsByCondition;
};

export const AirMenu = [
  // {
  //   id: 1,
  //   icon: apwIcon,
  //   text: 'AiProWallet',
  //   subText: 'Wallet For AI Enabled Investments',
  //   onClick: 'https://aiprowallet.com/',
  // },
  {
    id: 2,
    icon: viralverseIcon,
    text: 'ViralVerse',
    subText: 'Build Your Affiliate Marketing Business',
    onClick: 'https://verse.viral.group/',
  },
  {
    id: 8,
    icon: x3money,
    text: 'X3 Money',
    subText: 'The easiest way acquire and spend crypto',
    onClick: 'https://x3.money/',
  },
  {
    id: 3,
    icon: retiredIcon,
    text: 'Retired',
    subText: 'AI Enabled Retirement Planner',
    onClick: 'https://retired.app/',
  },
  {
    id: 5,
    icon: single,
    text: 'Signals',
    subText: 'Learn From The Best Traders',
    onClick: 'https://signals.app/',
  },

  // {
  //   id: 6,
  //   icon: accountantsIcon,
  //   text: 'Accountants',
  //   subText: 'Streamline Your Accounting',
  //   onClick: 'https://accountants.io/',
  // },
  // {
  //   id: 4,
  //   icon: tradeStreamIcon,
  //   text: 'TradeStream',
  //   subText: 'Launch Your Own Tokenized Fund',
  //   onClick: 'https://trade.stream/',
  // },

  // {
  //   id: 7,
  //   icon: web3todayIcon,
  //   text: 'Web3Today',
  //   subText: 'Crypto, AI, Blockchain News Platform',
  //   onClick: 'https://web3today.io/',
  // },
  // {
  //   id: 8,
  //   icon: assets,
  //   text: 'Assets',
  //   subText: 'Fractionalizing asset backed loans',
  //   onClick: 'https://assets.io/',
  // },
  // {
  //   id: 8,
  //   icon: mall,
  //   text: 'Malls',
  //   subText: 'Token-gated commerce',
  //   onClick: '',
  // },

  // {
  //   id: 8,
  //   icon: investmentbotsIcon,
  //   text: 'InvestmentBots',
  //   subText: 'Create Your Own Trading Bot',
  //   onClick: 'https://investmentbots.com/',
  // },
  // {
  //   id: 8,
  //   icon: hoilday,
  //   text: 'Holidays',
  //   subText: 'Go on a holiday every month',
  //   onClick: 'https://holidays.app/',
  // },
];

export const AirToken = [
  {
    id: 1,
    icon: air,
    text: 'AIR-E',
    subText: 'Become an owner of the #AiRevolution',
    onClick: 'https://aiprowallet.com/',
  },
  {
    id: 2,
    icon: influencer,
    text: 'InfluenceCoin',
    subText: 'Monetize Your Influence',
    onClick: 'influencecoin.com',
  },
  {
    id: 2,
    icon: rio,
    text: 'ROICoin',
    subText: 'Supercharge Your Investments With AI',
    onClick: 'https://roicoin.com/',
  },
];
