import React, { useEffect, useState } from 'react';
import Zoom from 'react-reveal/Zoom';
import Axios from 'axios';
import Layout from '../Layout/Index';
import linux from '../static/images/platforms/linux.svg';
import win from '../static/images/platforms/win.svg';
import mac from '../static/images/platforms/mac.svg';
import { Link } from 'react-router-dom';
import { useAppContextDetails } from '../context/AppContext';

function DesktopApps() {
  const [appLinks, setAppLinks] = useState({});
  const { appCode, appName, appLogo } = useAppContextDetails();

  useEffect(() => {
    Axios.get(
      'https://storeapi.apimachine.com/dynamic/Globalxchangetoken/applinks?key=4c69ba17-af5c-4a5c-a495-9a762aba1142'
    ).then((res) => {
      const { data } = res;
      if (data.success) {
        setAppLinks(
          data.data.filter((app) => app.Key === appCode)[0]?.formData
        );
      }
    });
  }, []);
  return (
    <Layout active="desktopApps" className="mobileApps">
      <Zoom>
        <div className="logo-n-title">
          <img className="logo" src={appLogo} alt="" />
          <div className="title">{appName}</div>
          <p className="subTitle">
            {/* Please Download The Mobile App For The Best User Experience. */}
            Coming Soon To
          </p>
        </div>
      </Zoom>
      <div className="download">
        <h6>Available For The Following Platforms</h6>
        <div className="buttons">
          <Link to="/desktopapps/linux" className="btn-app">
            <img className="noFilter" src={linux} alt="" />
            Linux
          </Link>
          <div className="space" />
          <Link to="/desktopapps/pc" className="btn-app">
            <img src={win} alt="" />
            Windows
          </Link>
          <div className="space" />
          <Link to="/desktopapps/mac" className="btn-app">
            <img src={mac} alt="" />
            Mac
          </Link>
        </div>
      </div>
    </Layout>
  );
}

export default DesktopApps;
