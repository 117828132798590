import React, { useState, useContext, useEffect } from 'react';
import {
  faCaretDown,
  faLongArrowAltDown,
  faLongArrowAltUp,
} from '@fortawesome/free-solid-svg-icons';
import Axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormatCurrency, FormatNumber } from '../../../../utils/FunctionTools';
import { BankContext } from '../../../../context/Context';
import returnIcon from '../../../../static/images/clipIcons/returnIcon.svg';
import selectedIcon from '../../../../static/images/sidebar-icons/selected.svg';
import LoadingAnim from '../../../LoadingAnim/LoadingAnim';
import LoginModal from '../../../LoginModalNew';
import TradeInspector from '../../../TradeInspector/TradeInspector';
import Scrollbars from 'react-custom-scrollbars';
import Skeleton from 'react-loading-skeleton';
import { useAppContextDetails } from '../../../../context/AppContext';

function SellBuyCard({ toCoin, coinSelected, exchage }) {
  const {
    coinListObject,
    email,
    token,
    profileId,
    updateBalance,
    tostShowOn,
    coinList,
  } = useContext(BankContext);

  const [sell, setSell] = useState(false);
  const [percent, setPercent] = useState(0);
  const percetVal = ((20 + percent) / 140) * 100;
  const baseBalance = coinSelected && coinSelected.coinValueUSD;
  const [baseValue, setBaseValue] = useState(0);
  const [toValue, setToValue] = useState(0);
  const [isTotal, setIsTotal] = useState(true);
  const onChangeBase = (e) => {
    const val = parseFloat(e.target.value);
    setBaseValue(e.target.value);
    if (coinSelected && coinSelected.price && coinSelected.price.USD) {
      let perc = ((val * coinSelected.price.USD) / baseBalance) * 100;
      setPercent(parseInt(perc));
    }
  };
  const rangeHandler = (e) => {
    let val = e.target.value;
    if (val < 0) {
      val = 0;
    } else if (val > 100) {
      val = 100;
    }
    setPercent(parseInt(val));
    if (
      coinSelected &&
      coinSelected.price &&
      coinSelected.price.USD &&
      coinSelected.coinSymbol &&
      toCoin &&
      toCoin.coinSymbol &&
      coinListObject[toCoin.coinSymbol] &&
      coinListObject[toCoin.coinSymbol].price &&
      coinListObject[toCoin.coinSymbol].price.USD
    ) {
      setToValue(
        FormatCurrency(
          (baseBalance * (parseFloat(val) / 100)) /
            coinListObject[toCoin.coinSymbol].price.USD,
          toCoin.coinSymbol
        )
      );
      setBaseValue(
        FormatCurrency(
          (baseBalance * (parseFloat(val) / 100)) / coinSelected.price.USD,
          coinSelected.coinSymbol
        ).replace(',', '')
      );
    }
  };

  const onChangeToVal = (e) => {
    let val = e.target.value;
    setToValue(val);
    if (
      coinListObject &&
      toCoin &&
      toCoin.coinSymbol &&
      coinListObject[toCoin.coinSymbol] &&
      coinListObject[toCoin.coinSymbol].price &&
      coinListObject[toCoin.coinSymbol].price.USD &&
      coinSelected &&
      coinSelected.coinSymbol &&
      coinSelected.price &&
      coinSelected.price.USD
    ) {
      const usdVal = val * coinListObject[toCoin.coinSymbol].price.USD;
      const baseVal = FormatCurrency(
        usdVal / coinSelected.price.USD,
        coinSelected.coinSymbol
      ).replace(',', '');
      onChangeBase({
        ...e,
        target: {
          value: baseVal,
        },
      });
    }
  };

  const [pathId, setPathId] = useState('');
  const [pathIdLoading, setPathIdLoading] = useState(false);
  useEffect(() => {
    setPathIdLoading(true);
    if (toCoin && toCoin.coinSymbol && coinSelected && coinSelected.coinSymbol)
      Axios.get(
        `https://comms.globalxchange.io/coin/vault/service/payment/paths/get?from_currency=${coinSelected.coinSymbol}&to_currency=${toCoin.coinSymbol}&banker=${exchage}`
      )
        .then((res) => {
          const { data } = res;
          if (
            data.status &&
            data.paths &&
            data.paths[0] &&
            data.paths[0].path_id
          ) {
            setPathId(data.paths[0].path_id);
          } else {
            setPathId('');
            tostShowOn('Transaction Not Possible With The Parameters');
          }
        })
        .catch((e) => {
          setPathId('');
        })
        .finally(() => setPathIdLoading(false));
  }, [toCoin, coinSelected, exchage]);
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [previewResult, setPreviewResult] = useState();
  const [showTi, setShowTi] = useState(false);
  const { appCode } = useAppContextDetails();

  const encryptForFrom = async (pathId, stats) => {
    if (!stats) setLoading(true);
    const resOne = await Axios.post(
      'https://comms.globalxchange.io/coin/vault/service/encode/decode/data',
      {
        data: isTotal
          ? {
              token: token,
              email: email,
              app_code: appCode,
              profile_id: profileId,
              from_amount: parseFloat(baseValue),
              stats: stats,
              identifier: 'quote',
              path_id: pathId,
            }
          : {
              token: token,
              email: email,
              app_code: appCode,
              profile_id: profileId,
              purchased_amount: parseFloat(toValue),
              stats: stats,
              identifier: 'quote',
              path_id: pathId,
            },
        encode: true,
      }
    );
    const { data } = resOne;
    if (data.status) {
      const resTwo = await Axios.post(
        'https://comms.globalxchange.io/coin/vault/service/trade/execute',
        {
          data: data.data,
        }
      );
      const dataTwo = resTwo.data;
      setPreviewResult(dataTwo);
      setStep(stats ? 1 : 0);
      if (!stats) {
        updateBalance();
        tostShowOn(dataTwo.message);
      }
    }
    setLoading(false);
  };
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  function waitForLogin() {
    if (email && token && profileId) {
      pathId && encryptForFrom(pathId);
    } else {
      setTimeout(() => {
        waitForLogin();
      }, 500);
    }
  }
  function onLogin() {
    waitForLogin();
    setLoginModalOpen(false);
  }

  const checkIsExpandValue = (num, coin) => {
    if (coin === 'BTC' || coin === 'ETH') {
      if (num && num < 0.0001) return true;
      else return false;
    } else {
      if (num && num < 0.01) return true;
      else return false;
    }
  };

  const [isFiat, setIsFiat] = useState(true);
  const [coinSearch, setCoinSearch] = useState('');
  const [previewCoin, setPreviewCoin] = useState();
  const [feeCoin, setFeeCoin] = useState();

  function getContent() {
    switch (true) {
      case step === 0:
        return (
          <>
            <div className="mainTab">
              <div
                className={`tabItm disable ${sell}`}
                onClick={() => {
                  // setSell(true);
                }}
              >
                Sell
                <FontAwesomeIcon icon={faLongArrowAltDown} />
              </div>
              <div className={`tabItm ${!sell}`} onClick={() => setSell(false)}>
                Buy
                <FontAwesomeIcon icon={faLongArrowAltUp} />
              </div>
            </div>
            <div className="subTab">
              <div className="tabSubItm true">Market</div>
              <div className="tabSubItm">Limit</div>
              <div className="tabSubItm">Stop-Limit</div>
              <div className="tabSubItm">Algo</div>
            </div>
            <div className="contents">
              <div className="box">
                <div className="label">Price</div>
                <div className="value">Market</div>
                <div className="btnBox">
                  {!isTotal
                    ? coinSelected && coinSelected.coinSymbol
                    : toCoin && toCoin.coinSymbol}
                </div>
              </div>
              <div className="box">
                <div className="label">
                  {isTotal ? 'Total' : 'Amount'}
                  <FontAwesomeIcon icon={faCaretDown} />
                  <div
                    className="labelDropDown"
                    onClick={() => setIsTotal((prev) => !prev)}
                  >
                    {!isTotal ? 'Total' : 'Amount'}
                  </div>
                </div>
                {isTotal ? (
                  <input
                    type="text"
                    className="value"
                    value={baseValue}
                    onChange={onChangeBase}
                    placeholder={FormatCurrency(
                      0,
                      coinSelected && coinSelected.coinSymbol
                    )}
                  />
                ) : (
                  <input
                    type="text"
                    className="value"
                    value={toValue}
                    onChange={onChangeToVal}
                    placeholder={FormatCurrency(0, toCoin && toCoin.coinSymbol)}
                  />
                )}
                <div className="btnBox">
                  {isTotal
                    ? coinSelected && coinSelected.coinSymbol
                    : toCoin && toCoin.coinSymbol}
                </div>
              </div>
              <div className="rangeWrap">
                <div className="rangeIn" style={{ flexBasis: `${percetVal}%` }}>
                  {FormatCurrency((baseBalance * percent) / 100)} USD
                </div>
                <div className="rangeOut">{FormatNumber(percent, 1)}%</div>
                <input
                  className="rangeSlider"
                  value={percent}
                  onChange={rangeHandler}
                  type="range"
                  min={-20}
                  max={120}
                />
              </div>
            </div>
            <div
              className="button"
              onClick={() => {
                if (pathId) {
                  encryptForFrom(pathId, true);
                  setStep(1);
                }
              }}
            >
              {isTotal
                ? `Buy
        ${FormatCurrency(baseValue, coinSelected && coinSelected.coinSymbol)} ${
                    coinSelected && coinSelected.coinSymbol
                  } Worth Of ${toCoin && toCoin.coinSymbol}`
                : `Buy ${FormatCurrency(
                    toValue,
                    toCoin && toCoin.coinSymbol
                  )} ${toCoin && toCoin.coinSymbol}`}
            </div>
          </>
        );
      case step === 1:
        return (
          <>
            <div className="tradeSumHead">
              <div className="ret">
                <img src={returnIcon} alt="" onClick={() => setStep(0)} />
              </div>
              <div className="txtMain">Trade Summary</div>
              <div
                className="btnConfirm"
                onClick={() => {
                  if (email) pathId && encryptForFrom(pathId);
                  else setLoginModalOpen(true);
                }}
              >
                Confirm
              </div>
            </div>
            <Scrollbars className="contents tradeContent">
              <div className="boxLabel">You Are Selling</div>
              {previewResult ? (
                <div className="box">
                  <div className="label">
                    {checkIsExpandValue(
                      previewResult?.finalFromAmount,
                      previewResult?.purchased_from
                    ) ? (
                      <span onClick={() => setShowTi(true)} className="expand">
                        Expand
                      </span>
                    ) : (
                      FormatCurrency(
                        previewResult?.finalFromAmount,
                        previewResult?.purchased_from
                      )
                    )}
                  </div>
                  <div className="btnBox">
                    <img
                      src={
                        coinListObject &&
                        coinListObject[previewResult?.purchased_from]?.coinImage
                      }
                      alt=""
                    />
                    {previewResult?.purchased_from}
                  </div>
                </div>
              ) : (
                <Skeleton className="box" />
              )}
              <div className="boxLabel">You Are Buying</div>
              {previewResult ? (
                <div className="box">
                  <div className="label">
                    {checkIsExpandValue(
                      previewResult?.finalToAmount,
                      previewResult?.coin_purchased
                    ) ? (
                      <span onClick={() => setShowTi(true)} className="expand">
                        Expand
                      </span>
                    ) : (
                      FormatCurrency(
                        previewResult?.finalToAmount,
                        previewResult?.coin_purchased
                      )
                    )}
                  </div>
                  <div className="btnBox">
                    <img
                      src={
                        coinListObject &&
                        coinListObject[previewResult?.coin_purchased]?.coinImage
                      }
                      alt=""
                    />
                    {previewResult?.coin_purchased}
                  </div>
                </div>
              ) : (
                <Skeleton className="box" />
              )}
              <div className="boxLabel">
                Final Price Of 1.00 {previewResult?.coin_purchased}
                <span onClick={() => setStep('fee')}>Fee Breakdown</span>
              </div>
              {previewResult ? (
                <div className="box">
                  <div className="label">
                    {previewCoin?.coinSymbol ? (
                      checkIsExpandValue(
                        previewResult?.final_1_to_currency_in_from_currency *
                          (coinListObject &&
                            coinListObject[previewResult?.purchased_from]?.price
                              .USD / previewCoin?.price?.USD),
                        previewCoin?.coinSymbol
                      ) ? (
                        <span
                          onClick={() => setShowTi(true)}
                          className="expand"
                        >
                          Expand
                        </span>
                      ) : (
                        FormatCurrency(
                          previewResult?.final_1_to_currency_in_from_currency *
                            (coinListObject &&
                              coinListObject[previewResult?.purchased_from]
                                ?.price.USD / previewCoin?.price?.USD),
                          previewCoin?.coinSymbol
                        )
                      )
                    ) : checkIsExpandValue(
                        previewResult?.final_1_to_currency_in_from_currency,
                        previewResult?.purchased_from
                      ) ? (
                      <span onClick={() => setShowTi(true)} className="expand">
                        Expand
                      </span>
                    ) : (
                      FormatCurrency(
                        previewResult?.final_1_to_currency_in_from_currency,
                        previewResult?.purchased_from
                      )
                    )}
                  </div>
                  <div
                    className="btnBox true"
                    onClick={() => setStep('prevCoin')}
                  >
                    <img
                      src={
                        previewCoin?.coinImage ||
                        (coinListObject &&
                          coinListObject[previewResult?.purchased_from]
                            ?.coinImage)
                      }
                      alt=""
                    />
                    {previewCoin?.coinSymbol || previewResult?.purchased_from}
                  </div>
                </div>
              ) : (
                <Skeleton className="box" />
              )}
            </Scrollbars>
            {showTi && (
              <TradeInspector
                tiObject={{
                  sellAmtLabel: 'Your Selling',
                  sellAmt: previewResult?.finalFromAmount,
                  sellCoin: previewResult?.purchased_from,
                  getAmtLabel: 'You Wil Get',
                  getAmt: previewResult?.finalToAmount,
                  getCoin: previewResult?.coin_purchased,
                  lblThree: `Final Price Of 1.00 ${previewResult?.coin_purchased}`,
                  amtThree: previewResult?.final_1_to_currency_in_from_currency,
                  coinThree: previewResult?.purchased_from,
                }}
                setOpenModal={setShowTi}
              />
            )}
          </>
        );
      case step === 'fee':
        return (
          <>
            <div className="tradeSumHead">
              <div className="ret">
                <img src={returnIcon} alt="" onClick={() => setStep(1)} />
              </div>
              <div className="txtMain">Fee Breakdown</div>
              <div
                className="btnConfirm"
                onClick={() => {
                  setStep('feeCoin');
                }}
              >
                <img
                  src={
                    feeCoin?.coinImage ||
                    (coinListObject &&
                      coinListObject[previewResult?.coin_purchased]?.coinImage)
                  }
                  alt=""
                />
                {feeCoin?.coinSymbol || previewResult?.coin_purchased}
              </div>
            </div>
            <Scrollbars className="contents tradeContent">
              <div className="valueBox">
                <div className="mTxt">
                  <span>Affiliate Fees</span>
                  <span>
                    {feeCoin?.coinSymbol ? (
                      checkIsExpandValue(
                        previewResult?.brokerData?.fee *
                          (coinListObject &&
                            coinListObject[previewResult?.coin_purchased]?.price
                              .USD / feeCoin?.price?.USD),
                        feeCoin?.coinSymbol
                      ) ? (
                        <span
                          onClick={() => setShowTi(true)}
                          className="expand"
                        >
                          Expand
                        </span>
                      ) : (
                        FormatCurrency(
                          previewResult?.brokerData?.fee *
                            (coinListObject &&
                              coinListObject[previewResult?.coin_purchased]
                                ?.price.USD / feeCoin?.price?.USD),
                          feeCoin?.coinSymbol
                        )
                      )
                    ) : checkIsExpandValue(
                        previewResult?.brokerData?.fee,
                        previewResult?.coin_purchased
                      ) ? (
                      <span onClick={() => setShowTi(true)} className="expand">
                        Expand
                      </span>
                    ) : (
                      FormatCurrency(
                        previewResult?.brokerData?.fee,
                        previewResult?.coin_purchased
                      )
                    )}
                  </span>
                </div>
                <div className="sTxt">
                  <span>
                    {FormatCurrency(
                      previewResult?.brokerData?.broker_fee_percentage
                    )}
                    % Of The Fee
                  </span>
                  <span>
                    ${FormatCurrency(previewResult?.brokerData?.fee_usd, 'USD')}{' '}
                    USD
                  </span>
                </div>
              </div>
              <div className="valueBox">
                <div className="mTxt">
                  <span>Platform Fees</span>
                  <span>
                    {feeCoin?.coinSymbol ? (
                      checkIsExpandValue(
                        previewResult?.appData?.app_fee *
                          (coinListObject &&
                            coinListObject[previewResult?.coin_purchased]?.price
                              .USD / feeCoin?.price?.USD),
                        feeCoin?.coinSymbol
                      ) ? (
                        <span
                          onClick={() => setShowTi(true)}
                          className="expand"
                        >
                          Expand
                        </span>
                      ) : (
                        FormatCurrency(
                          previewResult?.appData?.app_fee *
                            (coinListObject &&
                              coinListObject[previewResult?.coin_purchased]
                                ?.price.USD / feeCoin?.price?.USD),
                          feeCoin?.coinSymbol
                        )
                      )
                    ) : checkIsExpandValue(
                        previewResult?.appData?.app_fee,
                        previewResult?.coin_purchased
                      ) ? (
                      <span onClick={() => setShowTi(true)} className="expand">
                        Expand
                      </span>
                    ) : (
                      FormatCurrency(
                        previewResult?.appData?.app_fee,
                        previewResult?.coin_purchased
                      )
                    )}
                  </span>
                </div>
                <div className="sTxt">
                  <span>
                    {FormatCurrency(previewResult?.appData?.app_fee_percentage)}
                    % Of The Fee
                  </span>
                  <span>
                    $
                    {FormatCurrency(previewResult?.appData?.app_fee_usd, 'USD')}{' '}
                    USD
                  </span>
                </div>
              </div>
              <div className="valueBox">
                <div className="mTxt">
                  <span>Banker Fees</span>
                  <span>
                    {feeCoin?.coinSymbol ? (
                      checkIsExpandValue(
                        previewResult?.bankerData?.trade_fee_native *
                          (coinListObject &&
                            coinListObject[previewResult?.coin_purchased]?.price
                              .USD / feeCoin?.price?.USD),
                        feeCoin?.coinSymbol
                      ) ? (
                        <span
                          onClick={() => setShowTi(true)}
                          className="expand"
                        >
                          Expand
                        </span>
                      ) : (
                        FormatCurrency(
                          previewResult?.bankerData?.trade_fee_native *
                            (coinListObject &&
                              coinListObject[previewResult?.coin_purchased]
                                ?.price.USD / feeCoin?.price?.USD),
                          feeCoin?.coinSymbol
                        )
                      )
                    ) : checkIsExpandValue(
                        previewResult?.bankerData?.trade_fee_native,
                        previewResult?.coin_purchased
                      ) ? (
                      <span onClick={() => setShowTi(true)} className="expand">
                        Expand
                      </span>
                    ) : (
                      FormatCurrency(
                        previewResult?.bankerData?.trade_fee_native,
                        previewResult?.coin_purchased
                      )
                    )}
                  </span>
                </div>
                <div className="sTxt">
                  <span>
                    {FormatCurrency(
                      previewResult?.bankerData?.banker_fee_percentage
                    )}
                    % Of The Fee
                  </span>
                  <span>
                    $
                    {FormatCurrency(
                      previewResult?.bankerData?.total_fee_usd,
                      'USD'
                    )}{' '}
                    USD
                  </span>
                </div>
              </div>
            </Scrollbars>
            <div className="totalFees">
              <div className="total">Total Fees</div>
              <div className="totalNative">
                {feeCoin?.coinSymbol
                  ? FormatCurrency(
                      previewResult?.bankerData?.trade_fee_native +
                        previewResult?.appData?.app_fee +
                        previewResult?.brokerData?.fee *
                          (coinListObject &&
                            coinListObject[previewResult?.coin_purchased]?.price
                              .USD / feeCoin?.price?.USD),
                      feeCoin?.coinSymbol
                    )
                  : FormatCurrency(
                      previewResult?.bankerData?.trade_fee_native +
                        previewResult?.appData?.app_fee +
                        previewResult?.brokerData?.fee,
                      previewResult?.coin_purchased
                    )}
              </div>
              <div className="totalUsd">
                $
                {FormatCurrency(
                  previewResult?.bankerData?.total_fee_usd +
                    previewResult?.appData?.app_fee_usd +
                    previewResult?.brokerData?.fee_usd,
                  'USD'
                )}
              </div>
            </div>
            {showTi && (
              <TradeInspector
                tiObject={{
                  sellAmtLabel: 'Affiliate Fees',
                  sellAmt: feeCoin?.coinSymbol
                    ? previewResult?.brokerData?.fee *
                      (coinListObject &&
                        coinListObject[previewResult?.coin_purchased]?.price
                          .USD / feeCoin?.price?.USD)
                    : previewResult?.brokerData?.fee,
                  sellCoin:
                    feeCoin?.coinSymbol || previewResult?.coin_purchased,
                  getAmtLabel: 'Platform Fees',
                  getAmt: feeCoin?.coinSymbol
                    ? previewResult?.appData?.app_fee *
                      (coinListObject &&
                        coinListObject[previewResult?.coin_purchased]?.price
                          .USD / feeCoin?.price?.USD)
                    : previewResult?.appData?.app_fee,
                  getCoin: feeCoin?.coinSymbol || previewResult?.coin_purchased,
                  lblThree: 'Banker Fees',
                  amtThree: feeCoin?.coinSymbol
                    ? previewResult?.bankerData?.trade_fee_native *
                      (coinListObject &&
                        coinListObject[previewResult?.coin_purchased]?.price
                          .USD / feeCoin?.price?.USD)
                    : previewResult?.bankerData?.trade_fee_native,
                  coinThree:
                    feeCoin?.coinSymbol || previewResult?.coin_purchased,
                  lblFour: 'Total Fees',
                  amtFour: feeCoin?.coinSymbol
                    ? previewResult?.bankerData?.trade_fee_native +
                      previewResult?.appData?.app_fee +
                      previewResult?.brokerData?.fee *
                        (coinListObject &&
                          coinListObject[previewResult?.coin_purchased]?.price
                            .USD / feeCoin?.price?.USD)
                    : previewResult?.bankerData?.trade_fee_native +
                      previewResult?.appData?.app_fee +
                      previewResult?.brokerData?.fee,
                  coinFour:
                    feeCoin?.coinSymbol || previewResult?.coin_purchased,
                }}
                setOpenModal={setShowTi}
              />
            )}
          </>
        );
      case step === 'prevCoin':
        return (
          <>
            <input
              type="text"
              placeholder="Search Fiat Currencies"
              className="searchBox"
              value={coinSearch}
              onChange={(e) => setCoinSearch(e.target.value)}
            />
            <div className="tabsCrFi">
              <div
                className={`tabItm ${!isFiat}`}
                onClick={() => setIsFiat(false)}
              >
                Crypto
              </div>
              <div
                className={`tabItm ${isFiat}`}
                onClick={() => setIsFiat(true)}
              >
                Fiat
              </div>
            </div>
            <Scrollbars className="coinListWrapper">
              {coinList
                .filter((coin) => coin.type === (isFiat ? 'fiat' : 'crypto'))
                .filter(
                  (coin) =>
                    coin.coinName
                      .toLowerCase()
                      .includes(coinSearch.toLowerCase()) ||
                    coin.coinSymbol
                      .toLowerCase()
                      .includes(coinSearch.toLowerCase())
                )
                .map((coin) => (
                  <div
                    className="coinItem"
                    onClick={() => {
                      setPreviewCoin(coin);
                      setStep(1);
                    }}
                  >
                    <img src={coin.coinImage} alt="" className="coin" />
                    {coin.coinName}
                    {(previewCoin?.coinSymbol ||
                      previewResult?.purchased_from) === coin.coinSymbol ? (
                      <img src={selectedIcon} alt="" className="selectedTik" />
                    ) : (
                      ''
                    )}
                  </div>
                ))}
            </Scrollbars>
          </>
        );
      case step === 'feeCoin':
        return (
          <>
            <input
              type="text"
              placeholder="Search Fiat Currencies"
              className="searchBox"
              value={coinSearch}
              onChange={(e) => setCoinSearch(e.target.value)}
            />
            <div className="tabsCrFi">
              <div
                className={`tabItm ${!isFiat}`}
                onClick={() => setIsFiat(false)}
              >
                Crypto
              </div>
              <div
                className={`tabItm ${isFiat}`}
                onClick={() => setIsFiat(true)}
              >
                Fiat
              </div>
            </div>
            <Scrollbars className="coinListWrapper">
              {coinList
                .filter((coin) => coin.type === (isFiat ? 'fiat' : 'crypto'))
                .filter(
                  (coin) =>
                    coin.coinName
                      .toLowerCase()
                      .includes(coinSearch.toLowerCase()) ||
                    coin.coinSymbol
                      .toLowerCase()
                      .includes(coinSearch.toLowerCase())
                )
                .map((coin) => (
                  <div
                    className="coinItem"
                    onClick={() => {
                      setFeeCoin(coin);
                      setStep('fee');
                    }}
                  >
                    <img src={coin.coinImage} alt="" className="coin" />
                    {coin.coinName}
                    {(feeCoin?.coinSymbol || previewResult?.coin_purchased) ===
                    coin.coinSymbol ? (
                      <img src={selectedIcon} alt="" className="selectedTik" />
                    ) : (
                      ''
                    )}
                  </div>
                ))}
            </Scrollbars>
          </>
        );
      default:
        break;
    }
  }
  return (
    <>
      <div className="card-dark card trade-card">
        {getContent()}
        {(loading || pathIdLoading) && (
          <div className="lottieLoading">
            <LoadingAnim />
          </div>
        )}
      </div>
      {loginModalOpen && (
        <LoginModal
          onClose={() => {
            setLoginModalOpen(false);
          }}
          onSucces={onLogin}
        />
      )}
    </>
  );
}

export default SellBuyCard;
