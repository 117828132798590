import React, { useContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import Skeleton from 'react-loading-skeleton';
import { APP_USER_TOKEN } from '../../../config';
import { useAppContextDetails } from '../../../context/AppContext';
import { useCoinContextData } from '../../../context/CoinContext';
import { BankContext } from '../../../context/Context';
import { useUserApps } from '../../../queryHooks';
import { LoadingAnimation } from '../../LoadingAnimation';
import { useCoinData } from '../api';
import {
  useLoadBondEarningsBalance,
  useLoadMMEarningsBalance,
  useTransferBondFundApi,
  useTransferMMFundApi,
} from './api';

export const TransferSuccess = () => {
  const [loadBalance, setLoadBalance] = useState(false);
  const {
    transferAmount,
    selectedCoin,
    setUpdateBondVaultData,
    transferMethod,
    setUpdateMMVaultData,
  } = useCoinContextData();
  const { email } = useContext(BankContext);
  const { appLogo, appCode } = useAppContextDetails();
  const { data: userApps, isLoading: userAppsLoading } = useUserApps(email);
  const userIceAppData = userApps?.filter((e) => e.app_code === 'ice');
  const iceAppProfileId = userIceAppData?.[0]?.profile_id;

  const userAppData = userApps?.filter((e) => e.app_code === appCode);
  const appProfileId = userAppData?.[0]?.profile_id;

  const userToken = Cookies.get(APP_USER_TOKEN);
  const isTransferViaBond = transferMethod === 'bonds';
  const isTransferViaMM = transferMethod === 'moneymarkets';

  const { data: bondEarningsResponse, isLoading: isLoadingBalance } =
    useLoadBondEarningsBalance(email, transferMethod, loadBalance);
  const { data: moneyMarketEarningsResponse } = useLoadMMEarningsBalance(
    email,
    appCode,
    transferMethod,
    loadBalance
  );

  const bondsBalances = bondEarningsResponse?.data?.result?.[0]?.balances;
  const moneyMarketBalances =
    moneyMarketEarningsResponse?.data?.result?.[0]?.balances?.[0]
      ?.liquid_balances;
  const availableBalance = isTransferViaBond
    ? bondsBalances
    : moneyMarketBalances;

  const currentCoinData = availableBalance?.filter(
    (coin) => coin?.coinSymbol === selectedCoin.coinSymbol
  );
  const coinBalance = currentCoinData?.[0]?.coinValue;

  const { mutate: transferBondFund, isLoading: isTransferringBond } =
    useTransferBondFundApi(setLoadBalance, setUpdateBondVaultData);
  const { mutate: transferMMFund, isLoading: isTransferringMM } =
    useTransferMMFundApi(setLoadBalance, setUpdateMMVaultData);

  const { mutate: getCoinData, data: coinResponse } = useCoinData();
  const selectedCoinBalance = coinResponse?.data?.coins_data?.[0]?.coinValue;

  useEffect(() => {
    if (iceAppProfileId && isTransferViaBond) {
      transferBondFund({
        email,
        token: userToken,
        app_code: 'ice',
        profile_id: iceAppProfileId,
        coin: selectedCoin.coinSymbol,
        amount: transferAmount,
        toAppcode: appCode,
      });
    }
  }, [iceAppProfileId, transferMethod]);

  useEffect(() => {
    if (appProfileId && isTransferViaMM) {
      transferMMFund({
        email,
        token: userToken,
        app_code: appCode,
        profile_id: appProfileId,
        coin: selectedCoin.coinSymbol,
        amount: transferAmount,
      });
    }
  }, [appProfileId, transferMethod]);

  useEffect(() => {
    if (coinBalance) {
      getCoinData({
        app_code: appCode,
        email,
        include_coins: [selectedCoin.coinSymbol],
      });
    }
  }, [coinBalance]);

  const isLoading =
    userAppsLoading ||
    isTransferringBond ||
    isTransferringMM ||
    isLoadingBalance;

  return (
    <>
      <div
        className="sidebar-menu mt-3 transfer-amount-wrapper"
        style={{ opacity: isLoading ? 0.25 : 1 }}
      >
        <p className="header congrats">
          Congratulations. You Have Successully Transferred {transferAmount}{' '}
          {selectedCoin.coinSymbol} To Your Liquid Vault
        </p>

        <div className="mt-5">
          <p className="header">
            {selectedCoin.coinSymbol}{' '}
            {isTransferViaBond
              ? 'Bond Earnings Vault Balance'
              : 'MM Earnings Vault Balance'}
          </p>
        </div>
        {isLoadingBalance ? (
          <Skeleton className="balance-loading" />
        ) : (
          <div className="transfer-sending-amount-box">
            <div className="div-amount-input">
              <input
                className="transfer-input"
                placeholder="0.00"
                key="add"
                id="add"
                type="number"
                value={coinBalance}
              />
              <div className="transfrom-from-coin" key="add">
                <img
                  className="coinimgsmall"
                  src={selectedCoin?.coinImage}
                  alt=""
                />
                <p className="coinsymbolsmall">{selectedCoin?.coinSymbol}</p>
              </div>
            </div>
          </div>
        )}

        <div className="mt-5">
          <p className="header">
            {selectedCoin.coinSymbol} Liquid Vault Balance
          </p>
        </div>
        <div className="transfer-sending-amount-box">
          <div className="div-amount-input">
            <input
              className="transfer-input"
              placeholder="0.00"
              key="add"
              id="add"
              type="number"
              value={selectedCoinBalance}
            />
            <div className="transfrom-from-coin" key="add">
              <img
                className="coinimgsmall"
                src={selectedCoin?.coinImage}
                alt=""
              />
              <p className="coinsymbolsmall">{selectedCoin?.coinSymbol}</p>
            </div>
          </div>
        </div>
      </div>

      {isLoading && (
        <div className="coin-address-loading">
          <LoadingAnimation icon={appLogo} width={200} />
        </div>
      )}
    </>
  );
};
