import React, { useEffect, useState } from 'react';
import VaultPageContextProvider, {
  VaultPageContext,
} from '../../../context/VaultPageContext';
import classNames from '../../../pages/VaultsPage/vaultsPage.module.scss';
import { DividentVault } from '../../../components/VaultsPage/VaultComponent/DividentVault';
export default function Index({ shareTokenTranscation, SLoading }) {
  return (
    <>
      <VaultPageContextProvider>
        <DividentVault
          shareTokenTranscation={shareTokenTranscation}
          SLoading={SLoading}
          // getLink={getLink}
          // passvaulterFilerWithdraw={passvaulterFilerWithdraw}
          // passvaulterFiler={passvaulterFiler}
          // passTypeTransaction={passTypeTransaction}
        />
      </VaultPageContextProvider>
    </>
  );
}
