import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';

import Layout from '../Layout/Index';
import VaultPageHead from '../components/InvestmentVaultsPage/VaultPageHead';
import VaultTransactionTable from '../components/InvestmentVaultsPage/VaultTransactionTable';
import VaultControlls from '../components/InvestmentVaultsPage/VaultControlls';
import { BankContext } from '../context/Context';

function InvestmentVaults({ match }) {
  const { email } = useContext(BankContext);
  if (!email) {
    return <Redirect to="/" />;
  }
  return (
    <Layout active={`investmentVault`} className="vaults" footerMain>
      <VaultPageHead />
      <VaultControlls />
      <VaultTransactionTable
        credit={!(match.params.type === 'deposit')}
        debit={!(match.params.type === 'withdraw')}
        investmentCoin={true}
      />
    </Layout>
  );
}

export default InvestmentVaults;
