import React, { useEffect, useRef } from 'react';

function SignupSetName({
  setStepName,
  logo,
  fName,
  lName,
  setFName,
  setLName,
  setSignupStep,
}) {
  const ref = useRef();
  useEffect(() => {
    setTimeout(() => {
      ref && ref.current.focus();
    }, 200);
  }, []);
  return (
    <div className="stepWrapper stepForm">
      <img src={logo} alt="assetLogo" className="assetLogo" />
      <div className="stepDesc">Enter Your First And Last Name</div>
      <div className="group">
        <input
          ref={ref}
          type="text"
          className="input email"
          placeholder="First Name"
          value={fName}
          onChange={(e) => setFName(e.target.value)}
        />
      </div>
      <div className="group">
        <input
          type="text"
          className="input email"
          placeholder="Last Name"
          value={lName}
          onChange={(e) => setLName(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setSignupStep(1);
            }
          }}
        />
      </div>
      <div className="btns">
        <div className="btnBig" onClick={() => setStepName('')}>
          Back To Login
        </div>
        <div className="btnNext" onClick={() => setSignupStep(1)}>
          Next
        </div>
      </div>
    </div>
  );
}

export default SignupSetName;
