import React from 'react';
import './table.scss';
import { useAppContextDetails } from '../../context/AppContext';
import { allFunds, SeletedFunds } from './apidata';
import Skeleton from 'react-loading-skeleton';
export default function Table({ handleBuy, setdrawerToggle }) {
  const {
    setFundsTab,
    FundsTab,
    setStoreFundsDetail,
    StoreFundsDetail,
    fundsDetailTable,
    cardNameFunds,
    setfundsDetailTable,
    setfinaldata,
    finaldata,
    setcardNameFunds,
  } = useAppContextDetails();
  const table = [
    {
      name: 'Fund',
    },
    {
      name: 'Price',
    },
    {
      name: 'ROI (%)',
    },
    {
      name: 'Supply',
    },
    {
      name: 'Market Cap',
    },
    {
      name: 'Investors',
    },
    {
      name: 'Actions',
    },
  ];
  const callhander = (e) => {
    // setfinaldata(e);
    setcardNameFunds(e.name);
    fundssSeleted(e.code);
  };
  const fundssSeleted = async (e) => {
    await setfundsDetailTable([]);
    await SeletedFunds(e).then((response) => {
      let result = response?.data?.paths;
      setfundsDetailTable(result);
    });
  };
  return (
    //
    <div className="funds-content" onClick={() => setdrawerToggle(false)}>
      <div className="left-fund-container">
        <div className="cards-funds">
          <div className="Tablecard">
            {StoreFundsDetail.map((item) => {
              return (
                <div
                  className="innerTablecard"
                  onClick={() => callhander(item)}
                  style={{
                    boxShadow:
                      cardNameFunds == item.name
                        ? '0px 4px 4px rgba(0, 0, 0, 0.25)'
                        : '0px',
                    opacity: cardNameFunds == item.name ? 1 : 0.5,
                  }}
                >
                  <img src={item?.icon} />
                  <div className="fundsNamesub">{item?.name}</div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="TableSection">
          <div className="headerFunds">
            {table.map((item) => {
              return <div className="fund-title">{item.name}</div>;
            })}
          </div>
          <div className="fundBody">
            {fundsDetailTable?.length < 1 ? (
              <>
                <div className="InnerFundsBody">
                  <Skeleton className="sectionskeleton" />
                  <Skeleton className="sectionskeleton" />
                  <Skeleton className="sectionskeleton" />
                  <Skeleton className="sectionskeleton" />
                  <Skeleton className="sectionskeleton" />
                  <Skeleton className="sectionskeleton" />
                  <Skeleton className="sectionskeleton" />
                </div>
                <div className="InnerFundsBody">
                  <Skeleton className="sectionskeleton" />
                  <Skeleton className="sectionskeleton" />
                  <Skeleton className="sectionskeleton" />
                  <Skeleton className="sectionskeleton" />
                  <Skeleton className="sectionskeleton" />
                  <Skeleton className="sectionskeleton" />
                  <Skeleton className="sectionskeleton" />
                </div>
                <div className="InnerFundsBody">
                  <Skeleton className="sectionskeleton" />
                  <Skeleton className="sectionskeleton" />
                  <Skeleton className="sectionskeleton" />
                  <Skeleton className="sectionskeleton" />
                  <Skeleton className="sectionskeleton" />
                  <Skeleton className="sectionskeleton" />
                  <Skeleton className="sectionskeleton" />
                </div>
              </>
            ) : (
              <>
                {fundsDetailTable?.map((item) => {
                  return (
                    <>
                      <div className="InnerFundsBody">
                        <div className="sectionLeftBody">
                          <div className="img-table-frist">
                            <img
                              src={item?.token_profile_data?.coinImage}
                              className="logo-fund"
                            />
                            <div>
                              <div
                                className="text-funds"
                                style={{
                                  fontWeight: 600,
                                }}
                              >
                                {item?.token_profile_data?.coinName}
                              </div>

                              <div className="img-table-frist">
                                <div className="text-funds-inner">
                                  {item?.token_profile_data?.coinSymbol}
                                </div>
                                <img
                                  src={item?.asset_metaData?.coinImage}
                                  className="logo-fund-inner "
                                />
                                <div
                                  className="text-funds-inner"
                                  style={{
                                    fontWeight: 700,
                                  }}
                                >
                                  {item?.asset_metaData?.coinName}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="text-funds">
                          {item?.token_price?.toFixed(4)}
                        </div>
                        <div
                          className="text-funds"
                          style={{
                            color: '#4CAF50',
                          }}
                        >
                          0.0000 (0.00%)
                        </div>
                        <div className="text-funds">
                          {item?.circulating_supply?.toFixed(2)}
                        </div>
                        <div className="text-funds">
                          {item?.asset_balance?.toFixed(2)}
                        </div>
                        <div className="text-funds">
                          {item?.tokenHoldersCount}
                        </div>
                        <div className="labelcover">
                          <div
                            className="buylabel"
                            onClick={() => handleBuy(item)}
                          >
                            Buy
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
