import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../../../context/AppContext';
import { BankContext } from '../../../context/Context';
import SwitcherSearch from '../../MobilePages/SwitcherSearch';

const SellStepTwo = () => {
  const {
    selectedOperation,
    appCode,
    token,
    selectedAsset,
    setSelectedAsset,
    setBuySellStep,
    tradeResponse,
    setTradeResponse,
  } = useContext(AppContext);
  const { email, profileId } = useContext(BankContext);
  const [openSearch, setOpenSearch] = useState(false);
  const [assetList, setAssetList] = useState([]);

  // useEffect(() => {
  //   if (activeCrypto) {
  //     getUpdatedFromBalance(activeCrypto?.coinSymbol);
  //   }
  // }, [activeCrypto]);
  // useEffect(() => {
  //   getUpdatedToBalance(selectedCoin.coinSymbol);
  // }, []);

  // const getUpdatedToBalance = async (coin) => {
  //   axios
  //     .post(`https://comms.globalxchange.io/coin/vault/service/coins/get`, {
  //       app_code: appCode,
  //       email: email,
  //       displayCurrency: 'INR',
  //       include_coins: [coin],
  //     })
  //     .then(({ data }) => {
  //       setToBalance(data.coins_data[0].coinValue);
  //     });
  // };

  // const getUpdatedFromBalance = async (coin) => {
  //   axios
  //     .post(`https://comms.globalxchange.io/coin/vault/service/coins/get`, {
  //       app_code: appCode,
  //       email: email,
  //       displayCurrency: 'INR',
  //       include_coins: [coin],
  //     })
  //     .then(({ data }) => {
  //       setFromBalance(data.coins_data[0].coinValue);
  //       getPathId();
  //     });
  // };
  // const getPathId = () => {
  //   axios
  //     .get(
  //       `https://comms.globalxchange.io/coin/vault/service/payment/stats/get`,
  //       {
  //         params: {
  //           select_type: 'instant',
  //           to_currency: selectedCoin.coinSymbol,
  //           from_currency: activeCrypto?.coinSymbol,
  //           paymentMethod: 'vault',
  //           banker: 'Global X Change',
  //           app_code: appCode,
  //         },
  //       }
  //     )
  //     .then(({ data }) => {
  //       setPathId(data.pathData.paymentPaths[0].path_ids[0]);
  //     });
  // };

  // const getToValue = async () => {
  //   setLoading(true);

  //   axios
  //     .post(
  //       `https://comms.globalxchange.io/coin/vault/service/trade/execute`,
  //       {
  //         token: token,
  //         email: email,
  //         app_code: appCode,
  //         profile_id: profileId,
  //         from_amount: fromValue,
  //         stats: true,
  //         identifier: `Exchange ${activeCrypto?.coinSymbol} For ${selectedCoin?.coinSymbol}`,
  //         path_id: pathId,
  //       }
  //     )
  //     .then(({ data }) => {
  //       setToData(data);
  //       setLoading(false);
  //     });
  // };

  // useEffect(() => {
  //   if (fromValue && activeCrypto) {
  //     getToValue();
  //   }
  // }, [fromValue, activeCrypto]);

  // const calcValue = (perc) => {
  //   var value = (Number(fromBalance) * Number(perc)) / 100;

  //   setFromValue(value.toString());
  // };

  // const conditionalToData = () => {
  //   if (loading) {
  //     return (
  //       <Text style={[styles.debitAmountStyle, { opacity: 0.5 }]}>
  //         Loading ...
  //       </Text>
  //     );
  //   } else {
  //     if (toData) {
  //       return (
  //         <Text style={styles.debitAmountStyle}>{toData?.finalToAmount}</Text>
  //       );
  //     } else {
  //       return (
  //         <Text style={[styles.debitAmountStyle, { opacity: 0.5 }]}>
  //           0.0000
  //         </Text>
  //       );
  //     }
  //   }
  // };

  // const handlePlaceTrade = async () => {
  //   setTradeLoading(true);
  //   const email = await AsyncStorageHelper.getLoginEmail();
  //   const token = await AsyncStorageHelper.getAppToken();
  //   const profileId = await AsyncStorageHelper.getProfileId();

  //   axios
  //     .post(
  //       `https://comms.globalxchange.io/coin/vault/service/trade/execute`,
  //       {
  //         token: token,
  //         email: email,
  //         app_code: appCode,
  //         profile_id: profileId,
  //         from_amount: fromValue,
  //         stats: false,
  //         identifier: `Exchange ${activeCrypto?.coinSymbol} For ${selectedCoin?.coinSymbol}`,
  //         path_id: pathId,
  //       }
  //     )
  //     .then(({ data }) => {
  //       if (data.status) {
  //         setTradeLoading(false);
  //         navigate('BuyCryptoStepTwo', {
  //           tradeResponse: data,
  //         });
  //       }
  //     });
  // };

  useEffect(() => {
    axios
      .get(
        `https://comms.globalxchange.io/coin/vault/service/payment/stats/get`,
        {
          params: {
            select_type: 'instant',
            to_currency: selectedOperation?.coinData?.coinSymbol,
            paymentMethod: 'vault',
            banker: 'Global X Change',
            app_code: 'indianotc',
          },
        }
      )
      .then(({ data }) => {
        if (data.status) {
          const tempArr = [];

          data.pathData.from_currency.map((item) => {
            tempArr.push(item.coin_metadata);
          });

          setAssetList(tempArr);
        }
      });
  }, []);

  return (
    <div>
      {!openSearch ? (
        <>
          {/* <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '20px 0px',
          // borderBottom: '1px solid #e7e7e7',
        }}
      >
        <img
          src={selectedOperation?.coinData?.coinImage}
          alt=""
          style={{ width: '30px', height: '30px' }}
        />
        <div
          style={{
            fontSize: '20px',
            fontWeight: 'bold',
            paddingLeft: '10px',
          }}
        >
          {selectedOperation?.coinData?.coinName}
        </div>
      </div> */}
          <div className="breadcrumb">
            <span>Sell {selectedOperation?.coinData?.coinName}</span>
            &nbsp;&nbsp;
            {`>`}&nbsp;&nbsp;
            <span style={{ fontWeight: 700 }}>Success</span>
          </div>
          <div
            className="containerStyle"
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '80vh',
            }}
          >
            <div>
              <div>
                Congratulations. You Have Successully Exchanged{' '}
                {tradeResponse?.user_debit?.amount}{' '}
                {tradeResponse?.user_debit?.coin} for{' '}
                {tradeResponse?.user_credit?.amount}
                {tradeResponse?.user_credit?.coin}
              </div>

              <div>
                {/* From Input */}
                <div className="inputCardWithLabel">
                  <div className="labelText">
                    <div>
                      New {tradeResponse?.userResult?.debit_obj?.coin} Balance
                    </div>
                  </div>
                  <div className="inputStyle">
                    <div style={{ width: '60%' }}>
                      <input
                        type="number"
                        value={tradeResponse?.userResult?.debit_obj?.updated_balance?.toFixed(
                          4
                        )}
                      />
                    </div>
                    <div style={{ width: '40%' }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                          src={selectedAsset?.coinImage}
                          alt=""
                          style={{ width: '20px', height: '20px' }}
                        />
                        <span
                          className="inputButton"
                          style={{ padding: '0px 10px' }}
                        >
                          {tradeResponse?.userResult?.debit_obj?.coin}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="inputCardWithLabel">
                  <div className="labelText">
                    <div>
                      New {tradeResponse?.bankerResult?.credit_obj?.coin}{' '}
                      Balance
                    </div>
                  </div>
                  <div className="inputStyle">
                    <div style={{ width: '60%' }}>
                      <input
                        type="number"
                        value={tradeResponse?.bankerResult?.credit_obj?.updated_balance?.toFixed(
                          4
                        )}
                      />
                    </div>
                    <div style={{ width: '40%' }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                          src={selectedOperation?.coinData?.coinImage}
                          alt=""
                          style={{ width: '20px', height: '20px' }}
                        />
                        <span
                          className="inputButton"
                          style={{ padding: '0px 10px' }}
                        >
                          {selectedOperation?.coinData?.coinSymbol}
                        </span>
                        <span>{`>`}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Buttons */}
            <div className="halfButtonWrapper">
              <div onClick={(e) => setBuySellStep(null)}>Go Back</div>
            </div>
          </div>
        </>
      ) : (
        <SwitcherSearch
          path={'BuyStepOne'}
          listData={assetList}
          setSelectedData={setSelectedAsset}
          setOpenSearch={setOpenSearch}
        />
      )}
    </div>
  );
};

export default SellStepTwo;
