import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import classNames from './marketsPage.module.scss';
import tradeStream from '../../static/images/sidebarIcons/tradeStream.svg';
import searchIcon from '../../static/images/search.svg';
import fulllogo from '../../static/images/logos/stakingFullLogo.svg';
import ddown from '../../static/images/malls/ddown.svg';
import ddGrey from '../../static/images/icons/ddwonGrey.svg';
import { useBankerList, useShareTokensDetail } from '../../queryHooks';
import { SIDEBAR_FOOTERS, MARKET_ASSET_CLASSES1 } from '../../config/constants';
import { BankContext } from '../../context/Context';
import { GetSortOrder } from '../../utils/FunctionTools';
import MarketAssetDetail from '../../components/MarketAssetDetail';
import TerminalsSidebar from '../../components/TerminalsSidebar';
import LoginModal from '../../components/LoginModalNew';
import Layout from '../../Layout/Layout';
import MarketTableCrypto from './components/MarketTableCrypto';
import MarketTableForex from './components/MarketTableForex';
import MarketTableShares from './components/MarketTableShares';
import MarketTableBonds from './components/MarketTableBonds';
import { BondContractDetails } from './components/BondContractDetails';
import { useMarketContext } from '../../context/MarketContext';
import CreateBond from '../../components/CreateBond';
import { LoadingAnimation } from '../../components/LoadingAnimation';
import { useAppContextDetails } from '../../context/AppContext';
import ToastMessage from '../../components/ToastMessage/ToastMessage';
import Investments from './components/Investments';

import banner1 from '../../assets/images/DefiMarkets/banner1.png';
import banner2 from '../../assets/images/DefiMarkets/banner2.png';
import banner3 from '../../assets/images/DefiMarkets/banner3.png';
import banner4 from '../../assets/images/DefiMarkets/banner4.png';
import btc from '../../static/images/coin-small/btc1.svg';
import axios from 'axios';
import Staking from './components/Staking';

import defiIcon from '../../static/images/sidebar-icons/defiIcon.svg';
import navigateIcon from '../../static/images/icons/navigate.svg';
import { RegistrationContext } from '../../context/RegistrationContext';
import Skeleton from 'react-loading-skeleton';
import SelectBondAssetModal from '../../components/SelectBondAssetModal';
import SelectBondIssuerModal from '../../components/SelectBondIssuerModal';

import doubleTik from '../../static/images/clipIcons/doubleTik.svg';

function MarketsPage() {
  const history = useHistory();
  const { assetClassName, coin } = useParams();
  const [assetClass, setAssetClass] = useState(MARKET_ASSET_CLASSES1[0]);
  const [index, setIndex] = useState(2);
  const { email, sidebarCollapse, profileId, token, iceProfileId } =
    useContext(BankContext);
  const { appLogo } = useAppContextDetails();
  const {
    streamOpen,
    setStreamOpen,
    defiTab,
    setDefiTab,
    defiDropdown,
    setDefiDropdown,
    defiSelectedDropdown,
    setDefiSelectedDropdown,
    coinSelected,
    setCoinSelected,
    buyContract,
    setBuyContract,
    buyContractStep,
    setBuyContractStep,
    selectedAsset,
    setSelectedAsset,
    coinBalance,
    setCoinBalance,
    contractCost,
    setContractCost,
    setContractId,
    selectedContractId,
    setSelectedContractId,
  } = useMarketContext();

  // const { appData } = useContext(RegistrationContext);
  const { data: bankerList = [] } = useBankerList();
  const [search, setSearch] = useState('');
  const [selectedSidebarMenu, setSelectedSidebarMenu] = useState('Bonds');
  const ref = useRef();
  const [contractValue, setcontractValue] = useState(1);
  const [loading, setLoading] = useState(true);
  const [updatedVaultBalance, setUpdatedVaultBalance] = useState([]);
  const [bondHash, setBondHash] = useState('');
  const [toast, setToast] = useState('');
  const { pathname } = useLocation();
  const [clicked, setClicked] = useState('');
  const [allCoinCards, setAllCoinCards] = useState([]);

  const { data: shareToken } = useShareTokensDetail(coin);
  const [allBonds, setAllBonds] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [currenciesData, setCurrenciesData] = useState([]);
  const [loadingCurrencies, setLoadingCurrencies] = useState(false);

  const [assetModal, setAssetModal] = useState(false);
  const [issuerModalOpen, setIssuerModalOpen] = useState(false);

  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);

  const [bondAsset, setBondAsset] = useState({
    name: 'All Bond Assets ',
    coin: undefined,
    icon: doubleTik,
  });
  const [assetIssuer, setAssetIssuer] = useState({
    displayName: 'All Issuers',
    bankerTag: undefined,
    profilePicURL: doubleTik,
  });

  const getBankerDetail = useCallback(
    (bankerTag) =>
      bankerList.filter((banker) => banker?.bankerTag === bankerTag)[0],
    [bankerList]
  );

  const getDataForOneBondTemplateID = (templateId) => {
    axios
      .post('https://comms.globalxchange.io/coin/iced/banker/custom/bond/get', {
        bond_template_id: templateId,
      })
      .then((response) => {
        let result = response?.data;
        // console.log(result, 'result');
        setSelectedAsset(result);
        setContractCost(result?.custom_bonds?.[0]?.coinsData?.[0]?.bondCost);
      })
      .catch((error) => {
        console.log(error, 'templateId error');
      });
  };

  const getBalance = () => {
    let obj = {
      app_code: 'dgpayments',
      email,
      include_coins: [selectedCurrency?.coin],
    };
    axios
      .post('https://comms.globalxchange.io/coin/vault/service/coins/get', obj)
      .then((response) => {
        let result = response?.data?.coins_data?.[0];
        // console.log(result, 'balance result');
        setCoinBalance(result);
      })
      .catch((error) => {
        console.log(error, 'getBalance error');
      });
  };

  useEffect(() => {
    const classSelected = MARKET_ASSET_CLASSES1.filter(
      (asCls) => asCls.name === assetClassName
    );
    if (classSelected[0]) setAssetClass(classSelected[0]);
  }, [assetClassName]);

  useEffect(() => {
    if (assetClassName === 'shares' && shareToken?.token_profile_data) {
      setCoinSelected(shareToken?.token_profile_data);
    }
  }, [coin, shareToken, assetClassName]);

  useEffect(() => {
    ref.current.addEventListener('scroll', scrollHandle);
    return () => {
      ref.current.removeEventListener('scroll', scrollHandle);
    };
  }, []);

  useEffect(() => {
    if (pathname.includes('myContracts')) {
      setDefiTab('My Contracts');
    }
  }, [pathname]);

  const [active, setActive] = useState(false);
  const scrollHandle = (event) => {
    setActive(
      Boolean(
        event?.path?.[0]?.scrollTop >=
          event?.path?.[0].childNodes?.[1].childNodes?.[0].childNodes?.[1]
            ?.offsetTop
      )
    );
  };

  function selectTable() {
    switch (assetClass?.name) {
      case 'crypto':
        return (
          <MarketTableCrypto
            streamOpen={streamOpen}
            setCoinSelected={setCoinSelected}
            assetClass={assetClass}
            active={active}
            search={search}
          />
        );
      case 'fiat':
        return (
          <MarketTableForex
            streamOpen={streamOpen}
            setCoinSelected={setCoinSelected}
            assetClass={assetClass}
            search={search}
          />
        );
      case 'shares':
        return (
          <MarketTableShares
            streamOpen={streamOpen}
            setCoinSelected={setCoinSelected}
            assetClass={assetClass}
            search={search}
          />
        );
      case 'bonds':
        return (
          <MarketTableBonds
            streamOpen={streamOpen}
            setCoinSelected={setCoinSelected}
            assetClass={assetClass}
            active={active}
            search={search}
          />
        );
      case 'stakingVaults':
        return <CreateBond />;
      case 'myContracts':
        return <Staking />;

      default:
        return '';
    }
  }

  const updatedBalance = () => {
    if (selectedAsset?.custom_bonds?.[0]?.coinsData?.[0]?.coin) {
      let obj = {
        app_code: 'dgpayments',
        email,
        include_coins: [selectedAsset?.custom_bonds?.[0]?.coinsData?.[0]?.coin],
      };
      axios
        .post(
          'https://comms.globalxchange.io/coin/vault/service/coins/get',
          obj
        )

        .then((response) => {
          let result = response?.data?.coins_data;
          // console.log(result, 'updatedBalance result');
          // console.log(result?.[0]?.coinSymbol, 'coinSymbol');
          setLoading(false);
          setUpdatedVaultBalance(result?.[0]);
          setBuyContractStep('step3');
        })
        .catch((error) => {
          console.log(error, 'updatedBalance error');
        });
    }
  };

  const buyStakingContract = () => {
    if (
      selectedAsset?.custom_bonds?.[0]?.coinsData?.[0]?.coin &&
      selectedAsset?.custom_bonds?.[0]?.days &&
      selectedAsset?.custom_bonds?.[0]?.bond_template_id
    ) {
      let obj = {
        email,
        token,
        origin_app_code: 'dgpayments',
        profile_id: iceProfileId,
        coin: selectedAsset?.custom_bonds?.[0]?.coinsData?.[0]?.coin,
        days: selectedAsset?.custom_bonds?.[0]?.days,
        num_of_bonds: contractValue,
        simulate: false,
        payCoin: selectedAsset?.custom_bonds?.[0]?.coinsData?.[0]?.coin,
        stats: false,
        bond_type: 'custom',
        bond_template_id: selectedAsset?.custom_bonds?.[0]?.bond_template_id,
        debitfromApp: 'dgpayments',
        debitfromProfileId: profileId,
      };
      axios
        .post('https://comms.globalxchange.io/coin/iced/contract/create', obj)
        .then((response) => {
          let result = response?.data?.message;
          let hash = response?.data?.contract_id;
          if (result === 'Successfully Created the Iced contract') {
            updatedBalance();
            setBondHash(hash);
          } else {
            setLoading(false);
            setToast(result);
          }
        })
        .catch((error) => {
          console.log(error, 'buyStakingContract error');
        });
    }
  };

  const percentageCalc = (value) => {
    if (value == 50) {
      value = 0.5;
    } else {
      value = 1;
    }
    let balance = coinBalance?.coinValue;
    balance = value * balance;
    let contracts = balance / contractCost;
    if (contracts < 1) {
      contracts = 1;
      setcontractValue(contracts);
    } else {
      contracts = Math.floor(contracts);
      setcontractValue(contracts);
    }
  };

  const [loginModalOpen, setLoginModalOpen] = useState(false);

  if (!email) {
    history.push('/');
  }

  const getallBonds = async (coinSymbol) => {
    setLoading(true);
    // console.log(coinSymbol, selectedCurrency, 'kwjdgkwjefkwjfe');
    const res = await axios.get(
      `https://comms.globalxchange.io/coin/iced/banker/custom/bond/list/data/get?user_email=${email}&email=dgpayagent@gmail.com&coin=${
        selectedCurrency ? selectedCurrency?.coin : coinSymbol
      }`
    );

    if (res.data.status) {
      setAllBonds([...res.data.bondsListData]);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (selectedCurrency?.coin) {
      getallBonds(selectedCurrency?.coin);
    }
  }, [selectedCurrency]);

  useEffect(() => {
    setLoadingCurrencies(true);
    axios
      .get(
        `https://comms.globalxchange.io/coin/iced/banker/custom/bond/get?status=active&email=dgpayagent@gmail.com`
      )
      .then(({ data }) => {
        if (data.status) {
          const tempArr = Object.values(data.currenciesData);
          // console.log(tempArr[0].coin, 'kwjdgkwjefkwjfe');
          setCurrenciesData([...tempArr]);
          setSelectedCurrency(tempArr[0]);
          setLoadingCurrencies(false);
        } else {
          setLoadingCurrencies(false);
        }
      });
  }, []);

  const stakingContractsTable = () => {
    return (
      <div className={classNames.tableWrapper}>
        <div className={classNames.tableHeader}>
          <div className={classNames.tableHeaderItem}>Cost</div>
          <div className={classNames.tableHeaderItem}>Length</div>
          <div className={classNames.tableHeaderItem}>Daily Reward</div>
          <div className={classNames.tableHeaderItem}>Monthly Reward</div>
          <div className={classNames.tableHeaderItem}>Annual Reward</div>
          <div className={classNames.tableHeaderItem}>Term Reward</div>
          <div className={classNames.tableHeaderItem}></div>
        </div>
        <div
          className={classNames.tableBody}
          style={{ height: window.innerHeight / 1.8 }}
        >
          {!loading
            ? allBonds?.map((item, i) => (
                <div
                  className={classNames.tableheader}
                  style={{ paddingVertical: 25 }}
                >
                  <div className={classNames.tableheaderItem}>
                    <h2 className={classNames.cardTitle}>
                      {item?.coinsData[0]?.bondCost?.toFixed(4)}
                    </h2>
                    <p className={classNames.cardSubTitle}>
                      $ {item?.coinsData[0]?.bondCost_usd?.toFixed(2)}
                    </p>
                  </div>
                  <div className={classNames.tableheaderItem}>
                    <h2 className={classNames.cardTitle}>
                      {item?.months?.toFixed(0)} Months
                    </h2>
                    <p className={classNames.cardSubTitle}>{item?.days} Days</p>
                  </div>
                  <div className={classNames.tableheaderItem}>
                    <h2 className={classNames.cardTitle}>
                      %{item?.daily_interest_rate?.toFixed(3)}
                    </h2>
                    <p className={classNames.cardSubTitle}>
                      {item?.coinsData[0]?.perDayEarnings?.toFixed(4)}
                    </p>
                  </div>
                  <div className={classNames.tableheaderItem}>
                    <h2 className={classNames.cardTitle}>
                      %{item?.monthly_interest_rate?.toFixed(3)}
                    </h2>
                    <p className={classNames.cardSubTitle}>
                      {item?.coinsData[0]?.monthlyEarnings?.toFixed(4)}
                    </p>
                  </div>
                  <div className={classNames.tableheaderItem}>
                    <h2 className={classNames.cardTitle}>
                      %{item?.annual_interest_rate?.toFixed(3)}
                    </h2>
                    <p className={classNames.cardSubTitle}>
                      {item?.coinsData[0]?.annualEarnings?.toFixed(4)}
                    </p>
                  </div>
                  <div className={classNames.tableheaderItem}>
                    <h2 className={classNames.cardTitle}>
                      %{item?.term_interest_rate?.toFixed(3)}
                    </h2>
                    <p className={classNames.cardSubTitle}>
                      {item?.coinsData[0]?.termEarnings?.toFixed(4)}
                    </p>
                  </div>
                  <div className={classNames.tableheaderItem1}>
                    <button
                      className={classNames.buyButton}
                      onClick={() => {
                        setBuyContract(true);
                        setCoinSelected({
                          item,
                          coinOb: item?.coinsData?.[0]?.coin,
                          banker: getBankerDetail(coin?.bankerData?.bankerTag),
                        });
                        // selectedAsset(item);
                        getDataForOneBondTemplateID(item?.bond_template_id);
                        getBalance();
                      }}
                    >
                      Buy
                    </button>
                  </div>
                </div>
              ))
            : Array(5)
                .fill('')
                .map((item) => {
                  return (
                    <div
                      className={classNames.tableheader}
                      style={{ paddingVertical: 25 }}
                    >
                      <div className={classNames.tableheaderItem}>
                        <h2 className={classNames.cardTitle}>
                          <Skeleton width={100} height={10} />
                        </h2>
                        <Skeleton width={80} height={5} />
                      </div>
                      <div className={classNames.tableheaderItem}>
                        <h2 className={classNames.cardTitle}>
                          <Skeleton width={100} height={10} />
                        </h2>
                        <p className={classNames.cardSubTitle}>
                          <Skeleton width={80} height={5} />
                        </p>
                      </div>
                      <div className={classNames.tableheaderItem}>
                        <h2 className={classNames.cardTitle}>
                          <Skeleton width={100} height={10} />
                        </h2>
                        <p className={classNames.cardSubTitle}>
                          <Skeleton width={80} height={5} />
                        </p>
                      </div>
                      <div className={classNames.tableheaderItem}>
                        <h2 className={classNames.cardTitle}>
                          <Skeleton width={100} height={10} />
                        </h2>
                        <p className={classNames.cardSubTitle}>
                          <Skeleton width={80} height={5} />
                        </p>
                      </div>
                      <div className={classNames.tableheaderItem}>
                        <h2 className={classNames.cardTitle}>
                          <Skeleton width={100} height={10} />
                        </h2>
                        <p className={classNames.cardSubTitle}>
                          <Skeleton width={80} height={5} />
                        </p>
                      </div>
                      <div className={classNames.tableheaderItem}>
                        <h2 className={classNames.cardTitle}>
                          <Skeleton width={100} height={10} />
                        </h2>
                        <p className={classNames.cardSubTitle}>
                          <Skeleton width={80} height={5} />
                        </p>
                      </div>
                    </div>
                  );
                })}
        </div>
      </div>
    );
  };

  return (
    <Layout active="market" className={classNames.layoutManagement} hideFooter>
      <div className={classNames.marketsPage}>
        <div className={classNames.pageWrap} ref={ref}>
          {toast && (
            <ToastMessage textOne={toast} onClose={() => setToast('')} />
          )}
          <div className={classNames.definavbar}>
            <div className={classNames.defiicon}>
              <img src={fulllogo} alt="" />
            </div>
            <div
              className={classNames.searchdefi}
              style={{ borderRadius: defiDropdown ? '5px 5px 0 0' : '' }}
            >
              {/* <div
                className={classNames.all}
                onClick={() => setDefiDropdown(!defiDropdown)}
              >
                <p>{defiSelectedDropdown}</p>
                <div
                  className={classNames.arroww}
                  style={{ cursor: 'pointer' }}
                >
                  <img
                    src={ddown}
                    alt=""
                    style={{ rotate: defiDropdown ? '180deg' : '' }}
                  />
                </div>
              </div> */}
              <div className={classNames.im}>
                <input
                  type="text"
                  placeholder="Im Looking For..."
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <div
                  className={classNames.arroww}
                  style={{ cursor: 'pointer' }}
                >
                  <img src={searchIcon} alt="search" />
                </div>
              </div>
              <div
                className={classNames.dropDown}
                style={{
                  height: defiDropdown ? '' : '0',
                  overflow: defiDropdown ? '' : 'hidden',
                  display: defiDropdown ? '' : 'none',
                }}
              >
                <div className={classNames.eachSection}>
                  <div className={classNames.title}>Search By</div>
                  <div className={classNames.selectItems}>
                    <div
                      style={{
                        fontWeight:
                          defiSelectedDropdown == 'Asset' ? '900' : '',
                      }}
                      onClick={(event) =>
                        setDefiSelectedDropdown(event.target.innerText)
                      }
                    >
                      Asset
                    </div>
                    <div
                      style={{
                        fontWeight:
                          defiSelectedDropdown == 'Issuer' ? '900' : '',
                      }}
                      onClick={(event) =>
                        setDefiSelectedDropdown(event.target.innerText)
                      }
                    >
                      Issuer
                    </div>
                  </div>
                </div>
                <div className={classNames.eachSection}>
                  <div className={classNames.title}>Filter By</div>
                  <div className={classNames.selectItems}>
                    <div style={{ pointerEvents: 'none', opacity: '0.5' }}>
                      Contract Cost
                    </div>
                    <div style={{ pointerEvents: 'none', opacity: '0.5' }}>
                      Length
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={classNames.topValues}>
              <div className={classNames.eachTopValue}>
                <div className={classNames.font1}>Staking Rewards</div>
                <div className={classNames.font2}>$--</div>
              </div>
              <div className={classNames.eachTopValue} style={{ width: '31%' }}>
                <div className={classNames.font1}>MoneyMarket Earnings</div>
                <div className={classNames.font2}>$--</div>
              </div>
              <div className={classNames.eachTopValue}>
                <div className={classNames.font1}>Missed Rewards</div>
                <div className={classNames.font2}>$--</div>
              </div>
              <div className={classNames.monthlyTop}>
                <div>Monthly</div>
                <img src={ddGrey} alt="ddown" style={{ height: '7px' }} />
              </div>
            </div>
            <div
              className={classNames.defiMarketBtn}
              onClick={() => {
                setDefiTab('');
                setCoinSelected('explorer');
                window.open('https://defi.markets/', '_blank');
              }}
            >
              <img
                src={defiIcon}
                alt="defiIcon"
                className={classNames.defiIcon}
              />
              <div>Defi Markets</div>
              <img
                src={navigateIcon}
                alt="navigateIcon"
                className={classNames.defiIcon}
              />
            </div>
          </div>
          <div
            className={classNames.wspace}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div className={classNames.optionss} style={{ width: '25%' }}>
              {MARKET_ASSET_CLASSES1.sort(GetSortOrder('marketOrder')).map(
                (assetClassCard, i) => (
                  <div
                    key={i}
                    className={`${classNames.assetClassCard} ${
                      classNames[assetClassCard === assetClass]
                    } ${classNames} ${classNames.eachoption} ${
                      defiTab === assetClassCard.newName
                        ? classNames.eachoptionselected
                        : ''
                    }`}
                    onClick={() => {
                      if (assetClassCard.marketEnable) {
                        // console.log(assetClassCard.name, 'assetclass');
                        setDefiTab(assetClassCard.newName);
                        setAssetClass(assetClassCard);
                        setCoinSelected('');
                        history.push(`/market/${assetClassCard.name}`);
                      }
                    }}
                    style={{
                      // borderBottom:
                      //   defiTab === assetClassCard.newName
                      //     ? '2px solid #137EFB'
                      //     : '',
                      // fontWeight:
                      //   defiTab === assetClassCard.newName ? '600' : '',
                      opacity: assetClassCard.marketEnable ? '1' : '0.25',
                    }}
                  >
                    <div
                      style={{
                        cursor: assetClassCard.marketEnable
                          ? 'pointer'
                          : 'not-allowed',
                      }}
                    >
                      <p>{assetClassCard.newName}</p>
                    </div>
                  </div>
                )
              )}
            </div>
            {/* <div
              className={classNames.explorer}
              onClick={() => {
                setDefiTab('');
                setCoinSelected('explorer');
                window.open('https://defi.markets/', '_blank');
              }}
            >
              Explorer
              <div
                style={{
                  display:
                    coinSelected == 'explorer' || coinSelected == 'defihash'
                      ? ''
                      : 'none',
                }}
              ></div>
            </div> */}
          </div>

          <div
            className={classNames.pageContent}
            style={{ position: 'relative' }}
          >
            {coinSelected?.coinSymbol || coin ? (
              <MarketAssetDetail
                coin={coinSelected}
                coinSymbol={coin}
                onClose={() => {
                  setCoinSelected();
                  history.push(`/market/${assetClass.name}`);
                }}
                assetClass={assetClass}
                streamOpen={streamOpen}
                setStreamOpen={setStreamOpen}
              />
            ) : assetClass?.name == 'stakingVaults' ? (
              selectTable()
            ) : assetClass?.name == 'myContracts' ? (
              <div
                className="list-component"
                style={{ padding: '2rem 0 0', width: '100%' }}
              >
                <Investments />
                {selectTable()}
              </div>
            ) : (
              <div className={classNames.tableArea}>
                <div className={classNames.topBlank}>&nbsp;</div>
                <div className={classNames.cardList}>
                  {!loadingCurrencies
                    ? currenciesData.map((item) => (
                        <div
                          onClick={(e) => setSelectedCurrency(item)}
                          className={
                            item.name === selectedCurrency?.name
                              ? classNames.selectedCard
                              : classNames.card
                          }
                        >
                          <img
                            src={item.icon}
                            alt=""
                            style={{ width: '35px', height: '35px' }}
                          />
                          <div>
                            <div className={classNames.cardTitle}>
                              {item?.name}
                            </div>
                            <div className={classNames.cardSubTitle}>
                              {item?.active} Offerings
                            </div>
                          </div>
                        </div>
                      ))
                    : Array(4)
                        .fill('')
                        .map((item) => {
                          return (
                            <div
                              className={
                                item.name === selectedCurrency?.name
                                  ? classNames.selectedCard
                                  : classNames.card
                              }
                            >
                              <Skeleton
                                width={35}
                                height={35}
                                borderRadius={50}
                              />

                              <div>
                                <div className={classNames.cardTitle}>
                                  <Skeleton width={100} height={10} />
                                </div>
                                <div className={classNames.cardSubTitle}>
                                  <Skeleton width={80} height={5} />
                                </div>
                              </div>
                            </div>
                          );
                        })}
                </div>
                {stakingContractsTable()}
              </div>
            )}
            
            <div
              className={classNames.buyContractSidebar}
              style={{ right: buyContract ? '0' : '-100%' }}
            >
              {buyContractStep === 'step1' && (
                <>
                  <div className={classNames.headerCont}>
                    <div className={classNames.headerTxt}>
                      Buy Staking Contract
                    </div>
                    <div className={classNames.subbTxtbox}>
                      <div className={classNames.divv}>
                        <img
                          src={coinBalance?.coinImage}
                          alt=""
                          style={{ width: '25px' }}
                        />
                        <div>{coinBalance?.coinSymbol} Balance</div>
                      </div>
                      <div className={classNames.divv1}>
                        {coinBalance?.coinValue}
                      </div>
                    </div>
                  </div>
                  <div className={classNames.percentageDiv}>
                    <div className={classNames.perTxt}>
                      Enter Percentage Of Balance
                    </div>
                    <div className={classNames.perBoxes}>
                      <div
                        className={classNames.box}
                        onClick={() => {
                          percentageCalc(50);
                          setClicked('50');
                        }}
                        style={{
                          background: clicked === '50' ? '#9FD6DF' : '#ffffff',
                          color: clicked === '50' ? '#ffffff' : '#343c5b',
                        }}
                      >
                        50%
                      </div>
                      <div
                        className={classNames.box}
                        onClick={() => {
                          percentageCalc(100);
                          setClicked('100');
                        }}
                        style={{
                          background: clicked === '100' ? '#9FD6DF' : '#ffffff',
                          color: clicked === '100' ? '#ffffff' : '#343c5b',
                        }}
                      >
                        100%
                      </div>
                      <div
                        className={classNames.box}
                        style={{
                          visibility: 'hidden',
                          pointerEvents: 'none',
                        }}
                      >
                        <input
                          type="number"
                          style={{
                            width: '100%',
                            height: '100%',
                            background: 'transparent',
                            border: 'none',
                            textAlign: 'center',
                          }}
                          placeholder="0.00%"
                          onChange={(event) =>
                            percentageCalc(event.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className={classNames.orDiv}>
                    <div className={classNames.dash1}></div>
                    <div style={{ fontSize: '1.25rem', fontWeight: '600' }}>
                      OR
                    </div>
                    <div className={classNames.dash1}></div>
                  </div>
                  <div className={classNames.midCont}>
                    <div className={classNames.perTxt}>
                      How Many Contracts Do You Want To Buy?
                    </div>
                    <div className={classNames.subbTxtbox}>
                      <input
                        type="number"
                        placeholder="1"
                        value={contractValue}
                        onChange={(event) => {
                          if (event.target.value < 1) {
                            setcontractValue(1);
                          } else {
                            setcontractValue(event.target.value);
                          }
                        }}
                        style={{ width: '30%' }}
                      />
                      <div className={classNames.divv1}>
                        <input
                          type="number"
                          placeholder="0.01"
                          value={
                            selectedAsset?.custom_bonds?.[0]?.coinsData?.[0]
                              ?.bondCost
                              ? contractValue *
                                selectedAsset?.custom_bonds?.[0]?.coinsData?.[0]
                                  ?.bondCost
                              : ''
                          }
                          style={{
                            textAlign: 'end',
                            fontWeight: '650',
                            fontSize: '1.25rem',
                          }}
                        />
                        <div style={{ fontWeight: '650', fontSize: '1.25rem' }}>
                          {coinBalance?.coinSymbol}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={classNames.buttonCont}
                    onClick={() => {
                      setBuyContractStep('step2');
                    }}
                  >
                    Next Step
                  </div>
                </>
              )}
              {buyContractStep === 'step2' && (
                <>
                  <div className={classNames.headerCont}>
                    <div className={classNames.headerTxt}>
                      Purchase Confirmation
                    </div>
                    <div style={{ fontSize: '1.1rem' }}>
                      Are you sure you want to purchase this staking contract?
                      By Checking The Boxes You Confirm.
                    </div>
                  </div>
                  <div
                    className={classNames.midCont}
                    style={{ marginBottom: '17rem' }}
                  >
                    <div className={classNames.checkBoxes}>
                      <input
                        type="checkbox"
                        onClick={() => {
                          setChecked1(!checked1);
                        }}
                      />
                      <div className={classNames.conditions}>
                        Understand That This Purchase Cannot Be Reversed Or
                        Broken Until The End Of The Contract Term And I Am
                        Selecting A Staking Contract Which Coincides With My
                        Investment Requirements.
                      </div>
                    </div>
                    <div className={classNames.checkBoxes}>
                      <input
                        type="checkbox"
                        onClick={() => {
                          setChecked2(!checked2);
                        }}
                      />
                      <div className={classNames.conditions}>
                        I Have Read & Agreed To The Terms And Conditions
                      </div>
                    </div>
                  </div>
                  <div className={classNames.buttonCont1}>
                    <div
                      style={{
                        border: '1px solid #e5e5e5',
                        color: '#343C5B',
                      }}
                      onClick={() => {
                        setBuyContractStep('step1');
                      }}
                    >
                      Never Mind
                    </div>
                    <div
                      style={{
                        background:
                          'linear-gradient(90deg, #3d4394 0%, #81cbd7 100%)',
                        opacity: checked1 && checked2 ? '1' : '0.25',
                        cursor:
                          checked1 && checked2 ? 'pointer' : 'not-allowed',
                      }}
                      onClick={() => {
                        if (checked1 && checked2) {
                          buyStakingContract();
                          setLoading(true);
                        }
                      }}
                    >
                      Make Purchase
                    </div>
                  </div>
                </>
              )}
              {buyContractStep === 'step3' && (
                <>
                  <div className={classNames.headerCont}>
                    <div
                      className={classNames.headerTxt}
                      style={{ paddingBottom: '0' }}
                    >
                      Purchase Successful
                    </div>
                    <div style={{ fontSize: '1.1rem' }}>
                      You Have Purchased {contractValue}{' '}
                      {updatedVaultBalance?.coinName} Staking Contracts
                    </div>
                  </div>
                  <div
                    className={classNames.midCont}
                    style={{
                      marginBottom: '17rem',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <div style={{ paddingBottom: '0.75rem' }}>
                      Updated {updatedVaultBalance?.coinSymbol} Vault Balance
                    </div>
                    <div className={classNames.amountValue}>
                      <div className={classNames.imgData}>
                        <img src={updatedVaultBalance?.coinImage} alt="" />{' '}
                        <span>{updatedVaultBalance?.coinSymbol}</span>
                      </div>
                      <input
                        type="number"
                        placeholder="0.0000"
                        value={updatedVaultBalance?.coinValue}
                      />
                    </div>
                  </div>
                  <div className={classNames.buttonCont1}>
                    <div
                      style={{
                        border: '1px solid #e7e7e7',
                        color: '#343C5B',
                      }}
                      onClick={() => {
                        setBuyContract(false);
                        setBuyContractStep(1);
                        setDefiTab('My Contracts');
                        history.push('/market/myContracts');
                        setSelectedContractId(updatedVaultBalance?.coinSymbol);
                      }}
                    >
                      See {updatedVaultBalance?.coinSymbol} Contracts
                    </div>
                    <div
                      style={{
                        background:
                          'linear-gradient(90deg, #3d4394 0%, #81cbd7 100%)',
                      }}
                      onClick={() => {
                        window.open(
                          `https://defi.markets/hash/${bondHash}`,
                          '_blank'
                        );
                      }}
                    >
                      Go To Hash
                    </div>
                  </div>
                </>
              )}
              {loading && (
                <div className="loadingIcon">
                  <LoadingAnimation icon={appLogo} width={200} />
                </div>
              )}
            </div>

            {/* {buyContract && buyContractStep === 'step1' && (
            <div
              className={classNames.buyContractSidebar}
              style={{
                right:
                  buyContract && buyContractStep === 'step1' ? '0' : '-100%',
              }}
            >
              <div className={classNames.headerCont}>
                <div className={classNames.headerTxt}>Buy Staking Contract</div>
                <div className={classNames.subbTxtbox}>
                  <div className={classNames.divv}>
                    <img
                      src={coinBalance?.coinImage}
                      alt=""
                      style={{ width: '25px' }}
                    />
                    <div>{coinBalance?.coinSymbol} Balance</div>
                  </div>
                  <div className={classNames.divv1}>
                    {coinBalance?.coinValue}
                  </div>
                </div>
              </div>
              <div className={classNames.percentageDiv}>
                <div className={classNames.perTxt}>
                  Enter Percentage Of Balance
                </div>
                <div className={classNames.perBoxes}>
                  <div
                    className={classNames.box}
                    onClick={() => {
                      percentageCalc(50);
                      setClicked('50');
                    }}
                    style={{
                      background: clicked === '50' ? '#9FD6DF' : '#ffffff',
                      color: clicked === '50' ? '#ffffff' : '#343c5b',
                    }}
                  >
                    50%
                  </div>
                  <div
                    className={classNames.box}
                    onClick={() => {
                      percentageCalc(100);
                      setClicked('100');
                    }}
                    style={{
                      background: clicked === '100' ? '#9FD6DF' : '#ffffff',
                      color: clicked === '100' ? '#ffffff' : '#343c5b',
                    }}
                  >
                    100%
                  </div>
                  <div
                    className={classNames.box}
                    style={{ visibility: 'hidden', pointerEvents: 'none' }}
                  >
                    <input
                      type="number"
                      style={{
                        width: '100%',
                        height: '100%',
                        background: 'transparent',
                        border: 'none',
                        textAlign: 'center',
                      }}
                      placeholder="0.00%"
                      onChange={(event) => percentageCalc(event.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className={classNames.orDiv}>
                <div className={classNames.dash1}></div>
                <div style={{ fontSize: '1.25rem', fontWeight: '600' }}>OR</div>
                <div className={classNames.dash1}></div>
              </div>
              <div className={classNames.midCont}>
                <div className={classNames.perTxt}>
                  How Many Contracts Do You Want To Buy?
                </div>
                <div className={classNames.subbTxtbox}>
                  <input
                    type="number"
                    placeholder="1"
                    value={contractValue}
                    onChange={(event) => {
                      if (event.target.value < 1) {
                        setcontractValue(1);
                      } else {
                        setcontractValue(event.target.value);
                      }
                    }}
                    style={{ width: '30%' }}
                  />
                  <div className={classNames.divv1}>
                    <input
                      type="number"
                      placeholder="0.01"
                      value={
                        selectedAsset?.custom_bonds?.[0]?.coinsData?.[0]
                          ?.bondCost
                          ? contractValue *
                            selectedAsset?.custom_bonds?.[0]?.coinsData?.[0]
                              ?.bondCost
                          : ''
                      }
                      style={{
                        textAlign: 'end',
                        fontWeight: '650',
                        fontSize: '1.25rem',
                      }}
                    />
                    <div style={{ fontWeight: '650', fontSize: '1.25rem' }}>
                      {coinBalance?.coinSymbol}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={classNames.buttonCont}
                onClick={() => {
                  setBuyContractStep('step2');
                }}
              >
                Next Step
              </div>
            </div>
            )} */}
            {/* {buyContract && buyContractStep === 'step2' && (
            <div
              className={classNames.buyContractSidebar}
              style={{
                right:
                  buyContract && buyContractStep === 'step2' ? '0' : '-100%',
              }}
            >
              <div className={classNames.headerCont}>
                <div className={classNames.headerTxt}>
                  Purchase Confirmation
                </div>
                <div style={{ fontSize: '1.1rem' }}>
                  Are you sure you want to purchase this staking contract? By
                  Checking The Boxes You Confirm.
                </div>
              </div>
              <div
                className={classNames.midCont}
                style={{ marginBottom: '17rem' }}
              >
                <div className={classNames.checkBoxes}>
                  <input
                    type="checkbox"
                    onClick={() => {
                      setChecked1(!checked1);
                    }}
                  />
                  <div className={classNames.conditions}>
                    Understand That This Purchase Cannot Be Reversed Or Broken
                    Until The End Of The Contract Term And I Am Selecting A
                    Staking Contract Which Coincides With My Investment
                    Requirements.
                  </div>
                </div>
                <div className={classNames.checkBoxes}>
                  <input
                    type="checkbox"
                    onClick={() => {
                      setChecked2(!checked2);
                    }}
                  />
                  <div className={classNames.conditions}>
                    I Have Read & Agreed To The Terms And Conditions
                  </div>
                </div>
              </div>
              <div className={classNames.buttonCont1}>
                <div
                  style={{ border: '1px solid #e5e5e5', color: '#343C5B' }}
                  onClick={() => {
                    setBuyContractStep('step1');
                  }}
                >
                  Never Mind
                </div>
                <div
                  style={{
                    background:
                      'linear-gradient(90deg, #3d4394 0%, #81cbd7 100%)',
                    opacity: checked1 && checked2 ? '1' : '0.25',
                    cursor: checked1 && checked2 ? 'pointer' : 'not-allowed',
                  }}
                  onClick={() => {
                    if (checked1 && checked2) {
                      buyStakingContract();
                      setLoading(true);
                    }
                  }}
                >
                  Make Purchase
                </div>
              </div>
            </div>
             )}  */}
            {/* {buyContract && buyContractStep === 'step3' && (
              <div className={classNames.buyContractSidebar}>
                <div className={classNames.headerCont}>
                  <div
                    className={classNames.headerTxt}
                    style={{ paddingBottom: '0' }}
                  >
                    Purchase Successful
                  </div>
                  <div style={{ fontSize: '1.1rem' }}>
                    You Have Purchased {contractValue}{' '}
                    {updatedVaultBalance?.coinName} Staking Contracts
                  </div>
                </div>
                <div
                  className={classNames.midCont}
                  style={{
                    marginBottom: '17rem',
                    justifyContent: 'flex-start',
                  }}
                >
                  <div style={{ paddingBottom: '0.75rem' }}>
                    Updated {updatedVaultBalance?.coinSymbol} Vault Balance
                  </div>
                  <div className={classNames.amountValue}>
                    <div className={classNames.imgData}>
                      <img src={updatedVaultBalance?.coinImage} alt="" />{' '}
                      <span>{updatedVaultBalance?.coinSymbol}</span>
                    </div>
                    <input
                      type="number"
                      placeholder="0.0000"
                      value={updatedVaultBalance?.coinValue}
                    />
                  </div>
                </div>
                <div className={classNames.buttonCont1}>
                  <div
                    style={{ border: '1px solid #e7e7e7', color: '#343C5B' }}
                    onClick={() => {
                      setBuyContract(false);
                      setBuyContractStep(1);
                      setDefiTab('My Contracts');
                      history.push('/market/myContracts');
                      setSelectedContractId(updatedVaultBalance?.coinSymbol);
                    }}
                  >
                    See {updatedVaultBalance?.coinSymbol} Contracts
                  </div>
                  <div
                    style={{
                      background:
                        'linear-gradient(90deg, #3d4394 0%, #81cbd7 100%)',
                    }}
                    onClick={() => {
                      window.open(
                        `https://defi.markets/hash/${bondHash}`,
                        '_blank'
                      );
                    }}
                  >
                    Go To Hash
                  </div>
                </div>
              </div>
            )} */}
            {/* {buyContract && loading && (
              <div className={classNames.buyContractSidebar}>
                <div className="loadingIcon">
                  <LoadingAnimation icon={appLogo} width={200} />
                </div>
              </div>
            )} */}

          </div>
        </div>
        <div
          className={`${classNames.sidebarArea} ${
            classNames[Boolean(streamOpen)]
          }`}
          style={{ maxHeight: '100%' }}
        >
          <div
            className={classNames.sidebarContent}
            style={{ maxHeight: '100%' }}
          >
            {assetClassName === 'shares' && coin ? (
              <TerminalsSidebar
                shareToken={shareToken}
                streamOpen={streamOpen}
                setStreamOpen={setStreamOpen}
              />
            ) : assetClassName === 'bonds' &&
              selectedSidebarMenu === 'Bonds' ? (
              <BondContractDetails
                index={index}
                setIndex={setIndex}
                setSelectedSidebarMenu={setSelectedSidebarMenu}
              />
            ) : (
              <img
                className={classNames.fullLogo}
                src={SIDEBAR_FOOTERS[index].fullLogo}
                alt=""
              />
            )}
          </div>

          {(assetClassName === 'shares' && coin) ||
          (assetClassName === 'bonds' && selectedSidebarMenu === 'Bonds') ? (
            ''
          ) : (
            <div className={classNames.sidebarFooter}>
              {SIDEBAR_FOOTERS.map((menu, i) => (
                <div
                  className={`${classNames.footerMenu} ${
                    classNames[i === index]
                  } ${menu.disable && classNames.disable}`}
                  onClick={() => {
                    !menu.disable && setIndex(i);
                    setSelectedSidebarMenu(menu.label);
                  }}
                >
                  <img src={menu.icon} alt="" />
                  <span>{menu.label}</span>
                </div>
              ))}
            </div>
          )}
        </div>
        {loginModalOpen && (
          <LoginModal
            onClose={() => setLoginModalOpen(false)}
            onSuccess={() => setLoginModalOpen(false)}
          />
        )}
        {assetModal && (
          <SelectBondAssetModal
            onClose={() => setAssetModal(false)}
            onSuccess={() => setAssetModal(false)}
            bondAsset={bondAsset}
            setBondAsset={setBondAsset}
          />
        )}
        {issuerModalOpen && (
          <SelectBondIssuerModal
            onClose={() => setIssuerModalOpen(false)}
            onSuccess={() => setIssuerModalOpen(false)}
            assetIssuer={assetIssuer}
            setAssetIssuer={setAssetIssuer}
          />
        )}
      </div>
    </Layout>
  );
}

export default MarketsPage;
