import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import LoginModal from '../LoginModalNew';

function GetStartedSelectProcess({ setStepName, logo, setSignUpType }) {
  const history = useHistory();
  const { pathname } = useLocation();
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  useEffect(() => {
    if (pathname === '/login') {
      setLoginModalOpen(true);
    }
  }, [pathname]);
  return (
    <>
      <div className="stepWrapper selectProcess">
        <img
          src={logo}
          alt="assetLogo"
          className="assetLogo"
          onClick={() => history.push('/')}
        />
        <div className="desc">
          If This Is Your First Time Here. You Can Register For Free.
          <br /> Select Which Process Applies To You
        </div>
        <div className="actions">
          <div
            className="btnAction"
            onClick={() => {
              setStepName('preReg');
              setSignUpType('preReg');
            }}
          >
            I Was Pre-Registered
          </div>
          <div
            className="btnAction inv"
            onClick={() => {
              setStepName('byBroker');
              setSignUpType('byBroker');
            }}
          >
            I Was Referred By A Affiliate
          </div>
          <div className="btnAction" onClick={() => history.push('/login')}>
            I Already Have An Account
          </div>
        </div>
      </div>
      {loginModalOpen && (
        <LoginModal
          onClose={() => setLoginModalOpen(false)}
          onSuccess={() => history.push('/app')}
        />
      )}
    </>
  );
}

export default GetStartedSelectProcess;
