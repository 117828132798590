import React, { useState, useEffect, useContext } from 'react';
import { useAppContextDetails } from '../../../context/AppContext';
import QRCode from 'qrcode.react';
import axios from 'axios';

import pasteIcon from '../../../static/images/paste.svg';
import { BankContext } from '../../../context/Context';

const ViewAddressStepTwo = ({
  coinData,
  coinAddress,
  hashSuccessData,
  setHashSuccessData,
}) => {
  const { selectedCurrency, appCode, setBuySellStep, setActionFlow } =
    useAppContextDetails();
  const { email, token, profileId } = useContext(BankContext);
  const [loading, setLoading] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [hashValue, setHashValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const getUpdatedBalance = (email, coin) => {
    axios
      .post(`https://comms.globalxchange.io/coin/vault/service/coins/get`, {
        app_code: appCode,
        email: email,
        displayCurrency: 'INR',
        include_coins: [coin],
      })
      .then(({ data }) => {
        if (data.status) {
          setHashSuccessData(data);
          setLoading(false);
          setActionFlow('Hash Success');
        }
      });
  };

  const handleSubmitHash = async () => {
    if (coinData.coinSymbol === 'ETH' || coinData.eth_token) {
      setLoading(true);
      axios
        .post(
          `https://comms.globalxchange.io/coin/vault/service/deposit/eth/coins/request`,
          {
            app_code: appCode,
            email: email,
            coin: coinData?.coinSymbol,
            txn_hash: hashValue,
          }
        )
        .then(({ data }) => {
          if (data.status) {
            getUpdatedBalance(email, coinData?.coinSymbol);
          } else {
            setLoading(false);
            setErrorMessage('Wrong Hash');
          }
        });
    } else if (coinData.coinSymbol === 'XRP') {
      setLoading(true);
      axios
        .post(
          `https://comms.globalxchange.io/coin/vault/service/deposit/xrp/external/request`,
          {
            email: email,
            app_code: appCode,
            hash: hashValue,
          }
        )
        .then(({ data }) => {
          if (data.status) {
            getUpdatedBalance(email, coinData.coinSymbol);
          } else {
            setLoading(false);
            setErrorMessage('Wrong Hash');
          }
        });
    } else if (coinData.coinSymbol === 'TRX') {
      setLoading(true);
      axios
        .post(
          `https://comms.globalxchange.io/coin/vault/service/deposit/trx/external/request`,
          {
            email: email,
            app_code: appCode,
            hash: hashValue,
          }
        )
        .then(({ data }) => {
          if (data.status) {
            getUpdatedBalance(email, coinData.coinSymbol);
          } else {
            setLoading(false);
            setErrorMessage('Wrong Hash');
          }
        });
    } else if (coinData.coinSymbol === 'SOL') {
      setLoading(true);
      axios
        .post(
          `https://comms.globalxchange.io/coin/vault/service/deposit/eth/coins/request`,
          {
            email: email,
            app_code: appCode,
            txn_signature: hashValue,
          }
        )
        .then(({ data }) => {
          console.log(data, 'kwbekfwbefkjwbkfbkebfkrf');
          if (data.status) {
            getUpdatedBalance(email, coinData.coinSymbol);
          } else {
            setLoading(false);
            setErrorMessage('Wrong Hash');
          }
        });
    }
  };

  const onPasteClick = async () => {
    const copiedText = await navigator.clipboard.readText();
    setHashValue(copiedText.trim());
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: window.innerHeight - 100,
      }}
    >
      <div>
        <div className="breadcrumb">
          <span onClick={(e) => setActionFlow('View Address')}>
            View Address
          </span>
          &nbsp;&nbsp;{`>`}&nbsp;&nbsp;
          <span style={{ fontWeight: 700 }}>Submit Hash</span>
        </div>
        <div style={{ padding: '0px 22px' }}>
          <div style={{ paddingTop: '40px' }}>
            <div
              style={{
                fontWeight: 'bold',
                fontSize: '22px',
                paddingBottom: '31px',
              }}
            >
              Enter Hash
            </div>
            <div>
              Enter The Hash Of The {coinData?.coinSymbol} Deposit Transaction
              To{' '}
              <div style={{ fontWeight: 'bold', fontSize: '12px' }}>
                {coinAddress}
              </div>
            </div>

            <input
              style={{ width: '100%' }}
              className="addressInput1"
              placeholder="Enter Hash..."
              value={hashValue}
              onChange={(e) => setHashValue(e.target.value)}
            />
            {errorMessage ? (
              <div
                style={{
                  color: 'red',
                  fontWeight: '600',
                  paddingBottom: '10px',
                }}
              >
                {errorMessage}
              </div>
            ) : (
              ''
            )}
            <div
              style={{ display: 'flex', alignItems: 'center' }}
              onClick={onPasteClick}
            >
              <img src={pasteIcon} style={{ width: '20px', height: '20px' }} />
              <div style={{ paddingLeft: '10px' }}>
                Click To Paste Clipboard
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ padding: '0px 22px' }}>
        <div className="doubleButton">
          <div
            className="copyButton"
            style={{ background: '#464B4E' }}
            onClick={(e) => setActionFlow(null)}
          >
            <div style={{ color: 'white', fontWeight: 'bold' }}>Back</div>
          </div>
          <div className="submitButton" onClick={(e) => handleSubmitHash()}>
            <div style={{ color: '#464B4E', fontWeight: 'bold' }}>
              {coinData?.coinSymbol === 'SOL'
                ? 'Submit Txn Signature'
                : 'Submit Hash'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAddressStepTwo;
