import React, { useState, useEffect, useContext } from 'react';
import CoinsData from '../MobileLayoutComponents/CoinsData/CoinsData';

import searchIcon from '../../static/images/mobile/search.svg';
import cryptoIcon from '../../static/images/mobile/crypto.svg';
import forexIcon from '../../static/images/mobile/forex.svg';
import { AppContext, useAppContextDetails } from '../../context/AppContext';
import MoneyMarketData from '../MobileLayoutComponents/MoneyMarketData';
import BondsData from '../MobileLayoutComponents/BondsData';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import ActionFlow from '../MobileLayoutComponents/ActionFlow';

const MarketBonds = () => {
  const { selectedSubMenu, setSelectedSubMenu } = useContext(AppContext);
  const { ownerEmail, actionFlow, setActionFlow } = useAppContextDetails();
  const [assetType, setAssetType] = useState('crypto');
  const [currenciesData, setCurrenciesData] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `https://comms.globalxchange.io/coin/iced/banker/custom/bond/get?status=active&email=${ownerEmail}`
        // `https://comms.globalxchange.io/coin/iced/banker/custom/bond/get?status=active&email=shorupan@indianinvestor.com`
      )
      .then(({ data }) => {
        const tempArr = Object.values(data.currenciesData);
        setCurrenciesData([...tempArr]);
        setSelectedCurrency(tempArr[0]);
        console.log(data, 'lwjeljwebfwef');
        setLoading(false);
      });
  }, []);

  return (
    <div>
      {!actionFlow ? (
        <>
          <div className="switcherWrapper">
            <div className="iconDiv">
              <img className="divImg" src={searchIcon} alt="" />
            </div>
            {!loading ? (
              currenciesData.map((item) => {
                return (
                  <div
                    className="iconWrapper"
                    onClick={(e) => setSelectedCurrency(item)}
                  >
                    <div
                      className="iconDiv"
                      // onClick={(e) => setSelectedCurrency(item)}
                    >
                      <img className="divImg" src={item?.icon} alt="" />
                    </div>
                    {item.icon === selectedCurrency.icon ? (
                      <div
                        style={{
                          width: '90px',
                          fontSize: '10px',
                          fontWeight:
                            item.icon === selectedCurrency.icon ? 'bold' : 500,
                          paddingTop: '5px',
                          textAlign: 'center',
                        }}
                      >
                        {item?.name}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  // <div className="iconWrapper">
                  //   <div
                  //     className="iconDiv"
                  //     onClick={(e) => setSelectedCurrency(item)}
                  //   >
                  //     <img className="divImg" src={item?.icon} alt="" />
                  //   </div>
                  //   <div
                  //     style={{
                  //       fontSize: '10px',
                  //       fontWeight: 'bold',
                  //       paddingTop: '5px',
                  //     }}
                  //   >
                  //     {selectedCurrency === item?.coin ? item?.coin : null}
                  //   </div>
                  // </div>
                );
              })
            ) : (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {Array(10)
                  .fill(' ')
                  .map((item) => {
                    return (
                      <div style={{ textAlign: 'center' }}>
                        <Skeleton
                          width={50}
                          height={50}
                          style={{ margin: '0px 10px', marginBottom: '5px' }}
                        />
                        <Skeleton width={50} height={5} />
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
          <div style={{ overflowY: 'scroll', height: '80vh' }}>
            <BondsData selectedCurrency={selectedCurrency} />
          </div>
        </>
      ) : (
        <ActionFlow />
      )}
    </div>
  );
};

export default MarketBonds;
