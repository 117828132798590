import React, { useEffect } from 'react';
import { useAppContextDetails } from '../../../context/AppContext';
import { useCoinContextData } from '../../../context/CoinContext';
import { LoadingAnimation } from '../../LoadingAnimation';

export const WithdrawSuccess = ({ isLoading, confirmationData }) => {
  const { fromTerm, selectedCoin, setUpdateVaultData } = useCoinContextData();
  const { appLogo } = useAppContextDetails();

  useEffect(() => {
    if (confirmationData?.userDebit?.updated_balance) {
      setUpdateVaultData(true);
    }
  }, [confirmationData]);

  return (
    <div className="sidebarwithdraw">
      <div
        className="withdraw-success"
        style={{ opacity: isLoading ? 0.5 : 1 }}
      >
        <div>
          <p>
            {`You Have Successully Initiated An External Withdrawal Of ${fromTerm} ${selectedCoin.coinSymbol}`}
          </p>
        </div>
        <div>
          <p className="balance">{`Previous ${selectedCoin.coinSymbol} Balance`}</p>
        </div>
        <div className="withdraw-success-box">
          <div className="div-success-input">
            <input
              className="from-input"
              placeholder="0.00"
              key="from-1"
              id="from-1"
              type="text"
              value={confirmationData?.userDebit?.current_balance}
            />
            <div className="post-withdraw-from-coin" key="from-coin">
              <img
                className="coinimgsmall"
                src={selectedCoin.coinImage}
                alt=""
              />
              <p className="coinsymbolsmall">{selectedCoin.coinSymbol}</p>
            </div>
          </div>
        </div>
        <div>
          <p className="balance">{`New ${selectedCoin.coinSymbol} Balance`}</p>
        </div>
        <div className="withdraw-success-box">
          <div className="div-success-input">
            <input
              className="from-input"
              placeholder="0.00"
              key="from-1"
              id="from-1"
              type="text"
              value={confirmationData?.userDebit?.updated_balance}
            />
            <div className="post-withdraw-from-coin" key="from-coin">
              <img
                className="coinimgsmall"
                src={selectedCoin.coinImage}
                alt=""
              />
              <p className="coinsymbolsmall">{selectedCoin.coinSymbol}</p>
            </div>
          </div>
        </div>
      </div>

      {isLoading && (
        <div className="withdraw-loading">
          <LoadingAnimation icon={appLogo} width={200} />
        </div>
      )}
    </div>
  );
};
