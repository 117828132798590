import React, { useEffect, useState } from 'react';
import classNames from './marketAssetDetailFinancials.module.scss';
import angleDown from '../../static/images/angleDown.svg';
import togleFullScreen from '../../static/images/togleFullScreen.svg';
import { useAppContextDetails } from '../../context/AppContext';
import Skeleton from 'react-loading-skeleton';
import axios from 'axios';
import './Market.scss';
const TABS = [
  'Liquidity',
  'Issuances',
  'Balance Sheet',
  'Income Statement',
  'Cash Flow Statement',
  'Capitalization Table',
  'External Audits',
  'External Audits',
  'Earning Calls',
  'Other',
];

function MarketAssetDetailFinancials({ shareToken}) {
  const [fullScreen, setFullScreen] = useState(false);
  const [SLoading, setSLoading] = useState(false);
  const [storeRenevue, setstoreRenevue] = useState([]);
  // const { appCurrencyName } = useAppContextDetails();
  const {
    // cardSelected,
    // setCardSelected,
    // tokenSymbol,
    // setTokenSymbol,
    // setshareTokenTabName,
    // shareTokenSelected,
    // shareTokenTabName,
    selectedAppCode,
  } = useAppContextDetails();
  console.log('237hhfdsfjs', shareToken);
  useEffect(() => {
    passvaulterFilerWithdraw();
  }, [shareToken]);
  const passvaulterFilerWithdraw = () => {
    setSLoading(true);

    axios
      .get(
        `https://comms.globalxchange.io/coin/app/dividend/vault/balances/get?app_code=${shareToken?.pathAppCode}`
      )
      .then(({ data }) => {
       
        if (data.status) {
          setstoreRenevue(data?.earningsVault);
          // setstoreRenevue([
          //   {
          //     name: 'Exchange Fees',
          //     value: result?.[0],
          //     selectedVault: result?.[0]?.vaultName,
          //   },
          //   {
          //     name: 'Deposit Fees',
          //     value: result?.[1],
          //     selectedVault: result?.[1]?.vaultName,
          //   },
          //   {
          //     name: 'Withdrawal Fees',
          //     value: result?.[2],
          //     selectedVault: result?.[2]?.vaultName,
          //   },
          //   {
          //     name: 'Staking Spread',
          //     value: result?.[8],
          //     selectedVault: result?.[8]?.vaultName,
          //   },
          //   {
          //     name: 'MoneyMarket IRD',
          //     value: result?.[6],
          //     selectedVault: result?.[6]?.vaultName,
          //   },
          //   {
          //     name: 'Products',
          //     value: result?.[3],
          //     selectedVault: result?.[3]?.vaultName,
          //   },
          //   {
          //     name: 'Staking IRD',
          //     value: result?.[5],
          //     selectedVault: result?.[5]?.vaultName,
          //   },

          //   {
          //     name: 'Marketsverse',
          //     value: result?.[4],
          //     selectedVault: result?.[4]?.vaultName,
          //   },
          //   {
          //     name: 'BondCoins',
          //     value: result?.[7],
          //     selectedVault: result?.[7]?.vaultName,
          //   },
          //   {
          //     name: 'Terminals',
          //     value: result?.[9],
          //     selectedVault: result?.[9]?.vaultName,
          //   },
          // ]);

          setSLoading(false);
        } else {
          setSLoading(false);
          // tostShowOn(data.message || 'Something Went Wrong');
        }
      });
  };
  return (
    <>
      <div className="reneveFinancial">
        <div className="content">
          <h1>Revenue Streams</h1>
          <p>
            Here are the live time earning vaults for the revenue streams of the
            company across various applications and businesses. Each revenue
            stream has a dedicated vaults in every currency that revenue can be
            generated in. The dividends for the ShareToken holders are paid from
            these vaults.
          </p>
        </div>
        <div className="TableSection">
          <div className="header">
            <div>Name</div>
            <div>Balance</div>
            <div
              style={{
                width: '40%;',
              }}
            ></div>
          </div>
          {SLoading ? (
            <>
              {[1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1].map((tiem) => {
                return (
                  <div className="Details">
                    <div className="text">
                      {' '}
                      <Skeleton width={100} height={15} />
                    </div>
                    <div className="text">
                      {' '}
                      <Skeleton width={100} height={15} />
                    </div>
                    <div className="labelSection">
                      <Skeleton width={100} height={15} />
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className="bodyTableRenu">
              {storeRenevue.map((item) => {
                let ans =
                  item?.vaultName == 'trade'
                    ? 'Exchange Fees'
                    : item?.vaultName == 'withdraw'
                    ? 'Withdrawal Fees'
                    : item?.vaultName == 'fund'
                    ? 'Deposit Fees'
                    : item?.vaultName == 'product'
                    ? 'Products'
                    : item?.vaultName == 'investment'
                    ? 'Marketsverse'
                    : item?.vaultName == 'icedIRD'
                    ? 'Staking IRD'
                    : item?.vaultName == 'liquidIRD'
                    ? 'MoneyMarket IRD'
                    : item?.vaultName == 'bondCoinsIRD'
                    ? 'BondCoins'
                    : item?.vaultName == 'connectedBondSpread'
                    ? 'Staking Spread'
                    : item?.vaultName == 'terminal'
                    ? 'Terminals'
                    : item?.vaultName;
                return (
                  <div className="Details">
                    <div className="text">{ans}</div>
                    <div className="text">
                      ${item?.totalValueUSD?.toFixed(2)}
                    </div>
                    <div className="labelSection">
                      <label
                        style={{
                          marginRight: '12px',
                        }}
                      >
                        Stats
                      </label>
                      <label>Vaults</label>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </>
    // <div
    //   className={`${classNames[fullScreen.toString()]} ${
    //     classNames.marketAssetDetailFinancialsWrap
    //   }`}
    // >
    //   <div className={classNames.marketAssetDetailFinancials}>
    //     <div className={classNames.headerTabs}>
    //       {TABS.map((tab, i) => (
    //         <div
    //           className={`${classNames.tabItm} ${
    //             classNames[(i === 0).toString()]
    //           }`}
    //         >
    //           {tab}
    //         </div>
    //       ))}
    //       <div
    //         className={classNames.btnFullScreen}
    //         onClick={() => setFullScreen(!fullScreen)}
    //       >
    //         <img src={togleFullScreen} alt="" />
    //       </div>
    //     </div>
    //     <div className={classNames.detailWrap}>
    //       <div className={classNames.detailTexts}>
    //         <div className={classNames.title}>Liquidity</div>
    //         <div className={classNames.desc}>
    //           Liquidity represents the and demand data for the a particular
    //           ShareToken by mapping the circulating supply metrics over the data
    //           from the secondary market orderbook.
    //         </div>
    //       </div>
    //       <div className={classNames.details}>
    //         <div className={classNames.detail}>
    //           <div className={classNames.value}>--</div>
    //           <div className={classNames.label}>Owned By Company</div>
    //         </div>
    //         <div className={classNames.detail}>
    //           <div className={classNames.value}>--</div>
    //           <div className={classNames.label}>In The Orderbook</div>
    //         </div>
    //         <div className={classNames.detail}>
    //           <div className={classNames.value}>--</div>
    //           <div className={classNames.label}>Owned By Others</div>
    //         </div>
    //       </div>
    //     </div>
    //     <div className={classNames.marketTable}>
    //       <div className={`${classNames.header}`}>
    //         <div className={classNames.date}>Date</div>
    //         <div className={classNames.direction}>Direction</div>
    //         <div className={classNames.status}>Status</div>
    //         <div className={classNames.amount}>Amount</div>
    //         <div className={classNames.price}>Price</div>
    //         <div className={classNames.volume}>Volume</div>
    //         <div className={classNames.filled}>Filled</div>
    //         <div className={classNames.pending}>Pending</div>
    //         <div className={classNames.details}></div>
    //         <img
    //           className={`${classNames.btnFilter} ${
    //             classNames[filterOpen.toString()]
    //           }`}
    //           src={angleDown}
    //           alt=""
    //           onClick={() => setFilterOpen(!filterOpen)}
    //         />
    //       </div>
    //       {filterOpen && (
    //         <div className={classNames.filtersWrap}>
    //           <div className={classNames.filters}>
    //             <div className={classNames.filter}>
    //               <img
    //                 src={
    //                   'https://apimachine-s3.s3.us-east-2.amazonaws.com/coinImages/dollar.png'
    //                 }
    //                 alt=""
    //               />
    //               <span>{appCurrencyName}</span>
    //             </div>
    //             <div className={classNames.filter}>
    //               + <span>Add Filter</span>
    //             </div>
    //           </div>
    //         </div>
    //       )}
    //       <div className={classNames.liquidList}>
    //         {Array(10)
    //           .fill('')
    //           .map(() => (
    //             <div className={`${classNames.liquidItem}`}>
    //               <div className={classNames.date}>Jan 1st 2022</div>
    //               <div className={classNames.direction}>
    //                 <div className={classNames.btnSell}>Sell</div>
    //               </div>
    //               <div className={classNames.status}>Open</div>
    //               <div className={classNames.amount}>23</div>
    //               <div className={classNames.price}>10.00</div>
    //               <div className={classNames.volume}>2300.00</div>
    //               <div className={classNames.filled}>5</div>
    //               <div className={classNames.pending}>5</div>
    //               <div className={classNames.details}>
    //                 <div className={classNames.btnDetails}>
    //                   Investor Details
    //                 </div>
    //               </div>
    //               <img
    //                 className={`${classNames.btnFilter} ${
    //                   classNames[filterOpen.toString()]
    //                 }`}
    //                 src={angleDown}
    //                 alt=""
    //                 onClick={() => setFilterOpen(!filterOpen)}
    //               />
    //             </div>
    //           ))}
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
}

export default MarketAssetDetailFinancials;
