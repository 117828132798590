import React, { useState, useContext, useEffect } from 'react';
import './table.scss';
import { useAppContextDetails } from '../../../../context/AppContext';
import { allFunds, SeletedFunds, MultiBank } from '../../../Funds/apidata';
import Skeleton from 'react-loading-skeleton';
import d1 from '../../../../assets/images/d1.svg';
import d2 from '../../../../assets/images/d2.svg';
import d3 from '../../../../assets/images/d3.svg';
import d4 from '../../../../assets/images/d4.svg';

import w1 from '../../../../assets/images/w1.svg';
import w2 from '../../../../assets/images/w2.svg';
import w3 from '../../../../assets/images/w3.svg';
import w4 from '../../../../assets/images/w4.svg';

import dg from '../../../../static/images/Funds/dg.svg';
import allEa from '../../../../static/images/Funds/allEa.svg';
import EaDrawer from '../../../Funds/EaDrawer';
import { StatueData } from '../../../Funds/apidata';
import { BankContext } from '../../../../context/Context';
import './Fxacoount.scss';
export default function MyAccounts({ handleBuy, setdrawerToggle }) {
  const [drawerName, setdrawerName] = useState('');
  const [Eatoggle, setEatoggle] = useState(false);
  const [statusCheck, setstatusCheck] = useState({});
  const [licenseId, setlicenseId] = useState('');
  const [bankData, setbankData] = useState([]);
  const [nonactivebank, setnonactivebank] = useState(true);
  const [cardNumber, setcardNumber] = useState(1);
  const {
    setFundsTab,
    FundsTab,
    setStoreFundsDetail,
    StoreFundsDetail,
    fundsDetailTable,
    cardNameFunds,
    setfundsDetailTable,
    setfinaldata,
    finaldata,

    setcardNameFunds,
  } = useAppContextDetails();
  const table = [
    {
      name: 'Account Number',
    },
    {
      name: 'Asset',
    },
    {
      name: 'Balance',
    },
    {
      name: 'Equity',
    },
    {
      name: 'Actions',
    },
  ];

  const { email, profileId, token } = useContext(BankContext);

  useEffect(() => {
    callmuitbank();
    return () => {};
  }, []);

  const callmuitbank = async () => {
    let obj = {
      email: email,
    };

    await MultiBank(obj).then((response) => {
      let result = response?.data?.result;
      if (result?.status) {
        setbankData(result?.data);
      } else {
        setnonactivebank(false);
      }

      //  console.log("czxcew",result?.data)
    });
  };
  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };
  console.log('bankData', bankData);

  const callhander = (e) => {
    // setfinaldata(e);
    setcardNameFunds(e.name);
    fundssSeleted(e.code);
  };
  const fundssSeleted = async (e) => {
    await setfundsDetailTable([]);
    await SeletedFunds(e).then((response) => {
      let result = response?.data?.paths;
      setfundsDetailTable(result);
    });
  };

  //   useEffect(() => {

  //   }, []);

  const statusFunction = (e) => {
    let obj = {
      email: email,
    };
    setEatoggle(true);
    setdrawerName(e);

    StatueData(obj).then((response) => {
      let result = response?.data?.licenses?.[0];
      setstatusCheck(result);
      setlicenseId(result?.license_code);
    });
  };

  console.log('statusCheck', licenseId);

  const cardfx = [
    {
      white: w1,
      id: 1,
      color: '#010D3E',
      dark: d1,
    },
    {
      white: w2,
      id: 2,
      color: '#38508E',
      dark: d2,
    },
    {
      white: w3,
      id: 3,
      color: '#053C50',
      dark: d3,
    },
    {
      white: w4,
      id: 4,
      color: '#000000',
      dark: d4,
    },
  ];

  return (
    //
    <>
      <div
        className="fx-content"
        onClick={() => Eatoggle && setEatoggle(false)}
      >
        <div className="left-fund-container">
          <div className="cards-funds">
            <div className="Tablecardfx">
              {cardfx.map((item) => {
                return (
                  <div
                    className="innerTablecard"
                    style={{
                      background: cardNumber == item.id ? item.color : '#ffff',
                    }}
                    onClick={() => setcardNumber(item?.id)}
                    //   onClick={() => callhander(item)}
                    //   style={{
                    //     boxShadow:
                    //       cardNameFunds == item.name
                    //         ? '0px 4px 4px rgba(0, 0, 0, 0.25)'
                    //         : '0px',
                    //     opacity: cardNameFunds == item.name ? 1 : 0.5,
                    //   }}
                  >
                    <img
                      src={cardNumber == item.id ? item?.dark : item?.white}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="TableSectionfx">
            <div className="headerFundsEA">
              {table.map((item) => {
                return <div className="fund-title">{item.name}</div>;
              })}
            </div>
            <div className="fundBody">
              <>
                {nonactivebank ? (
                  <>
                    {bankData?.map((item) => {
                      return (
                        <div className="InnerFundsBodyEa">
                          <div className="text-funds">{item?.login}</div>

                          <div className="text-funds">{item?.currency}</div>
                          <div className="text-funds">
                            {numberWithCommas(item?.balance?.toFixed(2))}
                          </div>
                          <div className="text-funds">
                            {' '}
                            {numberWithCommas(item?.equity?.toFixed(2))}
                          </div>

                          <div className="labelcover">
                            <div className="buylabel">Trade</div>
                            <div className="buylabel">Fund</div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <div className="ErrorMessageDash">
                    <h1>
                      Looks Like You Don’t Have A MultiBank Account Yet. Click
                      Below To Get Started
                    </h1>
                    <label
                      className="sign"
                      onClick={() =>
                        window.open(
                          'https://multibankfx.com/account/live-account?ibNum=5573848',
                          '_blank'
                        )
                      }
                    >
                      Signup For MultiBank
                    </label>
                    <label
                      className="have"
                      style={{
                        marginTop: '1rem',
                      }}
                    >
                      I Already Have One
                    </label>
                  </div>
                )}
                {/*  */}
              </>
            </div>
          </div>
        </div>
      </div>
      {Eatoggle && (
        <EaDrawer
          drawerName={drawerName}
          statusCheck={statusCheck}
          setEatoggle={setEatoggle}
          licenseId={licenseId}
        />
      )}
    </>
  );
}
