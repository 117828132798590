import React, { useContext, useEffect, useState } from 'react';
import VidPlayer from "../VideoPlayer1/VideoPlayer1";

import { BankContext } from '../../context/Context';

function VideoPlayer() {
  const { videoShow, setVideoShow } = useContext(BankContext);
  return (
    <VidPlayer
      videoShow={videoShow}
      setVideoShow={setVideoShow}
      navbar="5fb318108efdfb791cb29ce5"
    />
  );
}

export default VideoPlayer;
