import React, { useContext, useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

import { BankContext } from '../../context/Context';

import unameIcon from '../../static/images/formIcons/uname.svg';
import emailIcon from '../../static/images/formIcons/email.svg';
import { useAppContextDetails } from '../../context/AppContext';

import classNames from './onhold.module.scss';
import ViewAsUserItem from '../../Layout/ViewAsUserItem';

const EmailSelection = ({ setOnHold }) => {
  const history = useHistory();

  const [search, setSearch] = useState('');
  const [seachEmail, setSeachEmail] = useState('');
  const [users, setUsers] = useState([]);
  const [filteredusers, setfilteredUsers] = useState([]);
  const [type, setType] = useState('email');
  const [loading, setLoading] = useState(true);
  const { appCode, appFullLogo } = useAppContextDetails();

  const { userSelectedOnHold } = useContext(BankContext);

  useEffect(() => {
    setLoading(true);
    Axios.get(
      `https://comms.globalxchange.io/gxb/apps/users/get?app_code=${appCode}`
    )
      .then((res) => {
        const { data } = res;
        if (data.status) {
          // console.log(data?.users, 'all users');
          setUsers(data.users);
          setfilteredUsers(data?.users);
        }
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    let filteredUsers = users?.filter((eachUser) => {
      return (
        eachUser?.email?.toLowerCase()?.includes(seachEmail?.toLowerCase()) ||
        eachUser?.name?.toLowerCase()?.includes(seachEmail?.toLowerCase())
      );
    });
    // console.log(filteredUsers, 'filteredUsers');
    setfilteredUsers(filteredUsers);
  }, [seachEmail]);

  return (
    <div className={classNames.emailSelection}>
      <div>
        <div className={classNames.title}>Enter the email of the user</div>
        <input
          className={classNames.emailInput}
          placeholder="Email..."
          onChange={(event) => setSeachEmail(event?.target?.value)}
          value={
            userSelectedOnHold
              ? userSelectedOnHold
              : seachEmail
              ? seachEmail
              : ''
          }
        />
      </div>

      <div className={classNames.userFind}>
        {loading ? (
          <div className={classNames.spinner}>
            <div className={classNames.spinnerIcon}></div>
          </div>
        ) : filteredusers?.length > 0 && seachEmail ? (
          filteredusers?.map((eachUser) => {
            return <EachUser {...eachUser} setSeachEmail={setSeachEmail} />;
          })
        ) : (
          ''
        )}
      </div>

      <div className={classNames.btnsContainer}>
        <div className={classNames.greenBtn} onClick={() => setOnHold('step3')}>
          Initiate
        </div>
        <div className={classNames.redBtn} onClick={() => setOnHold('')}>
          Close
        </div>
      </div>
    </div>
  );
};

export default EmailSelection;

export const EachUser = ({ name, email, profile_img, setSeachEmail }) => {
  const { userSelectedOnHold, setUserSelectedOnHold } = useContext(BankContext);

  return (
    <div
      className={classNames.eachUserEmailSelection}
      onClick={() => {
        if (setSeachEmail) {
          setSeachEmail('');
          setUserSelectedOnHold(email);
        }
      }}
    >
      <div className={classNames.userProfile}>
        {profile_img && <img src={profile_img} alt="profile_img" />}
      </div>
      <div className={classNames.userProfileDetails}>
        <div>{name}</div>
        <div>{email}</div>
      </div>
    </div>
  );
};
