import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BankContext } from '../../context/Context';
import TwoFaModel from '../TwoFaModel/TwoFaModel';

function GetStartedGetTempCred({
  setStepName,
  logo,
  setMailId,
  mailId,
  setTempPassword,
  tempPassword,
  setLoading,
  stepName,
}) {
  const history = useHistory();
  const { userLoginHandler } = useContext(BankContext);
  const [mfaEnabled, setMfaEnabled] = useState(false);
  const ref = useRef();
  useEffect(() => {
    setTimeout(() => {
      ref && ref.current.focus();
    }, 200);
  }, []);
  function tempLogin() {
    setLoading(true);
    Axios.post('https://gxauth.apimachine.com/gx/user/login', {
      email: mailId,
      password: tempPassword,
    })
      .then(({ data }) => {
        if (data.status) {
          userLoginHandler(mailId, data.accessToken, data.idToken);
          history.push('/app');
        } else if (data.resetPassword) {
          setStepName('setPassword');
        } else if (data.mfa) {
          setMfaEnabled(true);
        }
      })
      .finally(() => setLoading(false));
  }
  const [eye, setEye] = useState(false);
  return (
    <div className="stepWrapper stepForm">
      <img src={logo} alt="assetLogo" className="assetLogo" />
      {stepName === 'login' ? (
        <div className="stepDesc"></div>
      ) : (
        <div className="stepDesc">
          Enter The Temporaray Credentials You Got In Your Email
        </div>
      )}
      <div className="group">
        <input
          type="text"
          className="input email"
          placeholder="Email Id"
          value={mailId}
          onChange={(e) => setMailId(e.target.value)}
        />
      </div>
      <div className="group">
        <input
          ref={ref}
          type={eye ? 'text' : 'password'}
          className="input password"
          placeholder="Password"
          value={tempPassword}
          onChange={(e) => setTempPassword(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              tempLogin();
            }
          }}
        />
        <FontAwesomeIcon
          icon={eye ? faEye : faEyeSlash}
          className="eye"
          onClick={() => setEye(!eye)}
        />
      </div>
      <div className="group btnSingle" onClick={() => tempLogin()}>
        {stepName === 'login' ? 'LOGIN' : 'NEXT STEP'}
      </div>
      {mfaEnabled && (
        <TwoFaModel
          email={mailId}
          password={tempPassword}
          setPassword={setTempPassword}
          onLogin={() => {
            setMfaEnabled(false);
            history.push('/');
          }}
        />
      )}
    </div>
  );
}

export default GetStartedGetTempCred;
