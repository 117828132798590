import axios from 'axios';
import React, { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useAppContextDetails } from '../../../context/AppContext';
import Skeleton from 'react-loading-skeleton';
import './moneyMarketData.scss';

const MoneyMarketData = ({ assetType }) => {
  //   const { email, token, setSidebarCollapse } = useContext(BankContext);
  const { appFullLogo, appName, ownerEmail, appCode } = useAppContextDetails();
  const [allBonds, setAllBonds] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {

    setLoading(true);
    axios
      .get(`https://comms.globalxchange.io/coin/iced/get/liquid/interest`, {
        params: {
          app_code: appCode,
          asset_type: assetType,
        },
      })
      .then(({ data }) => {
        if (data.status) {
          setAllBonds(data.interest_rates);
          setLoading(false);
        }
      });
  }, [assetType]);

  return (
    <>
      {!loading ? (
        allBonds.length > 0 ? (
          allBonds.map((item, index) => {
            return (
              <div className="bondListWrapper" key={index}>
                <div className="rowStyle">
                  <div className="rowLeft">
                    <img
                      src={item.coin_metdata.coinImage}
                      alt=""
                      className="mktImgStyle"
                    />
                    <div style={{ paddingLeft: '10px' }}>
                      {item.coin_metdata.coinName}
                    </div>
                  </div>
                  <div>{item?.app_price_dc?.toFixed(4)}</div>
                </div>
                <div className="rowStyle1">
                  <div>Daily Rate</div>
                  <div>{item.interest_rate.toFixed(5)}%</div>
                </div>
                <div className="rowStyle1">
                  <div>Monthly Rate</div>
                  <div> {item.monthly_interest_rate.toFixed(5)}%</div>
                </div>
                <div className="rowStyle1">
                  <div>Annual Rate</div>
                  <div>{item.yearly_interest_rate.toFixed(5)}%</div>
                </div>
              </div>
            );
          })
        ) : (
          <div>No Data</div>
        )
      ) : (
        Array(10)
          .fill(' ')
          .map((item, index) => {
            return (
              <div className="bondListWrapper" key={index}>
                <div className="rowStyle">
                  <div className="rowLeft">
                    <Skeleton width={20} height={20} />
                    <Skeleton
                      width={100}
                      height={20}
                      style={{ marginLeft: 10 }}
                    />
                  </div>
                  <Skeleton width={70} height={20} />
                </div>
                <div className="rowStyle1">
                  <Skeleton width={100} height={10} />
                  <Skeleton width={70} height={10} />
                </div>
                <div className="rowStyle1">
                  <Skeleton width={100} height={10} />
                  <Skeleton width={70} height={10} />
                </div>
                <div className="rowStyle1">
                  <Skeleton width={100} height={10} />
                  <Skeleton width={70} height={10} />
                </div>
              </div>
            );
          })
      )}
    </>
  );
};

export default MoneyMarketData;
