import React, { useContext, useEffect, useState } from 'react';
import './addcrypto.scss';
import axios from 'axios';
import { useCoinContextData } from '../../../context/CoinContext';
import QRCode from 'qrcode.react';
import { BankContext } from '../../../context/Context';
import { useAppContextDetails } from '../../../context/AppContext';
import paste from '../../../static/images/clipIcons/pasteIcon.svg';
import arrowIcon from '../../../static/images/clipIcons/arrowIcon.svg';
import sampleQR from '../../../static/images/clipIcons/sampleQR.svg';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';

const AddCryptoAction = () => {
  const { email } = useContext(BankContext);
  const history = useHistory();
  const {
    selectedCoin,
    coinAction,
    setCoinAction,
    coinType,
    swapMenu,
    setSwapMenu,
    setCoinActionEnabled,
    loading,
    setLoading,
    networkData,
    setNetworkData,
    selectedNetwork,
    setSelectedNetwork,
    setSelectedCoin,
  } = useCoinContextData();

  const { appCode, appByCode, appColorCode, appLogo } = useAppContextDetails();

  const [addcryptoStep, setAddcryptoStep] = useState(1);
  const [hashCode, setHashCode] = useState();
  const [submitHashResponse, setSubmitHashResponse] = useState();
  const [submitHashResponse1, setSubmitHashResponse1] = useState();
  const [submitHashResponse2, setSubmitHashResponse2] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submitHashFunction = () => {
    if (hashCode && selectedNetwork?.networkName === 'Ripple') {
      setIsSubmitting(true);
      let body = {
        email,
        app_code: appCode,
        hash: hashCode,
      };
      axios
        .post(
          'https://comms.globalxchange.io/coin/vault/service/deposit/xrp/external/request',
          body
        )
        .then((response) => {
          let result = response?.data;
          // console.log(result, 'Ripple result');
          if (result?.status) {
            setSubmitHashResponse(result?.txnData);
            setIsSubmitting(false);
            setAddcryptoStep(4);
            history.push(
              `/vault/${selectedCoin.type}/${appCode}/${selectedCoin.coinSymbol}`
            );
          } else if (
            !result?.status &&
            result?.message ===
              'The user has already been credited for this txn'
          ) {
            setIsSubmitting(false);
            setAddcryptoStep(4);
            setSubmitHashResponse1(
              'You have already been credited for this transaction.'
            );
          } else if (!result?.status && result?.message === 'Invalid hash') {
            setIsSubmitting(false);
            setAddcryptoStep(4);
            setSubmitHashResponse2('Sorry, you have entered an invalid hash.');
          } else {
            setIsSubmitting(false);
            setAddcryptoStep(4);
            setSubmitHashResponse2('Sorry, you have entered an invalid hash.');
          }
        })
        .catch((error) => {
          console.log(error, 'error in submitHashFunction Ripple');
        });
    } else if (hashCode && selectedNetwork?.networkName === 'Solana') {
      setIsSubmitting(true);
      let body = {
        email,
        app_code: appCode,
        txn_signature: hashCode,
      };
      axios
        .post(
          'https://comms.globalxchange.io/coin/vault/service/deposit/sol/external/request',
          body
        )
        .then((response) => {
          let result = response?.data;
          // console.log(result, 'Solana result');
          if (result?.status) {
            setSubmitHashResponse(result?.txnData);
            setIsSubmitting(false);
            setAddcryptoStep(4);
            history.push(
              `/vault/${selectedCoin.type}/${appCode}/${selectedCoin.coinSymbol}`
            );
          } else if (
            !result?.status &&
            result?.message ===
              'There is already a transaction with the given Txn Signature!'
          ) {
            setIsSubmitting(false);
            setAddcryptoStep(4);
            setSubmitHashResponse1(
              'You have already been credited for this transaction.'
            );
          } else if (!result?.status && result?.message === 'Invalid hash') {
            setIsSubmitting(false);
            setAddcryptoStep(4);
            setSubmitHashResponse2('Sorry, you have entered an invalid hash.');
          } else {
            setIsSubmitting(false);
            setAddcryptoStep(4);
            setSubmitHashResponse2('Sorry, you have entered an invalid hash.');
          }
        })
        .catch((error) => {
          console.log(error, 'error in submitHashFunction SOL');
        });
    } else if (hashCode && selectedNetwork?.networkName === 'Tron (TRC20)') {
      setIsSubmitting(true);
      let body = {
        email,
        app_code: appCode,
        hash: hashCode,
        coin: selectedCoin?.coinSymbol,
      };
      axios
        .post(
          'https://comms.globalxchange.io/coin/vault/service/deposit/trx/external/requestnew',
          body
        )
        .then((response) => {
          let result = response?.data;
          // console.log(result, 'Tron (TRC20) result');
          if (result?.status) {
            setSubmitHashResponse(result?.txnData);
            setIsSubmitting(false);
            setAddcryptoStep(4);
            history.push(
              `/vault/${selectedCoin.type}/${appCode}/${selectedCoin.coinSymbol}`
            );
          } else if (
            !result?.status &&
            result?.message ===
              'The user has already been credited for this txn'
          ) {
            setIsSubmitting(false);
            setAddcryptoStep(4);
            setSubmitHashResponse1(
              'You have already been credited for this transaction.'
            );
          } else if (!result?.status && result?.message === 'Invalid hash') {
            setIsSubmitting(false);
            setAddcryptoStep(4);
            setSubmitHashResponse2('Sorry, you have entered an invalid hash.');
          } else {
            setIsSubmitting(false);
            setAddcryptoStep(4);
            setSubmitHashResponse2('Sorry, you have entered an invalid hash.');
          }
        })
        .catch((error) => {
          console.log(error, 'error in submitHashFunction Tron (TRC20)');
        });
    } else if (
      hashCode &&
      selectedNetwork?.networkName === 'Ethereum (ERC20)'
    ) {
      setIsSubmitting(true);
      let body = {
        email,
        app_code: appCode,
        txn_hash: hashCode,
        coin: selectedCoin?.coinSymbol,
      };
      axios
        .post(
          'https://comms.globalxchange.io/coin/vault/service/deposit/eth/coins/request',
          body
        )
        .then((response) => {
          let result = response?.data;
          // console.log(result, 'Ethereum (ERC20) result');
          if (result?.status) {
            setSubmitHashResponse(result?.txnData);
            setIsSubmitting(false);
            setAddcryptoStep(4);
            history.push(
              `/vault/${selectedCoin.type}/${appCode}/${selectedCoin.coinSymbol}`
            );
          } else if (
            !result?.status &&
            result?.message ===
              'There is already txn defined with the given Txn hash!'
          ) {
            setIsSubmitting(false);
            setAddcryptoStep(4);
            setSubmitHashResponse1(
              'You have already been credited for this transaction.'
            );
          } else if (!result?.status && result?.message === 'Invalid hash') {
            setIsSubmitting(false);
            setAddcryptoStep(4);
            setSubmitHashResponse2('Sorry, you have entered an invalid hash.');
          } else {
            setIsSubmitting(false);
            setAddcryptoStep(4);
            setSubmitHashResponse2('Sorry, you have entered an invalid hash.');
          }
        })
        .catch((error) => {
          console.log(error, 'error in submitHashFunction Ethereum (ERC20)');
        });
    }
  };

  const pasteFromClipboard = async () => {
    try {
      const text = await navigator.clipboard.readText();
      setHashCode(text);
    } catch (error) {
      console.error('Failed to paste from clipboard: ', error);
    }
  };

  const getNetworks = () => {
    setLoading(true);
    axios
      .get(
        `https://comms.globalxchange.io/coin/vault/service/coins/user/get?email=${email}&app_code=dgpayments`
      )
      .then((response) => {
        let result = response?.data?.data?.[0];
        // console.log(result[selectedCoin?.coinSymbol], 'networkData result');
        // console.log(selectedCoin?.coinSymbol, 'selectedCoin?.coinSymbol');
        if (result) {
          let data = result[selectedCoin?.coinSymbol];
          if (data?.length > 0) {
            setNetworkData(data);
            setLoading(false);
          }
        }
      })
      .catch((error) => {
        console.log(error, 'error in getNetworks');
      });
  };

  const generateAddress = (network) => {
    let obj = {
      email,
      app_code: appCode,
      coin: network,
    };
    axios
      .post(
        'https://comms.globalxchange.io/coin/vault/service/crypto/address/request',
        obj
      )
      .then((response) => {
        let result = response?.data;
        // console.log(result, 'address result');
        // let coin = selectedCoin;
        // setSelectedCoin('');
        // setSelectedCoin(coin);
        if (result?.status) {
          setAddcryptoStep(1);
          getNetworks();
        }
      })
      .catch((error) => {
        console.log(error, 'error in generateAddress');
      });
  };

  return (
    <div className="addcrypto">
      <div className="addcrypto-breadcrumb">
        {addcryptoStep === 4 && submitHashResponse
          ? 'Transaction Credited'
          : addcryptoStep === 4 && submitHashResponse1
          ? 'Transaction Credited'
          : addcryptoStep === 4 && submitHashResponse2
          ? 'Transaction Failed'
          : `${selectedCoin?.coinSymbol} Vault Actions`}
      </div>

      {addcryptoStep === 1 && (
        <>
          <div className="addcrypto-heading">Select Network</div>
          <div className="step1-container">
            <div className="network-container">
              {loading
                ? Array(3)
                    .fill(' ')
                    .map((item, index) => {
                      return (
                        <div key={index} className="each-network">
                          <Skeleton width={200} height={35} />
                        </div>
                      );
                    })
                : networkData?.length > 0 &&
                  networkData?.map((e, i) => {
                    return (
                      <div
                        key={i}
                        className="each-network"
                        onClick={() => {
                          setSelectedNetwork(e);
                          setAddcryptoStep(2);
                        }}
                      >
                        <p>{e.networkName}</p>
                      </div>
                    );
                  })}
            </div>
            <div className="step1-footer">
              <div
                onClick={() => {
                  setCoinAction(['Menu']);
                  // setNetworkData('');
                }}
              >
                Close
              </div>
            </div>
          </div>
        </>
      )}

      {addcryptoStep === 2 && (
        <>
          <div className="addcrypto-heading">
            {selectedCoin?.coinSymbol} Address
          </div>
          <div className="step2-container">
            <div className="qr-div">
              {selectedNetwork?.address === 'na' ? (
                <div>
                  <img src={sampleQR} alt="" />
                </div>
              ) : (
                <QRCode
                  value={selectedNetwork?.address}
                  renderAs="svg"
                  size={200}
                />
              )}
            </div>
            <div className="instruction-div">
              <div>
                Send only {selectedCoin?.coinSymbol} via the{' '}
                {selectedNetwork?.networkName} network to this deposit address.
                Using a different network will result in losing your coins.
              </div>
            </div>
            <div className="network-div">
              <div className="headerTxt">Network</div>
              <div className="network-field">
                {selectedNetwork?.networkName}
              </div>
            </div>
            <div className="addresss-div">
              <div className="addresss-headerTxt">
                <div>Address</div>
              </div>
              {selectedNetwork?.address === 'na' ? (
                <div className="address-field1">
                  <div
                    style={{ textDecorationLine: 'underline' }}
                    onClick={() => {
                      if (selectedNetwork?.networkName === 'Ethereum (ERC20)') {
                        generateAddress('ETH');
                      } else if (
                        selectedNetwork?.networkName === 'Tron (TRC20)'
                      ) {
                        generateAddress('TRX');
                      } else if (selectedNetwork?.networkName === 'Bitcoin') {
                        generateAddress('BTC');
                      } else if (selectedNetwork?.networkName === 'Solana') {
                        generateAddress('SOL');
                      } else if (selectedNetwork?.networkName === 'Ripple') {
                        generateAddress('XRP');
                      }
                    }}
                  >
                    Click Here To Generate Address
                    <img src={arrowIcon} alt="" />
                  </div>
                </div>
              ) : (
                <div className="address-field">{selectedNetwork?.address}</div>
              )}
            </div>
            <div className="deposit-div">
              <div className="topp-div">
                <div>Minimum Deposit</div>
                <div style={{ fontWeight: '500' }}>
                  0.00 {selectedCoin?.coinSymbol}
                </div>
              </div>
              <div className="topp-div">
                <div>Hash Submission</div>
                <div style={{ fontWeight: '500' }}>
                  {' '}
                  {selectedNetwork?.hash_submission_mandatory
                    ? 'Mandatory'
                    : 'Not Required'}
                </div>
              </div>
            </div>
            <div
              className="submit-Btn"
              style={{
                display: selectedNetwork?.hash_submission_mandatory
                  ? 'flex'
                  : 'none',
                opacity: selectedNetwork?.address != 'na' ? '1' : '0.25',
                pointerEvents: selectedNetwork?.address != 'na' ? '' : 'none',
              }}
            >
              <div
                onClick={() => {
                  setAddcryptoStep(3);
                }}
              >
                Submit Hash
              </div>
            </div>
            <div className="bottom-btnsDiv">
              <div className="topp-btn">
                <div
                  style={{ border: '1px solid #E5E5E5' }}
                  onClick={() => {
                    setAddcryptoStep(1);
                    setSelectedNetwork('');
                  }}
                >
                  Back
                </div>
                <div
                  style={{ background: '#FF5E5E', color: '#fff' }}
                  onClick={() => {
                    setAddcryptoStep(1);
                    setCoinAction(['Menu']);
                    setNetworkData('');
                    setSelectedNetwork('');
                    setCoinActionEnabled(false);
                  }}
                >
                  Close
                </div>
              </div>
              <div
                style={{
                  background: 'linear-gradient(270deg,#81cbd7,#3d4394)',
                }}
                className="bottomm-btn"
                onClick={() => {
                  setAddcryptoStep(1);
                  setCoinAction(['Menu']);
                  setSelectedNetwork('');
                }}
              >
                {selectedCoin?.coinSymbol} Actions
              </div>
            </div>
          </div>
        </>
      )}

      {addcryptoStep === 3 && (
        <>
          <div className="addcrypto-heading">Enter Hash</div>
          <div className="step2-container" style={{ paddingTop: '2.5rem' }}>
            <div className="network-div">
              <div className="headerTxt">Network</div>
              <div className="network-field">
                {selectedNetwork?.networkName}
              </div>
            </div>
            <div className="addresss-div">
              <div className="addresss-headerTxt">
                <div>Address</div>
                <div
                  className="explorerText"
                  onClick={() => {
                    if (selectedNetwork?.networkName === 'Ripple') {
                      window.open(
                        `https://xrpscan.com/account/${selectedNetwork?.address}`,
                        '_blank'
                      );
                    } else if (
                      selectedNetwork?.networkName === 'Tron (TRC20)'
                    ) {
                      window.open(
                        `https://tronscan.org/#/address/${selectedNetwork?.address}`,
                        '_blank'
                      );
                    } else if (selectedNetwork?.networkName === 'Solana') {
                      window.open(
                        `https://explorer.solana.com/address/${selectedNetwork?.address}`,
                        '_blank'
                      );
                    } else if (
                      selectedNetwork?.networkName === 'Ethereum (ERC20)'
                    ) {
                      window.open(
                        `https://etherscan.io/address/${selectedNetwork?.address}`,
                        '_blank'
                      );
                    }
                  }}
                >
                  Open In Explorer
                </div>
              </div>
              <div className="address-field">{selectedNetwork?.address}</div>
            </div>
            <div className="hash-div">
              <div className="hash-headerTxt">Enter Hash</div>
              <div className="hash-field">
                <input
                  type="text"
                  placeholder="Hash..."
                  onChange={(e) => {
                    setHashCode(e.target.value);
                  }}
                  value={hashCode}
                />
                <div className="pasteIcon">
                  <img
                    src={paste}
                    alt="paste"
                    onClick={() => {
                      pasteFromClipboard();
                    }}
                  />
                </div>
              </div>
            </div>
            <div
              className="submit-Btn"
              style={{
                position: 'absolute',
                bottom: '11rem',
                marginBottom: '0',
                left: '0',
              }}
            >
              <div
                onClick={() => {
                  if (hashCode) {
                    submitHashFunction();
                  }
                }}
                style={{
                  opacity: !hashCode ? '0.25' : isSubmitting ? '0.25' : '1',
                  cursor: !hashCode
                    ? 'not-allowed'
                    : isSubmitting
                    ? 'not-allowed'
                    : 'pointer',
                }}
              >
                {isSubmitting ? 'Loading...' : 'Submit Hash'}
              </div>
            </div>
            <div
              className="bottom-btnsDiv"
              style={{ position: 'absolute', bottom: '0', left: '0' }}
            >
              <div className="topp-btn">
                <div
                  style={{ border: '1px solid #E5E5E5' }}
                  onClick={() => {
                    setAddcryptoStep(2);
                    setHashCode('');
                  }}
                >
                  Back
                </div>
                <div
                  style={{ background: '#FF5E5E', color: '#fff' }}
                  onClick={() => {
                    setAddcryptoStep(1);
                    setCoinAction(['Menu']);
                    setNetworkData('');
                    setSelectedNetwork('');
                    setCoinActionEnabled(false);
                    setHashCode('');
                  }}
                >
                  Close
                </div>
              </div>
              <div
                style={{
                  background: 'linear-gradient(270deg,#81cbd7,#3d4394)',
                }}
                className="bottomm-btn"
                onClick={() => {
                  setAddcryptoStep(1);
                  setCoinAction(['Menu']);
                  setHashCode('');
                  setSelectedNetwork('');
                }}
              >
                {selectedCoin?.coinSymbol} Actions
              </div>
            </div>
          </div>
        </>
      )}

      {addcryptoStep === 4 && (
        <>
          <div className="addcrypto-heading">
            {submitHashResponse && 'Hash Entry Successful'}
            {submitHashResponse1 && 'Hash Entry Successful'}
            {submitHashResponse2 && 'Hash Entry Failed'}
          </div>
          <div className="step2-container" style={{ paddingTop: '2.5rem' }}>
            <div style={{ padding: '0 1.85rem' }}>
              {submitHashResponse && (
                <>
                  Congratulations. You Have Successfully Added{' '}
                  {submitHashResponse?.amount} {submitHashResponse?.coin} To
                  Your {submitHashResponse?.coin} Vault.
                </>
              )}
              {submitHashResponse1 && <>{submitHashResponse1}</>}
              {submitHashResponse2 && <>{submitHashResponse2}</>}
            </div>
            <div
              className="bottom-btnsDiv"
              style={{ position: 'absolute', bottom: '0', left: '0' }}
            >
              <div className="topp-btn">
                <div
                  style={{
                    background: '#FF5E5E',
                    color: '#fff',
                    width: '100%',
                  }}
                  onClick={() => {
                    setAddcryptoStep(1);
                    setCoinAction(['Menu']);
                    setNetworkData('');
                    setSelectedNetwork('');
                    setCoinActionEnabled(false);
                    setHashCode('');
                    setSubmitHashResponse('');
                    setSubmitHashResponse1('');
                    setSubmitHashResponse2('');
                  }}
                >
                  Close
                </div>
              </div>
              <div
                style={{
                  background: 'linear-gradient(270deg,#81cbd7,#3d4394)',
                }}
                className="bottomm-btn"
                onClick={() => {
                  setAddcryptoStep(1);
                  setCoinAction(['Menu']);
                  setHashCode('');
                  setSelectedNetwork('');
                  setSubmitHashResponse('');
                  setSubmitHashResponse1('');
                  setSubmitHashResponse2('');
                }}
              >
                {selectedCoin?.coinSymbol} Actions
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AddCryptoAction;
