import React, { useEffect, useContext, useState } from 'react';
import './css/vaultBond.scss';
import Skeleton from 'react-loading-skeleton';
import searchIcon from '../../static/images/mobile/search.svg';
import cryptoIcon from '../../static/images/mobile/crypto.svg';
import forexIcon from '../../static/images/mobile/forex.svg';

import debitIcon from '../../static/images/mobile/debit.svg';
import creditIcon from '../../static/images/mobile/credit.svg';

import axios from 'axios';
import { useAppContextDetails } from '../../context/AppContext';

import { BankContext } from '../../context/Context';
import VaultAction from '../MobileLayoutComponents/VaultAction';
import { GX_API_ENDPOINT } from '../../config';
import {
  formatterHelper,
  usdValueFormatter,
  usdValueFormatterWithoutSign,
} from '../../utils/FunctionTools';
import { VaultPageContext } from '../../context/VaultPageContext';
import SwitcherSearch from './SwitcherSearch';

const VaultBonds = () => {
  const {
    appCode,
    selectedView,
    setSelectedView,
    selectedCurrency,
    setSelectedCurrency,
    ownerEmail,
    appName,
    appLogo,
    appColorCode,
  } = useAppContextDetails();
  const { email, token, setSidebarCollapse } = useContext(BankContext);
  const [allCoins, setAllCoins] = useState([]);
  const [allCoinData, setAllCoinData] = useState([]);
  const [allTxns, setAllTxns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [moneyMarket, setMoneyMarket] = useState(null);
  const [loadingCoin, setLoadingCoin] = useState(false);
  const [bondData, setBondData] = useState([]);

  const [openSearch, setOpenSearch] = useState(false);

  const getBondData = async () => {
    setLoading(true);
    axios
      .get(
        `https://comms.globalxchange.io/coin/iced/contract/get?email=${email}&banker_email=${ownerEmail}`
        // `https://comms.globalxchange.io/coin/iced/contract/get?email=${email}&banker_email=shorupan@indianinvestor.com`
      )
      .then(({ data }) => {
        console.log(data, 'jbckjwbckwhjb');
        setBondData(data.icedContracts[0]);
        setLoading(false);
      });
  };

  useEffect(() => {
    getBondData();
  }, []);

  useEffect(() => {
    setLoadingCoin(true);
    axios
      .get(
        `https://comms.globalxchange.io/coin/iced/interest/balances/get?displayCurrency=${
          // showBalanceIn?.coinSymbol ? showBalanceIn?.coinSymbol : 'USD'
          'USD'
        }&email=${email}&with_balances=true`
      )
      .then(({ data }) => {
        if (data.status) {
          const result = data.result[0];
          if (result) {
            setSelectedCurrency(result.balances[0]);

            setAllCoinData(result.balances);
          }
        }
      })
      .catch((error) =>
        console.log('Error on getting MoneyMarket Balance', error)
      )
      .finally(() => setLoadingCoin(false));
  }, []);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${GX_API_ENDPOINT}/coin/iced/liquid/earnings/per/user/get`, {
        params: {
          app_code: appCode,
          coin: selectedCurrency?.coinSymbol || '',
          email,
        },
      })
      .then(({ data }) => {
        if (data.status) {
          setMoneyMarket(data.coins[0]);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log('Error getting money market', err);
      });
  }, [selectedCurrency]);

  useEffect(() => {
    if (selectedView === 'Transactions') {
      getTxns();
    }
  }, [selectedView, selectedCurrency]);

  const getTxns = () => {
    setLoading(true);
    axios
      .get(`https://comms.globalxchange.io/coin/iced/interest/logs/get`, {
        params: {
          email: email,
          coin: selectedCurrency?.coinSymbol,
        },
      })
      .then(({ data }) => {
        setAllTxns(data?.interestLogs);
        setLoading(false);
      });
  };

  const conditionalAmount = (item) => {
    if (selectedCurrency.coinSymbol === 'USDT') {
      return usdValueFormatterWithoutSign.format(
        item.withdraw ? item?.amount : item?.credited_interest
      );
    } else {
      return item.withdraw
        ? item?.amount.toFixed(9)
        : item?.credited_interest.toFixed(9);
    }
  };

  const conditionalTxnBalance = (item) => {
    if (selectedCurrency.coinSymbol === 'USDT') {
      return usdValueFormatterWithoutSign.format(item.updated_interest);
    } else {
      // return formatterHelper(item.updated_interest, activeWallet.coinSymbol);
      return item.updated_interest.toFixed(9);
    }
  };

  const conditionalView = () => {
    if (selectedView === 'Overview') {
      return (
        <>
          {!loading ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                // justifyContent: 'center',
                paddingTop: '5vh',
              }}
            >
              <div style={{ fontSize: '40px', fontWeight: 700 }}>
                {selectedCurrency?.coinValue?.toFixed(4)}
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  // justifyContent: 'space-between',
                  alignItems: 'center',
                  height: '60vh',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    paddingTop: '10px',
                  }}
                >
                  <img
                    src={selectedCurrency?.coinImage}
                    alt=""
                    width={20}
                    height={20}
                  />
                  <div style={{ fontSize: '18px', paddingLeft: '10px' }}>
                    {selectedCurrency?.coinName}
                  </div>
                </div>

                <div style={{ paddingTop: '20px' }}>
                  {!loading ? (
                    bondData?.contracts?.length > 0 ? (
                      <div style={{ overflowY: 'scroll' }}>
                        {bondData?.contracts?.map((item) => {
                          return (
                            <div className="bondWrapper">
                              <div className="dataRow">
                                <div className="headerLeft">
                                  <img src={appLogo} alt="" />
                                  <div>
                                    <div className="title">{appName}</div>
                                    <div className="subTitle">
                                      {item?.countryData?.code}
                                    </div>
                                  </div>
                                </div>
                                <div className="headerLeft">
                                  <img
                                    src={selectedCurrency?.coinImage}
                                    alt=""
                                  />
                                  <div className="title">
                                    {selectedCurrency?.coinSymbol}
                                  </div>
                                </div>
                              </div>
                              <div className="dataRow">
                                <div>
                                  <div className="title">
                                    {item?.investment.toFixed(4)}
                                  </div>
                                  <div className="subTitle">Investment</div>
                                </div>
                                <div className="dataRight">
                                  <div className="title">
                                    {item.voc.toFixed(4)}
                                  </div>
                                  <div className="subTitle">Current VOC</div>
                                </div>
                              </div>
                              <div className="dataRow">
                                <div>
                                  <div className="title">
                                    {item.completed_stats.earnings.toFixed(4)}
                                  </div>
                                  <div className="subTitle">
                                    Interest Earned
                                  </div>
                                </div>
                                <div className="dataRight">
                                  <div className="title">
                                    {item.remaining_stats.earnings.toFixed(4)}
                                  </div>
                                  <div className="subTitle">
                                    Interest Receivable
                                  </div>
                                </div>
                              </div>
                              <div className="dataRow1">
                                <div>
                                  <div className="title">Status</div>
                                </div>
                                <div className="dataRight">
                                  <div className="title">{item?.status}</div>
                                </div>
                              </div>
                              <div className="dataRow1">
                                <div>
                                  <div className="title">Bonds</div>
                                </div>
                                <div className="dataRight">
                                  <div className="title">
                                    {item?.num_of_bonds}
                                  </div>
                                </div>
                              </div>
                              <div className="dataRow1">
                                <div>
                                  <div className="title">Days</div>
                                </div>
                                <div className="dataRight">
                                  <div className="title">
                                    {item?.completed_stats?.days}/{item?.days}
                                  </div>
                                </div>
                              </div>
                              <div className="dataRow2">
                                <div
                                  className="roundButton"
                                  onClick={(e) =>
                                    window.open(
                                      `https://tokenhash.com/bond/${item._id}`,
                                      '_blank'
                                    )
                                  }
                                >
                                  Bond Hash
                                </div>
                                <div
                                  className="roundButton"
                                  style={{
                                    background: appColorCode,
                                    opacity: 0.3,
                                    color: 'white',
                                  }}
                                >
                                  Renew
                                </div>
                                <div
                                  className="roundButton"
                                  style={{ opacity: 0.3 }}
                                >
                                  Redeem
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : null
                  ) : (
                    <>
                      {[...Array(3)].map((_, index) => (
                        <div key={index}>
                          <Skeleton width={100} height={40} />
                          <Skeleton width={150} height={15} />
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: '60vh',
              }}
            >
              <div style={{ textAlign: 'center', marginTop: '10vh' }}>
                <Skeleton height={30} width={150} />
                <Skeleton height={10} width={100} />
              </div>
              <div>
                <div style={{ textAlign: 'center', marginTop: '5vh' }}>
                  <Skeleton height={30} width={150} />
                  <Skeleton height={10} width={100} />
                </div>
                <div style={{ textAlign: 'center', marginTop: '5vh' }}>
                  <Skeleton height={30} width={150} />
                  <Skeleton height={10} width={100} />
                </div>
                <div style={{ textAlign: 'center', marginTop: '5vh' }}>
                  <Skeleton height={30} width={150} />
                  <Skeleton height={10} width={100} />
                </div>
              </div>
            </div>
          )}
        </>
      );
    } else if (selectedView === 'Transactions') {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            // alignItems: 'center',
            // justifyContent: 'center',
            paddingTop: '5vh',
          }}
        >
          {!loading
            ? allTxns?.map((item) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      border: '1px solid #e7e7e7',
                      borderRadius: '10px',
                      padding: '20px',
                      marginBottom: '10px',
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {item?.withdraw ? (
                        <img src={debitIcon} width={30} height={30} />
                      ) : (
                        <img src={creditIcon} width={30} height={30} />
                      )}
                      <div style={{ paddingLeft: '10px' }}>
                        <div>{`${selectedCurrency?.coinSymbol} ${
                          item.deposit ? 'Credit' : 'Debit'
                        }`}</div>
                        <div style={{ fontSize: '9px' }}>{item.date}</div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                      }}
                    >
                      <div
                        style={{
                          fontWeight: 'bold',
                          color: item?.deposit ? 'green' : 'red',
                        }}
                      >
                        {item?.deposit ? '+' : '-'}
                        {conditionalAmount(item)}
                      </div>
                      <div style={{ fontSize: '9px' }}>
                        Balance: {conditionalTxnBalance(item)}
                      </div>
                    </div>
                  </div>
                );
              })
            : Array(10)
                .fill(' ')
                .map((item) => {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        border: '1px solid #e7e7e7',
                        borderRadius: '10px',
                        padding: '20px',
                        marginBottom: '10px',
                      }}
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Skeleton
                          height={40}
                          width={40}
                          style={{ borderRadius: '50%' }}
                        />
                        <div style={{ paddingLeft: '10px' }}>
                          <Skeleton height={10} width={40} />
                          <Skeleton height={10} width={40} />
                        </div>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-end',
                        }}
                      >
                        <div>
                          <Skeleton height={10} width={40} />
                        </div>
                        <div style={{ fontSize: '9px' }}>
                          <Skeleton height={10} width={40} />
                        </div>
                      </div>
                    </div>
                  );
                })}
        </div>
      );
    } else if (selectedView === 'Actions') {
      return <VaultAction vaultType={'Bonds'} />;
    }
  };

  return (
    <div>
      {!openSearch ? (
        <>
          <div className="switcherWrapper">
            <div className="iconWrapper">
              <div className="iconDiv" onClick={(e) => setOpenSearch(true)}>
                <img className="divImg" src={searchIcon} alt="" />
              </div>
              <div
                style={{
                  fontSize: '10px',
                  fontWeight: 'bold',
                  paddingTop: '5px',
                }}
              >
                Search
              </div>
            </div>
            <div
              style={{
                overflowX: 'scroll',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {!loadingCoin ? (
                allCoinData.map((item) => {
                  return (
                    <div
                      className="iconWrapper"
                      onClick={(e) => setSelectedCurrency(item)}
                    >
                      <div
                        className="iconDiv"
                        // onClick={(e) => setSelectedCurrency(item)}
                      >
                        <img className="divImg" src={item?.coinImage} alt="" />
                      </div>
                      <div
                        style={{
                          width: '90px',
                          fontSize: '10px',
                          fontWeight:
                            item.coinSymbol === selectedCurrency.coinSymbol
                              ? 'bold'
                              : 500,
                          paddingTop: '5px',
                          textAlign: 'center',
                        }}
                      >
                        {item?.coinName}
                      </div>
                    </div>
                  );
                })
              ) : (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {Array(10)
                    .fill(' ')
                    .map((item) => {
                      return (
                        <div style={{ textAlign: 'center' }}>
                          <Skeleton
                            width={50}
                            height={50}
                            style={{ margin: '0px 10px', marginBottom: '5px' }}
                          />
                          <Skeleton width={50} height={5} />
                        </div>
                      );
                    })}
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              overflowY: 'scroll',
              height: '80vh',
              padding: '20px 22px',
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div
                style={{ fontWeight: selectedView === 'Overview' ? 700 : 500 }}
                onClick={(e) => setSelectedView('Overview')}
              >
                Overview
              </div>
              <div
                style={{
                  fontWeight: selectedView === 'Transactions' ? 700 : 500,
                }}
                onClick={(e) => setSelectedView('Transactions')}
              >
                Transactions
              </div>
              <div
                style={{ fontWeight: selectedView === 'Actions' ? 700 : 500 }}
                onClick={(e) => setSelectedView('Actions')}
              >
                Actions
              </div>
            </div>
            {conditionalView()}
          </div>
        </>
      ) : (
        <SwitcherSearch
          path={'VaultBonds'}
          listData={allCoinData}
          setSelectedData={setSelectedCurrency}
          setOpenSearch={setOpenSearch}
        />
      )}
    </div>
  );
};

export default VaultBonds;
