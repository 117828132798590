import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';

import markets from '../../static/images/markets.svg';

function ConrolHead({
  selectedInvestment,
  setSelectedInvestment,
  fullPathList,
  type,
}) {
  const [investments, setInvestments] = useState([]);
  useEffect(() => {
    Axios.get('https://comms.globalxchange.io/coin/investment/types/get').then(
      ({ data }) => {
        if (data.status) {
          setInvestments(data.investments);
        }
      }
    );
  }, []);
  return (
    <Scrollbars className="earn-intrest-controll">
      <div
        className={`tab-item ${!Boolean(selectedInvestment.code)}`}
        onClick={() => setSelectedInvestment({})}
      >
        <img className="coin-img" src={markets} alt />
        <div className="text-content">
          <h5>
            All Offerings
            <span>
              {
                fullPathList.filter((path) => !type || path.pathType === type)
                  .length
              }
            </span>
          </h5>
          <div className="desc">
            Discover Assets From Across All Asset Types{' '}
          </div>
        </div>
      </div>
      {investments.map((invest) => (
        <div
          className={`tab-item ${selectedInvestment === invest}`}
          onClick={() => setSelectedInvestment(invest)}
        >
          <img className="coin-img" src={invest.icon} alt />
          <div className="text-content">
            <h5>
              {invest.name}
              <span>
                {
                  fullPathList.filter(
                    (path) =>
                      (!type || path.pathType === type) &&
                      (!invest?.code || path.investmentType === invest.code)
                  ).length
                }
              </span>
            </h5>
            <div className="desc">{invest.description}</div>
          </div>
        </div>
      ))}
    </Scrollbars>
  );
}

export default ConrolHead;
