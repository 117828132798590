import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';

function SignupSetPassword({
  logo,
  password,
  setPassword,
  setSignupStep,
  isValid,
  createInbox,
  setPasswordReqModal,
}) {
  const [eye, setEye] = useState(false);
  const ref = useRef();
  useEffect(() => {
    setTimeout(() => {
      ref && ref.current.focus();
    }, 200);
  }, []);
  return (
    <div className="stepWrapper stepForm">
      <img src={logo} alt="assetLogo" className="assetLogo" />
      <div className="stepDesc">Create Your Password</div>
      <div className={`group ${isValid.password}`}>
        <input
          ref={ref}
          type={eye ? 'text' : 'password'}
          className="input password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setSignupStep(3);
              try {
                createInbox();
              } catch (error) {}
            }
          }}
        />
        <FontAwesomeIcon
          icon={eye ? faEye : faEyeSlash}
          className="eye"
          onClick={() => setEye(!eye)}
        />
      </div>
      <div className="btns">
        <div className="btnBig" onClick={() => setPasswordReqModal(true)}>
          Password Requirements
        </div>
        <div
          className="btnNext"
          onClick={() => {
            setSignupStep(3);
            try {
              createInbox();
            } catch (error) {}
          }}
        >
          Next
        </div>
      </div>
    </div>
  );
}

export default SignupSetPassword;
