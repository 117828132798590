import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import classNames from './terminalsSidebar.module.scss';
import terminalsFull from '../../static/images/marketsSidebar/terminalsFull.svg';
import { BankContext } from '../../context/Context';
import {
  FormatCurrency,
  FormatNumber,
  GetSortOrder,
} from '../../utils/FunctionTools';
import LoginModal from '../LoginModalNew';
import {
  useAppDetail,
  useSingleCoinBalance,
  useTokenDetailByStatus,
} from '../../queryHooks';
import TerminalsAppCoinModal from '../TerminalsAppCoinModal';
import { useMutation } from 'react-query';
import LoaderDots from '../LoaderDots';
import ShareTradeDetailModal from '../ShareTradeDetailModal';
import { useAppContextDetails } from '../../context/AppContext';
import { MarketContext } from '../../context/MarketContext';

async function buyShare(paramData) {
  const { data } = await axios.post(
    'https://comms.globalxchange.io/coin/investment/path/tokens/resale/order/create',
    paramData
  );
  return data;
}

function TerminalsSidebar({ shareToken }) {
  const { email, token, tostShowOn } = useContext(BankContext);
  const { terminalSidebar, setTerminalSidebar } = useContext(MarketContext);
  useEffect(() => {
    if (terminalSidebar === true) {
      setTerminalSidebar('Buy');
    }
  }, [terminalSidebar]);

  const { mutate: mutateBuyShare, isLoading } = useMutation(buyShare, {
    onSuccess: (data) => {
      if (data.status) {
        setSuccessModal(true);
        refetchOrderBook();
      } else {
        tostShowOn(data.message || 'Some Thing Went Wrong!');
      }
    },
  });

  const [marketOrder, setMarketOrder] = useState(true);
  const [price, setPrice] = useState('');
  const [amount, setAmount] = useState('');
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [vaultModalOpen, setVaultModalOpen] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [appSelected, setAppSelected] = useState();
  const [vaultSelected, setVaultSelected] = useState();
  const {
    data: orderBookData,
    isLoading: orderBookDataLoading,
    refetch: refetchOrderBook,
  } = useTokenDetailByStatus(shareToken?.token, 'pending');
  const { appCurrencyName } = useAppContextDetails();

  const { data: appDetail, isLoading: loadingApp } = useAppDetail(
    shareToken?.pathAppCode
  );
  useEffect(() => {
    if (terminalSidebar === 'Sell') {
      setVaultSelected(shareToken?.token_profile_data);
    } else {
      setVaultSelected({
        ...shareToken?.asset_metaData,
        coinSymbol: shareToken?.asset,
      });
    }
  }, [shareToken, terminalSidebar]);

  useEffect(() => {
    setAppSelected(appDetail);
  }, [appDetail]);

  const { data: coinBalance } = useSingleCoinBalance(
    appSelected?.app_code,
    email,
    terminalSidebar === 'Sell',
    vaultSelected?.coinSymbol,
    appCurrencyName
  );
  return (
    <>
      <div
        className={classNames.terminalsSidebar}
        style={{ maxWidth: '35%', borderLeft: '0.5px solid #e7e7e7' }}
      >
        {/* <div className={classNames.header}>
          {email && (
            <div
              className={classNames.app}
              onClick={() => setVaultModalOpen(true)}
            >
              <img
                src={appSelected?.app_icon}
                alt=""
                className={classNames.icon}
              />
            </div>
          )}
          <div />
          <img src={terminalsFull} alt="" className={classNames.logo} />
          <div />
          {email && (
            <div
              className={classNames.coin}
              onClick={() => setVaultModalOpen(true)}
            >
              <img
                src={vaultSelected?.coinImage}
                alt=""
                className={classNames.icon}
              />
              <span>
                {FormatCurrency(
                  coinBalance?.coinValue,
                  coinBalance?.coinSymbol
                )}
              </span>
            </div>
          )}
        </div> */}
        <div className={classNames.tableHead} style={{ height: '45px' }}>
          <span>Price</span>
          <span>Amount</span>
          <span>Total</span>
        </div>
        <div className={classNames.stepsRed}>
          {Array(6 - orderBookData?.sell_orders?.orders?.length || 0)
            .fill('')
            .map((_, i) => (
              <div
                key={i}
                className={classNames.step}
                style={{
                  background: `linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) ${
                    (i + 1) * 10
                  }%, rgba(239,83,80,0.17) ${
                    (i + 1) * 10
                  }%, rgba(239,83,80,0.17) 100%)`,
                }}
              >
                <span>--</span>
                <span>--</span>
                <span>--</span>
              </div>
            ))}
          {orderBookData?.sell_orders?.orders
            ?.sort(GetSortOrder('price', -1))
            .map((order, i) => (
              <div
                key={i}
                className={classNames.step}
                style={{
                  background: `linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) ${
                    (i + 1) * 10
                  }%, rgba(239,83,80,0.17) ${
                    (i + 1) * 10
                  }%, rgba(239,83,80,0.17) 100%)`,
                }}
              >
                <span>{FormatCurrency(order?.price)}</span>
                <span>{FormatNumber(order?.pendingAmount)}</span>
                <span>{FormatCurrency(order?.pendingVolume)}</span>
              </div>
            ))}
        </div>
        <div className={classNames.centerValue}>
          {/* {FormatCurrency(shareToken?.token_price, shareToken?.asset)} */}
        </div>
        <div className={classNames.stepsGreen}>
          {orderBookData?.buy_orders?.orders?.map((order, i) => (
            <div
              className={classNames.step}
              style={{
                background: `linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) ${
                  (8 - i) * 10
                }%, rgb(161 199 230) ${
                  (8 - i) * 10
                }%, rgb(161 199 230) 100%)`,
              }}
            >
              <span>{FormatCurrency(order?.price)}</span>
              <span>{FormatNumber(order?.pendingAmount)}</span>
              <span>{FormatCurrency(order?.pendingVolume)}</span>
            </div>
          ))}
          {Array(6 - orderBookData?.buy_orders?.orders?.length || 0)
            .fill('')
            .map((_, i) => (
              <div
                key={i}
                className={classNames.step}
                style={{
                  background: `linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) ${
                    (8 - i) * 10
                  }%, rgb(161 199 230) ${
                    (8 - i) * 10
                  }%, rgb(161 199 230) 100%)`,
                }}
              >
                <span>--</span>
                <span>--</span>
                <span>--</span>
              </div>
            ))}
        </div>
        <div className={classNames.txnCard}>
          <div className={classNames.subTab}>
            <div
              className={`${classNames.tabSubItm} ${classNames[marketOrder]}`}
              onClick={() => setMarketOrder(true)}
            >
              Market
            </div>
            <div
              className={`${classNames.tabSubItm} ${classNames[!marketOrder]}`}
              onClick={() => setMarketOrder(false)}
            >
              Limit
            </div>
            <div className={classNames.tabSubItm}>More</div>
          </div>
          <div className={classNames.cardIn}>
            <div className={classNames.topBuySell}>
              <div
                className={`${classNames.btnBuy} ${
                  classNames[terminalSidebar === 'Buy']
                }`}
                onClick={() => setTerminalSidebar('Buy')}
                key="buy"
              >
                Buy
              </div>
              <div
                className={`${classNames.btnSell} ${
                  classNames[terminalSidebar === 'Sell']
                }`}
                onClick={() => setTerminalSidebar('Sell')}
                key="sell"
              >
                Sell
              </div>
            </div>
            <label className={classNames.inpCard}>
              <div className={classNames.label}>
                Price Per {shareToken?.token}
              </div>
              <input
                type={marketOrder ? 'text' : 'number'}
                className={classNames.inp}
                value={marketOrder ? 'Market' : price}
                placeholder="0.00"
                onChange={(e) => {
                  const val = e.target.value;
                  setPrice(marketOrder ? undefined : val);
                  // marketOrder &&
                  //   setAmount(
                  //     FormatCurrency(val / shareToken?.token_price).replace(
                  //       ',',
                  //       ''
                  //     )
                  //   );
                }}
              />
              <span className={classNames.coin}>{shareToken?.asset}</span>
            </label>
            <label className={classNames.inpCard}>
              <div className={classNames.label}>Amount</div>
              <input
                type="number"
                className={classNames.inp}
                value={amount}
                placeholder="0"
                onChange={(e) => {
                  const val = e.target.value;
                  setAmount(val);
                  marketOrder &&
                    setPrice(
                      FormatCurrency(
                        val * shareToken?.token_price,
                        shareToken?.asset
                      ).replace(',', '')
                    );
                }}
              />
              <span className={classNames.coin}>{shareToken?.token}</span>
            </label>
            <div
              className={`${classNames.btn} ${classNames[terminalSidebar]}`}
              onClick={() => {
                if (email) {
                  mutateBuyShare({
                    email,
                    token,
                    app_code: appSelected.app_code,
                    order_type: terminalSidebar.toLowerCase(),
                    market_order: marketOrder,
                    amount,
                    price: marketOrder ? undefined : price,
                    coin: shareToken?.token,
                  });
                } else setLoginModalOpen(true);
              }}
            >
              {isLoading ? (
                <LoaderDots zoom={0.4} />
              ) : (
                <>
                  {terminalSidebar} {shareToken?.token}
                </>
              )}
            </div>
          </div>
          {isLoading && <div className={classNames.loadingAnim} />}
        </div>
      </div>
      {loginModalOpen && (
        <LoginModal
          onClose={() => {
            setLoginModalOpen(false);
          }}
          onSuccess={() => setLoginModalOpen(false)}
        />
      )}
      {vaultModalOpen && (
        <TerminalsAppCoinModal
          email={email}
          onClose={() => setVaultModalOpen(false)}
          onSuccess={() => setVaultModalOpen(false)}
          appSelected={appSelected}
          setAppSelected={setAppSelected}
          vaultSelected={vaultSelected}
          setVaultSelected={setVaultSelected}
        />
      )}
      {successModal && (
        <ShareTradeDetailModal
          shareToken={shareToken}
          onClose={() => setSuccessModal(false)}
        />
      )}
    </>
  );
}

export default TerminalsSidebar;
