/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useContext } from 'react';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import Axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { useHistory } from 'react-router-dom';
import Lottie from 'react-lottie';

import * as animationData from '../../static/animations/walletAdd.json';

import TwoFAInput from './TwoFAInput';
import { BankContext } from '../../context/Context';
import ResetModal from './ResetModal';
import ResendCode from './ResendCode';
import ForcedResetModal from './ForcedResetModal';
import ForcedVerifyResetModal from './ForcedVerifyResetModal';
import { useAppContextDetails } from '../../context/AppContext';

function LoginModal({ onClose, onLogin }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const history = useHistory();
  const [emailid, setEmailId] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const { userLoginHandler, tostShowOn, setTempMail, setTempPass } =
    useContext(BankContext);
  const [mfaEnabled, setMfaEnabled] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const [resendCode, setResendCode] = useState(false);
  const [forcedReset, setForcedReset] = useState(false);
  const [forcedVerifyReset, setForcedVerifyReset] = useState(false);

  const { appCode, appFullLogo } = useAppContextDetails();

  const loginvalidate = (password, emailid) => {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(emailid)) {
      setLoading(true);
      Axios.post('https://gxauth.apimachine.com/gx/user/login', {
        email: emailid,
        password,
      })
        .then((response) => {
          const { data } = response;
          if (data.status) {
            userLoginHandler(
              emailid,
              data.accessToken,
              data.idToken,
              data.deviceKey
            );
            try {
              onLogin();
            } catch (error) {}
            Axios.post(
              'https://comms.globalxchange.io/gxb/apps/register/user',
              {
                email: emailid, // user email
                app_code: appCode, // app_code
                fromAppCreation: true,
              }
            );
            setPassword('');
          } else if (data.mfa) {
            setMfaEnabled(true);
          } else if (data.resend_code) {
            setResendCode(true);
          } else if (data.resetPassword) {
            setForcedReset(true);
          } else if (data.passwordResetPassword) {
            setForcedVerifyReset(true);
          } else if (data.passwordResetRequired) {
            setTempMail(emailid);
            setTempPass(password);
            history.push('/reset');
          } else {
            tostShowOn(data.message);
          }
        })
        .catch((error) => {
          tostShowOn(error.message ? error.message : 'Some Thing Went Wrong!');
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      tostShowOn('Enter Valid EmailId');
    }
  };
  function getForm() {
    switch (true) {
      case isReset:
        return (
          <ResetModal
            emailid={emailid}
            setEmailId={setEmailId}
            loginvalidate={loginvalidate}
          />
        );
      case forcedReset:
        return (
          <ForcedResetModal
            emailid={emailid}
            loginvalidate={loginvalidate}
            oldPassword={password}
          />
        );
      case mfaEnabled:
        return (
          <TwoFAInput
            onLogin={onLogin}
            email={emailid}
            setPassword={setPassword}
            password={password}
          />
        );
      case resendCode:
        return (
          <ResendCode
            emailid={emailid}
            password={password}
            loginvalidate={loginvalidate}
          />
        );
      case forcedVerifyReset:
        return (
          <ForcedVerifyResetModal
            emailid={emailid}
            loginvalidate={loginvalidate}
          />
        );

      default:
        return (
          <div className="card login-enter">
            <form
              className="login-form mx-5"
              onSubmit={(e) => {
                e.preventDefault();
                loginvalidate(password, emailid);
              }}
            >
              <Fade bottom>
                <div className="group">
                  <input
                    type="text"
                    name="email"
                    value={emailid}
                    onChange={(e) => setEmailId(e.target.value)}
                    required="required"
                  />
                  <span className="highlight" />
                  <span className="bar" />
                  <label>Email</label>
                </div>
              </Fade>
              <Fade bottom>
                <div className="group">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required="required"
                  />
                  <span className="highlight" />
                  <span className="bar" />
                  <FontAwesomeIcon
                    className="eye"
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                    icon={showPassword ? faEyeSlash : faEye}
                  />
                  <label>Password</label>
                </div>
              </Fade>
              <Fade bottom>
                <div className="forgot" onClick={() => setIsReset(true)}>
                  Forgot Your Password?
                </div>
              </Fade>
              <Fade bottom>
                <div className="group">
                  <button
                    type="submit"
                    disabled={loading}
                    className="btn btn-darkblue mb-5"
                  >
                    {loading ? (
                      <FontAwesomeIcon icon={faSpinner} spin />
                    ) : (
                      'Enter'
                    )}
                  </button>
                </div>
              </Fade>
            </form>
            {loading && (
              <div className="loading">
                <Lottie options={defaultOptions} height={300} width={300} />
              </div>
            )}
          </div>
        );
    }
  }
  return (
    <div className="login-modal">
      <Zoom>
        <div className="login-signup">
          <div className="login-wrap">
            <div className="head">
              {isReset ? (
                <>
                  <div className="resetTitle">Reset Password</div>
                  <div className="closeBtn" onClick={() => setIsReset(false)} />
                </>
              ) : resendCode ? (
                <>
                  <div className="resetTitle">Verificaiton Required</div>
                  <div
                    className="closeBtn"
                    onClick={() => setResendCode(false)}
                  />
                </>
              ) : forcedReset ? (
                <>
                  <div className="resetTitle">Password Reset Required</div>
                  <div
                    className="closeBtn"
                    onClick={() => setForcedReset(false)}
                  />
                </>
              ) : (
                <img src={appFullLogo} alt="" />
              )}
            </div>
            {getForm()}
          </div>
        </div>
      </Zoom>
    </div>
  );
}

export default LoginModal;
