import React, { useState, useContext } from 'react';
import './createBond.scss';
import Asset from './asset';
import Time from './time';
import Amount from './amount';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Assetsvault from './assets-vault';
import Timedata from './timedata';
import Amountdata from './amountdata';
import { BankContext } from '../../context/Context';
import { AppContext } from '../../context/AppContext';

function CreateBond() {
  const { bondcount, setBondcount, selectedbtn, setSelectedbtn } =
    useContext(BankContext);
  const { appColorCode } = useContext(AppContext);

  const Changeonclick = () => {
    switch (selectedbtn) {
      case 'asset':
        return <Asset />;
      case 'time':
        return <Time />;
      case 'amount':
        return <Amount />;
      default:
        break;
    }
  };

  const Conditionalselect = () => {
    switch (selectedbtn) {
      case 'asset':
        return <Assetsvault />;
      case 'time':
        return <Timedata />;
      case 'amount':
        return <Amountdata />;
      case '':
        return <Amountdata />;
      default:
        break;
    }
  };

  return (
    <div className="s-selection-master">
      <div className="partition-div">
        <div className="left-div">
          <div className="select-asset">
            <div className="flex-div">{Changeonclick()}</div>
          </div>
          <div className="s-selection-footer">
            <div
              className="select-btn"
              style={{
                color: selectedbtn === 'asset' ? '#FFFFFF' : '#464B4E',
                backgroundColor:
                  selectedbtn === 'asset' ? appColorCode : '#FFFFFF',
                opacity: selectedbtn === 'asset' ? 1 : 0.25,
              }}
              onClick={() => {
                setSelectedbtn('asset');
                setBondcount('');
              }}
            >
              Asset
            </div>
            <div
              className="select-btn"
              style={{
                color: selectedbtn === 'time' ? '#FFFFFF' : '#464B4E',
                backgroundColor:
                  selectedbtn === 'time' ? appColorCode : '#FFFFFF',
                opacity: selectedbtn === 'time' ? 1 : 0.25,
              }}
              onClick={() => {
                setSelectedbtn('time');
                setBondcount('');
              }}
            >
              Time
            </div>
            <div
              className="select-btn"
              style={{
                color: selectedbtn === 'amount' ? '#FFFFFF' : '#464B4E',
                backgroundColor:
                  selectedbtn === 'amount' ? appColorCode : '#FFFFFF',
                opacity: selectedbtn === 'amount' ? 1 : 0.25,
              }}
              onClick={() => {
                setSelectedbtn('amount');
                setBondcount('');
              }}
            >
              Amount
            </div>
          </div>
        </div>
        <div className="right-div">{Conditionalselect()}</div>
      </div>
    </div>
  );
}

export default CreateBond;
