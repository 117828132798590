import React from 'react';
import { useContext, useState } from 'react';
import { planBContext } from '../../../context/PlanBContext';
import classNames from './components.module.scss';

//assets
import selectDownArrow from '../../../static/images/selectDownArrow.svg';
import { selectoneOptions } from '../data/data';
import { uploadImageFunc } from '../../../utils/imageUpload';
import { futureDates } from '../../../static/data/constData';
import { useEffect } from 'react';
import axios from 'axios';
import { BankContext } from '../../../context/Context';
import tempCanadaFlag from '../../../static/images/tempCanadaFlag.svg';
import retiredIcon from '../../../static/images/sidebar-icons/retired.svg';
import uploading from '../../../static/images/uploading.svg';
import dummyProfile from '../../../static/images/dummyProfile.svg';
import { predefinedToast } from '../../../utils/toast';
import { useAppContextDetails } from '../../../context/AppContext';
const CreatePlanB = () => {
  const { email, token } = useContext(BankContext);
  const { allOpportunitiesRefresh, setAllOpportunitiesRefresh } =
    useAppContextDetails();
  const {
    planBCreate,
    setplanBCreate,
    setinsideplanBCreate,
    planBAccountName,
    setplanBAccountName,
    planBAccountDesc,
    setplanBAccountDesc,
    setSelectedDropDown,
    planBAccountTargetDate,
    planBAccountContributor,
    planBAccountType,
    planBAccountPic,
    setCreationLoading,
    planBAccountGoalId,
    planBAccountTypeIcon,
    setplanBAccountTypeIcon,
    planBAccountPicUploading,
    //aiindex
    assetCustodyMap,
    productsTypeMap,
    aidropdownAsset,
    aidropdownCustody,
    setaidropdownAsset,
    setaidropdownCustody,
    //values
    aidropdownProductName,
    setaidropdownProductName,
    productTypeSelected,
    setProductTypeSelected,
    assestCustodySelected,
    setAssestCustodySelected,
    aidropdownDesc,
    setaidropdownDesc,
    aidropdownProdImg,
    setaidropdownProdImg,
    aidropdownMontlyCost,
    setaidropdownMontlyCost,
    aidropdownManaFee,
    setaidropdownManaFee,
    aidropdownROI30,
    setaidropdownROI30,
    aidropdownROI12,
    setaidropdownROI12,
    aidropdownProductLive,
    setaidropdownProductLive,
    aidropdownAvgDrawdown,
    setaidropdownAvgDrawdown,
    aidropdownSubs,
    setaidropdownSubs,
    aidropdownCompanyCreated,
    setaidropdownCompanyCreated,
    aidropdownCompanyWebsite,
    setaidropdownCompanyWebsite,
    aidropdownRegLink,
    setaidropdownRegLink,

    //new fields
    assetClass,
    setAssetClass,
    aidropdownAssetClass,
    setaidropdownAssetClass,
    assetClassMap,
    upfrontCost,
    setUpfrontCost,
    productCharge,
    setProductCharge,
    aidropdownProductCharge,
    setaidropdownProductCharge,
    productChargeMap,
    paymentTypes,
    setPaymentTypes,
    aidropdownPaymentTypes,
    setaidropdownPaymentTypes,
    paymentTypesMap,
    referralProgram,
    setReferralProgram,
    aidropdownReferralProgram,
    setaidropdownReferralProgram,
    referralProgramMap,
    productLeverage,
    setProductLeverage,
    aidropdownProductLeverage,
    setaidropdownProductLeverage,
    productLeverageMap,
  } = useContext(planBContext);

  function listProductCreationFunc() {
    let obj = {
      email: email,
      token: token,
      item_type: productTypeSelected ? productTypeSelected : '',
      custody: assestCustodySelected ? assestCustodySelected : '',
      item_name: aidropdownProductName ? aidropdownProductName : '',
      company_name: aidropdownCompanyCreated ? aidropdownCompanyCreated : '',
      company_website: aidropdownCompanyWebsite ? aidropdownCompanyWebsite : '',
      product_icon: aidropdownProdImg ? aidropdownProdImg : '',
      product_description: aidropdownDesc ? aidropdownDesc : '',
      cost_per_month: aidropdownMontlyCost ? aidropdownMontlyCost : '',
      management_fee: aidropdownManaFee ? aidropdownManaFee : '',
      link: aidropdownRegLink ? aidropdownRegLink : '',
      last_month_ROI: aidropdownROI30 ? aidropdownROI30 : '',
      last_12_months_ROI: aidropdownROI12 ? aidropdownROI12 : '',
      months_running: aidropdownProductLive ? aidropdownProductLive : '',
      avg_drawdown: aidropdownAvgDrawdown ? aidropdownAvgDrawdown : '',
      subscribers: aidropdownSubs ? aidropdownSubs : '',
      asset_class: assetClass ? assetClass : '',
      billing_type: productCharge ? productCharge : '',
      comp_plan: referralProgram ? referralProgram : '',
      leverage: productLeverage ? productLeverage : '',
      upfront_cost: upfrontCost ? upfrontCost : '',
      accepted_payment_methods: paymentTypes ? paymentTypes : '',
    };

    // if (
    //   productTypeSelected &&
    //   assestCustodySelected &&
    //   assetClass &&
    //   productCharge &&
    //   referralProgram &&
    //   productLeverage &&
    //   paymentTypes
    // ) {
    axios
      .post(
        'https://comms.globalxchange.io/gxb/product/aiprowallet/index/item/create',
        obj
      )
      .then((res) => {
        console.log(res, 'post ress');
        setinsideplanBCreate(false);
        if (res?.data?.status) {
          predefinedToast('Product listed successful..');
        }
        setAllOpportunitiesRefresh(!allOpportunitiesRefresh);
      })
      .catch((error) => {
        console.log(error?.message, 'error');
        predefinedToast('Network error try again..');
      });
    // } else {
    //   predefinedToast('Enter all the required fields...');
    // }

    // console.log(obj, 'aiproo');
  }

  return (
    <div className={classNames.createPlanB}>
      <div className={classNames.title}>Product Information</div>
      <div className={classNames.contentDiv}>
        <InputDivs
          heading="What is your product name?"
          placeholderText="Name..."
          setFunc={setaidropdownProductName}
          funcValue={aidropdownProductName}
        />

        <InputDropdown
          heading="What type of product is it?"
          setFunc={setProductTypeSelected}
          mapValue={productsTypeMap}
          funcValue={productTypeSelected}
          dropdown={aidropdownAsset}
          dropdownFunc={setaidropdownAsset}
        />
        <InputDropdown
          heading="How are the assets custodialized?"
          setFunc={setAssestCustodySelected}
          mapValue={assetCustodyMap}
          funcValue={assestCustodySelected}
          dropdown={aidropdownCustody}
          dropdownFunc={setaidropdownCustody}
        />
        <InputDivs
          heading="Describe the product"
          placeholderText="Description..."
          setFunc={setaidropdownDesc}
          funcValue={aidropdownDesc}
        />
        <InputDropdown
          heading="What asset class does this product trade in?"
          setFunc={setAssetClass}
          mapValue={assetClassMap}
          funcValue={assetClass}
          dropdown={aidropdownAssetClass}
          dropdownFunc={setaidropdownAssetClass}
        />

        <ImageUploadDiv
          heading="Upload the product icon"
          setFunc={setaidropdownProdImg}
          funcValue={aidropdownProdImg}
        />
      </div>
      <div className={classNames.title}>Product Cost</div>

      <div className={classNames.contentDiv}>
        <InputDropdown
          heading="How do you charge for this product"
          setFunc={setProductCharge}
          mapValue={productChargeMap}
          funcValue={productCharge}
          dropdown={aidropdownProductCharge}
          dropdownFunc={setaidropdownProductCharge}
        />
        <InputDropdown
          heading="What payment types do you accept?"
          setFunc={setPaymentTypes}
          funcValue={paymentTypes}
          mapValue={paymentTypesMap}
          dropdown={aidropdownPaymentTypes}
          dropdownFunc={setaidropdownPaymentTypes}
        />
        <InputDivs
          heading="What is the upfront cost?"
          placeholderText="$0.00"
          setFunc={setUpfrontCost}
          funcValue={upfrontCost}
        />
        <InputDivs
          heading="What is the monthly cost?"
          placeholderText="$0.00"
          setFunc={setaidropdownMontlyCost}
          funcValue={aidropdownMontlyCost}
        />
        <InputDivs
          heading="What is the management fee?"
          placeholderText="0.00%"
          setFunc={setaidropdownManaFee}
          funcValue={aidropdownManaFee}
        />
        <InputDropdown
          heading="Does this product have a referral program"
          setFunc={setReferralProgram}
          funcValue={referralProgram}
          mapValue={referralProgramMap}
          dropdown={aidropdownReferralProgram}
          dropdownFunc={setaidropdownReferralProgram}
        />
      </div>
      <div className={classNames.title}>Product Performance</div>
      <div className={classNames.contentDiv}>
        <InputDivs
          heading="What is the ROI in the last 30 days?"
          placeholderText="0.00%"
          setFunc={setaidropdownROI30}
          funcValue={aidropdownROI30}
        />
        <InputDivs
          heading="What is the ROI in the last 12 months?"
          placeholderText="0.00%"
          setFunc={setaidropdownROI12}
          funcValue={aidropdownROI12}
        />
        <InputDivs
          heading="How many months has this product been live?"
          placeholderText="0"
          setFunc={setaidropdownProductLive}
          funcValue={aidropdownProductLive}
        />
        <InputDivs
          heading="What is the average drawdown?"
          placeholderText="0.00%"
          setFunc={setaidropdownAvgDrawdown}
          funcValue={aidropdownAvgDrawdown}
        />
        <InputDivs
          heading="How many people are subscribed to the product?"
          placeholderText="0"
          setFunc={setaidropdownSubs}
          funcValue={aidropdownSubs}
        />
        <InputDropdown
          heading="Does this product use leverage to trade?"
          setFunc={setProductLeverage}
          funcValue={productLeverage}
          mapValue={productLeverageMap}
          dropdown={aidropdownProductLeverage}
          dropdownFunc={setaidropdownProductLeverage}
        />
      </div>
      <div className={classNames.title}>Company Information</div>
      <div className={classNames.contentDiv}>
        <InputDivs
          heading="Which company created this product?"
          placeholderText="Company name"
          setFunc={setaidropdownCompanyCreated}
          funcValue={aidropdownCompanyCreated}
        />
        <InputDivs
          heading="What is the company’s website?"
          placeholderText="Enter URL"
          setFunc={setaidropdownCompanyWebsite}
          funcValue={aidropdownCompanyWebsite}
        />
        <InputDivs
          heading="Enter registration link"
          placeholderText="Enter URL"
          setFunc={setaidropdownRegLink}
          funcValue={aidropdownRegLink}
        />
      </div>
      <div
        className={classNames.createPlanBtn}
        onClick={() => {
          setSelectedDropDown('');
          listProductCreationFunc();
        }}
        style={{
          pointerEvents: planBAccountPicUploading ? 'none' : '',
          opacity: planBAccountPicUploading ? '0.5' : '',
        }}
      >
        List Your Product
      </div>
    </div>
  );
};

export default CreatePlanB;

export const InputDropdown = ({
  heading,
  setFunc,
  mapValue,
  funcValue,
  dropdown,
  dropdownFunc,
}) => {
  const [customSelected, setCustomSelected] = useState('');
  return (
    <div className={classNames.inputSingleDropdown}>
      <div className={classNames.title}>{heading}</div>
      <div
        className={classNames.select}
        onClick={() => dropdownFunc(!dropdown)}
      >
        <span>{funcValue ? funcValue : 'Select'}</span>
        <img src={selectDownArrow} alt="selectDownArrow" />
      </div>
      {dropdown && (
        <div
          className={`${classNames.select} ${classNames.selectedDropdown}`}
          style={{ top: '2.2rem' }}
        >
          <div
            onClick={() => {
              setFunc('Select');
              dropdownFunc(!dropdown);
            }}
          >
            <span>Select</span>
            <img
              src={selectDownArrow}
              alt="selectDownArrow"
              style={{ rotate: '180deg' }}
            />
          </div>
          {mapValue?.length > 0 &&
            mapValue?.map((eachItem, i) => {
              return (
                <div
                  key={eachItem?.name + i}
                  onClick={() => {
                    dropdownFunc(!dropdown);
                    setFunc(eachItem?.name);
                  }}
                >
                  {eachItem?.name}
                </div>
              );
            })}
          <div className={classNames.customDropdownValue}>
            <input
              type="text"
              placeholder="Add Custom Item.."
              onChange={(event) => setCustomSelected(event?.target?.value)}
            />
            <div onClick={() => setFunc(customSelected)}>+</div>
          </div>
        </div>
      )}
    </div>
  );
};

export const InputDivs = ({
  heading,
  placeholderText,
  setFunc,
  funcValue,
  addCurrency,
}) => {
  const { setSelectedDropDown } = useContext(planBContext);
  return (
    <div
      className={classNames.inputDivs}
      onClick={() => setSelectedDropDown('')}
    >
      <div className={classNames.heading}>{heading}</div>
      <div className={classNames.inputHolder}>
        <input
          className={classNames.inputFields}
          placeholder={placeholderText}
          onChange={(event) => {
            setFunc(event.target.value);
          }}
          value={funcValue ? funcValue : ''}
          style={{ pointerEvents: funcValue ? 'none' : '' }}
        />
        {/* {addCurrency ? (
          <div className={classNames.currencyDiv}>{addCurrency}</div>
        ) : (
          ''
        )} */}
      </div>
    </div>
  );
};

export const DateSelectionDiv = ({ heading }) => {
  const [date, setdate] = useState(futureDates.dates);
  const [month, setMonth] = useState(futureDates.month);
  const [years, setYears] = useState(futureDates.years);

  const [selectedMonth, setselectedMonth] = useState('');
  const {
    selectedDropDown,
    setSelectedDropDown,
    planBAccountTargetDate,
    setplanBAccountTargetDate,
  } = useContext(planBContext);

  function searchDates(text, searchArray) {
    let res;
    if (searchArray?.length == 12) {
      res = searchArray.filter((month) => {
        let ress = month.month.toLowerCase();
        return ress.includes(text.toLowerCase());
      });
      setMonth(res);
    } else if (searchArray?.length == 31) {
      res = searchArray.filter((value) => {
        let ress = value.toLowerCase();
        return ress.includes(text.toLowerCase());
      });
      setdate(res);
    } else {
      res = searchArray.filter((year) => {
        let ress = year.toString();
        return ress.includes(text.toString());
      });
      setYears(res);
    }
  }

  useEffect(() => {
    let selectdomth = futureDates.month.filter((eachmonth) => {
      let ress = eachmonth.month.toLowerCase();
      return ress.includes(selectedMonth.toLowerCase());
    });
    setplanBAccountTargetDate({
      ...planBAccountTargetDate,
      month: selectdomth[0]?.num,
    });
  }, [selectedMonth]);

  return (
    <div className={classNames.dateSelectionDiv}>
      <div
        className={classNames.heading}
        onClick={() => setSelectedDropDown('')}
      >
        {heading}
      </div>
      <div className={classNames.dateSelectorDiv}>
        <div style={{ background: selectedDropDown == 'month' ? 'white' : '' }}>
          <div>
            <span onClick={() => setSelectedDropDown('month')}>
              {selectedDropDown == 'month' ? (
                <input
                  placeholder="Search month..."
                  onChange={(event) =>
                    searchDates(event.target.value, futureDates.month)
                  }
                />
              ) : (
                <span>{selectedMonth ? selectedMonth : 'Month'}</span>
              )}
            </span>
            <img
              src={selectDownArrow}
              alt="selectDownArrow"
              style={{
                rotate: selectedDropDown == 'month' ? '180deg' : '',
                top: selectedDropDown == 'month' ? '30%' : '',
              }}
              onClick={() => {
                if (selectedDropDown == 'month') {
                  setSelectedDropDown('');
                } else {
                  setSelectedDropDown('month');
                }
              }}
            />
          </div>
          <div
            className={classNames.dropDownDiv}
            style={{
              height: selectedDropDown == 'month' ? '' : '0',
              border: selectedDropDown == 'month' ? '' : '0',
              overflowY: selectedDropDown == 'month' ? 'scroll' : 'hidden',
            }}
          >
            {month &&
              month?.map((eachmonth, i) => {
                return (
                  <div
                    onClick={(event) => {
                      setselectedMonth(event.target.innerText);
                      setSelectedDropDown('');
                    }}
                    key={'DateSelectionDiv' + i}
                  >
                    {eachmonth.month}
                  </div>
                );
              })}
          </div>
        </div>
        <div style={{ background: selectedDropDown == 'date' ? 'white' : '' }}>
          <div>
            <span onClick={() => setSelectedDropDown('date')}>
              {selectedDropDown == 'date' ? (
                <input
                  placeholder="Search date..."
                  onChange={(event) =>
                    searchDates(event.target.value, futureDates.dates)
                  }
                />
              ) : (
                <span>
                  {planBAccountTargetDate?.date
                    ? planBAccountTargetDate.date
                    : 'Date'}
                </span>
              )}
            </span>
            <img
              src={selectDownArrow}
              alt="selectDownArrow"
              style={{
                rotate: selectedDropDown == 'date' ? '180deg' : '',
                top: selectedDropDown == 'date' ? '30%' : '',
              }}
              onClick={() => {
                if (selectedDropDown == 'date') {
                  setSelectedDropDown('');
                } else {
                  setSelectedDropDown('date');
                }
              }}
            />
          </div>
          <div
            className={classNames.dropDownDiv}
            style={{
              height: selectedDropDown == 'date' ? '' : '0',
              border: selectedDropDown == 'date' ? '' : '0',
              overflowY: selectedDropDown == 'date' ? 'scroll' : 'hidden',
            }}
          >
            {date &&
              date?.map((eachdate, i) => {
                return (
                  <div
                    onClick={(event) => {
                      setplanBAccountTargetDate({
                        ...planBAccountTargetDate,
                        date: event.target.innerText,
                      });
                      setSelectedDropDown('');
                    }}
                    key={'eachdate' + i}
                  >
                    {eachdate}
                  </div>
                );
              })}
          </div>
        </div>
        <div style={{ background: selectedDropDown == 'year' ? 'white' : '' }}>
          <div>
            <span onClick={() => setSelectedDropDown('year')}>
              {selectedDropDown == 'year' ? (
                <input
                  placeholder="Search year..."
                  onChange={(event) =>
                    searchDates(event.target.value, futureDates.years)
                  }
                />
              ) : (
                <span>
                  {planBAccountTargetDate?.year
                    ? planBAccountTargetDate.year
                    : 'Year'}
                </span>
              )}
            </span>
            <img
              src={selectDownArrow}
              alt="selectDownArrow"
              style={{
                rotate: selectedDropDown == 'year' ? '180deg' : '',
                top: selectedDropDown == 'year' ? '30%' : '',
              }}
              onClick={() => {
                if (selectedDropDown == 'year') {
                  setSelectedDropDown('');
                } else {
                  setSelectedDropDown('year');
                }
              }}
            />
          </div>
          <div
            className={classNames.dropDownDiv}
            style={{
              height: selectedDropDown == 'year' ? '' : '0',
              border: selectedDropDown == 'year' ? '' : '0',
              overflowY: selectedDropDown == 'year' ? 'scroll' : 'hidden',
            }}
          >
            {years &&
              years?.map((eachyear, i) => {
                return (
                  <div
                    onClick={(event) => {
                      setplanBAccountTargetDate({
                        ...planBAccountTargetDate,
                        year: event.target.innerText,
                      });
                      setSelectedDropDown('');
                    }}
                    key={'eachyear' + i}
                  >
                    {eachyear}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export const SelectOneDiv = ({ heading, options }) => {
  const {
    planBAccountContributor,
    setplanBAccountContributor,
    setSelectedDropDown,
  } = useContext(planBContext);

  return (
    <div
      className={classNames.selectOneDiv}
      onClick={() => setSelectedDropDown('')}
    >
      <div className={classNames.heading}>{heading}</div>
      <div className={classNames.selectoptionsDiv}>
        {options &&
          options.map((each, i) => {
            return (
              <div
                onClick={(event) =>
                  setplanBAccountContributor(event.target.innerText)
                }
                style={{
                  background: planBAccountContributor == each ? '#44C2F4' : '',
                  color: planBAccountContributor == each ? 'white' : '',
                }}
                key={'eachoption' + i}
              >
                {each}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export const ImageUploadDiv = ({ heading, setFunc, funcValue }) => {
  const {
    planBAccountPicUploading,
    setplanBAccountPicUploading,
    setSelectedDropDown,
  } = useContext(planBContext);
  return (
    <div
      className={classNames.imageUploadDiv}
      onClick={() => setSelectedDropDown('')}
    >
      <div className={classNames.heading}>{heading}</div>
      <div className={classNames.imageDiv}>
        <img
          src={funcValue ? funcValue : dummyProfile}
          alt="planBAccountPic"
          className={classNames.profileImg}
          htmlFor="profileUpdateImgPlanB"
        />
        {planBAccountPicUploading ? (
          <div className={classNames.overlayDiv}>
            <img
              src={uploading}
              alt="uploading"
              className={classNames.uploadingimg}
            />
          </div>
        ) : (
          ''
        )}
      </div>
      <label
        htmlFor="profileUpdateImgPlanB"
        className={classNames.uploadFileDiv}
      >
        <input
          className={classNames.uploadNewPicPlanB}
          type="file"
          onChange={(e) => {
            uploadImageFunc(e, setFunc, setplanBAccountPicUploading);
          }}
          accept="image/*"
          id="profileUpdateImgPlanB"
        />
        <div>
          {planBAccountPicUploading ? 'Uploading...' : 'Upload Picture'}
        </div>
      </label>
    </div>
  );
};
