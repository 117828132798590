import React from 'react';
import { updateScroll } from '../../../config/constants';
import { useAppContextDetails } from '../../../context/AppContext';
import { useCoinContextData } from '../../../context/CoinContext';
import confirmTransaction from '../../../static/images/transfer-icons/confirmTransaction.svg';

export const TransferActionFooter = ({ DefaultFooter }) => {
  const { appColorCode } = useAppContextDetails();
  const {
    transferAmount,
    setTransferActionStep,
    transferActionStep,
    setCoinAction,
    setTransferAmount,
  } = useCoinContextData();
  if (transferActionStep === 3) {
    return (
      <div className="transfer-footer">
        <div
          className="back1"
          key="back1"
          onClick={() => {
            setTransferActionStep(2);
            updateScroll();
          }}
        >
          <h6>Back</h6>
        </div>
        <div
          className={`d-flex align-items-center ${
            transferAmount ? 'nextbtn' : 'nextbtn1'
          }`}
          key="nextbtn"
          style={{
            backgroundColor: appColorCode,
            opacity: transferAmount ? 1 : 0.25,
            color: transferAmount ? 'white' : '',
            cursor: transferAmount && 'pointer',
            pointerEvents: !transferAmount && 'none',
          }}
          onClick={() => {
            setTransferActionStep(4);
            updateScroll();
          }}
        >
          <h6>Confirm Transaction</h6>
          <img src={confirmTransaction} alt="confirmTransaction" />
        </div>
      </div>
    );
  }
  if (transferActionStep === 4) {
    return (
      <div className="transfer-footer">
        <div
          className="back1 w-100"
          key="back1"
          onClick={() => {
            setCoinAction(['Menu']);
            setTransferActionStep(1);
            setTransferAmount('');
            updateScroll();
          }}
        >
          <h6>Close</h6>
        </div>
      </div>
    );
  }
  return DefaultFooter;
};
