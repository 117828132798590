import React, { useContext, useState, useEffect } from 'react';
import Axios from 'axios';
import Scrollbars from 'react-custom-scrollbars';
import { BankContext } from '../../../../context/Context';
import { FormatCurrency } from '../../../../utils/FunctionTools';
import BtcUsdtChart from '../ChartCard/BtcUsdtChart';
import LoginModal from '../../../LoginModalNew';
import NoChartView from '../ChartCard/NoChartView';
import { useParams } from 'react-router-dom';
import TradeInspector from '../../../TradeInspector/TradeInspector';

function MainContentCard({
  coinSelected,
  setCoinSelected,
  tabSelected,
  toCoin,
  setToCoin,
  exchage,
  setExchage,
}) {
  let { from } = useParams();
  const { coinList, email, coinListObject, defaultPrecission } =
    useContext(BankContext);
  const [coinType, setCoinType] = useState('');
  const [searchStr, setSearchStr] = useState('');
  const [loginModal, setLoginModal] = useState(false);
  const [chartOk, setChartOk] = useState(false);
  useEffect(() => {
    const coinSelect = coinList.filter(
      (coin) => coin.coinSymbol === (from || coinSelected?.coinSymbol)
    );
    if (coinSelect?.length) {
      setTimeout(() => {
        if (!coinSelect.includes(coinSelected))
          setCoinSelected(coinSelect && coinSelect[0]);
      }, 400);
    }
  }, [from, coinList, coinSelected]);
  useEffect(() => {
    const coinSelectTemp = coinList.filter((coin) => coin.type === coinType);
    if (coinSelected && coinSelected.type !== coinType)
      coinSelectTemp && coinSelectTemp[0] && setCoinSelected(coinSelectTemp[0]);
  }, [coinList, coinType]);

  const [toCoinList, setToCoinList] = useState([]);
  const [exchangeList, setExchangeList] = useState([]);
  useEffect(() => {
    if (toCoin && toCoin.coinSymbol && coinSelected && coinSelected.coinSymbol)
      Axios.get(
        `https://comms.globalxchange.io/coin/vault/service/payment/stats/get?paymentMethod=vault&select_type=instant&from_currency=${coinSelected.coinSymbol}&to_currency=${toCoin.coinSymbol}`
      ).then((res) => {
        const { data } = res;
        if (
          data.status &&
          data.pathData &&
          data.pathData.banker &&
          data.pathData.banker[1]
        ) {
          setExchangeList(data.pathData.banker);
          setExchage(data.pathData.banker[1]);
        }
      });
  }, [toCoin, coinSelected]);

  useEffect(() => {
    coinSelected &&
      coinSelected.coinSymbol &&
      Axios.get(
        `https://comms.globalxchange.io/coin/vault/service/payment/stats/get?paymentMethod=vault&select_type=instant&from_currency=${coinSelected.coinSymbol}`
      ).then((res) => {
        const { data } = res;
        setToCoinList(data.pathData.to_currency);
        const toCoinTemp = data.pathData.to_currency.filter(
          (coin) =>
            coin.coin_metadata && coin.coin_metadata.coinSymbol === 'BTC'
        )[0];
        if (!toCoin.coinSymbol)
          setToCoin(
            (toCoinTemp && toCoinTemp.coin_metadata) ||
              (data.pathData.to_currency[0] &&
                data.pathData.to_currency[0].coin_metadata)
          );
      });
  }, [coinSelected]);

  const [exchangeOrCoin, setExchangeOrCoin] = useState(false);

  const [coinSearch, setCoinSearch] = useState('');
  const [bankerSearch, setBankerSearch] = useState('');

  const checkIsExpandValue = (num, coin) => {
    if (coin === 'BTC' || coin === 'ETH') {
      if (num && num < 0.0001) return true;
      else return false;
    } else {
      if (num && num < 0.01) return true;
      else return false;
    }
  };

  const [showTi, setShowTi] = useState(false);

  return (
    <div className="card contentCard card-dark">
      <div className="head">
        {tabSelected === 'Assets' ? (
          <>
            <label
              tabIndex={0}
              className={`tabIn ${exchangeOrCoin ? '' : 'list'} ${
                !exchangeOrCoin || (exchangeOrCoin && exchangeOrCoin === 'coin')
              }`}
              onClick={() => {
                setExchangeOrCoin('coin');
              }}
            >
              {exchangeOrCoin === 'coin' ? (
                <>
                  <input
                    onBlur={() => {
                      setTimeout(() => {
                        setExchangeOrCoin((exchangeOrCoin) =>
                          exchangeOrCoin === 'coin' ? false : 'exchange'
                        );
                      }, 200);
                    }}
                    value={coinSearch}
                    onChange={(e) => setCoinSearch(e.target.value)}
                    type="text"
                    placeholder={`${
                      coinSelected && coinSelected.coinSymbol
                    } Trading Pairs`}
                  />
                  <Scrollbars
                    className="dropDownList"
                    renderThumbHorizontal={() => <div />}
                    renderThumbVertical={() => <div />}
                  >
                    {toCoinList
                      .filter(
                        (coin) =>
                          coin &&
                          coin.coin_metadata &&
                          coinSelected &&
                          (`${coin.coin_metadata.coinSymbol}${coinSelected.coinSymbol}`.includes(
                            coinSearch.toUpperCase()
                          ) ||
                            `${coin.coin_metadata.coinSymbol}/${coinSelected.coinSymbol}`.includes(
                              coinSearch.toUpperCase()
                            ))
                      )
                      .map((coin) => {
                        return (
                          coin &&
                          coin.coin_metadata && (
                            <div
                              className="listItem"
                              onClick={() => setToCoin(coin.coin_metadata)}
                            >
                              <img src={coin.coin_metadata.coinImage} alt="" />
                              {coin.coin_metadata.coinSymbol}/
                              {coinSelected && coinSelected.coinSymbol}
                            </div>
                          )
                        );
                      })}
                  </Scrollbars>
                </>
              ) : (
                `${toCoin && toCoin.coinSymbol}/${
                  coinSelected && coinSelected.coinSymbol
                }`
              )}
            </label>
            <label
              className={`tabIn ${
                !exchangeOrCoin ||
                (exchangeOrCoin && exchangeOrCoin === 'exchange')
              }`}
              onClick={() => {
                setExchangeOrCoin('exchange');
              }}
            >
              {exchangeOrCoin === 'exchange' ? (
                <>
                  <input
                    onBlur={() => {
                      setTimeout(() => {
                        exchangeOrCoin === 'exchange' &&
                          setExchangeOrCoin((exchangeOrCoin) =>
                            exchangeOrCoin === 'exchange' ? false : 'coin'
                          );
                      }, 200);
                    }}
                    value={bankerSearch}
                    onChange={(e) => setBankerSearch(e.target.value)}
                    type="text"
                    placeholder="Search  Exchanges"
                  />
                  <Scrollbars
                    className="dropDownList"
                    renderThumbHorizontal={() => <div />}
                    renderThumbVertical={() => <div />}
                  >
                    {exchangeList
                      .filter((exch) =>
                        exch.displayName
                          .toLowerCase()
                          .includes(bankerSearch.toLowerCase())
                      )
                      .map((exch) => (
                        <div
                          className="listItem"
                          onClick={() => setExchage(exch)}
                        >
                          <img src={exch.icons.image1} alt="" />
                          {exch.displayName}
                        </div>
                      ))}
                  </Scrollbars>
                </>
              ) : (
                <>
                  <img
                    src={exchage && exchage.icons && exchage.icons.image1}
                    alt=""
                  />{' '}
                  {exchage && exchage.displayName}
                </>
              )}
            </label>
            <div className="coinValue">
              <span>
                <img src={toCoin && toCoin.coinImage} alt="" />
                {checkIsExpandValue(
                  toCoin?.coinSymbol &&
                    coinListObject &&
                    coinListObject[toCoin.coinSymbol]?.coinValue,
                  toCoin?.coinSymbol
                ) ? (
                  <span onClick={() => setShowTi(true)} className="expand">
                    Expand
                  </span>
                ) : (
                  <>
                    {FormatCurrency(
                      toCoin?.coinSymbol &&
                        coinListObject &&
                        coinListObject[toCoin.coinSymbol]?.coinValue,
                      toCoin?.coinSymbol,
                      defaultPrecission
                    )}
                    <small>{toCoin && toCoin.coinSymbol}</small>
                  </>
                )}
              </span>
            </div>
            <div className="coinValue">
              <span>
                <img src={coinSelected && coinSelected.coinImage} alt="" />
                {checkIsExpandValue(
                  coinSelected?.coinValue,
                  coinSelected?.coinSymbol
                ) ? (
                  <span onClick={() => setShowTi(true)} className="expand">
                    Expand
                  </span>
                ) : (
                  <>
                    {FormatCurrency(
                      coinSelected?.coinValue,
                      coinSelected?.coinSymbol,
                      defaultPrecission
                    )}
                    <small>{coinSelected && coinSelected.coinSymbol}</small>
                  </>
                )}
              </span>
            </div>
          </>
        ) : (
          <div className="titleText">{tabSelected} Markets</div>
        )}
      </div>
      <div className="content">
        {coinSelected && toCoin && (
          <BtcUsdtChart
            setChartOk={setChartOk}
            toCoin={toCoin.coinSymbol || 'BTC'}
            fromCoin={
              (coinSelected.type === 'crypto' && coinSelected.coinSymbol) ||
              'USDT'
            }
          />
        )}
        {!chartOk &&
        coinSelected &&
        toCoin &&
        (coinSelected.type === 'crypto' || coinSelected.coinSymbol === 'USD') &&
        (toCoin.type === 'crypto' || toCoin.coinSymbol === 'USD') ? (
          ''
        ) : (
          <NoChartView
            toCoin={(toCoin && toCoin.coinSymbol) || 'BTC'}
            fromCoin={(coinSelected && coinSelected.coinSymbol) || 'USDT'}
          />
        )}
      </div>
      <div className="footerControlls">
        <div className="valueNSearch">
          <input
            type="text"
            value={searchStr}
            onChange={(e) => setSearchStr(e.target.value)}
            className="coinSearch"
            placeholder="Select One Of Your Assets To Be The Quote Asset"
          />
          {tabSelected === 'Assets' ? (
            <div className="coinTypeTabs">
              <div
                className={`tabItm ${coinType === ''}`}
                onClick={() => setCoinType('')}
              >
                All
              </div>
              <div
                className={`tabItm ${coinType === 'crypto'}`}
                onClick={() => setCoinType('crypto')}
              >
                Crypto
              </div>
              <div
                className={`tabItm ${coinType === 'fiat'}`}
                onClick={() => setCoinType('fiat')}
              >
                Fiat
              </div>
            </div>
          ) : (
            <div className="coinNameNValue">
              Liquid {coinSelected && coinSelected.coinName}
              {email ? (
                <b>
                  $
                  {FormatCurrency(
                    coinSelected.coinValueUSD,
                    'USD',
                    defaultPrecission
                  )}
                </b>
              ) : (
                <b onClick={() => setLoginModal(true)}>Login To See Balance</b>
              )}
            </div>
          )}
        </div>
        <Scrollbars
          className="coinListWrap"
          renderThumbHorizontal={() => <div />}
          renderThumbVertical={() => <div />}
          renderView={(props) => <div {...props} className="listView" />}
        >
          {coinList
            .filter(
              (coin) =>
                (!coinType || coin.type === coinType) &&
                (coin.coinName
                  .toLowerCase()
                  .includes(searchStr.toLowerCase()) ||
                  coin.coinSymbol
                    .toLowerCase()
                    .includes(searchStr.toLowerCase()))
            )
            .map((coin) => (
              <div
                className={`coinWrap ${
                  coinSelected && coinSelected.coinSymbol === coin.coinSymbol
                }`}
                key={coin.coinSymbol}
              >
                <img
                  src={coin.coinImage}
                  onClick={() => setCoinSelected(coin)}
                />
                <div className="appTooltip">{coin.coinName}</div>
              </div>
            ))}
        </Scrollbars>
      </div>
      {loginModal && (
        <LoginModal
          onSuccess={() => setLoginModal(false)}
          onClose={() => setLoginModal(false)}
        />
      )}
      {showTi && (
        <TradeInspector
          tiObject={{
            sellAmtLabel: `${toCoin?.coinSymbol} Balance`,
            sellAmt:
              toCoin?.coinSymbol &&
              coinListObject &&
              coinListObject[toCoin.coinSymbol]?.coinValue,
            sellCoin: toCoin?.coinSymbol,
            getAmtLabel: `${coinSelected?.coinSymbol} Balance`,
            getAmt: coinSelected?.coinValue,
            getCoin: coinSelected?.coinSymbol,
          }}
          setOpenModal={setShowTi}
        />
      )}
    </div>
  );
}

export default MainContentCard;
