import React, { useState, useEffect, useContext } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import OrderListItem from './OrderListItem';
import { OptionsContext } from '../../ContextAPI/OptionContext';
import { FormatCurrency } from '../../../../utils/FunctionTools';

function OrderBookCard({ toCoin, fromCoin }) {
  const { cryptoTimeRate } = useContext(OptionsContext);
  const [wsOpen, setWsOpen] = useState(false);
  const [unSubscribe, setUnSubscribe] = useState({
    method: 'UNSUBSCRIBE',
    params: [`${toCoin.toLowerCase()}${fromCoin.toLowerCase()}@depth5@1000ms`],
    id: 1,
  });
  const [wsResp, setWsResp] = useState({});
  const [largest, setLargest] = useState(1);

  const [socketUrl, setSocketUrl] = useState(
    'wss://stream.binance.com:9443/ws/btcusdt@depth5@1000ms'
  );
  const { sendMessage, lastMessage } = useWebSocket(socketUrl);

  useEffect(() => {
    const response = JSON.parse(lastMessage && lastMessage.data);
    if (response) setWsResp(response);
  }, [lastMessage]);

  useEffect(() => {
    const subscribe = {
      method: 'SUBSCRIBE',
      params: [
        `${toCoin.toLowerCase()}${fromCoin.toLowerCase()}@depth5@1000ms`,
      ],
      id: 1,
    };
    sendMessage(JSON.stringify(unSubscribe));
    sendMessage(JSON.stringify(subscribe));
    setUnSubscribe({
      method: 'UNSUBSCRIBE',
      params: [
        `${toCoin.toLowerCase()}${fromCoin.toLowerCase()}@depth5@1000ms`,
      ],
      id: 1,
    });
  }, [toCoin, fromCoin]);

  const [bids, setBids] = useState([]);
  const [asks, setAsks] = useState([]);
  useEffect(() => {
    if (wsResp.bids && wsResp.asks) {
      setLargest(1);
      setBids(wsResp.bids);
      setAsks(wsResp.asks);
    }
  }, [wsResp]);

  return (
    <div className="card order-book-card card-dark flex-grow-1 h-100">
      <div className="opt-tab d-flex justify-content-between text-left px-4">
        <div className="tab-itm col-4 pr-0">Price</div>
        <div className="tab-itm col-4 px-0 text-center">Amount</div>
        <div className="tab-itm col-4 pl-0 text-right">USD</div>
      </div>
      <div className="order-book-list d-flex flex-column flex-grow-1">
        {bids
          ? bids.map((bid) => {
              if (bid[0] * bid[1] > largest) {
                setLargest(bid[0] * bid[1]);
              }
              return (
                <OrderListItem
                  key={bid}
                  value={bid}
                  percentage={62}
                  color="#EF5350"
                  largest={largest}
                />
              );
            })
          : ''}
        <h4 className="d-flex justify-content-between m-0 px-4 py-2">
          <span className="col">1 {toCoin}</span>
          <span className="col text-right">
            {cryptoTimeRate
              ? FormatCurrency(cryptoTimeRate.value, fromCoin) + fromCoin
              : ''}
          </span>{' '}
        </h4>
        {asks
          ? asks.map((bid) => {
              if (bid[0] * bid[1] > largest) {
                setLargest(bid[0] * bid[1]);
              }
              return (
                <OrderListItem
                  key={bid}
                  value={bid}
                  percentage={62}
                  color="#70C582"
                  largest={largest}
                />
              );
            })
          : ''}
      </div>
    </div>
  );
}

export default OrderBookCard;
