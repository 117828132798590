import React, { useState, useEffect, useContext } from 'react';
import { useAppContextDetails } from '../../../context/AppContext';
import QRCode from 'qrcode.react';
import axios from 'axios';

import pasteIcon from '../../../static/images/paste.svg';
import { BankContext } from '../../../context/Context';
import OtpInput from 'react-otp-input';

const AddForexStepThree = ({
  addressInput,
  fromCurrency,
  country,
  setPaymentMethod,
}) => {
  const { selectedCurrency, appCode, setBuySellStep, setActionFlow } =
    useAppContextDetails();
  const { email, token, profileId } = useContext(BankContext);
  const [allCountry, setAllCountry] = useState([]);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [selectedCoin, setSelectedCoin] = useState(null);
  const [loading, setLoading] = useState(false);
  const [allPaymentMethods, setAllPaymentMethods] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [query, setQuery] = useState('');

  // useEffect(() => {
  //   const foundData = allPaymentMethods.find(
  //     (o) => o.metadata.name === selectedPaymentMethod?.metadata?.name
  //   );
  //   if (foundData) {
  //     navigate('AddForexStepFour', {
  //       fromCurrency: fromCurrency,
  //       country: country,
  //       paymentMethod: foundData,
  //     });
  //   }
  // }, [selectedPaymentMethod]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `https://comms.globalxchange.io/coin/vault/service/payment/stats/get`,
        {
          params: {
            select_type: 'fund',
            to_currency: selectedCurrency?.coinSymbol,
            from_currency: fromCurrency?.coin_metadata?.coinSymbol,
            country: country?.metadata?.name,
          },
        }
      )
      .then(({ data }) => {
        setAllPaymentMethods(data.pathData.paymentMethod);
        setLoading(false);
      });
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: window.innerHeight - 100,
      }}
    >
      <div>
        <div className="breadcrumb">
          <span onClick={(e) => setActionFlow(null)}>Country</span>
          &nbsp;&nbsp;{`>`}&nbsp;&nbsp;
          <span style={{ fontWeight: 700 }}>Payment Method</span>
        </div>
        <div style={{ padding: '0px 22px' }}>
          <div style={{ paddingTop: '40px' }}>
            {/* <div
                style={{
                  fontWeight: 'bold',
                  fontSize: '22px',
                  paddingBottom: '31px',
                }}
              >
                Which Currency Are You Sending ?
              </div> */}
            <div>How Are You Sending The Funds ?</div>
          </div>
        </div>
      </div>
      <div className="midSectionStyle" style={{ height: '60vh' }}>
        <input
          className="addressInput"
          onFocus={(e) => setIsSearchOpen(true)}
          placeholder="Search Payment Method..."
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        <div
          style={{
            overflowY: 'scroll',
            height: '60vh',
            paddingBottom: '20px',
            marginTop: '1px solid #e7e7e7',
          }}
        >
          {!loading ? (
            allPaymentMethods?.length > 0 ? (
              allPaymentMethods
                .filter((o) =>
                  o?.metadata?.name.toLowerCase().includes(query.toLowerCase())
                )
                .map((item, index) => {
                  return (
                    <div
                      onClick={(e) => {
                        setPaymentMethod(item);
                        setActionFlow('AddForexStepFour');
                      }}
                      className="currencyWrapper"
                      // style={{ borderTopWidth: index === 0 ? 0 : 1 }}
                    >
                      <img
                        className="currencyIcon"
                        src={item?.metadata?.icon}
                      />
                      <div className="currencyName">{item?.metadata?.name}</div>
                    </div>
                  );
                })
            ) : (
              <div>
                <div>No Data</div>
              </div>
            )
          ) : (
            Array(3)
              .fill(' ')
              .map((item) => {
                return (
                  <div className="currencyWrapper row">
                    <div className="coinContainer">
                      {/* <SkeltonItem
                      itemHeight={25}
                      itemWidth={25}
                      style={styles.coinImage}
                    />
                    <SkeltonItem itemHeight={20} itemWidth={80} /> */}
                      loading...
                    </div>
                  </div>
                );
              })
          )}
        </div>
      </div>
      <div style={{ padding: '0px 22px' }}>
        <div className="doubleButton">
          <div
            className="submitButton"
            style={{ background: '#464B4E', width: '100%' }}
            onClick={(e) => setActionFlow('AddForexStepTwo')}
          >
            <div style={{ color: 'white', fontWeight: 'bold' }}>Back</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddForexStepThree;
