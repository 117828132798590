import React from 'react';
import { useCoinContextData } from '../../../context/CoinContext';

export const WithdrawForexSuccess = () => {
  const { selectedCoin, updatedForexBalance } = useCoinContextData();

  return (
    <div
      style={{
        width: '90%',
      }}
    >
      <div>
        <p style={{ fontWeight: '700', fontSize: '14px', lineHeight: '17px' }}>
          {`You Have Successfully Initiated An External Withdrawal Of  ${selectedCoin.coinSymbol}`}
        </p>
      </div>
      <div>
        <p className="balance">{`Previous ${selectedCoin.coinSymbol} Balance`}</p>
      </div>
      <div className="withdraw-success-box">
        <div className="div-success-input">
          <input
            className="from-input"
            placeholder="0.00"
            key="from-2"
            id="from-2"
            type="text"
            value={updatedForexBalance?.userDebit?.current_balance}
            disabled
          />
          <div className="post-withdraw-from-coin" key="from-coin">
            <img className="coinimgsmall" src={selectedCoin.coinImage} alt="" />
            <p className="coinsymbolsmall">{selectedCoin.coinSymbol}</p>
          </div>
        </div>
      </div>
      <div>
        <p className="balance">{`New ${selectedCoin.coinSymbol} Balance`}</p>
      </div>
      <div className="withdraw-success-box">
        <div className="div-success-input">
          <input
            className="from-input"
            placeholder="0.00"
            key="from-1"
            id="from-1"
            type="text"
            value={updatedForexBalance?.userDebit?.updated_balance}
            disabled
          />
          <div className="post-withdraw-from-coin" key="from-coin">
            <img className="coinimgsmall" src={selectedCoin.coinImage} alt="" />
            <p className="coinsymbolsmall">{selectedCoin.coinSymbol}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
