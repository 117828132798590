import React, { useContext, useState } from 'react';
import { useCoinContextData } from '../../context/CoinContext';
import { useAppContextDetails } from '../../context/AppContext';
import { BankContext } from '../../context/Context';
import '../../pages/SharesPage/shares.scss';
import { dividendVaultBalances } from '../../pages/SharesPage/api';

const SharetokenAction = ({ sidebarCollapse }) => {
  const {
    cardSelected,
    setCardSelected,
    tokenSymbol,
    setTokenSymbol,
    selectedAppCode,
    setSelectedAppCode,
    dividendBalance,
    setshareTokenTabName,
    setDividendBalance,
    setDisplayData,
    dividendLoading,
    setDividendLoading,
    setMapData,
    filteredMapData,
    setFilteredMapData,
  } = useAppContextDetails();

  const { email } = useContext(BankContext);

  const getDividendBalance = () => {
    setDividendLoading(true);
    let appcode = selectedAppCode;
    dividendVaultBalances(email, appcode).then((response) => {
      let result = response?.data?.apps?.[0]?.appVaults;
      // console.log(result);
      setDividendBalance(result);
      setMapData([
        {
          name: 'Exchange Fees',
          value: result?.[0],
          selectedVault: result?.[0]?.vaultName,
        },
        {
          name: 'Deposit Fees',
          value: result?.[1],
          selectedVault: result?.[1]?.vaultName,
        },
        {
          name: 'Withdrawal Fees',
          value: result?.[2],
          selectedVault: result?.[2]?.vaultName,
        },
        {
          name: 'Staking Spread',
          value: result?.[8],
          selectedVault: result?.[8]?.vaultName,
        },
        {
          name: 'MoneyMarket IRD',
          value: result?.[6],
          selectedVault: result?.[6]?.vaultName,
        },
        {
          name: 'Staking IRD',
          value: result?.[5],
          selectedVault: result?.[5]?.vaultName,
        },
        {
          name: 'Products',
          value: result?.[3],
          selectedVault: result?.[3]?.vaultName,
        },
        {
          name: 'Token Sales',
          value: result?.[4],
          selectedVault: result?.[4]?.vaultName,
        },
        {
          name: 'BondCoins',
          value: result?.[7],
          selectedVault: result?.[7]?.vaultName,
        },
        {
          name: 'Terminals',
          value: result?.[9],
          selectedVault: result?.[9]?.vaultName,
        },
      ]);
      setFilteredMapData([
        {
          name: 'Exchange Fees',
          value: result?.[0],
          selectedVault: result?.[0]?.vaultName,
        },
        {
          name: 'Deposit Fees',
          value: result?.[1],
          selectedVault: result?.[1]?.vaultName,
        },
        {
          name: 'Withdrawal Fees',
          value: result?.[2],
          selectedVault: result?.[2]?.vaultName,
        },
        {
          name: 'Staking Spread',
          value: result?.[8],
          selectedVault: result?.[8]?.vaultName,
        },
        {
          name: 'MoneyMarket IRD',
          value: result?.[6],
          selectedVault: result?.[6]?.vaultName,
        },
        {
          name: 'Staking IRD',
          value: result?.[5],
          selectedVault: result?.[5]?.vaultName,
        },
        {
          name: 'Products',
          value: result?.[3],
          selectedVault: result?.[3]?.vaultName,
        },
        {
          name: 'Token Sales',
          value: result?.[4],
          selectedVault: result?.[4]?.vaultName,
        },
        {
          name: 'BondCoins',
          value: result?.[7],
          selectedVault: result?.[7]?.vaultName,
        },
        {
          name: 'Terminals',
          value: result?.[9],
          selectedVault: result?.[9]?.vaultName,
        },
      ]);
      setDividendLoading(false);
    });
  };

  return (
    <div className="shareAction">
      <div className="actionHeading">{tokenSymbol} Actions</div>
      <div className="actionList">
        <div className="eachAction"
                style={{ cursor: 'pointer', opacity: '1' }}
                onClick={()=>{setshareTokenTabName("transcation");
                setCardSelected(false)
              }}
        >
          <div style={{ opacity: '1' }}>Check Transactions</div>
          <div style={{ opacity: '1' }}>See the transactions in {tokenSymbol} Vault</div>
        </div>
        <div
          className="eachAction"
          style={{ cursor: 'pointer', opacity: '1' }}
          onClick={() => {
            getDividendBalance();
            setDisplayData('vaultData');
            setCardSelected(false);
          }}
        >
          <div style={{ opacity: '1' }}>Check Dividends</div>
          <div style={{ opacity: '1' }}>
            Analyze the dividends from {tokenSymbol}
          </div>
        </div>
        {/* <div className="eachAction">
          <div>Trade</div>
          <div>Buy or sell {tokenSymbol}</div>
        </div> */}
      </div>
      <div className="actionCloseBtn">
        <div
          onClick={() => {
            setCardSelected(false);
            setTokenSymbol('');
            setSelectedAppCode('');
          }}
        >
          Close
        </div>
      </div>
    </div>
  );
};

export default SharetokenAction;
