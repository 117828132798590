import React from 'react';
import { useHistory } from 'react-router-dom';
import MainNavbar from '../../components/MainNavbar';
import { ASSET_CLASSES } from '../../config/constants';
import { GetSortOrder } from '../../utils/FunctionTools';
import bg from '../../static/images/mobileSlider/bg.jpeg';
import indianOtc from '../../static/images/mobileSlider/indianOtc.svg';
import classNames from './investorPage.module.scss';
import { useAppContextDetails } from '../../context/AppContext';

function InvestorPage() {
  const history = useHistory();
  const { appCurrencySymbol } = useAppContextDetails();

  return (
    <div className={classNames.investorPage}>
      <MainNavbar className={classNames.navbar} />
      <div className={classNames.explore}>
        <div className={classNames.subTitle}>For Investors</div>
        <div className={classNames.title}>Explore Indian Markets</div>
        <div className={classNames.cardListWrap}>
          <div className={classNames.cardList}>
            {ASSET_CLASSES.sort(GetSortOrder('marketOrder')).map(
              (assetClassCard, i) => (
                <div
                  key={i}
                  className={classNames.card}
                  onClick={() => {
                    if (assetClassCard.marketEnable) {
                      history.push(`/market/${assetClassCard.name}`);
                    }
                  }}
                >
                  <img
                    src={assetClassCard.icon}
                    alt=""
                    className={classNames.icon}
                  />
                </div>
              )
            )}
          </div>
        </div>
      </div>
      <div className={classNames.trendingAssets}>
        <div className={classNames.title}>Trending Assets</div>
        <div className={classNames.cardListWrap}>
          <div className={classNames.cardList}>
            {Array(4)
              .fill('')
              .map((_, i) => (
                <div className={`${classNames.carouselCard}`} key={i}>
                  <div className={classNames.content}>
                    <img src={indianOtc} alt="" className={classNames.logo} />
                    <div className={classNames.cardSubTitle}>
                      India’s Future Crypto Exchange
                    </div>
                    <div className={classNames.rate}>
                      {appCurrencySymbol}&nbsp;35.00
                      <small>Per Share</small>
                    </div>
                    <div className={classNames.btnsCard}>
                      <div className={classNames.btnHyd}>HYD</div>
                      <div className={classNames.btnCrypto}>Crypto</div>
                    </div>
                  </div>
                  <img src={bg} alt="" className={classNames.bg} />
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default InvestorPage;
