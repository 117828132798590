import React, { useState } from 'react';
import MainLayout from '../../Components/Layouts/MainLayout';
import OrderBookCard from '../../Components/OrderBookCard/OrderBookCard';
import MainContentCard from '../../Components/MainContentCard/MainContentCard';
import SellBuyCard from '../../Components/SellBuyCard/SellBuyCard';

function Home({ tabSelected }) {
  const [coinSelected, setCoinSelected] = useState({
    coinName: 'US Dollar',
    coinSymbol: 'USD',
  });
  const [toCoin, setToCoin] = useState({});
  const [exchage, setExchage] = useState('Global X Change');

  return (
    <>
      <MainLayout className={`home-page`}>
        <div className="one">
          <MainContentCard
            tabSelected={tabSelected}
            coinSelected={coinSelected}
            toCoin={toCoin}
            setToCoin={setToCoin}
            setCoinSelected={setCoinSelected}
            exchage={exchage}
            setExchage={setExchage}
          />
        </div>
        <div className="two">
          <div className="orderBookWrap">
            <OrderBookCard
              toCoin={(toCoin && toCoin.coinSymbol) || 'BTC'}
              fromCoin={
                (coinSelected &&
                  coinSelected.type === 'crypto' &&
                  coinSelected.coinSymbol) ||
                'USDT'
              }
            />
          </div>
          <div className="tradeCardWrap">
            <SellBuyCard
              toCoin={toCoin}
              coinSelected={coinSelected}
              exchage={exchage.displayName || exchage}
            />
          </div>
        </div>
      </MainLayout>
    </>
  );
}

export default Home;
