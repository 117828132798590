import React from 'react';
import TwoFAInput from '../LoginModal/TwoFAInput';
import assetsLogo from '../../static/images/logoIcons/assetsIoFullLogo.svg';

function TwoFaModel({ email, password, setPassword, onLogin }) {
  return (
    <div className="login-wrapper">
      <div className="bg-close" />
      <div className="login-modal">
        <div className="login-signup">
          <div className="login-wrap">
            <div className="head">
              <img src={assetsLogo} alt />
            </div>
            <TwoFAInput
              email={email}
              password={password}
              setPassword={setPassword}
              onLogin={onLogin}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TwoFaModel;
