import React, { useState, useEffect, useContext } from 'react';
import { useAppContextDetails } from '../../../context/AppContext';
import QRCode from 'qrcode.react';
import axios from 'axios';

import pasteIcon from '../../../static/images/paste.svg';
import { BankContext } from '../../../context/Context';
import OtpInput from 'react-otp-input';

const AddForexStepSix = ({
  addressInput,
  fromCurrency,
  country,
  setPaymentMethod,
  paymentMethod,
  fromAmount,
  setFromAmount,
  confirmQuote,
  setUpdatedBalance,
  pathId,
}) => {
  const { selectedCurrency, appCode, setBuySellStep, setActionFlow } =
    useAppContextDetails();
  const { email, token, profileId } = useContext(BankContext);
  const [loading, setLoading] = useState(false);
  const [allBankers, setAllBankers] = useState([]);
  const [selectedBanker, setSlectedBanker] = useState(null);
  const [addErr, setAddErr] = useState(null);

  // useEffect(() => {
  //   const foundData = allBankers.find(
  //     (o) => o.displayName === selectedBanker?.displayName
  //   );
  //   if (foundData) {
  //     navigate('AddForexStepFive', {
  //       fromCurrency: fromCurrency,
  //       country: country,
  //       paymentMethod: paymentMethod,
  //       banker: foundData,
  //     });
  //   }
  // }, [selectedBanker]);

  const AddFiatFunction = async () => {
    setLoading(true);

    axios
      .post(`https://comms.globalxchange.io/coin/vault/service/trade/execute`, {
        token: token,
        email: email,
        app_code: appCode,
        profile_id: profileId,
        coin_purchased: selectedCurrency?.coinSymbol,
        purchased_from: fromCurrency.coin_metadata.coinSymbol,
        from_amount: fromAmount,
        stats: false,
        identifier: `Add ${fromAmount} ${fromCurrency.coin_metadata.coinSymbol} Via ${paymentMethod._id}`,
        path_id: pathId,
      })
      .then(({ data }) => {
        if (data.status) {
          axios
            .post(
              `https://comms.globalxchange.io/coin/vault/service/coins/get`,
              {
                app_code: appCode,
                email: email,
                displayCurrency: selectedCurrency?.coinSymbol,
                include_coins: [selectedCurrency?.coinSymbol],
              }
            )
            .then(({ data }) => {
              console.log(data, 'lkdlwkenfkwjefbkwef');
              if (data.status) {
                setUpdatedBalance(data.coins_data[0]);
                setActionFlow('AddForexStepSix');
                setLoading(false);
              } else {
                setAddErr(data.message);
              }
            });
        }
      });
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `https://comms.globalxchange.io/coin/vault/service/payment/stats/get`,
        {
          params: {
            select_type: 'fund',
            to_currency: selectedCurrency.coinSymbol,
            from_currency: fromCurrency.coin_metadata.coinSymbol,
            country: country.metadata.name,
            paymentMethod: paymentMethod._id,
          },
        }
      )
      .then(({ data }) => {
        setAllBankers(data.pathData.banker);
        setLoading(false);
      });
  }, []);
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: window.innerHeight - 100,
      }}
    >
      <div>
        <div className="breadcrumb">
          <span onClick={(e) => setActionFlow(null)}>Amount</span>
          &nbsp;&nbsp;{`>`}&nbsp;&nbsp;
          <span style={{ fontWeight: 700 }}>Confirm Quote</span>
        </div>
      </div>
      <div style={{ height: '70vh', padding: '0px 22px' }}>
        <div>
          <div className="cardlabel">Sending Via {paymentMethod?._id}</div>
          <div className="inputStyle">
            <div style={{ width: '60%' }}>
              <input
                disabled
                type="number"
                value={confirmQuote?.finalFromAmount}
              />
            </div>
            <div style={{ width: '40%' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={fromCurrency?.coin_metadata?.coinImage}
                  alt=""
                  style={{ width: '20px', height: '20px' }}
                />
                <span
                  className="inputButton"
                  style={{ padding: '0px 10px', border: 'none' }}
                >
                  {fromCurrency?.coin_metadata?.coinSymbol}
                </span>
                <span>{`>`}</span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="cardlabel">
            Credited To {selectedCurrency?.coinSymbol} Liquid Vault
          </div>
          <div className="inputStyle">
            <div style={{ width: '60%' }}>
              <input
                disabled
                type="number"
                value={confirmQuote?.finalToAmount}
              />
            </div>
            <div style={{ width: '40%' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={selectedCurrency?.coinImage}
                  alt=""
                  style={{ width: '20px', height: '20px' }}
                />
                <span
                  className="inputButton"
                  style={{ padding: '0px 10px', border: 'none' }}
                >
                  {selectedCurrency?.coinSymbol}
                </span>
                <span>{`>`}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {addErr ? (
        <div>
          <div style={{ fontWeight: 'bold', color: 'red' }}>
            Error Occoured. Restart the Transaction
          </div>
          <div
            className="submitButton"
            style={{ background: '#464B4E' }}
            onClick={(e) => setActionFlow('')}
          >
            <div style={{ color: 'white', fontWeight: 'bold' }}>Go Back</div>
          </div>
        </div>
      ) : null}
      {/* Buttons */}
      <div style={{ padding: '0px 22px' }}>
        <div className="doubleButton">
          <div
            className="submitButton"
            style={{ background: 'white' }}
            onClick={(e) => setActionFlow('AddForexStepFour')}
          >
            <div style={{ fontWeight: 'bold' }}>Back</div>
          </div>
          <div
            className="submitButton"
            style={{ background: '#464B4E' }}
            onClick={AddFiatFunction}
          >
            <div style={{ color: 'white', fontWeight: 'bold' }}>
              {loading ? 'Loading...' : 'Next'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddForexStepSix;
