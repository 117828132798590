import React, { useEffect, useRef } from 'react';
import paste from '../../static/images/clipIcons/paste.svg';
function GetStartedFindBroker({
  setStepName,
  logo,
  isBrokerEmail,
  brokerEmailOrId,
  setBrokerEmailOrId,
  setIsBrokerEmail,
  onSubmit,
}) {
  const ref = useRef();
  useEffect(() => {
    setTimeout(() => {
      ref && ref.current.focus();
    }, 200);
  }, []);
  return (
    <div className="stepWrapper stepForm">
      <img src={logo} alt="assetLogo" className="assetLogo" />
      <div className="stepDesc">
        {isBrokerEmail
          ? 'Please Enter Your Brokers Email To Initiate Registration'
          : 'Please Enter Your NetworkSync™ Code To Initiate Registration'}
      </div>
      <div className="group">
        <input
          ref={ref}
          type="text"
          className="input email"
          placeholder={isBrokerEmail ? 'broker@gmail.com' : 'Ex. broker123'}
          value={brokerEmailOrId}
          onChange={(e) => setBrokerEmailOrId(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              try {
                onSubmit();
              } catch (error) {}
            }
          }}
        />
        <img
          src={paste}
          alt=""
          className="icon"
          onClick={() => {
            navigator.clipboard
              .readText()
              .then((clipText) => setBrokerEmailOrId(clipText));
          }}
        />
      </div>
      <div className="btns">
        <div className="btnBig" onClick={() => setStepName('')}>
          Go Back
        </div>
        <div
          className="btnNext w60"
          onClick={() => {
            try {
              onSubmit();
            } catch (error) {}
          }}
        >
          Proceed
        </div>
      </div>
      <div className="btnLink" onClick={() => setIsBrokerEmail(!isBrokerEmail)}>
        {isBrokerEmail
          ? 'User NetworkSync™ Instead'
          : 'User Affiliate’s Email Address Instead'}
      </div>
    </div>
  );
}

export default GetStartedFindBroker;
