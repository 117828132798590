import React, { useContext } from 'react';
import classNames from './seperatecomponents.module.scss';
import Skeleton from 'react-loading-skeleton';

//images
import vaultFullLogo from '../../static/images/vaults/vaultFullLogo.svg';
import walletIcon from '../../static/images/vaults/wallet.svg';
import { RiArrowDropDownLine } from 'react-icons/ri';
import { switchItems } from './static/mapdata';
import { BsThreeDotsVertical } from 'react-icons/bs';

import dummyIcon from './static/dummyIcon.svg';
import { useEffect } from 'react';
import axios from 'axios';
import { BankContext } from '../../context/Context';
import { useAppContextDetails } from '../../context/AppContext';
import { VaultContext } from '../../context/VaultContext';
import { useState } from 'react';
import Transaction from './ValutPage';
import './vault.scss';

export const TopNavbar = () => {
  const { email } = useContext(BankContext);
  const { appColorCode, cardSelected, setshareTokenTabName, setCardSelected } =
    useAppContextDetails();
  const [coinValue, setCoinValue] = useState('');
  const [sharetokenBalance, setsharetokenBalance] = useState([]);
  const [apiDataLoadingCoinValue, setApiDataLoadingCoinValue] = useState(false);

  useEffect(() => {
    getbalance();
  }, []);

  function getbalance() {
    setApiDataLoadingCoinValue(true);

    let obj = {
      app_code: 'indianinvestor',
      email: email,
      include_coins: ['INR'],
    };

    axios
      .post('https://comms.globalxchange.io/coin/vault/service/coins/get', obj)
      .then((response) => {
        if (response?.data?.coins_data?.length > 0) {
          //   console.log(response?.data, 'response');
          setCoinValue(response?.data?.coins_data[0]?.coinValue);
          setApiDataLoadingCoinValue(false);
        }
      })
      .catch((error) => {
        setApiDataLoadingCoinValue(false);
      });
  }

  return (
    <div className={classNames.topNavbar}>
      {/* <div className={classNames.hamburger}></div> */}
      <div className={classNames.vaultsIcon}>
        <img src={vaultFullLogo} alt="vaultFullLogo" />
      </div>
      <div className={classNames.searchVaults}>
        {/* <div className={classNames.dropDown}>
          <div>All Holdings</div>
          <div>
            <RiArrowDropDownLine />
          </div>
        </div> */}
        <div className={classNames.searchDiv}>
          <input type="text" placeholder="Im Looking For.." />
        </div>
      </div>
      <div className={classNames.btnDiv} style={{ background: appColorCode }}>
        Fund
      </div>
      {/* <div className={classNames.balance}>
        <div>
          <img src={walletIcon} alt="walletIcon" />
        </div>
        <div>
          <div>INR Balance</div>
          <div className={classNames.amount}>
            {apiDataLoadingCoinValue ? (
              <Skeleton width={100} height={15} />
            ) : coinValue ? (
              '₹' + coinValue
            ) : (
              '₹0.00'
            )}
          </div>
        </div>
      </div>
      <div className={classNames.balanceActions}>
        <div className={classNames.addMore}>+ Add More</div>
        <div className={classNames.withdraw}>- Withdraw</div>
      </div> */}
    </div>
  );
};

export const SwithNavbar = () => {
  return (
    <div className={classNames.switchNavbar}>
      {switchItems.map((eachItem, i) => {
        return <SwitchItems {...eachItem} index={i} />;
      })}
    </div>
  );
};

const SwitchItems = ({ name, icon, index }) => {
  return (
    <div
      className={`${classNames.switchItems} ${
        index == 0 ? classNames.switchItemsSelected : ''
      }`}
      // style={{ borderBottom: index == 0 ? '3px solid  #5F6163' : '' }}
    >
      {/* <img src={icon} alt={name} /> */}
      <div>{name}</div>
      <div
        className={classNames.hoverDiv}
        style={{ display: index == 0 ? 'none' : '' }}
      ></div>
    </div>
  );
};

export const VaultDetailedSection = ({ searchTerm, setSearchTerm }) => {
  const { email } = useContext(BankContext);

  // const { setVaultTxns } = useContext(VaultContext);
  const {
    cardSelected,
    setCardSelected,
    tokenSymbol,
    setTokenSymbol,
    setshareTokenTabName,
    shareTokenSelected,
    shareTokenTabName,
    selectedAppCode,
  } = useAppContextDetails();
  const [shareTokenTranscation, setshareTokenTranscation] = useState([]);
  const [SLoading, setSLoading] = useState(false);
  const [apiDataLoadingShareValue, setApiDataLoadingShareValue] =
    useState(false);
  const [sharetokenBalance, setsharetokenBalance] = useState([]);
  const [privateEquityHoldingValue, setprivateEquityHoldingValue] =
    useState('');
  const [
    privateEquityHoldingValueLoading,
    setprivateEquityHoldingValueLoading,
  ] = useState(false);

  useEffect(() => {
    shareTokenBalance();
    shareTokenNewWorth();
    // getLink();
  }, []);
  useEffect(() => {
    // shareTokenBalance();
    // shareTokenNewWorth();
    if (
      shareTokenSelected?.token == undefined &&
      selectedAppCode == undefined
    ) {
      return;
    } else {
      getLink();
    }
  }, [shareTokenSelected?.token, selectedAppCode]);

  function shareTokenBalance() {
    setApiDataLoadingShareValue(true);
    axios
      .get(
        `https://comms.globalxchange.io/coin/investment/path/user/vault/balances/get?email=${email}&investmentType=EQT`
      )
      .then((response) => {
        // console.log(response?.data, 'response sharetoken');
        setsharetokenBalance(response?.data?.vaultsData);
        setApiDataLoadingShareValue(false);
      })
      .catch((error) => {
        setApiDataLoadingShareValue(false);
      });
  }

  function shareTokenNewWorth() {
    // console.log('working');
    setprivateEquityHoldingValueLoading(true);
    axios
      .get(
        `https://comms.globalxchange.io/coin/vault/service/user/total/funds/data/get?email=${email}&displayCurrency=USD`
      )
      .then((response) => {
        if (response?.data?.investmentTokensData?.pathTypes?.length > 0) {
          //   console.log(
          //     response?.data?.investmentTokensData?.pathTypes,
          //     'net worth'
          //   );
          let item = response?.data?.investmentTokensData?.pathTypes.filter(
            (eachItem) => {
              return eachItem.investmentType == 'EQT';
            }
          );
          //   console.log(item, 'item');
          if (item?.length > 0) {
            setprivateEquityHoldingValue(
              item[0]?.investmentType_data?.stats?.type_totalHoldings_dc
            );
            // console.log(
            //   item[0]?.investmentType_data?.stats?.type_totalHoldings_dc,
            //   'type data'
            // );
          }
        }
        // console.log(response, 'net worth full');

        setprivateEquityHoldingValueLoading(false);
      })
      .catch((error) => {
        console.log(error, 'networth error');

        setprivateEquityHoldingValueLoading(false);
      });
  }

  // useEffect(() => {

  // }, []);

  const getLink = () => {
    setSLoading(true);

    // console.log('1323424234e', selectedAppCode);
    setshareTokenTranscation([]);
    axios
      .post('https://comms.globalxchange.io/coin/vault/service/txns/get', {
        app_code: selectedAppCode,
        email: email,
        coin: shareTokenSelected?.token,
      })
      .then(({ data }) => {
        // console.log('5316238173123', data?.txns);
        if (data.status) {
          setshareTokenTranscation(data?.txns);
          setSLoading(false);
        } else {
          // tostShowOn(data.message || 'Something Went Wrong');
        }
      });
  };

  const passvaulterFiler = () => {
    setSLoading(true);

    axios
      .post('https://comms.globalxchange.io/coin/vault/service/txns/get', {
        app_code: selectedAppCode,
        email: email,
        coin: shareTokenSelected?.token,
        deposit: true,
      })
      .then(({ data }) => {
        console.log('cmzxncnx', data?.txns);
        if (data.status) {
          setshareTokenTranscation(data?.txns);
          setSLoading(false);
        } else {
          // tostShowOn(data.message || 'Something Went Wrong');
        }
      });
  };

  const passvaulterFilerWithdraw = () => {
    setSLoading(true);

    axios
      .post('https://comms.globalxchange.io/coin/vault/service/txns/get', {
        app_code: selectedAppCode,
        email: email,
        coin: shareTokenSelected?.token,
        withdraw: true,
      })
      .then(({ data }) => {
        console.log('cmzxncnx', data?.txns);
        if (data.status) {
          setshareTokenTranscation(data?.txns);
          setSLoading(false);
        } else {
          // tostShowOn(data.message || 'Something Went Wrong');
        }
      });
  };

  const passTypeTransaction = (e) => {
    setSLoading(true);

    axios
      .post('https://comms.globalxchange.io/coin/vault/service/txns/get', {
        app_code: selectedAppCode,
        email: email,
        coin: shareTokenSelected?.token,
        type: e,
      })
      .then(({ data }) => {
        console.log('cmzxncnx', data?.txns);
        if (data.status) {
          setshareTokenTranscation(data?.txns);
          setSLoading(false);
        } else {
          // tostShowOn(data.message || 'Something Went Wrong');
        }
      });
  };

  const tableSwtich = () => {
    switch (shareTokenTabName) {
      case 'transcation':
        return (
          <>
            <div
              className="paddingtranscation"
              style={{
                padding: '2rem 3rem',
              }}
            >
              <Transaction
                shareTokenTranscation={shareTokenTranscation}
                passvaulterFiler={passvaulterFiler}
                passvaulterFilerWithdraw={passvaulterFilerWithdraw}
                getLink={getLink}
                SLoading={SLoading}
                passTypeTransaction={passTypeTransaction}
              />
            </div>
          </>
        );
      case 'default':
        return (
          <>
            <div
              className={classNames.vaultDetailedSection}
              style={{ height: '100%', background: '#ffffff' }}
            >
              <div
                className={classNames.holdingValue}
                style={{
                  padding: '2rem 3rem',
                  paddingBottom: '0rem',
                }}
              >
                <div style={{ fontWeight: '600' }}>
                  {privateEquityHoldingValueLoading ? (
                    <Skeleton width={150} height={15} />
                  ) : privateEquityHoldingValue ? (
                    privateEquityHoldingValue.toFixed(2)
                  ) : (
                    '₹0'
                  )}
                </div>
                <div>Private Equity Holdings Valuation</div>
              </div>
              <div className={classNames.contentDiv}>
                <div className={classNames.tokenSwitch}>
                  <div>ShareTokens</div>
                  <div style={{ opacity: '0.5' }}>SafeTokens</div>
                  <div style={{ opacity: '0.5' }}>CSOPTokens</div>
                  <div style={{ opacity: '0.5' }}>ESOPTokens</div>
                </div>
                <div className={classNames.cardsContainer}>
                  {apiDataLoadingShareValue ? (
                    <>
                      <SingleCardLoading />
                      <SingleCardLoading />
                      <SingleCardLoading />
                      <SingleCardLoading />
                      <SingleCardLoading />
                      <SingleCardLoading />
                      <SingleCardLoading />
                      <SingleCardLoading />
                    </>
                  ) : sharetokenBalance?.length > 0 ? (
                    sharetokenBalance
                      ?.filter((eachitem) => {
                        return eachitem?.tokens[0]?.token_profile_data?.coinName
                          ?.toLowerCase()
                          ?.includes(searchTerm);
                      })
                      ?.map((eachitem) => {
                        return <SingleCard eachitem={eachitem} />;
                      })
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </>
        );

        break;

      default:
        break;
    }
  };
  return (
    <>
      <div
        className="paddingtranscatioxn"
        // style={{
        //   padding: '2rem 3rem',
        // }}
      >
        {tableSwtich()}
      </div>
    </>
  );
};

const SingleCard = ({ eachitem }) => {
  //   console.log(eachitem, 'eactitem');
  const {
    cardSelected,
    setCardSelected,
    tokenSymbol,
    setTokenSymbol,
    selectedAppCode,
    setSelectedAppCode,
    setshareTokenSelected,
    shareTokenSelected,
  } = useAppContextDetails();

  const handleClick = (eachitem) => {
    // console.log('czxceew32234', eachitem?.app_code);
    // console.log('working..');
    setshareTokenSelected(eachitem?.tokens[0]);
    setCardSelected(true);
    setTokenSymbol(eachitem?.tokens[0]?.token_profile_data?.coinSymbol);
    setSelectedAppCode(eachitem?.app_code);
  };

  return (
    <div
      className={classNames.singleCard}
      onClick={() => handleClick(eachitem)}
      style={{
        opacity: !cardSelected
          ? '1'
          : cardSelected &&
            eachitem?.tokens[0]?.token_profile_data?.coinSymbol === tokenSymbol
          ? '1'
          : '0.25',
        boxShadow:
          cardSelected &&
          eachitem?.tokens[0]?.token_profile_data?.coinSymbol === tokenSymbol
            ? '0px 4px 4px rgba(0, 0, 0, 0.25)'
            : '',
      }}
    >
      <div className={classNames.topDiv}>
        <div className={classNames.itemDiv}>
          <img
            src={eachitem?.tokens[0]?.token_profile_data?.coinImage}
            alt="dummyIcon"
            style={{ width: '100px', height: '100px' }}
          />
          <div>{eachitem?.tokens[0]?.token_profile_data?.coinName}</div>
        </div>
        <div className={classNames.dotsIcon}>
          <BsThreeDotsVertical />
        </div>
      </div>
      <div className={classNames.bottomDiv}>
        <div>
          {eachitem?.tokens[0]?.value + ' ' + eachitem?.tokens[0]?.token}
        </div>
        <div>
          (
          {eachitem?.tokens[0]?.asset +
            ' ' +
            eachitem?.tokens[0]?.value_in_asset?.toFixed(2)}
          )
        </div>
      </div>
    </div>
  );
};

const SingleCardLoading = ({ eachitem }) => {
  //   console.log(eachitem, 'eactitem');
  return (
    <div className={classNames.singleCard}>
      <div className={classNames.topDiv}>
        <div className={classNames.itemDiv}>
          <Skeleton width={120} height={120} circle />
          <Skeleton width={100} height={15} />
        </div>
        <div className={classNames.dotsIcon}>
          <BsThreeDotsVertical />
        </div>
      </div>
      <div className={classNames.bottomDiv}>
        <div>
          <Skeleton width={100} height={15} />
        </div>
        <div>
          <Skeleton width={100} height={15} />
        </div>
      </div>
    </div>
  );
};
