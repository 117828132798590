import React, { useState, useEffect, useContext } from 'react';
import { useAppContextDetails } from '../../../context/AppContext';
import QRCode from 'qrcode.react';
import axios from 'axios';

import pasteIcon from '../../../static/images/paste.svg';
import { BankContext } from '../../../context/Context';
import OtpInput from 'react-otp-input';

const WithdrawCryptoStepFive = ({
  coinData,
  coinAddress,
  hashSuccessData,
  setHashSuccessData,
  addressInput,
  setAddressInput,
}) => {
  const { selectedCurrency, appCode, setBuySellStep, setActionFlow } =
    useAppContextDetails();
  const { email, token, profileId } = useContext(BankContext);
  const [loading, setLoading] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [otp, setOtp] = useState('');

  const onPasteClick = async () => {
    const copiedText = await navigator.clipboard.readText();
    setAddressInput(copiedText.trim());
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: window.innerHeight - 100,
      }}
    >
      <div>
        <div className="breadcrumb">
          <span onClick={(e) => setActionFlow(null)}>Email Verification</span>
          &nbsp;&nbsp;{`>`}&nbsp;&nbsp;
          <span style={{ fontWeight: 700 }}>Withdrawal Initiated</span>
        </div>
        <div style={{ padding: '0px 22px' }}>
          <div style={{ paddingTop: '40px' }}>
            <div
              style={{
                fontWeight: 'bold',
                fontSize: '22px',
                paddingBottom: '31px',
              }}
            >
              Congratulations
            </div>
            <div>
              You Have Successully Initiated An External Withdrawal Of 0.000
              {selectedCurrency?.coinSymbol}
            </div>

            <div>
              <div className="cardlabel">
                Previous {selectedCurrency?.coinSymbol} Balance
              </div>
              <div
                className="cardStyle"
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <div className="cardText">
                  {/* {withdrawalDetails?.appData?.app_name} */}
                  {selectedCurrency?.coinName}
                </div>
                <img
                  // src={withdrawalDetails?.appData?.app_icon}
                  src={selectedCurrency?.coinImage}
                  className="cardImage"
                />
              </div>
            </div>

            <div>
              <div className="cardlabel">
                Updated {selectedCurrency?.coinSymbol} Balance
              </div>
              <div
                className="cardStyle"
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <div className="cardText">
                  {/* {withdrawalDetails?.appData?.app_name} */}
                  {selectedCurrency?.coinName}
                </div>
                <img
                  // src={withdrawalDetails?.appData?.app_icon}
                  src={selectedCurrency?.coinImage}
                  className="cardImage"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ padding: '0px 22px' }}>
        <div className="doubleButton">
          <div
            className="submitButton"
            style={{ background: '#464B4E', width: '100%' }}
            onClick={(e) => setActionFlow('WithdrawCryptoStepTwo')}
          >
            <div style={{ color: 'white', fontWeight: 'bold' }}>
              Go To Vault
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithdrawCryptoStepFive;
