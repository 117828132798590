/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable indent */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useState } from 'react';
import Axios from 'axios';
import Lottie from 'react-lottie';
import * as animationData from '../../../../../static/animations/cpu-loading.json';
import { BankContext } from '../../../../../context/Context';
import { EarningsContext } from '../../../../../context/EarningsContext';
import { FormatCurrency } from '../../../../../utils/FunctionTools';
import { useAppContextDetails } from '../../../../../context/AppContext';

function SetAmount({ setOpenModal }) {
  const {
    coinListObject,
    email,
    token,
    profileId,
    tostShowOn,
    validateToken,
    admin,
    defaultPrecission,
  } = useContext(BankContext);
  const { appCode, appName } = useAppContextDetails();
  const {
    coinSelected,
    liquidEarningBalances,
    liquidOrBond,
    contractEarnings,
    appSelected,
  } = useContext(EarningsContext);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const [amount, setAmount] = useState('');
  const [usdAmount, setUsdAmount] = useState('');
  const amountChange = (e) => {
    if (
      coinSelected &&
      coinSelected.coinSymbol &&
      coinListObject[coinSelected.coinSymbol] &&
      coinListObject[coinSelected.coinSymbol].price
    ) {
      setAmount(e.target.value);
      setUsdAmount(
        e.target.value === ''
          ? ''
          : (
              coinListObject[coinSelected.coinSymbol].price.USD * e.target.value
            ).toFixed(2)
      );
    }
  };
  const usdAmountChange = (e) => {
    if (
      coinSelected &&
      coinSelected.coinSymbol &&
      coinListObject[coinSelected.coinSymbol] &&
      coinListObject[coinSelected.coinSymbol].price
    ) {
      setUsdAmount(e.target.value);
      setAmount(
        e.target.value === ''
          ? ''
          : Math.round(
              (e.target.value /
                coinListObject[coinSelected.coinSymbol].price.USD +
                Number.EPSILON) *
                100000
            ) / (100000).toPrecision(5)
      );
    }
  };

  const [loading, setLoading] = useState(false);

  const withdraw = async () => {
    const isValidTkn = await validateToken(email, token);
    if ((isValidTkn || admin) && coinSelected && coinSelected.coinSymbol) {
      if (appSelected && appSelected.app_code && liquidOrBond === 'Liquid') {
        setLoading(true);
        Axios.post(
          'https://comms.globalxchange.io/coin/vault/service/user/app/interest/withdraw',
          {
            email,
            token,
            app_code: appSelected.app_code,
            profile_id: appSelected.profile_id,
            coin: coinSelected.coinSymbol,
            amount: amount,
          }
        )
          .then((res) => {
            const { data } = res;
            if (data.status) {
              setOpenModal(false);
            }
            tostShowOn(data.message);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        setLoading(true);
        Axios.post(
          'https://comms.globalxchange.io/coin/iced/interest/withdraw',
          {
            email,
            token,
            app_code: appCode,
            profile_id: profileId,
            coin: coinSelected.coinSymbol,
            amount: amount,
          }
        )
          .then((res) => {
            const { data } = res;
            if (data.status) {
              setOpenModal(false);
            }
            tostShowOn(data.message);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  return (
    <>
      {loading ? (
        <div className="d-flex flex-column flex-grow-1">
          <div className="m-auto">
            <Lottie options={defaultOptions} height={150} width={150} />
          </div>
        </div>
      ) : (
        <div className="content detail">
          <div className="label">
            Your {coinSelected?.coinSymbol} Earnings Vault Will Be Debited
            <div className="btns">
              <div
                className="btnPercent"
                onClick={() => {
                  {
                    if (liquidOrBond === 'Liquid') {
                      setAmount(
                        liquidEarningBalances[coinSelected.coinSymbol] / 4
                      );
                      amountChange({
                        target: {
                          value:
                            liquidEarningBalances[coinSelected.coinSymbol] / 4,
                        },
                      });
                    } else {
                      setAmount(contractEarnings[coinSelected.coinSymbol] / 4);
                      amountChange({
                        target: {
                          value: contractEarnings[coinSelected.coinSymbol] / 4,
                        },
                      });
                    }
                  }
                }}
              >
                25%
              </div>
              <div
                className="btnPercent"
                onClick={() => {
                  {
                    if (liquidOrBond === 'Liquid') {
                      setAmount(
                        liquidEarningBalances[coinSelected.coinSymbol] / 2
                      );
                      amountChange({
                        target: {
                          value:
                            liquidEarningBalances[coinSelected.coinSymbol] / 2,
                        },
                      });
                    } else {
                      setAmount(contractEarnings[coinSelected.coinSymbol] / 2);
                      amountChange({
                        target: {
                          value: contractEarnings[coinSelected.coinSymbol] / 2,
                        },
                      });
                    }
                  }
                }}
              >
                50%
              </div>
              <div
                className="btnPercent"
                onClick={() => {
                  {
                    if (liquidOrBond === 'Liquid') {
                      setAmount(liquidEarningBalances[coinSelected.coinSymbol]);
                      amountChange({
                        target: {
                          value: liquidEarningBalances[coinSelected.coinSymbol],
                        },
                      });
                    } else {
                      setAmount(contractEarnings[coinSelected.coinSymbol]);
                      amountChange({
                        target: {
                          value: contractEarnings[coinSelected.coinSymbol],
                        },
                      });
                    }
                  }
                }}
              >
                100%
              </div>
            </div>
          </div>
          <label className="asset-item " tabIndex={0} role="button">
            <img src={coinSelected?.coinImage} className="icon my-auto" alt />
            <div className="name">{coinSelected?.coinName}</div>
            <div className="amount">
              <input
                type="text"
                placeholder={FormatCurrency(0, coinSelected?.coinSymbol)}
                value={amount}
                onChange={amountChange}
              />
              <small>{coinSelected?.coinSymbol}</small>
            </div>
          </label>
          <div className="label">
            Your {appName} {coinSelected?.coinSymbol} Vault Will Be Credited
          </div>
          <label className="asset-item " tabIndex={0} role="button">
            <img src={coinSelected?.coinImage} className="icon my-auto" alt />
            <div className="name">{coinSelected?.coinName}</div>
            <div className="amount">
              <input
                type="text"
                placeholder={FormatCurrency(0, coinSelected?.coinSymbol)}
                value={amount}
                onChange={amountChange}
              />
              <small>{coinSelected?.coinSymbol}</small>
            </div>
          </label>
          <div className="buttons">
            <div className="deposit inv">Done</div>
            <div
              className="deposit"
              onClick={() => {
                withdraw();
              }}
            >
              Confirm
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SetAmount;
