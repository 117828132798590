import virtualProspectus from '../../static/images/sharesToken-icons/virtualProspectus.svg';
import vaults from '../../static/images/sharesToken-icons/vaults.svg';
import dividends from '../../static/images/sharesToken-icons/dividends.svg';

export const SHARESTOKEN_HEADERS = [
  {
    name: 'virtualProtocol',
    icon: virtualProspectus,
    label: 'VirtualProtocol',
    marketOrder: 0,
    marketEnable: true,
    aboutOrder: 1,
  },
  {
    name: 'vaults',
    icon: vaults,
    label: 'Vaults',
    marketOrder: 1,
    marketEnable: true,
    aboutOrder: 2,
  },
  {
    name: 'dividends',
    icon: dividends,
    label: 'Dividends',
    marketOrder: 2,
    marketEnable: true,
    aboutOrder: 3,
  },
];
