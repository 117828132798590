import React, { useContext, useEffect, useMemo, useState } from 'react';

//css
import classNames from './vaults.module.scss';

//context
import { useCoinContextData } from '../../context/CoinContext';
import VaultsContent from './components/VaultsContent';
import {
  getMoneyMarketsVaults,
  getStakingEarningVaults,
  getVaultsCoin,
} from './assets/api';
import { BankContext } from '../../context/Context';
import VaultsContentLoading from './components/VaultsContentLoading';
import {
  useLoadBondVaults,
  useLoadMoneyMarketVault,
} from '../VaultsPage/Vaults/api';
import { useAppContextDetails } from '../../context/AppContext';

const Vaults = ({ searchedValue }) => {
  const { email } = useContext(BankContext);
  const { coinType } = useCoinContextData();
  const { balanceToggle } = useAppContextDetails();
  const [vaultsContent, setVaultsContent] = useState([]);
  const [vaultsLoading, setVaultsLoading] = useState(false);

  useEffect(() => {
    setVaultsContent([]);
    if (coinType == 'bonds') {
      getStakingEarningVaults(
        email,
        setVaultsContent,
        setVaultsLoading,
        balanceToggle
      );
    } else if (coinType == 'moneyMarkets') {
      getMoneyMarketsVaults(
        email,
        setVaultsContent,
        setVaultsLoading,
        balanceToggle
      );
    } else {
      if (coinType === 'crypto' || coinType === 'fiat') {
        getVaultsCoin(
          email,
          coinType,
          setVaultsContent,
          setVaultsLoading,
          balanceToggle
        );
      }
    }
  }, [coinType, balanceToggle]);

  const filteredData = (item) =>
    item
      ? Object.values(item)
          ?.join(' ')
          ?.toLowerCase()
          ?.includes(searchedValue.toLowerCase())
      : [];

  //imported from old temp

  // const {
  //   data: moneyMarketVaultData,
  //   isLoading: isMoneyMarketVaultDataLoading,
  // } = useLoadMoneyMarketVault(
  //   email,
  //   appCurrencyName,
  //   appCode,
  //   balanceToggle,
  //   true,
  //   coinType,
  //   updateMMVaultData,
  //   setUpdateMMVaultData
  // );

  // const moneyMarketData = useMemo(
  //   () =>
  //     moneyMarketVaultData?.data?.result?.[0]?.balances?.[0]?.liquid_balances,
  //   [moneyMarketVaultData]
  // );

  // const { data: bondVaultData, isLoading: isBondVaultLoading } =
  //   useLoadBondVaults(
  //     email,
  //     balanceToggle,
  //     appCurrencyName,
  //     true,
  //     coinType,
  //     updateBondVaultData,
  //     setUpdateBondVaultData
  //   );
  // const bondData = useMemo(
  //   () => bondVaultData?.data?.result?.[0]?.balances,
  //   [bondVaultData]
  // );

  return (
    <div className={classNames.vaultsMain}>
      <div className={classNames.headTab}>
        <div>Asset</div>
        <div>Balance</div>
        <div>Value</div>
      </div>
      <div className={classNames.bodyContainer}>
        {vaultsLoading || vaultsContent?.length < 1
          ? Array.from({ length: 4 }).map((_, index) => {
              return <VaultsContentLoading key={'vaults loading ' + index} />;
            })
          : vaultsContent
              ?.filter((coinItem) => filteredData(coinItem))
              ?.map((eachItem, index) => {
                return (
                  <VaultsContent
                    key={'vaults content ' + index}
                    // {...eachItem}
                    each={eachItem}
                  />
                );
              })}
      </div>
    </div>
  );
};

export default Vaults;
