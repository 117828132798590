import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppContextDetails } from '../../../context/AppContext';
import { BankContext } from '../../../context/Context';

import bitcoin from '../../../static/images/vaults/bitcoin.svg';
import forex from '../../../static/images/vaults/forex.svg';
import staking from '../../../static/images/vaults/staking.svg';
import moneymarket from '../../../static/images/vaults/moneymarket.svg';
import './vaults.scss';
import Toggle from '../../Toggle';

import { useCoinContextData } from '../../../context/CoinContext';
import { ListComponent } from '../../ListComponent';

export const Vaults = () => {
  const history = useHistory();

  const { searchedValue, setSearchedValue, balanceToggle, setBalanceToggle } =
    useAppContextDetails();
  const { coinType, setCoinType } = useCoinContextData();
  const { username } = useContext(BankContext);

  return (
    <ListComponent
      leftHeaderData={<h6>Welcome Back {username}</h6>}
      leftSubHeader={
        <div className="refer" onClick={() => history.push('/affiliate')}>
          <h6>Refer A Friend</h6>
        </div>
      }
      listOfIcons={
        <div className="icons">
          {coinType !== 'portfolio' && (
            <div className="toggle">
              <Toggle
                toggle={balanceToggle}
                setToggle={setBalanceToggle}
                coinType={coinType}
              />
            </div>
          )}

          <div className="iconbox">
            <div className="icondiv">
              <img
                className="iconsimg"
                style={{
                  opacity: coinType === 'crypto' ? 1 : '',
                }}
                src={bitcoin}
                alt="Bitcoin"
                key="img1"
                onClick={() => setCoinType('crypto')}
              />
            </div>
          </div>

          <div className="iconbox" style={{ borderTopRightRadius: '20px' }}>
            <div className="icondiv">
              <img
                className="iconsimg"
                style={{ opacity: coinType === 'fiat' ? 1 : '' }}
                src={forex}
                alt="Forex"
                key="img2"
                onClick={() => setCoinType('fiat')}
              />
            </div>
          </div>

          <div className="iconbox">
            <div className="icondiv">
              <img
                className="iconsimg"
                style={{
                  opacity: coinType === 'bonds' ? 1 : '',
                }}
                src={staking}
                alt="Staking"
                key="img4"
                onClick={() => setCoinType('bonds')}
              />
            </div>
          </div>

          <div className="iconbox" style={{ borderTopRightRadius: '20px' }}>
            <div className="icondiv">
              <img
                className="iconsimg"
                style={{
                  opacity: coinType === 'moneyMarkets' ? 1 : '',
                }}
                src={moneymarket}
                alt="MoneyMarket"
                key="img3"
                onClick={() => setCoinType('moneyMarkets')}
              />
            </div>
          </div>
        </div>
      }
      searchedValue={searchedValue}
      setSearchedValue={setSearchedValue}
    />
  );
};
