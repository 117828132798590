import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Layout from '../Layout/Layout';
import appScreen from '../static/images/appScreen/appScreen.png';
import linux from '../static/images/platforms/snap.png';
import win from '../static/images/platforms/win.svg';
import mac from '../static/images/platforms/mac.svg';
import { useAppContextDetails } from '../context/AppContext';

const platforms = {
  mac: {
    built: 'Built For Your Mac',
    logo: mac,
    store: 'Mac App Store',
  },
  pc: {
    built: 'Built For Your PC',
    logo: win,
    store: 'Microsoft Store',
  },
  linux: {
    built: 'Built For Linux',
    logo: linux,
    store: 'Snap Store',
  },
};
function DesktopAppDetail() {
  const { os } = useParams();
  const [platform, setPlatform] = useState({
    built: 'Built For Your Mac',
    logo: mac,
    store: 'Mac App Store',
  });
  const { appLogo, appName } = useAppContextDetails();

  useEffect(() => {
    if (platforms[os]) setPlatform(platforms[os]);
  }, [os]);
  return (
    <Layout active="desktopApps" className="desktopAppDetail">
      <div className="head">
        <div className="platform">
          <img src={appLogo} alt="" className="logo" />
          <div className="texts">
            <div className="assets">{appName}</div>
            <div className="builtFor">{platform.built}</div>
          </div>
        </div>
        <div className="version">
          <b>Version 1.1</b> | <u>View Update History</u>
        </div>
      </div>
      <div className="imgNButtons">
        <img className="appScreen" src={appScreen} alt="" />
        <div className="buttons">
          <div className="btnDownload">Download</div>
          <div className="btnStore">
            <img src={platform.logo} alt="" />
            <div className="texts">
              <div className="downLoadOn">Download On</div>
              <div className="store">{platform.store}</div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default DesktopAppDetail;
