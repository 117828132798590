import React from 'react'

const Amount = () => {
  return (
    <div>
      <p>Select Amount</p>
      <div className="text-size">How Much Do You Want To Invest?</div>
      <input
        type="text"
        className="asset-select-input"
        // placeholder="Search Assets.."
      />
    </div>
  )
}

export default Amount