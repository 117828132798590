import React, { useState, useEffect, useContext } from 'react';

import { FormatCurrency, FormatNumber } from '../../utils/FunctionTools';
import Axios from 'axios';
import HexaNumber from '../HexaNumber/HexaNumber';
import { BankContext } from '../../context/Context';
import { useAppContextDetails } from '../../context/AppContext';

const numRegex = /^\d*[.]?\d*$/;
function GenarateQuote({ setOpenModal, trustBalance, simulteRes, slots }) {
  const { defaultPrecission } = useContext(BankContext);
  const [value, setValue] = useState('');
  const [simulateObj, setSimulateObj] = useState('');
  const [balances, setBalances] = useState([]);
  const [selectCoin, setSelectCoin] = useState('BTC');
  const [dropOpen, setDropOpen] = useState(false);
  const { appLogo } = useAppContextDetails();

  useEffect(() => {
    
    Axios.get(
      'https://comms.globalxchange.io/coin/iced/get/liquid/interest'
    ).then(({ data }) => {
      if (data.status) {
        setBalances(data.interest_rates);
      }
    });
  }, []);
  const [slotIndex, setSlotIndex] = useState(0);
  const [selectedSlot, setSelectedSlot] = useState('slot1');
  const genarateValues = () => {
    Axios.post(
      'https://comms.globalxchange.io/coin/iced/liquid/icetray/interest/simualte/coin',
      {
        balance: parseFloat(value),
        balance_in_coin: selectCoin,
        slotName: selectedSlot,
        slotCoin: 'BTC',
      }
    ).then(({ data }) => {
      if (data.status) setSimulateObj(data);
    });
  };
  useEffect(() => {
    genarateValues();
  }, [selectedSlot]);

  return (
    <div className="transactionInspectorWrapper smart">
      <div
        className="overlay-ti"
        role="button"
        tabIndex="-1"
        onClick={() => setOpenModal(false)}
      />
      <div className="ti-content">
        <div className="ti-head">
          <img src={appLogo} alt="" />
          Generate Quote
        </div>
        <div className={`ti-detailSmart ${Boolean(simulateObj)}`}>
          {simulateObj && (
            <div className="sideLevels">
              <HexaNumber
                className="hexa"
                onClick={() => {
                  setSlotIndex(
                    slotIndex - 3 < 0
                      ? slotIndex - 3 + slots.length
                      : slotIndex - 3
                  );
                  setSelectedSlot(
                    slots[
                      slotIndex - 3 < 0
                        ? slotIndex - 3 + slots.length
                        : slotIndex - 3
                    ].name
                  );
                }}
                num={
                  slotIndex - 3 < 0
                    ? slots[slotIndex - 3 + slots.length]?.name?.replace(
                        'slot',
                        ''
                      )
                    : slots[slotIndex - 3]?.name?.replace('slot', '')
                }
              />{' '}
              <HexaNumber
                className="hexa "
                onClick={() => {
                  setSlotIndex(
                    slotIndex - 2 < 0
                      ? slotIndex - 2 + slots.length
                      : slotIndex - 2
                  );
                  setSelectedSlot(
                    slots[
                      slotIndex - 2 < 0
                        ? slotIndex - 2 + slots.length
                        : slotIndex - 2
                    ].name
                  );
                }}
                num={
                  slotIndex - 2 < 0
                    ? slots[slotIndex - 2 + slots.length]?.name?.replace(
                        'slot',
                        ''
                      )
                    : slots[slotIndex - 2]?.name?.replace('slot', '')
                }
              />
              <HexaNumber
                className="hexa "
                onClick={() => {
                  setSlotIndex(
                    slotIndex - 1 < 0
                      ? slotIndex - 1 + slots.length
                      : slotIndex - 1
                  );
                  setSelectedSlot(
                    slots[
                      slotIndex - 1 < 0
                        ? slotIndex - 1 + slots.length
                        : slotIndex - 1
                    ].name
                  );
                }}
                num={
                  slotIndex - 1 < 0
                    ? slots[slotIndex - 1 + slots.length]?.name?.replace(
                        'slot',
                        ''
                      )
                    : slots[slotIndex - 1]?.name?.replace('slot', '')
                }
              />
              <HexaNumber
                className="hexa true"
                onClick={() => {}}
                num={slots[slotIndex]?.name?.replace('slot', '')}
              />
              <HexaNumber
                className="hexa "
                onClick={() => {
                  setSlotIndex(
                    slotIndex + 1 > slots.length - 1
                      ? slotIndex + 1 - slots.length
                      : slotIndex + 1
                  );
                  setSelectedSlot(
                    slots[
                      slotIndex + 1 > slots.length - 1
                        ? slotIndex + 1 - slots.length
                        : slotIndex + 1
                    ].name
                  );
                }}
                num={
                  slotIndex + 1 > slots.length - 1
                    ? slots[slotIndex + 1 - slots.length]?.name?.replace(
                        'slot',
                        ''
                      )
                    : slots[slotIndex + 1]?.name?.replace('slot', '')
                }
              />
              <HexaNumber
                className="hexa "
                onClick={() => {
                  setSlotIndex(
                    slotIndex + 2 > slots.length - 1
                      ? slotIndex + 2 - slots.length
                      : slotIndex + 2
                  );
                  setSelectedSlot(
                    slots[
                      slotIndex + 2 > slots.length - 1
                        ? slotIndex + 2 - slots.length
                        : slotIndex + 2
                    ].name
                  );
                }}
                num={
                  slotIndex + 2 > slots.length - 1
                    ? slots[slotIndex + 2 - slots.length]?.name?.replace(
                        'slot',
                        ''
                      )
                    : slots[slotIndex + 2]?.name?.replace('slot', '')
                }
              />
              <HexaNumber
                className="hexa "
                onClick={() => {
                  setSlotIndex(
                    slotIndex + 3 > slots.length - 1
                      ? slotIndex + 3 - slots.length
                      : slotIndex + 3
                  );
                  setSelectedSlot(
                    slots[
                      slotIndex + 3 > slots.length - 1
                        ? slotIndex + 3 - slots.length
                        : slotIndex + 3
                    ].name
                  );
                }}
                num={
                  slotIndex + 3 > slots.length - 1
                    ? slots[slotIndex + 3 - slots.length]?.name?.replace(
                        'slot',
                        ''
                      )
                    : slots[slotIndex + 3]?.name?.replace('slot', '')
                }
              />
            </div>
          )}
          <div className="appNameBalance">Enter Prospective Balance</div>
          <div className="appNameBalanceValue inp">
            <div className="inpVal">
              <input
                type="text"
                className="inpVal"
                placeholder="0.0000"
                value={value}
                onChange={(e) => {
                  numRegex.test(e.target.value) && setValue(e.target.value);
                }}
              />
              <div className="boxCoin" onClick={() => setDropOpen(!dropOpen)}>
                {selectCoin}
                {dropOpen && (
                  <div className="list">
                    {balances.map((coin) => (
                      <div
                        className="listItem"
                        onClick={() => setSelectCoin(coin.coin)}
                      >
                        {coin.coin}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div
              className="btnGenarate"
              onClick={() => {
                value && genarateValues();
              }}
            >
              Genarate
            </div>
          </div>
          {simulateObj && (
            <>
              <div className="lblRow">
                <span>Trust Balance</span>
                <span>
                  {simulteRes?.slotData?.name.replace('slot', 'Tier ')}{' '}
                  Reqruirements
                </span>
              </div>
              <div className="valueRow">
                <span>
                  {FormatCurrency(trustBalance, 'BTC', defaultPrecission)} BTC
                </span>
                <span>
                  {FormatCurrency(
                    simulteRes?.slotData?.amount,
                    'BTC',
                    defaultPrecission
                  )}{' '}
                  BTC
                </span>
              </div>
              <div className="lblRow">
                <span>Base Rate</span>
                <span>
                  {simulteRes?.slotData?.name.replace('slot', 'Tier ')} Rate
                </span>
              </div>
              <div className="valueRow">
                <span>{FormatNumber(simulateObj.base_interest, 2)}%</span>
                <span>0.00%</span>
              </div>
              <div className="lblRow">
                <span>Base Earnings</span>
                <span>
                  {simulteRes?.slotData?.name.replace('slot', 'Tier ')} Earnings
                </span>
              </div>
              <div className="valueRow">
                <span>
                  {simulateObj.base_interest_value} {simulateObj.coin}
                </span>
                <span>
                  {simulateObj.interest_value || 0} {simulateObj.coin}
                </span>
              </div>
              <div className="lblRow">
                <span>Base Earnings USD</span>
                <span>
                  {simulteRes?.slotData?.name.replace('slot', 'Tier ')} Earnings
                  USD
                </span>
              </div>
              <div className="valueRow">
                <span>{simulateObj.base_interest_value_usd} USD</span>
                <span>{simulateObj.interest_value_usd || 0} USD</span>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default GenarateQuote;
