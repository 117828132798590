import React from 'react';
import { WithdrawActionForex } from '.';
import { useCoinContextData } from '../../../context/CoinContext';
import { WithdrawForexBreadcrumb } from './WithdrawForexBreadcrumb';

export const ConditionalWithdrawForex = () => {
  const { withdrawForexStep } = useCoinContextData();

  switch (withdrawForexStep) {
    case '1':
      var header = 'Which Currency Do You Want To Receive?';
      var searchPlaceholder = 'Search Assets...';
      return (
        <WithdrawActionForex header={header} placeholder={searchPlaceholder} />
      );
    case '2':
      var header = 'Which Account Do You Want Us To Send The Funds To?';
      var searchPlaceholder = 'Search Accounts...';
      return (
        <WithdrawActionForex header={header} placeholder={searchPlaceholder} />
      );
    case '3':
      var header = 'Select One Of The OTCDesks';
      var searchPlaceholder = 'Search OTCDesks...';
      return (
        <WithdrawActionForex header={header} placeholder={searchPlaceholder} />
      );
    default:
      return <WithdrawActionForex />;
  }
};

export const WithdrawForexBreadcrumbs = () => {
  const { withdrawForexStep, selectedCoin } = useCoinContextData();

  switch (withdrawForexStep) {
    case '1':
      var data = [
        { value: selectedCoin.coinName + ' Actions >', clickable: '1' },
        { value: 'Withdraw > ', clickable: '1' },
        { value: 'Receiving Currency', clickable: '1' },
      ];
      return <WithdrawForexBreadcrumb data={data} />;
    case '2':
      var data = [
        { value: 'Withdraw > ', clickable: '2' },
        { value: 'Receiving Currency > ', clickable: '2' },
        { value: 'Bank Account', clickable: '2' },
      ];
      return <WithdrawForexBreadcrumb data={data} />;
    case '3':
      var data = [
        { value: 'Bank Account > ', clickable: '3' },
        { value: 'Select OTCDesk', clickable: '3' },
      ];
      return <WithdrawForexBreadcrumb data={data} />;
    case '4':
      var data = [
        { value: 'Select OTCDesk > ', clickable: '4' },
        { value: 'Enter Amount', clickable: '4' },
      ];
      return <WithdrawForexBreadcrumb data={data} />;
    case '5':
      var data = [
        { value: 'Enter Amount > ', clickable: '5' },
        { value: 'Withdrawal Confirmation', clickable: '5' },
      ];
      return <WithdrawForexBreadcrumb data={data} />;
    case '6':
      var data = [
        { value: 'Withdrawal Confirmation > ', clickable: '6' },
        { value: 'Email Verification', clickable: '6' },
      ];
      return <WithdrawForexBreadcrumb data={data} />;
    default:
      return null;
  }
};
