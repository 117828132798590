import React, { useContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { APP_USER_TOKEN } from '../../../config';
import { useAppContextDetails } from '../../../context/AppContext';
import { useCoinContextData } from '../../../context/CoinContext';
import { BankContext } from '../../../context/Context';
import { useUserApps } from '../../../queryHooks';
import info from '../../../static/images/info.svg';
import { getAssets, getCoindata, getTradeValue } from '../api';
import SelectAsset from '../BuyAction/SelectAsset';
import './sellAction.scss';
import ToastMessage from '../../ToastMessage/ToastMessage';
import { handleDecimal } from '../../../utils/FunctionTools';
import { updateScroll } from '../../../config/constants';

export const SellAction = () => {
  const {
    selectAsset,
    setSelectAsset,
    fromAsset,
    setAssetName,
    setFromAsset,
    setCoinAction,
    setUpdateAfterTrade,
    coinAction,
    selectedCoin,
    setUpdateVaultData,
  } = useCoinContextData();

  const { appColorCode, appLogo, appCode, appByCode } = useAppContextDetails();
  const { email } = useContext(BankContext);

  const { data: userApps } = useUserApps(email);
  const selectedUserApp = userApps?.filter((e) => e.app_code === appCode);
  const userAppsProfileId = selectedUserApp?.[0]?.profile_id;
  const userIdToken = Cookies.get(APP_USER_TOKEN);

  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [fromBalance, setFromBalance] = useState('');
  const [stats, setStats] = useState(false);
  const [statsData, setStatsData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tradeDone, setTradeDone] = useState({});
  const [toast, setToast] = useState('');

  const handlePercCal = (value) => {
    let balance = handleDecimal(selectedCoin.coinValue);
    let final = (balance * value) / 100;
    final = handleDecimal(final);
    setFrom(final);
    handleApi(final, true);
  };

  const handleBreadCrumb = () => {
    setAssetName('');
    setFromAsset('');
    setFromBalance('');
    setCoinAction(['Menu']);
    updateScroll();
  };

  useEffect(() => {
    setTimeout(() => {
      setToast('');
    }, 2000);
  }, [toast]);

  useEffect(() => {
    if (coinAction?.length === 1) setSelectAsset(false);
  }, [coinAction]);

  const handleInput = (value, type, pathdat) => {
    let t = value;
    let newValue = (t === '' ? '0.00' : t !== '' && t.indexOf('.') >= 0)
      ? t.substr(0, t.indexOf('.')) + t.substr(t.indexOf('.'), 5)
      : t;
    if (type === 'to') {
      setTo(newValue);
      if (newValue === '') {
        handleApi(from, true, pathdat);
      } else {
        setStats(false);
      }
    } else if (type === 'from') {
      setFrom(newValue);
      handleApi(newValue, true);
    }
  };

  useEffect(() => {
    if (selectAsset === false && Object.keys(fromAsset).length > 0) {
      handleAssetData();
    }
  }, [selectAsset]);

  const handleApi = (value, data, pathdat, tradeClick) => {
    if (Object.keys(fromAsset).length === 0) {
      setTo('');
    } else if (
      (value === '' ||
        fromBalance === '' ||
        parseFloat(value) > selectedCoin.coinValue) &&
      data === false
    ) {
      setToast('Balance insufficient');
    } else {
      if (data === true) {
        setTo('Loading..');
      } else {
        setIsLoading(true);
      }
      let identifier = `Exchange ${selectedCoin.coinSymbol} FOR ${fromAsset.from_currency[0]?.coin_metadata?.coinSymbol}`;
      let obj = {
        token: userIdToken,
        email,
        app_code: appCode,
        profile_id: userAppsProfileId,
        from_amount: value === '' ? 0 : parseFloat(value),
        stats: data,
        identifier,
        path_id:
          pathdat !== undefined
            ? pathdat.paymentPaths[0].path_ids[0]
            : fromAsset.paymentPaths[0].path_ids[0],
      };

      getTradeValue(obj).then((response) => {
        let result = response.data;
        if (result.status) {
          if (data === true) {
            setStats(true);
            setStatsData(response.data);
            handleMappingStatsData(response.data);
          } else {
            setUpdateAfterTrade(true);
            setIsLoading(false);
            setTradeDone(response.data);
          }
          tradeClick && setUpdateVaultData(true);
        } else {
          if (data === false) {
            setIsLoading(false);
            setTradeDone({});
          }
          setTo('');
        }
      });
    }
  };

  const handleAssetData = () => {
    setTo('Loading..');
    let obj = {
      appCode,
      fromCurrency: selectedCoin.coinSymbol,
      toCurrency: fromAsset.from_currency[0]?.coin_metadata?.coinSymbol,
    };
    getAssets(obj).then((response) => {
      let result = response.data;
      if (result.status) {
        setFromAsset(result.pathData);
        handleInput(to, 'to', result.pathData);
      }
    });
  };

  const handleMappingStatsData = (data) => {
    setTo(handleDecimal(data.finalToAmount));
  };

  const handleSelectAsset = () => {
    setAssetName('');
    setStats(false);
    setSelectAsset(true);
    setCoinAction(['Sell', 'Select Asset']);
  };

  useEffect(() => {
    if (Object.keys(fromAsset).length !== 0) {
      DummyData();
    }
  });

  useEffect(() => {
    if (Object.keys(fromAsset).length !== 0) {
      handleAssetData();
    }
  }, [selectedCoin.coinSymbol]);

  const DummyData = () => {
    let obj = {
      app_code: appCode,
      email,
      displayCurrency: appByCode?.data.currencyname,
      post_app_prices: true,
      orderby_dsc: true,
      type: fromAsset.to_currency[0]?.coin_metadata?.type,
    };

    getCoindata(obj).then((response) => {
      let result = response.data;
      if (result.status) {
        result.coins_data.forEach((each) => {
          if (
            each.coinSymbol ===
            fromAsset.to_currency[0]?.coin_metadata?.coinSymbol
          ) {
            setFromBalance(each.coinValue);
          }
        });
      }
    });
  };

  const resetData = () => {
    setAssetName('');
    setFrom('');
    setTo('');
    setStats(false);
    setFromAsset({});
    setFromBalance('');
    setTradeDone({});
  };

  return (
    <div className="sidebarbuy">
      <div style={{ opacity: isLoading ? 0.25 : '' }}>
        {toast && <ToastMessage textOne={toast} onClose={() => setToast('')} />}
        <>
          {selectAsset === true ? (
            <SelectAsset />
          ) : selectAsset === false && Object.keys(tradeDone).length > 0 ? (
            <div className="congratscard">
              <div className="successData">
                <p className="congratsText">{`Congratulations. You Have Successully Exchanged ${
                  tradeDone.user_debit.amount
                    ? handleDecimal(tradeDone.user_debit.amount)
                    : ''
                } ${
                  tradeDone.user_debit.coin ? tradeDone.user_debit.coin : ''
                }  
          for ${
            tradeDone.user_credit.amount
              ? handleDecimal(tradeDone.user_credit.amount)
              : ''
          } ${
                  tradeDone.user_credit.coin ? tradeDone.user_credit.coin : ''
                }`}</p>
                <div>
                  <p className="congratshead">{`New ${
                    tradeDone.userResult.debit_obj.coin
                      ? tradeDone.userResult.debit_obj.coin
                      : ''
                  } Balance`}</p>
                  <div className="transactionA">
                    <p className="coinVal">
                      {tradeDone.userResult.debit_obj.updated_balance
                        ? handleDecimal(
                            tradeDone.userResult.debit_obj.updated_balance
                          )
                        : ''}
                    </p>
                    <div className="coinA" key="assets">
                      <img
                        className="coinimgsmallA"
                        src={
                          Object.keys(fromAsset).length > 0
                            ? fromAsset.from_currency[0]?.coin_metadata
                                ?.coinImage
                            : ''
                        }
                        alt=""
                      />
                      <p className="coinsymbolsmallA">
                        {Object.keys(fromAsset).length > 0
                          ? fromAsset.from_currency[0]?.coin_metadata
                              ?.coinSymbol
                          : ''}{' '}
                        &gt;
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <p className="congratshead">{`New ${
                    tradeDone.bankerResult.credit_obj.coin
                      ? tradeDone.bankerResult.credit_obj.coin
                      : ''
                  } Balance`}</p>
                  <div className="transactionA">
                    <p className="coinVal">
                      {tradeDone.bankerResult.credit_obj.updated_balance
                        ? handleDecimal(
                            tradeDone.bankerResult.credit_obj.updated_balance
                          )
                        : ''}
                    </p>
                    <div className="coinA" key="assets">
                      <img
                        className="coinimgsmallA"
                        src={
                          Object.keys(fromAsset).length > 0
                            ? fromAsset.to_currency[0]?.coin_metadata?.coinImage
                            : ''
                        }
                        alt=""
                      />
                      <p className="coinsymbolsmallA">
                        {Object.keys(fromAsset).length > 0
                          ? fromAsset.to_currency[0]?.coin_metadata?.coinSymbol
                          : ''}{' '}
                        &gt;
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer2">
                <div
                  className="back1"
                  key="back1"
                  onClick={() => handleBreadCrumb()}
                >
                  Close
                </div>
                <div
                  className="tradebtn1"
                  style={{
                    backgroundColor: appColorCode,
                  }}
                  key="tradebtn1"
                  onClick={() => {
                    resetData();
                    updateScroll();
                  }}
                >
                  Trade Again
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="menu1">
                <div className="menuItem1">
                  <div className="d-flex justify-content-between">
                    <p className="subhead">From</p>
                    <p className="subhead">
                      Balance: {handleDecimal(selectedCoin.coinValue)}
                    </p>
                  </div>

                  <div className="transaction">
                    <input
                      className="transc"
                      type="text"
                      key="to"
                      id="to"
                      name="to"
                      placeholder="0.00"
                      value={from}
                      onInput={(e) => handleInput(e.target.value, 'from')}
                    />
                    <div className="coin" key="coin">
                      <img
                        className="coinimgsmall"
                        src={selectedCoin.coinImage}
                        alt=""
                      />
                      <p className="coinsymbolsmall">
                        {selectedCoin.coinSymbol} &gt;
                      </p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p className="buy-perc" onClick={() => handlePercCal(25)}>
                      25%
                    </p>
                    <p className="buy-perc" onClick={() => handlePercCal(50)}>
                      50%
                    </p>
                    <p className="buy-perc" onClick={() => handlePercCal(100)}>
                      100%
                    </p>
                  </div>
                  <div style={{ opacity: to === 'Loading..' ? 0.25 : 1 }}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        margin: '43px 0 20px 0',
                      }}
                    >
                      <p className="subhead">To</p>
                      <p className="subhead">
                        Balance:{' '}
                        {fromBalance === ''
                          ? 'TBD'
                          : handleDecimal(fromBalance)}
                      </p>
                    </div>
                    {/* */}
                    <div className="transaction">
                      <input
                        className="transc"
                        key="from"
                        type="text"
                        id="from"
                        name="from"
                        placeholder="0.00"
                        value={to}
                        onInput={(e) => handleInput(e.target.value, 'to')}
                        disabled={
                          Object.keys(fromAsset).length === 0 ? 'disabled' : ''
                        }
                        style={{
                          cursor:
                            Object.keys(fromAsset).length === 0
                              ? 'not-allowed'
                              : '',
                        }}
                      />
                      <>
                        {Object.keys(fromAsset).length === 0 ? (
                          <div
                            className="coin1"
                            key="assets"
                            onClick={() => handleSelectAsset()}
                          >
                            <p className="selectAsset">Select Asset &gt;</p>
                          </div>
                        ) : (
                          <div
                            className="coin"
                            key="assets"
                            onClick={() => handleSelectAsset()}
                          >
                            <img
                              className="coinimgsmall"
                              src={
                                fromAsset.to_currency[0]?.coin_metadata
                                  ?.coinImage
                              }
                              alt=""
                            />
                            <p className="coinsymbolsmall">
                              {
                                fromAsset.to_currency[0]?.coin_metadata
                                  ?.coinSymbol
                              }{' '}
                              &gt;
                            </p>
                          </div>
                        )}
                      </>
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        margin: '43px 0 20px 0',
                      }}
                    >
                      <p className="subhead">
                        Fees in{' '}
                        {stats
                          ? statsData?.fees_in_coin
                          : selectedCoin.coinSymbol}
                      </p>
                      <p className="subhead" style={{ opacity: 0.25 }}>
                        {stats ? handleDecimal(statsData?.gx_fee) : 0.0} (
                        {stats
                          ? statsData?.percentage_fee_by_volume?.fees_with_broker?.toFixed(
                              2
                            )
                          : 0.0}
                        %)
                      </p>
                    </div>
                    <div
                      style={{
                        padding: '20px',
                        border: '0.5px solid #E5E5E5',
                        borderRadius: '15px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          padding: '20px 0 20px 0',
                          borderBottom: '0.5px solid #E5E5E5',
                        }}
                      >
                        <p>
                          Affiliate{' '}
                          <span>
                            <img src={info} alt="" width={10} />
                          </span>
                        </p>
                        <p style={{ opacity: 0.25 }}>
                          {stats
                            ? handleDecimal(statsData?.brokerData?.fee)
                            : 0.0}{' '}
                          (
                          {stats
                            ? statsData?.brokerData?.broker_fee_percentage?.toFixed(
                                2
                              )
                            : 0.0}
                          %)
                        </p>
                      </div>
                      <div
                        className="d-flex justify-content-between"
                        style={{
                          padding: '20px 0 20px 0',
                          borderBottom: '0.5px solid #E5E5E5',
                        }}
                      >
                        <p>
                          App{' '}
                          <span>
                            <img src={info} alt="" width={10} />
                          </span>
                        </p>
                        <p style={{ opacity: 0.25 }}>
                          {stats
                            ? handleDecimal(statsData?.appData?.app_fee)
                            : 0.0}{' '}
                          (
                          {stats
                            ? statsData?.appData?.app_fee_percentage?.toFixed(2)
                            : 0.0}
                          %)
                        </p>
                      </div>
                      <div
                        className="d-flex justify-content-between"
                        style={{
                          padding: '20px 0 20px 0',
                          borderBottom: '0.5px solid #E5E5E5',
                        }}
                      >
                        <p>
                          Banker{' '}
                          <span>
                            <img src={info} alt="" width={10} />
                          </span>
                        </p>
                        <p style={{ opacity: 0.25 }}>
                          {stats
                            ? handleDecimal(
                                statsData?.bankerData?.trade_fee_native
                              )
                            : 0.0}
                          (
                          {stats
                            ? statsData?.bankerData?.banker_fee_percentage?.toFixed(
                                2
                              )
                            : 0.0}
                          %)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="footer1">
                <div
                  className="back"
                  key="back"
                  onClick={() => handleBreadCrumb()}
                >
                  Back
                </div>
                <div
                  className="tradebtn"
                  key="tradebtn"
                  style={{
                    backgroundColor: appColorCode,
                    opacity: stats ? 1 : 0.25,
                    color: stats ? 'white' : '',
                    cursor: stats ? 'pointer' : '',
                  }}
                  onClick={() =>
                    stats ? handleApi(from, false, undefined, true) : ''
                  }
                >
                  Place Trade
                </div>
              </div>
            </>
          )}
        </>
      </div>
      <>
        {isLoading && (
          <div className="buyotclogo">
            <img className="otclogoimg" src={appLogo} alt="" />
          </div>
        )}
      </>
    </div>
  );
};
