import React, { useState, useEffect, useContext } from 'react';
import CoinsData from '../MobileLayoutComponents/CoinsData/CoinsData';

import searchIcon from '../../static/images/mobile/search.svg';
import cryptoIcon from '../../static/images/mobile/crypto.svg';
import forexIcon from '../../static/images/mobile/forex.svg';
import { AppContext } from '../../context/AppContext';
import MoneyMarketData from '../MobileLayoutComponents/MoneyMarketData';
import SwitcherSearch from './SwitcherSearch';

const MarketMoneyMarket = () => {
  const { selectedSubMenu, setSelectedSubMenu } = useContext(AppContext);
  const [assetType, setAssetType] = useState('crypto');
  const [openSearch, setOpenSearch] = useState(false);

  return (
    <div>
      {!openSearch ? (
        <>
          <div className="switcherWrapper">
            <div className="iconDiv">
              <img
                className="divImg"
                src={searchIcon}
                alt=""
                onClick={(e) => setOpenSearch(true)}
              />
            </div>
            <div className="iconWrapper">
              <div className="iconDiv" onClick={(e) => setAssetType('crypto')}>
                <img className="divImg" src={cryptoIcon} alt="" />
              </div>
              <div
                style={{
                  fontSize: '10px',
                  fontWeight: 'bold',
                  paddingTop: '5px',
                }}
              >
                {assetType === 'crypto' ? 'Crypto' : null}
              </div>
            </div>
            <div className="iconWrapper">
              <div className="iconDiv" onClick={(e) => setAssetType('fiat')}>
                <img className="divImg" src={forexIcon} alt="" />
              </div>
              <div
                style={{
                  fontSize: '10px',
                  fontWeight: 'bold',
                  paddingTop: '5px',
                }}
              >
                {assetType === 'fiat' ? 'Forex' : null}
              </div>
            </div>
          </div>
          <div style={{ overflowY: 'scroll', height: '80vh' }}>
            <MoneyMarketData assetType={assetType} />
          </div>
        </>
      ) : (
        <SwitcherSearch
          path={'MarketMoneyMarket'}
          listData={[
            { icon: cryptoIcon, name: 'Crypto' },
            { icon: forexIcon, name: 'Forex' },
          ]}
          setSelectedData={setAssetType}
          setOpenSearch={setOpenSearch}
        />
      )}
    </div>
  );
};

export default MarketMoneyMarket;
