import React from 'react';
import tripleArrow from '../../Static/image/tripleArrow.svg';

function NoChartView({ toCoin, fromCoin }) {
  return (
    <div className="noChartView">
      <div className="underConstruction">
        <p>
          Chart & Live Time Order Book For{' '}
          <b>
            {toCoin}/{fromCoin}
          </b>{' '}
          Is Still Under Construction. Rest Assured, It Will Be Worth The Wait.
        </p>
      </div>
      <div className="noWorries">
        <p>
          No Worries. Our Routing Engine Still Connects To All The Exchanges Who
          Can Do Your Trade. Proceed By Clicking Here
        </p>
        <img className="tripleArrow" src={tripleArrow} alt="" />
      </div>
    </div>
  );
}

export default NoChartView;
