import React, { useContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useAppContextDetails } from '../../../context/AppContext';
import { BankContext } from '../../../context/Context';

const BuyBondStepOne = ({ bondCount, setBondCount }) => {
  const { email, token, setSidebarCollapse } = useContext(BankContext);
  const {
    ownerEmail,
    appColorCode,
    setActionFlow,
    selectedBond,
    setSelectedBond,
    selectedCurrency,
  } = useAppContextDetails();

  const [amount, setAmount] = useState(0);

  useEffect(() => {
    console.log(selectedBond, 'wlkejhfclwhef');
  }, []);

  useEffect(() => {
    console.log();
    if (bondCount > 0) {
      const tempAmount =
        Number(bondCount) * Number(selectedBond.coinsData[0]?.bondCost);
      setAmount(tempAmount);
    }
  }, [bondCount]);

  return (
    <>
      <div>
        <div style={{ background: appColorCode, height: '15vh' }}>&nbsp;</div>
        <div className="imgWrapper">
          <div className="imgCointainer">
            <img
              src={selectedBond?.bankerData?.profilePicURL}
              alt=""
              style={{ width: '60px', height: '60px' }}
            />
          </div>
        </div>
        <div
          style={{ textAlign: 'center', fontSize: '20px', fontWeight: 'bold' }}
        >
          {selectedBond?.bankerData?.displayName} Bond
        </div>
        <div style={{ textAlign: 'center' }}>Amount</div>
        <div className="dataContainer">
          <div>
            <div
              style={{
                paddingTop: '30px',
                fontSize: '20px',
                fontWeight: 'bold',
              }}
            >
              Step 1: Amount
            </div>
            <div>How Many Staking Contracts Do You Want To Buy?</div>
          </div>

          <div>
            <div
              style={{ display: 'flex', fontWeight: '600', fontSize: '20px' }}
            >
              <input
                value={bondCount}
                onChange={(e) => setBondCount(e.target.value)}
                type="text"
                placeholder="0"
                style={{ width: '100%', border: 'none' }}
              />
              <div>Staking Contracts</div>
            </div>
            <hr />
            <div
              style={{ display: 'flex', fontWeight: '600', fontSize: '20px' }}
            >
              <input
                value={amount}
                type="text"
                placeholder="0"
                style={{ width: '100%', border: 'none' }}
              />
              <div>{selectedBond?.coinsData[0]?.coin}</div>
            </div>
          </div>

          <div className="doubleButton">
            <div
              className="submitButton"
              style={{ background: 'white' }}
              onClick={(e) => setActionFlow('')}
            >
              <div style={{ fontWeight: 'bold' }}>Back</div>
            </div>
            <div
              className="submitButton"
              style={{ background: appColorCode }}
              onClick={(e) => setActionFlow('BuyBondStepTwo')}
              //   onClick={confirmQuoteFunction}
            >
              <div style={{ color: 'white', fontWeight: 'bold' }}>Next</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BuyBondStepOne;
