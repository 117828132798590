import React, { useContext, useEffect, useState } from 'react';
import classNames from './eaconnect.module.scss';
import axios from 'axios';

import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import {StatueDataMuiltBak} from './apidata'
import { BankContext } from '../../context/Context';
const MyEAConnect = () => {
const [Data,setData]=useState([])
const [loader,setloader]=useState(false)
const { email, profileId, token } = useContext(BankContext);
  // const { connectEA } = useContext(BankContext);
  useEffect(()=>{
    SelectMuiltbank(email)
  },[])

  const SelectMuiltbank = async (e) => {
    setloader(true)
    await StatueDataMuiltBak(e).then((response) => {
      let result = response?.data?.data;
      setData(result);
      setloader(false)
    });
  };

  return (
    <div className={classNames.eaConnectContainer}>
      <div className={classNames.eaConnectHead}>
        <div className={classNames.name}>EA Name</div>
        <div className={classNames.acctNum}>Account Number</div>
        <div className={classNames.serverName}>Server Name</div>
        <div className={classNames.masterPass}>Master Password</div>
        <div className={classNames.statuss}>Submission Status</div>
        <div className={classNames.dateofSub}>Time Of Submission</div>
        <div className={classNames.actionBtn}>Actions</div>
      </div>
      <div className={classNames.eaConnectContent}>
        {!loader
          ? Data?.map((eachItem, i) => {
              return <EachConnectDiv eachItem={eachItem} i={i} />;
            })
          : Array(10)
              .fill(' ')
              .map((item, i) => {
                return (
                  <div className={classNames.eachItem} key={'loading' + i}>
                    <div className={classNames.name}>
                      <Skeleton />
                    </div>
                    <div className={classNames.acctNum}>
                      <Skeleton />
                    </div>
                    <div className={classNames.serverName}>
                      <Skeleton />
                    </div>
                    <div className={classNames.masterPass}>
                      <Skeleton />
                    </div>
                    <div className={classNames.statuss}>
                      <Skeleton />
                    </div>
                    <div className={classNames.dateofSub}>
                      <Skeleton />
                    </div>
                    <div className={classNames.actionBtn}>   <Skeleton /></div>                  </div>
                );
              })}
      </div>
    </div>
  );
};

export default MyEAConnect;

const EachConnectDiv = ({ eachItem, i }) => {
  const [pass, setPass] = useState(true);

  // useEffect(() => {
  //   if (eachItem?.note?.masterpassword) {
  //     setPass(eachItem?.note?.masterpassword);
  //   } else {
  //     setPass('');
  //   }
  // }, []);
console.log("zxc",pass)
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1) ;
}
const toTitleCase = (phrase) => {
  return phrase
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};
  return (
    <div className={classNames.eachItem} key={eachItem?.note?.ea + i}>
      <div className={classNames.name}>
        {eachItem?.note?.ea ? capitalizeFirstLetter(eachItem?.note?.ea) : ''}
      </div>
      <div className={classNames.acctNum}>
        {eachItem?.note?.accountnumber ? eachItem?.note?.accountnumber : ''}
      </div>
      <div className={classNames.serverName}>
        {' '}
        {eachItem?.note?.servername ? eachItem?.note?.servername : ''}
      </div>
      <div className={classNames.masterPass} onClick={()=>setPass(!pass)}>
        {' '}
        <input type={pass?"password":"text"}  value={eachItem?.note?.masterpassword ? eachItem?.note?.masterpassword : ''} />
        {/* {eachItem?.note?.masterpassword ? eachItem?.note?.masterpassword : ''} */}
        
      </div>
      <div className={classNames.statuss}>
        {' '}
        {eachItem?.status ?capitalizeFirstLetter( eachItem?.status) : ''}
      </div>
      <div className={classNames.dateofSub}>
        {eachItem?.date
          ? moment(eachItem?.date).format('MMMM Do YYYY, h:mm:ss A')
          : ''}
      </div>
      <div className={classNames.serverName}
      style={{color:eachItem?.note?.connection_status=="connected"?"green":"red"}}
      >
        {' '}
        {eachItem?.note?.connection_status ? toTitleCase(eachItem?.note?.connection_status ): ''}
      </div>
      {/* <div className={classNames.actionBtn}>
      
      </div> */}
    </div>
  );
};
