import React from 'react';
import { useCoinContextData } from '../../../context/CoinContext';
import checkbook from '../../../static/images/checkbook.svg';
import paste from '../../../static/images/paste.svg';
import { pasteSelected } from '../../../utils/FunctionTools';

export const EnterAddress = ({ handleBreadCrumb }) => {
  const {
    selectedCoin,
    addressTerm,
    setAddressTerm,
    setCoinAction,
    setSelectedCoin,
    setCoinActionEnabled,
    setStep,
  } = useCoinContextData();

  return (
    <div className="sidebarwithdraw" style={{ height: '100%' }}>
      <div style={{ height: '100%' }}>
        <div
          className="breadcrumb"
          style={{
            display: 'flex',
            padding: '5px 0 5px 1.85rem',
            height: '4%',
            borderTop: '0.5px solid #E7E7E7',
            borderBottom: '0.5px solid #E7E7E7',
            alignItems: 'center',
            width: '100%',
            borderRadius: '0px',
            minHeight: '38px',
            cursor: 'default',
            marginBottom: '0',
          }}
        >
          <p
            className="breadcrumbText"
            style={{
              margin: '0',
              cursor: 'pointer',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={() => handleBreadCrumb()}
          >
            <span
              style={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                padding: '0 1.5rem',
                borderRadius: '35px',
                color: '#fff',
                fontWeight: '500',
                background: 'linear-gradient(270deg, #81cbd7, #3d4394)',
              }}
            >
              Destination
            </span>
          </p>
          {/* <p
            className="active-crumb"
            style={{
              margin: '0',
              cursor: 'pointer',
            }}
          >
            <span>Enter Address</span>
          </p> */}
        </div>
        <div
          className="step-div"
          style={{
            padding: '0',
            width: '100%',
            height: 'calc(100% - 4%)',
            position: 'relative',
          }}
        >
          {/* <p className="step-head">Step 1: Enter Address</p> */}
          <p
            className="step-para"
            style={{
              margin: '0',
              paddingLeft: '1.85rem',
              height: '5rem',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            Enter Destination Address
          </p>
          <div
            className="address-div"
            style={{ height: '6.25rem', borderRadius: '0' }}
          >
            <input
              style={{
                width: '100%',
                height: '100%',
                borderRadius: '0',
                borderLeft: '0',
                borderRight: '0',
                fontWeight: '500',
                padding: '0 0 0 1.85rem',
                fontSize: '1.5rem',
              }}
              className="address-input"
              key="address"
              id="clipboard"
              placeholder="Address..."
              type="text"
              onChange={(e) => setAddressTerm(e.target.value)}
            />
          </div>
          <div style={{ paddingLeft: '1.85rem' }}>
            <div className="each-paste" key="paste">
              <div>
                <img className="paste-btn" src={paste} alt="Paste" width={20} />
              </div>
              <p
                className="paste-text"
                onClick={() => pasteSelected(setAddressTerm)}
              >
                Paste Clipboard
              </p>
            </div>
            <div className="each-paste" key="checkbook">
              <div>
                <img className="checkbook-btn" src={checkbook} alt="" />
              </div>
              <p className="checkbook-text">Add From Checkbook</p>
            </div>
          </div>
          <div className="w-nextBtn">
            <div
              style={{
                opacity: addressTerm !== '' ? 1 : 0.25,
                cursor: addressTerm !== '' ? 'pointer' : 'not-allowed',
              }}
              onClick={() => {
                if (addressTerm !== '') {
                  setStep(2);
                }
              }}
            >
              Next Step
            </div>
          </div>
          <div className="withdrawFooter">
            <div className="backclose-btns">
              <div
                style={{
                  color: '#343C5B',
                  border: '1px solid #E5E5E5',
                }}
                onClick={() => {
                  setCoinAction(['Menu']);
                  setAddressTerm('');
                }}
              >
                Back
              </div>
              <div
                style={{
                  background: '#FF5E5E',
                }}
                onClick={() => {
                  setCoinAction(['Menu']);
                  setSelectedCoin('');
                  setCoinActionEnabled(false);
                }}
              >
                Close
              </div>
            </div>
            <div
              className="action-btn"
              style={{
                background:
                  'linear-gradient(270deg, rgb(129, 203, 215), rgb(61, 67, 148))',
              }}
              onClick={() => {
                setCoinAction(['Menu']);
              }}
            >
              {selectedCoin?.coinSymbol} Action
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
