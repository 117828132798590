import React, { useState, useEffect } from 'react';
import axios from 'axios';

import './css/marketMatketVerse.scss';
import { formatterHelper } from '../../utils/FunctionTools';
import { GX_API_ENDPOINT } from '../../config';
import searchIcon from '../../static/images/mobile/search.svg';
import SwitcherSearch from './SwitcherSearch';

const MarketMarketsVerse = () => {
  const [allMarkets, setAllMarkets] = useState([]);
  const [allCoins, setAllCoins] = useState([]);
  const [marketSwitherData, setMarketSwitherData] = useState([]);
  const [selectedMarket, setSelectedMarket] = useState(null);
  const [openSearch, setOpenSearch] = useState(false);

  useEffect(() => {
    axios
      .get(`https://comms.globalxchange.io/coin/vault/get/all/coins`)
      .then(({ data }) => {
        setAllCoins(data?.coins);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${GX_API_ENDPOINT}/coin/investment/types/get`)
      .then(({ data }) => {
        console.log(data, 'lwjfkjwebfkjwbef');
        setSelectedMarket(data?.investments[0]);
        setMarketSwitherData(data?.investments);
      });
  }, []);

  useEffect(() => {
    getAllMarkets();
  }, [selectedMarket]);

  const getAllMarkets = () => {
    axios
      .get(`https://comms.globalxchange.io/coin/investment/path/get`, {
        params: {
          country: 'India',
          investmentType: selectedMarket?.code,
        },
      })
      .then(({ data }) => {
        if (data.status) {
          setAllMarkets(data.paths);
        } else {
          setAllMarkets([]);
        }
      });
  };

  return (
    <div>
      {!openSearch ? (
        <>
          <div>
            <div className="switcherWrapper">
              <div className="iconDiv">
                <img
                  className="divImg"
                  src={searchIcon}
                  alt=""
                  onClick={(e) => setOpenSearch(true)}
                />
              </div>
              {marketSwitherData.map((item) => {
                return (
                  <div className="iconWrapper">
                    <div
                      className="iconDiv"
                      onClick={(e) => setSelectedMarket(item)}
                    >
                      <img className="divImg" src={item?.icon} alt="" />
                    </div>
                    <div
                      style={{
                        fontSize: '10px',
                        fontWeight: 'bold',
                        paddingTop: '5px',
                      }}
                    >
                      {selectedMarket?.name === item?.name ? item?.name : null}
                    </div>
                  </div>
                );
              })}
            </div>
            <div style={{ overflowY: 'scroll', height: '80vh' }}>
              {allMarkets.length > 0 ? (
                allMarkets.map((item) => {
                  return (
                    <>
                      <div className="marketWrapper">
                        <div className="marketRow">
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <img
                              src={item?.token_profile_data?.coinImage}
                              alt=""
                              style={{ width: '25px', height: '25px' }}
                            />
                            <div style={{ paddingLeft: '10px' }}>
                              {item?.token_profile_data?.coinName}
                            </div>
                          </div>
                          <div>
                            {formatterHelper(item?.token_price, item.asset)}
                          </div>
                        </div>
                        <div className="marketRowSmall">
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <div>By</div>
                            <img
                              src={item?.banker_metaData?.profilePicURL}
                              alt=""
                              style={{
                                width: '15px',
                                height: '15px',
                                margin: '0px 5px',
                              }}
                            />
                            <div> {item?.banker_metaData?.displayName}</div>
                          </div>
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <img
                              src={
                                allCoins.find(
                                  (o) => o.coinSymbol === item.asset
                                ).coinImage
                              }
                              alt=""
                              style={{
                                width: '15px',
                                height: '15px',
                                marginRight: '5px',
                              }}
                            />
                            <div>{item?.asset}</div>
                          </div>
                        </div>
                        <div
                          className="marketRowSmall"
                          style={{ fontSize: '12px' }}
                        >
                          <div>Supply: {item?.token_totalSupply}</div>
                          <div>Market Cap: {item?.asset_balance}</div>
                        </div>
                      </div>
                    </>
                  );
                })
              ) : (
                <div>No Paths Found</div>
              )}
            </div>
          </div>
        </>
      ) : (
        <SwitcherSearch
          path={'MarketMarketsVerse'}
          listData={marketSwitherData}
          setSelectedData={setSelectedMarket}
          setOpenSearch={setOpenSearch}
        />
      )}
    </div>
  );
};

export default MarketMarketsVerse;
