import React, { useState } from 'react';
import { useContext } from 'react';
import { BankContext } from '../../context/Context';
import { useEffect } from 'react';
import axios from 'axios';
import classNames from './onhold.module.scss';
import { EachUser } from './EmailSelection';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const EmailConfirmation = ({ setOnHold }) => {
  const {
    setEmail,
    setUsername,
    setProfileImg,
    setName,
    userSelectedOnHold,
    setUserSelectedOnHold,
  } = useContext(BankContext);

  const [selectedAccountDetails, setSelectedAccountDetails] = useState('');

  useEffect(() => {
    axios
      .get(
        `https://comms.globalxchange.io/user/profile/data/get?email=${
          userSelectedOnHold ? userSelectedOnHold : ''
        }`
      )
      .then((res) => {
        const { data } = res;
        // console.log(data?.usersData[0], 'user selecteddddd');
        setSelectedAccountDetails(data?.usersData[0]);
      });
  }, [userSelectedOnHold]);

  return (
    <div className={classNames.emailConfirmation}>
      <div>
        <div className={classNames.title}>Is This The User You Want?</div>
        {selectedAccountDetails ? (
          <EachUser
            name={
              selectedAccountDetails?.hardCoded[0]?.data?.username
                ? selectedAccountDetails?.hardCoded[0]?.data?.username
                : ''
            }
            email={
              selectedAccountDetails?.email ? selectedAccountDetails?.email : ''
            }
            profile_img={
              selectedAccountDetails?.dynamic[0]?.data?.profile_img
                ? selectedAccountDetails?.dynamic[0]?.data?.profile_img
                : ''
            }
            setSeachEmail=""
          />
        ) : (
          <div
            style={{ display: 'flex', gap: '1.5rem', padding: '1rem 1.85rem' }}
          >
            <Skeleton circle width="3.5rem" height="3.5rem" />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Skeleton width={175} />
              <Skeleton width={90} />
            </div>
          </div>
        )}
      </div>
      <div className={classNames.btnsContainer}>
        <div
          className={classNames.greenBtn}
          onClick={() => {
            setEmail(selectedAccountDetails?.email);
            setUsername(selectedAccountDetails?.hardCoded[0]?.data?.username);
            setName(selectedAccountDetails?.hardCoded[0]?.data?.username);
            setProfileImg(
              selectedAccountDetails?.dynamic[0]?.data?.profile_img
            );
            setOnHold('');
          }}
          style={{
            pointerEvents: !selectedAccountDetails ? 'none' : '',
            opacity: !selectedAccountDetails ? '0.5' : '',
          }}
        >
          Yes
        </div>
        <div
          className={classNames.blueBtn}
          onClick={() => {
            setOnHold('step2');
            setUserSelectedOnHold('');
          }}
        >
          No Its Not
        </div>
        <div className={classNames.redBtn} onClick={() => setOnHold('')}>
          Close
        </div>
      </div>
    </div>
  );
};

export default EmailConfirmation;
