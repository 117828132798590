import { faEnvelope, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faArrowLeft, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import ReactSafeHtml from 'react-safe-html';

function OpenMailView({ onClose, mailDetail }) {
  return (
    <div className="openMailView">
      <div className="head">
        <FontAwesomeIcon
          icon={faArrowLeft}
          className="icon"
          onClick={() => {
            try {
              onClose();
            } catch (error) {}
          }}
        />
        <FontAwesomeIcon
          icon={faTrashAlt}
          className="icon del"
          onClick={() => {
            try {
              onClose();
            } catch (error) {}
          }}
        />
        <FontAwesomeIcon
          icon={faEnvelope}
          className="icon"
          onClick={() => {
            try {
              onClose();
            } catch (error) {}
          }}
        />
        <FontAwesomeIcon
          icon={faEllipsisV}
          className="icon"
          onClick={() => {
            try {
              onClose();
            } catch (error) {}
          }}
        />
      </div>
      <div className="content">
        <div className="subject">{mailDetail.subject}</div>
        <div className="body">
          <ReactSafeHtml html={mailDetail.body} />
        </div>
      </div>
    </div>
  );
}

export default OpenMailView;
