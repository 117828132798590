import React, { useEffect, useState } from 'react';
import { useCoinContextData } from '../../context/CoinContext';
import { useLocation, useHistory } from 'react-router-dom';

const ActionFooter = () => {
  const {
    coinActionEnabled,
    setCoinActionEnabled,
    setCoinAction,
    isCoinLoadingInAddAction,
    addBankingEnabled,
    setAddBankingEnabled,
    setNetworkData,
  } = useCoinContextData();

  const { pathname } = useLocation();
  const [showItem, setShowItem] = useState(true);

  useEffect(() => {
    if (pathname.includes('/swap')) {
      setShowItem(false);
    }
  }, [pathname]);

  return (
    <div
      onClick={() => {
        if (coinActionEnabled) {
          setCoinActionEnabled(false);
          setCoinAction(['Menu']);
          setNetworkData('');
        }
      }}
      className="actionMenu-btmItm"
      style={{ display: !showItem ? 'none' : 'flex' }}
    >
      <div className="closee-btn">Close</div>
    </div>
  );
};

export default ActionFooter;
