import Axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';

import { BankContext } from '../../context/Context';
import placeHolder from '../../static/images/placeHolder/videoHolder.svg';
function WhatIsAssetPostItem({ vidData }) {
  const history = useHistory();
  const { setVideoShow, videoShow } = useContext(BankContext);
  const [videoUrl, setVideoUrl] = useState('');
  useEffect(() => {
    Axios.post(
      'https://vod-backend.globalxchange.io/get_user_profiled_video_stream_link',
      {
        video_id: vidData.video,
      }
    ).then(({ data }) => setVideoUrl(data));
  }, []);
  return (
    <div
      className="postItm"
      onClick={() => {
        if (videoUrl) {
          setVideoShow(
            videoShow
              ? false
              : {
                  ...vidData,
                  videoUrl,
                }
          );
          history.push(`/Classrooms/video/${vidData._id}`);
        }
      }}
    >
      <div className="img">
        <img src={placeHolder} alt="" className="ph" />
        {videoUrl ? (
          <>
            <img
              className="videoThumb"
              src={vidData.image}
              width="100%"
              height="100%"
              alt=""
              onClick={() =>
                setVideoShow(
                  videoShow
                    ? false
                    : {
                        ...vidData,
                        videoUrl,
                      }
                )
              }
            />
          </>
        ) : (
          <Skeleton width="100%" height="100%" />
        )}
      </div>
      <div className="texts">
        <div className="titlePost">{vidData.title}</div>
        <p className="descPost">{vidData.desc}</p>
      </div>
    </div>
  );
}

export default WhatIsAssetPostItem;
