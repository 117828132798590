import React, { useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';

function EarningsController({ onSave }) {
  const [timeFrame, setTimeFrame] = useState('Daily');
  const [statistic, setStatistic] = useState('Gross Earnings');
  return (
    <div className="earningsController">
      <div className="ecTitle">Earnings Controller</div>
      <div className="timeRow">
        <div className="rowLbl">Time Frame</div>
        <Scrollbars
          className="optListWpapper"
          renderView={(props) => <div {...props} className="optList" />}
          renderThumbHorizontal={() => <div />}
          renderThumbVertical={() => <div />}
        >
          <div
            className={`optItm ${timeFrame === 'Daily'}`}
            onClick={() => setTimeFrame('Daily')}
          >
            Daily
          </div>
          <div
            className={`optItm ${timeFrame === 'Weekly'}`}
            onClick={() => setTimeFrame('Weekly')}
          >
            Weekly
          </div>
          <div
            className={`optItm ${timeFrame === 'Monthly'}`}
            onClick={() => setTimeFrame('Monthly')}
          >
            Monthly
          </div>
          <div
            className={`optItm ${timeFrame === 'Quarterly'}`}
            onClick={() => setTimeFrame('Quarterly')}
          >
            Quarterly
          </div>
          <div
            className={`optItm ${timeFrame === 'Yearly'}`}
            onClick={() => setTimeFrame('Yearly')}
          >
            Yearly
          </div>
        </Scrollbars>
      </div>
      <div className="tierRow">
        <div className="rowLbl">Statistic</div>
        <Scrollbars
          className="optListWpapper"
          renderView={(props) => <div {...props} className="optList" />}
          renderThumbHorizontal={() => <div />}
          renderThumbVertical={() => <div />}
        >
          <div
            className={`optItm ${statistic === 'Gross Earnings'}`}
            onClick={() => setStatistic('Gross Earnings')}
          >
            Gross Earnings
          </div>
          <div
            className={`optItm ${statistic === 'Net Earnings'}`}
            onClick={() => setStatistic('Net Earnings')}
          >
            Net Earnings
          </div>
        </Scrollbars>
      </div>
      <div
        className="btnSave"
        onClick={() => {
          try {
            onSave();
          } catch (error) {}
        }}
      >
        Save
      </div>
    </div>
  );
}

export default EarningsController;
