import React from 'react';
import { useCoinContextData } from '../../context/CoinContext';
import ActionFooter from './ActionFooter';
import { AddActionFooter } from './CoinActionFooters/AddActionFooter';
import { AddBankAccountFooter } from './CoinActionFooters/AddBankAccountFooter';
import { DefaultFooter } from './CoinActionFooters/DefaultFooter';
import { TransferActionFooter } from './CoinActionFooters/TransferActionFooter';
import { WithdrawActionFooter } from './CoinActionFooters/WithdrawActionFooter';

export const SelectFooters = ({
  toggleSettings,
  setToast,
  isSecondaryMenu,
  openSettings,
  setAdminSidebar,
}) => {
  const { coinAction, addBankingEnabled, coinActionEnabled, swapMenu } =
    useCoinContextData();

  if (addBankingEnabled)
    return (
      <AddBankAccountFooter
        DefaultFooter={
          <DefaultFooter
            isSecondaryMenu={isSecondaryMenu}
            toggleSettings={toggleSettings}
            openSettings={openSettings}
            setAdminSidebar={setAdminSidebar}
          />
        }
      />
    );

  switch (coinAction[0]) {
    case 'Add':
      return (
        <AddActionFooter
          setToast={setToast}
          alternateFooterComponent={
            <DefaultFooter
              isSecondaryMenu={isSecondaryMenu}
              toggleSettings={toggleSettings}
              openSettings={openSettings}
              setAdminSidebar={setAdminSidebar}
            />
          }
        />
      );
    case 'Withdraw':
      return <WithdrawActionFooter />;
    case 'Menu':
      if (coinActionEnabled && !swapMenu) {
        return (
          // <DefaultFooter
          //   isSecondaryMenu={isSecondaryMenu}
          //   toggleSettings={toggleSettings}
          //   openSettings={openSettings}
          //   setAdminSidebar={setAdminSidebar}
          // />
          <ActionFooter />
        );
      } else {
        return <></>;
      }

    case 'Transfer':
      return (
        <TransferActionFooter
          DefaultFooter={
            <DefaultFooter
              isSecondaryMenu={isSecondaryMenu}
              toggleSettings={toggleSettings}
              openSettings={openSettings}
              setAdminSidebar={setAdminSidebar}
            />
          }
        />
      );
    default:
      return <></>;
  }
};
