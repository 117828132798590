import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import classNames from './marketsPage.module.scss';
import tradeStream from '../../static/images/sidebarIcons/tradeStream.svg';
import searchIcon from '../../static/images/search.svg';
import { useShareTokensDetail } from '../../queryHooks';
import { MARKET_ASSET_CLASSES, SIDEBAR_FOOTERS } from '../../config/constants';
import { BankContext } from '../../context/Context';
import { GetSortOrder } from '../../utils/FunctionTools';
import MarketAssetDetail from '../../components/MarketAssetDetail';
import TerminalsSidebar from '../../components/TerminalsSidebar';
import LoginModal from '../../components/LoginModalNew';
import Layout from '../../Layout/Layout';
import MarketTableCrypto from './components/MarketTableCrypto';
import MarketTableForex from './components/MarketTableForex';
import MarketTableShares from './components/MarketTableShares';
import MarketTableBonds from './components/MarketTableBonds';
import MarketTableMoneyMarkets from './components/MarketTableMoneyMarkets';
import { BondContractDetails } from './components/BondContractDetails';
import { useMarketContext } from '../../context/MarketContext';

import fulllogo from '../../static/images/shares/fulllogo.svg';
import searchIconn from '../../static/images/malls/search.svg';
import { useAppContextDetails } from '../../context/AppContext';

function MarketsPage({MarketTableShares}) {
  const history = useHistory();
  const { assetClassName, coin } = useParams();
  const [assetClass, setAssetClass] = useState(MARKET_ASSET_CLASSES[0]);
  const [index, setIndex] = useState(2);
  const { email, sidebarCollapse } = useContext(BankContext);
  const { streamOpen, setStreamOpen } = useMarketContext();

  const [search, setSearch] = useState('');
  const [selectedSidebarMenu, setSelectedSidebarMenu] = useState('Bonds');
  const ref = useRef();

  const {
    marketSearch,
    setMarketSearch,
    coinSelected,
    setCoinSelected,
    appColorCode,
  } = useAppContextDetails();

  const { data: shareToken } = useShareTokensDetail(coin);

  useEffect(() => {
    const classSelected = MARKET_ASSET_CLASSES.filter(
      (asCls) => asCls.name === assetClassName
    );
    if (classSelected[0]) setAssetClass(classSelected[0]);
  }, [assetClassName]);

  useEffect(() => {
    if (assetClassName === 'shares' && shareToken?.token_profile_data) {
      setCoinSelected(shareToken?.token_profile_data);
    }
  }, [coin, shareToken, assetClassName]);

  useEffect(() => {
    ref.current.addEventListener('scroll', scrollHandle);
    return () => {
      ref.current.removeEventListener('scroll', scrollHandle);
    };
  }, []);
  const [active, setActive] = useState(false);
  const scrollHandle = (event) => {
    setActive(
      Boolean(
        event?.path[0]?.scrollTop >=
          event?.path[0].childNodes[1].childNodes[0].childNodes[1]?.offsetTop
      )
    );
  };

  let pathName = window?.location?.pathname?.split('/');

  const filteredData = (item) =>
    item
      ? Object.values(item)
          ?.join(' ')
          ?.toLowerCase()
          ?.includes(marketSearch.toLowerCase())
      : [];

  function selectTable() {
    // console.log(assetClass?.name, 'assetClassname', assetClass);
    switch (assetClass?.name) {
      case 'crypto':
        return (
          <MarketTableCrypto
            streamOpen={streamOpen}
            setCoinSelected={setCoinSelected}
            assetClass={assetClass}
            active={active}
            search={search}
          />
        );
      case 'fiat':
        return (
          <MarketTableForex
            streamOpen={streamOpen}
            setCoinSelected={setCoinSelected}
            assetClass={assetClass}
            search={search}
          />
        );
      case 'shares':
        return (
          <MarketTableShares
            streamOpen={streamOpen}
            setCoinSelected={setCoinSelected}
            assetClass={assetClass}
            search={search}
          />
        );
      case 'bonds':
        return (
          <MarketTableBonds
            streamOpen={streamOpen}
            setCoinSelected={setCoinSelected}
            assetClass={assetClass}
            active={active}
            search={search}
          />
        );
      case 'moneyMarkets':
        return (
          <MarketTableMoneyMarkets
            streamOpen={streamOpen}
            setCoinSelected={setCoinSelected}
            assetClass={assetClass}
            active={active}
            search={search}
          />
        );

      default:
        return '';
    }
  }

  const [loginModalOpen, setLoginModalOpen] = useState(false);

  if (!email) {
    history.push('/');
  }

  return (
    <Layout active="markets" className={classNames.layoutManagement} hideFooter>
      <div className={classNames.marketsPage}>
        <div className={classNames.pageWrap} ref={ref}>
          {/* <div className={classNames.assetClassWrap}>
            <div className={classNames.profileSearch}>
              <label className={classNames.assetFinder}>
                <img src={searchIcon} alt="Search" />
                <input
                  type="text"
                  placeholder="Asset Finder"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </label>
            </div>
            <div className={classNames.assetClass}>
              {MARKET_ASSET_CLASSES.sort(GetSortOrder('marketOrder')).map(
                (assetClassCard, i) => (
                  <div
                    key={i}
                    className={`${classNames.assetClassCard} ${
                      classNames[assetClassCard === assetClass]
                    } ${classNames}`}
                    onClick={() => {
                      if (assetClassCard.marketEnable) {
                        setAssetClass(assetClassCard);
                        history.push(`/market/${assetClassCard.name}`);
                      }
                    }}
                  >
                    <img
                      src={assetClassCard.icon}
                      alt=""
                      className={classNames.icon}
                    />
                  </div>
                )
              )}
            </div>
            <div
              className={`${classNames.streamOpen} ${
                classNames[Boolean(streamOpen)]
              }`}
              onClick={() => setStreamOpen((prev) => !prev)}
            >
              {streamOpen ? (
                <svg
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.6317 9L17.6617 1.96993C18.1127 1.51912 18.1128 0.789171 17.6618 0.338256C17.2109 -0.112694 16.481 -0.112799 16.0301 0.338221L9 7.36829L1.96993 0.338256C1.51913 -0.112658 0.78914 -0.112799 0.338226 0.338186C-0.112724 0.789101 -0.112759 1.51895 0.338226 1.9699L7.36822 9L0.33819 16.03C-0.111705 16.4799 -0.111705 17.2119 0.33819 17.6617C0.78907 18.1127 1.51898 18.1128 1.9699 17.6617L8.99993 10.6316L16.03 17.6617C16.4808 18.1126 17.2108 18.1127 17.6616 17.6617C18.1126 17.2108 18.1127 16.4809 17.6617 16.0299L10.6317 9Z"
                    fill="#1A2663"
                  />
                  <path
                    d="M10.6317 9L17.6617 1.96993C18.1127 1.51912 18.1128 0.789171 17.6618 0.338256C17.2109 -0.112693 16.481 -0.112799 16.0301 0.338221L9 7.36825V10.6317L16.03 17.6618C16.4808 18.1127 17.2108 18.1128 17.6617 17.6618C18.1127 17.2109 18.1128 16.481 17.6617 16.03L10.6317 9Z"
                    fill="#1A2663"
                  />
                </svg>
              ) : (
                <img src={tradeStream} alt="" />
              )}
            </div>
          </div> */}
          <div className={classNames.settingsNavbar}>
            <div
              className={classNames.settingsIcon}
              onClick={() => {
                setCoinSelected('');
                history.push('/shares');
              }}
            >
              <img src={fulllogo} alt="" />
            </div>
            <div className={classNames.searchSettings}>
              {/* <div className="all">
              <p>All Categories</p>
              <div className="arroww" style={{ cursor: 'pointer' }}>
                <img src={ddown} alt="" />
              </div>
            </div> */}
              <div className={classNames.im}>
                <input
                  type="text"
                  placeholder="Im Looking For..."
                  onChange={(event) => setMarketSearch(event?.target?.value)}
                />
                <div
                  className={classNames.arroww}
                  style={{ cursor: 'pointer' }}
                >
                  <img src={searchIconn} alt="" />
                </div>
              </div>
            </div>
            {/* <div className={classNames.settingsListBtn} style={{background: appColorCode}}>List Your Startup</div> */}
          </div>

          <div className={classNames.pageContent}>
            {coinSelected?.coinSymbol || coin ? (
              <MarketAssetDetail
                coin={coinSelected}
                coinSymbol={coin}
                onClose={() => {
                  setCoinSelected();
                  history.push(`/market/${assetClass.name}`);
                }}
                assetClass={assetClass}
                streamOpen={streamOpen}
                setStreamOpen={setStreamOpen}
              />
            ) : (
              <div className={classNames.tableArea}>{selectTable()}</div>
            )}
            <TerminalsSidebar
              shareToken={shareToken}
              streamOpen={streamOpen}
              setStreamOpen={setStreamOpen}
            />
          </div>
        </div>
        <div
          className={`${classNames.sidebarArea} ${
            classNames[Boolean(streamOpen)]
          }`}
          style={{ maxHeight: '100%' }}
        >
          <div
            className={classNames.sidebarContent}
            style={{ maxHeight: '100%' }}
          >
            {assetClassName === 'shares' && coin ? (
              <TerminalsSidebar
                shareToken={shareToken}
                streamOpen={streamOpen}
                setStreamOpen={setStreamOpen}
              />
            ) : assetClassName === 'bonds' &&
              selectedSidebarMenu === 'Bonds' ? (
              <BondContractDetails
                index={index}
                setIndex={setIndex}
                setSelectedSidebarMenu={setSelectedSidebarMenu}
              />
            ) : (
              <img
                className={classNames.fullLogo}
                src={SIDEBAR_FOOTERS[index].fullLogo}
                alt=""
              />
            )}
          </div>

          {(assetClassName === 'shares' && coin) ||
          (assetClassName === 'bonds' && selectedSidebarMenu === 'Bonds') ? (
            ''
          ) : (
            <div className={classNames.sidebarFooter}>
              {SIDEBAR_FOOTERS.map((menu, i) => (
                <div
                  className={`${classNames.footerMenu} ${
                    classNames[i === index]
                  } ${menu.disable && classNames.disable}`}
                  onClick={() => {
                    !menu.disable && setIndex(i);
                    setSelectedSidebarMenu(menu.label);
                  }}
                >
                  <img src={menu.icon} alt="" />
                  <span>{menu.label}</span>
                </div>
              ))}
            </div>
          )}
        </div>
        {loginModalOpen && (
          <LoginModal
            onClose={() => setLoginModalOpen(false)}
            onSuccess={() => setLoginModalOpen(false)}
          />
        )}
      </div>
    </Layout>
  );
}

export default MarketsPage;
