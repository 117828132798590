import React, { useState, useEffect, useContext } from 'react';
import { useAppContextDetails } from '../../../context/AppContext';
import QRCode from 'qrcode.react';
import axios from 'axios';

import pasteIcon from '../../../static/images/paste.svg';
import { BankContext } from '../../../context/Context';

const WithdrawCryptoStepOne = ({
  coinData,
  coinAddress,
  hashSuccessData,
  setHashSuccessData,
  addressInput,
  setAddressInput,
}) => {
  const { selectedCurrency, appCode, setBuySellStep, setActionFlow } =
    useAppContextDetails();
  const { email, token, profileId } = useContext(BankContext);
  const [loading, setLoading] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const onPasteClick = async () => {
    const copiedText = await navigator.clipboard.readText();
    setAddressInput(copiedText.trim());
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: window.innerHeight - 100,
      }}
    >
      <div>
        <div className="breadcrumb">
          <span onClick={(e) => setActionFlow(null)}>Actions</span>
          &nbsp;&nbsp;{`>`}&nbsp;&nbsp;
          <span style={{ fontWeight: 700 }}>
            Enter {selectedCurrency?.coinSymbol} Address
          </span>
        </div>
        <div style={{ padding: '0px 22px' }}>
          <div style={{ paddingTop: '40px' }}>
            <div
              style={{
                fontWeight: 'bold',
                fontSize: '22px',
                paddingBottom: '31px',
              }}
            >
              Step 1: Enter Address
            </div>
            <div>
              Enter The External {selectedCurrency.coinSymbol} Address That You
              Want The Coins Sent To
            </div>

            <input
              style={{ width: '100%' }}
              className="addressInput1"
              placeholder="Enter Address..."
              value={addressInput}
              onChange={(e) => setAddressInput(e.target.value)}
            />
            {errorMessage ? (
              <div
                style={{
                  color: 'red',
                  fontWeight: '600',
                  paddingBottom: '10px',
                }}
              >
                {errorMessage}
              </div>
            ) : (
              ''
            )}
            <div
              style={{ display: 'flex', alignItems: 'center' }}
              onClick={onPasteClick}
            >
              <img src={pasteIcon} style={{ width: '20px', height: '20px' }} />
              <div style={{ paddingLeft: '10px' }}>
                Click To Paste Clipboard
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ padding: '0px 22px' }}>
        <div className="doubleButton">
          <div className="copyButton" onClick={(e) => setActionFlow(null)}>
            <div style={{ color: '#464B4E', fontWeight: 'bold' }}>Back</div>
          </div>
          <div
            className="submitButton"
            style={{ background: '#464B4E' }}
            onClick={(e) => setActionFlow('WithdrawCryptoStepTwo')}
          >
            <div style={{ color: 'white', fontWeight: 'bold' }}>Proceed</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithdrawCryptoStepOne;
