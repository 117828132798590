import { faCaretLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import { BankContext } from '../../../context/Context';
import { FormatCurrency, FormatNumber } from '../../../utils/FunctionTools';

function SlotCard({ slot, i, intrestRes }) {
  const [balances, setBalances] = useState([]);
  const [balance, setBalance] = useState();
  const [dropOpen, setDropOpen] = useState(false);
  useEffect(() => {
    Axios.post(
      'https://comms.globalxchange.io/coin/iced/liquid/icetray/interest/simulator',
      {
        coin: 'BTC',
        slotName: slot.name,
      }
    ).then(({ data }) => {
      if (data.status) {
        setBalances(data.finalInterestData);
      }
    });
  }, []);
  const { defaultPrecission } = useContext(BankContext);
  return (
    <div className="cardItems">
      <div className="rowOne">
        <div className="tier">{slot?.name?.replace('slot', 'Tier ')}</div>
        <div className="value">
          {FormatCurrency(slot.amount, 'BTC', defaultPrecission)}
        </div>
        <div className="value">
          {FormatCurrency(
            intrestRes?.slots[i + 1]?.amount,
            'BTC',
            defaultPrecission
          )}
        </div>
      </div>
      <div className="rowTwo">
        <div className="valueLabel">
          <div className="value">Fixed</div>
          <div className="label">
            {FormatNumber(
              slot.name === 'slot1' && intrestRes?.initialAdd_percentage * 100,
              2
            )}
            %
          </div>
        </div>
        <div className="valueLabel">
          <div className="value">Variable</div>
          <div className="label">
            {FormatNumber(intrestRes?.multiplier_value, 2)}%
          </div>
        </div>
        <div className="valueLabel">
          <div className="value">Acceleration</div>
          <div className="label">
            {FormatNumber(intrestRes?.acceleration, 2)}%
          </div>
        </div>
      </div>
      <div className="rowThree">
        <div className="asset" onClick={() => setDropOpen(!dropOpen)}>
          {balance ? balance?.coin : 'Asset'}
          {dropOpen && (
            <div className="list">
              {balances.map((coin) => (
                <div className="listItem" onClick={() => setBalance(coin)}>
                  {coin.coin}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="finalRate">
          {balance ? (
            <>
              <div className="rate">
                <span className="label">Base Rate</span>
                <span>{FormatNumber(balance?.base_interest, 4)}</span>
              </div>
              <div className="rate">
                <span className="label">Trust Rate</span>
                <span>{FormatNumber(balance?.final_interest, 4)}</span>
              </div>
            </>
          ) : (
            <>
              <span>
                <FontAwesomeIcon icon={faCaretLeft} />
              </span>
              <span>Please Select An Asset To See The Trust Rate</span>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default SlotCard;
