import React, { useContext } from 'react';
import './transferAction.scss';
import { useCoinContextData } from '../../../context/CoinContext';
import { useLoadBondEarningsBalance, useLoadMMEarningsBalance } from './api';
import { BankContext } from '../../../context/Context';
import { useAppContextDetails } from '../../../context/AppContext';

export const FromAssetEarnings = () => {
  const { email } = useContext(BankContext);
  const { selectedCoin, transferAmount, setTransferAmount, transferMethod } =
    useCoinContextData();
  const { appCode } = useAppContextDetails();
  const { data: bondEarningsResponse } = useLoadBondEarningsBalance(
    email,
    transferMethod
  );
  const { data: moneyMarketEarningsResponse } = useLoadMMEarningsBalance(
    email,
    appCode,
    transferMethod
  );
  const isTransferViaBond = transferMethod === 'bonds';

  const bondsBalances = bondEarningsResponse?.data?.result?.[0]?.balances;
  const moneyMarketBalances =
    moneyMarketEarningsResponse?.data?.result?.[0]?.balances?.[0]
      ?.liquid_balances;
  const availableBalance = isTransferViaBond
    ? bondsBalances
    : moneyMarketBalances;

  const currentCoinData = availableBalance?.filter(
    (coin) => coin?.coinSymbol === selectedCoin.coinSymbol
  );

  return (
    <>
      <div className="sidebar-menu mt-3">
        <div className="d-flex flex-column transfer-amount-wrapper">
          <div className="d-flex flex-row justify-content-between transfer-amount-labels">
            <h6>Sending Amount</h6>
            <p>
              Balance: {currentCoinData?.[0]?.coinValue?.toFixed(4) ?? '0.00'}
            </p>
          </div>
          <div>
            <div className="transfer-sending-amount-box">
              <div className="div-amount-input">
                <input
                  className="transfer-input"
                  placeholder="0.00"
                  key="add"
                  id="add"
                  type="number"
                  value={transferAmount}
                  onChange={(e) => {
                    const { value } = e.target;
                    setTransferAmount(value);
                  }}
                />
                <div className="transfrom-from-coin" key="add">
                  <img
                    className="coinimgsmall"
                    src={selectedCoin?.coinImage}
                    alt=""
                  />
                  <p className="coinsymbolsmall">{selectedCoin?.coinSymbol}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
