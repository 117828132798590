import React from 'react';
import { updateScroll } from '../../../config/constants';
import { useAppContextDetails } from '../../../context/AppContext';
import { useCoinContextData } from '../../../context/CoinContext';

export const AddBankAccountFooter = ({ DefaultFooter }) => {
  const { appColorCode } = useAppContextDetails();
  const {
    addBankAccountStep,
    setAddBankAccountStep,
    nameOnBankAccount,
    bankingPhoneNumber,
    beneficiaryAddress,
    upiId,
    upiPhoneNumber,
    setEnableAccountCreation,
    addBankAccountLoading,
  } = useCoinContextData();

  const isAcHolderDetailsAvailable =
    nameOnBankAccount && bankingPhoneNumber && beneficiaryAddress;
  const isBankDetailsAvailable = upiId && upiPhoneNumber;

  if (addBankAccountStep === 3) {
    return (
      <div className="transfer-footer">
        <div
          className="back1"
          key="back1"
          onClick={() => {
            setAddBankAccountStep(2);
            updateScroll();
          }}
        >
          <h6>Back</h6>
        </div>
        <div
          className={`d-flex align-items-baseline justify-content-around ${
            isAcHolderDetailsAvailable ? 'nextbtn' : 'nextbtn1'
          }`}
          key="nextbtn"
          style={{
            backgroundColor: appColorCode,
            opacity: isAcHolderDetailsAvailable ? 1 : 0.25,
            color: isAcHolderDetailsAvailable ? 'white' : '',
            cursor: isAcHolderDetailsAvailable && 'pointer',
            pointerEvents: !isAcHolderDetailsAvailable && 'none',
          }}
          onClick={() => {
            setAddBankAccountStep(4);
            updateScroll();
          }}
        >
          <h6>Next</h6>
        </div>
      </div>
    );
  } else if (addBankAccountStep === 4) {
    return (
      <div className="transfer-footer">
        <div
          className="back1"
          key="back1"
          onClick={() => {
            setAddBankAccountStep(3);
            updateScroll();
          }}
        >
          <h6>Back</h6>
        </div>
        <div
          className={`d-flex align-items-baseline justify-content-around ${
            isBankDetailsAvailable ? 'nextbtn' : 'nextbtn1'
          }`}
          key="nextbtn"
          style={{
            backgroundColor: appColorCode,
            opacity: addBankAccountLoading
              ? 0.25
              : isBankDetailsAvailable
              ? 1
              : 0.25,
            color: isBankDetailsAvailable ? 'white' : '',
            cursor: isBankDetailsAvailable && 'pointer',
            pointerEvents:
              (!isBankDetailsAvailable || addBankAccountLoading) && 'none',
          }}
          onClick={() => {
            setEnableAccountCreation(true);
          }}
        >
          <h6>Add</h6>
        </div>
      </div>
    );
  }
  return DefaultFooter;
};
