import React, { useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { BankContext } from '../../context/Context';
import Axios from 'axios';
import { useEffect } from 'react';

function CentralizedTrust({ onLogin }) {
  const [showPass, setShowPass] = useState(false);
  const { email, tostShowOn, userLoginHandler } = useContext(BankContext);
  const [emailid, setEmailid] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [trustName, setTrustName] = useState('');
  useEffect(() => {
    Axios.post('https://comms.globalxchange.io/gxb/apps/register/user', {
      email: email, // user email
      app_code: 'icetray', // app_code
      fromAppCreation: true,
    });
  }, []);
  const loginvalidate = (e) => {
    e && e.preventDefault();
    // if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(emailid)) {
    setLoading(true);
    Axios.post('https://gxauth.apimachine.com/gx/user/login', {
      email: emailid,
      password,
    })
      .then((response) => {
        const { data } = response;
        if (data.status) {
          userLoginHandler(
            emailid,
            data.accessToken,
            data.idToken,
            data.deviceKey
          );
          Axios.post('https://comms.globalxchange.io/gxb/apps/register/user', {
            email: emailid, // user email
            app_code: 'icetray', // app_code
            fromAppCreation: true,
          });
          setPassword('');
        } else if (data.mfa) {
          // setMfaEnabled(true);
        } else {
          tostShowOn(data.message);
        }
      })
      .catch((error) => {
        tostShowOn(error.message ? error.message : 'Some Thing Went Wrong!');
      })
      .finally(() => {
        setLoading(false);
      });
    // } else {
    //   tostShowOn('Enter Valid EmailId');
    // }
  };
  return (
    <div className="login-enter">
      <h2 className="login-text">Centralized</h2>
      <div className="gx-account-text">
        It Becomes Attached To Your GX Account
      </div>
      {email ? (
        <form
          className="login-form"
          onSubmit={(e) => {
            e.preventDefault();
            onLogin();
          }}
        >
          <div className="group">
            <input type="text" name="email" required />
            <span className="highlight" />
            <label>Name Your Trust</label>
          </div>
          <div className="group">
            <button type="submit" className="btn-primary-col">
              New Defan Trust
            </button>
          </div>
        </form>
      ) : (
        <form className="login-form" onSubmit={loginvalidate}>
          <div className="group">
            <input
              type="text"
              name="email"
              required
              value={emailid}
              onChange={(e) => setEmailid(e.target.value)}
            />
            <span className="highlight" />
            <label>USERNAME</label>
          </div>
          <div className="group">
            <input
              type={showPass ? 'text' : 'password'}
              name="password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <span className="highlight" />
            <FontAwesomeIcon
              onClick={() => setShowPass(!showPass)}
              className="eye"
              icon={showPass ? faEyeSlash : faEye}
            />
            <label>PASSWORD</label>
          </div>
          <div className="group">
            <button type="submit" className="btn-primary-col">
              New Defan Trust
            </button>
          </div>
        </form>
      )}
    </div>
  );
}

export default CentralizedTrust;
