import React, { useRef, useContext, Fragment } from 'react';
import classNames from '../../../pages/VaultsPage/vaultsPage.module.scss';
import Skeleton from 'react-loading-skeleton';
import { VaultPageContext } from '../../../context/VaultPageContext';
import Scrollbars from 'react-custom-scrollbars';
import moment from 'moment';
import { YesterdayToday } from '../../../utils/FunctionTools';
import VaultItemForexCrypto from './VaultShareCtypo';

function VaultTransactionsShare({ SLoading, shareTokenTranscation }) {
  //   const { txnListFxCrypto: txnList, txnListLoadingFxCrypto: txnListLoading } =
  //     useContext(VaultPageContext);
  const date = useRef();

  // console.log('zxcef', shareTokenTranscation);
  return (
    <div
      style={{
        width: '100%',
        height: 'calc(100vh - 12rem)',
      }}
    >
      <Scrollbars className={classNames.vaultsView}>
        {SLoading
          ? Array(3)
              .fill('')
              .map((_, i) => (
                <>
                  <Skeleton className={classNames.day} width={200} key={i} />
                  {Array(4)
                    .fill('')
                    .map((_, i) => (
                      <div className={classNames.vaultItmWrap}>
                        <div className={classNames.vaultsItm}>
                          <Skeleton className={classNames.img} />
                          <div className={classNames.nameDate}>
                            <Skeleton className={classNames.name} width={350} />
                            <Skeleton className={classNames.date} width={300} />
                          </div>
                          <div className={classNames.credit}>
                            <Skeleton className={classNames.value} width={80} />
                          </div>
                          <div className={classNames.debit}>
                            <Skeleton className={classNames.value} width={80} />
                          </div>
                          <div className={classNames.balance}>
                            <Skeleton className={classNames.value} width={80} />
                          </div>
                        </div>
                      </div>
                    ))}
                </>
              ))
          : shareTokenTranscation?.map((txn) => {
              function sameDay() {
                if (moment(txn.timestamp).format('MMDDYYYY') === date.current) {
                  return <></>;
                } else {
                  date.current = moment(txn.timestamp).format('MMDDYYYY');
                  return (
                    <div className={classNames.day}>
                      {YesterdayToday(txn.timestamp)}
                    </div>
                  );
                }
              }
              return (
                <Fragment key={txn._id}>
                  {sameDay()}
                  <VaultItemForexCrypto key={txn._id} txn={txn} />
                </Fragment>
              );
            })}
      </Scrollbars>
    </div>
  );
}

export default VaultTransactionsShare;
