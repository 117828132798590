import React from 'react';
import SignupSetName from './SignupFormSteps/SignupSetName';
import SignupSetUsernameEmail from './SignupFormSteps/SignupSetUsernameEmail';
import SignupSetPassword from './SignupFormSteps/SignupSetPassword';
import SignupConfirmPassword from './SignupFormSteps/SignupConfirmPassword';
import SignupVerifyOtp from './SignupFormSteps/SignupVerifyOtp';
import LoadingAnim from '../LoadingAnim/LoadingAnim';

function GetStartedSignupForm({
  setStepName,
  logo,
  isValid,
  fName,
  lName,
  userName,
  setFName,
  setLName,
  customEmail,
  mailId,
  setUserName,
  password,
  setPassword,
  signupStep,
  setCustomEmail,
  setMailId,
  setSignupStep,
  confirmPassword,
  setConfirmPassword,
  pin,
  setPin,
  createInbox,
  resendMail,
  setResendMail,
  verifyEmail,
  setPasswordReqModal,
  now,
  setNotSeeing,
  resendMailNow,
}) {
  function getStep() {
    switch (signupStep) {
      case 1:
        return (
          <SignupSetUsernameEmail
            setStepName={setStepName}
            logo={logo}
            isValid={isValid}
            userName={userName}
            mailId={mailId}
            setUserName={setUserName}
            setMailId={setMailId}
            setSignupStep={setSignupStep}
            customEmail={customEmail}
            setCustomEmail={setCustomEmail}
          />
        );
      case 2:
        return (
          <SignupSetPassword
            logo={logo}
            isValid={isValid}
            password={password}
            setPassword={setPassword}
            setSignupStep={setSignupStep}
            createInbox={createInbox}
            setPasswordReqModal={setPasswordReqModal}
          />
        );
      case 3:
        return (
          <SignupConfirmPassword
            logo={logo}
            isValid={isValid}
            confirmPassword={confirmPassword}
            setConfirmPassword={setConfirmPassword}
            setSignupStep={setSignupStep}
          />
        );
      case 4:
        return (
          <SignupVerifyOtp
            logo={logo}
            setSignupStep={setSignupStep}
            pin={pin}
            setPin={setPin}
            resendMail={resendMail}
            setResendMail={setResendMail}
            verifyEmail={verifyEmail}
            isValid={isValid}
            mailId={mailId}
            customEmail={customEmail}
            now={now}
            setNotSeeing={setNotSeeing}
            resendMailNow={resendMailNow}
          />
        );
      case 5:
        return (
          <div className="stepWrapper stepForm">
            <LoadingAnim />
          </div>
        );
      default:
        return (
          <SignupSetName
            setStepName={setStepName}
            logo={logo}
            fName={fName}
            lName={lName}
            setFName={setFName}
            setLName={setLName}
            setSignupStep={setSignupStep}
          />
        );
    }
  }
  return <>{getStep()}</>;
}

export default GetStartedSignupForm;
