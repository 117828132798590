import React, { useState, useContext } from 'react';
import { useCoinContextData } from '../../../context/CoinContext';
import { useLoadBondEarningsBalance, useLoadMMEarningsBalance } from './api';
import { BankContext } from '../../../context/Context';
import { useAppContextDetails } from '../../../context/AppContext';

const UpdatedAction = () => {
  const { email } = useContext(BankContext);
  const {
    selectedCoin,
    transferAmount,
    setTransferAmount,
    transferMethod,
    setCoinActionEnabled,
    setSelectedCoin,
    setCoinAction,
    setTransferActionStep,
  } = useCoinContextData();
  const { appCode } = useAppContextDetails();
  const { data: bondEarningsResponse } = useLoadBondEarningsBalance(
    email,
    transferMethod
  );
  const { data: moneyMarketEarningsResponse } = useLoadMMEarningsBalance(
    email,
    appCode,
    transferMethod
  );
  const isTransferViaBond = transferMethod === 'bonds';

  const bondsBalances = bondEarningsResponse?.data?.result?.[0]?.balances;
  const moneyMarketBalances =
    moneyMarketEarningsResponse?.data?.result?.[0]?.balances?.[0]
      ?.liquid_balances;
  const availableBalance = isTransferViaBond
    ? bondsBalances
    : moneyMarketBalances;

  const currentCoinData = availableBalance?.filter(
    (coin) => coin?.coinSymbol === selectedCoin.coinSymbol
  );

  const handleClick = () => {
    // console.log(selectedCoin, 'selectedCoin');
    setTransferAmount(selectedCoin?.coinValue?.toFixed(2));
  }

  const [step, setStep] = useState(1);

  return (
    <div
      style={{
        width: '100%',
        height: '97%',
        position: 'relative',
      }}
    >
      {step === 1 && (
        <>
          <div
            style={{
              width: '100%',
              padding: '0 1.85rem',
              display: 'flex',
              flexDirection: 'column',
              height: '8rem',
              justifyContent: 'center',
              borderBottom: '0.5px solid #e7e7e7',
              cursor: 'pointer',
            }}
            onClick={() => {
              setStep(2);
            }}
          >
            <div style={{ fontSize: '1.2rem', fontWeight: '500' }}>
              Transfer
            </div>
            <div style={{ fontSize: '0.8rem' }}>
              Move Earnings To Liquid Vault
            </div>
          </div>
          <div
            style={{
              position: 'absolute',
              bottom: '3%',
              width: '100%',
              padding: '0 1.85rem',
            }}
          >
            <div
              style={{
                background: '#FF5E5E',
                fontSize: '1rem',
                fontWeight: '500',
                color: 'white',
                cursor: 'pointer',
                height: '3.5rem',
                borderRadius: '35px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onClick={() => {
                setCoinActionEnabled(false);
                setCoinAction(['Menu']);
                setSelectedCoin('');
              }}
            >
              Close
            </div>
          </div>
        </>
      )}

      {step === 2 && (
        <>
          <div
            style={{
              width: '100%',
              height: '4rem',
              padding: '0 1.85rem',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            Enter Transfer Amount
          </div>
          <div
            style={{
              width: '100%',
              height: '7rem',
              padding: '0 1.85rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              borderTop: '0.5px solid #E5E5E5',
              borderBottom: '0.5px solid #E5E5E5',
            }}
          >
            <input
              type="number"
              placeholder="0.00"
              value={transferAmount}
              onChange={(e) => {
                const { value } = e.target;
                setTransferAmount(value);
              }}
              style={{
                width: '70%',
                border: 'none',
                height: '100%',
                fontSize: '1.5rem',
                fontWeight: '600',
              }}
            />
            <div
              style={{
                padding: '0.7rem 2rem',
                border: '1px solid #EDEDED',
                borderRadius: '35px',
                fontWeight: '600',
                cursor: 'pointer',
              }}
              onClick={() => {
                handleClick();
              }}
            >
              100%
            </div>
          </div>

          <div
            style={{
              position: 'absolute',
              bottom: '11rem',
              borderRadius: '35px',
              fontWeight: '500',
              cursor: 'pointer',
              display: 'flex',
              width: '90%',
              padding: '0.75rem 2.2rem',
              color: '#fff',
              justifyContent: 'center',
              background: '#78D0B1',
              left: '5%',
            }}
            onClick={() => {
              setTransferActionStep(6);
            }}
          >
            Initiate Transfer
          </div>

          <div
            style={{
              position: 'absolute',
              bottom: '0',
              width: '100%',
              height: '10rem',
              padding: '1.5rem',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              borderTop: '0.5px solid #E5E5E5',
            }}
          >
            <div
              style={{
                display: 'flex',
                width: '100%',
                gap: '2%',
              }}
            >
              <div
                style={{
                  borderRadius: '35px',
                  fontWeight: '500',
                  border: '1px solid #E5E5E5',
                  width: '48%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                  padding: '0.75rem 2.2rem',
                }}
                onClick={() => {
                  setStep(1);
                  setTransferAmount('');
                }}
              >
                Back
              </div>
              <div
                style={{
                  background: 'rgb(255, 94, 94)',
                  borderRadius: '35px',
                  fontWeight: '500',
                  width: '48%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                  padding: '0.75rem 2.2rem',
                  color: '#fff',
                }}
                onClick={() => {
                  setCoinActionEnabled(false);
                  setCoinAction(['Menu']);
                  setSelectedCoin('');
                  setStep(1);
                  setTransferAmount('');
                }}
              >
                Close
              </div>
            </div>
            <div
              style={{
                background: 'linear-gradient(270deg,#81cbd7,#3d4394)',
                borderRadius: '35px',
                fontWeight: '500',
                cursor: 'pointer',
                display: 'flex',
                width: '100%',
                padding: '0.75rem 2.2rem',
                color: '#fff',
                justifyContent: 'center',
              }}
            >
              {selectedCoin?.coinSymbol} Actions
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default UpdatedAction;
