import React, { createContext, useState, useEffect, useContext } from 'react';
import Axios from 'axios';
import { BankContext } from './Context';

export const ChatContext = createContext();
function ChatContextProvider({ children }) {
  const { email, chatOn, setChatOn } = useContext(BankContext);
  const [trustDepositOpen, setTrustDepositOpen] = useState(false);
  const [defiTrustProfileId, setDefiTrustProfileId] = useState('');
  useEffect(() => {
    if (email) {
      Axios.get(
        `https://comms.globalxchange.io/user/details/get?email=${email}`
      ).then(({ data }) => {
        if (data.status) {
          const profileId = data.user.icetray_profile_id;
          setDefiTrustProfileId(profileId);
        }
      });
    }
  }, []);

  const [chatPathData, setChatPathData] = useState('');

  useEffect(() => {
    if (!chatOn) {
      setChatPathData('');
    }
  }, [chatOn]);

  const [tabSelected, setTabSelected] = useState();
  useEffect(() => {
    tabSelected &&
      setTimeout(() => {
        setTabSelected();
      }, 1000);
  }, [tabSelected]);

  return (
    <ChatContext.Provider
      value={{
        chatOn,
        setChatOn,
        trustDepositOpen,
        setTrustDepositOpen,
        defiTrustProfileId,
        setDefiTrustProfileId,
        chatPathData,
        setChatPathData,
        tabSelected,
        setTabSelected,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
}

export default ChatContextProvider;
