import React from 'react';
import CreateDefiTrust from './CreateDefiTrust';

function CreateDefiTrustWrapper({ setOpenDefiLogin, onLogin }) {
  return (
    <div className="createDefiTrustWrapper">
      <div className="overlayTrust" onClick={() => setOpenDefiLogin(false)} />
      <CreateDefiTrust onLogin={onLogin} />
    </div>
  );
}

export default CreateDefiTrustWrapper;
