import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';

function SignupConfirmPassword({
  logo,
  confirmPassword,
  setConfirmPassword,
  setSignupStep,
  isValid,
}) {
  const [eye, setEye] = useState(false);
  const ref = useRef();
  useEffect(() => {
    setTimeout(() => {
      ref && ref.current.focus();
    }, 200);
  }, []);
  return (
    <div className="stepWrapper stepForm">
      <img src={logo} alt="assetLogo" className="assetLogo" />
      <div className="stepDesc">Confirm Your Password</div>
      <div className={`group ${isValid.confirmPassword}`}>
        <input
          ref={ref}
          type={eye ? 'text' : 'password'}
          className="input password"
          placeholder="Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setSignupStep(4);
            }
          }}
        />
        <FontAwesomeIcon
          icon={eye ? faEye : faEyeSlash}
          className="eye"
          onClick={() => setEye(!eye)}
        />
      </div>
      <div className="btns">
        <div className="btnBig" onClick={() => setSignupStep(2)}>
          Change Password
        </div>
        <div
          className="btnNext"
          onClick={() => {
            setSignupStep(4);
          }}
        >
          Next
        </div>
      </div>
    </div>
  );
}

export default SignupConfirmPassword;
