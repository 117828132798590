import Cookies from 'js-cookie';
import { APP_USER_TOKEN } from '../../config';
import {
  generateAddressForBTC,
  generateAddressForEthToken,
  generateAddressForLTCOrDOGE,
  generateAddressForXRPOrTRX,
} from './api';

export const selectCoinToGenerateAddress = (
  coinSymbol,
  email,
  appCode,
  setIsLoading,
  ethToken,
  userProfileId,
  setIsGenerating
) => {
  setIsGenerating(true);
  const token = Cookies.get(APP_USER_TOKEN);
  const ethApiObj = {
    app_code: appCode,
    profile_id: userProfileId,
  };
  const btcApiObj = {
    email,
    app_code: appCode,
  };
  const tRXOrXRPApiObj = {
    email,
    app_code: appCode,
    coin: coinSymbol,
  };
  const ltcOrDogeApiObj = {
    email,
    token,
    app_code: appCode,
    coin: coinSymbol,
  };

  const handleApiCall = (apiCall) => {
    apiCall.then(() => {
      setIsLoading(true);
      setIsGenerating(false);
    });
  };

  if (ethToken) {
    handleApiCall(generateAddressForEthToken(ethApiObj));
  } else {
    switch (coinSymbol) {
      case 'ETH':
        handleApiCall(generateAddressForEthToken(ethApiObj));
      case 'BTC':
        handleApiCall(generateAddressForBTC(btcApiObj));
      case 'XRP':
      case 'TRX':
      case 'SOL':
        handleApiCall(generateAddressForXRPOrTRX(tRXOrXRPApiObj));
      case 'LTC':
      case 'DOGE':
        handleApiCall(generateAddressForLTCOrDOGE(ltcOrDogeApiObj));
      default:
    }
  }
};
