import axios from 'axios';

export const dividendVaultBalances = async (email, appcode) => {
  try {
    const data = await axios.get(
      `https://comms.globalxchange.io/coin/app/dividend/user/vault/balances/get?email=${email}&app_code=${appcode}`
    );
    return data;
  } catch (err) {
    console.error(err);
  }
};
