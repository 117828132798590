import React from 'react';
import Skeleton from 'react-loading-skeleton';
import classNames from './affiliate.module.scss';
import Layout from '../../Layout/Layout';
import affiliate from '../../static/images/sidebarFullLogos/affiliate.svg';
import appleDownload from '../../static/images/appleDownload.svg';
import androidDownload from '../../static/images/androidDownload.svg';
import { useLoadAffiliateAppData } from './api';

function AffiliatePageR() {
  const { data: affiliateResponseData, isLoading: isAffiliateResponseLoading } =
    useLoadAffiliateAppData();

  const iosLink = affiliateResponseData?.data?.logs?.[0]?.ios_app_link;
  const androidLink = affiliateResponseData?.data?.logs?.[0]?.android_app_link;

  return (
    <Layout active="react" className={classNames.affiliatePage} hideFooter>
      <div className={classNames.content}>
        <img src={affiliate} alt="Affiliate Logo" className={classNames.logo} />
        <div className="d-flex justify-content-between">
          {isAffiliateResponseLoading ? (
            <>
              <Skeleton
                className={`${classNames.loadingLinks} ${classNames.loadingLeftLink}`}
              />
              <Skeleton
                className={`${classNames.loadingLinks} ${classNames.loadingRightLink}`}
              />
            </>
          ) : (
            <>
              <img
                className={`${classNames.appleDownload} clickable-div`}
                src={appleDownload}
                onClick={() => window.open(iosLink, '_blank')}
              />
              <img
                className={`${classNames.androidDownload} clickable-div`}
                src={androidDownload}
                onClick={() => window.open(androidLink, '_blank')}
              />
            </>
          )}
        </div>
      </div>
      <div className={classNames.footer}>
        Go To&nbsp;
        <p onClick={() => window.open('https://affiliate.app', '_blank')}>
          Affiliate.App
        </p>
        &nbsp;To Start Earning Today
      </div>
    </Layout>
  );
}

export default AffiliatePageR;
