import React, { useState, useEffect, useContext } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import AssetTable from './AssetTable';
import fullScreenIcon from '../../../static/images/fullScreen.svg';
import fullScreenIconExit from '../../../static/images/fullScreenExit.svg';
import { BankContext } from '../../../context/Context';
import CurrencyDetailTable from './CurrencyDetailTable';

function CurrencyTable({
  coinList,
  searchTitle,
  coinToDetail,
  setCoinToDetail,
}) {
  const { setContentSideBar } = useContext(BankContext);
  const [fullScreen, setFullScreen] = useState(false);
  const [search, setSearch] = useState('');

  useEffect(() => {
    return () => {
      setContentSideBar({});
    };
  }, [setContentSideBar]);

  return (
    <div
      className={`assetPlatformTable ${Boolean(!coinToDetail)} ${
        fullScreen ? ' fullScreen' : ''
      }`}
    >
      <div className="assetTableControlls">
        <label className="searchWrapper">
          <input
            value={search}
            type="text"
            onChange={(e) => setSearch(e.target.value)}
            placeholder={`Search ${searchTitle}`}
          />
          <FontAwesomeIcon icon={faSearch} />
        </label>
        <img
          onClick={() => setFullScreen(!fullScreen)}
          className="fullIcon"
          src={fullScreen ? fullScreenIconExit : fullScreenIcon}
          alt=""
        />
      </div>
      {coinToDetail ? (
        <CurrencyDetailTable
          coinToDetail={coinToDetail}
          setCoinToDetail={setCoinToDetail}
        />
      ) : (
        <Scrollbars autoHide className="tableScrollWrapper">
          <AssetTable
            coinList={coinList}
            search={search}
            coinToDetail={coinToDetail}
            setCoinToDetail={setCoinToDetail}
          />
        </Scrollbars>
      )}
    </div>
  );
}

export default CurrencyTable;
