import React, { useState } from 'react';
import { useAppContextDetails } from '../../../context/AppContext';
import ViewAddressStepOne from './ViewAddressStepOne';

import './actionFlow.scss';
import ViewAddressStepTwo from './ViewAddressStepTwo';
import ViewAddressStepThree from './ViewAddressStepThree';
import WithdrawCryptoStepOne from './WithdrawCryptoStepOne';
import WithdrawCryptoStepTwo from './WithdrawCryptoStepTwo';
import WithdrawCryptoStepThree from './WithdrawCryptoStepThree';
import WithdrawCryptoStepFour from './WithdrawCryptoStepFour';
import WithdrawCryptoStepFive from './WithdrawCryptoStepFive';
import AddForexStepOne from './AddForexStepOne';
import AddForexStepTwo from './AddForexStepTwo';
import AddForexStepThree from './AddForexStepThree';
import AddForexStepFour from './AddForexStepFour';
import AddForexStepFive from './AddForexStepFive';
import AddForexStepSix from './AddForexStepSix';
import WithdrawForexStepOne from './WithdrawForexStepOne';
import WithdrawForexStepTwo from './WithdrawForexStepTwo';
import WithdrawForexStepThree from './WithdrawForexStepThree';
import BuyBondStepOne from './BuyBondStepOne';
import BuyBondStepTwo from './BuyBondStepTwo';
import BuyBondStepThree from './BuyBondStepThree';
import BuyBondStepFour from './BuyBondStepFour';

const ActionFlow = ({ flow }) => {
  const { actionFlow, setActionFlow, selectedCurrency, appCode } =
    useAppContextDetails();

  const [coinData, setCoinData] = useState(null);
  const [coinAddress, setCoinAddress] = useState(null);
  const [hashSuccessData, setHashSuccessData] = useState(null);

  const [addressInput, setAddressInput] = useState('');
  const [withdrawalDetails, setWithdrawalDetails] = useState(null);

  const [fromCurrency, setFromCurrency] = useState(null);
  const [country, setCountry] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [banker, setBanker] = useState(null);
  const [fromAmount, setFromAmount] = useState(null);
  const [confirmQuote, setConfirmQuote] = useState(null);
  const [updatedBalance, setUpdatedBalance] = useState(null);
  const [pathId, setPathId] = useState('');
  const [selectedAccount, setSelectedAccount] = useState(null);

  const [bondCount, setBondCount] = useState(0);
  const [payCoin, setPayCoin] = useState(null);

  const conditionalFlow = () => {
    if (actionFlow === 'View Address') {
      return (
        <ViewAddressStepOne
          coinData={coinData}
          setCoinData={setCoinData}
          coinAddress={coinAddress}
          setCoinAddress={setCoinAddress}
        />
      );
    } else if (actionFlow === 'Submit Hash') {
      return (
        <ViewAddressStepTwo
          coinData={coinData}
          coinAddress={coinAddress}
          hashSuccessData={hashSuccessData}
          setHashSuccessData={setHashSuccessData}
        />
      );
    } else if (actionFlow === 'Hash Success') {
      return (
        <ViewAddressStepThree
          coinData={coinData}
          hashSuccessData={hashSuccessData}
        />
      );
    } else if (actionFlow === 'Withdraw Crypto') {
      return (
        <WithdrawCryptoStepOne
          addressInput={addressInput}
          setAddressInput={setAddressInput}
        />
      );
    } else if (actionFlow === 'WithdrawCryptoStepTwo') {
      return (
        <WithdrawCryptoStepTwo
          addressInput={addressInput}
          setAddressInput={setAddressInput}
          withdrawalDetails={withdrawalDetails}
          setWithdrawalDetails={setWithdrawalDetails}
        />
      );
    } else if (actionFlow === 'WithdrawCryptoStepThree') {
      return (
        <WithdrawCryptoStepThree
          addressInput={addressInput}
          setAddressInput={setAddressInput}
          withdrawalDetails={withdrawalDetails}
          setWithdrawalDetails={setWithdrawalDetails}
        />
      );
    } else if (actionFlow === 'WithdrawCryptoStepFour') {
      return (
        <WithdrawCryptoStepFour
          addressInput={addressInput}
          setAddressInput={setAddressInput}
          withdrawalDetails={withdrawalDetails}
          setWithdrawalDetails={setWithdrawalDetails}
          pathId={pathId}
        />
      );
    } else if (actionFlow === 'WithdrawCryptoStepFive') {
      return (
        <WithdrawCryptoStepFive
          addressInput={addressInput}
          setAddressInput={setAddressInput}
          withdrawalDetails={withdrawalDetails}
          setWithdrawalDetails={setWithdrawalDetails}
        />
      );
    }

    // Add Forex
    else if (actionFlow === 'AddForexStepOne') {
      return (
        <AddForexStepOne
          fromCurrency={fromCurrency}
          setFromCurrency={setFromCurrency}
        />
      );
    } else if (actionFlow === 'AddForexStepTwo') {
      return (
        <AddForexStepTwo fromCurrency={fromCurrency} setCountry={setCountry} />
      );
    } else if (actionFlow === 'AddForexStepThree') {
      return (
        <AddForexStepThree
          fromCurrency={fromCurrency}
          country={country}
          setPaymentMethod={setPaymentMethod}
        />
      );
    } else if (actionFlow === 'AddForexStepFour') {
      return (
        <AddForexStepFour
          fromCurrency={fromCurrency}
          country={country}
          paymentMethod={paymentMethod}
          setBanker={setBanker}
        />
      );
    } else if (actionFlow === 'AddForexStepFive') {
      return (
        <AddForexStepFive
          fromCurrency={fromCurrency}
          country={country}
          paymentMethod={paymentMethod}
          banker={banker}
          fromAmount={fromAmount}
          setFromAmount={setFromAmount}
          setConfirmQuote={setConfirmQuote}
          setPathId={setPathId}
        />
      );
    } else if (actionFlow === 'AddForexStepSix') {
      return (
        <AddForexStepSix
          fromCurrency={fromCurrency}
          country={country}
          paymentMethod={paymentMethod}
          banker={banker}
          fromAmount={fromAmount}
          setFromAmount={setFromAmount}
          confirmQuote={confirmQuote}
          setUpdatedBalance={setUpdatedBalance}
          pathId={pathId}
        />
      );
    }

    //Withdraw Forex
    else if (actionFlow === 'WithdrawForexStepOne') {
      return (
        <WithdrawForexStepOne
          fromCurrency={fromCurrency}
          setFromCurrency={setFromCurrency}
        />
      );
    } else if (actionFlow === 'WithdrawForexStepTwo') {
      return (
        <WithdrawForexStepTwo
          fromCurrency={fromCurrency}
          setCountry={setCountry}
          setSelectedAccount={setSelectedAccount}
        />
      );
    } else if (actionFlow === 'WithdrawForexStepThree') {
      return (
        <WithdrawForexStepThree
          fromCurrency={fromCurrency}
          setCountry={setCountry}
          selectedAccount={selectedAccount}
          setBanker={setBanker}
        />
      );
    }

    //Buy Bond
    else if (actionFlow === 'BuyBondStepOne') {
      return (
        <BuyBondStepOne bondCount={bondCount} setBondCount={setBondCount} />
      );
    } else if (actionFlow === 'BuyBondStepTwo') {
      return (
        <BuyBondStepTwo
          bondCount={bondCount}
          setBondCount={setBondCount}
          setPayCoin={setPayCoin}
        />
      );
    } else if (actionFlow === 'BuyBondStepThree') {
      return (
        <BuyBondStepThree
          bondCount={bondCount}
          setBondCount={setBondCount}
          payCoin={payCoin}
        />
      );
    } else if (actionFlow === 'BuyBondStepFour') {
      return (
        <BuyBondStepFour
          bondCount={bondCount}
          setBondCount={setBondCount}
          payCoin={payCoin}
        />
      );
    }

    // else if (actionFlow === 'Withdraw Crypto') {
    //   return (
    //     <WithdrawCryptoStepFive
    //       addressInput={addressInput}
    //       setAddressInput={setAddressInput}
    //       withdrawalDetails={withdrawalDetails}
    //       setWithdrawalDetails={setWithdrawalDetails}
    //     />
    //   );
    // }
  };

  return <>{conditionalFlow()}</>;
};

export default ActionFlow;
