import React, { useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';

function TierComparison({ onSave, selectedSlot, slots, setSelectedSlot }) {
  const [selected, setSelected] = useState('slot0');
  return (
    <div className="tierComparison">
      <div className="ecTitle">Earnings Controller</div>
      <div className="timeRow">
        <div className="rowLbl">Current</div>
        <Scrollbars
          className="optListWpapper"
          renderView={(props) => <div {...props} className="optList" />}
          renderThumbHorizontal={() => <div />}
          renderThumbVertical={() => <div />}
        >
          <div className={`optItm true`}>
            {selectedSlot.replace('slot', 'Tier ')}
          </div>
        </Scrollbars>
      </div>
      <div className="tierRow">
        <div className="rowLbl">Reference</div>
        <Scrollbars
          className="optListWpapper"
          renderView={(props) => <div {...props} className="optList" />}
          renderThumbHorizontal={() => <div />}
          renderThumbVertical={() => <div />}
        >
          {slots?.map((slot, i) => (
            <div
              className={`optItm ${selectedSlot} ${slot.name} ${
                selected === slot.name
              }`}
              onClick={() => {
                setSelected(slot.name);
              }}
            >
              {slot.name.replace('slot', 'Tier ')}
            </div>
          ))}
        </Scrollbars>
      </div>
      <div
        className="btnSave"
        onClick={() => {
          try {
            onSave();
          } catch (error) {}
          setSelectedSlot(selected);
        }}
      >
        Save
      </div>
    </div>
  );
}

export default TierComparison;
