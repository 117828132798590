import React, { memo, useContext, useMemo, useState } from 'react';
import MainNavbar from '../../components/MainNavbar';
import classNames from './PreRegisterMobile.module.scss';
import { ReactComponent as ArrowRight } from '../../static/images/arrowRight.svg';
import { validateEmail, validatePassword } from '../../utils/FunctionTools';
import { useMutation } from 'react-query';
import axios from 'axios';
import { BankContext } from '../../context/Context';
import LoadingAnim from '../../components/LoadingAnim/LoadingAnim';
import { useHistory } from 'react-router-dom';

async function loginFunc(paramData) {
  const { data } = await axios.post(
    'https://gxauth.apimachine.com/gx/user/login',
    paramData
  );
  return data;
}

function PreRegisterMobile() {
  const history = useHistory();
  const { userLoginHandler, tostShowOn } = useContext(BankContext);
  const [step, setStep] = useState(0);
  const [email, setEmail] = useState('');
  const [tmpPassword, setTmpPassword] = useState('');
  const [cnfPassword, setCnfPassword] = useState('');
  const [password, setPassword] = useState('');

  const { isLoading, mutate } = useMutation(loginFunc, {
    onSuccess: (data) => {
      if (data.status) {
        userLoginHandler(email, data.accessToken, data.idToken);
        history.push('/app');
      } else if (data.resetPassword) {
        setStep(2);
      } else {
        tostShowOn(data.message || 'Some Thing Went Wrong!');
        setPassword('');
        setTmpPassword('');
        setCnfPassword('');
        setStep(0);
      }
    },
  });

  const textInput = useMemo(() => {
    switch (step) {
      case 3:
        return (
          <label className={classNames.inpWrap} key="3">
            <input
              type="password"
              className={classNames.input}
              placeholder="Confirm New Password"
              value={cnfPassword}
              onChange={(e) => setCnfPassword(e.target.value)}
            />
            <ArrowRight
              className={`${classNames.iconBtn} ${
                classNames[(password === cnfPassword)?.toString()]
              }`}
              onClick={() => {
                if (password === cnfPassword)
                  mutate({
                    email,
                    password: tmpPassword,
                    newPassword: password,
                  });
              }}
            />
          </label>
        );
      case 2:
        return (
          <label className={classNames.inpWrap} key="2">
            <input
              type="password"
              className={classNames.input}
              placeholder="Create New Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <ArrowRight
              className={`${classNames.iconBtn} ${
                classNames[validatePassword(password)?.toString()]
              }`}
              onClick={() => {
                if (validatePassword(password)) setStep(3);
              }}
            />
          </label>
        );
      case 1:
        return (
          <label className={classNames.inpWrap} key="1">
            <input
              type="text"
              className={classNames.input}
              placeholder="Enter Temporary Password"
              value={tmpPassword}
              onChange={(e) => setTmpPassword(e.target.value)}
            />
            <ArrowRight
              className={`${classNames.iconBtn} ${
                classNames[validatePassword(tmpPassword)?.toString()]
              }`}
              onClick={() => {
                if (validatePassword(tmpPassword))
                  mutate({
                    email,
                    password: tmpPassword,
                  });
              }}
            />
          </label>
        );
      case 0:
      default:
        return (
          <label className={classNames.inpWrap} key="0">
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={classNames.input}
              placeholder="Pre-Registration Email"
            />
            <ArrowRight
              className={`${classNames.iconBtn} ${
                classNames[validateEmail(email)?.toString()]
              }`}
              onClick={() => {
                if (validateEmail(email)) setStep(1);
              }}
            />
          </label>
        );
    }
  }, [
    tmpPassword,
    setTmpPassword,
    cnfPassword,
    setCnfPassword,
    email,
    setEmail,
    password,
    setPassword,
    validateEmail,
    step,
    setStep,
    mutate,
  ]);

  return (
    <div className={classNames.preRegPage}>
      <MainNavbar />
      <div className={classNames.formArea}>
        {isLoading && <LoadingAnim className={classNames.loading} />}
        {textInput}
      </div>
    </div>
  );
}

export default memo(PreRegisterMobile);
