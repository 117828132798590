import React, { useContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import Skeleton from 'react-loading-skeleton';
import { APP_USER_TOKEN } from '../../../config';
import { useAppContextDetails } from '../../../context/AppContext';
import { useCoinContextData } from '../../../context/CoinContext';
import { BankContext } from '../../../context/Context';
import { useUserApps } from '../../../queryHooks';
import { LoadingAnimation } from '../../LoadingAnimation';
import { useCoinData } from '../api';
import {
  useLoadBondEarningsBalance,
  useLoadMMEarningsBalance,
  useTransferBondFundApi,
  useTransferMMFundApi,
} from './api';
import { useHistory } from 'react-router-dom';

const UpdatedSuccess = () => {
  const history = useHistory();
  const [loadBalance, setLoadBalance] = useState(false);
  const {
    transferAmount,
    selectedCoin,
    setUpdateBondVaultData,
    transferMethod,
    setUpdateMMVaultData,
    setSelectedCoin,
    setCoinAction,
    setCoinActionEnabled,
  } = useCoinContextData();
  const { email } = useContext(BankContext);
  const { appLogo, appCode } = useAppContextDetails();
  const { data: userApps, isLoading: userAppsLoading } = useUserApps(email);
  const userIceAppData = userApps?.filter((e) => e.app_code === 'ice');
  const iceAppProfileId = userIceAppData?.[0]?.profile_id;

  const userAppData = userApps?.filter((e) => e.app_code === appCode);
  const appProfileId = userAppData?.[0]?.profile_id;

  const userToken = Cookies.get(APP_USER_TOKEN);
  const isTransferViaBond = transferMethod === 'bonds';
  const isTransferViaMM = transferMethod === 'moneymarkets';

  const { data: bondEarningsResponse, isLoading: isLoadingBalance } =
    useLoadBondEarningsBalance(email, transferMethod, loadBalance);
  const { data: moneyMarketEarningsResponse } = useLoadMMEarningsBalance(
    email,
    appCode,
    transferMethod,
    loadBalance
  );

  const bondsBalances = bondEarningsResponse?.data?.result?.[0]?.balances;
  const moneyMarketBalances =
    moneyMarketEarningsResponse?.data?.result?.[0]?.balances?.[0]
      ?.liquid_balances;
  const availableBalance = isTransferViaBond
    ? bondsBalances
    : moneyMarketBalances;

  const currentCoinData = availableBalance?.filter(
    (coin) => coin?.coinSymbol === selectedCoin.coinSymbol
  );
  const coinBalance = currentCoinData?.[0]?.coinValue;

  const { mutate: transferBondFund, isLoading: isTransferringBond } =
    useTransferBondFundApi(setLoadBalance, setUpdateBondVaultData);
  const { mutate: transferMMFund, isLoading: isTransferringMM } =
    useTransferMMFundApi(setLoadBalance, setUpdateMMVaultData);

  const { mutate: getCoinData, data: coinResponse } = useCoinData();
  const selectedCoinBalance = coinResponse?.data?.coins_data?.[0]?.coinValue;

  //   const [coinDetail, setCoinDetail] = useState();

  useEffect(() => {
    if (coinResponse) {
      let type = transferMethod === 'bonds' ? 'bonds' : 'moneyMarket';
      history.push(`/vault/${type}/${appCode}/${selectedCoin.coinSymbol}`);
    }
  }, [coinResponse]);

  useEffect(() => {
    if (iceAppProfileId && isTransferViaBond) {
      transferBondFund({
        email,
        token: userToken,
        app_code: 'ice',
        profile_id: iceAppProfileId,
        coin: selectedCoin.coinSymbol,
        amount: transferAmount,
        toAppcode: appCode,
      });
    }
  }, [iceAppProfileId, transferMethod]);

  useEffect(() => {
    if (appProfileId && isTransferViaMM) {
      transferMMFund({
        email,
        token: userToken,
        app_code: appCode,
        profile_id: appProfileId,
        coin: selectedCoin.coinSymbol,
        amount: transferAmount,
      });
    }
  }, [appProfileId, transferMethod]);

  useEffect(() => {
    if (coinBalance) {
      getCoinData({
        app_code: appCode,
        email,
        include_coins: [selectedCoin.coinSymbol],
      });
    }
  }, [coinBalance]);

  const isLoading =
    userAppsLoading ||
    isTransferringBond ||
    isTransferringMM ||
    isLoadingBalance;
  return (
    <>
      <div
        style={{
          width: '100%',
          padding: '1.85rem 1.85rem 0',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          fontWeight: '100',
          fontSize: '1.1rem',
          lineHeight: '30px',
          opacity: isLoading ? 0.25 : 1,
        }}
      >
        Congratulations. You Have Successfully Transferred {transferAmount}{' '}
        {selectedCoin.coinSymbol} From Your{' '}
        {transferMethod === 'bonds' ? 'Staking' : 'MoneyMarkets'} Earnings Vault
        To Your Liquid Vault.
      </div>

      <div
        style={{
          position: 'absolute',
          bottom: '0',
          width: '100%',
          height: '10rem',
          padding: '1.5rem',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          borderTop: '0.5px solid #E5E5E5',
          opacity: isLoading ? 0.25 : 1,
        }}
      >
        <div
          style={{
            background: 'rgb(255, 94, 94)',
            borderRadius: '35px',
            fontWeight: '500',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            padding: '0.75rem 2.2rem',
            color: '#fff',
          }}
          onClick={() => {
            setCoinActionEnabled(false);
            setCoinAction(['Menu']);
            setSelectedCoin('');
          }}
        >
          Close
        </div>
        <div
          style={{
            background: 'linear-gradient(270deg,#81cbd7,#3d4394)',
            borderRadius: '35px',
            fontWeight: '500',
            cursor: 'pointer',
            display: 'flex',
            width: '100%',
            padding: '0.75rem 2.2rem',
            color: '#fff',
            justifyContent: 'center',
          }}
        >
          {selectedCoin?.coinSymbol} Actions
        </div>
      </div>
      {isLoading && (
        <div className="coin-address-loading">
          <LoadingAnimation icon={appLogo} width={200} />
        </div>
      )}
    </>
  );
};

export default UpdatedSuccess;
