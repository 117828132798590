import React, { useState, useContext } from 'react';
import { BankContext } from '../../../context/Context';
import './mainMenu.scss';

import cryptoIcon from '../../../static/images/mobile/crypto.svg';
import forexIcon from '../../../static/images/mobile/forex.svg';
import marketIcon from '../../../static/images/mobile/market.svg';
import vaultIcon from '../../../static/images/mobile/vault.svg';
import reloadIcon from '../../../static/images/mobile/reload.svg';
import plusIcon from '../../../static/images/mobile/plus.svg';
import logoutIcon from '../../../static/images/mobile/logout.svg';

import moneyMarketIcon from '../../../static/images/mobile/moneyMarket.svg';
import bondIcon from '../../../static/images/mobile/bond.svg';
import dividendIcon from '../../../static/images/mobile/dividend.svg';
import { AppContext } from '../../../context/AppContext';

const MainMenu = () => {
  const { email, username, name, profileImg, sidebarCollapse } =
    useContext(BankContext);
  const {
    openSidebar,
    setOpenSidebar,
    appLogo,
    selectedMenu,
    setSelectedMenu,
    selectedSubMenu,
    setSelectedSubMenu,
    setBuySellStep,
  } = useContext(AppContext);

  const marketSubMenu = [
    {
      icon: cryptoIcon,
      text: 'Crypto',
    },
    {
      icon: forexIcon,
      text: 'Forex',
    },
    {
      icon: moneyMarketIcon,
      text: 'MoneyMarkets',
    },
    {
      icon: bondIcon,
      text: 'Bonds',
    },
    {
      icon: marketIcon,
      text: 'MarketsVerse',
    },
  ];

  const vaultSubMenu = [
    {
      icon: cryptoIcon,
      text: 'Crypto',
      enabled: true,
    },
    {
      icon: forexIcon,
      text: 'Forex',
      enabled: true,
    },
    {
      icon: moneyMarketIcon,
      text: 'MoneyMarkets',
      enabled: true,
    },
    {
      icon: bondIcon,
      text: 'Bonds',
      enabled: true,
    },
    {
      icon: marketIcon,
      text: 'MarketsVerse',
      enabled: false,
    },
    {
      icon: dividendIcon,
      text: 'Dividends',
      enabled: false,
    },
  ];

  const conditionalSubMenu = () => {
    if (selectedMenu === 'market') {
      return marketSubMenu.map((item, index) => {
        return (
          <div
            key={index}
            onClick={(e) => {
              setBuySellStep(null);
              setOpenSidebar(false);
              setSelectedSubMenu(item.text);
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              paddingBottom: '40px',
            }}
          >
            <img src={item.icon} alt="" width={30} height={30} />
            <div
              style={{
                fontSize: '20px',
                fontWeight: selectedSubMenu === item.text ? 700 : 500,
                paddingLeft: '10px',
              }}
            >
              {item.text}
            </div>
          </div>
        );
      });
    } else {
      return vaultSubMenu.map((item, index) => {
        return (
          <div
            key={index}
            onClick={(e) => {
              setOpenSidebar(false);
              setSelectedSubMenu(item.text);
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              paddingBottom: '40px',
              opacity: item.enabled ? 1 : 0.3,
            }}
          >
            <img src={item.icon} alt="" width={30} height={30} />
            <div
              style={{
                fontSize: '20px',
                fontWeight: selectedSubMenu === item.text ? 700 : 500,
                paddingLeft: '10px',
              }}
            >
              {item.text}
            </div>
          </div>
        );
      });
    }
  };

  return (
    <>
      <div className="menuWrapper">
        <div className="mainMenu">
          <div className="topSection">
            <img
              onClick={(e) => setOpenSidebar(false)}
              src={profileImg ? profileImg : appLogo}
              alt=""
              className="profileImg"
            />
            <div
              className={
                selectedMenu === 'market' ? 'selectedMenu' : 'menuItem'
              }
            >
              <img
                onClick={(e) => setSelectedMenu('market')}
                src={marketIcon}
                alt=""
                className="menuItemImg"
              />
            </div>
            <div
              className={selectedMenu === 'vault' ? 'selectedMenu' : 'menuItem'}
            >
              <img
                onClick={(e) => setSelectedMenu('vault')}
                src={vaultIcon}
                alt=""
                className="menuItemImg"
              />
            </div>
          </div>
          <div>
            <img
              src={logoutIcon}
              alt=""
              className="profileImg"
              onClick={(e) => {
                localStorage.clear();
                window.location.reload();
              }}
            />
          </div>
        </div>

        <div className="subMenu">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div>
              <div className="subMenuHeader">
                {username?.length > 13
                  ? username?.substring(0, 13) + '...'
                  : username}
              </div>
              <div className="subMenuSubHeader">Copy NetworkSync</div>
            </div>
            <div>
              <img src={reloadIcon} alt="" />
              <img src={plusIcon} alt="" style={{ paddingLeft: '25px' }} />
            </div>
          </div>
          <div style={{ paddingTop: '35px' }}>{conditionalSubMenu()}</div>
        </div>
      </div>
    </>
  );
};

export default MainMenu;
