import React,{useEffect, useState} from 'react';
import VaultPageContextProvider, {
  VaultPageContext,
} from '../../../context/VaultPageContext';
import classNames from '../../../pages/VaultsPage/vaultsPage.module.scss';
import { ShareVaultContent } from '../../../components/VaultsPage/VaultComponent/ShareVaultContent';
export default function Index({shareTokenTranscation,SLoading,passvaulterFiler,passTypeTransaction,
  getLink,
  passvaulterFilerWithdraw
}) {

  return (
    <>
      <VaultPageContextProvider>
        <ShareVaultContent shareTokenTranscation={shareTokenTranscation}
        SLoading={SLoading}
        getLink={getLink}
        passvaulterFilerWithdraw={passvaulterFilerWithdraw}
        passvaulterFiler={passvaulterFiler}
        passTypeTransaction={passTypeTransaction}
        />
      </VaultPageContextProvider>
    </>
  );
}
