import React, { useEffect } from 'react';
import './socially.scss';
import Layout from '../../Layout/Layout';
import { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppContextDetails } from '../../context/AppContext';
import { BankContext } from '../../context/Context';

// import fulllogo from '../../static/images/aiindex/fulllogo.svg';
import fulllogo from '../../static/images/aiindex/signalsFullLogo.svg';
// import search from '../../static/images/malls/search.svg';
import searchIcon from '../../static/images/search.svg';
import ddown from '../../static/images/malls/ddown.svg';
import sample from '../../static/images/aiindex/sample.svg';
import CreatePlanBContainer from '../../components/BuyBlocks';
import { planBContext } from '../../context/PlanBContext';
import Subscription from '../Shop/Subscription';
import ShopComponent from '../Shop';
import Skeleton from 'react-loading-skeleton';
import { RxHamburgerMenu } from 'react-icons/rx';
import MyAccounts from './components/MyAccounts/Fxaccount';

const Socially = () => {
  const [sociallyTab, setSociallyTab] = useState('My Accounts');
  const { setfiltersearch } = useAppContextDetails();

  return (
    <Layout active="signals" className="socially" hideFooter>
      <div style={{ width: '100%', height: '100%' }}>
        <div className="socially-navbar">
          <div className="socially-icon">
            <img src={fulllogo} alt="" />
          </div>
          <div className="search-socially">
            {/* <div className="all">
              <p>All Categories</p>
              <div className="arroww" style={{ cursor: 'pointer' }}>
                <img src={ddown} alt="" />
              </div>
            </div> */}
            <div className="im">
              <input
                type="text"
                placeholder="Im Looking For..."
                onChange={(event) => setfiltersearch(event?.target?.value)}
              />
              <div className="arroww" style={{ cursor: 'pointer' }}>
                <img src={searchIcon} alt="" />
              </div>
            </div>
          </div>
          <div
            className="socially-listBtn"
            // onClick={() => {
            //   window.open('https://verse.viral.group/', '_blank');
            // }}
          >
            Become A Trader
          </div>
        </div>
        <div
          className="w-space"
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <div className="optionss">
            <div
              className={`each-option ${
                sociallyTab === 'My Accounts' ? 'each-option-selected' : ''
              }`}
              onClick={() => setSociallyTab('My Accounts')}
            >
              <div>
                <p>My Accounts</p>
              </div>
            </div>

            <div
              className={`each-option ${
                sociallyTab === 'Positions' ? 'each-option-selected' : ''
              }`}
              style={{ opacity: '0.5', pointerEvents: 'none' }}
              onClick={() => setSociallyTab('Positions')}
            >
              <div>
                <p>Positions</p>
              </div>
            </div>
            <div
              className={`each-option ${
                sociallyTab === 'Trade History' ? 'each-option-selected' : ''
              }`}
              style={{ opacity: '0.5', pointerEvents: 'none' }}
              onClick={() => setSociallyTab('Trade History')}
            >
              <div>
                <p>Trade History</p>
              </div>
            </div>
            <div
              className={`each-option ${
                sociallyTab === 'Traders' ? 'each-option-selected' : ''
              }`}
              style={{ opacity: '0.5', pointerEvents: 'none' }}
              onClick={() => setSociallyTab('Traders')}
            >
              <div>
                <p>Traders</p>
              </div>
            </div>
            <div
              className={`each-option ${
                sociallyTab === 'Competitions' ? 'each-option-selected' : ''
              }`}
              style={{ opacity: '0.5', pointerEvents: 'none' }}
              onClick={() => setSociallyTab('Competitions')}
            >
              <div>
                <p>Competitions</p>
              </div>
            </div>
            <div
              className={`each-option ${
                sociallyTab === 'Brokers/Exchanges'
                  ? 'each-option-selected'
                  : ''
              }`}
              style={{ opacity: '0.5', pointerEvents: 'none' }}
              onClick={() => setSociallyTab('Brokers/Exchanges')}
            >
              <div>
                <p>Brokers/Exchanges</p>
              </div>
            </div>
          </div>
        </div>
        {sociallyTab == 'My Accounts' ? <MyAccounts /> : ''}
      </div>
    </Layout>
  );
};

export default Socially;
