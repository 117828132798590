import React, { Fragment, useContext } from 'react';
import { SIDEBAR_FOOTERS } from '../../../config/constants';
import { BankContext } from '../../../context/Context';
import { useLoadBondContracts } from '../../../queryHooks';
import bonds from '../../../static/images/marketLogos/bonds.svg';
import classNames from '../marketsPage.module.scss';

export const BondContractDetails = ({
  index,
  setIndex,
  setSelectedSidebarMenu,
}) => {
  const { email } = useContext(BankContext);
  const { data: bondContractsResponse } = useLoadBondContracts(email);
  const bondContracts =
    bondContractsResponse?.data?.icedContracts?.[0]?.contracts;
  const bondCoinName =
    bondContractsResponse?.data?.icedContracts?.[0]?.coin_name;
  const bondCoinImage =
    bondContractsResponse?.data?.icedContracts?.[0]?.coin_image;

  const formatValue = (value) => value?.toFixed(4) ?? '0.00';

  return (
    <div className={`d-flex ${classNames.bondContractsWrapper}`}>
      <div className={classNames.bondContractImg}>
        <img src={bonds} alt="Bonds" width={200} />
      </div>
      <div className={classNames.bondFilter}>
        <div className={classNames.pill}>All Assets</div>
        <div className={classNames.pill}>All Statuses</div>
        <div className={classNames.pill}>All Issuers</div>
        <div className={classNames.pill}>All Apps</div>
      </div>
      <div className={classNames.bondContract}>
        {bondContracts?.map((contract, i) => (
          <Fragment key={i}>
            <>
              <div className={classNames.bondData}>
                <div className={`d-flex ${classNames.value}`}>
                  <div>
                    <img
                      style={{ marginLeft: '-1rem' }}
                      src={contract?.BondDetail?.bankerData?.profile_img}
                      width={40}
                    />
                  </div>
                  <div className={classNames.bankerData}>
                    <div className={classNames.value}>
                      {contract?.BondDetail?.bankerData?.name}
                    </div>
                  </div>
                </div>
                <div className={`d-flex ${classNames.rightElement}`}>
                  <div>
                    <img src={bondCoinImage} width={40} />
                  </div>
                  <div className={classNames.coinData}>
                    <div className={classNames.value}>{bondCoinName}</div>
                    <div className={classNames.label}>{contract?.coin}</div>
                  </div>
                </div>
              </div>
              <div className={classNames.bondData}>
                <div className="d-flex flex-column">
                  <div className={classNames.value}>
                    {formatValue(contract?.investment)}
                  </div>
                  <div className={classNames.label}>Investment</div>
                </div>
                <div
                  className={`d-flex flex-column ${classNames.rightElement}`}
                >
                  <div className={classNames.value}>{contract?.voc}</div>
                  <div className={classNames.label}>Current VOC</div>
                </div>
              </div>
              <div className={classNames.bondData}>
                <div className="d-flex flex-column">
                  <div className={classNames.value}>
                    {formatValue(contract?.completed_stats?.earnings)}
                  </div>
                  <div className={classNames.label}>Interest Earned</div>
                </div>
                <div
                  className={`d-flex flex-column ${classNames.rightElement}`}
                >
                  <div className={classNames.value}>
                    {formatValue(contract?.remaining_stats?.earnings)}
                  </div>
                  <div className={classNames.label}>Interest Receivable</div>
                </div>
              </div>

              <div className={classNames.sidebarSpacer} />
              <div className={classNames.bondData}>
                <div className={classNames.subLabel}>Status</div>
                <div
                  className={`${classNames.rightElement} ${classNames.value}`}
                  style={{ textTransform: 'capitalize' }}
                >
                  {contract?.status}
                </div>
              </div>

              <div className={classNames.sidebarSpacer} />
              <div className={classNames.bondData}>
                <div className={classNames.subLabel}>Bonds</div>
                <div
                  className={`${classNames.rightElement} ${classNames.value}`}
                >
                  {contract?.num_of_bonds}
                </div>
              </div>

              <div className={classNames.sidebarSpacer} />
              <div className={classNames.bondData}>
                <div className={classNames.subLabel}>Days</div>
                <div
                  className={`${classNames.rightElement} ${classNames.value}`}
                >
                  {contract?.completed_stats?.days}/{contract?.days}
                </div>
              </div>
              <div className={classNames.actionWrapper}>
                <div
                  className={`${classNames.pillAction} ${classNames.true}`}
                  onClick={() =>
                    window.open(
                      `https://tokenhash.com/bond/${contract?._id}`,
                      '_blank'
                    )
                  }
                >
                  Bond Hash
                </div>
                <div className={`${classNames.pillAction}`}>Renew</div>
                <div className={`${classNames.pillAction}`}>Redeem</div>
              </div>
              <div className={classNames.splitSpacer} />
            </>
          </Fragment>
        ))}
      </div>
      <div className={classNames.bondSidebarFooter} style={{ height: '7%' }}>
        {SIDEBAR_FOOTERS.map((menu, i) => (
          <div
            className={`${classNames.footerMenu} ${classNames[i === index]} ${
              menu.disable && classNames.disable
            }`}
            onClick={() => {
              !menu.disable && setIndex(i);
              setSelectedSidebarMenu(menu.label);
            }}
          >
            <img src={menu.icon} alt="" />
            <span>{menu.label}</span>
          </div>
        ))}
      </div>
    </div>
  );
};
