import React, { useEffect, useState, useContext } from 'react';
import './Holdings.scss';
import { HoldingFunds, InvestmentData } from './apidata';
import { BankContext } from '../../context/Context';
import Skeleton from 'react-loading-skeleton';
export default function Holdings() {
  const [fundsholding, setfundsholding] = useState([]);
  const { email, profileId, token } = useContext(BankContext);
  const [investmentData, setinvestmentData] = useState([]);
  const [tokenName, settokenName] = useState('');
  const [loader, setloder] = useState(false);
  useEffect(() => {
    valuebalanceFunction();
  }, []);
  const valuebalanceFunction = () => {
    let obj = {
      email: email,
      app_code: 'dgpayments',
    };
    console.log('tokezxczxczxn', obj);
    setloder(true);
    HoldingFunds(obj).then((response) => {
      let result = response?.data;
      if (result.status) {
        setfundsholding(result?.vaultsData[0]?.tokens);
        settokenName(result?.vaultsData[0]?.tokens[0]?.token);
        InvestmentDataFunction(result?.vaultsData[0]?.tokens[0]?.token);
        // setCoinStore(result.coins_data);
      }
    });
  };

  const tokeNameFunction = (e) => {
    setloder(true);
    // settokenName

    InvestmentDataFunction(e);
  };

  const InvestmentDataFunction = (e) => {
    let obj = {
      email: email,
      token: e,
    };
    settokenName(e);
    InvestmentData(obj).then((response) => {
      let result = response?.data;
      if (result.status) {
        setinvestmentData(result);
        setloder(false);
        // setCoinStore(result.coins_data);
      }
    });
  };
  console.log('72834723', tokenName);
  return (
    <div className="holdingPage">
      <div className="contianer-holding">
        <p>FundCoin Vaults</p>
        <div className="holdingcard">
          {fundsholding?.length > 0 ? (
            <>
              {fundsholding?.map((item) => {
                return (
                  <div
                    className="inner"
                    style={{
                      boxShadow:
                        tokenName == item.token
                          ? '0px 4px 4px rgba(0, 0, 0, 0.25)'
                          : '0px 0px 0px rgba(0, 0, 0, 0.25)',
                      //   opacity: tokenName == item.token ? 1 : 0.5,
                    }}
                    // style={
                    //   (tokenName == item.token
                    //     ? {
                    //         boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
                    //       }
                    //     : { boxShadow: '0px' })
                    // }
                    onClick={() => tokeNameFunction(item.token)}
                  >
                    <div className="img-holding">
                      <img src={item.token_profile_data?.coinImage} />
                      <h1>{item?.token}</h1>
                    </div>
                    <div className="img-holding">
                      <h2>{item?.value?.toFixed(4)}</h2>
                      <h5>{item?.asset}</h5>
                    </div>
                    <h3>Balance: {item?.value_in_asset?.toFixed(2)}</h3>
                  </div>
                );
              })}
            </>
          ) : (
            <>
              <div className="inner">
                <Skeleton className="sectionskeletonHoding" />
                <Skeleton className="sectionskeletonHoding" />
                <Skeleton className="sectionskeletonHoding" />
              </div>
              <div className="inner">
                <Skeleton className="sectionskeletonHoding" />
                <Skeleton className="sectionskeletonHoding" />
                <Skeleton className="sectionskeletonHoding" />
              </div>
              <div className="inner">
                <Skeleton className="sectionskeletonHoding" />
                <Skeleton className="sectionskeletonHoding" />
                <Skeleton className="sectionskeletonHoding" />
              </div>
              <div className="inner">
                <Skeleton className="sectionskeletonHoding" />
                <Skeleton className="sectionskeletonHoding" />
                <Skeleton className="sectionskeletonHoding" />
              </div>
            </>
          )}
        </div>

        <div className="InvestDetail-hodlings">
          <div className="investmentcardSection">
            <div className="inv-detail">Investment Details</div>
            <div className="innerInvestmentCard">
              <div className="innerItemCard">
                {loader ? (
                  <Skeleton className="sectionskeletonHoding" />
                ) : (
                  <h1>
                    {investmentData?.investmentData?.count > 0
                      ? investmentData?.investmentData?.count
                      : 0.0}
                  </h1>
                )}

                <h2>Transactions</h2>
              </div>
              <div className="innerItemCard" style={{ borderRight: '0px' }}>
                {loader ? (
                  <Skeleton className="sectionskeletonHoding" />
                ) : (
                  <h1>
                    {investmentData?.investmentData?.total_tokens_purchased >
                    0 ? (
                      <>
                        {investmentData?.investmentData?.total_tokens_purchased?.toFixed(
                          2
                        )}
                      </>
                    ) : (
                      0?.toFixed(2)
                    )}
                  </h1>
                )}

                <h2>Tokens Purchased</h2>
              </div>
              <div className="innerItemCard">
                {loader ? (
                  <Skeleton className="sectionskeletonHoding" />
                ) : (
                  <h1>
                    {investmentData?.investmentData?.investment > 0
                      ? investmentData?.investmentData?.investment?.toFixed(2)
                      : 0?.toFixed(2)}
                  </h1>
                )}

                <h2>Total Investment</h2>
              </div>
              <div className="innerItemCard" style={{ borderRight: '0px' }}>
                {loader ? (
                  <Skeleton className="sectionskeletonHoding" />
                ) : (
                  <h1>
                    {investmentData?.investmentData?.average_investment > 0
                      ? investmentData?.investmentData?.average_investment?.toFixed(
                          2
                        )
                      : 0?.toFixed(2)}
                  </h1>
                )}

                <h2>Avg Investment </h2>
              </div>
              <div className="innerItemCard">
                {loader ? (
                  <Skeleton className="sectionskeletonHoding" />
                ) : (
                  <h1>
                    {investmentData?.investmentData?.average_price > 0
                      ? investmentData?.investmentData?.average_price?.toFixed(
                          2
                        )
                      : 0?.toFixed(2)}
                  </h1>
                )}

                <h2>Avg Price</h2>
              </div>
              <div className="innerItemCard" style={{ borderRight: '0px' }}>
                {loader ? (
                  <Skeleton className="sectionskeletonHoding" />
                ) : (
                  <h1>
                    {investmentData?.investmentData?.purchase_fees > 0
                      ? investmentData?.investmentData?.purchase_fees?.toFixed(
                          2
                        )
                      : 0?.toFixed(2)}
                  </h1>
                )}

                <h2>Total Fees</h2>
              </div>
              <div className="innerItemCard">
                {loader ? (
                  <Skeleton className="sectionskeletonHoding" />
                ) : (
                  <h1>
                    {investmentData?.investmentData?.purchase_fixed_fees > 0
                      ? investmentData?.investmentData?.purchase_fixed_fees?.toFixed(
                          2
                        )
                      : 0?.toFixed(2)}
                  </h1>
                )}

                <h2>Fixed Fees</h2>
              </div>
              <div className="innerItemCard" style={{ borderRight: '0px' }}>
                {loader ? (
                  <Skeleton className="sectionskeletonHoding" />
                ) : (
                  <h1>
                    {investmentData?.investmentData?.purchase_trade_fees > 0
                      ? investmentData?.investmentData?.purchase_trade_fees?.toFixed(
                          2
                        )
                      : 0?.toFixed(2)}
                  </h1>
                )}

                <h2>Variable Fees</h2>
              </div>
              <div className="innerItemCard">
                {loader ? (
                  <Skeleton className="sectionskeletonHoding" />
                ) : (
                  <h1>
                    {investmentData?.investmentData?.average_price_with_fees > 0
                      ? investmentData?.investmentData?.average_price_with_fees?.toFixed(
                          2
                        )
                      : 0?.toFixed(2)}
                  </h1>
                )}

                <h2>Avg Price With Fees</h2>
              </div>
              <div className="innerItemCard" style={{ borderRight: '0px' }}>
                {loader ? (
                  <Skeleton className="sectionskeletonHoding" />
                ) : (
                  <h1>
                    {investmentData?.investmentData?.investment_with_fees > 0
                      ? investmentData?.investmentData?.investment_with_fees?.toFixed(
                          2
                        )
                      : 0?.toFixed(2)}
                  </h1>
                )}

                <h2>Total Investment With Fees</h2>
              </div>
              <div className="innerItemCard">
                {loader ? (
                  <Skeleton className="sectionskeletonHoding" />
                ) : (
                  <h1>
                    {investmentData?.investmentData
                      ?.average_investment_with_fees > 0
                      ? investmentData?.investmentData?.average_investment_with_fees?.toFixed(
                          2
                        )
                      : 0?.toFixed(2)}
                  </h1>
                )}

                <h2>Avg Investment With Fees</h2>
              </div>
            </div>
          </div>

          {/*  */}

          <div className="investmentcardSection">
            <div className="inv-detail">Performances</div>
            <div className="innerInvestmentCard">
              <div className="innerItemCard">
                {loader ? (
                  <Skeleton className="sectionskeletonHoding" />
                ) : (
                  <h1>
                    {investmentData?.current_market_cap > 0
                      ? investmentData?.current_market_cap?.toFixed(2)
                      : 0?.toFixed(2)}
                  </h1>
                )}
                <h2>Market Cap</h2>
              </div>
              <div className="innerItemCard" style={{ borderRight: '0px' }}>
                {loader ? (
                  <Skeleton className="sectionskeletonHoding" />
                ) : (
                  <h1>
                    {investmentData?.last_market_cap > 0
                      ? investmentData?.last_market_cap?.toFixed(2)
                      : 0?.toFixed(2)}
                  </h1>
                )}
                <h2>Last Market Cap</h2>
              </div>
              <div className="innerItemCard">
                {loader ? (
                  <Skeleton className="sectionskeletonHoding" />
                ) : (
                  <h1>
                    {investmentData?.current_token_price > 0
                      ? investmentData?.current_token_price?.toFixed(2)
                      : 0?.toFixed(2)}
                  </h1>
                )}
                <h2>Current Token Price</h2>
              </div>
              <div className="innerItemCard" style={{ borderRight: '0px' }}>
                {loader ? (
                  <Skeleton className="sectionskeletonHoding" />
                ) : (
                  <h1>
                    {investmentData?.average_price > 0
                      ? investmentData?.average_price?.toFixed(2)
                      : 0?.toFixed(2)}
                  </h1>
                )}
                <h2>Avg Token Price </h2>
              </div>
              <div className="innerItemCard">
                {loader ? (
                  <Skeleton className="sectionskeletonHoding" />
                ) : (
                  <h1>
                    {investmentData?.current_token_balance > 0
                      ? investmentData?.current_token_balance?.toFixed(2)
                      : 0?.toFixed(2)}
                  </h1>
                )}
                <h2>Current Token Balance</h2>
              </div>
              <div className="innerItemCard" style={{ borderRight: '0px' }}>
                {loader ? (
                  <Skeleton className="sectionskeletonHoding" />
                ) : (
                  <h1>
                    {investmentData?.current_pl > 0
                      ? investmentData?.current_pl?.toFixed(2)
                      : 0?.toFixed(2)}
                  </h1>
                )}
                <h2>Current PL</h2>
              </div>
              <div className="innerItemCard">
                {loader ? (
                  <Skeleton className="sectionskeletonHoding" />
                ) : (
                  <h1>
                    {investmentData?.current_pl_percentage > 0
                      ? investmentData?.current_pl_percentage?.toFixed(2)
                      : 0?.toFixed(2)}
                    %
                  </h1>
                )}
                <h2>Current PL (%)</h2>
              </div>
              <div className="innerItemCard" style={{ borderRight: '0px' }}>
                {loader ? (
                  <Skeleton className="sectionskeletonHoding" />
                ) : (
                  <h1>
                    {investmentData?.liveInvestment > 0
                      ? investmentData?.liveInvestment?.toFixed(2)
                      : 0?.toFixed(2)}
                  </h1>
                )}
                <h2>Live Investment</h2>
              </div>
              <div className="innerItemCard">
                {loader ? (
                  <Skeleton className="sectionskeletonHoding" />
                ) : (
                  <h1>
                    {investmentData?.performance_fees > 0
                      ? investmentData?.performance_fees?.toFixed(2)
                      : 0?.toFixed(2)}
                  </h1>
                )}
                <h2>Performance Fees</h2>
              </div>
              <div className="innerItemCard" style={{ borderRight: '0px' }}>
                {loader ? (
                  <Skeleton className="sectionskeletonHoding" />
                ) : (
                  <h1>
                    {investmentData?.dividend_performance_fees > 0
                      ? investmentData?.dividend_performance_fees?.toFixed(2)
                      : 0?.toFixed(2)}
                  </h1>
                )}
                <h2>Dividend Performance Fees</h2>
              </div>
              <div className="innerItemCard">
                {loader ? (
                  <Skeleton className="sectionskeletonHoding" />
                ) : (
                  <h1>
                    {investmentData?.withdrawals_fixed_fee > 0
                      ? investmentData?.withdrawals_fixed_fee?.toFixed(2)
                      : 0?.toFixed(2)}
                  </h1>
                )}
                <h2>Withdrawal Fixed Fee</h2>
              </div>
              <div className="innerItemCard" style={{ borderRight: '0px' }}>
                {loader ? (
                  <Skeleton className="sectionskeletonHoding" />
                ) : (
                  <h1>
                    {investmentData?.withdrawals_performance_fees > 0
                      ? investmentData?.withdrawals_performance_fees?.toFixed(2)
                      : 0?.toFixed(2)}
                  </h1>
                )}
                <h2>Withdrawal Performance Fees</h2>
              </div>

              <div className="innerItemCard">
                {loader ? (
                  <Skeleton className="sectionskeletonHoding" />
                ) : (
                  <h1>
                    {investmentData?.withdrawals_trade_fee > 0
                      ? investmentData?.withdrawals_trade_fee?.toFixed(2)
                      : 0?.toFixed(2)}
                  </h1>
                )}
                <h2>Withdrawal Variable Fee</h2>
              </div>
              <div className="innerItemCard" style={{ borderRight: '0px' }}>
                {loader ? (
                  <Skeleton className="sectionskeletonHoding" />
                ) : (
                  <h1>
                    {investmentData?.net_totalInvestment > 0
                      ? investmentData?.net_totalInvestment?.toFixed(2)
                      : 0?.toFixed(2)}
                  </h1>
                )}
                <h2>Net Total Investment</h2>
              </div>

              <div className="innerItemCard">
                {loader ? (
                  <Skeleton className="sectionskeletonHoding" />
                ) : (
                  <h1>
                    {investmentData?.net_average_price > 0
                      ? investmentData?.net_average_price?.toFixed(2)
                      : 0?.toFixed(2)}
                  </h1>
                )}
                <h2>Net Avg Price</h2>
              </div>
              <div className="innerItemCard" style={{ borderRight: '0px' }}>
                {loader ? (
                  <Skeleton className="sectionskeletonHoding" />
                ) : (
                  <h1>
                    {investmentData?.net_current_pl > 0
                      ? investmentData?.net_current_pl?.toFixed(2)
                      : 0?.toFixed(2)}
                  </h1>
                )}
                <h2>Net Current PL</h2>
              </div>

              <div className="innerItemCard">
                {loader ? (
                  <Skeleton className="sectionskeletonHoding" />
                ) : (
                  <h1>
                    {investmentData?.net_current_pl_percentage > 0
                      ? investmentData?.net_current_pl_percentage?.toFixed(2)
                      : 0?.toFixed(2)}
                    %
                  </h1>
                )}
                <h2>Net Current PL (%)</h2>
              </div>
              <div className="innerItemCard" style={{ borderRight: '0px' }}>
                {loader ? (
                  <Skeleton className="sectionskeletonHoding" />
                ) : (
                  <h1>
                    {investmentData?.net_liveInvestment > 0
                      ? investmentData?.net_liveInvestment?.toFixed(2)
                      : 0?.toFixed(2)}
                  </h1>
                )}
                <h2>Net Live Investment</h2>
              </div>

              <div className="innerItemCard">
                {loader ? (
                  <Skeleton className="sectionskeletonHoding" />
                ) : (
                  <h1>
                    {investmentData?.high_market_cap > 0
                      ? investmentData?.high_market_cap?.toFixed(2)
                      : 0?.toFixed(2)}
                  </h1>
                )}
                <h2>Market Cap (High)</h2>
              </div>
              <div className="innerItemCard" style={{ borderRight: '0px' }}>
                {loader ? (
                  <Skeleton className="sectionskeletonHoding" />
                ) : (
                  <h1>
                    {investmentData?.low_market_cap > 0
                      ? investmentData?.low_market_cap?.toFixed(2)
                      : 0?.toFixed(2)}
                  </h1>
                )}
                <h2>Market Cap (Low)</h2>
              </div>
            </div>
          </div>
          {/*   */}

          <div className="investmentcardSection">
            <div className="inv-detail">Dividends & Withdrawals</div>
            <div className="innerInvestmentCard">
              <div className="innerItemCard">
                {loader ? (
                  <Skeleton className="sectionskeletonHoding" />
                ) : (
                  <h1>
                    {investmentData?.dividendsData?.count > 0
                      ? investmentData?.dividendsData?.count
                      : 0}
                  </h1>
                )}
                <h2>Withdrawals</h2>
              </div>
              <div className="innerItemCard" style={{ borderRight: '0px' }}>
                {loader ? (
                  <Skeleton className="sectionskeletonHoding" />
                ) : (
                  <h1>
                    {investmentData?.dividendsData?.earnings > 0
                      ? investmentData?.dividendsData?.earnings?.toFixed(2)
                      : 0?.toFixed(2)}
                  </h1>
                )}
                <h2>Earnings</h2>
              </div>
              <div className="innerItemCard">
                {loader ? (
                  <Skeleton className="sectionskeletonHoding" />
                ) : (
                  <h1>
                    {investmentData?.dividendsData?.earnings_after_fees > 0
                      ? investmentData?.dividendsData?.earnings_after_fees?.toFixed(
                          2
                        )
                      : 0?.toFixed(2)}
                  </h1>
                )}
                <h2>Earnings After Fees</h2>
              </div>
              <div className="innerItemCard" style={{ borderRight: '0px' }}>
                {loader ? (
                  <Skeleton className="sectionskeletonHoding" />
                ) : (
                  <h1>
                    {investmentData?.dividendsData
                      ?.earnings_over_totalInvestment > 0
                      ? investmentData?.dividendsData?.earnings_over_totalInvestment?.toFixed(
                          2
                        )
                      : 0?.toFixed(2)}
                  </h1>
                )}
                <h2>Earnings Over Total Investment</h2>
              </div>
              <div className="innerItemCard">
                {loader ? (
                  <Skeleton className="sectionskeletonHoding" />
                ) : (
                  <h1>
                    {investmentData?.dividendsData
                      ?.earnings_over_totalInvestment_percentage > 0
                      ? investmentData?.dividendsData?.earnings_over_totalInvestment_percentage?.toFixed(
                          2
                        )
                      : 0?.toFixed(2)}
                    %
                  </h1>
                )}
                <h2>Earnings Over Total Investment (%)</h2>
              </div>
              <div className="innerItemCard" style={{ borderRight: '0px' }}>
                {loader ? (
                  <Skeleton className="sectionskeletonHoding" />
                ) : (
                  <h1>
                    {investmentData?.dividendsData
                      ?.earnings_over_liveInvestment > 0
                      ? investmentData?.dividendsData?.earnings_over_liveInvestment?.toFixed(
                          2
                        )
                      : 0?.toFixed(2)}
                  </h1>
                )}
                <h2>Earnings Over Live Investment</h2>
              </div>
              <div className="innerItemCard">
                {loader ? (
                  <Skeleton className="sectionskeletonHoding" />
                ) : (
                  <h1>
                    {investmentData?.dividendsData
                      ?.earnings_over_liveInvestment_percentage > 0
                      ? investmentData?.dividendsData?.earnings_over_liveInvestment_percentage?.toFixed(
                          2
                        )
                      : 0?.toFixed(2)}
                    %
                  </h1>
                )}
                <h2>Earnings Over Live Investment (%)</h2>
              </div>
              <div className="innerItemCard" style={{ borderRight: '0px' }}>
                {loader ? (
                  <Skeleton className="sectionskeletonHoding" />
                ) : (
                  <h1>
                    {investmentData?.dividendsData
                      ?.earnings_over_totalInvestment_after_fees > 0
                      ? investmentData?.dividendsData?.earnings_over_totalInvestment_after_fees?.toFixed(
                          2
                        )
                      : 0?.toFixed(2)}
                  </h1>
                )}
                <h2>Earning/Total Investment After Fees</h2>
              </div>
              <div className="innerItemCard">
                {loader ? (
                  <Skeleton className="sectionskeletonHoding" />
                ) : (
                  <h1>
                    {investmentData?.dividendsData
                      ?.earnings_over_totalInvestment_after_fees_percentage > 0
                      ? investmentData?.dividendsData?.earnings_over_totalInvestment_after_fees_percentage?.toFixed(
                          2
                        )
                      : 0?.toFixed(2)}
                    %
                  </h1>
                )}
                <h2>Earning/Total Investment After Fees (%)</h2>
              </div>
              <div className="innerItemCard" style={{ borderRight: '0px' }}>
                {loader ? (
                  <Skeleton className="sectionskeletonHoding" />
                ) : (
                  <h1>
                    {investmentData?.dividendsData
                      ?.earnings_over_liveInvestment_after_fees > 0
                      ? investmentData?.dividendsData?.earnings_over_liveInvestment_after_fees?.toFixed(
                          2
                        )
                      : 0?.toFixed(2)}
                  </h1>
                )}
                <h2>Earning/Live Investment After Fees</h2>
              </div>
              <div className="innerItemCard">
                {loader ? (
                  <Skeleton className="sectionskeletonHoding" />
                ) : (
                  <h1>
                    {investmentData?.dividendsData
                      ?.earnings_over_liveInvestment_after_fees_percentage > 0
                      ? investmentData?.dividendsData?.earnings_over_liveInvestment_after_fees_percentage?.toFixed(
                          2
                        )
                      : 0?.toFixed(2)}
                    %
                  </h1>
                )}
                <h2>Earning/Live Investment After Fees (%)</h2>
              </div>
              <div className="innerItemCard" style={{ borderRight: '0px' }}>
                {loader ? (
                  <Skeleton className="sectionskeletonHoding" />
                ) : (
                  <h1>
                    {investmentData?.withdrawalsData?.count > 0
                      ? investmentData?.withdrawalsData?.count?.toFixed(2)
                      : 0?.toFixed(2)}
                  </h1>
                )}
                <h2>Token Sales</h2>
              </div>

              <div className="innerItemCard">
                {loader ? (
                  <Skeleton className="sectionskeletonHoding" />
                ) : (
                  <h1>
                    {investmentData?.withdrawalsData?.withdraw_amount > 0
                      ? investmentData?.withdrawalsData?.withdraw_amount?.toFixed(
                          2
                        )
                      : 0?.toFixed(2)}
                  </h1>
                )}
                <h2>Sale Total Volume </h2>
              </div>
              <div className="innerItemCard" style={{ borderRight: '0px' }}>
                {loader ? (
                  <Skeleton className="sectionskeletonHoding" />
                ) : (
                  <h1>
                    {investmentData?.withdrawalsData?.average_sell_price > 0
                      ? investmentData?.withdrawalsData?.average_sell_price?.toFixed(
                          2
                        )
                      : 0?.toFixed(2)}
                  </h1>
                )}
                <h2>Avg Sale Price</h2>
              </div>

              <div className="innerItemCard">
                {loader ? (
                  <Skeleton className="sectionskeletonHoding" />
                ) : (
                  <h1>
                    {investmentData?.withdrawalsData?.average_sell_volume > 0
                      ? investmentData?.withdrawalsData?.average_sell_volume?.toFixed(
                          2
                        )
                      : 0?.toFixed(2)}
                  </h1>
                )}
                <h2>Avg Sale Volume</h2>
              </div>
              <div className="innerItemCard" style={{ borderRight: '0px' }}>
                {loader ? (
                  <Skeleton className="sectionskeletonHoding" />
                ) : (
                  <h1>
                    {investmentData?.withdrawalsData?.txn_fees > 0
                      ? investmentData?.withdrawalsData?.txn_fees?.toFixed(2)
                      : 0?.toFixed(2)}
                  </h1>
                )}
                <h2>Transaction Fees</h2>
              </div>

              <div className="innerItemCard">
                {loader ? (
                  <Skeleton className="sectionskeletonHoding" />
                ) : (
                  <h1>
                    {investmentData?.withdrawalsData?.withdraw_trade_fees > 0
                      ? investmentData?.withdrawalsData?.withdraw_trade_fees?.toFixed(
                          2
                        )
                      : 0?.toFixed(2)}
                  </h1>
                )}
                <h2>Withdrawal Variable Fee</h2>
              </div>
              <div className="innerItemCard" style={{ borderRight: '0px' }}>
                {loader ? (
                  <Skeleton className="sectionskeletonHoding" />
                ) : (
                  <h1>
                    {investmentData?.withdrawalsData?.withdraw_fixed_fees > 0
                      ? investmentData?.withdrawalsData?.withdraw_fixed_fees?.toFixed(
                          2
                        )
                      : 0?.toFixed(2)}
                  </h1>
                )}
                <h2>Withdrawal Fixed Fees</h2>
              </div>

              <div className="innerItemCard">
                {loader ? (
                  <Skeleton className="sectionskeletonHoding" />
                ) : (
                  <h1>
                    {investmentData?.withdrawalsData?.performance_fees > 0
                      ? investmentData?.withdrawalsData?.performance_fees?.toFixed(
                          2
                        )
                      : 0?.toFixed(2)}
                  </h1>
                )}
                <h2>Performance Fees</h2>
              </div>
              <div className="innerItemCard" style={{ borderRight: '0px' }}>
                {loader ? (
                  <Skeleton className="sectionskeletonHoding" />
                ) : (
                  <h1>
                    {investmentData?.withdrawalsData
                      ?.withdraw_amount_after_fees > 0
                      ? investmentData?.withdrawalsData?.withdraw_amount_after_fees?.toFixed(
                          2
                        )
                      : 0?.toFixed(2)}
                  </h1>
                )}
                <h2>Withdrawal Volume After Fees</h2>
              </div>
              <div className="innerItemCard">
                {loader ? (
                  <Skeleton className="sectionskeletonHoding" />
                ) : (
                  <h1>
                    {investmentData?.withdrawalsData
                      ?.average_sell_volume_after_fees > 0
                      ? investmentData?.withdrawalsData?.average_sell_volume_after_fees?.toFixed(
                          2
                        )
                      : 0?.toFixed(2)}
                  </h1>
                )}
                <h2>Avg Sale Volume After Fees</h2>
              </div>
              <div className="innerItemCard" style={{ borderRight: '0px' }}>
                {loader ? (
                  <Skeleton className="sectionskeletonHoding" />
                ) : (
                  <h1>
                    {investmentData?.withdrawalsData
                      ?.withdraw_amount_after_performance_fees > 0
                      ? investmentData?.withdrawalsData?.withdraw_amount_after_performance_fees?.toFixed(
                          2
                        )
                      : 0?.toFixed(2)}
                  </h1>
                )}
                <h2>Sale Volume After Performance Fees</h2>
              </div>

              <div className="innerItemCard">
                {loader ? (
                  <Skeleton className="sectionskeletonHoding" />
                ) : (
                  <h1>
                    {investmentData?.withdrawalsData
                      ?.average_sell_price_after_performance_fees > 0
                      ? investmentData?.withdrawalsData?.average_sell_price_after_performance_fees?.toFixed(
                          2
                        )
                      : 0?.toFixed(2)}
                  </h1>
                )}
                <h2>Avg Sale Price After Performance Fees</h2>
              </div>
              <div className="innerItemCard" style={{ borderRight: '0px' }}>
                {loader ? (
                  <Skeleton className="sectionskeletonHoding" />
                ) : (
                  <h1>
                    {investmentData?.withdrawalsData
                      ?.average_sell_volume_after_performance_fees > 0
                      ? investmentData?.withdrawalsData?.average_sell_volume_after_performance_fees?.toFixed(
                          2
                        )
                      : 0?.toFixed(2)}
                  </h1>
                )}
                <h2>Avg Sale Volume After Performance Fees</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
