import React, { useState, useContext, useEffect, useMemo } from 'react';
import { useMutation } from 'react-query';
import Skeleton from 'react-loading-skeleton';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link, useHistory } from 'react-router-dom';
import { LoadingAnimation } from '../../components/LoadingAnimation';
import eyeIcon from '../../static/images/icons/eye.svg';
import eyeSlash from '../../static/images/icons/eyeSlash.svg';
import classNames from './signInPage.module.scss';
import './login.scss';
import GlobalSidebar from '../../components/GlobalSidebar';
import { BankContext } from '../../context/Context';
import jwt_decode from 'jwt-decode';
import styles from './login.module.scss';
import close from './close1.svg';
import paste from './paste.svg';
import googleIcon from './google.svg';
import info from './info.svg';
import toast from 'react-hot-toast';

import emailIcon from './email.svg';
import emailChainIcon from './emailChain.svg';
import affiliateIcon from './affiliate.svg';
import myselfButton from './myselfButton.svg';
import affUsernameIcon from './affUsername.svg';
import appleIcon from './apple.svg';
import playStoreIcon from './androids.svg';
import mobilelogo from './Mobilelogo.svg';
import ios from './ios.svg';
import eye1 from './eye1.svg';
import eye2 from './eye2.svg';
import android from './android.svg';

import * as qs from 'qs';

import {
  loginFunc,
  registerOnApp,
  useRequestLoginChallenge,
  initiateForgotPassword,
  completeForgotPassword,
} from './api';
import { globalMenu } from './constants';
import { setBackgroundColor } from './helpers';
import { AppContext, useAppContextDetails } from '../../context/AppContext';
import { APP_CODE } from '../../config/appConfig';
import Cookies from 'js-cookie';
import { APP_USER_TOKEN } from '../../config';
import { forgetPass } from '../../static/data/constData';
import { useLoadAppDetails } from '../../queryHooks';
import useWindowDimensions from '../../utils/WindowSize';
import LoginMobile from './LoginMobile';
import axios from 'axios';
import OtpInput from 'react-otp-input';
import { useLocation } from 'react-router-dom';

function Login() {
  const { pathname } = useLocation();
  const splitArr = pathname.split('/');
  const { width, height } = useWindowDimensions();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [resetPasswordStep, setResetPasswordStep] = useState(0);
  const [requestChallengeResponse, setRequestChallengeResponse] = useState({});
  const [twoFactorStep, setTwoFactorStep] = useState(0);
  const [twoFactorCode, setTwoFactorCode] = useState('');
  const [message, setMessage] = useState('');
  const [forgotPassword, setForgotPassword] = useState(false);
  const [forgotPasswordStep, setForgotPasswordStep] = useState('1');
  const [sixDigitPin, setSixDigitPin] = useState('');
  const [confirmPassword1, setConfirmPassword1] = useState('');
  const [confirmPassword2, setConfirmPassword2] = useState('');
  const [forgotPasswordError, setForgotPasswordError] = useState('');
  const [isForgotPasswordError, setIsForgotPasswordError] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');
  const [isChanging, setIsChanging] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const { appByCode: appLoginByCode, appByCodeLoading: loginByCodeLoading } =
    useLoadAppDetails(APP_CODE);

  const [hasCapitalLetter, setHasCapitalLetter] = useState(false);
  const [hasSpecialCharacter, setHasSpecialCharacter] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasMinLength, setHasMinLength] = useState(false);

  const [hasCapitalLetterMob, setHasCapitalLetterMob] = useState(false);
  const [hasSpecialCharacterMob, setHasSpecialCharacterMob] = useState(false);
  const [hasNumberMob, setHasNumberMob] = useState(false);
  const [hasMinLengthMob, setHasMinLengthMob] = useState(false);
  const [affiliateMismatch, setAffiliateMismatch] = useState(false);
  const [affiliateMismatchName, setAffiliateMismatchName] = useState(false);
  const [affWrongEmail, setAffWrongEmail] = useState('');
  const [affWrongName, setAffWrongName] = useState('');

  const { userLoginHandler } = useContext(BankContext);
  const history = useHistory();
  const {
    appCode,
    appName,
    appLogo,
    appFullLogo,
    appColorCode,
    websiteTitle,
    websiteDescription,
    appDetailsLoading,
    responseData,
    setResponseData,
    showMoreInfo,
    setShowMoreInfo,
  } = useAppContextDetails();
  const { ownerEmail } = useContext(AppContext);

  const [selectedApp, setSelectedApp] = useState(globalMenu(appName)[0]);

  const [isLoading, setIsLoading] = useState(false);

  const [affEmail, setAffEmail] = useState('');
  const [fullName, setFullName] = useState('');
  const [idToken, setIdToken] = useState('');
  const [cognitoData, setCognitoData] = useState({});
  const [tempEmail, setTempEmail] = useState('');
  const [userNotFound, setUserNotFound] = useState(false);
  const [incorrectCredentials, setIncorrectCredentials] = useState(false);

  const [loggedinBeforeStep, setLoggedinBeforeStep] = useState(0);

  const [mobileStep, setMobileStep] = useState(0);
  const [affUsername, setAffUsername] = useState('');
  const [affData, setAffData] = useState({});
  const [mobileLoading, setMobileLoading] = useState(false);

  const [userEmail, setUserEmail] = useState('');
  const [userUsername, setUserUsername] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [userConfirmPassword, setUserConfirmPassword] = useState('');
  const [userOtp, setUserOtp] = useState('');
  const [available, setAvailable] = useState(false);
  const [otpMisMatch, setOtpMisMatch] = useState(false);
  const [appLinks, setAppLinks] = useState(null);
  const [fromSso, setFromSso] = useState(false);

  const [moreInfoStep, setMoreInfoStep] = useState(0);
  const [checked, setChecked] = useState(false);

  const handleLoginSuccess = (data) => {
    // console.log(data, 'login data');
    localStorage.setItem('accessToken aiprowallet', data?.accessToken);

    userLoginHandler(email, data.accessToken, data.idToken);
    registerApp({ email, app_code: APP_CODE });
    registerApp({ email, app_code: 'ice' });
    Cookies.set(APP_USER_TOKEN, data.idToken);
    history.push('/app');
    setMessage('');
  };

  const handleLoginSuccess1 = (data, email) => {
    // console.log(
    //   email,
    //   data?.access_token,
    //   data?.id_token,
    //   'knadklnawlkdhklqwhdwql'
    // );
    userLoginHandler(email, data.access_token, data.id_token);
    registerApp({ email, app_code: APP_CODE });
    registerApp({ email, app_code: 'ice' });
    Cookies.set(APP_USER_TOKEN, data.id_token);
    history.push('/app');
    setMessage('');
  };

  const { mutate: registerApp } = useMutation(registerOnApp, {});

  const {
    isLoading: isLoggingIn,
    mutate: attemptLogin,
    data: loginAttemptResponse,
  } = useMutation(loginFunc, {
    onSuccess: (data) => {
      if (data.status) {
        setUserNotFound(false);
        setIncorrectCredentials(false);
        handleLoginSuccess(data);
      } else if (!data?.status && data?.message === 'User not Found!') {
        setTempEmail(email);
        setUserNotFound(true);
      } else if (
        !data?.status &&
        data?.message === 'Incorrect username or password.'
      ) {
        setIncorrectCredentials(true);
      }
    },
  });

  const {
    mutate: requestLoginChallenge,
    isLoading: isRequestingLoginChallenge,
    isSuccess: isRequestLoginChallengeSuccess,
    data: requestLoginChallengeResponse,
  } = useRequestLoginChallenge();

  useEffect(() => {
    if (
      isRequestLoginChallengeSuccess &&
      requestLoginChallengeResponse?.data?.status
    ) {
      handleLoginSuccess(requestLoginChallengeResponse?.data);
    }
  }, [isRequestLoginChallengeSuccess]);

  const isResetPassword =
    loginAttemptResponse?.message === 'Reset User Password!' &&
    loginAttemptResponse?.resetPassword &&
    loginAttemptResponse?.challengeName === 'NEW_PASSWORD_REQUIRED';

  const isTwoFactorEnabled =
    loginAttemptResponse?.message === 'Enter the Authenticator Code!' &&
    loginAttemptResponse?.mfa &&
    loginAttemptResponse?.challengeName === 'SOFTWARE_TOKEN_MFA';

  useEffect(() => {
    if (loginAttemptResponse?.message === 'Incorrect username or password.') {
      setMessage(
        'You Have Entered Incorrect Login Credentials. Please Try Again'
      );
    } else if (isResetPassword) {
      setMessage('For Security Reasons You Need To Change Your Password');
      setRequestChallengeResponse({
        authChallenge_id: loginAttemptResponse?.authChallenge_id,
        email: loginAttemptResponse?.email,
        username: loginAttemptResponse?.username,
        challengeName: loginAttemptResponse?.challengeName,
        session: loginAttemptResponse?.session,
      });
      setResetPasswordStep(1);
    } else if (isTwoFactorEnabled) {
      setMessage('Please Enter Your 2 Factor Authentication');
      setRequestChallengeResponse({
        authChallenge_id: loginAttemptResponse?.authChallenge_id,
        email: loginAttemptResponse?.email,
        username: loginAttemptResponse?.username,
        challengeName: loginAttemptResponse?.challengeName,
        session: loginAttemptResponse?.session,
      });
      setTwoFactorStep(1);
    }
  }, [loginAttemptResponse]);

  const defaultApp = useMemo(
    () => ({
      appName,
      appLogo,
      appFullLogo,
      appColor: appColorCode,
    }),
    [appCode, appName, appLogo, appFullLogo, appColorCode]
  );

  const rightSideContent = globalMenu(appName).filter(
    (item) => selectedApp.appName === item.appName
  )?.[0]?.content;
  const arrayOfContent = rightSideContent?.split(' ');

  const joinedContent = arrayOfContent && (
    <div>
      {arrayOfContent[0]} <br /> {arrayOfContent[1]} {arrayOfContent[2]}
      <br />
      {appName}
    </div>
  );

  useEffect(() => {
    const favIcon = document.getElementById('fav-icon');
    favIcon.href = defaultApp.appLogo;

    if (defaultApp) {
      setSelectedApp(defaultApp);
    } else {
      setSelectedApp(globalMenu(appName)[0]);
    }
  }, [defaultApp]);

  const handleRegisterClick = () => {
    Cookies.remove('app_user_token');
  };

  const backgroundColor = setBackgroundColor(selectedApp?.appColor);

  const initializeForgotPassword = () => {
    setIsChanging(true);
    let obj = {
      email,
      app_code: APP_CODE,
    };
    initiateForgotPassword(obj).then((response) => {
      let result = response.data;
      setIsChanging(false);
      if (result.status) {
        setForgotPasswordStep('2');
        setForgotPasswordError('');
      } else {
        setForgotPasswordEmail(email);
        setForgotPasswordError(result?.message);
        if (result?.message === 'User is not Found!!') {
          setIsForgotPasswordError(true);
        }
      }
    });
  };

  const completeForgotPass = () => {
    setIsChanging(true);
    let obj = {
      email,
      code: sixDigitPin,
      newPassword: confirmPassword2,
    };
    completeForgotPassword(obj).then((response) => {
      let result = response.data;
      setIsChanging(false);

      if (result.status && result.message === 'Password Succesfully changed') {
        setPasswordChanged(true);
        setForgotPasswordError();
      } else {
        setForgotPasswordError(result.message);
      }
      setForgotPasswordStep('1');
      setForgotPassword(false);
      setShowPassword(false);
      setEmail('');
      setPassword('');
      setSixDigitPin('');
      setConfirmPassword1('');
      setConfirmPassword2('');
      setLoggedinBeforeStep(0);
    });
  };

  useEffect(() => {
    if (window.location.search.includes('code')) {
      handleSSOCallback();
    }
  }, []);

  // const deleteSSOUser = (username) => {
  //   axios.post(`https://gxauth.apimachine.com/gx/user/cognito/delete`, {
  //     cognito_username: username,
  //   });
  // };

  const checkDB = (email, username, cogData, authorizationCode) => {
    axios
      .post(`https://gxauth.apimachine.com/gx/user/email/exist`, {
        email: email,
      })
      .then(({ data }) => {
        if (data.status) {
          if (data?.data?.sso_g) {
            if (window.innerWidth > 600) {
              handleLoginSuccess1(cogData, email);
            } else {
              // userLoginHandler(email, cogData.access_token, cogData.id_token);
              registerApp({ email, app_code: APP_CODE });
              registerApp({ email, app_code: 'ice' });
              // Cookies.set(APP_USER_TOKEN, cogData.id_token);
              setMobileStep(12);

              // const loginData = {
              //   email: email,
              //   app_code: APP_CODE,
              //   access_token: cogData.access_token,
              //   id_token: cogData.id_token,
              // };
              // const encoded = JSON.stringify(loginData);
              // const params = new URLSearchParams(loginData).toString();

              const url = `aiprowallet://Landing/data?email=${email}&idToken=${cogData.id_token}&accessToken=${cogData.access_token}`;
              window.location.href = url;
            }
          } else {
            // deleteSSOUser(username);
            toast('This Email has already been used.', {
              duration: 4000,
              position: 'top-left',
            });
          }
        } else {
          if (window.innerWidth > 600) {
            setShowMoreInfo(true);
          } else {
            setMobileStep(2);
            setFromSso(true);
          }
        }
      });
  };

  const saveToDB = () => {
    axios
      .post(`https://gxauth.apimachine.com/gx/user/sso/signup`, {
        email: cognitoData?.email,
        ref_affiliate:
          window.innerWidth > 600
            ? affEmail
            : affData?.hardCoded[0]?.data?.username, // reference/upline affiliate-id
        app_code: 'dgpayments',
        client_app: '',
        user_full_name: fullName,
        token: cognitoData?.data?.id_token,
      })
      .then(({ data }) => {
        // console.log(data, 'data');
        if (data.status) {
          if (window.innerWidth > 600) {
            handleLoginSuccess1(cognitoData.data, cognitoData.email);
          } else {
            registerApp({ email: cognitoData.email, app_code: APP_CODE });
            registerApp({ email: cognitoData.email, app_code: 'ice' });
            // Cookies.set(APP_USER_TOKEN, cogData.id_token);
            setMobileStep(12);
          }
        } else {
          toast('Something went wrong.', {
            duration: 4000,
            position: 'top-left',
          });
        }
      });
  };

  const handleSSOCallback = async () => {
    setIsLoading(true);

    const authorizationCode = new URLSearchParams(window.location.search).get(
      'code'
    );

    try {
      var authCode =
        'NjRmcGZnNjl1MWZlNnZjNHBnYWxodGp1MHM6OWN1cDI2OHVqNnNxOG04MjRmMGFxdDNjNm42bWsxOWZsMWR2OGFhNWdya29icmszN2lz';
      var requestData = qs.stringify({
        grant_type: 'authorization_code',
        code: authorizationCode,
        redirect_uri: 'https://my.aiprowallet.com',
      });
      // console.log(requestData, 'kjbefkjwebfkjwhefkwhkkjwbfkejwbfkjwe');
      axios
        .post(
          `https://gxnitrossso.auth.us-east-2.amazoncognito.com/oauth2/token`,
          requestData,
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              Authorization: 'Basic ' + authCode,
            },
          }
        )
        .then(({ data }) => {
          // console.log(data, 'data getting from cognito');
          const decoded = jwt_decode(data.id_token);
          setCognitoData({
            email: decoded.email,
            data: data,
          });
          checkDB(
            decoded.email,
            decoded['cognito:username'],
            data,
            authorizationCode
          );
          // handleLoginSuccess1(data, decoded.email);
          // localStorage.setItem('nvestBankLoginAccount', decoded.email);
          // localStorage.setItem('nvestBankIdToken', data.id_token);
          // localStorage.setItem('nvestBankAccessToken', data.access_token);
          // userLoginHandler(decoded.email, data.access_token, data.id_token);
          // registerApp({ email: decoded.email, app_code: APP_CODE });
          // Cookies.set(APP_USER_TOKEN, data.idToken);
          // history.push('/app');
          // setMessage('');
          // localStorage.setItem('decoded', JSON.stringify(decoded));

          // localStorage.setItem('responseData', JSON.stringify(data));
          // localStorage.setItem('access_token', data.access_token);
          // localStorage.setItem('id_token', data.id_token);
          // const accessToken = data.access_token;
          // const email = data.email;
        });
    } catch (err) {
      console.error(
        'Error exchanging authorization code for access token:',
        err
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handlePasteClick = async () => {
    const clipboardData = await navigator.clipboard.readText();
    setSixDigitPin(clipboardData);
  };

  function handleChange(event) {
    const password = event.target.value;
    const capitalRegex = /[A-Z]/;
    const specialRegex = /[!@#$%^&*(),.?":{}|<>]/;
    const numberRegex = /\d/;

    setHasCapitalLetter(capitalRegex.test(password));
    setHasSpecialCharacter(specialRegex.test(password));
    setHasNumber(numberRegex.test(password));
    setHasMinLength(password.length >= 7);
  }

  function handleChangeMob(event) {
    const password = event.target.value;
    const capitalRegex = /[A-Z]/;
    const specialRegex = /[!@#$%^&*(),.?":{}|<>]/;
    const numberRegex = /\d/;

    setHasCapitalLetterMob(capitalRegex.test(password));
    setHasSpecialCharacterMob(specialRegex.test(password));
    setHasNumberMob(numberRegex.test(password));
    setHasMinLengthMob(password.length >= 7);
  }

  const conditionalMoreInfo = () => {
    if (moreInfoStep === 0) {
      return (
        <>
          <div
            // className={styles.forgotPassword}
            style={{
              textAlign: 'left',
              fontSize: '20px',
              marginTop: '-4%',
              marginBottom: '5%',
              fontWeight: '400',
              textAlign: 'center',
              width: '70%',
              lineHeight: '1.5',
              marginBottom: '4rem',
            }}
          >
            You have successfully verified your Google account. Please fill out
            the remaining information to complete your registration
          </div>

          <input
            type="text"
            placeholder="Your Full Name"
            className={styles.input2}
            value={fullName}
            onChange={(e) => {
              setMessage('');
              setFullName(e.target.value);
            }}
          />

          <div
            className={styles.loginButton}
            onClick={() => {
              if (fullName) {
                setMoreInfoStep(1);
              } else {
                setMessage('Please Fill All The Fields');
              }
            }}
          >
            Next Step
          </div>

          {/* <div
            onClick={(e) => handleRegisterClick()}
            className={styles.forgotPassword}
            style={{ textAlign: 'center', paddingTop: '4%' }}
          >
            Click Here To Register With Email
          </div> */}
        </>
      );
    } else if (moreInfoStep === 1) {
      return (
        <>
          <div
            className={styles.forgotPassword}
            style={{
              textAlign: 'left',
              fontSize: '20px',
              marginTop: '-4%',
              marginBottom: '5%',
              fontWeight: '400',
              textAlign: 'center',
              width: '70%',
              lineHeight: '1.5',
              marginBottom: '4rem',
            }}
          >
            Enter The Email Or Username Of The Person Who Referred You?
          </div>
          <input
            disabled={affUsername.length > 0 || checked ? true : false}
            type="email"
            placeholder="Affiliate’s Email"
            className={styles.input1}
            value={affEmail}
            onChange={(e) => {
              setAffUsername('');
              setAffEmail(e.target.value.toLowerCase());
            }}
          />
          <div className={styles.seperateBorder}>&nbsp;OR&nbsp;</div>
          <input
            disabled={affEmail.length > 0 || checked ? true : false}
            type="text"
            placeholder="Affiliate’s Username"
            className={styles.input2}
            value={affUsername}
            onChange={(e) => {
              setAffEmail('');
              setAffUsername(e.target.value);
            }}
          />
          <div
            style={{
              fontSize: '1rem',
              fontWeight: 400,
              paddingTop: '30px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <input
              disabled={affEmail.length > 0 || affUsername.length > 0}
              style={{ width: '20px', height: '20px' }}
              type="checkbox"
              id="vehicle1"
              name="vehicle1"
              checked={checked}
              onChange={(e) => {
                setAffEmail('');
                setAffUsername('');
                setChecked(e.target.checked);
              }}
            />
            <label for="vehicle1" style={{ margin: 0 }}>
              &nbsp;&nbsp; Click Here If You Got Here By Yourself
            </label>
          </div>
          <div
            className={styles.loginButton}
            onClick={() => {
              if (affEmail.length > 0 || affUsername.length > 0 || checked) {
                if (affEmail.length > 0) {
                  getAffDataEmail(affEmail);
                } else if (affUsername.length > 0) {
                  getAffDataUsername(affUsername);
                } else {
                  getAffDataEmail(ownerEmail);
                }
              } else {
                toast('Fill required fields', {
                  duration: 4000,
                  position: 'top-left',
                });
              }
            }}
          >
            {mobileLoading
              ? 'Loading...'
              : checked
              ? 'Finish Registration'
              : 'Next Step'}
          </div>
          <div
            className={styles.loginButton}
            style={{ marginTop: '20px', backgroundColor: '#E73625' }}
            onClick={() => {
              setMoreInfoStep(0);
            }}
          >
            Go Back
          </div>
          {/* <div
            onClick={(e) => handleRegisterClick()}
            className={styles.forgotPassword}
            style={{ textAlign: 'center', paddingTop: '4%' }}
          >
            Click Here To Register With Email
          </div> */}
        </>
      );
    } else if (moreInfoStep === 2) {
      return (
        <>
          <div
            className={styles.forgotPassword}
            style={{
              textAlign: 'left',
              fontSize: '20px',
              marginTop: '-4%',
              marginBottom: '5%',
              fontWeight: '400',
              textAlign: 'center',
              width: '70%',
              lineHeight: '1.5',
              marginBottom: '4rem',
            }}
          >
            Is This The Person Who Referred You?
          </div>
          <div className={styles.optionCards} style={{ width: '70%' }}>
            <img
              src={affData?.dynamic[0]?.data?.profile_img}
              alt=""
              style={{
                width: '5vh',
                height: '5vh',
                marginRight: '4%',
                borderRadius: '50%',
              }}
            />
            <div className={styles.dataStyle}>
              <div>{affData?.hardCoded[0]?.data?.username}</div>
              <div style={{ fontWeight: 400, fontSize: '14px' }}>
                {affData?.email}
              </div>
            </div>
          </div>

          <div
            className={styles.loginButton}
            onClick={() => {
              saveToDB();
            }}
          >
            Next Step
          </div>
          <div
            className={styles.loginButton}
            style={{ marginTop: '20px', backgroundColor: '#E73625' }}
            onClick={() => {
              setMoreInfoStep(1);
            }}
          >
            No Its Not
          </div>
          {/* <div
            onClick={(e) => handleRegisterClick()}
            className={styles.forgotPassword}
            style={{ textAlign: 'center', paddingTop: '4%' }}
          >
            Click Here To Register With Email
          </div> */}
        </>
      );
    }
  };

  const conditionalForm = () => {
    if (forgotPassword && forgotPasswordStep === '1') {
      return (
        <>
          <div
            className="errorMessagePara"
            style={{ width: '70%', paddingBottom: '20px' }}
          >
            Enter Your Email
          </div>
          <div className={styles.input1}>
            <input
              value={email}
              onChange={(e) => {
                setMessage('');
                setEmail(e.target.value.toLowerCase());
                if (isForgotPasswordError) {
                  setIsForgotPasswordError(false);
                }
              }}
              type="email"
              placeholder="Email"
            />
            <img
              src={close}
              alt="close"
              className={styles.closeImg}
              onClick={() => {
                setEmail('');
                if (isForgotPasswordError) {
                  setIsForgotPasswordError(false);
                }
              }}
            />
          </div>

          {isForgotPasswordError && (
            <div
              style={{
                background: '#FFFDF2',
                borderRadius: '15px',
                padding: '1rem 1.5rem',
                fontSize: '0.9rem',
                fontWeight: '405',
                display: 'flex',
                gap: '10px',
                alignItems: 'center',
                width: '70%',
              }}
            >
              <div>
                <img src={info} alt="" />
              </div>
              <div>
                We are not able to find a user with the email{' '}
                {forgotPasswordEmail}. Try again.
              </div>
            </div>
          )}

          <div
            className={styles.loginButton}
            onClick={() => {
              setForgotPasswordError('');
              setPasswordChanged(false);
              if (email?.length > 3) {
                initializeForgotPassword();
              }
            }}
            style={{
              opacity: isForgotPasswordError
                ? '0.25'
                : email.length < 3
                ? '0.25'
                : '1',
              pointerEvents: isForgotPasswordError
                ? 'none'
                : email.length < 3
                ? 'none'
                : '',
            }}
          >
            Next
          </div>
          <div className={styles.buttonDiv}>
            <div
              className={styles.gbBtn}
              style={{ opacity: '0.5', cursor: 'not-allowed' }}
            >
              Go Back
            </div>
            <div
              className={styles.gbBtn}
              onClick={() => {
                setForgotPassword(false);
                setLoggedinBeforeStep(0);
              }}
            >
              Never Mind
            </div>
          </div>
        </>
      );
    } else if (forgotPassword && forgotPasswordStep === '2') {
      return (
        <>
          <div
            className="errorMessagePara"
            style={{ width: '70%', paddingBottom: '20px' }}
          >
            Enter The Six Digit Code Sent To{' '}
            <span style={{ fontWeight: '600' }}>{email}</span>
          </div>
          <div className={styles.input1}>
            <input
              value={sixDigitPin}
              onChange={(e) => setSixDigitPin(e.target.value)}
              placeholder="Code"
              maxLength={6}
              minLength={6}
            />
            <img
              src={paste}
              alt="paste"
              className={styles.closeImg}
              onClick={handlePasteClick}
            />
          </div>
          <div
            className={styles.loginButton}
            onClick={() => {
              if (sixDigitPin.length === 6) {
                setForgotPasswordStep('3');
              }
            }}
            style={{
              opacity: sixDigitPin.length === 6 ? '1' : '0.25',
              pointerEvents: sixDigitPin.length === 6 ? '' : 'none',
            }}
          >
            Next
          </div>
          <div className={styles.buttonDiv}>
            <div
              className={styles.gbBtn}
              onClick={() => {
                setForgotPasswordStep('1');
                setSixDigitPin('');
              }}
            >
              Go Back
            </div>
            <div
              className={styles.gbBtn}
              onClick={() => {
                setForgotPassword(false);
                setLoggedinBeforeStep(0);
                setForgotPasswordStep('1');
                setSixDigitPin('');
              }}
            >
              Never Mind
            </div>
          </div>
        </>
      );
    } else if (forgotPassword && forgotPasswordStep === '3') {
      return (
        <>
          <div
            className="errorMessagePara"
            style={{ width: '70%', paddingBottom: '20px' }}
          >
            Please Enter Your New Password
          </div>
          <div className={styles.input1}>
            <input
              value={confirmPassword1}
              onChange={(e) => {
                setConfirmPassword1(e.target.value);
                handleChange(e);
              }}
              type={showPassword ? 'text' : 'password'}
              placeholder="Password"
            />
            <div
              className={styles.indicator}
              style={{
                background:
                  hasCapitalLetter &&
                  hasMinLength &&
                  hasNumber &&
                  hasSpecialCharacter
                    ? '#B5E6EA'
                    : '#dc3027',
              }}
            ></div>
            <div className={styles.eyeImg}>
              <img
                src={showPassword ? eye2 : eye1}
                alt=""
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
              />
            </div>
          </div>

          <div className={styles.conditions}>
            <div className={styles.eachCondition}>
              Minimum One Capital Letter
              <div
                className={styles.indicator}
                style={{
                  background: hasCapitalLetter
                    ? '#B5E6EA'
                    : 'rgba(220, 48, 39, 0.37)',
                }}
              ></div>
            </div>
            <div className={styles.eachCondition}>
              Minimum One Special Character
              <div
                className={styles.indicator}
                style={{
                  background: hasSpecialCharacter
                    ? '#B5E6EA'
                    : 'rgba(220, 48, 39, 0.37)',
                }}
              ></div>
            </div>
            <div className={styles.eachCondition}>
              Minimum Seven Characters
              <div
                className={styles.indicator}
                style={{
                  background: hasMinLength
                    ? '#B5E6EA'
                    : 'rgba(220, 48, 39, 0.37)',
                }}
              ></div>
            </div>
            <div className={styles.eachCondition}>
              Minimum One Number
              <div
                className={styles.indicator}
                style={{
                  background: hasNumber ? '#B5E6EA' : 'rgba(220, 48, 39, 0.37)',
                }}
              ></div>
            </div>
          </div>

          <div
            className={styles.loginButton}
            onClick={() => {
              if (
                hasCapitalLetter &&
                hasMinLength &&
                hasNumber &&
                hasSpecialCharacter
              ) {
                setForgotPasswordStep('4');
              }
            }}
            style={{
              marginTop: '0',
              opacity:
                hasCapitalLetter &&
                hasMinLength &&
                hasNumber &&
                hasSpecialCharacter
                  ? '1'
                  : '0.25',
            }}
          >
            Next
          </div>
          <div className={styles.buttonDiv}>
            <div
              className={styles.gbBtn}
              style={{ opacity: '0.5', cursor: 'not-allowed' }}
            >
              Go Back
            </div>
            <div
              className={styles.gbBtn}
              onClick={() => {
                setForgotPassword(false);
                setLoggedinBeforeStep(0);
                setForgotPasswordStep('1');
                setSixDigitPin('');
                setConfirmPassword1('');
              }}
            >
              Never Mind
            </div>
          </div>
        </>
      );
    } else if (forgotPassword && forgotPasswordStep === '4') {
      return (
        <>
          <div
            className="errorMessagePara"
            style={{ width: '70%', paddingBottom: '20px' }}
          >
            Please Confirm Your New Password
          </div>
          <div className={styles.input1}>
            <input
              value={confirmPassword2}
              onChange={(e) => setConfirmPassword2(e.target.value)}
              type={showPassword ? 'text' : 'password'}
              placeholder="Password"
            />
            <div
              className={styles.indicator}
              style={{
                background:
                  confirmPassword1 === confirmPassword2 ? '#B5E6EA' : '#dc3027',
              }}
            ></div>
            <div className={styles.eyeImg}>
              <img
                src={showPassword ? eye2 : eye1}
                alt=""
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
              />
            </div>
          </div>

          <div
            className={styles.loginButton}
            style={{
              opacity: confirmPassword1 === confirmPassword2 ? 1 : 0.25,
            }}
            onClick={() => {
              if (confirmPassword1 === confirmPassword2) {
                completeForgotPass();
              } else {
                setForgotPasswordError("Password doesn't Match");
              }
            }}
          >
            Next
          </div>
          <div className={styles.buttonDiv}>
            <div
              className={styles.gbBtn}
              onClick={() => {
                setConfirmPassword2('');
                setForgotPasswordStep('3');
              }}
            >
              Go Back
            </div>
            <div
              className={styles.gbBtn}
              onClick={() => {
                setForgotPassword(false);
                setLoggedinBeforeStep(0);
                setForgotPasswordStep('1');
                setSixDigitPin('');
                setConfirmPassword1('');
              }}
            >
              Never Mind
            </div>
          </div>
        </>
      );
    } else if (resetPasswordStep === 1 && isResetPassword) {
      return (
        <>
          <div>
            {message && <p style={{ fontSize: '15px' }}>{message}</p>}
            <div>
              <input
                value={password}
                onChange={(e) => {
                  setMessage('');
                  setPassword(e.target.value);
                }}
                className={styles.input1}
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
              />
              {/* <img
                className="eye"
                src={showPassword ? eyeSlash : eyeIcon}
                onClick={() => setShowPassword(!showPassword)}
              /> */}
            </div>
          </div>
          <div
            // className="btnLogin"
            // style={backgroundColor}
            className={styles.loginButton}
            onClick={() => setResetPasswordStep(2)}
          >
            Next
          </div>
        </>
      );
    } else if (resetPasswordStep === 2 && isResetPassword) {
      return (
        <>
          <div>
            <p style={{ fontSize: '15px' }}>
              Please Confirm The Password You Just Entered
            </p>
            <div className="pt-5 pb-5 passwordWrap">
              <input
                value={confirmPassword}
                onChange={(e) => {
                  setMessage('');
                  setConfirmPassword(e.target.value);
                }}
                className={styles.input1}
                // className="inputLogin"
                // type={showPassword ? 'text' : 'password'}
                type="password"
                placeholder="Password"
              />
              {/* <img
                className="eye"
                src={showPassword ? eyeSlash : eyeIcon}
                onClick={() => setShowPassword(!showPassword)}
              /> */}
            </div>
          </div>
          <div
            // className="btnLogin"
            // style={backgroundColor}
            className={styles.loginButton}
            onClick={() => {
              requestLoginChallenge({
                authChallenge_id: requestChallengeResponse?.authChallenge_id,
                email: requestChallengeResponse?.email,
                username: requestChallengeResponse?.username,
                challengeName: requestChallengeResponse?.challengeName,
                session: requestChallengeResponse?.session,
                newPassword: confirmPassword,
              });
            }}
          >
            Change Password
          </div>
        </>
      );
    } else if (twoFactorStep === 1 && isTwoFactorEnabled) {
      return (
        <div>
          <p style={{ fontSize: '15px' }}>
            Please Enter Your 2 Factor Authentication
          </p>
          <div className="pt-5 pb-5 passwordWrap">
            <input
              value={twoFactorCode}
              onChange={(e) => {
                setMessage('');
                setTwoFactorCode(e.target.value);
              }}
              className={styles.input1}
              // className="inputLogin"
              type={showPassword ? 'text' : 'password'}
              placeholder="Six Digit Code"
            />
          </div>
          <div
            className={styles.loginButton}
            // className="btnLogin"
            // style={backgroundColor}
            onClick={() => {
              requestLoginChallenge({
                authChallenge_id: requestChallengeResponse?.authChallenge_id,
                email: requestChallengeResponse?.email,
                username: requestChallengeResponse?.username,
                challengeName: requestChallengeResponse?.challengeName,
                session: requestChallengeResponse?.session,
                totp_code: twoFactorCode,
              });
            }}
          >
            Confirm
          </div>
        </div>
      );
    } else {
      if (showMoreInfo) {
        return conditionalMoreInfo();
      } else {
        return (
          <>
            <input
              type="email"
              placeholder="Email"
              className={styles.input1}
              value={email}
              onChange={(e) => {
                setMessage('');
                setEmail(e.target.value.toLowerCase());
              }}
            />
            <div className={styles.input2}>
              <input
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                value={password}
                onChange={(e) => {
                  setMessage('');
                  setPassword(e.target.value);
                }}
              />
              <img
                src={showPassword ? eye2 : eye1}
                alt=""
                className={styles.eyeImg}
                onClick={() => setShowPassword(!showPassword)}
              />
            </div>

            <div
              className={styles.forgotPassword}
              style={{
                paddingBottom: userNotFound
                  ? '1.5rem'
                  : incorrectCredentials
                  ? '1.5rem'
                  : '0',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <div
                onClick={() => {
                  setForgotPassword(!forgotPassword);
                  setLoggedinBeforeStep("can't login");
                }}
              >
                Forgot Password
              </div>
            </div>

            {userNotFound && (
              <div
                style={{
                  background: '#FFFDF2',
                  borderRadius: '15px',
                  padding: '1rem 1.5rem',
                  fontSize: '0.9rem',
                  fontWeight: '405',
                  display: 'flex',
                  gap: '10px',
                  alignItems: 'center',
                  width: '70%',
                }}
              >
                <div>
                  <img src={info} alt="" />
                </div>
                <div>
                  Sorry but we are not able to find a user with the email{' '}
                  {tempEmail}.
                </div>
              </div>
            )}

            {incorrectCredentials && (
              <div
                style={{
                  background: '#FFFDF2',
                  borderRadius: '15px',
                  padding: '1rem 1.5rem',
                  fontSize: '0.9rem',
                  fontWeight: '405',
                  display: 'flex',
                  gap: '10px',
                  alignItems: 'center',
                  width: '70%',
                }}
              >
                <div>
                  <img src={info} alt="" />
                </div>
                <div>
                  The credentials you entered are incorrect. Please try again or
                  reset your password.
                </div>
              </div>
            )}

            <div
              className={styles.loginButton}
              onClick={() => {
                setForgotPasswordError('');
                setPasswordChanged(false);
                if (appLoginByCode) {
                  attemptLogin({ email, password });
                }
              }}
              style={{ opacity: isLoggingIn ? '0.25' : '1' }}
            >
              {isLoggingIn ? 'Loading...' : 'Login'}
            </div>
            <div
              className={styles.googleLogin}
              onClick={() => {
                window.open(
                  'https://gxnitrossso.auth.us-east-2.amazoncognito.com/oauth2/authorize?identity_provider=Google&client_id=64fpfg69u1fe6vc4pgalhtju0s&response_type=code&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=https%3A%2F%2Fmy.aiprowallet.com'
                );
              }}
            >
              <img
                src={googleIcon}
                alt=""
                style={{ width: '2vh', height: '2vh', marginRight: '4%' }}
              />
              <div>Login With Google</div>
            </div>
            {/* <div
              onClick={(e) => handleRegisterClick()}
              className={styles.forgotPassword}
              style={{ textAlign: 'center', paddingTop: '4%' }}
            >
              Click Here To Register With Email
            </div> */}
          </>
        );
      }
    }
  };

  const conditionalLoggedInBefore = () => {
    switch (loggedinBeforeStep) {
      case "can't login":
        return (
          <>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div className={styles.rightlabel}>Logged In Before</div>
              <div
                className={styles.rightlabel}
                style={{
                  cursor: 'pointer',
                  opacity: forgotPassword ? '0.25' : '1',
                  pointerEvents: forgotPassword ? 'none' : '',
                }}
                onClick={() => setLoggedinBeforeStep(0)}
              >
                Back
              </div>
            </div>
            <div className={styles.rightCardnoHover} style={{ height: '100%' }}>
              <div className={styles.rightCardTitle}>
                Im An Existing AiProWallet User But I Can’t Login
              </div>
              <div className={styles.rightCardSubTitle}>
                We apologize for that. Click here and lets find out why you are
                not able to login to your AiProWallet Account.
              </div>

              <div
                className={styles.rightCardTitle}
                style={{
                  fontSize: '1.8vh',
                  paddingTop: '10%',
                  paddingBottom: '5%',
                }}
              >
                When are you getting your issue?
              </div>
              <div className={styles.options}>When trying to login</div>
              <div
                className={styles.options}
                onClick={(e) => setLoggedinBeforeStep('reset password')}
              >
                When trying to reset password
              </div>
              <div className={styles.options}>
                When trying to enter the 2FA code
              </div>
            </div>
          </>
        );
        break;
      case 'reset password':
        return (
          <>
            <div className={styles.rightlabel}>Logged In Before</div>
            <div className={styles.rightCardnoHover} style={{ height: '100%' }}>
              <div className={styles.rightCardTitle}>
                Im An Existing AiProWallet User But I Can’t Login
              </div>
              <div className={styles.rightCardSubTitle}>
                We apologize for that. Click here and lets find out why you are
                not able to login to your AiProWallet Account.
              </div>

              <div
                className={styles.options}
                style={{ marginTop: '5%' }}
                onClick={(e) => setLoggedinBeforeStep("can't login")}
              >
                When trying to reset password
              </div>
              <div
                className={styles.rightCardTitle}
                style={{
                  fontSize: '1.7vh',
                  paddingTop: '5%',
                  paddingBottom: '5%',
                }}
              >
                What’s happening when you try to reset your password?
              </div>
              <div
                className={styles.options}
                onClick={(e) => setLoggedinBeforeStep('not getting email')}
              >
                Im not getting the email
              </div>
            </div>
          </>
        );
        break;
      case 'not getting email':
        return (
          <>
            <div className={styles.rightlabel}>Logged In Before</div>
            <div className={styles.rightCardnoHover} style={{ height: '100%' }}>
              <div className={styles.rightCardTitle}>
                Im An Existing AiProWallet User But I Can’t Login
              </div>
              <div className={styles.rightCardSubTitle}>
                We apologize for that. Click here and lets find out why you are
                not able to login to your AiProWallet Account.
              </div>

              <div
                className={styles.options}
                style={{ marginTop: '5%' }}
                onClick={(e) => setLoggedinBeforeStep("can't login")}
              >
                When trying to reset password
              </div>
              <div
                onClick={(e) => setLoggedinBeforeStep('reset password')}
                className={styles.options}
                // style={{ marginTop: '5%' }}
              >
                Im not getting the email
              </div>
              <div
                className={styles.rightCardTitle}
                style={{
                  fontSize: '1.7vh',
                  paddingTop: '5%',
                  paddingBottom: '5%',
                }}
              >
                Pick a solution
              </div>
              <div className={styles.optionsDisabled} style={{ opacity: 0.5 }}>
                Switch to login with Google which doesn’t need a password
              </div>
              <div
                className={styles.options}
                onClick={(e) => {
                  window.open('https://t.me/shorupan', '_blank');
                }}
              >
                Request an administrative reset
              </div>
            </div>
          </>
        );
      default:
        return (
          <>
            <div className={styles.rightlabel}>Never Logged In Before</div>
            <div
              className={styles.rightCardActive}
              onClick={(e) => {
                handleRegisterClick();
                history.push('register/pre-registered');
              }}
            >
              <div className={styles.rightCardTitle}>
                I Was Pre-Registered By An AiProWallet Affiliate
              </div>
              <div className={styles.rightCardSubTitle}>
                This means you have already received an email with your
                temporary login credentials for AiProWallet.{' '}
              </div>
            </div>
            <div className={styles.rightCardActive}>
              <div className={styles.rightCardTitle}>
                I Used To Be A DGG.ai User
              </div>
              <div className={styles.rightCardSubTitle}>
                All DGG.ai users were made honorary members of AiProWallet and
                should have received an email with their AiProWallet temporary
                login credentials.
              </div>
            </div>
            <div
              className={styles.rightCardActive}
              onClick={(e) => {
                handleRegisterClick();
                history.push(`/register/affiliate`);
              }}
            >
              <div className={styles.rightCardTitle}>
                I Am New And I Want To Register
              </div>
              <div className={styles.rightCardSubTitle}>
                That is awesome news. We are thrilled to have you. Please click
                here and you can complete your registration in minutes.
              </div>
            </div>
            <div className={styles.rightlabel} style={{ marginTop: '10%' }}>
              Logged In Before
            </div>
            <div
              className={styles.rightCardActive}
              onClick={(e) => setLoggedinBeforeStep("can't login")}
            >
              <div className={styles.rightCardTitle}>
                Im An Existing AiProWallet User But I Can’t Login
              </div>
              <div className={styles.rightCardSubTitle}>
                We apologize for that. Click here and lets find out why you are
                not able to login to your AiProWallet Account.
              </div>
            </div>
          </>
        );
        break;
    }
  };

  const getAffDataEmail = async (email) => {
    setMobileLoading(true);
    axios
      .get(
        `https://comms.globalxchange.io/user/profile/data/get?email=${email}`
      )
      .then(({ data }) => {
        if (data?.status && data?.usersData?.length > 0) {
          setMobileLoading(false);
          setAffData(data.usersData[0]);
          setMobileStep(6);
        } else {
          setAffiliateMismatch(true);
          setAffWrongEmail(affEmail);
        }
      });
  };

  const getAffDataUsername = async (name) => {
    setMobileLoading(true);
    axios
      .get(
        `https://comms.globalxchange.io/user/profile/data/get?username=${name}`
      )
      .then(({ data }) => {
        if (data?.status) {
          setMobileLoading(false);
          setAffData(data.usersData[0]);
          if (splitArr[3]) {
            setMobileStep(7);
          } else {
            setMobileStep(6);
          }
        } else if (
          data?.status === false &&
          data?.message === 'user not found!'
        ) {
          setMobileLoading(false);
          setAffWrongName(affUsername);
          setAffiliateMismatchName(true);
        }
      });
  };

  useEffect(() => {
    axios
      .get(`https://comms.globalxchange.io/user/profile/data/get`, {
        params: {
          email: userEmail,
        },
      })
      .then(({ data }) => {
        if (data.count === 1) {
          setAvailable(false);
        } else {
          if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/.test(userEmail)) {
            setAvailable(true);
          } else {
            setAvailable(false);
          }
        }
      });
  }, [userEmail]);

  useEffect(() => {
    axios
      .get(`https://comms.globalxchange.io/user/profile/data/get`, {
        params: {
          username: userUsername,
        },
      })
      .then(({ data }) => {
        if (data.status || data.count === 1) {
          setAvailable(false);
        } else {
          setAvailable(true);
        }
      });
  }, [userUsername]);

  useEffect(() => {
    let strongPassword = new RegExp(
      '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})'
    );
    if (strongPassword.test(userPassword)) {
      setAvailable(true);
    } else {
      setAvailable(false);
    }
  }, [userPassword]);

  const handleCreateAccount = () => {
    setMobileLoading(true);
    axios
      .post(`https://gxauth.apimachine.com/gx/user/signup`, {
        username: userUsername,
        email: userEmail,
        password: userPassword,
        ref_affiliate: affUsername,
        account_type: 'Personal',
        signedup_app: 'dgpayments',
      })
      .then(({ data }) => {
        if (data.status) {
          setMobileStep(11);
          setMobileLoading(false);
        }
      });
  };

  const confirmEmail = () => {
    axios
      .post(`https://gxauth.apimachine.com/gx/user/confirm`, {
        email: userEmail,
        code: userOtp,
      })
      .then(({ data }) => {
        if (data.status) {
          setMobileStep(12);
        } else {
          setMobileStep(11);
          setOtpMisMatch(true);
        }
      });
  };

  const getAppLinks = () => {
    axios
      .get(
        `https://comms.globalxchange.io/gxb/apps/mobile/app/links/logs/get?app_code=dgpayments`
      )
      .then(({ data }) => {
        if (data.status) {
          setAppLinks(data.logs[0]);
        }
      });
  };

  useEffect(() => {
    getAppLinks();
  }, []);

  useEffect(() => {
    if (splitArr[3]) {
      getAffDataUsername(splitArr[3]);
      // setMobileStep(6);
    }
  }, [splitArr[3]]);

  const conditionalMobileUI = () => {
    switch (mobileStep) {
      case 1:
        return (
          <div className={styles.stepContainer}>
            <div className={styles.pageTitle}>How Do You Want To Register</div>
            <div
              className={styles.optionCards}
              onClick={() => {
                window.open(
                  'https://gxnitrossso.auth.us-east-2.amazoncognito.com/oauth2/authorize?identity_provider=Google&client_id=64fpfg69u1fe6vc4pgalhtju0s&response_type=code&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=https%3A%2F%2Fmy.aiprowallet.com&prompt=login'
                );
              }}
            >
              <img
                src={googleIcon}
                alt=""
                style={{ width: '2.8vh', height: '2.8vh', marginRight: '4%' }}
              />
              <div>With Google</div>
            </div>
            <div
              className={styles.optionCards}
              onClick={(e) => setMobileStep(2)}
            >
              <img
                src={emailIcon}
                alt=""
                style={{ width: '2.8vh', height: '2.8vh', marginRight: '4%' }}
              />
              <div>Personal Email</div>
            </div>
            <div className={styles.optionCards} style={{ opacity: 0.5 }}>
              <img
                src={emailChainIcon}
                alt=""
                style={{ width: '2.8vh', height: '2.8vh', marginRight: '4%' }}
              />
              <div>EmailChains</div>
            </div>
            <div className={styles.buttonWrapper}>
              <div
                className={styles.backButton}
                onClick={(e) => setMobileStep(0)}
              >
                Go Back
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className={styles.stepContainer}>
            <div className={styles.pageTitle}>How Did You Hear About Us?</div>
            <div
              className={styles.optionCards}
              onClick={(e) => setMobileStep(3)}
            >
              <img
                src={affiliateIcon}
                alt=""
                style={{ width: '2.8vh', height: '2.8vh', marginRight: '4%' }}
              />
              <div>Affiliate</div>
            </div>
            <div
              className={styles.optionCards}
              onClick={(e) => setMobileStep(13)}
            >
              <img
                src={myselfButton}
                alt=""
                style={{ width: '2.8vh', height: '2.8vh', marginRight: '4%' }}
              />
              <div>I Got Here By Myself</div>
            </div>
            <div
              className={styles.buttonWrapper}
              onClick={(e) => setMobileStep(1)}
            >
              <div className={styles.backButton}>Go Back</div>
            </div>
          </div>
        );
      case 3:
        return (
          <div className={styles.stepContainer}>
            <div className={styles.pageTitle}>Which one do you know?</div>
            <div
              className={styles.optionCards}
              onClick={(e) => setMobileStep(4)}
            >
              <img
                src={emailIcon}
                alt=""
                style={{ width: '2.8vh', height: '2.8vh', marginRight: '4%' }}
              />
              <div>Affiliate’s Email</div>
            </div>

            <div
              className={styles.optionCards}
              onClick={(e) => setMobileStep(5)}
            >
              <img
                src={affUsernameIcon}
                alt=""
                style={{ width: '2.8vh', height: '2.8vh', marginRight: '4%' }}
              />
              <div>Affiliate’s Username</div>
            </div>

            <div
              className={styles.buttonWrapper}
              onClick={(e) => setMobileStep(2)}
            >
              <div className={styles.backButton}>Go Back</div>
            </div>
          </div>
        );
      case 4:
        return (
          <div className={styles.stepContainer}>
            {affiliateMismatch && (
              <div className={styles.AffiliatePrompt}>
                <img src={info} alt="" />
                Unfortunately we are not able to find an affiliate with the
                email {affWrongEmail}
              </div>
            )}
            <div
              className={styles.pageTitle}
              style={{ marginBottom: affiliateMismatch ? '5%' : '10%' }}
            >
              Enter your affiliates email
            </div>
            <div className={styles.optionCards}>
              <input
                type="text"
                placeholder="Ex. Danny@gmail.com"
                value={affEmail}
                onChange={(e) => {
                  setAffEmail(e.target.value.toLowerCase());
                  if (affiliateMismatch) {
                    setAffiliateMismatch(false);
                  }
                }}
              />
            </div>

            <div
              className={styles.buttonWrapper1}
              style={{ marginTop: affiliateMismatch ? '3vh' : '10vh' }}
            >
              <div
                className={styles.continueButton}
                onClick={(e) => getAffDataEmail(affEmail)}
              >
                Continue
              </div>
              <div
                className={styles.backButton}
                onClick={(e) => setMobileStep(3)}
              >
                Go Back
              </div>
            </div>
          </div>
        );
      case 5:
        return (
          <div className={styles.stepContainer}>
            {affiliateMismatchName && (
              <div className={styles.AffiliatePrompt}>
                <img src={info} alt="" />
                Unfortunately we are not able to find an affiliate with the name{' '}
                {affWrongName}
              </div>
            )}
            <div
              className={styles.pageTitle}
              style={{ marginBottom: affiliateMismatchName ? '5%' : '10%' }}
            >
              Enter your affiliates username
            </div>
            <div className={styles.optionCards}>
              <input
                type="text"
                placeholder="Ex. Danny"
                value={affUsername}
                onChange={(e) => {
                  setAffUsername(e.target.value);
                  if (affiliateMismatchName) {
                    setAffiliateMismatchName(false);
                  }
                }}
              />
            </div>

            <div
              className={styles.buttonWrapper1}
              style={{ marginTop: affiliateMismatchName ? '3vh' : '10vh' }}
            >
              <div
                className={styles.continueButton}
                onClick={(e) => {
                  getAffDataUsername(affUsername);
                }}
              >
                Continue
              </div>
              <div
                className={styles.backButton}
                onClick={(e) => setMobileStep(3)}
              >
                Go Back
              </div>
            </div>
          </div>
        );
      case 6:
        return (
          <div className={styles.stepContainer}>
            <div className={styles.pageTitle}>Confirm Affiliate</div>
            <div
              className={styles.optionCards}
              onClick={(e) => setMobileStep(7)}
            >
              <img
                src={affData?.dynamic[0]?.data?.profile_img}
                alt=""
                style={{
                  width: '5vh',
                  height: '5vh',
                  marginRight: '4%',
                  borderRadius: '50%',
                }}
              />
              <div className={styles.dataStyle}>
                <div>{affData?.hardCoded[0]?.data?.username}</div>
                <div style={{ fontWeight: 400, fontSize: '14px' }}>
                  {affData?.email}
                </div>
              </div>
            </div>

            <div className={styles.buttonWrapper}>
              <div
                className={styles.continueButton}
                onClick={(e) => {
                  if (fromSso) {
                    setMobileStep(13);
                  } else {
                    setMobileStep(7);
                  }
                }}
              >
                Yes It Is
              </div>
              <div
                className={styles.backButton}
                onClick={(e) => setMobileStep(4)}
              >
                No Its Not
              </div>
            </div>
          </div>
        );
      case 7:
        return (
          <div className={styles.stepContainer}>
            <div className={styles.pageTitle}>Enter your email</div>
            <div className={styles.optionCards}>
              <input
                type="text"
                placeholder="Ex. Danny@gmail.com"
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value.toLowerCase())}
              />
              <div>
                {available && userEmail.length > 0 ? (
                  <div className={styles.greenDot}></div>
                ) : (
                  <div className={styles.redDot}></div>
                )}
              </div>
            </div>

            <div className={styles.buttonWrapper1}>
              <div
                className={styles.continueButton}
                onClick={(e) => {
                  if (available) {
                    setMobileStep(8);
                    setAvailable(false);
                  }
                }}
              >
                Continue
              </div>
              <div
                className={styles.backButton}
                onClick={(e) => setMobileStep(6)}
              >
                Go Back
              </div>
            </div>
          </div>
        );

      case 8:
        return (
          <div className={styles.stepContainer}>
            <div className={styles.pageTitle}>Enter your username</div>
            <div className={styles.optionCards}>
              <input
                type="text"
                placeholder="Ex. Danny"
                value={userUsername}
                onChange={(e) => setUserUsername(e.target.value)}
              />
              <div>
                {available && userUsername.length > 0 ? (
                  <div className={styles.greenDot}></div>
                ) : (
                  <div className={styles.redDot}></div>
                )}
              </div>
            </div>

            <div className={styles.buttonWrapper1}>
              <div
                className={styles.continueButton}
                onClick={(e) => {
                  if (available) {
                    setMobileStep(9);
                    setAvailable(false);
                  }
                }}
              >
                Continue
              </div>
              <div
                className={styles.backButton}
                onClick={(e) => setMobileStep(7)}
              >
                Go Back
              </div>
            </div>
          </div>
        );
      case 9:
        return (
          <div className={styles.stepContainer}>
            <div className={styles.pageTitle}>Enter your password</div>
            <div
              className={styles.optionCards}
              style={{ justifyContent: 'space-between', paddingRight: '20px' }}
            >
              <input
                type={showPassword ? 'text' : 'password'}
                placeholder="*************"
                value={userPassword}
                onChange={(e) => {
                  setUserPassword(e.target.value);
                  handleChangeMob(e);
                }}
                style={{ width: '80%' }}
              />
              <div>
                {hasSpecialCharacterMob &&
                hasCapitalLetterMob &&
                hasMinLengthMob &&
                hasNumberMob ? (
                  <div className={styles.greenDot}></div>
                ) : (
                  <div className={styles.redDot}></div>
                )}
              </div>
              <div onClick={() => setShowPassword(!showPassword)}>
                <img src={showPassword ? eye2 : eye1} alt="show" />
              </div>
            </div>
            <div>
              <div className={styles.passwordChecklist}>
                <div
                  style={{ padding: '10px 0 5px' }}
                  className={styles.eachChecklist}
                >
                  Minimum One Capital Letter
                  <div
                    className={styles.indicator}
                    style={{
                      background: hasCapitalLetterMob
                        ? '#B5E6EA'
                        : 'rgba(220, 48, 39, 0.37)',
                    }}
                  ></div>
                </div>
                <div
                  style={{ padding: '5px 0 5px' }}
                  className={styles.eachChecklist}
                >
                  Minimum One Special Character
                  <div
                    className={styles.indicator}
                    style={{
                      background: hasSpecialCharacterMob
                        ? '#B5E6EA'
                        : 'rgba(220, 48, 39, 0.37)',
                    }}
                  ></div>
                </div>
                <div
                  style={{ padding: '5px 0 5px' }}
                  className={styles.eachChecklist}
                >
                  Minimum Seven Characters
                  <div
                    className={styles.indicator}
                    style={{
                      background: hasMinLengthMob
                        ? '#B5E6EA'
                        : 'rgba(220, 48, 39, 0.37)',
                    }}
                  ></div>
                </div>
                <div
                  style={{ padding: '5px 0 10px' }}
                  className={styles.eachChecklist}
                >
                  Minimum One Number
                  <div
                    className={styles.indicator}
                    style={{
                      background: hasNumberMob
                        ? '#B5E6EA'
                        : 'rgba(220, 48, 39, 0.37)',
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <div className={styles.buttonWrapper1} style={{ marginTop: '3vh' }}>
              <div
                className={styles.continueButton}
                onClick={(e) => {
                  if (available) {
                    setMobileStep(10);
                    setAvailable(false);
                  }
                }}
              >
                Continue
              </div>
              <div
                className={styles.backButton}
                onClick={(e) => setMobileStep(8)}
              >
                Go Back
              </div>
            </div>
          </div>
        );
      case 10:
        return (
          <div className={styles.stepContainer}>
            <div className={styles.pageTitle}>Confirm your password</div>
            <div
              className={styles.optionCards}
              style={{ justifyContent: 'space-between', paddingRight: '20px' }}
            >
              <input
                type={showPassword ? 'text' : 'password'}
                placeholder="*************"
                value={userConfirmPassword}
                onChange={(e) => setUserConfirmPassword(e.target.value)}
                style={{ width: '80%' }}
              />
              <div>
                {userPassword === userConfirmPassword ? (
                  <div className={styles.greenDot}></div>
                ) : (
                  <div className={styles.redDot}></div>
                )}
              </div>
              <div onClick={() => setShowPassword(!showPassword)}>
                <img src={showPassword ? eye2 : eye1} alt="show" />
              </div>
            </div>

            <div className={styles.buttonWrapper1}>
              <div
                className={styles.continueButton}
                onClick={(e) => {
                  if (userPassword === userConfirmPassword) {
                    handleCreateAccount();
                  }
                }}
              >
                Continue
              </div>
              <div
                className={styles.backButton}
                onClick={(e) => setMobileStep(9)}
              >
                Go Back
              </div>
            </div>
          </div>
        );
      case 11:
        return (
          <div className={styles.stepContainer}>
            <div className={styles.pageTitle}>Enter OTP</div>
            <OtpInput
              value={userOtp}
              onChange={setUserOtp}
              numInputs={6}
              separator={<span>&nbsp;&nbsp;&nbsp;</span>}
              shouldAutoFocus
              containerStyle={styles.otpInputWrapper}
              inputStyle={styles.otpInput}
            />
            {otpMisMatch ? (
              <div style={{ color: 'red' }}>Invalid OTP</div>
            ) : null}
            <div className={styles.buttonWrapper1}>
              <div
                className={styles.continueButton}
                onClick={(e) => {
                  if (userOtp === 6) {
                    confirmEmail();
                  }
                }}
              >
                Continue
              </div>
              <div className={styles.backButton} style={{ opacity: 0.5 }}>
                Resend Code
              </div>
            </div>
          </div>
        );
      case 12:
        return (
          <div className={styles.stepContainer}>
            <div className={styles.pageTitle} style={{ textAlign: 'center' }}>
              Your AiProWallet account has been successfully created and
              verified.{' '}
            </div>
            <div className={styles.pageTitle} style={{ textAlign: 'center' }}>
              Which type of phone do you have?
            </div>
            <div
              className={styles.optionCards}
              onClick={() => {
                window.open(appLinks.ios_app_link);
              }}
            >
              <img
                src={appleIcon}
                alt=""
                style={{ width: '2.8vh', height: '2.8vh', marginRight: '4%' }}
              />
              <div>An iPhone</div>
            </div>
            <div
              className={styles.optionCards}
              onClick={() => {
                window.open(appLinks.android_app_link);
              }}
            >
              <img
                src={playStoreIcon}
                alt=""
                style={{ width: '2.8vh', height: '2.8vh', marginRight: '4%' }}
              />
              <div>An Android</div>
            </div>
          </div>
        );
      case 13:
        return (
          <div className={styles.stepContainer}>
            <div className={styles.pageTitle}>Enter Full Name</div>
            <div className={styles.optionCards}>
              <input
                type="text"
                placeholder="Ex. John Doe"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
            </div>

            <div className={styles.buttonWrapper1}>
              <div
                className={styles.continueButton}
                onClick={(e) => {
                  saveToDB();
                }}
              >
                Continue
              </div>
              {/* <div
                className={styles.backButton}
                onClick={(e) => setMobileStep(6)}
              >
                Go Back
              </div> */}
            </div>
          </div>
        );

      default:
        return (
          <div className={styles.stepContainer}>
            <div
              style={{
                padding: '0px 40px',
                paddingBottom: '40px',
                paddingTop: '0px',
              }}
            >
              <img src={mobilelogo} alt="" style={{ width: '100%' }} />
            </div>
            <p
              style={{
                fontSize: '0.8rem',
                fontWeight: '100',
                textAlign: 'center',
              }}
            >
              If you want to use AiProWallet on your phone, you will have to
              download one of our beta mobile applications. Click your phone
              type below.
            </p>
            <div style={{ display: 'flex', width: '100%', gap: '10px' }}>
              <button
                onClick={() => {
                  window.open(appLinks.ios_app_link, '_blank');
                }}
                className="mobile-register"
                style={{ border: '0.5px solid #e5e5e5' }}
              >
                <img src={ios} alt="ios" />
              </button>
              <button
                onClick={() => {
                  window.open(appLinks.android_app_link, '_blank');
                }}
                className="mobile-register"
                style={{ border: '0.5px solid #e5e5e5' }}
              >
                <img src={android} alt="android" />
              </button>
            </div>
            <div className={styles.buttonWrapper1}>
              <div
                className={styles.continueButton}
                onClick={(e) => {
                  setMobileStep(1);
                }}
              >
                Click Here To Register
              </div>
            </div>
          </div>
        );
        break;
    }
  };

  return (
    <HelmetProvider>
      <Helmet>
        <title>{websiteTitle}</title>
        <meta name="description" content={websiteDescription} />
      </Helmet>
      {width > 768 || width > height ? (
        <div className={styles.mainGrid}>
          <div className={styles.leftSide}>
            {(isChanging || isRequestingLoginChallenge) && (
              <div className="loading-component">
                <LoadingAnimation icon={defaultApp?.appLogo} width={200} />
              </div>
            )}
            <div className={styles.leftForm}>
              {appDetailsLoading ? (
                <Skeleton className="mb-5" style={{ height: 50 }} />
              ) : (
                <img
                  src={selectedApp?.appFullLogo}
                  alt="Full Logo"
                  className={classNames.logo}
                  style={{
                    width: '70%',
                    paddingBottom:
                      forgotPassword && forgotPasswordStep === '3' ? '0' : '6%',
                  }}
                />
              )}

              {conditionalForm()}
            </div>
          </div>
          <div className={styles.rightSide}>
            {conditionalLoggedInBefore()}
            {/* <img
              src={bgImg}
              alt=""
              style={{ height: '90%', marginTop: '-20px' }}
            /> */}
          </div>
        </div>
      ) : (
        <>
          {mobileLoading ||
            (isLoading && (
              <div className={styles.loadingComponent}>
                <LoadingAnimation icon={defaultApp?.appLogo} width={200} />
              </div>
            ))}
          <div>
            <div className={styles.mobileLogo}>
              {appFullLogo ? (
                <img
                  src={appFullLogo}
                  alt="Full Logo"
                  onClick={(e) => setMobileStep(0)}
                />
              ) : (
                <Skeleton width={'100%'} height={'100%'} />
              )}
            </div>
            <div style={{ padding: '0px 20px' }}>{conditionalMobileUI()}</div>
          </div>
        </>
        // <LoginMobile />
      )}
    </HelmetProvider>
  );
}

export default Login;
