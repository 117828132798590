import React, { useContext, useEffect, useRef, useState } from 'react';
import Axios from 'axios';
import Scrollbars from 'react-custom-scrollbars';

import logo from '../../../static/images/logoIcons/defi.svg';
import { FormatNumber } from '../../../utils/FunctionTools';
import { BankContext } from '../../../context/Context';
import SlotCard from './SlotCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';

function ReserveRequirements({ setOpenModal }) {
  const scrollRef = useRef();
  const [intrestCoinList, setintrestCoinList] = useState([]);
  const { coinListObject, email } = useContext(BankContext);
  useEffect(() => {
    if (email) {
      Axios.get(
        'https://comms.globalxchange.io/coin/iced/get/liquid/interest'
      ).then(({ data }) => {
        if (data.status) {
          setintrestCoinList(data.interest_rates);
        }
      });
    }
  }, []);
  const [intrestRes, setIntrestRes] = useState({});
  useEffect(() => {
    Axios.get(
      'https://comms.globalxchange.io/coin/iced/get/liquid/interest?coin=BTC'
    ).then(({ data }) => {
      if (data.status) {
        setIntrestRes(data.interest_rates[0]);
      }
    });
  }, []);

  return (
    <div className="transactionInspectorWrapper reserve">
      <div
        className="overlay-ti"
        role="button"
        tabIndex="-1"
        onClick={() => setOpenModal(false)}
      />
      <div className="ti-content">
        <div className="ti-head">
          <img src={logo} alt="" />
          Reserve Requirements
        </div>
        <div className="ti-reseerveRequirement">
          <div className="subTitle">Select The Reserve Currency </div>
          <Scrollbars
            className="coinListWrapper"
            renderView={(props) => <div {...props} className="coinList" />}
            renderThumbHorizontal={() => <div />}
            renderThumbVertical={() => <div />}
          >
            {intrestCoinList
              .filter((coin) => coin.coin === 'BTC')
              .map((coin) => (
                <div className="coin">
                  <img src={coinListObject[coin.coin]?.coinImage} alt="" />
                </div>
              ))}
            {intrestCoinList
              .filter((coin) => coin.coin !== 'BTC')
              .map((coin) => (
                <div className="coin disable">
                  <img src={coinListObject[coin.coin]?.coinImage} alt="" />
                </div>
              ))}
          </Scrollbars>
          <div className="coinDetail">
            <span>Bitcoin</span>
            <span>{FormatNumber(intrestRes.interest_rate, 3)}%</span>
          </div>
          <Scrollbars
            className="cardListWrapper"
            renderView={(props) => <div {...props} className="cardList" />}
            renderThumbHorizontal={() => <div />}
            renderThumbVertical={() => <div />}
            ref={scrollRef}
          >
            {intrestRes?.slots?.map((slot, i) => (
              <SlotCard slot={slot} i={i} intrestRes={intrestRes} />
            ))}
          </Scrollbars>
          <div
            className="btnScroll left"
            onClick={() =>
              scrollRef?.current?.scrollTop(
                scrollRef?.current?.lastViewScrollTop - 240
              )
            }
          >
            <FontAwesomeIcon icon={faCaretUp} />
          </div>
          <div
            className="btnScroll right"
            onClick={() =>
              scrollRef?.current?.scrollTop(
                scrollRef?.current?.lastViewScrollTop + 240
              )
            }
          >
            <FontAwesomeIcon icon={faCaretDown} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReserveRequirements;
