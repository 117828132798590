import React, { useEffect } from 'react';
import Layout from '../../Layout/Layout';
import './malls.scss';
import mall from '../../static/images/malls/mall-icon.svg';
import search from '../../static/images/malls/search.svg';
import img1 from '../../static/images/malls/ham.svg';
import notification from '../../static/images/malls/notification.svg';
import cart from '../../static/images/malls/cart.svg';
import ddown from '../../static/images/malls/ddown.svg';
import home from '../../static/images/malls/home-icon.svg';
import subscription from '../../static/images/malls/subscription.svg';
import offerTag from '../../static/images/malls/offerTag.svg';
import { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { useAppContextDetails } from '../../context/AppContext';
import { BankContext } from '../../context/Context';
import Skeleton from 'react-loading-skeleton';
import Subscription from './Subscription';
import { planBContext } from '../../context/PlanBContext';

const Malls = () => {
  const { selectedTab, setSelectedTab, responseData, setResponseData } =
    useAppContextDetails();

  const {
    apiData,
    setApiData,
    filteredApiData,
    setFilteredApiData,
    isloading,
    setisloading,
  } = useContext(planBContext);
  const history = useHistory();
  const { email } = useContext(BankContext);

  if (!email) {
    history.push('/');
  }

  function filterItem(text) {
    let filterProduct = apiData?.filter((eachitem) => {
      return eachitem?.product_name
        ?.toLowerCase()
        ?.includes(text?.toLowerCase());
    });
    setFilteredApiData(filterProduct);
  }

  return (
    <Layout active="malls" className="malls" hideFooter>
      <>
        <div className="malls-navbar">
          {/* <div>
            <img src={img1} alt="" />
          </div> */}
          <div className="malls-icon">
            <img src={mall} alt="" />
          </div>
          <div className="search-mall">
            {/* <div className="all">
              <p>All Categories</p>
              <div className="arroww" style={{ cursor: 'pointer' }}>
                <img src={ddown} alt="" />
              </div>
            </div> */}
            <div className="im">
              <input
                type="text"
                placeholder="Im Shopping For..."
                onChange={(event) => filterItem(event.target.value)}
              />
              <div className="arroww" style={{ cursor: 'pointer' }}>
                <img src={search} alt="" />
              </div>
            </div>
          </div>
          <div className="right-top">
            <div
              style={{ borderRight: '1px solid #e7e7e7', paddingRight: '3rem' }}
              className="divv"
            >
              <img src={cart} alt="" style={{ height: '50px' }} />
              <div>
                <p style={{ opacity: '0.25' }}>Active Subscriptions</p>
                <p>0 | $0.00 USD</p>
              </div>
            </div>
            <div style={{ paddingLeft: '3rem' }} className="divv">
              <img src={notification} alt="" style={{ height: '50px' }} />
              <div>
                <p style={{ opacity: '0.25' }}>Notifications</p>
                <p>0</p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-space">
          <div className="optionss">
            <div
              className="each-option"
              style={{
                borderBottom: selectedTab === 'home' ? '2px solid #6669B0' : '',
                fontWeight: selectedTab === 'home' ? '600' : '',
              }}
              onClick={() => setSelectedTab('home')}
            >
              <div>
                <img src={home} alt="" />
                <p>Home</p>
              </div>
            </div>
            <div
              className="each-option"
              style={{
                borderBottom:
                  selectedTab === 'subscription' ? '2px solid #6669B0' : '',
                fontWeight: selectedTab === 'subscription' ? '600' : '',
              }}
              onClick={() => setSelectedTab('subscription')}
            >
              <div>
                <img src={subscription} alt="" />
                <p>Subscriptions</p>
              </div>
            </div>
            <div className="each-option">
              <div>
                <img src={offerTag} alt="" />
                <p>Offers</p>
              </div>
            </div>
          </div>
        </div>
        <div className="malls-content">
          <div style={{ width: '100%', height: '100%', overflowY: 'scroll' }}>
            {selectedTab === 'home' ? (
              <>
                <div className="image-container">
                  <div className="course-img">
                    {/* <img src={img1} alt="" /> */}
                  </div>
                  <div className="imgs">
                    <div className="imgg1">
                      {/* <img src={img2} alt="" /> */}
                    </div>
                    <div className="imgg2">
                      {/* <img src={img3} alt="" /> */}
                    </div>
                  </div>
                </div>
                <section className="best-sellers">
                  <p className="headingg">All Products</p>
                  <div className="bs-products">
                    {isloading || filteredApiData?.length < 1
                      ? Array(10)
                          .fill(' ')
                          .map((item, index) => {
                            return (
                              <div className="each-productt" key={index}>
                                <div className="top-sec">
                                  <div style={{ width: '50%' }}>
                                    <Skeleton
                                      width={100}
                                      height={100}
                                      borderRadius={50}
                                    />
                                  </div>
                                  <Skeleton width={100} height={35} />
                                </div>
                                <div className="mid-sec">
                                  <Skeleton width={150} height={35} />
                                </div>
                                <div className="bottom-sec">
                                  <div>
                                    <div className="amnt">
                                      <Skeleton width={50} height={35} />
                                    </div>
                                    <div className="first">
                                      <Skeleton width={100} height={35} />
                                    </div>
                                  </div>
                                  <div>
                                    <div className="amnt">
                                      <Skeleton width={50} height={35} />
                                    </div>
                                    <div className="first">
                                      <Skeleton width={100} height={35} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                      : filteredApiData?.length > 0
                      ? filteredApiData?.map((e, i) => {
                          return (
                            <div
                              className="each-productt"
                              key={i}
                              onClick={() => {
                                history.push(`/malls/${e?.product_code}`);
                                localStorage.setItem(
                                  'product',
                                  JSON.stringify(e)
                                );
                              }}
                            >
                              <div className="top-sec">
                                <div style={{ width: '50%' }}>
                                  <img src={e?.product_icon} alt="" />
                                </div>
                                <button>Buy now</button>
                              </div>
                              <div className="mid-sec">{e?.product_name}</div>
                              <div className="bottom-sec">
                                <div>
                                  <div className="amnt">
                                    {e?.first_purchase?.price || '0.00'}
                                  </div>
                                  <div className="first">First Month</div>
                                </div>
                                <div>
                                  <div className="amnt">
                                    {e?.billing_cycle?.monthly?.price}
                                  </div>
                                  <div className="first">Monthly</div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      : ''}
                  </div>
                </section>
              </>
            ) : (
              <Subscription />
            )}
          </div>
        </div>
      </>
    </Layout>
  );
};

export default Malls;
