import React from 'react';
import moment from 'moment';

import logo from '../../static/images/assetsLogo.svg';

function TradeInspector({ setOpenModal, tiObject }) {
  return (
    <div className="transactionInspectorWrapper tradeInspectorWrapper">
      <div
        className="overlay-ti"
        role="button"
        tabIndex="-1"
        onClick={() => setOpenModal(false)}
      />
      <div className="ti-content tradeInspector">
        <div className="ti-head">
          <img src={logo} alt="" /> Transaction Inspector
        </div>
        <div className="ti-detail">
          <div className="ti-row">
            <div className="label">{tiObject.sellAmtLabel}</div>
            <div className="value">
              {tiObject.sellAmt.toFixed(12).replace(/\.?0+$/, '')}{' '}
              {tiObject.sellCoin}
            </div>
          </div>
          <div className="ti-row">
            <div className="label">{tiObject.getAmtLabel}</div>
            <div className="value">
              {tiObject.getAmt.toFixed(12).replace(/\.?0+$/, '')}{' '}
              {tiObject.getCoin}
            </div>
          </div>
          {tiObject?.lblThree && (
            <div className="ti-row">
              <div className="label">{tiObject.lblThree}</div>
              <div className="value">
                {tiObject.amtThree.toFixed(12).replace(/\.?0+$/, '')}{' '}
                {tiObject.coinThree}
              </div>
            </div>
          )}
          {tiObject?.lblFour && (
            <div className="ti-row">
              <div className="label">{tiObject.lblFour}</div>
              <div className="value">
                {tiObject.amtFour.toFixed(12).replace(/\.?0+$/, '')}{' '}
                {tiObject.coinFour}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TradeInspector;
