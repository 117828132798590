import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import copy from '../../../static/images/sidebar-icons/copy.svg';
import { useCoinContextData } from '../../../context/CoinContext';
import { useAppContextDetails } from '../../../context/AppContext';
import { BankContext } from '../../../context/Context';
import { updateScroll } from '../../../config/constants';
import { SubmitButton } from './helpers';
import quote from '../../../static/images/actionSidebar/qoute.svg';
import {
  useGetPathId,
  getQuote1,
  initiateAdd1,
  getPathDataForId1,
} from '../../../components/VaultActions/AddForexAction/api';
import { useCoinData } from '../../../components/VaultActions/api';
import Cookies from 'js-cookie';
import { APP_USER_TOKEN } from '../../../config';
import { useUserApps } from '../../../queryHooks';

export const AddActionFooter = ({ setToast, alternateFooterComponent }) => {
  const history = useHistory();

  const { email, sidebarCollapse, admin } = useContext(BankContext);

  const {
    enterAmount,
    setCoinAction,
    selectedCoin,
    coinType,
    selectedTab,
    setEnterAmount,
    setSelectedTab,
    setCurrentStep,
    otcDesk,
    setOtcDesk,
    payMethod,
    setPayMethod,
    fundingCurrency,
    setFundingCurrency,
    setAddActionStep,
    addActionStep,
    hashText,
    setSubmitHashEnabled,
    setConfirmAmount,
    addForexCurrencyValue,
    setAddApiValue,
    setAddApiValueCoin,
    setToCurrencyApiValue,
    setCountryApiValue,
    setPayMethodApiValue,
    setAddForexCurrencyValue,
    isAddingForexLoading,
    addApiValue,
    payMethodApiValue,
    bankerId,
    confirmQuote,
    setConfirmQuote,
    addApiValueCoin,
    setIsAddingForexLoading,
    setCoinActionEnabled,
    setOtcApiValue,
    setEachCardShowValue,
    setNewWholeValue,
    newWholeValue,
    senbtnClicked,
    setSendBtnClicked,
    finalToAmountValue,
    setFinalToAmountValue,
    pathData,
    setPathData,
  } = useCoinContextData();

  const { appColorCode, appCode } = useAppContextDetails();
  const [pathIdParams, setPathIdParams] = useState({});
  const userToken = Cookies.get(APP_USER_TOKEN);
  const [gettingQuote, setGettingQuote] = useState(false);
  const [initiating, setInitiating] = useState(false);

  const { data: pathIdResponse } = useGetPathId(pathIdParams, setConfirmQuote);
  const pathId = pathIdResponse?.data?.paths?.[0]?.path_id;

  const { data: userApps } = useUserApps(email);
  const selectedUserApp = userApps?.filter((e) => e.app_code === appCode);
  const userAppsProfileId = selectedUserApp?.[0]?.profile_id;

  useEffect(() => {
    if (
      selectedTab === 'enterAmount' ||
      selectedTab === 'confirmQuote' ||
      selectedTab === 'addSuccess'
    ) {
      setPathIdParams({
        select_type: 'fund',
        to_currency: selectedCoin.coinSymbol,
        from_currency: addApiValue.value,
        paymentMethod: payMethodApiValue.value,
        banker: bankerId,
      });
    }
  }, [selectedTab]);

  useEffect(() => {
    console.log('entering');
    if (selectedTab === 'addSuccess') {
      console.log('enteringgggg');
      getPathDataForId1(pathId).then((response) => {
        let result = response?.data?.paths?.[0]?.total_steps;
        let pathDatas = result && Object?.entries?.(result)?.map((e) => e[1]);
        setPathData(pathDatas);
      });
    }
  }, [selectedTab]);

  const getQuoteApiCall = () => {
    setGettingQuote(true);
    if (confirmQuote && pathId && userAppsProfileId && addForexCurrencyValue) {
      getQuote1({
        token: userToken,
        email,
        app_code: appCode,
        profile_id: userAppsProfileId,
        coin_purchased: selectedCoin?.coinSymbol,
        purchased_from: addApiValue?.value,
        from_amount: addForexCurrencyValue,
        stats: true,
        identifier: `Add ${addForexCurrencyValue} ${addApiValue?.value} Via ${payMethodApiValue?.value}`,
        path_id: pathId,
      }).then((response) => {
        let result = response?.data?.finalToAmount;
        // console.log(result, 'quote amount');
        if (result) {
          setFinalToAmountValue(result);
          setConfirmAmount(true);
          setCurrentStep('step6');
          setSelectedTab('confirmQuote');
          setGettingQuote(false);
          updateScroll();
        } else {
          setGettingQuote(false);
        }
      });
    }
  };

  const initiateAddApiCall = () => {
    setInitiating(true);
    if (pathId && userAppsProfileId) {
      initiateAdd1({
        token: userToken,
        email,
        app_code: appCode,
        profile_id: userAppsProfileId,
        coin_purchased: selectedCoin.coinSymbol,
        purchased_from: addApiValue.value,
        from_amount: addForexCurrencyValue,
        stats: false,
        identifier: `Add ${addForexCurrencyValue} ${addApiValue.value} Via ${payMethodApiValue.value}`,
        path_id: pathId,
      }).then((response) => {
        let result = response?.data?.status;
        console.log(result, 'trade result');
        if (result) {
          setCurrentStep('step7');
          setSelectedTab('addSuccess');
          setInitiating(false);
          updateScroll();
          history.push(
            `/vault/${selectedCoin.type}/${appCode}/${selectedCoin.coinSymbol}`
          );
        } else {
          setInitiating(false);
        }
      });
    }
  };

  // const {
  //   mutate: getCoinData,
  //   data: coinResponse,
  //   isLoading: isCoinDataLoading,
  // } = useCoinData();
  // let coinBalance = coinResponse?.data?.coins_data?.[0]?.coinValue;

  // useEffect(() => {
  //   coinBalance = coinResponse?.data?.coins_data?.[0]?.coinValue;
  //   // console.log(coinBalance, 'coinBalance');
  //   if (coinBalance) {
  //     history.push(
  //       `/vault/${selectedCoin.type}/${appCode}/${selectedCoin.coinSymbol}`
  //     );
  //   }
  // }, [coinResponse]);

  const isEthCoin = selectedCoin.eth_token || selectedCoin.coinSymbol === 'ETH';
  const isTrxOrXrp =
    selectedCoin.coinSymbol === 'TRX' || selectedCoin.coinSymbol === 'XRP';
  const isSolCoin = selectedCoin.coinSymbol === 'SOL';

  const handleCloseButton = () => {
    setCurrentStep('step1');
    setSelectedTab('sendingCurrency');
    setCoinAction(['Menu']);
    setAddApiValue('');
    setAddApiValueCoin('');
    setToCurrencyApiValue('');
    setCountryApiValue('');
    setPayMethodApiValue('');
    setFundingCurrency(false);
    setPayMethod(false);
    setOtcDesk(false);
    setAddForexCurrencyValue('');
    updateScroll();
  };

  if (coinType === 'crypto')
    return (
      <>
        {addActionStep === 1 && (
          <>
            {selectedCoin.coin_address ? (
              <div className="d-flex w-100 share-copy-wrapper">
                <div className="share-copy-div w-50">
                  <SubmitButton
                    isEthCoin={isEthCoin}
                    isTrxOrXrp={isTrxOrXrp}
                    isSolCoin={isSolCoin}
                  />
                </div>
                <div
                  className="share-copy-div w-50"
                  onClick={() =>
                    navigator.clipboard
                      .writeText(selectedCoin.coin_address)
                      .then(() => setToast(true))
                  }
                >
                  <div className="d-flex clickable-div">
                    <img className="mr-2" src={copy} alt="copy" width={20} />
                    <h5 className="mt-2">Copy</h5>
                  </div>
                </div>
              </div>
            ) : (
              alternateFooterComponent
            )}
          </>
        )}

        {addActionStep === 2 && (
          <>
            <div className="withdraw-footer">
              <div
                className="back1 w-100"
                key="back1"
                onClick={() => {
                  setAddActionStep(1);
                  updateScroll();
                }}
              >
                <h6>Back</h6>
              </div>
            </div>
          </>
        )}

        {addActionStep === 3 && (
          <>
            <div className="withdraw-footer">
              <div
                className="back1"
                key="back1"
                onClick={() => {
                  setAddActionStep(2);
                  updateScroll();
                }}
              >
                <h6>Back</h6>
              </div>
              <div
                className={hashText !== '' ? 'nextbtn' : 'nextbtn1'}
                key="nextbtn"
                style={{
                  backgroundColor: appColorCode,
                  opacity: hashText !== '' ? 1 : 0.25,
                  color: hashText !== '' ? 'white' : '',
                  cursor: hashText !== '' ? 'pointer' : '',
                }}
                onClick={() => {
                  setSubmitHashEnabled(true);
                  updateScroll();
                }}
              >
                <h6>Submit</h6>
              </div>
            </div>
          </>
        )}

        {addActionStep === 4 && (
          <>
            <div className="withdraw-footer">
              <div
                className="back1"
                key="back1"
                onClick={() => {
                  setCoinAction(['Menu']);
                  setAddActionStep(1);
                  updateScroll();
                }}
              >
                <h6>Close</h6>
              </div>
              <div
                className="nextbtn"
                key="nextbtn"
                style={{
                  backgroundColor: appColorCode,
                  opacity: 1,
                  color: 'white',
                  cursor: 'pointer',
                }}
                onClick={() =>
                  history.push(
                    `/vault/${selectedCoin.type}/${appCode}/${selectedCoin.coinSymbol}`
                  )
                }
              >
                <h6>{selectedCoin?.coinSymbol} Vault</h6>
              </div>
            </div>
          </>
        )}
      </>
    );

  return (
    <>
      {selectedTab === 'enterAmount' && (
        <div
          className="withdraw-footer"
          style={{
            position: 'absolute',
            bottom: '12rem',
            width: '22%',
            zIndex: '5',
            borderTop: 'none',
            padding: '0 1.5rem',
            height: '3rem',
          }}
        >
          <div
            className="add-nextbtn"
            key="add-nextbtn"
            style={{
              backgroundColor: '#78D0B1',
              opacity:
                addForexCurrencyValue && !gettingQuote
                  ? 1
                  : addForexCurrencyValue && gettingQuote
                  ? 0.25
                  : 0.25,
              color: addForexCurrencyValue ? 'white' : '',
              cursor: addForexCurrencyValue ? 'pointer' : '',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              gap: '10px',
              alignItems: 'baseline',
              borderRadius: '25px',
              height: '100%',
            }}
            onClick={() => getQuoteApiCall()}
          >
            {gettingQuote ? (
              <div className="hoverrr">
                <h6
                  className="footText2"
                  style={{ marginBottom: '0', fontWeight: '500' }}
                >
                  Getting Quote..
                </h6>
              </div>
            ) : (
              <div className="hoverrr">
                <h6
                  className="footText2"
                  style={{ marginBottom: '0', fontWeight: '500' }}
                >
                  Get Quote
                </h6>
                <img src={quote} alt="" />
              </div>
            )}
          </div>
        </div>
      )}
      {selectedTab === 'confirmQuote' && (
        <div
          className="withdraw-footer"
          style={{
            position: 'absolute',
            bottom: '12rem',
            width: '22%',
            zIndex: '5',
            borderTop: 'none',
            padding: '0 1.5rem',
            height: '3rem',
          }}
        >
          <div
            className="add-nextbtn"
            key="add-nextbtn"
            style={{
              backgroundColor: '#78D0B1',
              opacity: initiating ? 0.25 : 1,
              cursor: 'pointer',
              pointerEvents: initiating && 'none',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              gap: '10px',
              alignItems: 'baseline',
              borderRadius: '25px',
              height: '100%',
            }}
            onClick={() => {
              initiateAddApiCall();
            }}
          >
            {initiating ? (
              <div className="hoverrr">
                <h6 className="footText2" style={{ marginBottom: '0' }}>
                  Initiating Transaction..
                </h6>
              </div>
            ) : (
              <div className="hoverrr">
                <h6 className="footText2" style={{ marginBottom: '0' }}>
                  Initiate Transaction
                </h6>
                <img src={quote} alt="" />
              </div>
            )}
          </div>
        </div>
      )}

      {/* {selectedTab === 'addSuccess' && (
        <div className="withdraw-footer">
          <div
            className="back1"
            onClick={handleCloseButton}
            style={{
              backgroundColor: appColorCode,
              opacity: isAddingForexLoading ? 0.25 : 1,
              cursor: 'pointer',
              pointerEvents: isAddingForexLoading && 'none',
              width: '100%',
            }}
          >
            <p className="footText" style={{ color: 'white' }}>
              Close Control Panel
            </p>
          </div>
        </div>
      )} */}

      {/* {selectedTab === 'accountDetails' && (
        <div className="withdraw-footer">
          <div className="back1" onClick={() => setSelectedTab('addSuccess')}>
            <p className="footText">Back</p>
          </div>
          <div
            className="add-nextbtn"
            key="add-nextbtn"
            style={{
              backgroundColor: appColorCode,
              opacity: 1,
              cursor: 'pointer',
            }}
            onClick={handleCloseButton}
          >
            <h6 className="footText2">Close</h6>
          </div>
        </div>
      )} */}
    </>
  );
};
