import React, { useContext, useState, useEffect, Fragment } from 'react';
import CountUp from 'react-countup';
import Axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { BankContext } from '../../../context/Context';
import { IndexContext } from '../../../context/IndexContext';
import LoginWrapper from '../../LoginModalNew';
import { useHistory } from 'react-router-dom';
import { PortfolioContext } from '../../../context/PortfolioContext';
import { FormatNumber, FormatCurrency } from '../../../utils/FunctionTools';
import Skeleton from 'react-loading-skeleton';
import LoginModal from '../../LoginModalNew';

function BondsListTable({ assetTab, setCoinToDetail }) {
  const history = useHistory();
  const {
    email,
    coinListObject,
    tostShowOn,
    updateInterval,
    defaultPrecission,
  } = useContext(BankContext);
  const { conractsObj } = useContext(IndexContext);
  const { setCoinContract, setIcingStep, setIcingDays } =
    useContext(PortfolioContext);
  const [days, setDays] = useState('');
  const [contract, setContract] = useState({});
  const [contractPreview, setContractPreview] = useState({});

  const [duration, setDuration] = useState(2);
  const togleDuration = (duration) => {
    setDuration(duration === 2 ? 2.1 : 2);
  };
  const [assetType, setAssetType] = useState('Fiat');
  useEffect(() => {
    switch (assetTab) {
      case 'Fiat':
        setAssetType('Fiat');
        break;
      case 'Crypto':
        setAssetType('Crypto');
        break;
      case 'stableCoin':
        setAssetType('Stable');
        break;
      default:
        break;
    }
  }, [assetTab]);

  const [loading, setLoading] = useState(false);

  const calculateRoi = (key) => {
    setLoading(true);
    Axios.post('https://comms.globalxchange.io/coin/iced/contract/create', {
      coin: key,
      days: days,
      simulate: true,
    })
      .then((res) => {
        const { data } = res;
        if (data.status) {
          setContractPreview(data);
        } else {
          tostShowOn(data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getCoinDetail = (key) => {
    setCoinToDetail(coinListObject[key]);
  };

  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [valueInUsd, setValueInUsd] = useState(false);

  return (
    <>
      <table className="asetPlatformTable bonds">
        <thead className="tableHead">
          <tr>
            <th>Rank</th>
            <th>Asset</th>
            <th>Contract Cost</th>
            <th>Base Rate</th>
            <th>Rate Velocity</th>
            <th>Acceleration</th>
            <th className="text-left">Estimated Days</th>
          </tr>
        </thead>
        <tbody className="tableContent">
          {Object.entries(conractsObj).map(([key, value], i) => {
            const openedDetail = contract && contract._id === value._id;
            if (
              coinListObject &&
              coinListObject[key] &&
              (assetType === 'Stable'
                ? coinListObject[key].stable_coin
                : coinListObject[key].asset_type === assetType)
            )
              return (
                <Fragment key={value._id}>
                  <tr className={`${contract && contract._id === value._id}`}>
                    <td className="rank">{i + 1}</td>
                    <td className="coin" onClick={() => getCoinDetail(key)}>
                      <div className="coin-name">
                        <img
                          src={coinListObject[key].coinImage}
                          alt=""
                          className="coinLogo"
                        />{' '}
                        {coinListObject[key].coinName}
                      </div>
                    </td>
                    <td className="" onClick={() => getCoinDetail(key)}>
                      <CountUp
                        onEnd={() => {
                          if (updateInterval)
                            setTimeout(() => {
                              togleDuration(duration);
                            }, updateInterval * 1000);
                        }}
                        duration={duration}
                        start={0}
                        end={
                          value.amount *
                          ((openedDetail &&
                            valueInUsd &&
                            coinListObject &&
                            coinListObject[key] &&
                            coinListObject[key].price.USD) ||
                            1)
                        }
                        decimals={
                          (key === 'ETH' || key === 'BTC') &&
                          !(openedDetail && valueInUsd)
                            ? 4
                            : 2
                        }
                      />{' '}
                      {key}
                    </td>
                    <td
                      className="dayChange false"
                      onClick={() => getCoinDetail(key)}
                    >
                      <CountUp
                        duration={duration}
                        start={value.base_rate_lower - 2 || 0}
                        end={value.base_rate_lower || 0}
                        decimals={3}
                      />
                      %
                    </td>
                    <td
                      className="dayChange false"
                      onClick={() => getCoinDetail(key)}
                    >
                      <CountUp
                        duration={duration}
                        start={value.base_velocity - 2 || 0}
                        end={value.base_velocity || 0}
                        decimals={2}
                      />
                      %
                    </td>
                    <td
                      className="dayChange true"
                      onClick={() => getCoinDetail(key)}
                    >
                      <CountUp
                        duration={duration}
                        start={value.acceleration - 2 || 0}
                        end={-value.acceleration || 0}
                        decimals={2}
                      />
                    </td>
                    <td className="estimatedDays">
                      <div className="estimatedDaysIn">
                        <input
                          type="number"
                          placeholder="0"
                          step="1"
                          min="1"
                          defaultValue=""
                          onChange={(e) => setDays(e.target.value)}
                        />
                        <div
                          className="btn-go"
                          onClick={() => {
                            setContract(value);
                            calculateRoi(key);
                          }}
                        >
                          <FontAwesomeIcon icon={faPaperPlane} />
                        </div>
                      </div>
                    </td>
                  </tr>
                  {contract && contract._id === value._id ? (
                    <tr className="detailRow">
                      <td colSpan="7" className="p-0">
                        <div className="bondDetail">
                          <div className="cardRow">
                            <div className="bondCard">
                              {loading ? (
                                <Skeleton className="value" width={100} />
                              ) : (
                                <div className="value">
                                  {FormatNumber(
                                    contractPreview.interestRate,
                                    2
                                  )}
                                  %
                                </div>
                              )}
                              <div className="label">Daily Interest Rate</div>
                            </div>
                            <div className="bondCard">
                              {loading ? (
                                <Skeleton className="value" width={100} />
                              ) : (
                                <div className="value">
                                  {valueInUsd
                                    ? `$${FormatNumber(
                                        contractPreview.interestValueUsd,
                                        3
                                      )}`
                                    : FormatNumber(
                                        contractPreview.interestValue,
                                        6
                                      )}
                                  <small>
                                    {valueInUsd ? 'USD' : contractPreview.coin}
                                  </small>
                                </div>
                              )}
                              <div className="label">Daily Earnings</div>
                            </div>
                            <div className="bondCard">
                              {loading ? (
                                <Skeleton className="value" width={100} />
                              ) : (
                                <div className="value">
                                  {FormatNumber(
                                    contractPreview.interestRate *
                                      contractPreview.days,
                                    2
                                  )}
                                  %
                                </div>
                              )}
                              <div className="label">Gross ROI</div>
                            </div>
                            <div className="bondCard">
                              {loading ? (
                                <Skeleton className="value" width={100} />
                              ) : (
                                <div className="value">
                                  {valueInUsd
                                    ? `$${FormatCurrency(
                                        contractPreview.interestValueUsd *
                                          contractPreview.days,
                                        'USD',
                                        defaultPrecission
                                      )}`
                                    : FormatCurrency(
                                        contractPreview.interestValue *
                                          contractPreview.days,
                                        contractPreview.coin,
                                        defaultPrecission
                                      )}
                                  <small>
                                    {valueInUsd ? 'USD' : contractPreview.coin}
                                  </small>
                                </div>
                              )}
                              <div className="label">Gross Earnings</div>
                            </div>
                          </div>
                          <div className="controllsRow">
                            <div
                              className="switchCurrency"
                              onClick={() => setValueInUsd(!valueInUsd)}
                            >
                              {`View Data In ${
                                valueInUsd
                                  ? coinListObject &&
                                    coinListObject[contractPreview.coin] &&
                                    coinListObject[contractPreview.coin]
                                      .coinName
                                  : 'US Dollars'
                              }`}
                            </div>
                            <div
                              className="btnSimulation"
                              onClick={() => setContract({})}
                            >
                              Close Simulation
                            </div>
                            <div
                              className="btnBond"
                              onClick={() => {
                                if (email) {
                                  setCoinContract(contract.coin);
                                  setIcingStep(1);
                                  setIcingDays(days);
                                  history.push('/iceAsset');
                                } else {
                                  setLoginModalOpen(true);
                                }
                              }}
                            >
                              Create Bond
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <></>
                  )}
                </Fragment>
              );
            return <Fragment key={value._id} />;
          })}
        </tbody>
      </table>
      {loginModalOpen ? (
        <LoginModal
          onClose={() => {
            setLoginModalOpen(false);
          }}
          onSuccess={() => {
            setCoinContract(contract.coin);
            setIcingStep(1);
            setIcingDays(days);
            history.push('/iceAsset');
            setLoginModalOpen(false);
          }}
        />
      ) : (
        ''
      )}
    </>
  );
}

export default BondsListTable;
