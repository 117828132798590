import Axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';

import { FormatCurrency, FormatNumber } from '../../utils/FunctionTools';
import globe from '../../static/images/world.svg';
import fundOffering from '../../static/images/fundOffering.svg';
import { ChatContext } from '../../context/ChatContext';
import { BankContext } from '../../context/Context';

function MarketDetail({ selectedInvestment, pathId, setPathId }) {
  const [pathData, setPathData] = useState({});
  const [btTab, setBtTab] = useState('Asset');
  const { setChatOn, setChatPathData } = useContext(ChatContext);
  const { defaultPrecission } = useContext(BankContext);
  useEffect(() => {
    Axios.get(
      `https://comms.globalxchange.io/coin/investment/path/get?path_id=${pathId}`
    ).then(({ data }) => {
      if (data.status) {
        setPathData(data?.paths[0]);
      }
    });
  }, [pathId]);
  const getData = () => {
    switch (btTab) {
      case 'Asset':
        return (
          <div className="rows">
            <div className="assetRow">
              <div className="usd">
                <div className="title">
                  {FormatNumber(pathData?.tokens_burned, 3)} USD
                </div>
                <div className="label">Issuance Price</div>
              </div>
              <div className="supply">
                <div className="title">
                  {FormatNumber(pathData?.banker_fixed_fee, 2)}{' '}
                  {pathData?.token_profile_data?.coinSymbol}
                </div>
                <div className="label">Initial Supply</div>
              </div>
              <div className="capital">
                <div className="title">25,361,266.24 USD</div>
                <div className="label">Fund Capitalization</div>
              </div>
            </div>
            <div className="assetRow">
              <div className="usd">
                <div className="title">0.000 USD</div>
                <div className="label">Live Price</div>
              </div>
              <div className="supply">
                <div className="title">
                  {FormatNumber(pathData?.banker_fixed_fee, 2)}{' '}
                  {pathData?.token_profile_data?.coinSymbol}
                </div>
                <div className="label">Circulating Supply</div>
              </div>
              <div className="capital">
                <div className="title">
                  {FormatNumber(pathData?.asset_balance, 2)} USD
                </div>
                <div className="label">Fund Balance</div>
              </div>
            </div>
          </div>
        );
      case 'Banker':
        return (
          <div className="rows">
            <div className="d-row">
              <div className="boxView">
                <div className="label">Country</div>
                <div className="cData">
                  <img src={globe} alt="" />
                  <span>{pathData?.country}</span>
                </div>
              </div>
              <div className="boxView">
                <div className="label">Currency</div>
                <div className="cData">
                  <img src={globe} alt="" />
                  <span>Bitcoin</span>
                </div>
              </div>
            </div>
            <div className="d-row">
              <div className="boxView">
                <div className="label">Type</div>
                <div className="cData">
                  <img src={globe} alt="" />
                  <span>{pathData?.investmentType}</span>
                </div>
              </div>
              <div className="boxView">
                <div className="label">Path</div>
                <div className="cData">
                  <img src={fundOffering} alt="" />
                  <span>Fund Offering</span>
                </div>
              </div>
            </div>
            <div className="d-row">
              <div className="boxView">
                <div className="label">Fixed Fee</div>
                <div className="cData">
                  <span>
                    $
                    {FormatCurrency(
                      pathData?.banker_fixed_fee,
                      'USD',
                      defaultPrecission
                    )}{' '}
                    USD
                  </span>
                </div>
              </div>
              <div className="boxView">
                <div className="label">Variable Fee</div>
                <div className="cData">
                  <span>{FormatCurrency(pathData?.banker_trade_fee)}%</span>
                </div>
              </div>
            </div>
          </div>
        );

      default:
        break;
    }
  };

  return (
    <div className="marketDetail">
      <div className="mainContents">
        <div className="breadCrumbs">
          <span onClick={() => setPathId('')}>Markets</span>
          <span className="gt">&gt;</span>
          <span onClick={() => setPathId('')}>
            {selectedInvestment.name || 'All'}
          </span>
          <span className="gt">&gt;</span>
          <span>{pathData?.token_profile_data?.coinName}</span>
        </div>
        <div className="headBar">
          <img src={pathData?.token_profile_data?.coinImage} alt="" />
          <div className="headText">
            {pathData?.token_profile_data?.coinName}
          </div>
          <a
            href={`https://initialfundoffering.com/prospectus/${selectedInvestment?.code}/${pathId}`}
            target="_blank"
            rel="noopener noreferrer"
            className="btnWhite"
          >
            Virtual Prospectus
          </a>
          <div
            className="btnBlack"
            onClick={() => {
              setChatOn(true);
              setChatPathData(pathData);
            }}
          >
            Buy {pathData?.token_profile_data?.coinName}
          </div>
        </div>
        <p className="desc">{pathData?.token_profile_data?.coinDescription}</p>
        {getData()}
      </div>
      <div className="tabsInv">
        <div
          className={`tabItm ${btTab === 'Asset'}`}
          onClick={() => {
            setBtTab('Asset');
          }}
        >
          Asset
        </div>
        <div
          className={`tabItm ${btTab === 'Banker'}`}
          onClick={() => {
            setBtTab('Banker');
          }}
        >
          Banker
        </div>
        <div
          className={`tabItm ${btTab === 'Investment'}`}
          onClick={() => {
            setBtTab('Investment');
          }}
        >
          Investment
        </div>
      </div>
    </div>
  );
}

export default MarketDetail;
