import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';
import classNames from '../marketsPage.module.scss';
import clock from '../../../static/images/marketsSidebar/clock.svg';
import angleDown from '../../../static/images/angleDown.svg';
import { FormatCurrency, FormatNumber } from '../../../utils/FunctionTools';
import { useAppContextDetails } from '../../../context/AppContext';
import { useShareTokensList } from '../../../queryHooks';
import market from '../../../assets/share.svg';
import './Market.scss';
import axios from 'axios';
import { useEffect } from 'react';
import { faSleigh } from '@fortawesome/free-solid-svg-icons';
function MarketTableShares({
  streamOpen,
  setCoinSelected,
  assetClass,
  search,
}) {
  const history = useHistory();
  // const { data: sharesList = [], isLoading } = useShareTokensList();
  const [filterOpen, setFilterOpen] = useState(false);
  const { appCurrencySymbol, marketSearch } = useAppContextDetails();
  const [tab, settab] = useState('All');
  const [tabName, settabName] = useState([]);
  const [sharesList, setsharesList] = useState([]);
  const [isLoading, setisLoading] = useState([]);
  const [detail, setdetail] = useState({
    icon: market,
    name: "ShareTokens",
    description:
      'Market capitalization shows the value of a corporation by multiplying the stock price by the number of stocks outstanding. Here you can see the companies with the largest market cap. Large-cap stocks are usually industry and sector leaders and represent well-known, established companies.',
  });
  useEffect(() => {
    handleta();
    handlStoreShare();
  }, []);

  const handletablelist = (e) => {
    if (e == 'All') {
      settab('All');
      handlStoreShare();
      setdetail({
        icon: market,
        name: 'ShareTokens',
        description:
          'Market capitalization shows the value of a corporation by multiplying the stock price by the number of stocks outstanding. Here you can see the companies with the largest market cap. Large-cap stocks are usually industry and sector leaders and represent well-known, established companies.',
      });
    } else {
      settab(e);
      fliterdataapiTable(e);
      datafliter(e);
    }
  };

  const datafliter = (e) => {
    const filteredCars = tabName.filter((item) => item.code == e);
    console.log('dfsdfdf', filteredCars[0]);
    setdetail({
      icon: filteredCars?.[0]?.icon,
      name: filteredCars?.[0]?.name,
      description: filteredCars?.[0]?.description,
    });
  };

  const fliterdataapiTable = async (e) => {
    setisLoading(true);
    let data = await axios.get(
      `https://comms.globalxchange.io/coin/investment/path/get?investmentSubType=${e}&investmentType=EQT`
    );
    let res = data?.data;
    console.log('xczzxc', res);
    if (res?.status) {
      setsharesList(res?.paths);
      setisLoading(false);
    }
  };

  const handlStoreShare = async () => {
    setisLoading(true);
    let data = await axios.get(
      `https://comms.globalxchange.io/coin/investment/path/get?investmentType=EQT`
    );
    let res = data?.data;
    console.log('xczzxc', res);
    if (res?.status) {
      setsharesList(res?.paths);
      setisLoading(false);
    }
  };

  const handleta = async () => {
    let data = await axios.get(
      `https://comms.globalxchange.io/coin/investment/sub/types/get?parent_investment_type=EQT`
    );
    let res = data?.data;
    console.log('xczzxc', res);
    if (res?.status) {
      settabName(res?.investmentSubTypes);
    }
  };

  return (
    <>
      <div className="tabSelectedInmarket">
        <div
          className="title"
          style={{
            background: tab == 'All' ? '#f9f9f9' :"white",
            // color: tab == 'All' && '#fff',
            fontWeight: tab == 'All' && '600',
            // borderRadius:tab == 'All' && "35px 0 0 0px"
            position: 'relative',
            bottom: tab == 'All' && "-1px",
         
            // borderRadius: '104px 0 0 0',
            marginLeft: '1px',
          }}
          onClick={() => handletablelist('All')}
        >
          All
        </div>
        {tabName?.map((item) => {
          return (
            <div
              className="title"
              style={{
                background: tab == item?.code ? '#f9f9f9':"white",
                // background: tab == item?.code && '#4CAF50',
                fontWeight: tab == item?.code && '600',
                // color: tab == item?.code && '#fff',
                position: 'relative',
                fontWeight: tab == item?.code  && '600',
               
                bottom: tab == item?.code && "-1px",
                // borderRadius:tab == item?.code && "35px 0 0 0px"
              }}
              onClick={() => handletablelist(item?.code)}
            >
              {item?.name}
            </div>
          );
        })}
      </div>

      <div className="describtionmarket">
        <div className="img-sec">
          <img src={detail?.icon} />
          <h1>{detail?.name}</h1>
        </div>
        <p>{detail?.description}</p>
      </div>

      <div className={classNames.marketTablecustom}>
        <div
          className={`${classNames.header} ${classNames[!Boolean(streamOpen)]}`}
          style={{
            height: '39px',
            // padding: "24px 3rem",
            background: 'white',
          }}
        >
          <div className={classNames.Company}>Company</div>
          <div className={classNames.assets}>Asset</div>
          <div className={classNames.price}>Price</div>
          {/* <div className={classNames.roiPercent}>24 Hr Change</div> */}
          <div className={classNames.supply}>Supply</div>
          <div className={classNames.marCap}>Market Cap</div>
          <div className={classNames.volume}>Shareholders</div>
          {/* <img
            className={`${classNames.btnFilter} ${
              classNames[filterOpen.toString()]
            }`}
            src={angleDown}
            alt=""
            onClick={() => setFilterOpen(!filterOpen)}
          /> */}
        </div>
        {filterOpen && (
          <div className={classNames.filtersWrap}>
            <div className={classNames.filters}>
              <div className={classNames.filter}>
                <img src={clock} alt="" />
                <span>24 Hrs</span>
              </div>
              <div className={classNames.filter}>
                <img
                  src={
                    'https://apimachine-s3.s3.us-east-2.amazonaws.com/coinImages/rupee.png'
                  }
                  alt=""
                />
                <span>{appCurrencySymbol}</span>
              </div>
              <div className={classNames.filter}>
                + <span>Add Filter</span>
              </div>
            </div>
          </div>
        )}
        <div className={classNames.marketsList}>
          {isLoading
            ? Array(8)
                .fill('')
                .map((_, i) => (
                  <div
                    className={`${classNames.marketItem}  ${
                      classNames[!Boolean(streamOpen)]
                    }`}
                    key={i}
                  >
                    <Skeleton width={200} />
                    <Skeleton width={200} />
                    <Skeleton width={200} />
                    {/* <Skeleton width={200} /> */}
                    {/* <Skeleton width={200} /> */}
                  </div>
                ))
            : sharesList
                ?.filter(
                  (path) =>
                    path?.token_profile_data?.coinSymbol
                      ?.toLowerCase()
                      .includes(marketSearch.toLowerCase()) ||
                    path?.token_profile_data?.coinName
                      ?.toLowerCase()
                      .includes(marketSearch.toLowerCase())
                )
                ?.map((path, i) => (
                  <div
                    className={`${classNames.marketItem}  ${
                      classNames[!Boolean(streamOpen)]
                    }`}
                    key={path._id}
                    onClick={() => {
                      setCoinSelected({
                        coinSymbol: path?.token_profile_data?.coinSymbol,
                        coinName: path?.token_profile_data?.coinName,
                        coinImage: path?.token_profile_data?.coinImage,
                      });
                      history.push(
                        `/shares/${assetClass.name}/${path?.token_profile_data?.coinSymbol}`
                      );
                    }}
                  >
                    <div className={classNames.assets}>
                      <img src={path?.token_profile_data?.coinImage} alt="" />
                      <div
                        className={classNames.symbol}
                        style={{
                          marginLeft: '5px',
                          background: '#F5F5F5',
                          padding: '5px 1rem',
                          borderRadius: '5px',
                        }}
                      >
                        {path?.token_profile_data?.coinSymbol}
                        {/* <img src={path?.asset_metaData?.coinImage} alt="" /> */}
                        {/* <strong>{path?.asset_metaData?.coinName}</strong> */}
                      </div>
                      <div
                        className={classNames.names}
                        style={{
                          marginLeft: '10px',
                        }}
                      >
                        <span className={classNames.name}>
                          {path?.token_profile_data?.coinName}
                        </span>
                      </div>
                    </div>

                    <div className={classNames.complany}>
                      {/* {appCurrencySymbol}&nbsp; */}
                      {path?.asset}
                    </div>
                    <div className={classNames.price}>
                      {/* {appCurrencySymbol}&nbsp; */}
                      {FormatCurrency(path?.token_price, appCurrencySymbol)}
                    </div>
                    {/* <div
                    className={`${classNames.roiPercent} ${classNames[0 < 0]}`}
                    style={{ opacity: 0.4 }}
                  >
                    {FormatNumber(0, 2)}%
                  </div> */}
                    <div className={classNames.supply}>
                      {FormatNumber(path?.token_totalSupply)}&nbsp;
                    </div>

                    <div className={classNames.marCap}>
                      {FormatCurrency(path?.asset_balance, appCurrencySymbol)}
                    </div>
                    <div className={classNames.marCap}>
                      {path?.tokenHoldersCount}
                    </div>
                    {/* */}
                    {/* <div className={classNames.volume}>
                    <div className={classNames.value}>
                      {path?.pathSeries && path?.pathSeries[0]?.seriesName}
                    </div>
                   
                  </div> */}
                  </div>
                ))}
        </div>
      </div>
    </>
  );
}

export default MarketTableShares;
