import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import { useMemo } from 'react';
import { AppContext } from '../../../context/AppContext';
import { BankContext } from '../../../context/Context';
import SwitcherSearch from '../../MobilePages/SwitcherSearch';

const SellStepOne = () => {
  const {
    selectedOperation,
    appCode,
    selectedAsset,
    setSelectedAsset,
    setBuySellStep,
    tradeResponse,
    setTradeResponse,
  } = useContext(AppContext);
  const { email, profileId, token } = useContext(BankContext);
  const [openSearch, setOpenSearch] = useState(false);
  const [assetList, setAssetList] = useState([]);

  const [fromBalance, setFromBalance] = useState(null);
  const [toBalance, setToBalance] = useState(0);
  const [fromValue, setFromValue] = useState('');
  const [toValue, setToValue] = useState(0);
  const [pathId, setPathId] = useState('');
  const [toData, setToData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tradeLoading, setTradeLoading] = useState(false);
  const [percentage, setPercentage] = useState('');

  // activecryto = selectedAsset
  // selectedcoin = selectedOperation?.coinData?.coinSymbol

  // NOTICE: Here From & To balance state are used in reverse.

  useEffect(() => {
    getUpdatedFromBalance(selectedOperation?.coinData.coinSymbol);
  }, []);

  useEffect(() => {
    if (selectedAsset) {
      getPathId();
      getUpdatedToBalance(selectedAsset?.coinSymbol);
    }
  }, [selectedAsset]);

  const getUpdatedFromBalance = async (coin) => {
    axios
      .post(`https://comms.globalxchange.io/coin/vault/service/coins/get`, {
        app_code: appCode,
        email: email,
        displayCurrency: 'INR',
        include_coins: [coin],
      })
      .then(({ data }) => {
        setFromBalance(data.coins_data[0].coinValue);
      });
  };

  const getUpdatedToBalance = async (coin) => {
    axios
      .post(`https://comms.globalxchange.io/coin/vault/service/coins/get`, {
        app_code: appCode,
        email: email,
        displayCurrency: 'INR',
        include_coins: [coin],
      })
      .then(({ data }) => {
        setToBalance(data.coins_data[0].coinValue);
        getPathId();
      });
  };
  const getPathId = () => {
    axios
      .get(
        `https://comms.globalxchange.io/coin/vault/service/payment/stats/get`,
        {
          params: {
            select_type: 'instant',
            to_currency: selectedAsset?.coinSymbol,
            from_currency: selectedOperation?.coinData.coinSymbol,
            paymentMethod: 'vault',
            banker: 'Global X Change',
            app_code: appCode,
          },
        }
      )
      .then(({ data }) => {
        setPathId(data.pathData.paymentPaths[0].path_ids[0]);
      });
  };

  const getToValue = async () => {
    setLoading(true);
    axios
      .post(`https://comms.globalxchange.io/coin/vault/service/trade/execute`, {
        token: token,
        email: email,
        app_code: appCode,
        profile_id: profileId,
        from_amount: fromValue,
        stats: true,
        identifier: `Exchange ${selectedOperation?.coinData?.coinSymbol} For ${selectedAsset?.coinSymbol}`,
        path_id: pathId,
      })
      .then(({ data }) => {
        setToData(data);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (fromValue && selectedAsset && pathId) {
      getToValue();
    }
  }, [fromValue, selectedAsset, pathId]);

  useEffect(() => {
    axios
      .get(
        `https://comms.globalxchange.io/coin/vault/service/payment/stats/get`,
        {
          params: {
            select_type: 'instant',
            to_currency: selectedOperation?.coinData?.coinSymbol,
            paymentMethod: 'vault',
            banker: 'Global X Change',
            app_code: 'indianotc',
          },
        }
      )
      .then(({ data }) => {
        if (data.status) {
          const tempArr = [];

          data.pathData.from_currency.map((item) => {
            tempArr.push(item.coin_metadata);
          });
          setAssetList(tempArr);
        }
      });
  }, [openSearch]);

  const calcValue = (perc) => {
    setPercentage(perc);
    var value = (Number(fromBalance) * Number(perc)) / 100;
    setFromValue(value.toString());
  };

  const conditionalToData = () => {
    if (loading) {
      return 'Loading ...';
    } else {
      if (toData) {
        return toData?.finalToAmount;
      } else {
        return '0.0000';
      }
    }
  };

  useEffect(() => {
    conditionalToData();
  }, [toData]);

  const handlePlaceTrade = async () => {
    setTradeLoading(true);

    axios
      .post(`https://comms.globalxchange.io/coin/vault/service/trade/execute`, {
        token: token,
        email: email,
        app_code: appCode,
        profile_id: profileId,
        from_amount: fromValue,
        stats: false,
        identifier: `Exchange ${selectedOperation?.coinData?.coinSymbol} For ${selectedAsset?.coinSymbol}`,
        path_id: pathId,
      })
      .then(({ data }) => {
        if (data.status) {
          console.log(data, 'wkjefgkwjegfkwjefb');
          setTradeLoading(false);
          setBuySellStep('SellStepTwo');
          setTradeResponse(data);
          setSelectedAsset(null);
          // navigate('BuyCryptoStepTwo', {
          //   resData: data,
          // });
        }
      });
  };

  const feeStructure = useMemo(() => {
    return (
      <>
        <div className="labelText" style={{ paddingTop: '15px' }}>
          <div>Fees in {toData ? toData?.fees_in_coin : 'TBD'}</div>
          {toData ? (
            <div>
              {toData?.gx_fee?.toFixed(5)} (
              {toData?.percentage_fee_by_volume?.fees_with_broker?.toFixed(4)}
              %)
            </div>
          ) : (
            <div>0.00 (0.00%)</div>
          )}
        </div>
        <div className="feesCard">
          <div className="feesRow">
            <div>Affiliate</div>
            <div>
              {toData ? (
                <>
                  {toData?.brokerData?.fee?.toFixed(5)} (
                  {toData?.brokerData?.broker_fee_percentage?.toFixed(4)}%)
                </>
              ) : (
                <>0.00 (0.00%)</>
              )}
            </div>
          </div>
          <div className="feesRow">
            <div>App</div>
            <div>
              {toData ? (
                <>
                  {toData?.appData?.app_fee?.toFixed(4)} (
                  {toData?.appData?.app_fee_percentage?.toFixed(3)}%)
                </>
              ) : (
                <>0.00 (0.00%)</>
              )}
            </div>
          </div>
          <div className="feesRow" style={{ border: 'none' }}>
            <div>Banker</div>
            <div>
              {toData ? (
                <>
                  {toData?.bankerData?.trade_fee_native?.toFixed(4)} (
                  {toData?.bankerData?.banker_fee_percentage?.toFixed(3)}%)
                </>
              ) : (
                <>0.00 (0.00%)</>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }, [toData]);

  return (
    <div>
      {!openSearch ? (
        <>
          <div className="breadcrumb">
            <span onClick={(e) => setBuySellStep(null)}>Actions</span>
            &nbsp;&nbsp;{`>`}&nbsp;&nbsp;
            <span style={{ fontWeight: 700 }}>
              Sell {selectedOperation?.coinData?.coinName}
            </span>
          </div>
          <div className="containerStyle">
            {/* From Input */}
            <div className="inputCardWithLabel">
              <div className="labelText">
                <div>From</div>
                <div>Balance: {fromBalance?.toFixed(4)}</div>
              </div>
              <div className="inputStyle">
                <div style={{ width: '60%' }}>
                  <input
                    type="number"
                    placeholder="0.0000"
                    value={fromValue}
                    onChange={(e) => setFromValue(e.target.value)}
                  />
                </div>
                <div style={{ width: '40%' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      src={selectedOperation?.coinData?.coinImage}
                      alt=""
                      style={{ width: '20px', height: '20px' }}
                    />
                    <span
                      className="inputButton"
                      style={{ padding: '0px 10px' }}
                    >
                      {selectedOperation?.coinData?.coinSymbol}
                    </span>
                    <span>{`>`}</span>
                  </div>
                </div>
              </div>
            </div>
            {/* Percentage */}
            <div className="percentageWrapper">
              <div
                style={{
                  background: percentage === 25 ? 'black' : '',
                  color: percentage === 25 ? 'white' : '',
                }}
                onClick={(e) => calcValue(25)}
              >
                25%
              </div>
              <div
                style={{
                  background: percentage === 50 ? 'black' : '',
                  color: percentage === 50 ? 'white' : '',
                }}
                onClick={(e) => calcValue(50)}
              >
                50%
              </div>
              <div
                style={{
                  background: percentage === 100 ? 'black' : '',
                  color: percentage === 100 ? 'white' : '',
                }}
                onClick={(e) => calcValue(100)}
              >
                100%
              </div>
            </div>
            {/* To Input */}
            <div className="inputCardWithLabel">
              <div className="labelText">
                <div>To</div>
                <div>Balance: {toBalance?.toFixed(4)}</div>
              </div>
              <div className="inputStyle">
                <div style={{ width: '60%' }}>
                  <input value={conditionalToData()} />
                </div>
                <div
                  style={{ width: '40%' }}
                  onClick={(e) => {
                    setOpenSearch(true);
                  }}
                >
                  {selectedAsset ? (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <img
                        src={selectedAsset?.coinImage}
                        alt=""
                        style={{ width: '20px', height: '20px' }}
                      />
                      <span
                        className="inputButton"
                        style={{ padding: '0px 10px' }}
                      >
                        {selectedAsset?.coinSymbol}
                      </span>
                      <span>{`>`}</span>
                    </div>
                  ) : (
                    <div className="inputButton">Select Asset {`>`}</div>
                  )}
                </div>
              </div>
            </div>

            {/* Fees Card */}
            {feeStructure}
            {/* <div className="labelText" style={{ paddingTop: '15px' }}>
              <div>Fees in {toData ? toData?.fees_in_coin : 'TBD'}</div>
              {toData ? (
                <div>
                  {toData?.gx_fee?.toFixed(5)} (
                  {toData?.percentage_fee_by_volume?.fees_with_broker?.toFixed(
                    4
                  )}
                  %)
                </div>
              ) : (
                <div>0.00 (0.00%)</div>
              )}
            </div>
            <div className="feesCard">
              <div className="feesRow">
                <div>Affiliate</div>
                <div>
                  {toData ? (
                    <>
                      {toData?.brokerData?.fee?.toFixed(5)} (
                      {toData?.brokerData?.broker_fee_percentage?.toFixed(4)}%)
                    </>
                  ) : (
                    <>0.00 (0.00%)</>
                  )}
                </div>
              </div>
              <div className="feesRow">
                <div>App</div>
                <div>
                  {toData ? (
                    <>
                      {toData?.appData?.app_fee?.toFixed(4)} (
                      {toData?.appData?.app_fee_percentage?.toFixed(3)}%)
                    </>
                  ) : (
                    <>0.00 (0.00%)</>
                  )}
                </div>
              </div>
              <div className="feesRow" style={{ border: 'none' }}>
                <div>Banker</div>
                <div>
                  {toData ? (
                    <>
                      {toData?.bankerData?.trade_fee_native?.toFixed(4)} (
                      {toData?.bankerData?.banker_fee_percentage?.toFixed(3)}%)
                    </>
                  ) : (
                    <>0.00 (0.00%)</>
                  )}
                </div>
              </div>
            </div> */}
            {/* Buttons */}
            <div className="halfButtonWrapper">
              <div onClick={(e) => setBuySellStep(null)}>Back</div>
              <div onClick={(e) => handlePlaceTrade()}>Place Trade</div>
            </div>
          </div>
        </>
      ) : (
        <SwitcherSearch
          path={'SellStepOne'}
          listData={assetList}
          setSelectedData={setSelectedAsset}
          setOpenSearch={setOpenSearch}
          setPathId={setPathId}
        />
      )}
    </div>
  );
};

export default SellStepOne;
