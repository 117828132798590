import React, { useEffect, useState } from 'react';
import classNames from './shareToken.module.scss';
import Layout from '../../Layout/Layout';
import { useAppContextDetails } from '../../context/AppContext';
import MarketAssetDetail from '../../components/MarketAssetDetail';
import { useShareTokensDetail } from '../../queryHooks';
import marketClassName from '../MarketsPage/marketsPage.module.scss';
import TerminalsSidebar from '../../components/TerminalsSidebar';
import { SHARESTOKEN_HEADERS } from './constants';

function ShareTokenPage() {
  const { token } = useAppContextDetails();
  const [streamOpen, setStreamOpen] = useState(true);
  const [coinSelected, setCoinSelected] = useState();
  const [selectedHeader, setSelectedHeader] = useState(SHARESTOKEN_HEADERS[0]);

  const { data: shareToken } = useShareTokensDetail(token);

  useEffect(() => {
    if (shareToken?.token_profile_data) {
      setCoinSelected(shareToken?.token_profile_data);
    }
  }, [token, shareToken]);

  return (
    <Layout
      active="sharetoken"
      className={classNames.shareTokenPage}
      hideFooter
    >
      <div className={marketClassName.marketsPage}>
        <div className={marketClassName.pageWrap}>
          <div className={marketClassName.assetClassWrap}>
            <div className={marketClassName.assetClass}>
              {SHARESTOKEN_HEADERS.map((headers, i) => (
                <div
                  key={i}
                  className={`${marketClassName.assetClassCard} ${
                    marketClassName[headers === selectedHeader]
                  } ${marketClassName}`}
                  onClick={() => {
                    if (headers.marketEnable) {
                      setSelectedHeader(headers);
                      // history.push(`/market/${headers.name}`);
                    }
                  }}
                >
                  <img
                    src={headers.icon}
                    alt=""
                    className={marketClassName.icon}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className={marketClassName.pageContent}>
            <MarketAssetDetail
              coin={coinSelected}
              coinSymbol={token}
              streamOpen={streamOpen}
              setStreamOpen={setStreamOpen}
              marketsPage={false}
            />
          </div>
        </div>
        <div
          className={`${marketClassName.sidebarArea} ${
            marketClassName[Boolean(streamOpen)]
          }`}
        >
          <div className={marketClassName.sidebarContent}>
            <TerminalsSidebar
              shareToken={shareToken}
              streamOpen={streamOpen}
              setStreamOpen={setStreamOpen}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default ShareTokenPage;
