import axios from 'axios';
import { useMutation, useQuery } from 'react-query';

export const getAssets = async (params) => {
  const { fromCurrency, toCurrency, appCode } = params;

  const response = await axios.get(
    `https://comms.globalxchange.io/coin/vault/service/payment/stats/get?select_type=instant&from_currency=${fromCurrency}&to_currency=${toCurrency}&paymentMethod=vault&banker=Global X Change&app_code=${appCode}`
  );
  return response;
};

export const useLoadAssets = (fromCurrency, toCurrency, appCode) => {
  const query = useQuery(
    ['loadAssets', { fromCurrency, toCurrency, appCode }],
    () => getAssets({ fromCurrency, toCurrency, appCode }),
    { enabled: Boolean(appCode) }
  );
  return query;
};

export const withdrawalwithStats = (object) => {
  try {
    const response = axios.post(
      `https://comms.globalxchange.io/coin/vault/service/trade/execute`,
      object
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getPathId = (object) => {
  const { select_type, to_currency, from_currency, paymentMethod } = object;
  try {
    const response = axios.get(
      `https://comms.globalxchange.io/coin/vault/service/payment/paths/get?select_type=${select_type}&to_currency=${to_currency}&from_currency=${from_currency}&paymentMethod=${paymentMethod}`
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getTradeValue = async (params) => {
  const response = await axios.post(
    `https://comms.globalxchange.io/coin/vault/service/trade/execute`,
    params
  );
  return response;
};

export const useLoadTradeValue = () => {
  const mutation = useMutation(getTradeValue);
  return mutation;
};

export const getCoindata = async (params) => {
  try {
    const response = await axios.post(
      `https://comms.globalxchange.io/coin/vault/service/coins/get`,
      params
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const useCoinData = () => {
  const mutation = useMutation(getCoindata);
  return mutation;
};

export const generateAddressForBTC = async (params) => {
  try {
    const data = await axios.post(
      'https://comms.globalxchange.io/coin/vault/service/coin/request/address',
      params
    );
    return data;
  } catch (err) {
    console.error(err);
  }
};

export const generateAddressForEthToken = async (params) => {
  try {
    const data = await axios.post(
      'https://comms.globalxchange.io/coin/vault/service/address/create',
      params
    );
    return data;
  } catch (err) {
    console.error(err);
  }
};

export const generateAddressForXRPOrTRX = async (params) => {
  try {
    const data = await axios.post(
      'https://comms.globalxchange.io/coin/vault/service/crypto/address/request',
      params
    );
    return data;
  } catch (err) {
    console.error(err);
  }
};

export const generateAddressForLTCOrDOGE = async (params) => {
  try {
    const data = await axios.post(
      'https://comms.globalxchange.io/coin/vault/service/bit/coins/generate/address',
      params
    );
    return data;
  } catch (err) {
    console.error(err);
  }
};

export const quickConvertCurrency = async (fromCoin, toCoin) => {
  try {
    const data = await axios.get(
      `https://comms.globalxchange.io/forex/convert?buy=${toCoin}&from=${fromCoin}`
    );
    return data;
  } catch (err) {
    console.error(err);
  }
};

export const initiateVerificationEmail = async (params) => {
  try {
    const data = axios.post(
      `https://comms.globalxchange.io/coin/vault/service/user/send/extenal/verify/mail`,
      params
    );
    return data;
  } catch (err) {
    console.error(err);
  }
};

export const useSendVerificationEmail = (setSendVerificationMail) => {
  const mutation = useMutation(initiateVerificationEmail, {
    onSuccess: () => setSendVerificationMail(false),
  });
  return mutation;
};

export const submitHashForETH = async (body) => {
  try {
    const data = await axios.post(
      `https://comms.globalxchange.io/coin/vault/service/deposit/eth/coins/request`,
      body
    );
    return data;
  } catch (err) {
    console.error(err);
  }
};

export const submitHashForSOL = async (body) => {
  try {
    const data = await axios.post(
      `https://comms.globalxchange.io/coin/vault/service/deposit/sol/external/request`,
      body
    );
    return data;
  } catch (err) {
    console.error(err);
  }
};

export const submitHashForTRX = async (body) => {
  try {
    const data = await axios.post(
      `https://comms.globalxchange.io/coin/vault/service/deposit/trx/external/request`,
      body
    );
    return data;
  } catch (err) {
    console.error(err);
  }
};

export const submitHashForXRP = async (body) => {
  try {
    const data = await axios.post(
      `https://comms.globalxchange.io/coin/vault/service/deposit/xrp/external/request`,
      body
    );
    return data;
  } catch (err) {
    console.error(err);
  }
};

export const useSubmitHash = (
  setAddActionStep,
  setSubmitHashEnabled,
  selectedCoin
) => {
  const submitHashApi = () => {
    if (selectedCoin.eth_token || selectedCoin.coinSymbol === 'ETH')
      return submitHashForETH;
    else if (selectedCoin.coinSymbol === 'TRX') return submitHashForTRX;
    else if (selectedCoin.coinSymbol === 'XRP') return submitHashForXRP;
    else if (selectedCoin.coinSymbol === 'SOL') return submitHashForSOL;
  };

  const mutation = useMutation(submitHashApi(), {
    onSuccess: () => {
      setSubmitHashEnabled(false);
      setAddActionStep(4);
    },
  });
  return mutation;
};
