import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import React, {
  useEffect,
  useState,
  createRef,
  useRef,
  useContext,
} from 'react';
import { BankContext } from '../context/Context';
import guest from '../static/images/guest.jpg';
import OnOutsideClick from '../utils/OnOutsideClick';

function ViewAsUserItem({ user, onClick }) {
  const [userData, setUserData] = useState({});

  const elRefs = useRef([]);
  if (elRefs.current.length !== 2) {
    // add or remove refs
    elRefs.current = Array(2)
      .fill()
      .map((_, i) => elRefs.current[i] || createRef());
  }

  const refOut = useRef();
  OnOutsideClick(
    undefined,
    () => {
      try {
        onClick();
      } catch (error) {}
    },
    refOut,
    elRefs
  );
  const { copyToClipboard } = useContext(BankContext);
  useEffect(() => {
    user &&
      Axios.get(
        `https://comms.globalxchange.io/user/details/get?email=${user.email}`
      ).then(({ data }) => {
        if (data.status) {
          setUserData(data.user);
        }
      });
  }, [user]);
  return (
    <div ref={refOut} className="viewAsUserItem">
      <img
        src={(user && user.profile_img) || guest}
        alt=""
        className="profileImg"
      />
      <div className="nameEmail">
        <div className="name">
          {user.name}
          <FontAwesomeIcon
            forwardedRef={elRefs.current[0]}
            icon={faCopy}
            className="cpyIcn"
            onClick={() => {
              copyToClipboard(`${user.name}`, `Name Copied`);
            }}
          />
        </div>
        <div className="email">
          {user.email}
          <FontAwesomeIcon
            forwardedRef={elRefs.current[1]}
            icon={faCopy}
            className="cpyIcn"
            onClick={() => {
              copyToClipboard(`${user.email}`, `Email Copied`);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default ViewAsUserItem;
