import React from 'react';

const capRegex = new RegExp(/^.*[A-Z].*/);
const numRegex = new RegExp(/^.*[0-9].*/);
const speRegex = new RegExp(/^.*[!@#$%^&*()+=].*/);

function PasswordRequirementModal({ password, validateCircle, onClose }) {
  return (
    <div className="modalSessionExpired">
      <div
        className="overlayClose"
        onClick={() => {
          try {
            onClose();
          } catch (error) {}
        }}
      />
      <div className="modalContent passwordReq">
        <div className="head">
          <h5>Password Requirements</h5>
        </div>
        <div className="contents">
          <div className="item">
            Should be 8 charectors
            {validateCircle(password.length >= 8)}
          </div>
          <div className="item">
            Contains Atleast One Capital Letter
            {validateCircle(capRegex.test(password))}
          </div>
          <div className="item">
            Contains Atleast One Number
            {validateCircle(numRegex.test(password))}
          </div>
          <div className="item">
            Contains Atleast One Special Charector
            {validateCircle(speRegex.test(password))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PasswordRequirementModal;
