import React, { useRef, useState, useContext } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';
import classNames from '../marketsPage.module.scss';
import clock from '../../../static/images/marketsSidebar/clock.svg';
import angleDown from '../../../static/images/angleDown.svg';
import { useMarketCoinsList } from '../../../queryHooks';
import { FormatCurrency, FormatNumber } from '../../../utils/FunctionTools';
import { useAppContextDetails } from '../../../context/AppContext';
import { useCoinContextData } from '../../../context/CoinContext';
import { BankContext } from '../../../context/Context';

function MarketTableCrypto({
  streamOpen,
  setCoinSelected,
  assetClass,
  active,
  search,
}) {
  const headRef = useRef();
  const { appCode, appCurrencySymbol, appCurrencyName } =
    useAppContextDetails();
  const { setCoinActionEnabled, setSelectedCoin, setCoinAction, setSwapMenu } =
    useCoinContextData();
  const { data: cryptoList = [], isLoading } = useMarketCoinsList(
    appCode,
    'crypto',
    appCurrencyName
  );
  const [filterOpen, setFilterOpen] = useState(false);
  const history = useHistory();
  const { setSidebarCollapse } = useContext(BankContext);

  return (
    <div className={classNames.marketTable}>
      <div
        className={`${classNames.header} ${classNames[!streamOpen]} ${
          classNames[active ? 'active' : '']
        }`}
        ref={headRef}
      >
        <div className={classNames.assets}>Asset</div>
        <div className={classNames.price}>Price</div>
        <div className={classNames.roiPercent}>24 Hr Change</div>
        <div className={classNames.marCap}>Market Cap</div>
        <div className={classNames.volume}>Trading Volume</div>
        <div className={classNames.supply}>Supply</div>
        <img
          className={`${classNames.btnFilter} ${
            classNames[filterOpen.toString()]
          }`}
          src={angleDown}
          alt=""
          onClick={() => setFilterOpen(!filterOpen)}
        />
      </div>
      {filterOpen && (
        <div className={classNames.filtersWrap}>
          <div className={classNames.filters}>
            <div className={classNames.filter}>
              <img src={clock} alt="" />
              <span>24 Hrs</span>
            </div>
            <div className={classNames.filter}>
              <img
                src={
                  'https://apimachine-s3.s3.us-east-2.amazonaws.com/coinImages/rupee.png'
                }
                alt=""
              />
              <span>{appCurrencySymbol}</span>
            </div>
            <div className={classNames.filter}>
              + <span>Add Filter</span>
            </div>
          </div>
        </div>
      )}
      <div className={classNames.marketsList}>
        {isLoading
          ? Array(8)
              .fill('')
              .map((_, i) => (
                <div
                  className={`${classNames.marketItem}  ${
                    classNames[!streamOpen]
                  }`}
                  key={i}
                >
                  <Skeleton width={200} />
                  <Skeleton width={200} />
                  <Skeleton width={200} />
                  <Skeleton width={200} />
                  <Skeleton width={200} />
                </div>
              ))
          : cryptoList
              ?.filter(
                (coin) =>
                  coin.coinName.toLowerCase().includes(search.toLowerCase()) ||
                  coin.coinSymbol.toLowerCase().includes(search.toLowerCase())
              )
              ?.map((coin, i) => (
                <div
                  className={`${classNames.marketItem}  ${
                    classNames[!streamOpen]
                  }`}
                  key={coin._id}
                  onClick={() => {
                    setCoinActionEnabled(true);
                    setSelectedCoin(coin);
                    // setCoinAction(['Menu']);
                    setSidebarCollapse(false);
                    setSwapMenu(true);
                  }}
                >
                  <div className={classNames.assets}>
                    <img src={coin.coinImage} alt="" />
                    <span className={classNames.name}>{coin.coinName}</span>
                  </div>
                  <div className={classNames.price}>
                    {appCurrencySymbol}&nbsp;
                    {FormatCurrency(
                      coin?.price?.[appCurrencySymbol],
                      appCurrencySymbol
                    )}
                  </div>
                  <div
                    className={`${classNames.roiPercent} ${
                      classNames[coin?._24hrchange < 0]
                    }`}
                  >
                    {FormatNumber(coin?._24hrchange, 2)}%
                  </div>
                  <div className={classNames.marCap}>
                    {appCurrencySymbol}&nbsp;
                    {FormatCurrency(coin?.mkt_cap_DC, appCurrencySymbol)}
                  </div>
                  <div className={classNames.volume}>
                    {appCurrencySymbol}&nbsp;
                    {FormatCurrency(coin?.volume24hr_DC, appCurrencySymbol)}
                  </div>
                  <div className={classNames.supply}>
                    {FormatNumber(coin?.total_supply)}&nbsp;
                    {coin.coinSymbol}
                  </div>
                </div>
              ))}
      </div>
    </div>
  );
}

export default MarketTableCrypto;
