import React, { useContext, useEffect } from 'react';
import CoinsData from '../MobileLayoutComponents/CoinsData/CoinsData';
import PageHeader from '../MobileLayoutComponents/PageHeader';

import { useState } from 'react';
import MainMenu from '../MobileLayoutComponents/MainMenu';
import { AppContext } from '../../context/AppContext';
import MarketCryptoForex from './MarketCryptoForex';
import MarketMoneyMarket from './MarketMoneyMarket';
import MarketBonds from './MarketBonds';
import VaultCrypto from './VaultCrypto';
import VaultForex from './VaultForex';
import VaultMoneyMarket from './VaultMoneyMarket';
import VaultBonds from './VaultBonds';
import MarketMarketsVerse from './MarketMarketsVerse';

const NewMobileLayout = () => {
  const {
    openSidebar,
    setOpenSidebar,
    selectedMenu,
    setSelectedMenu,
    selectedSubMenu,
    setSelectedSubMenu,
  } = useContext(AppContext);

  useEffect(() => {
    conditionalMobilePage();
  }, [selectedMenu, selectedSubMenu]);

  const conditionalMobilePage = () => {
    console.log();
    if (selectedMenu === 'market' && selectedSubMenu === 'Crypto') {
      return <MarketCryptoForex />;
    } else if (selectedMenu === 'market' && selectedSubMenu === 'Forex') {
      return <MarketCryptoForex />;
    } else if (
      selectedMenu === 'market' &&
      selectedSubMenu === 'MoneyMarkets'
    ) {
      return <MarketMoneyMarket />;
    } else if (selectedMenu === 'market' && selectedSubMenu === 'Bonds') {
      return <MarketBonds />;
    } else if (
      selectedMenu === 'market' &&
      selectedSubMenu === 'MarketsVerse'
    ) {
      return <MarketMarketsVerse />;
    } else if (selectedMenu === 'vault' && selectedSubMenu === 'Crypto') {
      return <VaultCrypto />;
    } else if (selectedMenu === 'vault' && selectedSubMenu === 'Forex') {
      return <VaultForex />;
    } else if (selectedMenu === 'vault' && selectedSubMenu === 'MoneyMarkets') {
      return <VaultMoneyMarket />;
    } else if (selectedMenu === 'vault' && selectedSubMenu === 'Bonds') {
      return <VaultBonds />;
    }
  };

  return (
    <>
      {!openSidebar ? (
        <div style={{ height: '100vh', width: '100%' }}>
          <PageHeader setOpenSidebar={setOpenSidebar} />
          {conditionalMobilePage()}
        </div>
      ) : (
        <MainMenu />
      )}
    </>
  );
};

export default NewMobileLayout;
