import Axios from 'axios';
import React, { Fragment, useState } from 'react';
import countryCodes from 'country-codes-list';

import emailIcn from '../../static/images/clipIcons/email.svg';
import phoneIcn from '../../static/images/clipIcons/phone.svg';
import bothIcn from '../../static/images/clipIcons/both.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import LoadingAnim from '../LoadingAnim/LoadingAnim';
import { useAppContextDetails } from '../../context/AppContext';

function SendInviteModal({ onClose, platform = 'android' }) {
  const myCountryCodesObject = countryCodes.customList(
    'countryCode',
    '+{countryCallingCode}'
  );
  const myCountryNamesObject = countryCodes.customList(
    'countryCode',
    '{countryNameEn}'
  );
  const [country, setCountry] = useState('IN');
  const [dropOpen, setDropOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [mobile, setMobile] = useState('');
  const [mailId, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [res, setRes] = useState('');
  const [resLink, setResLink] = useState('');
  const [copied, setCopied] = useState(false);
  const { appCode, appFullLogo } = useAppContextDetails();

  function getLink() {
    setLoading(true);
    Axios.post(
      'https://comms.globalxchange.io/coin/vault/service/send/app/links/invite',
      {
        userEmail: mailId,
        app_code: appCode,
        email: mailId,
        mobile: `${myCountryCodesObject[country]}${mobile}`,
        app_type: platform,
        custom_message: 'Download Now!',
      }
    )
      .then(({ data }) => {
        if (data.status) {
          setRes(data);
        } else {
          alert(data.message || 'Something Went Wrong');
        }
      })
      .finally(() => setLoading(false));
    Axios.get(
      `https://comms.globalxchange.io/gxb/apps/mobile/app/links/logs/get?app_code=${appCode}`
    ).then(({ data }) => {
      setResLink(data.logs[0]);
    });
  }
  const [step, setStep] = useState(0);
  const [tempStep, setTempStep] = useState(null);
  const [emailOnly, setEmailOnly] = useState(false);
  const getData = () => {
    switch (true) {
      case loading:
        return (
          <div className="contentInvite">
            <LoadingAnim />
          </div>
        );
      case step === 1:
        return (
          <Fragment key="0">
            <div className="contentInvite">
              <label htmlFor="">What Is Your Recipient’s Email Address</label>
              <div className="inputBox">
                <input
                  type="text"
                  value={mailId}
                  onChange={(e) => setEmail(e.target.value)}
                  className="number"
                  placeholder="Enter Email"
                />
                <div className="countryFlag">
                  <img className="flag" src={emailIcn} alt="" />
                </div>
              </div>
            </div>
            {emailOnly ? (
              <div className="footer" onClick={getLink}>
                Get Link
              </div>
            ) : (
              <div className="footer" onClick={() => setStep(2)}>
                <span>Next</span>
              </div>
            )}
          </Fragment>
        );
      case step === 2:
        return (
          <Fragment key="1">
            <div className="contentInvite">
              <label htmlFor="">
                {!dropOpen
                  ? 'What Is Your Recipient’s Phone Number'
                  : 'Find Your Country Code'}
              </label>
              <div className={`inputBox ${dropOpen}`}>
                {!dropOpen && (
                  <label className="dropDown">
                    <div onClick={() => setDropOpen(!dropOpen)}>
                      <span>{myCountryCodesObject[country]}</span>
                    </div>
                  </label>
                )}
                {dropOpen ? (
                  <input
                    type="text"
                    value={search}
                    className="number"
                    placeholder="Enter The Country"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                ) : (
                  <input
                    type="number"
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                    className="number"
                    placeholder="Enter Phone Number"
                  />
                )}
                <div
                  className="countryFlag"
                  onClick={() => setDropOpen(!dropOpen)}
                >
                  <img
                    className="flag"
                    src={`https://www.countryflags.io/${country}/shiny/64.png`}
                    alt=""
                  />
                </div>
                {dropOpen && (
                  <div className="dropList">
                    {Object.keys(myCountryCodesObject)
                      .filter(
                        (key) =>
                          myCountryCodesObject[key].includes(search) ||
                          myCountryNamesObject[key]
                            .toLowerCase()
                            .includes(search.toLowerCase()) ||
                          key.includes(search.toUpperCase())
                      )
                      .map((key) => (
                        <div
                          className="item"
                          key={key}
                          onClick={() => {
                            setCountry(key);
                            setDropOpen(false);
                          }}
                        >
                          <span>{myCountryNamesObject[key]}</span>
                          <span>{myCountryCodesObject[key]}</span>
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </div>
            <div className="footer" onClick={getLink}>
              <span>Get Link</span>
            </div>
          </Fragment>
        );
      default:
        return (
          <Fragment key="0">
            <div className="contentInvite">
              <label htmlFor="">
                How Do You Want To Recieve The Download Link?
              </label>
              <div className="links">
                <div
                  className={`link ${tempStep === 1 && emailOnly}`}
                  onClick={() => {
                    setTempStep(1);
                    setEmailOnly(true);
                  }}
                >
                  <img src={emailIcn} alt="" />
                  <span>Email</span>
                </div>
                <div
                  className={`link ${tempStep === 2}`}
                  onClick={() => {
                    setTempStep(2);
                  }}
                >
                  <img src={phoneIcn} alt="" />
                  <span>Text</span>
                </div>
                <div
                  className={`link ${tempStep === 1 && !emailOnly}`}
                  onClick={() => {
                    setTempStep(1);
                    setEmailOnly(false);
                  }}
                >
                  <img src={bothIcn} alt="" />
                  <span>Both</span>
                </div>
              </div>
            </div>
            <div className="footer" onClick={() => setStep(tempStep)}>
              <span>Next</span>
            </div>
          </Fragment>
        );
    }
  };

  return (
    <div className="inviteModal">
      <div
        className="overlay"
        onClick={() => {
          try {
            onClose();
          } catch (e) {}
        }}
      />
      <div className="modalInvite">
        <div className="headInvite">
          <img src={appFullLogo} alt="" className="headLogo" />
        </div>
        {res ? (
          <div className="contentInvite">
            <div className="hCongrats">Congratulations</div>
            <p className="message">
              We Have Texted & Emailed You A Download Link For The
              Discussion.app App For {platform}
            </p>
            <div
              className="inputBox res"
              onClick={() => {
                navigator.clipboard
                  .writeText(resLink && resLink[`${platform}_app_link`])
                  .then(() => {
                    setCopied(true);
                    setTimeout(() => {
                      setCopied(false);
                    }, 2000);
                  });
              }}
            >
              <span>
                {copied
                  ? 'Copied To Clipboard'
                  : resLink && resLink[`${platform}_app_link`]}
              </span>
              <FontAwesomeIcon icon={faCopy} />
            </div>
          </div>
        ) : (
          getData()
        )}
      </div>
    </div>
  );
}

export default SendInviteModal;
