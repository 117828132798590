import React from 'react';
import CentralizedTrust from './CentralizedTrust';
import DeCentralizedTrust from './DeCentralizedTrust';

function CreateDefiTrust({ onLogin }) {
  return (
    <div className="createTrustModal">
      <CentralizedTrust onLogin={onLogin} />
      <DeCentralizedTrust />
    </div>
  );
}

export default CreateDefiTrust;
