import axios from 'axios';
import { useQuery } from 'react-query';

const getWithdrawForexResponse = async (
  fromCurrency,
  toCurrency,
  country,
  paymentMethod
) => {
  try {
    const data = await axios.get(
      `https://comms.globalxchange.io/coin/vault/service/payment/stats/get`,
      {
        params: {
          select_type: 'withdraw',
          from_currency: fromCurrency,
          to_currency: toCurrency,
          country,
          paymentMethod,
        },
      }
    );
    return data;
  } catch (err) {
    console.error(err);
  }
};

export const useLoadWithdrawForexResponse = (
  withdrawForexStep,
  fromCurrency,
  toCurrency,
  country,
  paymentMethod
) => {
  const query = useQuery(
    [
      'getWithdrawForexResponse',
      {
        withdrawForexStep,
        fromCurrency,
        toCurrency,
        country,
        paymentMethod,
      },
    ],
    () =>
      getWithdrawForexResponse(fromCurrency, toCurrency, country, paymentMethod)
  );
  return query;
};

const getBankingDataForWithDrawForex = async (email) => {
  try {
    const data = await axios.get(
      `https://comms.globalxchange.io/coin/user/bank/account/get`,
      { params: { email: 'shorupan@inr.group' } }
    );
    return data;
  } catch (err) {
    console.error(err);
  }
};

export const useLoadBankingDataForWithDrawForex = (email, isSecondStep) =>
  useQuery(
    `getBankingDataForWithDrawForex`,
    () => getBankingDataForWithDrawForex(email),
    { enabled: Boolean(isSecondStep) }
  );

export const WithdrawalForexPathId = async (object) => {
  try {
    const response = await axios.get(
      `https://comms.globalxchange.io/coin/vault/service/payment/paths/get`,
      { params: object }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const InitiateWithdrawal = async (object) => {
  try {
    const response = await axios.post(
      `https://comms.globalxchange.io/coin/vault/service/trade/execute`,
      object
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const MailConfirmation = async (object) => {
  try {
    const response = await axios.post(
      `https://comms.globalxchange.io/coin/vault/service/user/send/extenal/verify/mail`,
      object
    );
    return response;
  } catch (error) {
    return error;
  }
};
