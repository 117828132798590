import React from 'react';
import { useEffect } from 'react';
import { useAppContextDetails } from '../../../context/AppContext';
import QRCode from 'qrcode.react';
import { useContext } from 'react';
import { BankContext } from '../../../context/Context';
import { useState } from 'react';
import axios from 'axios';

const ViewAddressStepOne = ({
  coinData,
  setCoinData,
  coinAddress,
  setCoinAddress,
}) => {
  const { selectedCurrency, appCode, setBuySellStep, setActionFlow } =
    useAppContextDetails();
  const { email, token, profileId } = useContext(BankContext);

  const [loading, setLoading] = useState(false);

  const [isCopied, setIsCopied] = useState(false);
  const getSingleCoinData = async () => {
    setLoading(true);

    axios
      .post(`https://comms.globalxchange.io/coin/vault/service/coins/get`, {
        app_code: appCode,
        email: email,
        include_coins: [selectedCurrency?.coinSymbol],
      })
      .then(({ data }) => {
        console.log(data, email, 'kjqwdjwebfkjwkejfbkefk3rbf');
        setCoinData(data.coins_data[0]);
        setLoading(false);
      });
  };

  useEffect(() => {
    console.log('res', 'qwertyuioasdfghjklzxcvbnmsdfghjkrtyui');
    getSingleCoinData();
  }, [selectedCurrency]);

  useEffect(() => {
    console.log(coinData, 'oooooooooooooooooooooooooooooooooooo');
    if (coinData) {
      if (coinData.native_deposit === true && coinData.coin_address !== '') {
        setCoinAddress(coinData.coin_address);
      } else if (
        coinData.native_deposit === true &&
        coinData.coin_address === ''
      ) {
        setCoinAddress(false);
      } else if (coinData.native_deposit === false) {
        setCoinAddress(null);
      }
    }
  }, [coinData, loading]);

  const onCopyClick = () => {
    navigator.clipboard.writeText(coinAddress);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const conditionalSubmitHash = () => {
    if (
      coinData.coinSymbol === 'ETH' ||
      coinData.coinSymbol === 'XRP' ||
      coinData.coinSymbol === 'TRX' ||
      coinData.eth_token
    ) {
      return (
        <div
          className="submitButton"
          onClick={(e) => setActionFlow('Submit Hash')}
          // onClick={(e) =>
          //   navigate('submitHash', {
          //     coinData: coinData,
          //     coinAddress: coinAddress,
          //   })
          // }
        >
          <div style={{ color: '#464B4E', fontWeight: 'bold' }}>
            Submit Hash
          </div>
        </div>
      );
    } else if (coinData.coinSymbol === 'SOL') {
      return (
        <div
          className="submitButton"
          // onClick={(e) =>
          //   navigate('submitHash', {
          //     coinData: coinData,
          //     coinAddress: coinAddress,
          //   })
          // }
        >
          <div
            style={{
              color: '#464B4E',
              fontWeight: 'bold',
              fontSize: 12,
            }}
          >
            Submit Txn Signature
          </div>
        </div>
      );
    } else {
      return (
        <div className="submitButton" style={{ opacity: 0.3 }}>
          <div style={{ color: '#464B4E', fontWeight: 'bold' }}>
            Submit Hash
          </div>
        </div>
      );
    }
  };

  const conditionalMiddleSection = () => {
    if (coinAddress !== '' && coinAddress !== false && coinAddress !== null) {
      return (
        <div>
          <div className="qrContainer">
            <QRCode value={coinAddress} size={220} color={'#C5DAEC'} />
          </div>
          <div className="addressWrapper">
            <div className="addressStyle">
              {coinAddress?.substring(0, 20)}...
            </div>
          </div>
          <div className="doubleButton">
            <div
              className="copyButton"
              onClick={onCopyClick}
              style={{ color: '#464B4E', fontWeight: 'bold' }}
            >
              {isCopied ? 'Address Copied' : 'Copy'}
            </div>
            {conditionalSubmitHash()}
          </div>
        </div>
      );
    } else if (coinAddress === false) {
      return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className="descText">
            Looks Like Its Your First Time Add {selectedCurrency.coinSymbol}.
            Please Request Your Wallet Address By Clicking The Button Below
          </div>
        </div>
      );
    } else if (coinAddress === null) {
      return (
        <div>
          <div className="descText">
            Sorry We Don’t Currency Support Native Deposits For{' '}
            {coinData?.coinName} But We Are Working On It!
          </div>
        </div>
      );
    }
  };

  const handleGenerateAddress = () => {
    if (coinData.coinSymbol === 'BTC') {
      setLoading(true);
      axios
        .post(
          `https://comms.globalxchange.io/coin/vault/service/coin/request/address`,
          {
            email: email,
            app_code: appCode,
          }
        )
        .then(({ data }) => {
          if (data.status) {
            console.log(data, 'BTC Addresssssssssssss');
            getSingleCoinData();
          }
        });
    } else if (coinData.coinSymbol === 'ETH' || coinData.eth_token) {
      setLoading(true);
      axios
        .post(
          `https://comms.globalxchange.io/coin/vault/service/address/create`,
          {
            app_code: appCode,
            profile_id: profileId,
          }
        )
        .then(({ data }) => {
          console.log(data, 'ETH Addresssssssssssss');
          if (data.status) {
            getSingleCoinData();
          }
        });
    } else if (
      coinData.coinSymbol === 'LTC' ||
      coinData.coinSymbol === 'DOGE'
    ) {
      setLoading(true);
      axios
        .post(
          `https://comms.globalxchange.io/coin/vault/service/bit/coins/generate/address`,
          {
            email: email,
            token: token,
            app_code: appCode,
            coin: coinData.coinSymbol,
          }
        )
        .then(({ data }) => {
          if (data.status) {
            console.log(data, 'LTC/DOGE Addresssssssssssss');
            getSingleCoinData();
          }
        });
    } else if (
      coinData.coinSymbol === 'TRX' ||
      coinData.coinSymbol === 'XRP' ||
      coinData.coinSymbol === 'SOL'
    ) {
      setLoading(true);
      axios
        .post(
          `https://comms.globalxchange.io/coin/vault/service/crypto/address/request`,
          {
            email: email,
            app_code: appCode,
            coin: coinData.coinSymbol,
          }
        )
        .then(({ data }) => {
          if (data.status) {
            console.log(data, 'TRX/XRP/SOL Addresssssssssssss');
            getSingleCoinData();
          }
        });
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: window.innerHeight - 90,
      }}
    >
      <div className="breadcrumb">
        <span onClick={(e) => setBuySellStep(null)}>
          {selectedCurrency?.coinName} Actions
        </span>
        &nbsp;&nbsp;{`>`}&nbsp;&nbsp;
        <span style={{ fontWeight: 700 }}>View Address</span>
      </div>
      <div style={{ padding: '0px 22px' }}>
        {!loading ? conditionalMiddleSection() : <div>Loading...</div>}
      </div>
      <div style={{ padding: '0px 22px' }}>
        {coinAddress === false ? (
          <div className="singleButton">
            <div
              onClick={(e) => handleGenerateAddress()}
              className="fullButtonWhite"
            >
              <div style={{ color: '#343C5B', fontWeight: 'bold' }}>
                Generate Address
              </div>
            </div>
          </div>
        ) : null}
        <br />
        <br />
        <br />
        <div className="singleButton">
          <div
            className="fullButton"
            style={{ width: '90%' }}
            onClick={(e) => setActionFlow(null)}
          >
            <div style={{ color: 'white', fontWeight: 'bold' }}>Go Back</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAddressStepOne;
