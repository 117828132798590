import React, { useState, useEffect, useContext } from 'react';
import { useAppContextDetails } from '../../../context/AppContext';
import QRCode from 'qrcode.react';
import axios from 'axios';

import pasteIcon from '../../../static/images/paste.svg';
import { BankContext } from '../../../context/Context';
import OtpInput from 'react-otp-input';

const AddForexStepFive = ({
  addressInput,
  fromCurrency,
  country,
  setPaymentMethod,
  paymentMethod,
  fromAmount,
  setFromAmount,
  banker,
  setConfirmQuote,
  setPathId,
}) => {
  const { selectedCurrency, appCode, setBuySellStep, setActionFlow } =
    useAppContextDetails();
  const { email, token, profileId } = useContext(BankContext);
  const [loading, setLoading] = useState(false);
  const [allBankers, setAllBankers] = useState([]);
  const [selectedBanker, setSlectedBanker] = useState(null);

  const confirmQuoteFunction = () => {
    setLoading(true);
    getPathId();
  };
  const getPathId = () => {
    axios
      .get(
        `https://comms.globalxchange.io/coin/vault/service/payment/paths/get`,
        {
          params: {
            select_type: 'fund',
            to_currency: selectedCurrency?.coinSymbol,
            from_currency: fromCurrency?.coin_metadata?.coinSymbol,
            country: country?.metadata?.name,
            paymentMethod: paymentMethod?._id,
            banker: banker?._id,
          },
        }
      )
      .then(({ data }) => {
        if (data.status) {
          console.log(data.paths[0].path_id, 'kjwefkjwfjgrfkerwferwf');
          setPathId(data.paths[0].path_id);
          if (data.paths[0].path_id) {
            getQuote(data.paths[0].path_id);
          }
        }

        console.log(data, 'all Paths hihihih');
      });
  };

  const getQuote = async (path) => {
    axios
      .post(`https://comms.globalxchange.io/coin/vault/service/trade/execute`, {
        token: token,
        email: email,
        app_code: appCode,
        profile_id: profileId,
        coin_purchased: selectedCurrency?.coinSymbol,
        purchased_from: fromCurrency?.coin_metadata?.coinSymbol,
        from_amount: fromAmount,
        stats: true,
        identifier: `Add ${fromAmount} ${fromCurrency?.coin_metadata.coinSymbol} Via ${paymentMethod?._id}`,
        path_id: path,
      })
      .then(({ data }) => {
        console.log(data, 'kjwljkwefkjwgkefjbcwkebc');
        if (data.status) {
          setLoading(false);
          setConfirmQuote(data);
          setActionFlow('AddForexStepSix');
          // navigate('AddForexStepSix', {
          //   fromAmount: fromAmount,
          //   confirmQuote: data,
          //   fromCurrency: fromCurrency,
          //   paymentMethod: paymentMethod,
          //   path: path,
          // });
        }
      });
  };

  // useEffect(() => {
  //   const foundData = allBankers.find(
  //     (o) => o.displayName === selectedBanker?.displayName
  //   );
  //   if (foundData) {
  //     navigate('AddForexStepFive', {
  //       fromCurrency: fromCurrency,
  //       country: country,
  //       paymentMethod: paymentMethod,
  //       banker: foundData,
  //     });
  //   }
  // }, [selectedBanker]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `https://comms.globalxchange.io/coin/vault/service/payment/stats/get`,
        {
          params: {
            select_type: 'fund',
            to_currency: selectedCurrency.coinSymbol,
            from_currency: fromCurrency.coin_metadata.coinSymbol,
            country: country.metadata.name,
            paymentMethod: paymentMethod._id,
          },
        }
      )
      .then(({ data }) => {
        setAllBankers(data.pathData.banker);
        setLoading(false);
      });
  }, []);
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: window.innerHeight - 100,
      }}
    >
      <div>
        <div className="breadcrumb">
          <span onClick={(e) => setActionFlow(null)}>Select OTCDesk</span>
          &nbsp;&nbsp;{`>`}&nbsp;&nbsp;
          <span style={{ fontWeight: 700 }}>Amount</span>
        </div>
        <div style={{ padding: '0px 22px' }}>
          <div style={{ paddingTop: '40px' }}>
            {/* <div
                style={{
                  fontWeight: 'bold',
                  fontSize: '22px',
                  paddingBottom: '31px',
                }}
              >
                Which Currency Are You Sending ?
              </div> */}
            <div>How Much {fromCurrency?._id} Are You Sending ?</div>
          </div>
        </div>
      </div>
      <div style={{ height: '60vh', padding: '0px 22px' }}>
        <div className="inputStyle">
          <div style={{ width: '60%' }}>
            <input
              type="number"
              placeholder="0.0000"
              value={fromAmount}
              onChange={(e) => setFromAmount(e.target.value)}
            />
          </div>
          <div style={{ width: '40%' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={fromCurrency?.coin_metadata?.coinImage}
                alt=""
                style={{ width: '20px', height: '20px' }}
              />
              <span
                className="inputButton"
                style={{ padding: '0px 10px', border: 'none' }}
              >
                {fromCurrency?.coin_metadata?.coinSymbol}
              </span>
              <span>{`>`}</span>
            </div>
          </div>
        </div>
      </div>
      <div style={{ padding: '0px 22px' }}>
        <div className="doubleButton">
          <div
            className="submitButton"
            style={{ background: 'white' }}
            onClick={(e) => setActionFlow('AddForexStepFour')}
          >
            <div style={{ fontWeight: 'bold' }}>Back</div>
          </div>
          <div
            className="submitButton"
            style={{ background: '#464B4E' }}
            onClick={confirmQuoteFunction}
          >
            <div style={{ color: 'white', fontWeight: 'bold' }}>
              {loading ? 'Loading...' : 'Next'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddForexStepFive;
