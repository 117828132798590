import axios from 'axios';
import { useQuery } from 'react-query';

export const getAffiliateAppData = async () => {
  try {
    const data = await axios.get(
      'https://comms.globalxchange.io/gxb/apps/mobile/app/links/logs/get',
      {
        params: {
          app_code: 'broker_app',
        },
      }
    );
    return data;
  } catch (err) {
    console.error(err);
  }
};

export const useLoadAffiliateAppData = () => {
  const query = useQuery('loadAffiliateAppData', getAffiliateAppData);
  return query;
};
