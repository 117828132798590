import React, { useContext, useEffect, useState } from 'react';
import { useAppContextDetails } from '../../../context/AppContext';
import { useCoinContextData } from '../../../context/CoinContext';
import { BankContext } from '../../../context/Context';
import { EachCardForList } from '../../EachCardForList';
import { LoadingAnimation } from '../../LoadingAnimation';
import { ForexAddSkeletons } from '../AddForexAction/ForexAddSkeletons';
import {
  useLoadBankingDataForWithDrawForex,
  useLoadWithdrawForexResponse,
} from './api';
import { EmailVerification } from './EmailVerification';
import { EnterAmount } from './EnterAmount';
import { WithdrawForexConfirmation } from './WithdrawForexConfirmation';
import { WithdrawForexSuccess } from './WithdrawForexSuccess';

export const WithdrawActionForex = ({ header, placeholder }) => {
  const { email } = useContext(BankContext);
  const { appLogo } = useAppContextDetails();
  const {
    selectedCoin,
    withdrawForexStep,
    withdrawForexCurrency,
    withdrawForexCountry,
    withdrawForexPaymentMethod,
    setWithdrawForexStep,
    setWithdrawForexCurrency,
    setWithdrawForexCountry,
    setWithdrawForexPaymentMethod,
    setWithdrawForexAccountId,
    setWithdrawForexOtcDesk,
    setWithdrawForexAccountName,
    allApiData,
    setAllApiData,
  } = useCoinContextData();

  const {
    data: withdrawForexResponse,
    isLoading: isWithdrawForexResponseLoading,
  } = useLoadWithdrawForexResponse(
    withdrawForexStep,
    selectedCoin.coinSymbol,
    withdrawForexCurrency,
    withdrawForexCountry,
    withdrawForexPaymentMethod
  );

  const { data: bankingDataResponse, isLoading: isBankDetailsLoading } =
    useLoadBankingDataForWithDrawForex(email, withdrawForexStep === '2');

  const isLoading = isBankDetailsLoading || isWithdrawForexResponseLoading;
  const pathData = withdrawForexResponse?.data?.pathData;
  const toCurrencyList = pathData?.to_currency;
  const bankAccountList = bankingDataResponse?.data?.bankAccounts_info;
  const bankerList = pathData?.banker;

  const [filteredCurrencyList, setFilteredCurrencyList] =
    useState(toCurrencyList);
  const [filteredBankAccountList, setFilteredBankAccountList] =
    useState(bankAccountList);
  const [filteredOtcDeskList, setFilteredOtcDeskList] = useState(bankerList);

  useEffect(() => {
    if (withdrawForexStep === '1') setFilteredCurrencyList(toCurrencyList);
    else if (withdrawForexStep === '2')
      setFilteredBankAccountList(bankAccountList);
    else if (withdrawForexStep === '3') setFilteredOtcDeskList(bankerList);
  }, [toCurrencyList, bankAccountList, bankerList, withdrawForexStep]);

  const debounceFunc = (value = '') => {
    if (withdrawForexStep === '1' && toCurrencyList) {
      let searchValues = toCurrencyList?.filter((data) => {
        if (data.coin_metadata) {
          let searchdata = data.coin_metadata?.coinName.toLowerCase();
          return searchdata.includes(value.toLowerCase());
        }
      });
      setFilteredCurrencyList(searchValues);
    } else if (withdrawForexStep === '2' && bankAccountList) {
      let searchValues = bankAccountList?.filter((data) => {
        const searchableValue = [
          data?.account_name,
          data?.instituteData?.institute_name,
          data?.countryData?.name,
          data?.paymentMethod_data?.name,
          data?.paymentMethod_data?.code,
        ];
        return searchableValue.some((item) =>
          item?.toLowerCase()?.includes(value?.toLowerCase())
        );
      });
      setFilteredBankAccountList(searchValues);
    } else if (withdrawForexStep == '3' && bankerList) {
      let searchValues = bankerList?.filter((data) => {
        if (data) {
          let searchdata = data.displayName.toLowerCase();
          return searchdata.includes(value.toLowerCase());
        }
      });
      setFilteredOtcDeskList(searchValues);
    }
  };

  const isListComponent =
    withdrawForexStep === '1' ||
    withdrawForexStep === '2' ||
    withdrawForexStep === '3';

  return (
    <>
      {isListComponent && (
        <div className="menu2" style={{ overflowY: 'hidden' }}>
          <p className="sending-txt">{header}</p>
          <div
            className="userAppscard"
            onMouseDown={(e) => e.stopPropagation()}
          >
            <input
              className="appsearch"
              key="appsearch"
              type="search"
              placeholder={placeholder}
              onChange={(e) => debounceFunc(e.target.value)}
            />
            <>
              <div className="apps-card">
                {isLoading && <ForexAddSkeletons />}

                {!isLoading &&
                  withdrawForexStep == '1' &&
                  filteredCurrencyList?.map((singledata, i) => {
                    return (
                      <EachCardForList
                        key={i}
                        onClick={() => {
                          setWithdrawForexCurrency(
                            singledata?.coin_metadata?.coinSymbol
                          );
                          setWithdrawForexStep('2');
                        }}
                        icon={singledata?.coin_metadata?.coinImage}
                        label={singledata?.coin_metadata?.coinName}
                      />
                    );
                  })}

                {!isLoading &&
                  withdrawForexStep == '2' &&
                  filteredBankAccountList?.map((singledata, i) => {
                    return (
                      <EachCardForList
                        key={i}
                        onClick={() => {
                          setWithdrawForexCountry(
                            singledata?.countryData?.name
                          );
                          setWithdrawForexPaymentMethod(
                            singledata?.paymentMethod_data?.code
                          );
                          setWithdrawForexAccountId(
                            singledata?.bank_account_id
                          );
                          setWithdrawForexAccountName(singledata?.account_name);
                          setAllApiData({
                            ...allApiData,
                            withdrawForexPaymentMethodName:
                              singledata?.paymentMethod_data?.name,
                            withdrawForexAccountName: singledata?.account_name,
                            withdrawForexInstitueName:
                              singledata?.instituteData?.institute_name,
                            withdrawForexInstitueIcon:
                              singledata?.instituteData?.profile_image,
                            withdrawForexPaymentIcon:
                              singledata?.paymentMethod_data?.icon,
                          });
                          setWithdrawForexStep('3');
                        }}
                        icon={singledata?.instituteData?.profile_image}
                        label={
                          <div className="d-flex flex-column">
                            <h4>{singledata?.instituteData?.institute_name}</h4>
                            <div className="d-flex flex-column ml-n4 mt-2">
                              <div style={{ fontSize: '14px' }}>
                                Account Name
                              </div>
                              <div
                                style={{
                                  fontSize: '20px',
                                  fontWeight: '600',
                                }}
                              >
                                {singledata?.account_name}
                              </div>
                            </div>
                            <div className="d-flex flex-row justify-content-end mt-4 ml-n4 w-100">
                              <img
                                className="app-icon"
                                src={singledata?.paymentMethod_data?.icon}
                                alt="appIcon"
                              />
                              <div className="ml-1">
                                {singledata?.paymentMethod_data?.name}
                              </div>
                            </div>
                          </div>
                        }
                      />
                    );
                  })}

                {!isLoading &&
                  withdrawForexStep == '3' &&
                  filteredOtcDeskList?.map((singledata, i) => {
                    return (
                      <EachCardForList
                        key={i}
                        onClick={() => {
                          setWithdrawForexOtcDesk(singledata._id);
                          setWithdrawForexStep('4');
                        }}
                        icon={singledata?.icons?.image1}
                        label={singledata?.displayName}
                      />
                    );
                  })}
              </div>
            </>
          </div>
        </div>
      )}

      {!isListComponent && (
        <>
          <div
            className="addForexAmountWrapper pb-3 mt-3"
            style={{
              opacity: isLoading ? 0.25 : 1,
              overflow: withdrawForexStep === '5' ? 'hidden' : 'scroll',
            }}
          >
            {withdrawForexStep === '4' && <EnterAmount />}
            {withdrawForexStep === '5' && <WithdrawForexConfirmation />}
            {withdrawForexStep === '6' && <EmailVerification />}
            {withdrawForexStep === '7' && <WithdrawForexSuccess />}
          </div>
          {isLoading && (
            <div className="coin-address-loading">
              <LoadingAnimation icon={appLogo} width={200} />
            </div>
          )}
        </>
      )}
    </>
  );
};
