import React from 'react';
import './tellers.scss';
import Layout from '../../Layout/Layout';
import logo from '../../static/images/logos/tellersLogo.svg';

const Tellers = () => {
  return (
    <Layout active="tellers" className="tellers" hideFooter>
      <div style={{ width: '100%', height: '100%', position: 'relative' }}>
        <div className="tellers-content">
          <img src={logo} alt="Tellers" />
          <div className="tellers-txt">
            Make Profit For Sending Money Back Home
          </div>
        </div>
        <div className="tellers-footer">Coming Soon To AiProWallet</div>
      </div>
    </Layout>
  );
};

export default Tellers;
