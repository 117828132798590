import React, { useState, useEffect, useContext } from 'react';
import QRCode from 'qrcode.react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-regular-svg-icons';

import iceLogo from '../../static/images/logo.svg';
import blockCheckLogo from '../../static/images/actionSidebar/blockCheckLogo.svg';
import icedLogo from '../../static/images/actionSidebar/icedLogo.svg';
import moneyMarketsLogo from '../../static/images/actionSidebar/moneyMarketsLogo.svg';
import vaultLogo from '../../static/images/actionSidebar/vaultLogo.svg';
import addCoin from '../../static/images/actionSidebar/addCoin.svg';
import exchangeCoin from '../../static/images/actionSidebar/exchangeCoin.svg';
import qrAddress from '../../static/images/actionSidebar/qrAddress.svg';
import requestCoin from '../../static/images/actionSidebar/requestCoin.svg';
import sendCoin from '../../static/images/actionSidebar/sendCoin.svg';
import poweredByBlockCheck from '../../static/images/actionSidebar/poweredByBlockCheck.svg';
import Axios from 'axios';
import { BankContext } from '../../context/Context';
import { Link } from 'react-router-dom';
import { useAppContextDetails } from '../../context/AppContext';

function DepositWithdrawActionPanel({
  setOpenModal,
  setDepositMenuOpen,
  setisDeposit,
  vaultSelected: coinSelected,
}) {
  const { email, tostShowOn } = useContext(BankContext);
  const [step, setStep] = useState('home');
  const [stepIn, setStepIn] = useState(0);
  function getSidebar() {
    switch (step) {
      case 'home':
        return (
          <div className="sidebar">
            <div className="headTitle">Action Panel</div>
            <div className="menuItm" onClick={() => setStep('addCoin')}>
              <img src={addCoin} alt="" className="icon" />
              <div className="name">
                Add {coinSelected && coinSelected.coinName}
              </div>
            </div>
            <div className="menuItm" onClick={() => setStep('sendCoin')}>
              <img src={sendCoin} alt="" className="icon" />
              <div className="name">
                Send {coinSelected && coinSelected.coinName}
              </div>
            </div>
            <Link
              to={`/Terminal/${coinSelected && coinSelected.coinSymbol}`}
              className="menuItm"
            >
              <img src={exchangeCoin} alt="" className="icon" />
              <div className="name">
                Exchange {coinSelected && coinSelected.coinName}
              </div>
            </Link>
          </div>
        );
      case 'addCoin':
        return (
          <div className="sidebar">
            <div className="headTitle">
              Add {coinSelected && coinSelected.coinName}
            </div>
            <div className="menuItm" onClick={() => setStep('blockCheck')}>
              <img src={blockCheckLogo} alt="" className="logo" />
              <div className="menuToolTip">
                Send Funds From An External{' '}
                {coinSelected && coinSelected.coinName} Wallet
              </div>
            </div>
            <div
              className="menuItm"
              onClick={() => {
                setDepositMenuOpen(false);
                setOpenModal(true);
                setisDeposit(true);
              }}
            >
              <img src={vaultLogo} alt="" className="logo" />
              <div className="menuToolTip">
                Send Funds From Another One Of Your GX Apps
              </div>
            </div>
            <div className="menuItm disable">
              <img src={moneyMarketsLogo} alt="" className="logo" />
              <div className="menuToolTip">
                Add Funds From Your {coinSelected && coinSelected.coinSymbol}{' '}
                Liquid Earnings Vault
              </div>
            </div>
            <div className="menuItm disable">
              <img src={icedLogo} alt="" className="logo" />
              <div className="menuToolTip">
                Add Funds From Your {coinSelected && coinSelected.coinSymbol}{' '}
                Bonds Earnings Vault
              </div>
            </div>
            <div className="goBack" onClick={() => setStep('home')}>
              Go Back
            </div>
          </div>
        );
      case 'sendCoin':
        return (
          <div className="sidebar">
            <div className="headTitle">
              Add {coinSelected && coinSelected.coinName}
            </div>
            <div className="menuItm disable">
              <img src={blockCheckLogo} alt="" className="logo" />
              <div className="menuToolTip">
                Send Funds To An External{' '}
                {coinSelected && coinSelected.coinName} Wallet
              </div>
            </div>
            <div
              className="menuItm"
              onClick={() => {
                setDepositMenuOpen(false);
                setOpenModal(true);
                setisDeposit(false);
              }}
            >
              <img src={vaultLogo} alt="" className="logo" />{' '}
              <div className="menuToolTip">
                Send Funds To Another One Of Your GX Apps
              </div>
            </div>
            <div className="goBack" onClick={() => setStep('home')}>
              Go Back
            </div>
          </div>
        );
      case 'blockCheck':
        return (
          <div className="sidebar">
            <div className="headTitle">
              <img src={blockCheckLogo} alt="" className="logo" />
            </div>
            <div className="menuItm">
              <img src={requestCoin} alt="" className="icon" />
              <div className="name">
                Request {coinSelected && coinSelected.coinName}
              </div>
            </div>
            <div
              className={`menuItm ${
                coinSelected && coinSelected.native_deposit ? '' : ' disable'
              }`}
              onClick={() =>
                coinSelected &&
                coinSelected.native_deposit &&
                setStep('coinAddress')
              }
            >
              <img src={qrAddress} alt="" className="icon" />
              <div className="name">
                {coinSelected && coinSelected.coinName} Address
              </div>
            </div>
            <div className="goBack" onClick={() => setStep('addCoin')}>
              Go Back
            </div>
          </div>
        );
      case 'coinAddress':
        return (
          <div className="sidebar">
            <div className="headTitle">
              <img src={blockCheckLogo} alt="" className="logo" />
            </div>
            <div className="menuItm">
              <img src={iceLogo} alt="" className="icon" />
              <div className="name">
                Request {coinSelected && coinSelected.coinName}
              </div>
            </div>
            <div
              className="menuItm true"
              onClick={() => setStep('coinAddress')}
            >
              <img src={iceLogo} alt="" className="icon" />
              <div className="name">
                {coinSelected && coinSelected.coinName} Address
              </div>
            </div>
            <div className="goBack" onClick={() => setStep('addCoin')}>
              Go Back
            </div>
          </div>
        );

      default:
        break;
    }
  }

  const [coinAddress, setCoinAddress] = useState('');
  const { appCode } = useAppContextDetails();

  async function getAddress() {
    if (coinSelected && coinSelected.coinSymbol === 'BTC') {
      const { data } = await Axios.post(
        'https://comms.globalxchange.io/coin/vault/service/coin/request/address',
        {
          email,
          app_code: appCode,
        }
      );
      if (data.address) {
        setCoinAddress(data.address);
      } else {
        tostShowOn('Something Went Wrong Getting Coin Address');
        setCoinAddress('');
      }
    } else {
      setCoinAddress(coinSelected.coin_address);
    }
    setStepIn(1);
  }

  function getContent() {
    switch (step) {
      case 'coinAddress':
        switch (stepIn) {
          case 0:
            return (
              <div className="modalCoinAddress">
                <div className="headNameLogo">
                  <img
                    src={coinSelected && coinSelected.coinImage}
                    alt=" "
                    className="icon"
                  />
                  <span>{coinSelected && coinSelected.coinName}</span>
                </div>
                <img src={poweredByBlockCheck} alt="" className="poweredBy" />
                <p>
                  Your {coinSelected && coinSelected.coinName} Address Can Be
                  Used In Many Ways. Which Action Do You Wish To Perform
                </p>
                <div
                  className="btnShow"
                  onClick={() => {
                    getAddress();
                  }}
                >
                  Show
                </div>
              </div>
            );
          case 1:
            return (
              <div className="modalCoinAddress true">
                <div className="headNameLogo">
                  <img
                    src={coinSelected && coinSelected.coinImage}
                    alt=" "
                    className="icon"
                  />
                  <span>{coinSelected && coinSelected.coinName}</span>
                </div>
                <img src={poweredByBlockCheck} alt="" className="poweredBy" />
                <QRCode
                  className="qr-svg"
                  value={
                    (coinSelected.coinSymbol === 'BTC' && coinAddress) ||
                    (coinSelected &&
                      (coinSelected.coin_address ||
                        coinSelected.erc20Address)) ||
                    ''
                  }
                  renderAs="svg"
                />
                <CopyToClipboard
                  text={
                    (coinSelected.coinSymbol === 'BTC' && coinAddress) ||
                    (coinSelected &&
                      (coinSelected.coin_address || coinSelected.erc20Address))
                  }
                  onCopy={() => {
                    tostShowOn('Copied To Clipboard');
                  }}
                >
                  <span className="wallet-addr">
                    <span>
                      {(coinSelected.coinSymbol === 'BTC' && coinAddress) ||
                        (coinSelected &&
                          (coinSelected.coin_address ||
                            coinSelected.erc20Address))}
                    </span>
                    <FontAwesomeIcon icon={faCopy}></FontAwesomeIcon>
                  </span>
                </CopyToClipboard>
              </div>
            );
          default:
            break;
        }
    }
  }

  useEffect(() => {
    setStepIn(0);
  }, [step]);

  return (
    <div className="actionPanelModal">
      {getSidebar()}
      <div className="contentWrapper">
        <div
          className="overLayClose"
          onClick={() => setDepositMenuOpen(false)}
        />
        {getContent()}
      </div>
      <div
        className="fabBtn true"
        onClick={() => setDepositMenuOpen(false)}
      ></div>
    </div>
  );
}

export default DepositWithdrawActionPanel;
