import Axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import AnalyticsCurrency from './AnalyticsCurrency';
import CurrencyControll from './CurrencyControll';
import CurrencyTable from './CurrencyTable';

function Currencies() {
  const { coinSymbol } = useParams();
  const [assetTab, setAssetTab] = useState('Crypto');
  const [fiatList, setFiatList] = useState([]);
  const [stableList, setStableList] = useState([]);
  const [cryptoList, setCryptoList] = useState([]);
  const [coinToDetail, setCoinToDetail] = useState();

  useEffect(() => {
    Axios.get(
      'https://comms.globalxchange.io/coin/vault/get/all/coins?crypto=true&stable_coin=true'
    ).then(({ data }) => {
      if (data.status) setStableList(data.coins);
    });
    Axios.get(
      'https://comms.globalxchange.io/coin/vault/get/all/coins?fiat=true'
    ).then(({ data }) => {
      if (data.status) setFiatList(data.coins);
    });
    Axios.get(
      'https://comms.globalxchange.io/coin/vault/get/all/coins?crypto=true'
    ).then(({ data }) => {
      if (data.status) setCryptoList(data.coins);
    });
  }, []);

  function getCoins(tab) {
    switch (tab) {
      case 'stableCoin':
        return stableList;
      case 'Fiat':
        return fiatList;
      default:
        return cryptoList;
    }
  }
  function getTitle(tab) {
    switch (tab) {
      case 'stableCoin':
        return 'stableList';
      case 'Fiat':
        return 'fiatList';
      default:
        return 'cryptoList';
    }
  }
  useEffect(() => {
    if (coinToDetail?.coinSymbol !== coinSymbol) {
      if (
        stableList.some((coin) => {
          if (coin.coinSymbol === coinSymbol?.toUpperCase()) {
            setCoinToDetail(coin);
            return true;
          } else return false;
        })
      ) {
        setAssetTab('stableCoin');
      } else if (
        fiatList.some((coin) => {
          if (coin.coinSymbol === coinSymbol?.toUpperCase()) {
            setCoinToDetail(coin);
            return true;
          } else return false;
        })
      ) {
        setAssetTab('Fiat');
      } else if (
        cryptoList.some((coin) => {
          if (coin.coinSymbol === coinSymbol?.toUpperCase()) {
            setCoinToDetail(coin);
            return true;
          } else return false;
        })
      ) {
        setAssetTab('Crypto');
      } else if (!coinSymbol) {
        setCoinToDetail(null);
      }
    }
  }, [coinSymbol, coinToDetail, cryptoList, fiatList, stableList]);

  return (
    <div className="earn-intrest">
      <CurrencyControll
        tabSelected={assetTab}
        setTabSelected={setAssetTab}
        countCrypto={cryptoList.length}
        countStable={stableList.length}
        countFiat={fiatList.length}
      />
      <CurrencyTable
        coinList={getCoins(assetTab)}
        searchTitle={getTitle(assetTab)}
        coinToDetail={coinToDetail}
        setCoinToDetail={setCoinToDetail}
      />
      {coinToDetail ? <AnalyticsCurrency /> : ''}
    </div>
  );
}

export default Currencies;
