import React from 'react';
import './pageHeader.scss';
import { useAppContextDetails } from '../../../context/AppContext';

import { useAppDetail, useShareTokensDetail } from '../../../queryHooks';

import menuIcon from '../../../static/images/mobile/menu.svg';

const PageHeader = ({ setOpenSidebar }) => {
  const { appFullLogo, appName } = useAppContextDetails();

  return (
    <div className="pageHeader">
      <img
        src={menuIcon}
        alt=""
        style={{ height: '100%', width: '25px' }}
        onClick={(e) => setOpenSidebar(true)}
      />
      <img src={appFullLogo} alt="" style={{ width: '70%', height: '100%' }} />
      <div>&nbsp;</div>
    </div>
  );
};

export default PageHeader;
