import Axios from 'axios';
import React, { useState } from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import OtpInput from 'react-otp-input';
import { APP_CODE } from '../../config/appConfig';
import { BankContext } from '../../context/Context';
import { getAppByCode } from '../../queryHooks';
import LoadingAnim from '../LoadingAnim/LoadingAnim';
import classNames from './onhold.module.scss';

export const otpRegex = new RegExp(/^\d*$/);

const PinVerify = ({ onSucces, onClose, local, setOnHold }) => {
  const { email, token, tostShowOn, setUserSelectedOnHold } =
    useContext(BankContext);
  const [pin, setPin] = useState('');
  const [loading, setLoading] = useState(false);
  const [appLogo, setAppLogo] = useState('');

  useEffect(() => {
    getAppByCode(APP_CODE).then((response) => {
      const logo = response?.app_icon;
      setAppLogo(logo);
    });
  }, []);

  const pinValidator = (pinStr) => {
    if (otpRegex.test(pinStr)) setPin(pinStr);
  };

  function verifyPin() {
    if (pin.length === 4) {
      setUserSelectedOnHold('');
      if (local) {
        if (pin === local) {
          try {
            onSucces();
          } catch (error) {}
        } else {
          tostShowOn('Incorrect Pin');
          setPin('');
        }
      } else {
        setLoading(true);
        Axios.post(
          'https://comms.globalxchange.io/gxb/apps/user/validate/group/pin',
          {
            email: email,
            token: token,
            pin: pin,
            group_id: '66me7fdkhxsbtur',
          }
        )
          .then(({ data }) => {
            if (data.status) {
              try {
                onSucces();
              } catch (error) {}
            } else {
              tostShowOn(data.message || 'Incorrect Pin');
              setPin('');
            }
          })
          .catch((err) => {
            tostShowOn(err.message || 'Something Went Wrong');
            setPin('');
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }
  return (
    <div className={classNames.enterPinVerify}>
      <div>
        <div className={classNames.onholdTitle}>Enter Pin</div>
        <OtpInput
          containerStyle="otp-input-wrapper-onhold"
          value={pin}
          onChange={(otp) => pinValidator(otp)}
          numInputs={4}
          separator={<span> </span>}
          inputStyle="otp-input"
          className={classNames.otpInputWrapperOnhold}
        />
        {loading && (
          <div className="loadingAnim">
            <LoadingAnim />
          </div>
        )}
      </div>

      <div className={classNames.btnsContainer}>
        <div className={classNames.greenBtn} onClick={verifyPin}>
          Submit Pin
        </div>
        <div className={classNames.redBtn} onClick={() => setOnHold('')}>
          Close
        </div>
      </div>
    </div>
  );
};

export default PinVerify;
