import React from 'react';
import classNames from './bplanner.module.scss';
import Layout from '../../Layout/Layout';
import coachesIcon from '../../static/images/sidebar-icons/coaches.svg';
import travelIcon from '../../static/images/reactSidebar/travel.svg';
import useWindowDimensions from '../../utils/WindowSize';
import NewMobileLayout from '../../Layout/MobilePages/NewMobileLayout';

function TravelPage() {
  const { width, height } = useWindowDimensions();
  return (
    <>
      {width > 768 || width > height ? (
        <Layout active="react" className={classNames.bplannerPage} hideFooter>
          <div className={classNames.headingDiv}>
            <img
              src={travelIcon}
              alt="travelIcon"
              style={{ display: 'flex', width: '5vh', height: '5vh' }}
            />
            <div className={classNames.feature}>Travel</div>
          </div>
          <div className={classNames.comingText}>Coming Soon!</div>
          <div className={classNames.workingText}>
            <span>We are currently working on this feature</span>
            <span>and will launch soon!</span>
          </div>
          <div className={classNames.bottomLine}></div>
        </Layout>
      ) : (
        <NewMobileLayout />
      )}
    </>
  );
}

export default TravelPage;
