import axios from 'axios';
import { useQuery } from 'react-query';

const getBankingData = async (email) => {
  try {
    const data = await axios.get(
      `https://comms.globalxchange.io/coin/user/bank/account/get`,
      { params: { email } }
    );
    return data;
  } catch (err) {
    console.error(err);
  }
};

export const useLoadBankingData = (
  email,
  updateBankAccounts,
  setUpdateBankAccounts
) => {
  const query = useQuery(
    ['getBankingData', { email, updateBankAccounts }],
    () => getBankingData(email),
    { onSuccess: () => setUpdateBankAccounts(false) }
  );
  return query;
};
