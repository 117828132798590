import React from 'react'

const Time = () => {
  return (
    <div>
      <p>Select Time</p>
      <div className="text-size">For Your Bond Purchase</div>
      <input
        type="text"
        className="asset-select-input"
        placeholder="Search Days.."
      />
    </div>
  )
}

export default Time