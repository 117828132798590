import React from 'react';
import { handleDecimal } from '../../../utils/FunctionTools';
import { useCoinContextData } from '../../../context/CoinContext';

export const EnterAmount = () => {
  const {
    selectedCoin,
    withdrawForexAccountName,
    withdrawForexEnteredAmount,
    setWithdrawForexEnteredAmount,
  } = useCoinContextData();

  const handlePercCal = (value) => {
    let balance = selectedCoin.coinValue;
    let final = (balance * value) / 100;
    final = handleDecimal(final);
    setWithdrawForexEnteredAmount(final);
  };

  return (
    <div
      style={{
        width: '90%',
      }}
    >
      <p className="step-head">Step 2: Enter Amount</p>
      <p className="step-para">{`Enter The Amount Of ${selectedCoin.coinName} You Wish To Send To ${withdrawForexAccountName}`}</p>
      <div className="step2-div">
        <div className="from-details">
          <p className="from-text">From</p>
          <p className="from-text">
            Balance: {handleDecimal(selectedCoin.coinValue)}
          </p>
        </div>
        <div className="transaction">
          <input
            className="transc"
            type="text"
            placeholder="0.00"
            value={withdrawForexEnteredAmount}
            onInput={(e) => {
              setWithdrawForexEnteredAmount(e.target.value);
            }}
          />
          <div className="coin" key="coin">
            <img className="coinimgsmall" src={selectedCoin.coinImage} alt="" />
            <p className="coinsymbolsmall">{selectedCoin.coinSymbol}</p>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <p className="withdraw-perc" onClick={() => handlePercCal(25)}>
            25%
          </p>
          <p className="withdraw-perc" onClick={() => handlePercCal(50)}>
            50%
          </p>
          <p className="withdraw-perc" onClick={() => handlePercCal(100)}>
            100%
          </p>
        </div>
      </div>
    </div>
  );
};
