import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import Fade from 'react-reveal/Fade';
import OtpInput from 'react-otp-input';
import { BankContext } from '../../context/Context';
import { useAppContextDetails } from '../../context/AppContext';

const capRegex = new RegExp(/^.*[A-Z].*/);
const numRegex = new RegExp(/^.*[0-9].*/);
const speRegex = new RegExp(/^.*[!@#$%^&*()+=].*/);

function ResetModal({ emailid, setEmailId, loginvalidate }) {
  const { tostShowOn } = useContext(BankContext);
  const [loading, setLoading] = useState(false);
  const [inValidMail, setInValidMail] = useState(false);
  const [step, setStep] = useState('');

  //Form Values
  const [isValid, setIsValid] = useState({});
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [pin, setPin] = useState('');
  const { appCode } = useAppContextDetails();

  useEffect(() => {
    setIsValid({
      password:
        capRegex.test(password) &&
        numRegex.test(password) &&
        speRegex.test(password) &&
        password.length >= 8,
      confirmPassword: confirmPassword === password,
      pin: String(pin).length === 6,
    });
  }, [password, confirmPassword, pin]);

  function sendPin() {
    setLoading(true);
    Axios.post(
      'https://gxauth.apimachine.com/gx/user/password/forgot/request',
      {
        email: emailid,
        app_code: appCode,
      }
    )
      .then(({ data }) => {
        tostShowOn(data.message);
        if (data.status) {
          setStep('enterPin');
        } else {
          setInValidMail(true);
        }
      })
      .finally(() => setLoading(false));
  }
  const [invalidPin, setInvalidPin] = useState(false);
  function resetPassword() {
    setLoading(true);
    Axios.post(
      'https://gxauth.apimachine.com/gx/user/password/forgot/confirm',
      {
        email: emailid,
        code: pin,
        newPassword: password,
      }
    )
      .then(({ data }) => {
        tostShowOn(data.message);
        if (data.status) {
          setStep('succes');
        } else {
          setInvalidPin(true);
          setStep('enterPin');
        }
      })
      .finally(() => setLoading(false));
  }

  const [eye, setEye] = useState(false);

  function getStep() {
    switch (step) {
      case 'enterPin':
        return (
          <div className="login-form mx-5">
            <h5>
              {invalidPin
                ? 'Incorrect Code. Please Enter It Again'
                : 'Enter The Verification Code'}
            </h5>
            <OtpInput
              containerStyle="input-wrapper-2fa"
              value={pin}
              onChange={setPin}
              numInputs={6}
              separator={<span> </span>}
              inputStyle="input-2fa"
              shouldAutoFocus
            />
            <div className="group">
              <button
                type="submit"
                disabled={loading}
                className="btn btn-darkblue mb-5"
                onClick={() => {
                  if (isValid.pin) {
                    setStep('enterPassWord');
                  }
                }}
              >
                {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Enter'}
              </button>
            </div>
          </div>
        );
      case 'enterPassWord':
        return (
          <div className="login-form mx-5">
            <h5>Enter New Password</h5>
            <div className={`groupM ${isValid.password}`}>
              <input
                type={eye ? 'password' : 'text'}
                className="input password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setStep('enterCPassWord');
                  }
                }}
              />
              <FontAwesomeIcon
                icon={eye ? faEye : faEyeSlash}
                className="eye"
                onClick={() => setEye(!eye)}
              />
            </div>
            <div className="group">
              <button
                type="submit"
                disabled={loading}
                className="btn btn-darkblue mb-5"
                onClick={() => {
                  if (isValid.password) {
                    setStep('enterCPassWord');
                  }
                }}
              >
                {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Enter'}
              </button>
            </div>
          </div>
        );
      case 'enterCPassWord':
        return (
          <div className="login-form mx-5">
            <h5>Enter Confirm Password</h5>
            <div className={`groupM ${isValid.confirmPassword}`}>
              <input
                type={eye ? 'password' : 'text'}
                className="input password"
                placeholder="Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    resetPassword();
                  }
                }}
              />
              <FontAwesomeIcon
                icon={eye ? faEye : faEyeSlash}
                className="eye"
                onClick={() => setEye(!eye)}
              />
            </div>
            <div className="group">
              <button
                type="submit"
                disabled={loading}
                className="btn btn-darkblue mb-5"
                onClick={() => resetPassword()}
              >
                {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Enter'}
              </button>
            </div>
          </div>
        );
      case 'succes':
        setTimeout(() => {
          loginvalidate(password, emailid);
        }, 2000);
        return (
          <div className="success mx-5">
            <div className="succesTitle">
              You Have Successfully Reset Your Password
            </div>
            <div className="succesBottom">
              You Will Automatically Be Logged In
            </div>
          </div>
        );
      default:
        return (
          <div className="login-form mx-5">
            <Fade bottom>
              <div className="group">
                <input
                  type="text"
                  name="email"
                  value={emailid}
                  onChange={(e) => setEmailId(e.target.value)}
                  required="required"
                  onFocus={() => setInValidMail(false)}
                />
                <span className="highlight" />
                <span className="bar" />
                <label>
                  {inValidMail
                    ? 'We Cannot FInd That Email. Please Try Again'
                    : 'Email'}
                </label>
              </div>
            </Fade>
            <Fade bottom>
              <div className="group">
                <button
                  type="submit"
                  disabled={loading}
                  className="btn btn-darkblue mb-5"
                  onClick={() => sendPin()}
                >
                  {loading ? (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  ) : (
                    'Enter'
                  )}
                </button>
              </div>
            </Fade>
          </div>
        );
    }
  }
  return <div className="card login-enter">{getStep()}</div>;
}

export default ResetModal;
