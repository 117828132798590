import React, { useState, useEffect, useContext } from 'react';
import { useAppContextDetails } from '../../../context/AppContext';
import QRCode from 'qrcode.react';
import axios from 'axios';

import pasteIcon from '../../../static/images/paste.svg';
import { BankContext } from '../../../context/Context';
import OtpInput from 'react-otp-input';

const WithdrawCryptoStepFour = ({
  coinData,
  coinAddress,
  hashSuccessData,
  setHashSuccessData,
  addressInput,
  setAddressInput,
  pathId,
  withdrawalDetails,
}) => {
  const { selectedCurrency, appCode, setBuySellStep, setActionFlow } =
    useAppContextDetails();
  const { email, token, profileId } = useContext(BankContext);
  const [loading, setLoading] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [otp, setOtp] = useState('');
  const [werr, setWerr] = useState(null);

  const onPasteClick = async () => {
    const copiedText = await navigator.clipboard.readText();
    setAddressInput(copiedText.trim());
  };

  useEffect(() => {
    if (otp.length === 6) {
      initiateWithdrawal();
    }
  }, [otp]);

  const initiateWithdrawal = async () => {
    // const token = await AsyncStorageHelper.getAppToken();
    // const email = await AsyncStorageHelper.getLoginEmail();
    // const profileId = await AsyncStorageHelper.getProfileId();

    setLoading(true);

    axios
      .post(`https://comms.globalxchange.io/coin/vault/service/trade/execute`, {
        token: token,
        email: email,
        app_code: appCode,
        profile_id: profileId,
        path_id: pathId,
        from_amount: withdrawalDetails.finalFromAmount,
        stats: false,
        identifier: `Withdraw ${selectedCurrency.coinSymbol} To ${addressInput}`,
        userWithdrawData: addressInput,
        priceLock: false,
        priceLock_currency: 'false',
        ext_verify_code: otp,
      })
      .then(({ data }) => {
        if (data.status) {
          setLoading(false);
          setActionFlow('WithdrawCryptoStepFive');
          // navigate('withdrawSuccess', {
          //   previousBalance: data?.userDebit.current_balance,
          //   updatedBalance: data?.userDebit.updated_balance,
          // });
          setLoading(false);
        } else {
          setLoading(false);
          setWerr(data.message);
          // WToast.show({ data: data.message, position: WToast.position.TOP });
        }
      });
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: window.innerHeight - 100,
      }}
    >
      <div>
        <div className="breadcrumb">
          <span onClick={(e) => setActionFlow(null)}>Confirmation</span>
          &nbsp;&nbsp;{`>`}&nbsp;&nbsp;
          <span style={{ fontWeight: 700 }}>Email Verification</span>
        </div>
        <div style={{ padding: '0px 22px' }}>
          <div style={{ paddingTop: '40px' }}>
            <div
              style={{
                fontWeight: 'bold',
                fontSize: '22px',
                paddingBottom: '31px',
              }}
            >
              Step 4: Email Verification
            </div>
            <div>
              Enter The Six Digit Withdrawal Code Which We Just Sent To Your
              Email
            </div>
            <div className="email-code-wrapper">
              <OtpInput
                className="email-code"
                value={otp}
                onChange={(val) => setOtp(val)}
                // onChange={setOtp}
                numInputs={6}
                separator={<span> </span>}
              />
            </div>
            {errorMessage ? (
              <div
                style={{
                  color: 'red',
                  fontWeight: '600',
                  paddingBottom: '10px',
                }}
              >
                {errorMessage}
              </div>
            ) : (
              ''
            )}
            <div
              style={{ display: 'flex', alignItems: 'center' }}
              onClick={onPasteClick}
            >
              <img src={pasteIcon} style={{ width: '20px', height: '20px' }} />
              <div style={{ paddingLeft: '10px' }}>
                Resend Verification Code
              </div>
            </div>
          </div>
        </div>
        {werr ? (
          <div>
            <div style={{ fontWeight: 'bold', color: 'red' }}>
              Error Occoured. Restart the Transaction
            </div>
            <div
              className="submitButton"
              style={{ background: '#464B4E' }}
              onClick={(e) => setActionFlow('')}
            >
              <div style={{ color: 'white', fontWeight: 'bold' }}>Go Back</div>
            </div>
          </div>
        ) : null}
      </div>
      {/* <div style={{ padding: '0px 22px' }}>
        <div className="doubleButton">
          <div className="copyButton" onClick={(e) => setActionFlow(null)}>
            <div style={{ color: '#464B4E', fontWeight: 'bold' }}>Back</div>
          </div>
          <div
            className="submitButton"
            style={{ background: '#464B4E' }}
            onClick={(e) => setActionFlow('WithdrawCryptoStepTwo')}
          >
            <div style={{ color: 'white', fontWeight: 'bold' }}>Proceed</div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default WithdrawCryptoStepFour;
