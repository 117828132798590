import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import Fade from 'react-reveal/Fade';
import OtpInput from 'react-otp-input';
import { BankContext } from '../../context/Context';

const capRegex = new RegExp(/^.*[A-Z].*/);
const numRegex = new RegExp(/^.*[0-9].*/);
const speRegex = new RegExp(/^.*[!@#$%^&*()+=].*/);

function ForcedResetModal({ emailid, loginvalidate, oldPassword }) {
  const { tostShowOn } = useContext(BankContext);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState('start');

  //Form Values
  const [isValid, setIsValid] = useState({});
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  function resetPassword() {
    setLoading(true);
    Axios.post('https://gxauth.apimachine.com/gx/user/login', {
      email: emailid,
      password: oldPassword,
      newPassword: password,
    })
      .then(({ data }) => {
        tostShowOn(data.message);
        if (data.status) {
          setStep('succes');
        } else {
          tostShowOn(data.message);
        }
      })
      .finally(() => setLoading(false));
  }
  useEffect(() => {
    setIsValid({
      password:
        capRegex.test(password) &&
        numRegex.test(password) &&
        speRegex.test(password) &&
        password.length >= 8,
      confirmPassword: confirmPassword === password,
    });
  }, [password, confirmPassword]);

  const [eye, setEye] = useState(false);

  function getStep() {
    switch (step) {
      case 'start':
        return (
          <div className="success login-form mx-5">
            <div className="succesTitle">
              In Order To Login, We Need You To Need Update Your Password In
              Accordance With Our Security Standards.
            </div>
            <div className="group">
              <button
                type="submit"
                className="btn btn-darkblue mb-5"
                onClick={() => {
                  setStep('enterPassWord');
                }}
              >
                {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Enter'}
              </button>
            </div>
          </div>
        );
      case 'enterPassWord':
        return (
          <div className="login-form mx-5">
            <h5>Enter The Verification Code</h5>
            <div className={`group ${isValid.password}`}>
              <input
                type={eye ? 'text' : 'password'}
                className="input password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setStep('enterCPassWord');
                  }
                }}
              />
              <FontAwesomeIcon
                icon={eye ? faEye : faEyeSlash}
                className="eye"
                onClick={() => setEye(!eye)}
              />
            </div>
            <div className="group">
              <button
                type="submit"
                disabled={loading}
                className="btn btn-darkblue mb-5"
                onClick={() => {
                  if (isValid.password) {
                    setStep('enterCPassWord');
                  }
                }}
              >
                {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Enter'}
              </button>
            </div>
          </div>
        );
      case 'enterCPassWord':
        return (
          <div className="login-form mx-5">
            <h5>Enter The Verification Code</h5>
            <div className={`groupM ${isValid.confirmPassword}`}>
              <input
                type={eye ? 'text' : 'password'}
                className="input password"
                placeholder="Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    resetPassword();
                  }
                }}
              />
              <FontAwesomeIcon
                icon={eye ? faEye : faEyeSlash}
                className="eye"
                onClick={() => setEye(!eye)}
              />
            </div>
            <div className="group">
              <button
                type="submit"
                disabled={loading}
                className="btn btn-darkblue mb-5"
                onClick={() => resetPassword()}
              >
                {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Enter'}
              </button>
            </div>
          </div>
        );
      case 'succes':
        setTimeout(() => {
          loginvalidate(password, emailid);
        }, 2000);
        return (
          <div className="success mx-5">
            <div className="succesTitle">
              You Have Successfully Reset Your Password
            </div>
            <div className="succesBottom">
              You Will Automatically Be Logged In
            </div>
          </div>
        );
      default:
        return '';
    }
  }
  return <div className="card login-enter">{getStep()}</div>;
}

export default ForcedResetModal;
