import Cookies from 'js-cookie';
import React, { useContext, useEffect, useState } from 'react';
import { APP_USER_TOKEN } from '../../../config';
import { useCoinContextData } from '../../../context/CoinContext';
import { BankContext } from '../../../context/Context';
import { useUserApps } from '../../../queryHooks';
import { getAssets, getCoindata, getTradeValue } from '../api';
import info from '../../../static/images/info.svg';
import './buyAction.scss';
import SelectAsset from './SelectAsset';
import { useAppContextDetails } from '../../../context/AppContext';
import ToastMessage from '../../ToastMessage/ToastMessage';
import { handleDecimal } from '../../../utils/FunctionTools';
import { updateScroll } from '../../../config/constants';

export const BuyAction = () => {
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [stats, setStats] = useState(false);
  const [fromBalance, setFromBalance] = useState('');
  const [tradeDone, setTradeDone] = useState({});
  const [isLoading, setisLoading] = useState(false);
  const [statsData, setStatsData] = useState(false);
  const [toast, setToast] = useState('');

  const { email } = useContext(BankContext);
  const {
    fromAsset,
    setFromAsset,
    setAssetName,
    setSelectAsset,
    selectAsset,
    setUpdateAfterTrade,
    setCoinAction,
    coinAction,
    selectedCoin,
    setUpdateVaultData,
  } = useCoinContextData();

  const userIdToken = Cookies.get(APP_USER_TOKEN);
  const { appCode, appByCode, appColorCode, appLogo } = useAppContextDetails();
  const { data: userApps } = useUserApps(email);
  const selectedApp = userApps?.filter((e) => e.app_code === appCode);
  const userProfileId = selectedApp?.[0]?.profile_id;

  useEffect(() => {
    setTimeout(() => {
      setToast('');
    }, 2000);
  }, [toast]);

  useEffect(() => {
    if (coinAction?.length === 1) setSelectAsset(false);
  }, [coinAction]);

  const handlePercCal = (value) => {
    let balance = fromBalance;
    let final = (balance * value) / 100;
    final = handleDecimal(final);
    setFrom(final);
    handleApi(final, true);
  };

  const handleInput = (value, type, pathdat) => {
    let t = value;
    let newValue = (t === '' ? '0.00' : t !== '' && t.indexOf('.') >= 0)
      ? t.substr(0, t.indexOf('.')) + t.substr(t.indexOf('.'), 5)
      : t;
    if (type === 'from') {
      setFrom(newValue);
      if (newValue !== '') {
        handleApi(newValue, true, pathdat);
      } else {
        setStats(false);
        setTo('');
      }
    } else if (type === 'to') {
      setTo(newValue);
    }
  };

  const handleMappingStatsData = (data) => {
    setTo(handleDecimal(data.finalToAmount));
  };

  const handleApi = (value, data, pathdat, tradeClick) => {
    if (
      (value === '' || fromBalance === '' || parseFloat(value) > fromBalance) &&
      data === false
    ) {
      setToast('Balance insufficient');
    } else {
      if (data === true) {
        setTo('Loading..');
      } else {
        setisLoading(true);
      }
      let identifier = `Exchange ${fromAsset?.from_currency?.[0]?.coin_metadata?.coinSymbol} FOR ${selectedCoin.coinSymbol}`;
      let obj = {
        token: userIdToken,
        email,
        app_code: appCode,
        profile_id: userProfileId,
        from_amount: value === '' ? 0 : parseFloat(value),
        stats: data,
        identifier,
        path_id:
          pathdat !== undefined
            ? pathdat.paymentPaths[0].path_ids[0]
            : fromAsset.paymentPaths[0].path_ids[0],
      };

      getTradeValue(obj).then((response) => {
        let result = response?.data;
        if (result?.status) {
          if (data === true) {
            setStats(true);
            setStatsData(response.data);
            handleMappingStatsData(response.data);
          } else {
            setUpdateAfterTrade(true);
            setisLoading(false);
            setTradeDone(response.data);
          }
          tradeClick && setUpdateVaultData(true);
        } else {
          if (data === false) {
            setisLoading(false);
            setTradeDone({});
          }
          setTo(handleDecimal(statsData?.finalToAmount));
        }
      });
    }
  };

  useEffect(() => {
    if (Object.keys(fromAsset).length !== 0) {
      DummyData();
    }
  });

  const DummyData = () => {
    let obj = {
      app_code: appCode,
      email,
      displayCurrency: appByCode.data.currencyname,
      post_app_prices: true,
      orderby_dsc: true,
      type: fromAsset.from_currency?.[0]?.coin_metadata?.type,
    };

    getCoindata(obj).then((response) => {
      let result = response.data;
      if (result.status) {
        result.coins_data.forEach((each) => {
          if (
            each.coinSymbol ===
            fromAsset.from_currency[0]?.coin_metadata?.coinSymbol
          ) {
            setFromBalance(each.coinValue);
          }
        });
      }
    });
  };

  const handleAssetData = () => {
    setTo('Loading..');
    let obj = {
      appCode,
      fromCurrency: fromAsset.from_currency[0]?.coin_metadata?.coinSymbol ?? '',
      toCurrency: selectedCoin.coinSymbol,
    };
    getAssets(obj).then((response) => {
      let result = response?.data;
      if (result.status) {
        setFromAsset(result.pathData);
        handleInput(from, 'from', result.pathData);
      }
    });
  };

  useEffect(() => {
    if (Object.keys(fromAsset).length !== 0) {
      handleAssetData();
    }
  }, [selectedCoin.coinSymbol]);

  const handleSelectAsset = () => {
    setAssetName('');
    setFrom('');
    setTo('');
    setStats(false);
    setSelectAsset(true);
    setCoinAction(['Buy', 'Select Asset']);
  };

  const handleBreadCrumb = () => {
    setAssetName('');
    setFromAsset('');
    setFromBalance('');
    setCoinAction(['Menu']);
    updateScroll();
  };

  const resetData = () => {
    setAssetName('');
    setFrom('');
    setTo('');
    setStats(false);
    setFromAsset({});
    setFromBalance('');
    setTradeDone({});
  };

  return (
    <div className="sidebarbuy">
      <div style={{ opacity: isLoading ? 0.25 : '' }}>
        <>
          {isLoading && (
            <div className="buyotclogo">
              <img className="otclogoimg" src={appLogo} alt="App Logo" />
            </div>
          )}
          {toast && (
            <ToastMessage textOne={toast} onClose={() => setToast('')} />
          )}
          {selectAsset ? (
            <SelectAsset />
          ) : Object.keys(tradeDone).length > 0 ? (
            <div className="congratscard">
              <div className="successData">
                <p className="congratsText">{`Congratulations. You Have Successully Exchanged ${
                  tradeDone.user_debit.amount
                    ? handleDecimal(tradeDone.user_debit.amount)
                    : ''
                } ${
                  tradeDone.user_debit.coin ? tradeDone.user_debit.coin : ''
                }  
        for ${
          tradeDone.user_credit.amount
            ? handleDecimal(tradeDone.user_credit.amount)
            : ''
        } ${tradeDone.user_credit.coin ? tradeDone.user_credit.coin : ''}`}</p>
                <div>
                  <p className="congratshead">{`New ${
                    tradeDone.userResult.debit_obj.coin
                      ? tradeDone.userResult.debit_obj.coin
                      : ''
                  } Balance`}</p>
                  <div className="transactionA">
                    <p className="coinVal">
                      {tradeDone.userResult.debit_obj.updated_balance
                        ? handleDecimal(
                            tradeDone.userResult.debit_obj.updated_balance
                          )
                        : ''}
                    </p>
                    <div className="coinA" key="assets">
                      <img
                        className="coinimgsmallA"
                        src={
                          Object.keys(fromAsset).length > 0
                            ? fromAsset.from_currency[0]?.coin_metadata
                                ?.coinImage
                            : ''
                        }
                        alt=""
                      />
                      <p className="coinsymbolsmallA">
                        {Object.keys(fromAsset).length > 0
                          ? fromAsset.from_currency[0]?.coin_metadata
                              ?.coinSymbol
                          : ''}{' '}
                        &nbsp;&gt;&nbsp;
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <p className="congratshead">{`New ${
                    tradeDone.bankerResult.credit_obj.coin
                      ? tradeDone.bankerResult.credit_obj.coin
                      : ''
                  } Balance`}</p>
                  <div className="transactionA">
                    <p className="coinVal">
                      {tradeDone.bankerResult.credit_obj.updated_balance
                        ? handleDecimal(
                            tradeDone.bankerResult.credit_obj.updated_balance
                          )
                        : ''}
                    </p>
                    <div className="coinA" key="assets">
                      <img
                        className="coinimgsmallA"
                        src={selectedCoin.coinImage}
                        alt="CoinImage"
                      />
                      <p className="coinsymbolsmallA">
                        {selectedCoin.coinSymbol} &nbsp;&gt;&nbsp;
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="menu1">
                <div className="menuItem1">
                  <div className="d-flex justify-content-between">
                    <p className="subhead">From</p>
                    <p className="subheadBalance">
                      Balance:{' '}
                      {fromBalance === '' ? 'TBD' : handleDecimal(fromBalance)}
                    </p>
                  </div>
                  <div className="transaction">
                    <input
                      className="transc"
                      key="from"
                      type="text"
                      id="from"
                      name="from"
                      placeholder="0.00"
                      value={from}
                      onInput={(e) => handleInput(e.target.value, 'from')}
                      disabled={
                        Object.keys(fromAsset).length === 0 ? 'disabled' : ''
                      }
                      style={{
                        cursor:
                          Object.keys(fromAsset).length === 0
                            ? 'not-allowed'
                            : '',
                      }}
                    />
                    <>
                      {Object.keys(fromAsset).length === 0 ? (
                        <div
                          className="coin1"
                          key="assets"
                          onClick={() => handleSelectAsset()}
                        >
                          <p className="selectAsset">
                            Select Asset &nbsp;&gt;&nbsp;
                          </p>
                        </div>
                      ) : (
                        <div
                          className="coin"
                          key="assets"
                          onClick={(e) => handleSelectAsset()}
                        >
                          <img
                            className="coinimgsmall"
                            src={
                              fromAsset.from_currency[0]?.coin_metadata
                                ?.coinImage
                            }
                            alt=""
                          />
                          <p className="coinsymbolsmall">
                            {
                              fromAsset.from_currency[0]?.coin_metadata
                                ?.coinSymbol
                            }{' '}
                            &nbsp;&gt;&nbsp;
                          </p>
                        </div>
                      )}
                    </>
                  </div>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <p
                      className="perc"
                      style={{
                        opacity: fromBalance === '' ? 0.25 : 1,
                        cursor: fromBalance === '' ? '' : 'pointer',
                      }}
                      onClick={() => handlePercCal(25)}
                      disabled={fromBalance === '' ? 'disabled' : ''}
                    >
                      25%
                    </p>
                    <p
                      className="perc"
                      style={{
                        opacity: fromBalance === '' ? 0.25 : 1,
                        cursor: fromBalance === '' ? '' : 'pointer',
                      }}
                      onClick={() => handlePercCal(50)}
                      disabled={fromBalance === '' ? 'disabled' : ''}
                    >
                      50%
                    </p>
                    <p
                      className="perc"
                      style={{
                        opacity: fromBalance === '' ? 0.25 : 1,
                        cursor: fromBalance === '' ? '' : 'pointer',
                      }}
                      onClick={() => handlePercCal(100)}
                      disabled={fromBalance === '' ? 'disabled' : ''}
                    >
                      100%
                    </p>
                  </div>
                  <div style={{ opacity: to === 'Loading..' ? 0.25 : 1 }}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        margin: '43px 0 20px 0',
                      }}
                    >
                      <p className="subhead">To</p>
                      <p className="subheadBalance">
                        Balance: {handleDecimal(selectedCoin.coinValue)}
                      </p>
                    </div>
                    <div className="transaction">
                      <input
                        className="transc"
                        type="text"
                        key="to"
                        id="to"
                        name="to"
                        placeholder="0.00"
                        disabled="disabled"
                        style={{ cursor: 'not-allowed' }}
                        value={to}
                        onInput={(e) => handleInput(e.target.value, 'to')}
                      />
                      <div className="coin" key="coin">
                        <img
                          className="coinimgsmall"
                          src={selectedCoin.coinImage}
                          alt=""
                        />
                        <p className="coinsymbolsmall">
                          {selectedCoin.coinSymbol} &nbsp;&gt;&nbsp;
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        margin: '43px 0 20px 0',
                      }}
                    >
                      <p className="subhead">
                        Fees in{' '}
                        {stats
                          ? statsData?.fees_in_coin
                          : selectedCoin.coinSymbol}
                      </p>
                      <p className="subhead" style={{ opacity: 0.25 }}>
                        {stats ? handleDecimal(statsData?.gx_fee) : 0.0} (
                        {stats
                          ? statsData?.percentage_fee_by_volume?.fees_with_broker?.toFixed(
                              2
                            )
                          : 0.0}
                        %)
                      </p>
                    </div>
                    <div
                      style={{
                        padding: '20px',
                        border: '0.5px solid #E5E5E5',
                        borderRadius: '15px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          padding: '20px 0 20px 0',
                          borderBottom: '0.5px solid #E5E5E5',
                        }}
                      >
                        <p>
                          Affiliate{' '}
                          <span>
                            <img src={info} alt="" width={10} />
                          </span>
                        </p>
                        <p style={{ opacity: 0.25 }}>
                          {stats
                            ? handleDecimal(statsData?.brokerData?.fee)
                            : 0.0}{' '}
                          (
                          {stats
                            ? statsData?.brokerData?.broker_fee_percentage?.toFixed(
                                2
                              )
                            : 0.0}
                          %)
                        </p>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          padding: '20px 0 20px 0',
                          borderBottom: '0.5px solid #E5E5E5',
                        }}
                      >
                        <p>
                          App{' '}
                          <span>
                            <img src={info} alt="" width={10} />
                          </span>
                        </p>
                        <p style={{ opacity: 0.25 }}>
                          {stats
                            ? handleDecimal(statsData?.appData?.app_fee)
                            : 0.0}{' '}
                          (
                          {stats
                            ? statsData?.appData?.app_fee_percentage?.toFixed(2)
                            : 0.0}
                          %)
                        </p>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          padding: '20px 0 20px 0',
                          borderBottom: '0.5px solid #E5E5E5',
                        }}
                      >
                        <p>
                          Banker{' '}
                          <span>
                            <img src={info} alt="" width={10} />
                          </span>
                        </p>
                        <p style={{ opacity: 0.25 }}>
                          {stats
                            ? handleDecimal(
                                statsData?.bankerData?.trade_fee_native
                              )
                            : 0.0}
                          (
                          {stats
                            ? statsData?.bankerData?.banker_fee_percentage?.toFixed(
                                2
                              )
                            : 0.0}
                          %)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      </div>

      {Object.keys(tradeDone).length > 0 ? (
        <div className="footer2">
          <div className="back1" key="back1" onClick={() => handleBreadCrumb()}>
            Close
          </div>
          <div
            className="tradebtn1"
            style={{
              backgroundColor: appColorCode,
            }}
            key="tradebtn1"
            onClick={() => {
              resetData();
              updateScroll();
            }}
          >
            Trade Again
          </div>
        </div>
      ) : (
        <div className="footer1">
          <div className="back" key="back" onClick={() => handleBreadCrumb()}>
            Back
          </div>
          <div
            className="tradebtn"
            key="tradebtn"
            style={{
              backgroundColor: appColorCode,
              opacity: stats ? 1 : 0.25,
              color: stats ? 'white' : '',
              cursor: stats ? 'pointer' : '',
            }}
            onClick={() =>
              stats ? handleApi(from, false, undefined, true) : ''
            }
          >
            Place Trade
          </div>
        </div>
      )}
    </div>
  );
};
