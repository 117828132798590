import React, { useContext, useState } from 'react';
import classNames from './metaverseAppsPage.module.scss';
import Layout from '../../Layout/Layout';
import metaverseApps from '../../static/images/sidebarFullLogos/metaverseApps.svg';

function MetaverseAppsPage() {
  return (
    <Layout
      active={`metaverse`}
      className={classNames.metaverseAppsPage}
      hideFooter
    >
      <div className={classNames.content}>
        <img src={metaverseApps} alt="" className={classNames.logo} />
      </div>
      <div className={classNames.footer}>
        The Metaverse App Store Is Coming To IndianInvestor.com
      </div>
    </Layout>
  );
}

export default MetaverseAppsPage;
