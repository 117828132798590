import React from 'react';
import Skeleton from 'react-loading-skeleton';

export const Step3Skeleton = () => (
  <div>
    <Skeleton
      style={{
        height: '30px',
        width: '30px',
        borderRadius: '50%',
      }}
    />
  </div>
);

export const Step3SkeletonBottom = () => (
  <Skeleton
    style={{
      marginLeft: '15px',
      height: '30px',
      width: '150px',
    }}
  />
);

export const InputSkeleton = () => (
  <>
    <Skeleton
      style={{
        marginTop: '15px',
        height: '30px',
        width: '30px',
        borderRadius: '50%',
        marginLeft: '15px',
      }}
    />
    <Skeleton
      style={{
        marginTop: '15px',
        marginLeft: '15px',
        height: '30px',
        width: '150px',
      }}
    />
  </>
);

export const ExchangeRangeSkeleton = () => (
  <Skeleton
    style={{
      marginLeft: '5px',
      height: '30px',
      width: '150px',
    }}
  />
);

export const TimeLoadingSkeleton = () => (
  <div className="d-flex flex-direction-column">
    <p className="step3-each-card-text1">
      <Skeleton
        style={{
          marginLeft: '5px',
          height: '30px',
          width: '70px',
        }}
      />
    </p>
    <Skeleton
      style={{
        marginLeft: '5px',
        height: '30px',
        width: '70px',
      }}
    />
  </div>
);

export const FeesCardSkeleton = () => (
  <Skeleton
    style={{
      marginLeft: '5px',
      height: '30px',
      width: '50px',
    }}
  />
);
