import React from 'react';

function SignupSidebar({ signupStep, customEmail }) {
  let tick = (
    <svg
      className="tick"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 0C4.93436 0 0 4.93436 0 11C0 17.0656 4.93436 22 11 22C17.0656 22 22 17.0656 22 11C22 4.93436 17.0656 0 11 0Z"
        fill="#464B4E"
      />
      <path
        d="M16.5753 8.66925L10.6169 14.6275C10.4381 14.8062 10.2035 14.8962 9.96881 14.8962C9.73416 14.8962 9.49951 14.8062 9.32076 14.6275L6.34164 11.6484C5.98312 11.29 5.98312 10.7106 6.34164 10.3522C6.7 9.99373 7.27924 9.99373 7.63776 10.3522L9.96881 12.6833L15.2791 7.37314C15.6375 7.01462 16.2167 7.01462 16.5753 7.37314C16.9336 7.73149 16.9336 8.31073 16.5753 8.66925Z"
        fill="#FAFAFA"
      />
    </svg>
  );

  return (
    <div className="signupSidebar">
      <div className="title">Registration</div>
      <div
        className={`step ${
          signupStep === 0 ? 'active' : signupStep > 0 ? 'done' : ''
        }`}
      >
        Step 1: Identify Yourself
        {tick}
      </div>
      <div
        className={`step ${
          signupStep === 1 ? 'active' : signupStep > 1 ? 'done' : ''
        }`}
      >
        Step 2: {customEmail ? 'Email And Username' : 'Create BlockCheck ID'}
        {tick}
      </div>
      <div
        className={`step ${
          signupStep === 2 ? 'active' : signupStep > 2 ? 'done' : ''
        }`}
      >
        Step 3: Create Password
        {tick}
      </div>
      <div
        className={`step ${
          signupStep === 3 ? 'active' : signupStep > 3 ? 'done' : ''
        }`}
      >
        Step 4: Confirm Password
        {tick}
      </div>
      <div
        className={`step ${
          signupStep === 4 ? 'active' : signupStep > 4 ? 'done' : ''
        }`}
      >
        Step 5: {customEmail ? 'Verify Email' : 'Verify BlockCheck ID'}
        {tick}
      </div>
    </div>
  );
}

export default SignupSidebar;
