import React, { useContext } from 'react';
import PinVerify from './PinVerify';
import EmailSelection from './EmailSelection';
import EmailConfirmation from './EmailConfirmation';
import { BankContext } from '../../context/Context';

const OnholdComponent = ({ onhold, setOnHold }) => {
  const { setAdmin } = useContext(BankContext);
  return (
    <div style={{ height: '95%' }}>
      {onhold == 'step1' ? (
        <PinVerify
          onSucces={() => {
            setAdmin(true);
            setOnHold('step2');
          }}
          setOnHold={setOnHold}
        />
      ) : onhold == 'step2' ? (
        <EmailSelection setOnHold={setOnHold} />
      ) : onhold == 'step3' ? (
        <EmailConfirmation setOnHold={setOnHold} />
      ) : (
        ''
      )}
    </div>
  );
};

export default OnholdComponent;
