import React from 'react';
import info from '../../../static/images/info.svg';
import { useCoinContextData } from '../../../context/CoinContext';

export const WithdrawForexConfirmation = () => {
  const {
    selectedCoin,
    forexQuote,
    allApiData,
    checkedOneForex,
    setCheckedOneForex,
  } = useCoinContextData();

  const handleChangeOne = () => setCheckedOneForex(!checkedOneForex);

  return (
    <>
      <p className="step-head">Step 3: Confirmation</p>
      <div
        style={{
          width: '90%',
          height: '95%',
          overflow: 'scroll',
        }}
      >
        <div className="step-3-scroll-div" style={{ height: '100%' }}>
          <p className="step3-each-card-head">Debiting App</p>
          <div className="step3-each-card">
            <div>
              <img
                className="step3-each-card-img"
                src={forexQuote?.appData?.app_icon}
                alt="img"
              />
            </div>
            <p className="step3-each-card-text">
              {forexQuote?.appData?.app_name}
            </p>
          </div>
          <p className="step3-each-card-head">Debiting Vault</p>
          <div className="step3-each-card">
            <div>
              <img
                className="step3-each-card-img"
                src={forexQuote?.coinsData?.fromCoinData?.icon}
                alt="img"
              />
            </div>
            <p className="step3-each-card-text">
              Liquid {forexQuote?.coinsData?.fromCoinData?.name}
            </p>
          </div>
          <p className="step3-each-card-head">Destination</p>
          <div className="step3-each-card1">
            <div style={{ display: 'flex', gap: '5px' }}>
              <img
                className="step3-each-card-img"
                src={allApiData?.withdrawForexInstitueIcon}
                alt="img"
              />
              <p style={{ fontWeight: '700', fontSize: '15px' }}>
                {allApiData?.withdrawForexInstitueName}
              </p>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <p>Account Name</p>
              <p className="step3-each-card-text-2">
                {allApiData?.withdrawForexAccountName}
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '5px',
              }}
            >
              <img
                className="step3-each-card-img"
                src={allApiData?.withdrawForexPaymentIcon}
                alt="image"
              />
              <p>{allApiData?.withdrawForexPaymentMethodName}</p>
            </div>
          </div>
          <p className="step3-each-card-head">Debiting Amount</p>
          <div className="from-to-inputs1">
            <div className="div-from-input" style={{ height: '60%' }}>
              <input
                className="from-input"
                placeholder="0.00"
                key="from-2"
                id="from-2"
                type="text"
                value={forexQuote?.finalFromAmount}
                disabled
              />
              <div className="from-coin" key="from-coin">
                <img
                  className="coinimgsmall"
                  src={selectedCoin.coinImage}
                  alt=""
                />
                <p className="coinsymbolsmall">{selectedCoin.coinSymbol}</p>
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '25px',
            }}
          >
            <p className="step3-subhead">Fees</p>
            <p className="step3-subhead">
              {forexQuote?.reducedAmount ? forexQuote?.reducedAmount : '0.00'}{' '}
              {forexQuote?.fees_in_coin} (
              {forexQuote?.percentage_fee_by_volume?.fees_with_broker.toFixed(
                2
              )}
              %)
            </p>
          </div>
          <div className="feescard-1">
            <div className="fees-card-content">
              <p className="fee-text1">
                Affiliate{' '}
                <span>
                  <img src={info} alt="" width={10} />
                </span>
              </p>
              <p className="fee-text2">
                {forexQuote?.brokerData?.fee} (
                {forexQuote?.brokerData?.broker_fee_percentage === null
                  ? '0.00'
                  : forexQuote?.brokerData?.broker_fee_percentage.toFixed(2)}
                %)
              </p>
            </div>
            <div className="fees-card-content">
              <p className="fee-text1">
                App{' '}
                <span>
                  <img src={info} alt="" width={10} />
                </span>
              </p>
              <p className="fee-text2">
                {forexQuote?.appData?.app_fee} (
                {forexQuote?.appData?.app_fee_percentage === null
                  ? '0.00'
                  : forexQuote?.appData?.app_fee_percentage.toFixed(2)}
                %)
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '20px 0 20px 0',
              }}
            >
              <p className="fee-text1">
                Banker{' '}
                <span>
                  <img src={info} alt="" width={10} />
                </span>
              </p>
              <p className="fee-text2">
                {forexQuote?.bankerData?.trade_fee_native} (
                {forexQuote?.bankerData?.banker_fee_percentage === null
                  ? '0.00'
                  : forexQuote?.bankerData?.banker_fee_percentage.toFixed(2)}
                %)
              </p>
            </div>
          </div>
          <p className="step3-each-card-head">Receiving Amount</p>
          <div className="from-to-inputs1">
            <div className="div-from-input" style={{ height: '60%' }}>
              <input
                className="from-input"
                placeholder="0.00"
                key="from-2"
                id="from-2"
                type="text"
                value={forexQuote?.finalToAmount}
                disabled
              />
              <div className="from-coin" key="from-coin">
                <img
                  className="coinimgsmall"
                  src={selectedCoin.coinImage}
                  alt=""
                />
                <p className="coinsymbolsmall">{selectedCoin.coinSymbol}</p>
              </div>
            </div>
          </div>
          <p className="step3-each-card-head">Confirmations</p>
          <div className="check-div">
            <label>
              <input
                className="check-box-withdraw"
                type="checkbox"
                key="check-1"
                id="check-1"
                checked={checkedOneForex}
                onChange={handleChangeOne}
              />
              <p style={{ paddingLeft: '30px', marginTop: '-35px' }}>
                {
                  ' I Consent To All The Aforementioned Details Regarding This Transfer Request.'
                }
              </p>
            </label>
          </div>
        </div>
      </div>
    </>
  );
};
