import React, { useContext } from 'react';
import { useAppContextDetails } from '../../context/AppContext';
import { BankContext } from '../../context/Context';

import { FormatCurrency, FormatNumber } from '../../utils/FunctionTools';

function SmartProspectus({
  setOpenModal,
  tiObject,
  trustBalance,
  simulteRes,
  appSelected,
}) {
  const { defaultPrecission } = useContext(BankContext);
  const { appLogo } = useAppContextDetails();
  return (
    <div className="transactionInspectorWrapper smart">
      <div
        className="overlay-ti"
        role="button"
        tabIndex="-1"
        onClick={() => setOpenModal(false)}
      />
      <div className="ti-content">
        <div className="ti-head">
          <img src={appLogo} alt="" />
          Smart Prospectus
        </div>
        <div className="ti-detailSmart">
          <div className="appNameBalance">
            {appSelected?.app_code === 'all' ? 'Total' : appSelected.app_name}{' '}
            {tiObject.coin} Balance
          </div>
          <div className="appNameBalanceValue">
            {FormatCurrency(tiObject.amount, tiObject.coin, defaultPrecission)}{' '}
            {tiObject.coin}
          </div>
          <div className="lblRow">
            <span>Trust Balance</span>
            <span>
              {simulteRes?.slotData?.name.replace('slot', 'Tier ')}{' '}
              Reqruirements
            </span>
          </div>
          <div className="valueRow">
            <span>
              {FormatCurrency(trustBalance, 'BTC', defaultPrecission)} BTC
            </span>
            <span>
              {FormatCurrency(
                simulteRes?.slotData?.amount,
                'BTC',
                defaultPrecission
              )}{' '}
              BTC
            </span>
          </div>
          <div className="lblRow">
            <span>Base Rate</span>
            <span>
              {simulteRes?.slotData?.name.replace('slot', 'Tier ')} Rate
            </span>
          </div>
          <div className="valueRow">
            <span>{FormatNumber(tiObject.base_interest, 5)}%</span>
            <span>
              {FormatNumber(
                simulteRes?.finalInterestData?.filter(
                  (coin) => coin.coin === tiObject.coin
                )[0]?.final_interest,
                5
              )}
              %
            </span>
          </div>
          <div className="lblRow">
            <span>Base Earnings</span>
            <span>
              {simulteRes?.slotData?.name.replace('slot', 'Tier ')} Earnings
            </span>
          </div>
          <div className="valueRow">
            <span>
              {FormatNumber(tiObject.base_interest_value, 6)} {tiObject.coin}
            </span>
            <span>
              {FormatNumber(tiObject.interest_value || 0, 6)} {tiObject.coin}
            </span>
          </div>
          <div className="lblRow">
            <span>Base Earnings USD</span>
            <span>
              {simulteRes?.slotData?.name.replace('slot', 'Tier ')} Earnings USD
            </span>
          </div>
          <div className="valueRow">
            <span>{FormatNumber(tiObject.base_interest_value_usd, 6)} USD</span>
            <span>{FormatNumber(tiObject.interest_value_usd || 0, 6)} USD</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SmartProspectus;
