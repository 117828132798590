import axios from 'axios';
import { useMutation, useQuery } from 'react-query';

export const getBondEarningsBalance = async (email) => {
  try {
    const data = await axios.get(
      `https://comms.globalxchange.io/coin/iced/interest/balances/get?`,
      {
        params: {
          app_code: 'ice',
          email,
          with_balances: true,
        },
      }
    );
    return data;
  } catch (err) {
    console.error(err);
  }
};

export const useLoadBondEarningsBalance = (
  email,
  transferMethod,
  loadBalance = true
) => {
  const query = useQuery(
    ['loadBondEarningsBalance', { email, transferMethod }],
    () => getBondEarningsBalance(email),
    { enabled: transferMethod === 'bonds' && loadBalance }
  );
  return query;
};

export const getMoneyMarketEarningsBalance = async (body) => {
  try {
    const data = await axios.get(
      `https://comms.globalxchange.io/coin/vault/service/user/app/interest/balances/get`,
      { params: body }
    );
    return data;
  } catch (err) {
    console.error(err);
  }
};

export const useLoadMMEarningsBalance = (
  email,
  appCode,
  transferMethod,
  loadBalance = true
) => {
  const query = useQuery(
    ['loadMMEarningsBalance', { email, appCode, transferMethod }],
    () =>
      getMoneyMarketEarningsBalance({
        email,
        app_code: appCode,
        with_balances: true,
      }),
    { enabled: transferMethod === 'moneymarkets' && loadBalance }
  );
  return query;
};

export const transferFundFromBondingToLiquid = async (body) => {
  try {
    const data = await axios.post(
      `https://comms.globalxchange.io/coin/iced/interest/withdraw`,
      body
    );
    return data;
  } catch (err) {
    console.error(err);
  }
};

export const useTransferBondFundApi = (
  setLoadBalance,
  setUpdateBondVaultData
) => {
  const mutation = useMutation(transferFundFromBondingToLiquid, {
    onSuccess: () => {
      setLoadBalance(true);
      setUpdateBondVaultData(true);
    },
  });
  return mutation;
};

export const transferFundFromMMtoLiquid = async (body) => {
  try {
    const data = await axios.post(
      `https://comms.globalxchange.io/coin/vault/service/user/app/interest/withdraw`,
      body
    );
    return data;
  } catch (err) {
    console.error(err);
  }
};

export const useTransferMMFundApi = (setLoadBalance, setUpdateMMVaultData) => {
  const mutation = useMutation(transferFundFromMMtoLiquid, {
    onSuccess: () => {
      setLoadBalance(true);
      setUpdateMMVaultData(true);
    },
  });
  return mutation;
};
