import React, { useEffect, useContext, useState } from 'react';
import './EaDrawer.scss';
import { StatueData, SubmitStatus } from './apidata';
import { BankContext } from '../../context/Context';
import Skeleton from 'react-loading-skeleton';
export default function EaDrawer({
  drawerName,
  licenseId,
  statusCheck,
  setEatoggle,
}) {
  const [accNo, setaccNo] = useState('');
  const [ServerNo, setServerNo] = useState('');
  const [MasterPassword, setMasterPassword] = useState('');
  const [apiloading, setapiloading] = useState(false);
  const [tab, settab] = useState('connect');

  const { email, profileId, token } = useContext(BankContext);
  useEffect(() => {
    settab('connect');
  }, []);

  const handle = () => {
  
    let obj = {
      license_id: licenseId,
      email: email,
      note: {
        ea:drawerName=="Queen"?"queen":"nitroplus",
        accountnumber: accNo,
        masterpassword: ServerNo,
        servername: ServerNo,
      },
    };
    if (accNo !== '' && MasterPassword !== '' && ServerNo !== '') {
        setapiloading(true);
      SubmitStatus(obj).then((response) => {
        let result = response?.data;
        if (result.status) {
          settab('success');
          setapiloading(false);
        }
      });
    }
    console.log('sqsc', obj);
  };

  const page = () => {
    switch (tab) {
      case 'success':
        return (
          <>
            <div className="EaDrawer">
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  opacity: apiloading ? 0.2 : 1,
                }}
              >
                <div className="headerr">
                  <div className="topp">
                    <div style={{ width: '80%' }}>
                      <p style={{ fontSize: '1.75rem', fontWeight: '650' }}>
                        Success
                      </p>
                    </div>
                  </div>

                  <div className="scrollerFundsCutom ">
                    <div className="innerFundsAdding">
                      <div className="suceesmsg">
                        Thank you for submitting your account details. We will
                        review them within 24 hours and you will get a
                        notification when your account has been connected to the
                        DGFX {drawerName} EA.
                      </div>

                      <div
                        className="labelSubmit"
                        onClick={() => setEatoggle(false)}
                      >
                        Close
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
        break;
      case 'connect':
        return (
          <>
            <div className="EaDrawer">
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  opacity: apiloading ? 0.2 : 1,
                }}
              >
                <div className="headerr">
                  <div className="topp">
                    <div style={{ width: '80%' }}>
                      <p style={{ fontSize: '1.75rem', fontWeight: '650' }}>
                        Connect To DGFX {drawerName}
                      </p>
                    </div>
                  </div>

                  <div className="scrollerFundsCutom ">
                    <div className="innerFundsAdding">
                      <div className="subSection" style={{ marginTop: '2rem' }}>
                        <div className="Subscription">Subscription</div>
                        {/* {Object.keys(statusCheck)?.length > 0 ? ( */}
                        <div
                          className="ActiveEA"
                          style={{
                            color:
                              statusCheck?.license_status == 'active'
                                ? '#50c7ad'
                                : 'red',
                          }}
                        >
                          {statusCheck?.license_status == 'active'
                            ? 'Active'
                            : 'Inactive'}
                        </div>
                        {/* ) : (
                    <Skeleton className="sectionskeletonStstua" />
                  )} */}
                      </div>
                      <div className="borderSectionEadrawer"></div>
                      <div className="each-field">
                        <p>Account Number</p>
                        <div className="inputt-field">
                          <input
                            type=""
                            placeholder="Enter Account Number"
                            value={accNo}
                            onChange={(e) => setaccNo(e.target.value)}
                            style={{ cursor: 'text' }}
                          />
                        </div>
                      </div>

                      <div className="each-field">
                        <p>Master Password</p>
                        <div className="inputt-field">
                          <input
                            placeholder="Enter Master Password"
                            value={MasterPassword}
                            onChange={(e) => setMasterPassword(e.target.value)}
                            type="password"
                            style={{ cursor: 'text' }}
                          />
                        </div>
                      </div>
                      <div className="each-field">
                        <p>Server Name</p>
                        <div className="inputt-field">
                          <input
                            type=""
                            placeholder="Enter Server Name"
                            value={ServerNo}
                            onChange={(e) => setServerNo(e.target.value)}
                            style={{ cursor: 'text' }}
                          />
                        </div>
                      </div>
                      <div className="labelSubmit"
                      onClick={handle}
                       
                       >
                        Submit
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
        break;

      default:
        break;
    }
  };
  return <>{page()}</>;
}
