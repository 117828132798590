import axios from 'axios';
import React, { useContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useAppContextDetails } from '../../../context/AppContext';
import { BankContext } from '../../../context/Context';

const BuyBondStepThree = ({ bondCount, setBondCount, payCoin }) => {
  const { email, token, setSidebarCollapse } = useContext(BankContext);
  const {
    ownerEmail,
    appColorCode,
    setActionFlow,
    selectedBond,
    setSelectedBond,
    selectedCurrency,
    appCode,
  } = useAppContextDetails();

  const [amount, setAmount] = useState(0);
  const [allVaults, setAllVaults] = useState([]);
  const [filteredVaults, setFilteredVaults] = useState([]);
  const [query, setQuery] = useState('');

  const [check1, setCheck1] = useState(false);
  const [check2, setCheck2] = useState(false);

  const [iceObject, setIceObject] = useState(null);
  const [appObject, setAppObject] = useState(null);

  const [loading, setLoading] = useState(false);
  const [txnError, setTxnError] = useState('');

  const handleBuy = () => {
    getProfileIds();
    // buyBondFunction();
    // navigate('StepFour');
  };

  const getProfileIds = async () => {
    if (email) {
      axios
        .get(
          `https://comms.globalxchange.io/gxb/apps/registered/user?email=${email}`
        )
        .then(({ data }) => {
          if (data.status) {
            setIceObject(data.userApps.find((o) => o.app_code === 'ice'));
            setAppObject(data.userApps.find((o) => o.app_code === appCode));
            // setAppObject(data.userApps.find((o) => o.app_code === 'EuropeanOTC'));
          }
        });
    }
  };

  useEffect(() => {
    if (appObject !== null && iceObject != null) {
      buyBondFunction();
    }
  }, [appObject, iceObject]);

  const buyBondFunction = async () => {
    setLoading(true);

    axios
      .post(`https://comms.globalxchange.io/coin/iced/contract/create`, {
        email: email,
        token: token,
        origin_app_code: appObject.app_code,
        profile_id: iceObject.profile_id,
        coin: selectedBond.coinsData[0].coin,
        days: selectedBond.days,
        num_of_bonds: bondCount,
        simulate: false,
        payCoin: payCoin.coinSymbol,
        stats: false,
        bond_type: 'custom',
        bond_template_id: selectedBond.bond_template_id,
        debitfromApp: appObject.app_code,
        debitfromProfileId: appObject.profile_id,
      })
      .then(({ data }) => {
        console.log(data);
        if (data.status) {
          // navigate('StepFour', {
          //   bankerdata: bankerdata,
          //   bondCount: bondCount,
          //   appData: appData,
          //   payCoin: payCoin,
          // });
          setActionFlow('BuyBondStepFour');
          setLoading(false);
        } else {
          setLoading(false);
          // navigate('StepFour', {
          //   bankerdata: bankerdata,
          //   appData: appData,
          //   bondCount: bondCount,
          //   payCoin: payCoin,
          // });
          setTxnError(data.message);
          // alert(data.message);
          // WToast.show({ data: data.message, position: WToast.position.TOP });
        }
      });
  };

  return (
    <>
      <div>
        <div style={{ background: appColorCode, height: '15vh' }}>&nbsp;</div>
        <div className="imgWrapper">
          <div className="imgCointainer">
            <img
              src={selectedBond?.bankerData?.profilePicURL}
              alt=""
              style={{ width: '60px', height: '60px' }}
            />
          </div>
        </div>
        <div
          style={{ textAlign: 'center', fontSize: '20px', fontWeight: 'bold' }}
        >
          {selectedBond?.bankerData?.displayName} Bond
        </div>
        <div style={{ textAlign: 'center' }}>
          <span onClick={(e) => setActionFlow('BuyBondStepTwo')}>
            Payment Assets
          </span>{' '}
          {`>`} <span style={{ fontWeight: 'bold' }}>Terms</span>
        </div>
        <div className="dataContainer">
          <div>
            <div
              style={{
                paddingTop: '30px',
                fontSize: '20px',
                fontWeight: 'bold',
              }}
            >
              Step 3: Terms of Bond
            </div>
          </div>
          <div>
            <div className="confirmation">
              <input
                type="checkbox"
                checked={check1}
                onChange={(e) => setCheck1(e.target.checked)}
              />

              <div className="confirmationText">
                I Consent To All The Aforementioned Parameters Regarding This
                Transfer Request.
              </div>
            </div>
            <div className="confirmation">
              <input
                type="checkbox"
                checked={check2}
                onChange={(e) => setCheck2(e.target.checked)}
              />

              <div className="confirmationText">
                I Acknowledge That CCSWealth Is Not Able To Recover Funds Once
                They Have Sent To
                {/* <div className="addressText">{externalAddress}</div> */}
                <div className="addressText">kjdsbfkjsrktgherlgk</div>
              </div>
            </div>
          </div>
          {txnError ? (
            <div style={{ fontWeight: 'bold', color: 'red' }}>{txnError}</div>
          ) : (
            ''
          )}
          <div className="doubleButton">
            <div
              className="submitButton"
              style={{ background: 'white' }}
              onClick={(e) => setActionFlow('BuyBondStepTwo')}
            >
              <div style={{ fontWeight: 'bold' }}>Back</div>
            </div>
            <div
              className="submitButton"
              style={{ background: appColorCode }}
              onClick={(e) => {
                if (check1 && check2 && !loading) {
                  handleBuy();
                  // setActionFlow('BuyBondStepFour');
                }
              }}
              //   onClick={confirmQuoteFunction}
            >
              <div style={{ color: 'white', fontWeight: 'bold' }}>
                {loading ? 'Loading...' : 'Next'}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BuyBondStepThree;
