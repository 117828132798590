import React from 'react';

function HexaNumber({ num, onClick, className }) {
  return (
    <svg
      width={142}
      height={159}
      onClick={onClick}
      className={className}
      viewBox="0 0 142 159"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M141.221 112.674V46.3252C141.221 41.627 138.715 37.2856 134.646 34.9363L77.186 1.76196C73.1172 -0.58732 68.1041 -0.58732 64.0353 1.76196L6.57542 34.9366C2.50666 37.2859 0.000244141 41.627 0.000244141 46.3255V112.674C0.000244141 117.373 2.50666 121.714 6.57542 124.063L64.0353 157.238C68.1041 159.587 73.1172 159.587 77.186 157.238L134.646 124.063C138.715 121.714 141.221 117.373 141.221 112.674Z"
        fill="#9B9B9B"
      />
      <path
        d="M134.646 124.063C136.688 122.884 138.335 121.203 139.47 119.227L70.6104 79.4712V158.999C72.8807 158.999 75.1511 158.412 77.1855 157.238L134.646 124.063Z"
        fill="#464B4E"
      />
      <path
        d="M64.0354 1.76197L6.57518 34.9366C2.50641 37.2859 0 41.627 0 46.3252V112.674C0 117.372 2.50672 121.714 6.57518 124.063L64.0351 157.237C66.0698 158.412 68.3402 158.999 70.6106 158.999V0.000244141C68.3402 0.000244141 66.0698 0.587485 64.0354 1.76197Z"
        fill="#9B9B9B"
      />
      <path
        d="M70.6106 79.4998L139.459 39.75C138.324 37.7837 136.68 36.1111 134.646 34.9366L77.1857 1.76196C73.117 -0.58732 68.1041 -0.58732 64.0351 1.76196L6.57517 34.9366C4.54079 36.1111 2.89676 37.7837 1.76172 39.75L70.6106 79.4998Z"
        fill="#D8D8D8"
      />
      <path
        d="M64.0354 1.76197L6.57517 34.9366C4.54079 36.1111 2.89676 37.7837 1.76172 39.75L70.6106 79.4999V0.000244141C68.3402 0.000244141 66.0698 0.587485 64.0354 1.76197Z"
        fill="#9B9B9B"
      />
      <path
        d="M6.57514 124.063C4.53331 122.884 2.88493 121.204 1.74927 119.229L70.6105 79.4712V158.999C68.3401 158.999 66.0697 158.412 64.0353 157.238L6.57514 124.063Z"
        fill="#464B4E"
      />
      <path
        d="M9.70618 41.3756C8.48387 40.6669 6.91375 41.0849 6.2026 42.3147C5.49394 43.5419 5.91473 45.1096 7.14169 45.8182L69.3284 81.7213L71.8929 77.2787L9.70618 41.3756Z"
        fill="white"
      />
      <path
        d="M70.6104 153.871C72.028 153.871 73.1749 152.723 73.1749 151.306V80.9799L134.079 45.8181C135.307 45.1095 135.727 43.5415 135.018 42.3146C134.31 41.0898 132.739 40.6668 131.515 41.3755L69.3281 77.2786C68.5344 77.7369 68.0459 78.5835 68.0459 79.4999V151.306C68.0459 152.723 69.1931 153.871 70.6104 153.871Z"
        fill="white"
      />
      <foreignObject x={6} y={82} width={56} height={37}>
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 40,
            fontWeight: 600,
            color: 'white',
          }}
        >
          {num}
        </div>
      </foreignObject>
    </svg>
  );
}

export default HexaNumber;
