import React from 'react';
import OtpInput from 'react-otp-input';
import { useCoinContextData } from '../../../context/CoinContext';
import reload from '../../../static/images/reload.svg';

export const EmailVerification = ({ handleBreadCrumb }) => {
  const { otp, setOtp } = useCoinContextData();
  return (
    <div className="sidebarwithdraw">
      <div>
        <div className="breadcrumb" style={{ display: 'flex' }}>
          <p
            className="breadcrumbText"
            style={{
              marginTop: '5px',
              cursor: 'pointer',
            }}
            onClick={() => handleBreadCrumb()}
          >
            <span>Withdrawal Confirmation</span>
            &nbsp;&nbsp;&gt;&nbsp;&nbsp;
          </p>
          <p className="active-crumb">
            <span
              style={{
                textDecoration: 'underline',
                textUnderlineOffset: '3px',
              }}
            >
              Email Verification
            </span>
          </p>
        </div>
        <div className="step-div">
          <p className="step-head">Step 4: Email Verification</p>
          <p className="step-para">
            Enter The Six Digit Withdrawal Code Which We Just Sent To Your Email
          </p>
          <div className="email-code-wrapper">
            <OtpInput
              className="email-code"
              value={otp}
              onChange={setOtp}
              numInputs={6}
              separator={<span> </span>}
            />
          </div>

          <div className="d-flex clickable-div">
            <img src={reload} width={20} />
            <p className="reload-otp">Resend Verification Code</p>
          </div>
        </div>
      </div>
    </div>
  );
};
