import React, { useContext } from 'react';

import { useAppContextDetails } from '../../../context/AppContext';
import { BankContext } from '../../../context/Context';
import './vaultAction.scss';

import qrIcon from '../../../static/images/mobile/qr.svg';
import extWithdrawIcon from '../../../static/images/mobile/externalWithdraw.svg';

import addCoinIcon from '../../../static/images/mobile/addCoin.svg';
import withdrawCoinIcon from '../../../static/images/mobile/withdrawCoin.svg';
import moneyMarketIcon from '../../../static/images/mobile/moneyMarket.svg';
import bondIcon from '../../../static/images/mobile/bond.svg';
import { useState } from 'react';
import { useEffect } from 'react';
import Flow from '../ActionFlow';

const VaultAction = ({ vaultType }) => {
  const {
    appCode,
    selectedView,
    setSelectedView,
    selectedCurrency,
    setSelectedCurrency,
    actionFlow,
    setActionFlow,
  } = useAppContextDetails();
  const { email, token, setSidebarCollapse } = useContext(BankContext);

  const conditionalActions = () => {
    if (vaultType === 'Crypto') {
      return (
        <>
          <div
            className="actionButton"
            onClick={(e) => setActionFlow('View Address')}
          >
            <img src={qrIcon} alt="" />
            <div>View Address</div>
          </div>
          <div
            className="actionButton"
            onClick={(e) => setActionFlow('Withdraw Crypto')}
          >
            <img src={extWithdrawIcon} alt="" />
            <div>Withdraw {selectedCurrency?.coinName}</div>
          </div>
        </>
      );
    } else if (vaultType === 'Forex') {
      return (
        <>
          <div
            className="actionButton"
            onClick={(e) => setActionFlow('AddForexStepOne')}
          >
            <img src={addCoinIcon} alt="" />
            <div>Add {selectedCurrency?.coinName}</div>
          </div>
          <div
            className="actionButton"
            onClick={(e) => setActionFlow('WithdrawForexStepOne')}
          >
            <img src={withdrawCoinIcon} alt="" />
            <div>Withdraw {selectedCurrency?.coinName}</div>
          </div>
        </>
      );
    } else if (vaultType === 'MoneyMarket') {
      return (
        <div className="actionButton">
          <img src={moneyMarketIcon} alt="" />
          <div>Withdraw MoneyMarket Earning</div>
        </div>
      );
    } else if (vaultType === 'Bonds') {
      return (
        <div className="actionButton">
          <img src={bondIcon} alt="" />
          <div>Withdraw Bond Earning</div>
        </div>
      );
    }
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          paddingTop: '40px',
        }}
      >
        <div className="actionTitle">
          Which Action Would You Like To Perform?
        </div>
        {conditionalActions()}
      </div>
    </>
  );
};

export default VaultAction;
