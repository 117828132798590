import React from 'react';
import { useAppContextDetails } from '../../context/AppContext';
import android from '../../static/images/clipIcons/androidColor.svg';
import ios from '../../static/images/clipIcons/iosColor.svg';

function SendInviteModalPlatform({ onClose, setPlatform }) {
  const { appFullLogo } = useAppContextDetails();
  return (
    <div className="inviteModal">
      <div
        className="overlay"
        onClick={() => {
          try {
            onClose();
          } catch (e) {}
        }}
      />
      <div className="modalInvite">
        <>
          <div className="headInvite">
            <img src={appFullLogo} alt="" className="headLogo" />
          </div>
          <div className="contentInvite platform">
            <label htmlFor="">Which App Do You Want? </label>
            <div className="inputBoxIn">
              <div className="boxApp" onClick={() => setPlatform('android')}>
                <img src={android} alt="" />
                Android
              </div>
              <div className="boxApp" onClick={() => setPlatform('ios')}>
                <img src={ios} alt="" />
                Iphone
              </div>
            </div>
          </div>
        </>
      </div>
    </div>
  );
}

export default SendInviteModalPlatform;
