import React, { useContext } from "react";
import { useAppContextDetails } from "../../context/AppContext";
import { BankContext } from "../../context/Context"

const Amountdata = () => {
  const {
    bondcount,
    setBondcount,
    selectedbtn,
    setSelectedbtn,
  } = useContext(BankContext);
  const { appColorCode } = useAppContextDetails();

  // const bondCountFunc = () => {
  //   let value = document.getElementById("per-input");
  //   let data = value.innerText;
  //   setBondcount(data);
  //   setSelectedbtn("");
  // };

  // bondCountFunc();

  return (
    <div className="amount-data">
      <div className="padding-div">
        <p>Enter Percentage Of Balance</p>
        <div className="per-input">
          <div>50%</div>
          <div>100%</div>
          <input
            placeholder="0.00%"
            className="inputbox"
            style={{ opacity: 0.25 }}
          />
        </div>
        <p className="bonds-txt">Enter Amount Of Bonds</p>
        <div className="per-input">
          <div
            // id="per-input"
            onClick={() => {
              setBondcount(1);
              setSelectedbtn("");
            }}
            style={{
              border:
                bondcount == 1 ? "2px solid #E5E5E5" : "0.5px solid #E5E5E5",
            }}
          >
            1
          </div>
          <div
            // id="per-input"
            onClick={() => {
              setBondcount(25);
              setSelectedbtn("");
            }}
            style={{
              border:
                bondcount == 25 ? "2px solid #E5E5E5" : "0.5px solid #E5E5E5",
            }}
          >
            25
          </div>
          <input
            placeholder="0"
            className="inputbox"
            style={{ opacity: 0.25 }}
          />
        </div>
      </div>
      <div
        className="purchase-footer"
        style={{
          borderTop: "1px solid #E7E7E7",
          justifyContent: "center",
          backgroundColor:
            bondcount == 0
              ? "#FFFFFF"
              : appColorCode,
          color: bondcount == 0 ? "#464B4E" : "#FFFFFF",
        }}
      >
        <div style={{ opacity: bondcount == 0 ? 0.25 : 1 }}>
          Purchase {bondcount} Bonds
        </div>
      </div>
    </div>
  );
};

export default Amountdata;
