import React from 'react';
import OptionsContextProvider from './ContextAPI/OptionContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Static/scss/master.scss';
import Home from './pages/Home/Home';

function Indices({ tabSelected }) {
  return (
    <OptionsContextProvider>
      <Home tabSelected={tabSelected} />
    </OptionsContextProvider>
  );
}

export default Indices;
