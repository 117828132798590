import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import { useAppContextDetails } from '../../context/AppContext';
import { BankContext } from '../../context/Context';

function ResendCode({ password, emailid, loginvalidate }) {
  const { tostShowOn } = useContext(BankContext);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState('enterPin');

  //Form Values
  const [isValid, setIsValid] = useState({});
  const [pin, setPin] = useState('');
  const { appName } = useAppContextDetails();

  useEffect(() => {
    setIsValid({
      pin: String(pin).length === 6,
    });
  }, [pin]);

  const [invalidPin, setInvalidPin] = useState(false);
  function verifyEmail() {
    setLoading(true);
    Axios.post('https://gxauth.apimachine.com/gx/user/confirm', {
      email: emailid,
      code: pin,
    })
      .then(({ data }) => {
        tostShowOn(data.message);
        if (data.status) {
          setStep('succes');
        } else {
          setInvalidPin(true);
          setStep('enterPin');
        }
      })
      .finally(() => setLoading(false));
  }
  function getStep() {
    switch (step) {
      case 'enterPin':
        return (
          <div className="login-form mx-5">
            <h5>
              {invalidPin
                ? 'Incorrect Code. Please Enter It Again'
                : `We Need You To Verify Your Email. Please Enter The Code That Was Just Sent To Your ${appName} Email`}
            </h5>
            <OtpInput
              containerStyle="input-wrapper-2fa"
              value={pin}
              onChange={setPin}
              numInputs={6}
              separator={<span> </span>}
              inputStyle="input-2fa"
              shouldAutoFocus
            />
            <div className="group">
              <button
                type="submit"
                disabled={loading}
                className="btn btn-darkblue mb-5"
                onClick={() => {
                  if (isValid.pin) {
                    verifyEmail();
                  }
                }}
              >
                {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Enter'}
              </button>
            </div>
          </div>
        );
      case 'succes':
        setTimeout(() => {
          loginvalidate(password, emailid);
        }, 2000);
        return (
          <div className="success mx-5">
            <div className="succesTitle">
              You Have Successfully Verified Your Email
            </div>
            <div className="succesBottom">
              You Will Automatically Be Logged In
            </div>
          </div>
        );
    }
  }
  return <div className="card login-enter">{getStep()}</div>;
}

export default ResendCode;
