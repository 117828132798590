import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import classNames from './marketsPage.module.scss';
import tradeStream from '../../static/images/sidebarIcons/tradeStream.svg';
import searchIcon from '../../static/images/search.svg';
import { useShareTokensDetail } from '../../queryHooks';
import { MARKET_ASSET_CLASSES, SIDEBAR_FOOTERS } from '../../config/constants';
import { BankContext } from '../../context/Context';
import { GetSortOrder } from '../../utils/FunctionTools';
import MarketAssetDetail from '../../components/MarketAssetDetail';
import TerminalsSidebar from '../../components/TerminalsSidebar';
import LoginModal from '../../components/LoginModalNew';
import Layout from '../../Layout/Layout';
import MarketTableCrypto from './components/MarketTableCrypto';
import MarketTableForex from './components/MarketTableForex';
import MarketTableShares from './components/MarketTableShares';
import MarketTableBonds from './components/MarketTableBonds';
import MarketTableMoneyMarkets from './components/MarketTableMoneyMarkets';
import { BondContractDetails } from './components/BondContractDetails';
import { useMarketContext } from '../../context/MarketContext';

import fulllogo from '../../static/images/shares/fulllogo.svg';
import search from '../../static/images/malls/search.svg';
import { useAppContextDetails } from '../../context/AppContext';

function MarketsPage() {
  const history = useHistory();
  const { assetClassName, coin } = useParams();
  const [assetClass, setAssetClass] = useState(MARKET_ASSET_CLASSES[0]);
  const [index, setIndex] = useState(2);
  const { email, sidebarCollapse } = useContext(BankContext);
  const { streamOpen, setStreamOpen } = useMarketContext();

  const [search, setSearch] = useState('');
  const [selectedSidebarMenu, setSelectedSidebarMenu] = useState('Bonds');
  const ref = useRef();

  const { marketSearch, setMarketSearch, coinSelected, setCoinSelected } =
    useAppContextDetails();

  const { data: shareToken } = useShareTokensDetail(coin);

  useEffect(() => {
    const classSelected = MARKET_ASSET_CLASSES.filter(
      (asCls) => asCls.name === assetClassName
    );
    if (classSelected[0]) setAssetClass(classSelected[0]);
  }, [assetClassName]);

  useEffect(() => {
    if (assetClassName === 'shares' && shareToken?.token_profile_data) {
      setCoinSelected(shareToken?.token_profile_data);
    }
  }, [coin, shareToken, assetClassName]);

  useEffect(() => {
    ref.current.addEventListener('scroll', scrollHandle);
    return () => {
      ref.current.removeEventListener('scroll', scrollHandle);
    };
  }, []);
  const [active, setActive] = useState(false);
  const scrollHandle = (event) => {
    setActive(
      Boolean(
        event?.path[0]?.scrollTop >=
          event?.path[0].childNodes[1].childNodes[0].childNodes[1]?.offsetTop
      )
    );
  };
  // console.log('coinSymbol', coin);
  let pathName = window?.location?.pathname?.split('/');

  const filteredData = (item) =>
    item
      ? Object.values(item)
          ?.join(' ')
          ?.toLowerCase()
          ?.includes(marketSearch.toLowerCase())
      : [];

  function selectTable() {
    // console.log(assetClass?.name, 'assetClassname', assetClass);
    switch (assetClass?.name) {
      case 'crypto':
        return (
          <MarketTableCrypto
            streamOpen={streamOpen}
            setCoinSelected={setCoinSelected}
            assetClass={assetClass}
            active={active}
            search={search}
          />
        );
      case 'fiat':
        return (
          <MarketTableForex
            streamOpen={streamOpen}
            setCoinSelected={setCoinSelected}
            assetClass={assetClass}
            search={search}
          />
        );
      case 'shares':
        return (
          <MarketTableShares
            streamOpen={streamOpen}
            setCoinSelected={setCoinSelected}
            assetClass={assetClass}
            search={search}
          />
        );
      case 'bonds':
        return (
          <MarketTableBonds
            streamOpen={streamOpen}
            setCoinSelected={setCoinSelected}
            assetClass={assetClass}
            active={active}
            search={search}
          />
        );
      case 'moneyMarkets':
        return (
          <MarketTableMoneyMarkets
            streamOpen={streamOpen}
            setCoinSelected={setCoinSelected}
            assetClass={assetClass}
            active={active}
            search={search}
          />
        );

      default:
        return '';
    }
  }

  const [loginModalOpen, setLoginModalOpen] = useState(false);

  if (!email) {
    history.push('/');
  }

  return (
    <Layout active="shares" className={classNames.layoutManagement} hideFooter>
      <div className={classNames.marketsPage}>
        <div className={classNames.pageWrap} ref={ref}>
          <div
            className={classNames.settingsNavbar}
            style={{ justifyContent: 'space-between' }}
          >
            <div
              className={classNames.settingsIcon}
              onClick={() => {
                setCoinSelected('');
                history.push('/shares');
              }}
            >
              <img src={fulllogo} alt="logo" style={{ height: '100%' }} />
            </div>
            <div className={classNames.searchSettings}>
              <div className={classNames.im}>
                <input
                  type="text"
                  placeholder="Im Looking For..."
                  onChange={(event) => setMarketSearch(event?.target?.value)}
                />
                <div
                  className={classNames.arroww}
                  style={{ cursor: 'pointer' }}
                >
                  <img
                    src={searchIcon}
                    alt=""
                    style={{ width: '20px', opacity: '0.25' }}
                  />
                </div>
              </div>
            </div>
            <div
              className={classNames.settingsListBtn}
              style={{
                background:
                  'linear-gradient(270deg, rgb(129, 203, 215) 0%, rgb(61, 67, 148) 100%)',
                fontWeight: '500',
              }}
              onClick={() => {
                setAssetClass('');
                history.push('/share/holdings');
              }}
            >
              My Holdings
            </div>
          </div>
          <div
            className={classNames.wSpace}
            style={{
              display: coinSelected || pathName?.length > 3 ? 'none' : '',
              padding: '0 3rem',
            }}
          >
            <div className={classNames.optionss} style={{ margin: '0' }}>
              {MARKET_ASSET_CLASSES.sort(GetSortOrder('marketOrder')).map(
                (assetClassCard, i) => (
                  <div
                    key={i}
                    className={`${classNames.eachOption} ${
                      assetClassCard?.label === assetClass?.label
                        ? classNames.eachOptionSeleted
                        : ''
                    }`}
                    onClick={() => {
                      if (assetClassCard.marketEnable) {
                        setAssetClass(assetClassCard);
                        if (assetClassCard.name === 'shares') {
                          history.push('/shares');
                        } else {
                          history.push(`/shares/${assetClassCard.name}`);
                        }
                      }
                    }}
                  >
                    <div>
                      <img
                        src={assetClassCard.miniLogo}
                        alt={assetClassCard.label}
                      />
                      <p>{assetClassCard?.label}</p>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
          <div className={classNames.pageContent}>
            {coinSelected?.coinSymbol || coin ? (
              <>
                <MarketAssetDetail
                  coin={coinSelected}
                  coinSymbol={coin}
                  onClose={() => {
                    setCoinSelected();
                    history.push(`/shares/${assetClass.name}`);
                  }}
                  assetClass={assetClass}
                  streamOpen={streamOpen}
                  setStreamOpen={setStreamOpen}
                />
                <TerminalsSidebar
                  shareToken={shareToken}
                  streamOpen={streamOpen}
                  setStreamOpen={setStreamOpen}
                />
              </>
            ) : (
              <div className={classNames.tableArea}>{selectTable()}</div>
            )}
          </div>
        </div>
        <div
          className={`${classNames.sidebarArea} ${
            classNames[Boolean(streamOpen)]
          }`}
          style={{ maxHeight: '100%' }}
        >
          <div
            className={classNames.sidebarContent}
            style={{ maxHeight: '100%' }}
          >
            {assetClassName === 'shares' && coin ? (
              <TerminalsSidebar
                shareToken={shareToken}
                streamOpen={streamOpen}
                setStreamOpen={setStreamOpen}
              />
            ) : assetClassName === 'bonds' &&
              selectedSidebarMenu === 'Bonds' ? (
              <BondContractDetails
                index={index}
                setIndex={setIndex}
                setSelectedSidebarMenu={setSelectedSidebarMenu}
              />
            ) : (
              <img
                className={classNames.fullLogo}
                src={SIDEBAR_FOOTERS[index].fullLogo}
                alt=""
              />
            )}
          </div>

          {(assetClassName === 'shares' && coin) ||
          (assetClassName === 'bonds' && selectedSidebarMenu === 'Bonds') ? (
            ''
          ) : (
            <div className={classNames.sidebarFooter}>
              {SIDEBAR_FOOTERS.map((menu, i) => (
                <div
                  className={`${classNames.footerMenu} ${
                    classNames[i === index]
                  } ${menu.disable && classNames.disable}`}
                  onClick={() => {
                    !menu.disable && setIndex(i);
                    setSelectedSidebarMenu(menu.label);
                  }}
                >
                  <img src={menu.icon} alt="" />
                  <span>{menu.label}</span>
                </div>
              ))}
            </div>
          )}
        </div>
        {loginModalOpen && (
          <LoginModal
            onClose={() => setLoginModalOpen(false)}
            onSuccess={() => setLoginModalOpen(false)}
          />
        )}
      </div>
    </Layout>
  );
}

export default MarketsPage;
