import React from 'react';
import { useCoinContextData } from '../../../context/CoinContext';
import BreadCrumb from '../../../Layout/LayoutSideBar/BreadCrumb';
import AddForexAction from './AddForexAction';
import { AddForexSuccess } from './AddForexSuccess';
import { BankDetails } from './BankDetails';
import { ConfirmQuote } from './ConfirmQuote';
import EnterAmountForTransfer from './EnterAmountForTransfer';
import { updateScroll } from '../../../config/constants';
import NewBreadcrumb from './NewBreadcrumb';

export const ConditionalForexComponents = () => {
  const {
    selectedTab,
    fundingCurrency,
    setFundingCurrency,
    setCurrentStep,
    setSelectedTab,
    payMethod,
    setPayMethod,
    otcDesk,
    setOtcDesk,
    enterAmount,
    setEnterAmount,
    setCoinAction,
    setAddApiValue,
    setToCurrencyApiValue,
    setCountryApiValue,
    setPayMethodApiValue,
    setOtcApiValue,
    setEachCardShowValue,
    setNewWholeValue,
    setFromCurrencyData,
    newWholeValue,
    currentStep,
  } = useCoinContextData();

  const nextPage = () => {
    if (!fundingCurrency) {
      setFundingCurrency(true);
      setCurrentStep('step2');
      setSelectedTab('fundingCurrency');
    } else if (!payMethod) {
      setCurrentStep('step3');
      setPayMethod(true);
      setSelectedTab('payMethod');
    } else if (!otcDesk) {
      setCurrentStep('step4');
      setOtcDesk(true);
      setSelectedTab('otcDesk');
    } else {
      setCurrentStep('step5');
      setEnterAmount(true);
      setSelectedTab('enterAmount');
    }
  };

  const previousPage = () => {
    if (enterAmount) {
      setEnterAmount(false);
      setSelectedTab('otcDesk');
      setCurrentStep('step4');
      updateScroll();
    } else if (otcDesk) {
      setOtcDesk(false);
      setSelectedTab('payMethod');
      setCurrentStep('step3');
      updateScroll();
    } else if (payMethod) {
      setPayMethod(false);
      setSelectedTab('fundingCurrency');
      setCurrentStep('step2');
      updateScroll();
    } else if (fundingCurrency) {
      setFundingCurrency(false);
      setSelectedTab('sendingCurrency');
      setCurrentStep('step1');
      updateScroll();
    } else if (selectedTab === 'sendingCurrency') {
      setCoinAction(['Menu']);
      setAddApiValue('');
      setToCurrencyApiValue('');
      setCountryApiValue('');
      setPayMethodApiValue('');
      setOtcApiValue('');
      setEachCardShowValue({});
      setNewWholeValue({
        step1: '',
        step2: '',
        step3: '',
        step4: '',
      });
      setFromCurrencyData({
        step1: '',
        step2: '',
        step3: '',
        step4: '',
      });
      setFundingCurrency(false);
      setPayMethod(false);
      setOtcDesk(false);
      setEnterAmount(false);
      setCurrentStep('step1');
      // setSelectedTab('sendingCurrency');
      setEachCardShowValue('step1');
      setNewWholeValue({
        ...newWholeValue,
        step2: '',
        step3: '',
        step4: '',
      });
      updateScroll();
    }
  };

  switch (selectedTab) {
    case 'otcDesk':
      var ques = 'Select OTCDesk';
      return (
        <AddForexAction
          ques={ques}
          nextPage={nextPage}
          previousPage={previousPage}
        />
      );
    case 'payMethod':
      var ques = 'Select Payment Method';
      return (
        <AddForexAction
          ques={ques}
          nextPage={nextPage}
          previousPage={previousPage}
        />
      );
    case 'fundingCurrency':
      var ques = 'What Country Are You Sending From?';
      return (
        <AddForexAction
          ques={ques}
          nextPage={nextPage}
          previousPage={previousPage}
        />
      );
    case 'enterAmount':
      return <EnterAmountForTransfer previousPage={previousPage} />;
    case 'confirmQuote':
      return <ConfirmQuote />;
    case 'addSuccess':
      return <AddForexSuccess />;
    case 'accountDetails':
      return <BankDetails />;
    default:
      return <AddForexAction nextPage={nextPage} />;
  }
};

export const ForexBreadCrumbs = () => {
  const { currentStep, selectedCoin, setCoinAction } = useCoinContextData();
  const handleBreadCrumb = () => setCoinAction(['Menu']);

  switch (currentStep) {
    case 'step1':
      var data = [
        { value: ' Sending Currency', clickable: 'step1' },
      ];
      return <NewBreadcrumb data={data} handleBreadCrumb={handleBreadCrumb} />;
    case 'step2':
      var data = [
        { value: 'Sending Currency', clickable: 'step2' },
        { value: 'Country', clickable: 'step2' },
      ];
      return <NewBreadcrumb data={data} handleBreadCrumb={handleBreadCrumb} />;
    case 'step3':
      var data = [
        { value: 'Country', clickable: 'step3' },
        { value: 'Payment Method', clickable: 'step3' },
      ];
      return <NewBreadcrumb data={data} handleBreadCrumb={handleBreadCrumb} />;
    case 'step4':
      var data = [
        // { value: 'Country' + ' ', clickable: 'step4' },
        { value: 'Payment Method', clickable: 'step4' },
        { value: 'OTCDesk', clickable: 'step4' },
      ];
      return <NewBreadcrumb data={data} handleBreadCrumb={handleBreadCrumb} />;
    case 'step5':
      var data = [
        { value: 'OTCDesk', clickable: 'step5' },
        { value: 'Amount', clickable: 'step5' },
      ];
      return <NewBreadcrumb data={data} handleBreadCrumb={handleBreadCrumb} />;
    case 'step6':
      var data = [
        { value: 'Amount', clickable: 'step6' },
        { value: 'Confirm Quote', clickable: 'step6' },
      ];
      return <NewBreadcrumb data={data} handleBreadCrumb={handleBreadCrumb} />;
    case 'step7':
      return null;
    default:
      return;
  }
};
