import React, { Fragment, useEffect, useState, useContext } from 'react';
import { useCoinContextData } from '../../../context/CoinContext';
import { BankContext } from '../../../context/Context';
import { useLoadBankAccountDetails } from './api';
import copy from '../../../static/images/sidebar-icons/copy.svg';
import { useAppContextDetails } from '../../../context/AppContext';
import { LoadingAnimation } from '../../LoadingAnimation';
import ToastMessage from '../../ToastMessage/ToastMessage';
import { useHistory } from 'react-router-dom';

export const BankDetails = () => {
  const [selectedImage, setSelectedImage] = useState();
  const [toast, setToast] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const {
    bankAccountId,
    setCoinAction,
    setCoinActionEnabled,
    setFundingCurrency,
    setPayMethod,
    setOtcDesk,
    setEnterAmount,
    setCurrentStep,
    setSelectedTab,
    setAddApiValue,
    setToCurrencyApiValue,
    setCountryApiValue,
    setPayMethodApiValue,
    setOtcApiValue,
    setEachCardShowValue,
    setNewWholeValue,
    newWholeValue,
    setSendBtnClicked,
    setAddForexCurrencyValue,
  } = useCoinContextData();
  const { appLogo, appFullLogo } = useAppContextDetails();
  const { admin, sidebarCollapse } = useContext(BankContext);
  const history = useHistory();

  useEffect(() => {
    if (toast) {
      setTimeout(() => setToast(false), 1000);
    }
  }, [toast]);

  const { data: bankAccountResponse, isLoading } =
    useLoadBankAccountDetails(bankAccountId);
  const bankerData = bankAccountResponse?.data?.bankAccounts_info?.[0];
  const bankAccountInfo = bankerData?.bank_information;
  const arrayOfFields =
    bankAccountInfo &&
    Object?.entries?.(bankAccountInfo)?.map((info) => info[1]);

  const handleCopyClick = (value) => {
    navigator.clipboard.writeText(value);
    setToast(true);
  };

  return (
    <>
      <div className="newSidebar" style={{ opacity: isLoading ? 0.25 : 1 }}>
        <div className="top-text">
          <img
            className="clickable"
            src={sidebarCollapse ? appLogo : appFullLogo}
            style={{ width: '80%', height: '80%' }}
            onClick={() => {
              if (admin) {
                // setAdminSidebar(true);
                // setChatOn(false);
                console.log('admin');
              } else {
                history.push('/app');
                setCoinAction(['Menu']);
                setCoinActionEnabled(false);
              }
            }}
          />
        </div>
        <div
          className="addForexAmountWrapper1 pb-3"
          style={{ opacity: isLoading ? 0.25 : 1 }}
        >
          {bankAccountInfo && (
            <Fragment>
              <div className="d-flex flex-column w-100 mb-1">
                <label
                  className="header mb-0"
                  htmlFor="accountName"
                  style={{ fontSize: '1.03rem', fontWeight: '450' }}
                >
                  Account Name
                </label>
                <div
                  className="add-amount-box mt-0"
                  style={{ width: '100%', marginLeft: '0' }}
                >
                  <div
                    className="div-amount-input"
                    style={{ paddingTop: '0px' }}
                  >
                    <input
                      className="add-input"
                      id="accountName"
                      type="text"
                      value={bankerData?.account_name}
                      style={{ fontWeight: '450' }}
                    />
                    <img
                      className="clickable-div"
                      src={copy}
                      alt="copy"
                      style={{ marginTop: '0px' }}
                      width={20}
                      onClick={() => handleCopyClick(bankerData?.account_name)}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column w-100 mb-1">
                <label
                  className="header mb-0"
                  htmlFor="paymentMethod"
                  style={{ fontSize: '1.03rem', fontWeight: '450' }}
                >
                  Payment Method
                </label>
                <div
                  className="add-amount-box mt-0"
                  style={{ width: '100%', marginLeft: '0' }}
                >
                  <div
                    className="div-amount-input"
                    style={{ paddingTop: '0px' }}
                  >
                    <img
                      src={bankerData?.paymentMethod_data?.icon}
                      alt="icon"
                      width={30}
                      height={30}
                      style={{ marginTop: '0px' }}
                    />
                    <input
                      className="add-input"
                      id="paymentMethod"
                      type="text"
                      value={bankerData?.paymentMethod_data?.name}
                      style={{ fontWeight: '450' }}
                    />
                    <img
                      className="clickable-div"
                      src={copy}
                      alt="copy"
                      width={20}
                      onClick={() =>
                        handleCopyClick(bankerData?.paymentMethod_data?.name)
                      }
                    />
                  </div>
                </div>
              </div>
              {arrayOfFields?.map((field, i) => {
                if (field?.valuetype === 'text') {
                  return (
                    <div className="d-flex flex-column w-100 mb-1">
                      <label
                        className="header mb-0"
                        htmlFor={`field${i}`}
                        style={{ fontSize: '1.03rem', fontWeight: '450' }}
                      >
                        {field?.name}
                      </label>
                      <div
                        className="add-amount-box mt-0"
                        style={{ width: '100%', marginLeft: '0' }}
                      >
                        <div
                          className="div-amount-input"
                          style={{ paddingTop: '0px' }}
                        >
                          <input
                            className="add-input"
                            id={`field${i}`}
                            type="text"
                            value={field?.value}
                            style={{ fontWeight: '450' }}
                          />
                          <img
                            className="clickable-div"
                            src={copy}
                            alt="copy"
                            width={20}
                            onClick={() => handleCopyClick(field?.value)}
                          />
                        </div>
                      </div>
                    </div>
                  );
                }
                return (
                  <div className="d-flex flex-column w-100 mb-1">
                    <div
                      className="d-flex flex-row justify-content-between"
                      style={{ width: '90%' }}
                    >
                      <label
                        className="header mb-0"
                        htmlFor={`field${i}`}
                        style={{ fontSize: '1.03rem', fontWeight: '450' }}
                      >
                        {field?.name}
                      </label>
                      <div
                        className="clickable-div expand"
                        onClick={() => {
                          setSelectedImage(i);
                          setShowImage(!showImage);
                        }}
                      >
                        {showImage ? 'Collapse' : 'Expand'}
                      </div>
                    </div>
                    <img
                      className={`pictureData ${
                        selectedImage === i && showImage
                      } mt-0`}
                      id={`field${i}`}
                      src={field?.value}
                      style={{ width: '90%' }}
                    />
                  </div>
                );
              })}
            </Fragment>
          )}
          {toast && (
            <ToastMessage
              textOne="Copied the Value"
              onClose={() => setToast(false)}
            />
          )}
        </div>
        <div
          className="newbtns-div"
          style={{ borderTop: '0.5px solid #e5e5e5' }}
        >
          <div className="top-btns">
            <div
              style={{
                background: 'white',
                width: '100%',
                color: '#343C5B',
                fontWeight: '500',
                borderRadius: '35px'
              }}
              onClick={() => {
                setCurrentStep('step7');
                setSelectedTab('addSuccess');
              }}
            >
              Back
            </div>
          </div>
          <div
            className="bottom-btn"
            style={{ background: '#FF5E5E', fontWeight: '500', borderRadius: '35px' }}
            onClick={() => {
              setCoinActionEnabled(false);
              setCoinAction(['Menu']);
              setFundingCurrency(false);
              setPayMethod(false);
              setOtcDesk(false);
              setEnterAmount(false);
              setCurrentStep('step1');
              setSelectedTab('sendingCurrency');
              setAddApiValue('');
              setToCurrencyApiValue('');
              setCountryApiValue('');
              setPayMethodApiValue('');
              setOtcApiValue('');
              setAddForexCurrencyValue('');
              setEachCardShowValue('step1');
              setNewWholeValue({
                ...newWholeValue,
                step2: '',
                step3: '',
                step4: '',
              });
              setSendBtnClicked('');
            }}
          >
            Close
          </div>
        </div>
      </div>

      {isLoading && (
        <div className="coin-address-loading">
          <LoadingAnimation icon={appLogo} width={200} />
        </div>
      )}
    </>
  );
};
