import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ListComponent } from '../../components/ListComponent';
import { updateScroll } from '../../config/constants';
import { useCoinContextData } from '../../context/CoinContext';
import { BankContext } from '../../context/Context';
import Layout from '../../Layout/Layout';
import { useLoadBankingData } from './api';
import './banking.scss';

export const Banking = () => {
  const history = useHistory();
  const { email } = useContext(BankContext);
  const {
    setPlaceholder,
    setAddBankingEnabled,
    setAddBankAccountStep,
    updateBankAccounts,
    setUpdateBankAccounts,
  } = useCoinContextData();
  const [searchedValue, setSearchedValue] = useState('');
  const { data: bankingDataResponse, isLoading } = useLoadBankingData(
    email,
    updateBankAccounts,
    setUpdateBankAccounts
  );
  const bankAccountsInfo = bankingDataResponse?.data?.bankAccounts_info;

  useEffect(() => {
    setPlaceholder('Search Bank Accounts');
  }, []);

  if (!email) {
    history.push('/');
  }

  return (
    <Layout active="banking" className="banking" hideFooter>
      <ListComponent
        isLoading={isLoading}
        leftHeaderData={<h6>Your Bank Accounts </h6>}
        leftSubHeader={
          <div
            className="refer"
            onClick={() => {
              setAddBankingEnabled(true);
              setAddBankAccountStep(1);
              updateScroll();
            }}
          >
            <h6>Add New Account</h6>
          </div>
        }
        listOfIcons={
          <div className="icons">
            <div className="toggle"></div>
            <div className="iconbox">
              <div className="icondiv allBanking clickable-div">All</div>
            </div>
            <div className="iconbox">
              <div className="icondiv"></div>
            </div>
            <div className="iconbox">
              <div className="icondiv"></div>
            </div>
            <div className="iconbox">
              <div className="icondiv"></div>
            </div>
            <div className="iconbox1">
              <div className="icondiv"></div>
            </div>
          </div>
        }
        searchedValue={searchedValue}
        setSearchedValue={setSearchedValue}
        bankingData={bankAccountsInfo}
      />
    </Layout>
  );
};
