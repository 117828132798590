/* eslint-disable react/jsx-one-expression-per-line */
import React, { useContext, useState } from 'react';
import { Redirect } from 'react-router-dom';
import Layout from '../Layout/Index';
import { BankContext } from '../context/Context';
import HeadTabs from '../components/HeadTabs/HeadTabs';
import NetWorthChart from '../components/NetWorthPage/NetWorthChart';
import NetWorthCards from '../components/NetWorthPage/NetWorthCards';

function NetWorthPage({ match }) {
  const [segment, setSegment] = useState(null);

  const { email } = useContext(BankContext);

  const [netWorthMobileOpen, setNetWorthMobileOpen] = useState(false);

  if (!email) {
    return <Redirect to="/" />;
  }
  return (
    <Layout active="networth" className="vault-content" hideFooter>
      <HeadTabs />
      <div className={`netWorthLayout ${netWorthMobileOpen}`}>
        <div className={`netWorthContent false`}>
          <NetWorthChart
            setNetWorthMobileOpen={setNetWorthMobileOpen}
            match={match}
            segment={segment}
            setSegment={setSegment}
          />
          <NetWorthCards segment={segment} setSegment={setSegment} />
        </div>
      </div>
    </Layout>
  );
}

export default NetWorthPage;
