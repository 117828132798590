import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useCoinContextData } from '../../../context/CoinContext';
import './addAccountStyles.scss';
const PaymentType = () => {
  const {
    addAccountStepName,
    setAddAccountStepName,
    selectedPaymentType,
    setSelectedPaymentType,
  } = useCoinContextData();

  const [allPaymentType, setAllPaymentType] = useState([]);

  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState('');

  const conditionalPageSwitch = (code) => {
    if (code === 'GPAY') {
      setAddAccountStepName('selectBank');
    } else if (code === 'BT') {
      setAddAccountStepName('selectCountry');
    }
    // else {
    //   setAddAccountStepName('selectBank');
    // }
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `https://comms.globalxchange.io/coin/vault/service/payment/methods/get`
      )
      .then(({ data }) => {
        setAllPaymentType(data.methods);
        setLoading(false);
      });
  }, []);

  return (
    <>
      <div className="addAccountContainer">
        <div style={{ textDecoration: 'underline', fontWeight: 'bold' }}>
          Payment Type
        </div>
        <div className="stepTitle">What Type Of Account Is It?</div>
        <div className="selectionWrapper">
          <input
            placeholder="Search Types..."
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          <div style={{ overflowY: 'scroll', height: '70vh' }}>
            {!loading
              ? allPaymentType
                  .filter((o) =>
                    o.name.toLowerCase().includes(query.toLowerCase())
                  )
                  .map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="listItem"
                        onClick={(e) => {
                          setSelectedPaymentType(item);
                          conditionalPageSwitch(item.code);
                        }}
                      >
                        <img src={item.icon} alt="" />
                        <div style={{}}>{item.name}</div>
                      </div>
                    );
                  })
              : Array(10)
                  .fill(' ')
                  .map((item, index) => {
                    return (
                      <div className="listItem" key={index}>
                        <Skeleton width={20} height={20} />
                        <Skeleton
                          width={100}
                          height={20}
                          style={{ marginLeft: 10 }}
                        />
                      </div>
                    );
                  })}
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentType;
