import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const Assetsvault = () => {
  return (
    <>
      <div className="assets-vault">
        <div className="img-txt">
          <div className="skl-img">
            <Skeleton height={40} width={40} borderRadius={50} />
          </div>
          <div className="skl-txt1">
            <Skeleton height={30} width={150} />
          </div>
        </div>
        <div className="skl-txt2">
          <Skeleton height={30} width={150} />
        </div>
      </div>
      <div className="assets-vault">
        <div className="img-txt">
          <div className="skl-img">
            <Skeleton height={40} width={40} borderRadius={50} />
          </div>
          <div className="skl-txt1">
            <Skeleton height={30} width={150} />
          </div>
        </div>
        <div className="skl-txt2">
          <Skeleton height={30} width={150} />
        </div>
      </div>
      <div className="assets-vault">
        <div className="img-txt">
          <div className="skl-img">
            <Skeleton height={40} width={40} borderRadius={50} />
          </div>
          <div className="skl-txt1">
            <Skeleton height={30} width={150} />
          </div>
        </div>
        <div className="skl-txt2">
          <Skeleton height={30} width={150} />
        </div>
      </div>
      <div className="assets-vault">
        <div className="img-txt">
          <div className="skl-img">
            <Skeleton height={40} width={40} borderRadius={50} />
          </div>
          <div className="skl-txt1">
            <Skeleton height={30} width={150} />
          </div>
        </div>
        <div className="skl-txt2">
          <Skeleton height={30} width={150} />
        </div>
      </div>
      <div className="assets-vault">
        <div className="img-txt">
          <div className="skl-img">
            <Skeleton height={40} width={40} borderRadius={50} />
          </div>
          <div className="skl-txt1">
            <Skeleton height={30} width={150} />
          </div>
        </div>
        <div className="skl-txt2">
          <Skeleton height={30} width={150} />
        </div>
      </div>
      <div className="assets-vault">
        <div className="img-txt">
          <div className="skl-img">
            <Skeleton height={40} width={40} borderRadius={50} />
          </div>
          <div className="skl-txt1">
            <Skeleton height={30} width={150} />
          </div>
        </div>
        <div className="skl-txt2">
          <Skeleton height={30} width={150} />
        </div>
      </div>
    </>
  );
};

export default Assetsvault;
