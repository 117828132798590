import React, { useState, useContext } from 'react';
import CountUp from 'react-countup';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router';
import { BankContext } from '../../../context/Context';

function AssetTable({ coinList, search, setCoinToDetail }) {
  const history = useHistory();
  const { updateInterval } = useContext(BankContext);
  const [duration, setDuration] = useState(2);
  const togleDuration = (duration) => {
    setDuration(duration === 2 ? 2.1 : 2);
  };

  return (
    <table className="asetPlatformTable asset">
      <thead className="tableHead">
        <tr>
          <th className="col-b">Asset</th>
          <th className="col-c">Price</th>
          <th className="col-d">24 Hour Change</th>
          <th className="col-e">Market Cap</th>
          <th className="col-f text-left">24 Hour Trading Volume</th>
        </tr>
      </thead>
      <tbody className="tableContent">
        {coinList?.length
          ? coinList
              .filter((coin) =>
                coin.coinName.toLowerCase().includes(search.toLowerCase())
              )
              .map((coin, i) => (
                <tr
                  key={coin._id}
                  onClick={() => {
                    setCoinToDetail(coin);
                    history.push(`/markets/currencies/${coin.coinSymbol}`);
                  }}
                >
                  <td className="coin col-b">
                    <div className="coin-name">
                      <img src={coin.coinImage} alt="" className="coinLogo" />{' '}
                      {coin.coinName}
                    </div>
                  </td>
                  <td className="annRate col-c">
                    $
                    <CountUp
                      onEnd={() => {
                        if (updateInterval)
                          setTimeout(() => {
                            togleDuration(duration);
                          }, updateInterval * 1000);
                      }}
                      duration={duration}
                      start={0}
                      end={coin.usd_price}
                      decimals={2}
                    />
                  </td>
                  <td className={`dayChange col-d ${0 > coin._24hrchange}`}>
                    <CountUp
                      duration={duration}
                      start={0}
                      end={coin._24hrchange}
                      decimals={2}
                    />
                    %
                  </td>
                  <td className="supply col-e">
                    $
                    <CountUp
                      duration={duration}
                      start={0}
                      end={coin.market_cap / 1000000000 || 0}
                      decimals={2}
                    />
                    B
                  </td>
                  <td className="chart col-f">
                    $
                    <CountUp
                      duration={duration}
                      start={0}
                      end={coin.volume_24hr / 1000000000 || 0}
                      decimals={2}
                    />
                    B
                  </td>
                </tr>
              ))
          : Array(7)
              .fill('')
              .map(() => (
                <tr>
                  <td className="rank col-a">
                    <Skeleton />
                  </td>
                  <td className="coin col-b">
                    <Skeleton className="coin-name" />
                  </td>
                  <td className="annRate col-c">
                    <Skeleton />
                  </td>
                  <td className={`dayChange col-d`}>
                    <Skeleton />
                  </td>
                  <td className="supply col-e">
                    <Skeleton />
                  </td>
                </tr>
              ))}
      </tbody>
    </table>
  );
}

export default AssetTable;
