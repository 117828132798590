import React from 'react'

const Timedata = () => {
  return (
    <div  className="assets-vault">
        <div className='days-txt'>180 Days</div>
        <div className='days-txt'>6 Months</div>
    </div>
  )
}

export default Timedata