import React, {
  useState,
  useContext,
  useRef,
  Fragment,
  createRef,
} from 'react';
import { VaultPageContext } from '../../../context/VaultPageContext';
import classNames from '../../../pages/VaultsPage/vaultsPage.module.scss';
import Scrollbars from 'react-custom-scrollbars';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';
import { YesterdayToday, FormatCurrency } from '../../../utils/FunctionTools';
import credit from '../../../static/images/txnIcons/credit.svg';
import debit from '../../../static/images/txnIcons/debit.svg';
import chat from '../../../static/images/txnIcons/chat.svg';
import edit from '../../../static/images/txnIcons/edit.svg';
import txnHash from '../../../static/images/txnIcons/txnHash.svg';
import OnOutsideClick from '../../../utils/OnOutsideClick';

function VaultTransactionsBonds({ email }) {
  const { txnListBonds: txnList, txnListLoadingBonds: txnListLoading } =
    useContext(VaultPageContext);
  const date = useRef();
  return (
    <Scrollbars className={classNames.vaultsView}>
      {txnListLoading
        ? Array(3)
            .fill('')
            .map((_, i) => (
              <>
                <Skeleton className={classNames.day} width={200} key={i} />
                {Array(4)
                  .fill('')
                  .map((_, i) => (
                    <div className={classNames.vaultItmWrap}>
                      <div className={classNames.vaultsItm}>
                        <Skeleton className={classNames.img} />
                        <div className={classNames.nameDate}>
                          <Skeleton className={classNames.name} width={350} />
                          <Skeleton className={classNames.date} width={300} />
                        </div>
                        <div className={classNames.credit}>
                          <Skeleton className={classNames.value} width={80} />
                        </div>
                        <div className={classNames.debit}>
                          <Skeleton className={classNames.value} width={80} />
                        </div>
                        <div className={classNames.balance}>
                          <Skeleton className={classNames.value} width={80} />
                        </div>
                      </div>
                    </div>
                  ))}
              </>
            ))
        : txnList?.map((txn) => {
            function sameDay() {
              if (moment(txn.timestamp).format('MMDDYYYY') === date.current) {
                return <></>;
              } else {
                date.current = moment(txn.timestamp).format('MMDDYYYY');
                return (
                  <div className={classNames.day}>
                    {YesterdayToday(txn.timestamp)}
                  </div>
                );
              }
            }
            return (
              <Fragment key={txn._id}>
                {sameDay()}
                <VaultItemBonds key={txn._id} txn={txn} />
              </Fragment>
            );
          })}
    </Scrollbars>
  );
}

export default VaultTransactionsBonds;

function VaultItemBonds({ txn }) {
  const [txnOpen, setTxnOpen] = useState(false);

  const elRefs = useRef([]);
  if (elRefs.current.length !== 3) {
    // add or remove refs
    elRefs.current = Array(3)
      .fill()
      .map((_, i) => elRefs.current[i] || createRef());
  }

  const refOut = useRef();
  OnOutsideClick(
    undefined,
    () => {
      setTxnOpen((txnOpen) => !txnOpen);
    },
    refOut,
    elRefs
  );

  return (
    <div
      className={`${classNames.vaultItmWrap} ${classNames[txnOpen]}`}
      ref={refOut}
    >
      <div className={classNames.vaultsItm}>
        <img
          src={txn?.withdraw ? credit : debit}
          alt=""
          className={classNames.img}
        />
        <div className={classNames.nameDate}>
          <div className={classNames.name}>
            {/* {txn?.withdraw
              ? `Withdrawal Of ${txn.coin} Earnings`
              : `${txn.coin} Bond Interest Payment`} */}
            {txn?.withdraw
              ? `Withdrawal Of Staking Rewards`
              : `Staking Contract Reward`}
          </div>
          <div className={classNames.date}>
            {moment(txn.timestamp).format('MMMM Do YYYY [at] h:mm:ss A zz')}
          </div>
        </div>
        <div className={classNames.buttons} ref={elRefs.current[0]}>
          <div className={classNames.btnNotes}>
            <span>Notes</span>
          </div>
          <div className={classNames.btnEdit}>
            <img src={edit} alt="" className={classNames.btnIcon} />
          </div>
          <div className={classNames.btnTxn}>
            <img src={txnHash} alt="" className={classNames.btnIcon} />
          </div>
          <div className={classNames.btnChat}>
            <img src={chat} alt="" className={classNames.btnIcon} />
          </div>
        </div>
        <div className={classNames.credit}>
          <span className={classNames.expand}>Expand</span>
          <span className={classNames.value}>
            {FormatCurrency(
              txn?.deposit ? txn?.credited_interest : 0,
              txn?.coin
            )}
          </span>
        </div>
        <div className={classNames.debit}>
          <span className={classNames.expand}>Expand</span>
          <span className={classNames.value}>
            {FormatCurrency(txn?.withdraw ? txn?.amount : 0, txn?.coin)}
          </span>
        </div>
        <div className={classNames.balance}>
          <span className={classNames.expand}>Expand</span>
          <span className={classNames.value}>
            {FormatCurrency(txn?.updated_vault_balance, txn?.coin)}
          </span>
        </div>
      </div>
    </div>
  );
}
