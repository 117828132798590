import React from 'react';
import userIcon from '../../static/images/clipIcons/userIcon.svg';

function GetStartedBrokerDetail({
  setStepName,
  logo,
  brokerDetails,
  setBrokerEmailOrId,
}) {
  return (
    <div className="stepWrapper stepForm">
      <img src={logo} alt="assetLogo" className="assetLogo" />
      <div className="stepDesc">Is This Your Affiliate?</div>
      <div className="userDetail">
        <div className="user">
          <img src={brokerDetails.profile_img} alt="" className="profileImg" />
          <div className="name">
            {brokerDetails.first_name} {brokerDetails.last_name}
          </div>
          <div className="email">{brokerDetails.email}</div>
        </div>
        <div className="bio">
          <div className="bioDesc">{brokerDetails.bio}</div>
        </div>
      </div>
      <div className="btns">
        <div
          className="btnBig"
          onClick={() => {
            setStepName('byBroker');
            setBrokerEmailOrId('');
          }}
        >
          No It Is Not
        </div>
        <div className="btnNext w50" onClick={() => setStepName('signUpForm')}>
          Yes It Is
        </div>
      </div>
    </div>
  );
}

export default GetStartedBrokerDetail;
