import React, { useContext } from 'react';
import { NetWorthContext } from '../../context/NetWorthContext';
import HeadTabItem from './HeadTabItem';
import iceLogo from '../../static/images/logo.svg';
import backDouble from '../../static/images/backDouble.svg';
import { BankContext } from '../../context/Context';
function HeadTabs() {
  const { tabData } = useContext(NetWorthContext);
  return (
    <div className="headTabsWrapper">
      {Object.keys(tabData).map((key) => {
        return <HeadTabItem key={key} index={key} />;
      })}
     
    </div>
  );
}

export default HeadTabs;
