import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';
import classNames from '../marketsPage.module.scss';
import clock from '../../../static/images/marketsSidebar/clock.svg';
import angleDown from '../../../static/images/angleDown.svg';
import { FormatCurrency, FormatNumber } from '../../../utils/FunctionTools';
import { useAppContextDetails } from '../../../context/AppContext';
import { useShareTokensList } from '../../../queryHooks';

function MarketTableShares({
  streamOpen,
  setStreamOpen,
  setCoinSelected,
  assetClass,
  search,
}) {
  const history = useHistory();
  const { data: sharesList = [], isLoading } = useShareTokensList();
  const [filterOpen, setFilterOpen] = useState(false);
  const { appCurrencySymbol, marketSearch } = useAppContextDetails();

  // console.log(sharesList, 'bondsData marketTableShares');

  // useEffect(() => {
  //   setStreamOpen(true);
  // }, []);

  return (
    <div className={classNames.marketTable}>
      <div
        className={`${classNames.header} ${classNames[!Boolean(streamOpen)]}`}
      >
        <div className={classNames.assets} style={{ minWidth: '30%' }}>
          Company
        </div>
        <div className={classNames.price} style={{ minWidth: '20%' }}>
          Price
        </div>
        {/* <div className={classNames.roiPercent}>24 Hr Change</div> */}
        <div className={classNames.supply} style={{ minWidth: '25%' }}>
          Supply
        </div>
        <div className={classNames.marCap} style={{ minWidth: '20%' }}>
          Market Cap
        </div>
        {/* <div className={classNames.volume}>Round</div> */}
        <img
          className={`${classNames.btnFilter} ${
            classNames[filterOpen.toString()]
          }`}
          src={angleDown}
          alt=""
          onClick={() => setFilterOpen(!filterOpen)}
        />
      </div>
      {filterOpen && (
        <div className={classNames.filtersWrap}>
          <div className={classNames.filters}>
            <div className={classNames.filter}>
              <img src={clock} alt="" />
              <span>24 Hrs</span>
            </div>
            <div className={classNames.filter}>
              <img
                src={
                  'https://apimachine-s3.s3.us-east-2.amazonaws.com/coinImages/rupee.png'
                }
                alt=""
              />
              <span>{appCurrencySymbol}</span>
            </div>
            <div className={classNames.filter}>
              + <span>Add Filter</span>
            </div>
          </div>
        </div>
      )}
      <div
        className={classNames.marketsList}
        style={{ height: 'calc(100% - 12% - 50px)', overflowY: 'scroll' }}
      >
        {isLoading
          ? Array(8)
              .fill('')
              .map((_, i) => (
                <div
                  className={`${classNames.marketItem}  ${
                    classNames[!Boolean(streamOpen)]
                  }`}
                  key={i}
                >
                  <Skeleton width={200} />
                  <Skeleton width={200} />
                  <Skeleton width={200} />
                  <Skeleton width={200} />
                  <Skeleton width={200} />
                </div>
              ))
          : sharesList
              ?.filter(
                (path) =>
                  path?.token_profile_data?.coinSymbol
                    ?.toLowerCase()
                    .includes(marketSearch?.toLowerCase()) ||
                  path?.token_profile_data?.coinName
                    ?.toLowerCase()
                    .includes(marketSearch?.toLowerCase())
              )
              ?.map((path, i) => (
                <div
                  className={`${classNames.marketItem}  ${
                    classNames[!Boolean(streamOpen)]
                  }`}
                  key={path._id}
                  onClick={() => {
                    setCoinSelected({
                      coinSymbol: path?.token_profile_data?.coinSymbol,
                      coinName: path?.token_profile_data?.coinName,
                      coinImage: path?.token_profile_data?.coinImage,
                    });
                    history.push(
                      `/shares/${assetClass.name}/${path?.token_profile_data?.coinSymbol}`
                    );
                    // setStreamOpen(true);
                  }}
                >
                  <div
                    className={classNames.assets}
                    style={{ minWidth: '30%' }}
                  >
                    <img src={path?.token_profile_data?.coinImage} alt="" />
                    <div className={classNames.names}>
                      <span className={classNames.name}>
                        {path?.token_profile_data?.coinName}
                      </span>
                      <div className={classNames.symbol}>
                        {path?.token_profile_data?.coinSymbol}
                        <img src={path?.asset_metaData?.coinImage} alt="" />
                        <strong>{path?.asset_metaData?.coinName}</strong>
                      </div>
                    </div>
                  </div>
                  <div className={classNames.price} style={{ minWidth: '20%' }}>
                    {/* {appCurrencySymbol}&nbsp; */}
                    {FormatCurrency(path?.token_price, appCurrencySymbol)}
                  </div>
                  {/* <div
                    className={`${classNames.roiPercent} ${classNames[0 < 0]}`}
                    style={{ opacity: 0.4 }}
                  >
                    {FormatNumber(0, 2)}%
                  </div> */}
                  <div
                    className={classNames.supply}
                    style={{ minWidth: '25%' }}
                  >
                    {FormatNumber(path?.token_totalSupply)}&nbsp;
                  </div>
                  <div
                    className={classNames.marCap}
                    style={{ minWidth: '20%' }}
                  >
                    {/* {appCurrencySymbol}&nbsp; */}
                    {FormatCurrency(path?.asset_balance, appCurrencySymbol)}
                  </div>
                  {/* <div className={classNames.volume}>
                    <div className={classNames.value}>
                      {path?.pathSeries && path?.pathSeries[0]?.seriesName}
                    </div>
                    <div className={classNames.symbol}>
                      {path?.tokenHoldersCount} Share Holders
                    </div>
                  </div> */}
                </div>
              ))}
      </div>
    </div>
  );
}

export default MarketTableShares;
