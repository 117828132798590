import React from "react";

const Asset = () => {
  return (
    <div>
      <p>Select Asset</p>
      <div className="text-size">For Your Bond Purchase</div>
      <input
        type="text"
        className="asset-select-input"
        placeholder="Search Assets.."
      />
    </div>
  );
};

export default Asset;
