import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useRef,
} from 'react';
import Axios from 'axios';
import ioClient from 'socket.io-client';
import { BankContext } from './Context';
import { SUPPORT_CHAT_URL } from '../config/index';

export const SupportChatContext = createContext();
function SupportChatContextProvider({ children }) {
  const { email, token } = useContext(BankContext);

  const [chatUserObject, setChatUserObject] = useState({});

  const [messageArray, setMessageArray] = useState([]);

  const [threadId, setThreadId] = useState('');
  // const [fileName, setFileName] = useState('');

  const [chatSection, setChatSection] = useState(false);
  const [groupReadUnreadList, setGroupReadUnreadList] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [typingFlag, setTypingFlag] = useState(false);

  useEffect(() => {
    if (email && token) {
      Axios.post(`${SUPPORT_CHAT_URL}get_user`, {
        email,
        token,
      }).then((res) => {
        if (res.data.status) {
          setChatUserObject({
            username: res.data.payload.username,
            timestamp: res.data.payload.timestamp,
            email: res.data.payload.email,
          });
        }
      });
    }
  }, [email, token]);

  const getAdminsAndCheck = async () => {
    socketRef.current.emit(
      'get_user_support_group_interaction_list',
      chatUserObject,
      (response) => {
        console.log('get_user_support_group_interaction_list', response);
        setSelectedGroup(response[0].group_name);
        setGroupReadUnreadList([...response]);
        socketRef.current.emit(
          'get_support_group_chat_history',
          response[0].group_name,
          response[0].thread_id,
          (socketResp) => {
            console.log('All messages', socketResp);
            setThreadId(response[0].thread_id);
            setChatSection(true);
            setMessageArray([...socketResp.reverse()]);
          }
        );
      }
    );
    // }
  };

  const socketRef = useRef();
  const userSocketRef = useRef();
  useEffect(() => {
    if (chatUserObject && chatUserObject.username) {
      socketRef.current = ioClient('https://chatsapi.globalxchange.io', {
        query: {
          email: email,
          token: token,
          tokfortest: 'nvestisgx',
        },
      });
      userSocketRef.current = ioClient(
        'https://chatsapi.globalxchange.io/user',
        {
          query: {
            email: email,
            token: token,
            tokfortest: 'nvestisgx',
          },
        }
      );
      getAdminsAndCheck();
    }
    return () => {
      userSocketRef &&
        userSocketRef.current &&
        userSocketRef.current.off('get_online_user_list');
    };
  }, [chatUserObject, email, token]);

  useEffect(() => {
    userSocketRef &&
      userSocketRef.current &&
      userSocketRef.current.on('new_message_notify', (data) => {
        // write code to put dot on user if receiver is cuerrentuser

        if (groupReadUnreadList.length > 1) {
          console.log(data);
          if (data.group) {
            let tempArr = [...groupReadUnreadList];
            const objIndex = tempArr.findIndex(
              (obj) => obj.group_name === data.receiver
            );
            tempArr[objIndex].sender = data.sender;

            let newArr = [
              ...tempArr.filter((o) => o.group_name === data.receiver),
            ];
            tempArr = tempArr.filter((obj) => obj.group_name !== data.receiver);
            newArr = [...newArr, ...tempArr];
            console.log(newArr);
            setGroupReadUnreadList([...newArr]);

            console.log(tempArr);
          }
        }
      });
    return () => {
      userSocketRef &&
        userSocketRef.current &&
        userSocketRef.current.off('new_message_notify');
    };
  }, [groupReadUnreadList]);

  // useEffect to  update the message list when the other party types a new message.
  useEffect(() => {
    socketRef &&
      socketRef.current &&
      socketRef.current.on('msg_notify', (data) => {
        console.log(data);
        if (data.thread_id === threadId) {
          console.log(messageArray);
          if (messageArray[messageArray.length - 1] !== data) {
            setMessageArray([...messageArray, data]);
          }
        }
      });
    return () => {
      socketRef && socketRef.current && socketRef.current.off('msg_notify');
    };
  }, [messageArray, threadId]);

  // useEffect to notify that other party which the current user is interaction is typing
  useEffect(() => {
    socketRef &&
      socketRef.current &&
      socketRef.current.on('someone_typing', (typingUser, socketThreadId) => {
        if (socketThreadId === threadId) {
          console.log(typingUser, ` typing`);
          setTypingFlag(true);
          setTimeout(() => {
            setTypingFlag(false);
          }, 2800);
        } else {
          setTypingFlag(false);
        }
      });
    return () => {
      socketRef && socketRef.current && socketRef.current.off('someone_typing');
    };
  }, [threadId]);

  const handleSubmitMessage = (inputText, setInputText) => {
    if (inputText.length > 0) {
      const tempArr = [...groupReadUnreadList];
      const objIndex = tempArr.findIndex(
        (obj) => obj.group_name === selectedGroup
      );
      tempArr[objIndex].last_messaged_user = chatUserObject.username;
      // console.log(tempArr);
      setGroupReadUnreadList(tempArr);

      const notificationData = {
        sender: chatUserObject.username,
        receiver: selectedGroup,
        group: true,
      };

      userSocketRef.current.emit('new_group_message', notificationData);

      socketRef.current.emit(
        'new_support_group_message',
        JSON.stringify({
          message: inputText,
          thread_id: threadId,
          sender: chatUserObject.username,
          timestamp: Date.now(),
        }),
        (response) => {
          if (response === 'success') {
            // console.log(response);
            setMessageArray([
              ...messageArray,
              {
                message: inputText,
                threadId,
                sender: chatUserObject.username,
                timestamp: Date.now(),
              },
            ]);
            setInputText('');

            let tempArr1 = [...groupReadUnreadList];

            const objI = tempArr1.findIndex(
              (obj) => obj.group_name === selectedGroup
            );
            tempArr1[objI].sender = chatUserObject.username;

            let newArr = [
              ...tempArr1.filter((o) => o.group_name === selectedGroup),
            ];
            tempArr1 = tempArr1.filter(
              (obj) => obj.group_name !== selectedGroup
            );
            newArr = [...newArr, ...tempArr1];
            // console.log(newArr);
            setGroupReadUnreadList([...newArr]);
          } else {
            // console.log(response);
          }
        }
      );
    } else {
      // message.info('Please type something!');
    }
  };

  return (
    <SupportChatContext.Provider
      value={{
        messageArray,
        setMessageArray,
        typingFlag,
        chatUserObject,
        socketRef,
        handleSubmitMessage,
        threadId,
      }}
    >
      {children}
    </SupportChatContext.Provider>
  );
}

export default SupportChatContextProvider;
