import Axios from 'axios';
import React, { useContext, useState, useEffect } from 'react';
import defiLogo from '../../../static/images/logoIcons/defi.svg';
import Scrollbars from 'react-custom-scrollbars';

import compound from '../../../static/images/marketLogos/compound.svg';
import curve from '../../../static/images/marketLogos/curve.svg';
import maker from '../../../static/images/marketLogos/maker.svg';
import mortage from '../../../static/images/marketLogos/mortage.svg';
import uniswap from '../../../static/images/marketLogos/uniswap.svg';
import CreateDefiTrustWrapper from '../../CreateDefiTrust/CreateDefiTrustWrapper';
import useWindowDimensions from '../../../utils/WindowSize';
import IceTrustMobile from './IceTrustMobile';
import TrustComponent from '../../TrustComponent/TrustComponent';
import { BankContext } from '../../../context/Context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import ReserveRequirements from './ReserveRequirements';
import { useAppContextDetails } from '../../../context/AppContext';

function IceTrust() {
  const { email } = useContext(BankContext);
  const [openDefiLogin, setOpenDefiLogin] = useState(false);
  const { width } = useWindowDimensions();
  const [showTrust, setShowTrust] = useState(true);
  const [logged, setLogged] = useState(false);
  const [basicData, setBasicData] = useState({});
  const { appName } = useAppContextDetails();

  useEffect(() => {
    email &&
      Axios.post(
        `https://comms.globalxchange.io/coin/iced/liquid/icetray/user/interest/data/get`,
        {
          email,
        }
      ).then(({ data }) => {
        if (data?.status) {
          setLogged(data.icetray_user);
          setBasicData(data);
        }
      });
  }, [email]);

  const [more, setMore] = useState('');
  return (
    <>
      {logged && showTrust ? (
        <TrustComponent
          basicData={basicData}
          setShowTrust={setShowTrust}
          openReqModal={() => setMore(2)}
        />
      ) : width > 768 ? (
        <>
          <div className="iceTrust">
            <div className="heading">
              <img src={defiLogo} alt="" />
              EFI TRUST
            </div>
            <p className="iceDesc">
              A DEFI (Decentralized Finance) Trust Is A Programmticaly Issued
              Storge Protocol Which Allows You Hold An Array Of Financial Assets
              Within A Vault Connected To MoneyMarkets.io Allowing You To
              Generate Preferred Interest Rates For Your Liquid Assets.
            </p>
            <Scrollbars
              className="sectionsWrapper"
              renderView={(props) => (
                <div {...props} className="sectionsList" />
              )}
              renderThumbHorizontal={() => <div />}
              renderThumbVertical={() => <div />}
            >
              <div className={`selectionItem ${more === 1}`}>
                <div className="titleSection">
                  How Does It Work?{' '}
                  <FontAwesomeIcon
                    icon={more === 1 ? faCaretUp : faCaretDown}
                    onClick={() => {
                      if (more === 1) {
                        setMore('');
                      } else {
                        setMore(1);
                      }
                    }}
                  />
                </div>
                <p className="text-base ">
                  {appName} Integrates With Apps Supported Vault Powered
                  Wallets. Vault Powered Wallets Provides Support For Crypto &
                  Fiat Assets And Directly Connects Them To The MoneyMarkets.io
                  DEFI Aggregator Protocol. This Connection Allows The End Users
                  Of Any Vault Powered Wallet To Earn Daily Interest On All
                  Assets Without Any Lockups, Restrictions, Or Commitments.{' '}
                  <b className="read-more" onClick={() => setMore(1)}>
                    Read More
                  </b>
                </p>
                <p className="text-more">
                  When You Login To Your {appName} Account You Are Able To
                  Visualize, Optimize, And Manage All The Assets You Are Holding
                  Accross Any Of The Vault Powered Wallet Applications. The
                  DEFITrust Therefore Allows You To Generate Greater Returns On
                  All The Liquid Assets Without Having To Move, Add, Or Invest
                  Those Holdings.
                </p>
                <p className="text-more">
                  All You Have To Do Is Create A DEFI Trust Below And Allocate A
                  Trust Reserve. Right Now Trust Reserves Can Be Held In Bitcoin
                  But Other Reserve Assets Will Be Added Over Time. Your Trust
                  Reserve Balance Determines The DEFITrust Tier. There Are
                  Twelve Tiers Which A DEFITrust Can Qualify For. Each Of These
                  Tiers Increases The Daily Interest Rate That You Receive For
                  All Your Liquid Assets.{' '}
                </p>
              </div>
              <div className={`selectionItem`}>
                <div className="titleSection">
                  DEFITrust Reseve Requirements
                </div>
                <p className="text-base ">
                  The Reserve Assets For Your DEFI Trust Dictate The Earning
                  Power Of The Rest Of Your Liquid Assets. The Reserve Assets
                  Are Held In A Liquid Vault Controlled By Your Email Like Any
                  Other Liquid Vault. You Can Freely Move Funds In & Out Of The
                  Reseve Pool. The DEFIMultiplier Is Computed From The Balance
                  In Your Reserve At 11:59 PM EST The Previous Day.{' '}
                  <b
                    className="read-more"
                    onClick={() => {
                      if (more === 2) {
                        setMore('');
                      } else {
                        setMore(2);
                      }
                    }}
                  >
                    Reserve Requirements
                  </b>
                </p>
              </div>
              <div className={`selectionItem ${more === 3}`}>
                <div className="titleSection">
                  Self-Managed DEFITrusts
                  <FontAwesomeIcon
                    icon={more === 3 ? faCaretUp : faCaretDown}
                    onClick={() => {
                      if (more === 3) {
                        setMore('');
                      } else {
                        setMore(3);
                      }
                    }}
                  />
                </div>
                <p className="text-base ">
                  Self Managed DEFITrust Is A Completely Decentralized Version
                  Of The DEFITrust Protocol Which Allows Users Of Non Vault
                  Powered Wallets To Manage Their Existing DEFI Investments
                  Accorss Multiple Smart Contracts In One Place. The Self
                  Managed DEFITrusts Will Allow Interoperability Between
                  Ethereum Based Assets & In The Future Will Also Be Supporting
                  Off Chain Assets.{' '}
                  <b className="read-more" onClick={() => setMore(3)}>
                    Read More
                  </b>
                </p>
                <div className="whatBridgeSupport">
                  <div className="listsContainer">
                    <Scrollbars
                      className="scrollList"
                      renderThumbHorizontal={() => <div />}
                      renderThumbVertical={() => <div />}
                      renderView={(props) => (
                        <div {...props} className="scrollListView" />
                      )}
                    >
                      <div className="head">Blockchain Based Protocols</div>
                      <div
                        className="marketCard"
                        style={{
                          background: '#1AAB9A',
                          color: 'white',
                        }}
                      >
                        <img src={maker} alt="" />
                        MakerDAO
                      </div>
                      <div
                        className="marketCard"
                        style={{
                          background: '#0AD394',
                          color: 'white',
                        }}
                      >
                        <img src={compound} alt="" />
                        Compound
                      </div>
                      <div
                        className="marketCard"
                        style={{
                          background: '#DC6BE5',
                          color: 'white',
                        }}
                      >
                        <img src={uniswap} alt="" />
                        Uniswap
                      </div>
                      <div
                        className="marketCard"
                        style={{
                          background: '#3465A4',
                          color: 'white',
                        }}
                      >
                        <img src={curve} alt="" />
                        Curve
                      </div>
                    </Scrollbars>
                    <Scrollbars
                      className="scrollList"
                      renderThumbHorizontal={() => <div />}
                      renderThumbVertical={() => <div />}
                      renderView={(props) => (
                        <div {...props} className="scrollListView" />
                      )}
                    >
                      <div className="head">
                        Traditional Debt Instruments (Coming Soon)
                      </div>
                      <div className="marketCard">
                        <img src={mortage} alt="" />
                        Mortage
                      </div>
                    </Scrollbars>
                  </div>
                </div>
              </div>
            </Scrollbars>
          </div>
          {logged ? (
            <div
              className="footer-main bridge-footer"
              onClick={() => setShowTrust(true)}
            >
              Go To My DEFITrust
            </div>
          ) : (
            <div
              className="footer-main bridge-footer"
              onClick={() => setOpenDefiLogin(true)}
            >
              Create DEFI Assets Trust
            </div>
          )}
          {openDefiLogin ? (
            <CreateDefiTrustWrapper
              setOpenDefiLogin={setOpenDefiLogin}
              onLogin={() => {
                setOpenDefiLogin(false);
                setLogged(true);
              }}
            />
          ) : (
            ''
          )}
        </>
      ) : (
        <IceTrustMobile />
      )}
      {more === 2 && <ReserveRequirements setOpenModal={setMore} />}
    </>
  );
}

export default IceTrust;
