import React, { Component } from "react";
import Scrollbars from "react-custom-scrollbars";
import ReactPlayer from "react-player";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import Skeleton from "react-loading-skeleton";

import "./index.css";
import videHolder from "./videoHolder.svg";
import assetIoTv from "./assetIoTv.svg";

export class VideoPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = { showOn: true, tab: "About", videoList: [], showFull: false };
  }

  componentDidMount() {
    Axios.get(
      `https://fxagency.apimachine.com/video/navbar/${this.props.navbar}`
    ).then(({ data }) => {
      if (data.status && data.data) {
        this.setState({ videoList: data.data });
      }
    });
  }
  render() {
    const { videoShow, setVideoShow } = this.props;
    const { showOn, tab, videoList, showFull } = this.state;
    return (
      <div className={`videoPlayerSidebar ${Boolean(showOn)}`}>
        <div className="head">
          <img src={assetIoTv} alt="" />
          <div className="live">
            <FontAwesomeIcon className="icon" icon={faCircle} />
            LIVE
          </div>
        </div>
        <div className="vh">
          <img src={videHolder} alt="" />
          <ReactPlayer
            key={videoShow?.videoUrl}
            className="videoBox"
            light={videoShow?.image}
            width="100%"
            height="100%"
            url={videoShow?.videoUrl}
            playing={true}
            controls
            stopOnUnmount={false}
            onEnablePIP={() => this.setState({ showOn: false })}
            onDisablePIP={() => this.setState({ showOn: true })}
          />
        </div>
        <div className="tabs">
          <div
            className={`tab ${tab === "About"}`}
            onClick={() => this.setState({ tab: "About" })}
          >
            About
          </div>
          <div
            className={`tab ${tab === "Actions"}`}
            onClick={() => this.setState({ tab: "Actions" })}
          >
            Actions
          </div>
          <div
            className={`tab ${tab === "Related"}`}
            onClick={() => this.setState({ tab: "Related" })}
          >
            Related
          </div>
          <div
            className={`tab ${tab === "Engage"}`}
            onClick={() => this.setState({ tab: "Engage" })}
          >
            Engage
          </div>
        </div>
        <div className="content">
          <div
            className={`profile ${showFull}`}
            onClick={() => this.setState({ showFull: !showFull })}
          >
            <img
              className="user"
              src="https://randomuser.me/api/portraits/women/50.jpg"
              alt=""
            />
            <div className="texts">
              <div className="headTitle">{videoShow?.title}</div>
              <div className="uname">Shorupan P</div>
            </div>
          </div>

          <p className="desc">{videoShow?.desc}</p>
          <div className="headTitle playListTitle">About Playlists</div>
          <Scrollbars
            className="videoListWrap"
            renderView={(props) => <div {...props} className="videoList" />}
            renderThumbHorizontal={() => <div />}
            renderThumbVertical={() => <div />}
          >
            {videoList.map((videoItm) => (
              <VideoListItem
                key={videoItm.id}
                videoItm={videoItm}
                setVideoShow={setVideoShow}
              />
            ))}
          </Scrollbars>
        </div>
        <div className="footer">
          <div className="channel">Channels</div>
          <Scrollbars
            className="channelList"
            renderThumbHorizontal={() => <div />}
            renderThumbVertical={() => <div />}
          >
            <img
              className="user true"
              src="https://randomuser.me/api/portraits/women/50.jpg"
              alt=""
            />
            <img
              className="user"
              src="https://randomuser.me/api/portraits/women/50.jpg"
              alt=""
            />
            <img
              className="user"
              src="https://randomuser.me/api/portraits/women/50.jpg"
              alt=""
            />
            <img
              className="user"
              src="https://randomuser.me/api/portraits/women/50.jpg"
              alt=""
            />
            <img
              className="user"
              src="https://randomuser.me/api/portraits/women/50.jpg"
              alt=""
            />
            <img
              className="user"
              src="https://randomuser.me/api/portraits/women/50.jpg"
              alt=""
            />
            <img
              className="user"
              src="https://randomuser.me/api/portraits/women/50.jpg"
              alt=""
            />
            <img
              className="user"
              src="https://randomuser.me/api/portraits/women/50.jpg"
              alt=""
            />
            <img
              className="user"
              src="https://randomuser.me/api/portraits/women/50.jpg"
              alt=""
            />
            <img
              className="user"
              src="https://randomuser.me/api/portraits/women/50.jpg"
              alt=""
            />
          </Scrollbars>
        </div>
      </div>
    );
  }
}

export class VideoListItem extends Component {
  constructor(props) {
    super(props);
    this.state = { videoUrl: "" };
  }

  componentDidMount() {
    Axios.post(
      "https://vod-backend.globalxchange.io/get_user_profiled_video_stream_link",
      {
        video_id: this.props?.videoItm?.video,
      }
    ).then(({ data }) => this.setState({ videoUrl: data }));
  }
  render() {
    const { videoItm, setVideoShow } = this.props;
    const { videoUrl } = this.state;
    return (
      <div className="vidItm">
        <div className="vhSmall">
          <img className="vhHolder" src={videHolder} alt="" />
          {videoUrl ? (
            <img
              className="videoThumb"
              src={videoItm.image}
              width="100%"
              height="100%"
              alt=""
              onClick={() =>
                setVideoShow({
                  ...videoItm,
                  videoUrl,
                })
              }
            />
          ) : (
            <Skeleton className="videoThumb" width="100%" height="100%" />
          )}
        </div>
        <div className="textContent">
          <div className="cardTitle">{videoItm.title}</div>
          <div className="cardDesc">{videoItm.desc}</div>
        </div>
      </div>
    );
  }
}

export default VideoPlayer;
