import React, { useState, useEffect, useContext } from 'react';
import './bondsData.scss';

import axios from 'axios';
import { useAppContextDetails } from '../../../context/AppContext';
import { BankContext } from '../../../context/Context';
import Skeleton from 'react-loading-skeleton';

const BondsData = ({ selectedCurrency }) => {
  const { email, token, setSidebarCollapse } = useContext(BankContext);
  const {
    ownerEmail,
    appColorCode,
    setActionFlow,
    selectedBond,
    setSelectedBond,
  } = useAppContextDetails();
  const [allBonds, setAllBonds] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedCurrency !== null && selectedCurrency !== undefined) {
      console.log(selectedCurrency, 'ljewhlfwheflwef');
      getallBonds(selectedCurrency);
    }
  }, [selectedCurrency]);

  const getallBonds = (item) => {
    setLoading(true);
    axios
      .get(
        `https://comms.globalxchange.io/coin/iced/banker/custom/bond/list/data/get`,
        {
          params: {
            user_email: email,
            email: ownerEmail,
            // email: 'shorupan@indianinvestor.com',
            coin: item?.coin,
          },
        }
      )
      .then(({ data }) => {
        console.log(data, email, ownerEmail, item, 'wkjebdljkwebf');
        setAllBonds([...data.bondsListData]);
        setLoading(false);
      });
  };

  return (
    <>
      {!loading ? (
        allBonds.map((item) => {
          return (
            <div className="bondWrapper">
              <div>
                <div className="bondRowStyleTop">
                  <div>{item.months.toFixed(0)} Months</div>
                  <div>{item.coinsData[0].bondCost}</div>
                </div>
                <div className="bondRowStyle1">
                  <div>{item.days} Days</div>
                  <div>${item.coinsData[0].bondCost_usd.toFixed(2)}</div>
                </div>
              </div>

              <div>
                <div className="bondRowStyle">
                  <div>Daily Earnings</div>
                  <div>Daily Rate</div>
                </div>
                <div className="bondRowStyle1">
                  <div>{item.coinsData[0].perDayEarnings.toFixed(5)}</div>
                  <div>{item.daily_interest_rate.toFixed(5)}%</div>
                </div>
              </div>

              <div>
                <div className="bondRowStyle">
                  <div>Monthly Earnings</div>
                  <div>Monthly Rate</div>
                </div>
                <div className="bondRowStyle1">
                  <div>{item.coinsData[0].monthlyEarnings.toFixed(5)}</div>
                  <div>{item.monthly_interest_rate.toFixed(5)}%</div>
                </div>
              </div>

              <div>
                <div className="bondRowStyle">
                  <div>Annual Earnings</div>
                  <div>Annual Rate</div>
                </div>
                <div className="bondRowStyle1">
                  <div>{item.coinsData[0].annualEarnings.toFixed(5)}</div>
                  <div>{item.annual_interest_rate.toFixed(5)}%</div>
                </div>
              </div>

              <div>
                <div className="bondRowStyle">
                  <div>Term Earnings</div>
                  <div>Term Rate</div>
                </div>
                <div className="bondRowStyle1">
                  <div>{item.coinsData[0].termEarnings.toFixed(5)}</div>
                  <div>{item.term_interest_rate.toFixed(5)}%</div>
                </div>
              </div>

              <div
                // onClick={(e) => console.log(appColorCode)}
                onClick={(e) => {
                  setSelectedBond(item);
                  setActionFlow('BuyBondStepOne');
                }}
                className="buyButton"
                style={{ background: appColorCode }}
              >
                Buy
              </div>
            </div>
          );
        })
      ) : (
        <div className="bondWrapper">
          <div>
            <div className="bondRowStyleTop">
              <Skeleton width={140} height={20} />
              <Skeleton width={140} height={20} />
            </div>
            <div className="bondRowStyle1">
              <Skeleton width={100} height={10} />
              <Skeleton width={100} height={10} />
            </div>
          </div>

          <div>
            <div className="bondRowStyle">
              <Skeleton width={140} height={20} />
              <Skeleton width={140} height={20} />
            </div>
            <div className="bondRowStyle1">
              <Skeleton width={100} height={10} />
              <Skeleton width={100} height={10} />
            </div>
          </div>

          <div>
            <div className="bondRowStyle">
              <Skeleton width={140} height={20} />
              <Skeleton width={140} height={20} />
            </div>
            <div className="bondRowStyle1">
              <Skeleton width={100} height={10} />
              <Skeleton width={100} height={10} />
            </div>
          </div>

          <div>
            <div className="bondRowStyle">
              <Skeleton width={140} height={20} />
              <Skeleton width={140} height={20} />
            </div>
            <div className="bondRowStyle1">
              <Skeleton width={100} height={10} />
              <Skeleton width={100} height={10} />
            </div>
          </div>

          <div>
            <div className="bondRowStyle">
              <Skeleton width={140} height={20} />
              <Skeleton width={140} height={20} />
            </div>
            <div className="bondRowStyle1">
              <Skeleton width={100} height={10} />
              <Skeleton width={100} height={10} />
            </div>
          </div>

          <div className="buyButton" style={{ background: appColorCode }}>
            &nbsp;
          </div>
        </div>
      )}
    </>
  );
};

export default BondsData;
