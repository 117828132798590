import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import Skeleton from 'react-loading-skeleton';
import { AppContext } from '../../../context/AppContext';
import BuySellFlow from '../BuySellFlow';
import './coinsData.scss';

const CoinsData = () => {
  const {
    openSidebar,
    setOpenSidebar,
    selectedMenu,
    setSelectedMenu,
    selectedSubMenu,
    setSelectedSubMenu,

    selectedOperation,
    setSelectionOperation,
    buySellStep,
    setBuySellStep,
  } = useContext(AppContext);

  const [allCoinsData, setAllCoinsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    if (selectedMenu === 'market' && selectedSubMenu === 'Crypto') {
      getAllCoinsData('crypto');
    } else if (selectedMenu === 'market' && selectedSubMenu === 'Forex') {
      getAllCoinsData('fiat');
    }
  }, [selectedSubMenu]);

  const getAllCoinsData = (coin) => {
    // console.log(coin, 'welwnecw');
    setLoading(true);
    axios
      .post(`https://comms.globalxchange.io/coin/vault/service/coins/get`, {
        app_code: 'indianotc',
        displayCurrency: 'INR',
        type: coin,
      })
      .then(({ data }) => {
        setAllCoinsData(data.coins_data);
        setLoading(false);
      });
  };

  return (
    <div>
      {!loading
        ? allCoinsData.map((item, index) => {
            return (
              <div
                key={index}
                className="coinListItem"
                onClick={(e) => setSelectedItem(item)}
              >
                <div className="rowOne">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={item.coinImage} alt="" className="listIcon" />
                    <div className="title">{item?.coinName}</div>
                  </div>
                  <div className="title">
                    $
                    {item?.coinPriceUSD
                      ? item?.coinPriceUSD?.toFixed(2)
                      : '0.00'}
                  </div>
                </div>
                <div className="rowTwo" style={{ paddingTop: '10px' }}>
                  <div>
                    24 Hr:{' '}
                    <span
                      className={item._24hrchange > 0 ? 'positive' : 'negative'}
                    >
                      {item._24hrchange > 0 ? (
                        <span>&#x25B2;</span>
                      ) : (
                        <span>&#x25BC;</span>
                      )}{' '}
                      {item._24hrchange.toFixed(2)}%
                    </span>
                  </div>
                  <div
                    className={item._24hrchange > 0 ? 'positive' : 'negative'}
                  >
                    ${item._24hrchange_priceUSD.toFixed(2)}
                  </div>
                </div>
                <div className="rowThree">
                  <div>
                    24 Hr Vol:{' '}
                    {(Math.abs(Number(item.volume24hr)) / 1.0e9).toFixed(2)}{' '}
                    Billion
                  </div>
                  <div>
                    Mkt Cap: ${' '}
                    {(Math.abs(Number(item.mkt_cap)) / 1.0e9).toFixed(2)}{' '}
                    Billion
                  </div>
                </div>

                {/* Old */}
              </div>
            );
          })
        : Array(10)
            .fill(' ')
            .map((item, index) => {
              return (
                <div className="coinListItem" key={index}>
                  <div className="rowOne">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Skeleton width={20} height={20} />
                      <Skeleton
                        width={100}
                        height={20}
                        style={{ marginLeft: 10 }}
                      />
                    </div>
                    <Skeleton width={100} height={20} />
                  </div>
                  <div className="rowTwo" style={{ paddingTop: '10px' }}>
                    <Skeleton width={100} height={10} />
                    <Skeleton width={90} height={10} />
                  </div>
                  <div className="rowThree">
                    <Skeleton width={70} height={10} />
                    <Skeleton width={70} height={10} />
                  </div>
                </div>
              );
            })}
      {selectedItem !== null ? (
        <div className="buySellPopup">
          <div
            style={{ height: '70vh', background: 'black', opacity: 0.5 }}
            onClick={(e) => setSelectedItem(null)}
          >
            &nbsp;
          </div>
          <div className="bottomPopup">
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '20px 0px',
                borderBottom: '1px solid #e7e7e7',
              }}
            >
              <img
                src={selectedItem?.coinImage}
                alt=""
                style={{ width: '30px', height: '30px' }}
              />
              <div
                style={{
                  fontSize: '20px',
                  fontWeight: 'bold',
                  paddingLeft: '10px',
                }}
              >
                {selectedItem?.coinName}
              </div>
            </div>
            <div style={{ padding: '0px 22px' }}>
              <div
                onClick={(e) =>
                  setSelectionOperation({
                    operation: 'Buy',
                    coinData: selectedItem,
                  })
                }
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '11vh',
                  // backgroundColor: 'lightgray',
                  borderBottom: '1px solid #e7e7e7',
                }}
              >
                <div style={{ fontSize: '40px', color: 'green' }}>&#x25B2;</div>
                <div style={{ paddingLeft: '20px' }}>
                  <div style={{ fontSize: '20px', fontWeight: 'bold' }}>
                    Buy
                  </div>
                  <div>Buy {selectedItem?.coinName} With Another Currency</div>
                </div>
              </div>
              <div
                onClick={(e) =>
                  setSelectionOperation({
                    operation: 'Sell',
                    coinData: selectedItem,
                  })
                }
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '11vh',
                  // backgroundColor: 'yellow',
                }}
              >
                <div style={{ fontSize: '40px', color: 'red' }}>&#x25BC;</div>
                <div style={{ paddingLeft: '20px' }}>
                  <div style={{ fontSize: '20px', fontWeight: 'bold' }}>
                    Sell
                  </div>
                  <div>Sell {selectedItem?.coinName} With Another Currency</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default CoinsData;
