import React from 'react';
import classNames from './taxesPage.module.scss';
import Layout from '../../Layout/Layout';
import coinTracker from '../../static/images/sidebarFullLogos/coinTracker.svg';
import { useAppContextDetails } from '../../context/AppContext';

function TaxesPage() {
  const { appName } = useAppContextDetails();
  return (
    <Layout active="taxes" className={classNames.taxesPage} hideFooter>
      <div className={classNames.content}>
        <img src={coinTracker} alt="" className={classNames.logo} />
      </div>
      <div className={classNames.footer}>
        File Your Taxes Directly Inside {appName} With CoinTracker
      </div>
    </Layout>
  );
}

export default TaxesPage;
