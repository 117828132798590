import React from 'react';
import submitHash from '../../../static/images/sidebar-icons/submitHash.svg';
import share from '../../../static/images/sidebar-icons/share.svg';
import { updateScroll } from '../../../config/constants';
import { useCoinContextData } from '../../../context/CoinContext';

export const SubmitButton = ({ isEthCoin, isTrxOrXrp, isSolCoin }) => {
  const { setAddActionStep } = useCoinContextData();

  if (isEthCoin || isTrxOrXrp) {
    return (
      <div
        className="d-flex clickable-div"
        onClick={() => {
          setAddActionStep(2);
          updateScroll();
        }}
      >
        <img className="mr-2" src={submitHash} alt="share" width={20} />
        <h5 className="mt-2">Submit Hash</h5>
      </div>
    );
  } else if (isSolCoin) {
    return (
      <div
        className="d-flex clickable-div"
        onClick={() => {
          setAddActionStep(2);
          updateScroll();
        }}
      >
        <img className="mr-2" src={submitHash} alt="share" width={20} />
        <h5 className="mt-2">Submit Txn Sign</h5>
      </div>
    );
  }
  return (
    <div className="d-flex clickable-div">
      <img className="mr-2" src={share} alt="share" width={20} />
      <h5 className="mt-2">Share</h5>
    </div>
  );
};
