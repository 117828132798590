import React, { useState } from 'react';
import OtpInput from 'react-otp-input';
import Countdown from 'react-countdown';
import moment from 'moment';

function SignupVerifyOtp({
  logo,
  pin,
  setPin,
  setSignupStep,
  resendMail,
  setResendMail,
  verifyEmail,
  isValid,
  mailId,
  customEmail,
  now,
  setNotSeeing,
  resendMailNow,
}) {
  const [count, setCount] = useState(0);
  const renderer = ({ seconds, completed }) => {
    if (completed) {
      // Render a completed state
      if (count >= 1)
        return (
          <div
            className="resend true"
            onClick={() => {
              setNotSeeing(true);
              setCount((count) => count + 1);
            }}
          >
            <span>Still Not Seeing The Code? Click Here</span>
          </div>
        );
      else
        return (
          <div
            className="resend true"
            onClick={() => {
              setResendMail(false);
              resendMailNow();
            }}
          >
            <span>Click Here</span> To Resend Code
          </div>
        );
    } else {
      return (
        <div className="resend">
          If A Code Is Not Displayed In {seconds} Seconds{' '}
          <span>Click Here</span>
        </div>
      );
    }
  };
  return (
    <div className="stepWrapper stepForm">
      <img src={logo} alt="assetLogo" className="assetLogo" />
      <div className="stepDesc">
        {customEmail
          ? `Enter The Code That Was Sent To ${mailId}`
          : 'Verifying BlockCheck ID'}
      </div>
      <OtpInput
        containerStyle="otp-input-wrapper"
        value={pin}
        onChange={(pinVal) => customEmail && setPin(pinVal)}
        numInputs={6}
        separator={<span> </span>}
        inputStyle="otp-input"
      />
      <div className="btns">
        <div
          className={`btnBig ${resendMail}`}
          onClick={() => {
            setResendMail(false);
            resendMailNow();
          }}
        >
          Resend Code
        </div>
        <div
          className={`btnNext ${isValid.pin}`}
          onClick={() => {
            if (isValid.pin) {
              setSignupStep(5);
              verifyEmail();
            }
          }}
        >
          Next
        </div>
      </div>
      {now && (
        <Countdown
          key={moment(now).format('mm:ss')}
          date={now}
          renderer={renderer}
          intervalDelay={1000}
        />
      )}
    </div>
  );
}
export default SignupVerifyOtp;
