import React from 'react';

function NotSeeingMailModal({ onClose }) {
  return (
    <div className="modalSessionExpired">
      <div
        className="overlayClose"
        onClick={() => {
          try {
            onClose();
          } catch (error) {}
        }}
      />
      <div className="modalContent passwordReq">
        <div className="head">
          <h5>Not Seeing BlockCheck Code?</h5>
        </div>
        <div className="contents">
          <p>
            Your New BlockCheck Inbox Maybe Taking A Bit Longer To Correspond
            With Our Servers. Ideally We Just Extract The Verification Code And
            Fill It In For You But In Circumstances Like This You Will Need To
            Get The Code By Doing The Following:{' '}
          </p>
          <div className="stepTitle">Steps To Get Code</div>
          <div className="itemStep">
            1. Click{' '}
            <a
              href="https://blockcheck.io/mail/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Here
            </a>{' '}
            And Open BlockCheck
          </div>
          <div className="itemStep">
            2. Access You Inbox By Entering This Email
          </div>
          <div className="itemStep">
            3. Copy The Code From The Verifiation Email And Entering Back On
            This Page
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotSeeingMailModal;
