import React, { useState, useContext } from 'react';

import { useAppContextDetails } from '../../context/AppContext';
import { allFunds, SeletedFunds } from './apidata';
import Skeleton from 'react-loading-skeleton';
import './Funds.scss';
import './table.scss';
import dg from '../../static/images/Funds/dg.svg';
import allEa from '../../static/images/Funds/allEa.svg';
import EaDrawer from './EaDrawer';
import { StatueData } from './apidata';
import { BankContext } from '../../context/Context';

export default function Table({ handleBuy, setdrawerToggle }) {
  const [drawerName, setdrawerName] = useState('');
  const [Eatoggle, setEatoggle] = useState(false);
  const [statusCheck, setstatusCheck] = useState({});
  const [licenseId, setlicenseId] = useState('');
  const {
    setFundsTab,
    FundsTab,
    setStoreFundsDetail,
    StoreFundsDetail,
    fundsDetailTable,
    cardNameFunds,
    setfundsDetailTable,
    setfinaldata,
    finaldata,
    setcardNameFunds,
  } = useAppContextDetails();
  const table = [
    {
      name: 'Fund Manager',
    },
    {
      name: 'Name',
    },
    {
      name: 'Price',
    },
    {
      name: 'All Time ROI',
    },
    {
      name: '30 Day ROI',
    },
    {
      name: 'Recommended Broker',
    },
    {
      name: 'Status',
    },
    {
      name: 'Actions',
    },
  ];
  const callhander = (e) => {
    // setfinaldata(e);
    setcardNameFunds(e.name);
    fundssSeleted(e.code);
  };
  const fundssSeleted = async (e) => {
    await setfundsDetailTable([]);
    await SeletedFunds(e).then((response) => {
      let result = response?.data?.paths;
      setfundsDetailTable(result);
    });
  };
  const { email, profileId, token } = useContext(BankContext);

  //   useEffect(() => {

  //   }, []);

  const statusFunction = (e) => {
    let obj = {
      email: email,
    };
    setEatoggle(true);
    setdrawerName(e);
   
    StatueData(obj).then((response) => {
      let result = response?.data?.licenses?.[0];
      setstatusCheck(result);
      setlicenseId(result?.license_code);
   
    });
  };

  console.log('statusCheck', licenseId);

  return (
    //
    <>
      <div
        className="funds-content"
        style={{
          paddingLeft:'2rem',
          paddingRight:'2rem',
          paddingTop:"2rem"

        }}
        onClick={() => Eatoggle && setEatoggle(false)}
      >
        <div className="left-fund-container">
          <div className="cards-funds">
            <div className="Tablecard">
              <div
                className="innerTablecard"
                //   onClick={() => callhander(item)}
                //   style={{
                //     boxShadow:
                //       cardNameFunds == item.name
                //         ? '0px 4px 4px rgba(0, 0, 0, 0.25)'
                //         : '0px',
                //     opacity: cardNameFunds == item.name ? 1 : 0.5,
                //   }}
              >
                <img src={allEa} />
                <div className="fundsNamesub">All EA’s</div>
              </div>
            </div>
          </div>
          <div className="TableSection">
            <div className="headerFundsEA">
              {table.map((item) => {
                return <div className="fund-title">{item.name}</div>;
              })}
            </div>
            <div className="fundBody">
              <>
                <div className="InnerFundsBodyEa">
                  <div className="sectionLeftBody">
                    <div className="img-table-frist">
                      <img src={dg} className="logo-fund" />
                    </div>
                  </div>
                  <div className="text-funds">Queen</div>
                  <div className="text-funds">$50.00/Month</div>

                  <div
                    className="text-funds"
                    style={{
                      color: '#4CAF50',
                    }}
                  >
                    0.00%
                  </div>
                  <div
                    className="text-funds"
                    style={{
                      color: '#4CAF50',
                    }}
                  >
                    0.00%
                  </div>
                  <div
                    className="text-funds hoverEffer"
                    onClick={() =>
                      window.open('https://multibankfx.com/', '_blank')
                    }
                  >
                    MultiBank
                  </div>
                  <div className="text-funds">Not Connected</div>
                  {/* <div className="text-funds">{item?.tokenHoldersCount}</div> */}
                  <div className="labelcover">
                    <div
                      className="buylabel"
                      onClick={() => statusFunction('Queen')}
                    >
                      Connect
                    </div>
                  </div>
                </div>
                <div className="InnerFundsBodyEa">
                  <div className="sectionLeftBody">
                    <div className="img-table-frist">
                      <img src={dg} className="logo-fund" />
                    </div>
                  </div>
                  <div className="text-funds">Nitro Plus</div>
                  <div className="text-funds">$50.00/Month</div>

                  <div
                    className="text-funds"
                    style={{
                      color: '#4CAF50',
                    }}
                  >
                    0.00%
                  </div>
                  <div
                    className="text-funds"
                    style={{
                      color: '#4CAF50',
                    }}
                  >
                    0.00%
                  </div>
                  <div
                    className="text-funds hoverEffer"
                    onClick={() =>
                      window.open('https://multibankfx.com/', '_blank')
                    }
                  >
                    MultiBank
                  </div>
                  <div className="text-funds">Not Connected</div>
                  {/* <div className="text-funds">{item?.tokenHoldersCount}</div> */}
                  <div className="labelcover">
                    <div
                      className="buylabel"
                      onClick={() => statusFunction('Nitro Plus')}
                    >
                      Connect
                    </div>
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
      {Eatoggle && (
        <EaDrawer
          drawerName={drawerName}
          statusCheck={statusCheck}
          setEatoggle={setEatoggle}
          licenseId={licenseId}
        />
      )}
    </>
  );
}
