/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import React from 'react';
import Lottie from 'react-lottie';
import QRCode from 'qrcode.react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import * as animationData from '../../static/animations/cpu-loading.json';

function DepositCrypto({
  openModal,
  setOpenModal,
  vaultSelected: coinSelected,
}) {
  const onCopy = () => {
    // setWalletAddressCopy('Copied To Clipboard');
    // setTimeout(() => {
    //   setWalletAddressCopy(walletAddress);
    // }, 1000);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData.default,
  };

  return (
    <div className={`deposit-modal ${openModal ? '' : 'd-none'}`}>
      <div
        className="overlay-deposit"
        role="button"
        tabIndex="-1"
        onClick={() => setOpenModal(false)}
      />
      <div className="deposit-card-qr">
        <div className="title">Crypto</div>
        {coinSelected ? (
          <>
            <QRCode
              className="qr-svg"
              value={coinSelected.coin_address || ''}
              renderAs="svg"
            />
            <CopyToClipboard text={coinSelected.coin_address} onCopy={onCopy}>
              <span className="wallet-addr">
                <input type="text" value={coinSelected.coin_address} readOnly />
                <FontAwesomeIcon icon={faCopy}></FontAwesomeIcon>
              </span>
            </CopyToClipboard>
          </>
        ) : (
          <div className="m-auto">
            <Lottie options={defaultOptions} height={150} width={150} />
          </div>
        )}
      </div>
    </div>
  );
}

export default DepositCrypto;
