import React from 'react';
import OtpInput from 'react-otp-input';
import reload from '../../../static/images/reload.svg';
import { useCoinContextData } from '../../../context/CoinContext';

export const EmailVerification = () => {
  const { otpForex, setOtpForex } = useCoinContextData();

  return (
    <div
      style={{
        width: '90%',
      }}
    >
      <p className="step-head">Step 4: Email Verification</p>
      <p className="step-para">
        Enter The Six Digit Withdrawal Code Which We Just Sent To Your Email
      </p>
      <div className="email-code-wrapper">
        <OtpInput
          className="email-code"
          value={otpForex}
          onChange={setOtpForex}
          numInputs={6}
          separator={<span> </span>}
        />
      </div>

      <div className="d-flex clickable-div" style={{ opacity: '0.25' }}>
        <img src={reload} width={20} />
        <p className="reload-otp">Resend Verification Code</p>
      </div>
    </div>
  );
};
