import React, { createContext, useState, useContext, useEffect } from 'react';
import { BankContext } from './Context';
import Axios from 'axios';
import { useAppContextDetails } from './AppContext';

export const VaultContext = createContext();

function VaultContextProvider({ children }) {
  const { profileId, token, email } = useContext(BankContext);
  const [coinBalanceList, setCoinBalanceList] = useState([]);
  const [vaultTxns, setVaultTxns] = useState([]);
  const [coinSelected, setCoinSelected] = useState({});
  const [coinAddress, setCoinAddress] = useState({});
  const [loading, setLoading] = useState(0);
  const { appCode } = useAppContextDetails();




  function updateBalance() {
    setLoading(0);
    Axios.post('https://comms.globalxchange.io/coin/vault/service/coins/get', {
      app_code: appCode,
      profile_id: profileId,
      getAllCoins: true,
    })
      .then(({ data }) => {
        setCoinBalanceList(data.coins_data);
        const btcArray = data.coins_data.filter(
          (coin) => coin.coinSymbol === 'BTC'
        );
        if (!coinSelected) {
          setCoinSelected(btcArray[0]);
        }
      })
      .finally(() => setLoading((loading) => loading + 1));
    Axios.post(
      'https://comms.globalxchange.io/coin/vault/service/balances/get',
      {
        app_code: appCode,
        profile_id: profileId,
        getAllCoins: true,
      }
    )
      .then(({ data }) => {
        if (data.status) {
          setCoinAddress(data.vault.coinAddress);
        }
      })
      .finally(() => setLoading((loading) => loading + 1));
    if (email) {
      Axios.post('https://comms.globalxchange.io/coin/vault/service/txns/get', {
        app_code: appCode,
        profile_id: profileId,
        getAllCoins: true,
      })
        .then(({ data }) => {
          if (data.status) setVaultTxns(data.txns);
        })
        .finally(() => setLoading((loading) => loading + 1));
    }
  }
  useEffect(() => {
    if (profileId && token) {
      updateBalance();
    }
    // eslint-disable-next-line
  }, [profileId]);

  // For Vault Filter Conrolls

  const [menuTwo, setMenuTwo] = useState({
    key: 'all',
    value: 'All Directions',
  });

  const [dateSelected, setDateSelected] = useState(null);

  const [showNativeValue, setShowNativeValue] = useState(true);

  return (
    <VaultContext.Provider
      value={{
        coinBalanceList,
        coinSelected,
        setCoinSelected,
        vaultTxns,
        updateBalance,
        setVaultTxns,
        coinAddress,
        loading: loading < 3,
        menuTwo,
        setMenuTwo,
        dateSelected,
        setDateSelected,
        showNativeValue,
        setShowNativeValue,
      }}
    >
      {children}
    </VaultContext.Provider>
  );
}

export default VaultContextProvider;
