import React, { useContext, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import classNames from './selectBondIssuerModal.module.scss';
import doubleTik from '../../static/images/clipIcons/doubleTik.svg';
import { useCustomBondsList } from '../../queryHooks';
import { useAppContextDetails } from '../../context/AppContext';
import { BankContext } from '../../context/Context';

function SelectBondIssuerModal({
  onClose = () => {},
  onSuccess = () => {},
  assetIssuer,
  setAssetIssuer,
}) {
  const { ownerEmail } = useAppContextDetails();
  const { email } = useContext(BankContext);
  const { data: bondsData, isLoading } = useCustomBondsList(email, ownerEmail);
  const [search, setSearch] = useState('');
  return (
    <div className={classNames.selectBondIssuerModal}>
      <div
        className={classNames.overlayClose}
        onClick={() => {
          try {
            onClose();
          } catch (error) {}
        }}
      />
      <div className={classNames.settingsCard}>
        <div className={classNames.inCard}>
          <div className={classNames.title}>Select A Bond Issuer</div>
          <div className={classNames.search}>
            <input
              type="text"
              placeholder="Search For A Bond Issuer...."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div className={classNames.settingCards}>
            <div
              className={`${classNames.settingCard} ${
                classNames[assetIssuer.bankerTag === undefined]
              }`}
              onClick={() => {
                setAssetIssuer({
                  displayName: 'All Issuers',
                  bankerTag: undefined,
                  profilePicURL: doubleTik,
                });
                onSuccess();
              }}
            >
              <img src={doubleTik} alt="" className={classNames.icon} />
              <div className={classNames.texts}>
                All Assets
                <span>{bondsData?.count} Staking Contracts</span>
              </div>
            </div>
            {isLoading
              ? Array(10)
                  .fill('')
                  .map((_, i) => (
                    <div className={classNames.settingCard}>
                      <Skeleton className={classNames.icon} circle />
                      <div className={classNames.texts}>
                        <Skeleton className={classNames.icon} width={200} />
                      </div>
                    </div>
                  ))
              : bondsData?.bankersData &&
                Object.values(bondsData?.bankersData)
                  .filter((banker) =>
                    banker?.displayName
                      ?.toLowerCase()
                      .includes(search.toLowerCase())
                  )
                  .map((banker) => (
                    <div
                      className={`${classNames.settingCard} ${
                        classNames[assetIssuer.bankerTag === banker.bankerTag]
                      }`}
                      onClick={() => {
                        setAssetIssuer(banker);
                        onSuccess();
                      }}
                    >
                      <img
                        src={banker.profilePicURL}
                        alt=""
                        className={classNames.icon}
                      />
                      <div className={classNames.texts}>
                        {banker.displayName}
                        <span>{banker?.count} Staking Contracts</span>
                      </div>
                    </div>
                  ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SelectBondIssuerModal;
