import React, { createContext, useState, useContext, useEffect } from 'react';
import Axios from 'axios';
import { useQuery } from 'react-query';
import { BankContext } from './Context';
import { useAppContextDetails } from './AppContext';
import axios from 'axios';
import { useParams } from 'react-router-dom';
// import { predefinedToast } from '../utils/toast';
import toast from 'react-hot-toast';

export const planBContext = createContext();

function PlanBContextProvider({ children }) {
  const { email, token, chatOn, setChatOn } = useContext(BankContext);
  const [planBCreate, setplanBCreate] = useState(false);
  const [insideplanBCreate, setinsideplanBCreate] = useState(false);
  const [aiIndexDefaultSelected, setaiIndexDefaultSelected] =
    useState('All Products');
  const [allPlansList, setallPlansList] = useState([]);
  const [planBAccountName, setplanBAccountName] = useState('');
  const [planBAccountType, setplanBAccountType] = useState('');
  const [planBAccountTypeIcon, setplanBAccountTypeIcon] = useState('');
  const [planBAccountGoalId, setplanBAccountGoalId] = useState('');
  const [planBAccountDesc, setplanBAccountDesc] = useState('');
  const [planBAccountTargetDate, setplanBAccountTargetDate] = useState({
    date: '',
    month: '',
    year: '',
  });
  const [planBAccountContributor, setplanBAccountContributor] = useState('Me');
  const [planBAccountPic, setplanBAccountPic] = useState('');
  const [planBAccountPicUploading, setplanBAccountPicUploading] =
    useState(false);

  //dropDown
  const [selectedDropDown, setSelectedDropDown] = useState(false);

  //planCreation
  const [planBPlanPic, setplanBPlanPic] = useState('');
  const [planBPlanPicUploading, setplanBPlanPicUploading] = useState(false);
  const [planBCreateName, setplanBCreateName] = useState('');
  const [planBCreateDescription, setplanBCreateDescription] = useState('');
  const [creationLoading, setCreationLoading] = useState(false);
  const [deletePlanID, setdeletePlanID] = useState('');

  //configuration
  const [configurationValues, setConfigurationValues] = useState([]);
  const [configurationGoalValues, setConfigurationGoalValues] = useState('');
  const [configurationDateValues, setConfigurationDateValues] = useState('');

  const [editingConfigurationField, setEditingConfigurationField] =
    useState('');

  const [editingConfigurationInput, seteditingConfigurationInput] =
    useState('');
  const [editingConfigurationSearch, seteditingConfigurationSearch] =
    useState('');
  const [editingConfigurationLoading, seteditingConfigurationLoading] =
    useState(false);
  const [planTypeCreate, setplanTypeCreate] = useState(false);

  //AIIndex

  const [aiIndexOfferings, setAiIndexOfferings] = useState(false);

  const [aidropdownAsset, setaidropdownAsset] = useState('');
  const [aidropdownCustody, setaidropdownCustody] = useState('');
  const productsTypeMap = [
    'Automated Trading',
    'Managed Fund',
    'Passive Fund',
    'Signal',
    'Education',
  ];
  const assetCustodyMap = [
    'Self Custody',
    'Trader Custody',
    'Platform Custody',
    'NA',
  ];
  const [productTypeSelected, setProductTypeSelected] = useState('');
  const [assestCustodySelected, setAssestCustodySelected] = useState('');
  const [aidropdownProductName, setaidropdownProductName] = useState('');
  const [aidropdownDesc, setaidropdownDesc] = useState('');
  const [aidropdownProdImg, setaidropdownProdImg] = useState('');
  const [aidropdownMontlyCost, setaidropdownMontlyCost] = useState('');
  const [aidropdownManaFee, setaidropdownManaFee] = useState('');
  const [aidropdownROI30, setaidropdownROI30] = useState('');
  const [aidropdownROI12, setaidropdownROI12] = useState('');
  const [aidropdownProductLive, setaidropdownProductLive] = useState('');
  const [aidropdownAvgDrawdown, setaidropdownAvgDrawdown] = useState('');
  const [aidropdownSubs, setaidropdownSubs] = useState('');
  const [aidropdownCompanyCreated, setaidropdownCompanyCreated] = useState('');
  const [aidropdownCompanyWebsite, setaidropdownCompanyWebsite] = useState('');
  const [aidropdownRegLink, setaidropdownRegLink] = useState('');

  //new fields
  const [assetClass, setAssetClass] = useState('');
  const [aidropdownAssetClass, setaidropdownAssetClass] = useState('');
  const assetClassMap = ['Crypto', 'Forex', 'Stocks'];

  const [upfrontCost, setUpfrontCost] = useState('');

  const [productCharge, setProductCharge] = useState('');
  const [aidropdownProductCharge, setaidropdownProductCharge] = useState('');
  const productChargeMap = ['One Time', 'Monthly', 'Free'];

  const [paymentTypes, setPaymentTypes] = useState('');
  const [aidropdownPaymentTypes, setaidropdownPaymentTypes] = useState('');
  const paymentTypesMap = ['Credit Card', 'Crypto', 'Bank Transfer'];

  const [referralProgram, setReferralProgram] = useState('');
  const [aidropdownReferralProgram, setaidropdownReferralProgram] =
    useState('');
  const referralProgramMap = ['Yes', 'No'];

  const [productLeverage, setProductLeverage] = useState('');
  const [aidropdownProductLeverage, setaidropdownProductLeverage] =
    useState('');
  const productLeverageMap = ['Yes', 'No'];
  const [isloading, setisloading] = useState(true);
  const [apiData, setApiData] = useState([]);
  const [filteredApiData, setFilteredApiData] = useState([]);

  //aiindex filter
  const [typeFilter, setTypeFilter] = useState('');
  const [custodyFilter, setCustodyFilter] = useState('');
  const [assetClassFilter, setAssetClassFilter] = useState('');
  const [billingFilter, setBillingFilter] = useState('');
  const [compFilter, setCompFilter] = useState('');
  const [leverageFilter, setLeverageFilter] = useState('');
  const [paymentMethodsFilter, setPaymentMethodsFilter] = useState('');

  //aiindex shop filter
  const [shopCompaniesFilter, setShopCompaniesFilter] = useState('');
  const [shopBillingFilter, setShopBillingFilter] = useState('');

  const { shopCompanies, setShopCompanies, shopBilling, setShopBilling } =
    useAppContextDetails();

  useEffect(() => {
    // if (email) {
    setisloading(true);
    setFilteredApiData('');
    if (shopCompanies || shopBilling) {
      axios
        .post(
          'https://comms.globalxchange.io/gxb/product/price/with/fees/get',
          {
            app_code: 'dgpayments',
            product_creator: shopCompanies ? shopCompanies : '',
            billing_type: shopBilling ? shopBilling : '',
          }
        )
        .then((response) => {
          let result = response?.data?.products;
          // console.log(response?.data, 'products with data');
          let objCompanies = response?.data?.productCreators;
          let objBilling = response?.data?.billingMethods;
          let arrOfObjCompanies = Object.keys(objCompanies).map((key) => {
            return { id: key, ...objCompanies[key] };
          });
          let arrOfObjBilling = Object.keys(objBilling).map((key) => {
            return { id: key, ...objBilling[key] };
          });
          setShopCompaniesFilter(arrOfObjCompanies);
          setShopBillingFilter(arrOfObjBilling);

          setApiData(result);
          setFilteredApiData(result);
          setisloading(false);
        })
        .catch((error) => {
          console.log(error, 'products error');
        });
    } else {
      axios
        .post(
          'https://comms.globalxchange.io/gxb/product/price/with/fees/get',
          {
            app_code: 'dgpayments',
          } //url changed
        )
        .then((response) => {
          let result = response?.data?.products;
          // console.log(response?.data, 'products without data');
          let objCompanies = response?.data?.productCreators;
          let objBilling = response?.data?.billingMethods;
          let arrOfObjCompanies = Object.keys(objCompanies).map((key) => {
            return { id: key, ...objCompanies[key] };
          });
          let arrOfObjBilling = Object.keys(objBilling).map((key) => {
            return { id: key, ...objBilling[key] };
          });
          setShopCompaniesFilter(arrOfObjCompanies);
          setShopBillingFilter(arrOfObjBilling);

          setApiData(result);
          setFilteredApiData(result);
          setisloading(false);
        })
        .catch((error) => {
          console.log(error, 'products error');
        });
    }
    // }
  }, [shopCompanies, shopBilling]);

  useEffect(() => {
    if (token) {
      let allFilters = [
        { fieldName: 'item_type', setFunc: setTypeFilter },
        { fieldName: 'custody', setFunc: setCustodyFilter },
        { fieldName: 'asset_class', setFunc: setAssetClassFilter },
        { fieldName: 'billing_type', setFunc: setBillingFilter },
        { fieldName: 'comp_plan', setFunc: setCompFilter },
        { fieldName: 'leverage', setFunc: setLeverageFilter },
        {
          fieldName: 'accepted_payment_methods',
          setFunc: setPaymentMethodsFilter,
        },
      ];

      allFilters.forEach((eachFilterItem, i) => {
        let { fieldName, setFunc } = eachFilterItem;
        axios
          .get(
            `https://comms.globalxchange.io/gxb/product/aiprowallet/index/item/values?field_name=${fieldName}`
          )
          .then((response) => {
            // console.log(response?.data?.data, 'type data');
            setFunc(response?.data?.data);
          })
          .catch((error) => {
            console.log(error?.message, `${fieldName} filter API failed`);
          });
      });
    }
  }, []);

  useEffect(() => {
    setProductTypeSelected('');
    setAssestCustodySelected('');
    setaidropdownProductName('');
    setaidropdownDesc('');
    setaidropdownProdImg('');
    setaidropdownMontlyCost('');
    setaidropdownManaFee('');
    setaidropdownROI30('');
    setaidropdownROI12('');
    setaidropdownProductLive('');
    setaidropdownAvgDrawdown('');
    setaidropdownSubs('');
    setaidropdownCompanyCreated('');
    setaidropdownCompanyWebsite('');
    setaidropdownRegLink('');

    //new fields
    setAssetClass('');
    setUpfrontCost('');
    setProductCharge('');
    setPaymentTypes('');
    setReferralProgram('');
    setProductLeverage('');
  }, [insideplanBCreate]);

  function editFuncApi(retiredid) {
    seteditingConfigurationLoading(true);

    let obj = {
      [editingConfigurationField.body]:
        editingConfigurationField?.type == 'input'
          ? editingConfigurationInput
          : editingConfigurationField?.type == 'search'
          ? editingConfigurationSearch
          : editingConfigurationField?.type == 'date'
          ? planBAccountTargetDate?.date &&
            planBAccountTargetDate?.month &&
            planBAccountTargetDate?.year
            ? planBAccountTargetDate?.date +
              '/' +
              planBAccountTargetDate?.month +
              '/' +
              planBAccountTargetDate?.year
            : ''
          : editingConfigurationField?.type == 'image'
          ? planBAccountPic
          : '',
    };
    // console.log(obj, 'posting obj');
    axios
      .put(
        `https://comms.globalxchange.io/retired/block/update/retirementplan?retirement_plan_id=${retiredid}`,
        obj
      )
      .then((response) => {
        // console.log(response, 'response edited');
        // predefinedToast(response?.data?.message);
        toast(response?.data?.message, {
          duration: 4000,
          position: 'top-left',
        });
        seteditingConfigurationLoading(false);
        setEditingConfigurationField('');
      })
      .catch((error) => {
        console.log(error, 'error');
        // predefinedToast('Network error try again..');
        toast('Network error try again..', {
          duration: 4000,
          position: 'top-left',
        });
        seteditingConfigurationLoading(false);
        setEditingConfigurationField('');
      });
  }

  useEffect(() => {
    seteditingConfigurationInput('');
    seteditingConfigurationSearch('');
    setplanBAccountTargetDate({
      date: '',
      month: '',
      year: '',
    });
  }, [editingConfigurationField]);

  //progress

  const [overallProgressBar, setoverallProgressBar] = useState('');
  const [blockData, setblockData] = useState('');
  const [timeProgressbar, settimeProgressbar] = useState('');

  useEffect(() => {
    setaidropdownAsset(false);
    setaidropdownCustody(false);
    setaidropdownAssetClass(false);
    setaidropdownProductCharge(false);
    setaidropdownPaymentTypes(false);
    setaidropdownReferralProgram(false);
    setaidropdownProductLeverage(false);
  }, [
    productTypeSelected,
    assestCustodySelected,
    assetClass,
    productCharge,
    paymentTypes,
    referralProgram,
    productLeverage,
  ]);

  return (
    <planBContext.Provider
      value={{
        planBCreate,
        setplanBCreate,
        planBAccountName,
        setplanBAccountName,
        allPlansList,
        setallPlansList,
        planBAccountType,
        setplanBAccountType,
        planBAccountDesc,
        setplanBAccountDesc,
        planBAccountTargetDate,
        setplanBAccountTargetDate,
        planBAccountContributor,
        setplanBAccountContributor,
        planBAccountPic,
        setplanBAccountPic,
        planBAccountPicUploading,
        setplanBAccountPicUploading,
        insideplanBCreate,
        setinsideplanBCreate,
        planBPlanPic,
        setplanBPlanPic,
        planBPlanPicUploading,
        setplanBPlanPicUploading,
        planBCreateName,
        setplanBCreateName,
        planBCreateDescription,
        setplanBCreateDescription,
        selectedDropDown,
        setSelectedDropDown,
        creationLoading,
        setCreationLoading,
        planBAccountGoalId,
        setplanBAccountGoalId,
        planBAccountTypeIcon,
        setplanBAccountTypeIcon,
        deletePlanID,
        setdeletePlanID,
        configurationValues,
        setConfigurationValues,
        configurationGoalValues,
        setConfigurationGoalValues,
        configurationDateValues,
        setConfigurationDateValues,
        editingConfigurationField,
        setEditingConfigurationField,
        planTypeCreate,
        setplanTypeCreate,
        //editing configuration
        editingConfigurationInput,
        seteditingConfigurationInput,
        editingConfigurationSearch,
        seteditingConfigurationSearch,
        //editfuncapi
        editFuncApi,
        editingConfigurationLoading,
        seteditingConfigurationLoading,
        overallProgressBar,
        setoverallProgressBar,
        blockData,
        setblockData,
        timeProgressbar,
        settimeProgressbar,

        //aiindex
        aiIndexOfferings,
        setAiIndexOfferings,

        assetCustodyMap,
        productsTypeMap,
        aidropdownAsset,
        aidropdownCustody,
        setaidropdownAsset,
        setaidropdownCustody,
        aiIndexDefaultSelected,
        setaiIndexDefaultSelected,
        //values
        aidropdownProductName,
        setaidropdownProductName,
        productTypeSelected,
        setProductTypeSelected,
        assestCustodySelected,
        setAssestCustodySelected,
        aidropdownDesc,
        setaidropdownDesc,
        aidropdownProdImg,
        setaidropdownProdImg,
        aidropdownMontlyCost,
        setaidropdownMontlyCost,
        aidropdownManaFee,
        setaidropdownManaFee,
        aidropdownROI30,
        setaidropdownROI30,
        aidropdownROI12,
        setaidropdownROI12,
        aidropdownProductLive,
        setaidropdownProductLive,
        aidropdownAvgDrawdown,
        setaidropdownAvgDrawdown,
        aidropdownSubs,
        setaidropdownSubs,
        aidropdownCompanyCreated,
        setaidropdownCompanyCreated,
        aidropdownCompanyWebsite,
        setaidropdownCompanyWebsite,
        aidropdownRegLink,
        setaidropdownRegLink,

        //newfields
        assetClass,
        setAssetClass,
        aidropdownAssetClass,
        setaidropdownAssetClass,
        assetClassMap,
        upfrontCost,
        setUpfrontCost,
        productCharge,
        setProductCharge,
        aidropdownProductCharge,
        setaidropdownProductCharge,
        productChargeMap,
        paymentTypes,
        setPaymentTypes,
        aidropdownPaymentTypes,
        setaidropdownPaymentTypes,
        paymentTypesMap,
        referralProgram,
        setReferralProgram,
        aidropdownReferralProgram,
        setaidropdownReferralProgram,
        referralProgramMap,
        productLeverage,
        setProductLeverage,
        aidropdownProductLeverage,
        setaidropdownProductLeverage,
        productLeverageMap,

        //aiindex filter
        typeFilter,
        custodyFilter,
        assetClassFilter,
        billingFilter,
        compFilter,
        leverageFilter,
        paymentMethodsFilter,
        shopBillingFilter,
        shopCompaniesFilter,
        apiData,
        setApiData,
        filteredApiData,
        setFilteredApiData,
      }}
    >
      {children}
    </planBContext.Provider>
  );
}

export default PlanBContextProvider;
