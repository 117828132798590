import React, { useState } from 'react';
import classNames from './selectAppModal.module.scss';
import { useUserApps } from '../../queryHooks';
import Skeleton from 'react-loading-skeleton';

function SelectAppModal({
  onClose = () => {},
  onSuccess = () => {},
  appSelected,
  setAppSelected,
  email,
  filter,
}) {
  const { data: appList = [], isLoading } = useUserApps(email);
  const [search, setSearch] = useState('');
  return (
    <div className={classNames.selectAppModal}>
      <div
        className={classNames.overlayClose}
        onClick={() => {
          try {
            onClose();
          } catch (error) {}
        }}
      />
      <div className={classNames.settingsCard}>
        <div className={classNames.title}>Select One Of Your Apps</div>
        <div className={classNames.content}>
          <input
            type="text"
            name="search"
            className={classNames.search}
            placeholder="Search Apps..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <div className={classNames.appList}>
            {isLoading
              ? Array(8)
                  .fill('')
                  .map((_, i) => (
                    <div className={`${classNames.appCard}`} key={i}>
                      <Skeleton className={classNames.icon} />
                      <Skeleton className={classNames.appName} width={220} />
                    </div>
                  ))
              : appList
                  .filter((app) => {
                    if (filter) {
                      return filter.includes(app.app_code);
                    } else {
                      return true;
                    }
                  })
                  .filter(
                    (app) =>
                      app.app_name
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                      app.app_code.toLowerCase().includes(search.toLowerCase())
                  )
                  .map((app) => (
                    <div
                      className={`${classNames.appCard} ${
                        classNames[appSelected?.app_code === app.app_code]
                      }`}
                      key={app.app_code}
                      onClick={() => {
                        setAppSelected(app);
                        onSuccess();
                      }}
                    >
                      <img
                        src={app.app_icon}
                        alt=""
                        className={classNames.icon}
                      />
                      <span className={classNames.appName}>{app.app_name}</span>
                    </div>
                  ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SelectAppModal;
