import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useCoinContextData } from '../../../context/CoinContext';
import './addAccountStyles.scss';

const CountryType = () => {
  const {
    addAccountStepName,
    setAddAccountStepName,
    selectedCountry,
    setSelectedCountry,
  } = useCoinContextData();

  const [allCountries, setAllCountries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState('');

  useEffect(() => {
    setLoading(true);
    axios
      .get(`https://comms.globalxchange.io/coin/vault/countries/data/get`)
      .then(({ data }) => {
        if (data.status) {
          setAllCountries(data.countries);
          setLoading(false);
        }
      });
  }, []);

  return (
    <>
      <div className="addAccountContainer">
        <div>
          <span className="prevStep" onClick={(e) => setAddAccountStepName('')}>
            Payment Type
          </span>{' '}
          {`>`}{' '}
          <span style={{ textDecoration: 'underline', fontWeight: 'bold' }}>
            Countries
          </span>
        </div>
        <div className="stepTitle">Which Country Is Your Bank Account In?</div>
        <div className="selectionWrapper">
          <input
            placeholder="Search Banks..."
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          <div style={{ overflowY: 'scroll', height: '70vh' }}>
            {!loading
              ? allCountries
                  .filter((o) =>
                    o.name.toLowerCase().includes(query.toLowerCase())
                  )
                  .map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="listItem"
                        onClick={(e) => {
                          if (item.name === 'India') {
                            setSelectedCountry(item);
                            setAddAccountStepName('selectBank');
                          }
                        }}
                      >
                        <img src={item.image} alt="" />
                        <div>{item.name}</div>
                      </div>
                    );
                  })
              : Array(10)
                  .fill(' ')
                  .map((item, index) => {
                    return (
                      <div className="listItem" key={index}>
                        <Skeleton width={20} height={20} />
                        <Skeleton
                          width={100}
                          height={20}
                          style={{ marginLeft: 10 }}
                        />
                      </div>
                    );
                  })}
          </div>
        </div>
      </div>
    </>
  );
};

export default CountryType;
