import React, { useContext, useEffect } from 'react';
import classNames from './bplanner.module.scss';
import Layout from '../../Layout/Layout';
// import retiredIcon from '../../static/images/sidebar-icons/retired.svg';
import streamIcon from '../../static/images/sidebar-icons/stream.svg';
import useWindowDimensions from '../../utils/WindowSize';
import NewMobileLayout from '../../Layout/MobilePages/NewMobileLayout';
import axios from 'axios';
import { useState } from 'react';
import RetireContent from '../../components/Retire/RetireContent';
import RetireVideos from '../../components/Retire/RetireVideos';
import { PortfolioContext } from '../../context/PortfolioContext';
import EducationContainer from '../../components/React_Education';

function EducationPage() {
  const { width, height } = useWindowDimensions();
  const {
    youtubeApiData,
    setYoutubeApiData,
    youtubeApiVideoData,
    setYoutubeApiVideoData,
  } = useContext(PortfolioContext);

  return (
    <>
      {width > 768 || width > height ? (
        <Layout active="react" className={classNames.bplannerPage} hideFooter>
          <EducationContainer />
        </Layout>
      ) : (
        <NewMobileLayout />
      )}
    </>
  );
}

export default EducationPage;

{
  /* <div className={classNames.headingDiv}>
            <img
              src={streamIcon}
              alt="streamIcon"
              style={{ display: 'flex', width: '5vh', height: '5vh' }}
            />
            <div className={classNames.feature}>Stream</div>
          </div>
          <div className={classNames.comingText}>Coming Soon!</div>
          <div className={classNames.workingText}>
            <span>We are currently working on this feature</span>
            <span>and will launch soon!</span>
          </div>
          <div className={classNames.bottomLine}></div> */
}
