import React, { useContext } from 'react';
import { useAppContextDetails } from '../../../context/AppContext';
import { useCoinContextData } from '../../../context/CoinContext';
import { BankContext } from '../../../context/Context';
import transferIn from '../../../static/images/transfer-icons/transferIn.svg';
import transferOut from '../../../static/images/transfer-icons/transferOut.svg';
import metaverseApps from '../../../static/images/sidebarIcons/metaverseApps.svg';
import bonds from '../../../static/images/login/bond.svg';
import moneymarket from '../../../static/images/vaults/moneymarket.svg';
import { FromAssetEarnings } from './FromAssetEarnings';
import { TransferSuccess } from './TransferSuccess';
import UpdatedAction from './UpdatedAction';
import UpdatedSuccess from './UpdatedSuccess';

export const TransferAction = () => {
  const {
    transferActionStep,
    setTransferActionStep,
    selectedCoin,
    setCoinAction,
    setTransferMethod,
    transferMethod,
  } = useCoinContextData();
  const { sidebarCollapse } = useContext(BankContext);
  const { appName } = useAppContextDetails();
  const marginLeftSize = { marginLeft: sidebarCollapse ? '15px' : '35px' };

  const transferActions = [
    {
      id: 1,
      icon: transferIn,
      onClick: () => {
        setTransferActionStep(2);
      },
      text: 'Transfer In',
      subText: `Transferring Into ${appName} ${selectedCoin.coinSymbol} Vault`,
    },
    {
      id: 2,
      icon: transferOut,
      text: 'Transfer Out',
      subText: `Transferring Out Of ${appName} ${selectedCoin.coinSymbol} Vault`,
    },
  ];

  const transferInActions = [
    {
      id: 1,
      icon: metaverseApps,
      text: 'From Another App',
      subText: `Move Liquid ${selectedCoin.coinSymbol} Between Your Vaults`,
    },
    {
      id: 2,
      icon: bonds,
      onClick: () => {
        setTransferActionStep(3);
        setTransferMethod('bonds');
      },
      text: 'From Bond Earnings',
      subText: `Cash Out Your ${selectedCoin.coinSymbol} Bond Interest`,
    },
    {
      id: 3,
      icon: moneymarket,
      onClick: () => {
        setTransferActionStep(3);
        setTransferMethod('moneymarkets');
      },
      text: 'From MoneyMarkets',
      subText: `Cash Out Your ${selectedCoin.coinSymbol} MoneyMarkets Interest`,
    },
  ];

  return (
    <>
      <div
        className="breadCrumbs"
        style={{
          display:
            transferActionStep === 5
              ? 'none'
              : transferActionStep === 6
              ? 'none'
              : 'flex',
        }}
      >
        {transferActionStep === 1 && (
          <div className="gt">
            <span onClick={() => setCoinAction(['Menu'])}>
              {selectedCoin.coinName} Actions &nbsp;&gt;&nbsp;
            </span>
            <span>Select Direction</span>
          </div>
        )}
        {transferActionStep === 2 && (
          <div className="gt">
            <span onClick={() => setTransferActionStep(1)}>
              Select Direction &nbsp;&gt;&nbsp;
            </span>
            <span>Select Origin</span>
          </div>
        )}
        {transferActionStep === 3 && (
          <div className="gt">
            <span onClick={() => setTransferActionStep(2)}>
              Select Origin &nbsp;&gt;&nbsp;
            </span>
            <span>Amount</span>
          </div>
        )}
      </div>

      {transferActionStep === 5 && (
        <div
          style={{
            width: '100%',
            borderTop: '0.5px solid #E7E7E7',
            borderBottom: '0.5px solid #E7E7E7',
            height: '5%',
            padding: '0 1.85rem',
            display: 'flex',
            alignItems: 'center',
            fontWeight: '500',
          }}
        >
          {selectedCoin.coinSymbol}{' '}
          {transferMethod === 'bonds' ? 'Staking' : 'MoneyMarkets'} Vault
          Actions
        </div>
      )}

      {transferActionStep === 6 && (
        <div
          style={{
            width: '100%',
            borderTop: '0.5px solid #E7E7E7',
            borderBottom: '0.5px solid #E7E7E7',
            height: '5%',
            padding: '0 1.85rem',
            display: 'flex',
            alignItems: 'center',
            fontWeight: '500',
          }}
        >
          Transfer Successful
        </div>
      )}

      {transferActionStep === 1 && (
        <div className="sidebar-menu mt-3">
          {transferActions.map((option) => (
            <>
              <div
                className="d-flex sidebar-container clickable-div"
                onClick={option.onClick}
                style={{
                  opacity: option.onClick ? 1 : 0.25,
                  pointerEvents: !option.onClick && 'none',
                }}
              >
                <img
                  className="sidebar-content-icon"
                  src={option.icon}
                  alt={option.text}
                  width={60}
                  style={marginLeftSize}
                />
                {!sidebarCollapse && (
                  <div className="col my-auto p-0">
                    <h5 className="action-label">{option.text}</h5>
                    <div className="getStartedBtn action-sublabel">
                      {option.subText}
                    </div>
                  </div>
                )}
              </div>
              <div className="sidebar-spacer" />
            </>
          ))}
        </div>
      )}

      {transferActionStep === 2 && (
        <div className="sidebar-menu mt-3">
          {transferInActions.map((option) => (
            <>
              <div
                className="d-flex sidebar-container clickable-div"
                onClick={option.onClick}
                style={{
                  opacity: option.onClick ? 1 : 0.25,
                  pointerEvents: !option.onClick && 'none',
                }}
              >
                <img
                  className="sidebar-content-icon"
                  src={option.icon}
                  alt={option.text}
                  width={60}
                  style={marginLeftSize}
                />
                {!sidebarCollapse && (
                  <div className="col my-auto p-0">
                    <h5 className="action-label">{option.text}</h5>
                    <div className="getStartedBtn action-sublabel">
                      {option.subText}
                    </div>
                  </div>
                )}
              </div>
              <div className="sidebar-spacer" />
            </>
          ))}
        </div>
      )}
      {transferActionStep === 3 && <FromAssetEarnings />}
      {transferActionStep === 4 && <TransferSuccess />}
      {transferActionStep === 5 && <UpdatedAction />}
      {transferActionStep === 6 && <UpdatedSuccess />}
    </>
  );
};
