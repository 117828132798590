import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';

function GetStartedSetPassword({
  setStepName,
  logo,
  setPassword,
  password,
  validateCircle,
  isValid,
  setPasswordReqModal,
}) {
  const [eye, setEye] = useState(false);
  const ref = useRef();
  useEffect(() => {
    setTimeout(() => {
      ref && ref.current.focus();
    }, 200);
  }, []);
  return (
    <div className="stepWrapper stepForm">
      <img src={logo} alt="assetLogo" className="assetLogo" />
      <div className="stepDesc">Great Job. Now Assign A Permenant Password</div>
      <div className="group">
        <input
          ref={ref}
          type={eye ? 'text' : 'password'}
          className="input password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setStepName('confirmPassword');
            }
          }}
        />
        {validateCircle(isValid)}
        <FontAwesomeIcon
          icon={eye ? faEye : faEyeSlash}
          className="eye"
          onClick={() => setEye(!eye)}
        />
      </div>
      <div className="btns">
        <div className="btnBig" onClick={() => setPasswordReqModal(true)}>
          Password Requirements
        </div>
        <div className="btnNext" onClick={() => setStepName('confirmPassword')}>
          Next
        </div>
      </div>
    </div>
  );
}

export default GetStartedSetPassword;
