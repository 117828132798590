import React from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { AppContext } from '../../../context/AppContext';
import './buySellStyle.scss';
import BuyStepOne from './BuyStepOne';
import BuyStepTwo from './BuyStepTwo';
import SellStepOne from './SellStepOne';
import SellStepTwo from './SellStepTwo';
const BuySellFlow = ({ selectedItem }) => {
  const {
    selectedOperation,
    setSelectionOperation,
    buySellStep,
    setBuySellStep,
    setSelectedAsset,
  } = useContext(AppContext);

  const conditionalSteps = () => {
    if (buySellStep === 'BuyStepOne') {
      return <BuyStepOne />;
    } else if (buySellStep === 'BuyStepTwo') {
      return <BuyStepTwo />;
    } else if (buySellStep === 'SellStepOne') {
      return <SellStepOne />;
    } else if (buySellStep === 'SellStepTwo') {
      return <SellStepTwo />;
    }
  };

  useEffect(() => {
    conditionalSteps();
  }, [buySellStep]);

  return <>{conditionalSteps()}</>;
};

export default BuySellFlow;
